<router-outlet (activate)="hideSelf = true" (deactivate)="deactivated()" ></router-outlet>

<div class="row" *ngIf="!hideSelf">
    <div class="col-md-12">
        <wic-apl-imports-table
            [configs]="configs$ | async"
            [forDeletion]="forDeletionConfig$ | async"
            [isLoading]="isLoadingConfigs$ | async"
            (added)="addConfig()"
            (deleted)="deleteConfig($event)"
            (refreshed)="refreshConfigs()">
        </wic-apl-imports-table>
    </div>
</div>

<div class="row mb-5" *ngIf="!hideSelf">
    <div class="col-md-12">
        <wic-apl-overrides-table
            [overrides]="overrides$ | async"
            [forDeletion]="forDeletionOverride$ | async"
            [isLoading]="isLoadingOverrides$ | async"
            [categories]="categories$ | async"
            [subCategories]="subCategories$ | async"
            [areShared]="true"
            (added)="addOverride()"
            (edited)="editOverride($event)"
            (deleted)="deleteOverride($event)"
            (refreshed)="refreshOverrides()">
        </wic-apl-overrides-table>
    </div>
</div>
