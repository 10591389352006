import {Component, Input} from '@angular/core';
import {IImportParseError} from '@models/types';

@Component({
  selector: 'wic-import-parse-errors',
  templateUrl: './wic-import-parse-errors.component.html',
  styleUrls: ['./wic-import-parse-errors.component.sass']
})
export class WicImportParseErrorsComponent {
    @Input() parseErrors: IImportParseError[];
}
