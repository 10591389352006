import { Component, OnInit } from '@angular/core';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {BenefitInfoFacade} from '@facades/benefit-info.facade';
import {CardsFacade} from '@facades/cards.facade';

@Component({
  selector: 'wic-card-check',
  templateUrl: './card-check.component.html',
  styleUrls: ['./card-check.component.scss']
})
export class CardCheckComponent implements OnInit {

  constructor(public benefitInfo: BenefitInfoFacade, public authorities: AuthoritiesFacade, public cards: CardsFacade) { }

  ngOnInit() {
  }

}
