/* tslint:disable */
import {Injectable} from '@angular/core';
import {APLImportConfigsFacade} from '@facades/apl-import-configs.facade';
import {APLSummariesFacade} from '@facades/apl-summaries.facade';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {APLSummary} from '@models/APLSummary';
import {ILookup} from '@models/types';
import {Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {from, Observable} from 'rxjs';
import {first, groupBy, map, mergeMap, reduce, switchMap, tap, toArray, withLatestFrom} from 'rxjs/operators';

/* tslint:enable */

export interface IAPLImportStatusCounts {
    skipped: number;
    succeeded: number;
    failed: number;
}

export interface ICountOfValue {
    value: number;
    count: number;
}

export interface IHistogram extends Array<ICountOfValue> {
}

export interface IDataPoint {
    name: string;
    count: number;
}

export interface ISeries extends Array<IDataPoint> {
}

@Injectable({
    providedIn: 'root'
})
export class APLReportingFacade {

    constructor(
        private store: Store<IAppState>,
        private authoritiesFacade: AuthoritiesFacade,
        private aplSummariesFacade: APLSummariesFacade,
        private aplImportConfigsFacade: APLImportConfigsFacade) {
    }

    // region Selections
    get detailedSummaries(): Observable<APLSummary[]> {
        return this.aplSummariesFacade.summaries.pipe(
            withLatestFrom(
                this.authoritiesFacade.allAsLookup$,
                this.aplImportConfigsFacade.selectAll
            ),
            map(([summaries, authorities, configs]) =>
                summaries.map(summary => ({
                    ...summary,
                    authority: (authorities.find(authority => authority.id === summary.authorityId) || {} as ILookup).name,
                    config: configs.find(config => config.authorityId === summary.authorityId)
                }))
            )
        );
    }

    get importStatusCounts(): Observable<IAPLImportStatusCounts> {
        return this.detailedSummaries.pipe(
            first(summaries => !!summaries && !!summaries.length),
            switchMap(summaries => from(summaries)),
            groupBy(summary => summary.config?.skipped ? 'skipped' : summary.config?.succeeded ? 'succeeded' : 'failed'),
            mergeMap(group => group.pipe(
                toArray(),
                map(g => g.length),
                map(sum => ({
                    [group.key]: sum
                }))
            )),
            reduce<{ [key: string]: number }, IAPLImportStatusCounts>((counts, group) => ({
                ...counts,
                ...group
            }), {
                skipped: 0,
                succeeded: 0,
                failed: 0
            })
        );
    }

    get itemCounts(): Observable<ISeries> {
        return this.detailedSummaries.pipe(
            first(summaries => !!summaries && !!summaries.length),
            switchMap(summaries => from(summaries)),
            map(summaries => ({
                    name: summaries.authority,
                    count: summaries.itemCount
                })
            ),
            reduce<IDataPoint, ISeries>((series, dataPoint) => [...series, dataPoint], [])
        );
    }

    get categoriesHistogram(): Observable<IHistogram> {
        return this.detailedSummaries.pipe(
            first(summaries => !!summaries && !!summaries.length),
            switchMap(summaries => from(summaries)),
            groupBy(summary => summary.catCount),
            mergeMap(group => group.pipe(
                toArray(),
                map(g => g.length),
                map(sum => ({
                    value: group.key,
                    count: sum
                }))
            )),
            reduce<ICountOfValue, IHistogram>((set, histogram) => [...set, histogram], []),
            map(histogram => histogram.sort((a, b) => a.value - b.value))
        );
    }

    get subCategoriesHistogram(): Observable<IHistogram> {
        return this.detailedSummaries.pipe(
            first(summaries => !!summaries && !!summaries.length),
            switchMap(summaries => from(summaries)),
            groupBy(summary => Math.floor(summary.subCatCount / 10) * 10),
            mergeMap(group => group.pipe(
                toArray(),
                map(g => g.length),
                map(sum => ({
                    value: group.key,
                    count: sum
                }))
            )),
            reduce<ICountOfValue, IHistogram>((set, histogram) => [...set, histogram], []),
            map(histogram => histogram.sort((a, b) => a.value - b.value))
        );
    }

    // endregion

    // region Dispatches

    // endregion
}
