import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {AuthFacade} from '@facades/auth.facade';
import {IAppState} from '@state/app.state';
import {loading} from '@state/loading/selectors';

@Component({
    selector: 'wic-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
    loading$: Observable<boolean>;

    ngOnInit() {
        if (
            this.location.path().startsWith('/login') ||
            this.location.path().startsWith('/reset') ||
            this.location.path().startsWith('/resetInitiated') ||
            this.location.path().startsWith('/change') ||
            this.location.path().startsWith('/changed')
        ) {
            return;
        }

        this.loading$ = this.store.pipe(select(loading.loading));
    }

    constructor(private store: Store<IAppState>, private location: Location, private auth: AuthFacade) {
    }
}
