import { Component, OnInit } from '@angular/core';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {AuthorityFlag, AuthorityType} from '@models';
import {Observable} from 'rxjs';

@Component({
  selector: 'wic-authority-types',
  templateUrl: './wic-authority-types.component.html',
  styleUrls: ['./wic-authority-types.component.sass']
})
export class WicAuthorityTypesComponent implements OnInit {
    constructor(public authorities: AuthoritiesFacade) {
    }

    ngOnInit() {
        this.authorities.loadTypes();
    }

    refreshFlags() {
        this.authorities.loadTypes();
    }
}
