import {Entity, Key} from '@briebug/ngrx-auto-entity';

import {ObjectId} from '@models/types';

@Entity({
    modelName: 'APLSearch',
})
export class APLSearch {
    // tslint:disable-next-line:variable-name
    @Key itemId?: ObjectId;
    purchaseIndicator: boolean;
    itemNumber: string;
    description: string;
    authorityId: number;
    authority?: string;
    categoryId: string | number;
    subCategoryId: string | number;
}
