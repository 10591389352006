<div class="w-100 h-100">
    <ng-template #noData>
        <div class="w-100 h-75"></div>
    </ng-template>
    <canvas baseChart *ngIf="labels && data; else noData"
            [datasets]="[{data: data,backgroundColor:colors,borderColor:'#fff',borderWidth:3}]"
            [labels]="labels"
            [chartType]="'doughnut'"
            [colors]="colors"
            [options]="{cutoutPercentage:67}"
            [legend]="false"></canvas>
    <span class="text-nowrap text-white" [ngClass]="totalLabel && totalLabel.length ? 'overlay-center-dual' : 'overlay-center'"
          *ngIf="data.length !== 2">{{sumTotal(data)}}
        <span *ngIf="totalLabel && totalLabel.length"><br>{{totalLabel}}</span>
    </span>
    <span class="text-nowrap text-white overlay-center" *ngIf="data.length === 2">{{data[0]}}/{{data[1]}}</span>
    <div class="text-nowrap text-muted m-2" *ngIf="chartTitle">{{chartTitle}}</div>
</div>
