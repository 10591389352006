import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {BenefitImportJob} from '@models/BenefitImportJob';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BenefitImportJobService implements IAutoEntityService<BenefitImportJob> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: any): Observable<BenefitImportJob[]> {
        const url = (criteria && criteria.authorityId) ?
            `/admin/authorities/${criteria.authorityId}/benefits/modern-imports` :
            '/admin/authorities/benefits/modern-imports';
        return this.http.get<BenefitImportJob[]>(environment.apiRoot + url);
    }

    load(entityInfo: IEntityInfo, keys, criteria?): Observable<BenefitImportJob> {
        const url = `/admin/authorities/benefits/modern-imports/${keys}`;
        return this.http.get<BenefitImportJob>(environment.apiRoot + url);
    }
}
