import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ModernStatisticsRecord} from '@models/ModernStatisticsRecord';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';
import {Sorter} from '../../util';
import {IDateRange} from '../wic-authorities/wic-authorities-legacy-report/wic-authorities-legacy-report.component';

@Component({
    selector: 'wic-modern-statistics-report',
    templateUrl: './modern-statistics-report.component.html',
    styleUrls: ['./modern-statistics-report.component.scss']
})
export class ModernStatisticsReportComponent implements OnInit {
    @Input() isLoading: boolean;
    @Input() loadedAt: number;
    @Input() loadDuration: string;
    @Input() criteria: { startDate: string, endDate: string};
    @Input() report: ModernStatisticsRecord[];
    @Input() totals: ModernStatisticsRecord;
    @Output() dateRangeChanged = new EventEmitter<IDateRange>();

    dateRange: IDateRange;

    apiUrl = environment.apiRoot;

    reportSorter = new Sorter(() => [...this.report], sorted => this.report = sorted, -1);

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        const start = moment();
        const end = moment().add(1, 'day');
        this.dateRange = {
            startDate: {
                year: start.year(),
                month: start.month() + 1,
                day: start.date(),
            },
            endDate: {
                year: end.year(),
                month: end.month() + 1,
                day: end.date(),
            },
        };
    }

    getFilename(dateRange: IDateRange) {
        if (!dateRange) {
            return;
        }

        const startDate = `${dateRange.startDate.year}-${dateRange.startDate.month}-${dateRange.startDate.day}`;
        const endDate = `${dateRange.endDate.year}-${dateRange.endDate.month}-${dateRange.endDate.day}`;
        return `Statistics_Report_${startDate}_${endDate}.csv`;
    }

    buildDownloadUri(report: ModernStatisticsRecord[]) {
        if (!report) {
            return;
        }

        /* tslint:disable:max-line-length */
        const csv = report.reduce((lines, record) =>
            lines + `${record.authorityId},${record.authority},${record.totalCards},${record.uniqueShoppingTrips},${record.totalShoppingTrips},${record.registrations},${record.deviceRegistrations},${record.deviceUsage},${record.uniqueProductChecks},${record.totalProductChecks}\r\n`
            , 'AuthorityID,Authority,Total Cards,Unique Shopping Trips,Total Shopping Trips,Registrations,Device Registrations,Device Usage,Unique Product Checks,Total Product Checks\r\n');
        /* tslint:enable:max-line-length */

        return this.sanitizer.bypassSecurityTrustUrl(`data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`);
    }
}
