import {buildState} from '@briebug/ngrx-auto-entity';
import {LocalBenefit} from '@models/LocalBenefit';
import {createSelector} from '@ngrx/store';

const {initialState, selectors, entityState} = buildState(LocalBenefit);

export const {
    selectAll: allLocalBenefits,
    selectEntities: localBenefitEntities,
    selectIds: localBenefitIds,
    selectTotal: totalLocalBenefits,
} = selectors;

export const localBenefitsPage = createSelector(entityState, state => {
    return (state && state.currentPage) ? state.currentPage.page || 1 : 1;
});
export const localBenefitPageableCount = createSelector(entityState, state => {
    return state ? state.totalPageableCount : 0;
});

export function localBenefitReducer(state = initialState) {
    return state;
}
