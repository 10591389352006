import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItem} from '@models';

@Component({
  selector: 'wic-full-screen-menu',
  templateUrl: './full-screen-menu.component.html',
  styleUrls: ['./full-screen-menu.component.sass']
})
export class FullScreenMenuComponent {
    @Input() menuItems: MenuItem[];
    @Input() areaPath: string;
    @Input() display = false;

    @Output() closed = new EventEmitter();
    @Output() navigated = new EventEmitter();
}
