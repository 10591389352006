import {Component, OnInit} from '@angular/core';
import {LoadAll, LoadPage} from '@briebug/ngrx-auto-entity';
import {AppointmentImportsFacade} from '@facades/appointment-imports.facade';
import {AppointmentImport} from '@models/Appointment';
import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {allAppointmentImports} from '@state/appointment-imports.state';
import {Observable} from 'rxjs';

@Component({
    selector: 'wic-appointment-imports',
    templateUrl: './wic-appointment-imports.component.html',
    styleUrls: ['./wic-appointment-imports.component.sass'],
})
export class WicAppointmentImportsComponent implements OnInit {
    apptImports$: Observable<AppointmentImport[]>;

    hideSelf = false;

    constructor(private store: Store<IAppState>, private appointmentImportsFacade: AppointmentImportsFacade) {
    }

    ngOnInit() {
        this.apptImports$ = this.appointmentImportsFacade.all;
        this.appointmentImportsFacade.loadAll();
    }

    refresh() {
        this.appointmentImportsFacade.loadAll();
    }
}
