import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {Injectable} from '@angular/core';
import {
    allBenefitImportJobs,
    currentBenefitImportJob,
    currentJobProgress,
} from '@state/benefit-import-job/benefit-import-job.state';
import {refreshBenefitImportJob} from '@state/benefit-import-job/benefit-import-job.actions';

@Injectable({
    providedIn: 'root'
})
export class BenefitImportJobFacade {
    benefitImportJobs$ = this.store.pipe(select(allBenefitImportJobs));
    selected$ = this.store.pipe(select(currentBenefitImportJob));
    currentJobProgress$ = this.store.pipe(select(currentJobProgress));

    constructor(private store: Store<IAppState>) {
    }

    refresh() {
        this.store.dispatch(refreshBenefitImportJob());
    }
}
