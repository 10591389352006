import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { NgrxAutoEntityModule } from '@briebug/ngrx-auto-entity';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EntityService } from '../../entity.service';
import { EnhancedIssueTranslation } from './enhanced-issue-translations.model';
import { ENHANCED_ISSUES_TRANSLATIONS_FEATURE_KEY, enhancedIssueTranslationsReducer } from './enhanced-issues-translations.state';
import { EnhancedIssuesTranslationsEffects } from './enhanced-issues-translations.effects';
import { EnhancedIssuesTranslationsFacade } from './enhanced-issues-translations.facade';

@NgModule({
  imports: [
    HttpClientModule,
    NgrxAutoEntityModule.forFeature(),
    EffectsModule.forFeature([EnhancedIssuesTranslationsEffects]),
    StoreModule.forFeature(ENHANCED_ISSUES_TRANSLATIONS_FEATURE_KEY, enhancedIssueTranslationsReducer),
  ],
  providers: [      
    EnhancedIssuesTranslationsFacade,
    
    { provide: EnhancedIssueTranslation, useClass: EntityService },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
})
export class EnhancedIssuesTranslationsFeatureModule {}
