import {AuthorityType} from '@models';
import {AuthorityTypesActions, FETCH_AUTHORITY_TYPES_SUCCESS} from '@state/authorities/types.actions';

export interface IAuthorityTypesState {
    AuthorityTypes?: AuthorityType[];
}

const initialState: IAuthorityTypesState = {
    AuthorityTypes: null,
};

export function authorityTypesReducer(state: IAuthorityTypesState = initialState, action: AuthorityTypesActions): IAuthorityTypesState {
    switch (action.type) {
        case FETCH_AUTHORITY_TYPES_SUCCESS:
            return {
                ...state,
                AuthorityTypes: action.payload,
            };
        default:
            return state;
    }
}
