import { buildState } from "@briebug/ngrx-auto-entity";
import { Action, createReducer } from "@ngrx/store";
import { StandardSubcategory } from "./standard-subcategory.model";


export const {
  initialState: initialStandardSubcategoriesState,
  facade: StandardSubcategoriesFacadeBase,
  actions: {
    loadAll: loadAllStandardSubcategories,
    create: createStandardSubcategory,
    createSuccess: createStandardSubcategorySuccess,
    replace: replaceStandardSubcategory,
    replaceSuccess: replaceStandardSubcategorySuccess,
    delete: deleteStandardSubcategory,
    deleteSuccess: deleteStandardSubcategorySuccess,
  },
  selectors: {
    selectAll: allStandardSubcategories,
  }
} = buildState(StandardSubcategory);

const reduce = createReducer(initialStandardSubcategoriesState);

export function standardSubcategoryReducer(state = initialStandardSubcategoriesState, action: Action) {
  return reduce(state, action);
}

export const STANDARD_SUBCATEGORY_FEATURE_KEY = 'standardSubcategory';