import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { NgrxAutoEntityModule } from '@briebug/ngrx-auto-entity';
import { LANGUAGES_FEATURE_KEY, languageReducer } from "./languages.state";
import { StoreModule } from "@ngrx/store";
import { LanguagesFacade } from "./languages.facade";
import { Language } from "./language.entity";
import { LanguagesService } from "./languages.service";

@NgModule({
  imports: [
    HttpClientModule,
    NgrxAutoEntityModule.forFeature(),
    StoreModule.forFeature(LANGUAGES_FEATURE_KEY, languageReducer),
    
  ],
  providers: [
    LanguagesFacade,
    { provide: Language, useClass: LanguagesService }
  ],
})
export class LanguageModule {}