import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EnhancedIssuesModalModule } from "./modals/enhanced-issues-modal.module";
import { CommonModule } from "@angular/common";
import { WicEnhancedIssuesComponent } from "./wic-enhanced-issues/wic-enhanced-issues.component";
import { EnhancedIssuesFeatureModule } from "./feature/enhanced-issues-feature.module";
import { EnhancedIssueTranslationsModule } from "./wic-enhanced-issue-translations/wic-enhanced-issue-translations.module";
import { EnhancedIssuesTranslationsFeatureModule } from "app/enhanced-issues-translations/feature/enhanced-issues-translations-feature.module";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    EnhancedIssuesFeatureModule,
    EnhancedIssuesModalModule,
    EnhancedIssuesTranslationsFeatureModule,
    EnhancedIssueTranslationsModule,
  ],
  declarations: [WicEnhancedIssuesComponent],
  exports: [WicEnhancedIssuesComponent]
})
export class EnhancedIssuesModule {}