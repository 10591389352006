<div class="modal-header">
    <h4 class="modal-title mb-0">
        <span class="badge badge-info" *ngIf="user._id">
            {{user._id}}
        </span>
        {{user._id ? 'Edit' : 'Create'}} User
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group row">
            <label for="username" class="col-sm-2 col-form-label" [class.text-danger]="submitted && form.controls.username.invalid">Username</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="username" formControlName="username" [class.is-invalid]="submitted && form.controls.username.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="password" class="col-sm-2 col-form-label" [class.text-danger]="submitted && form.controls.password.invalid">Password</label>
            <div class="col-sm-10">
                <input type="password" class="form-control" id="password" formControlName="password" [class.is-invalid]="submitted && form.controls.password.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="email" class="col-sm-2 col-form-label" [class.text-danger]="submitted && form.controls.email.invalid">Email</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="email" formControlName="email" [class.is-invalid]="submitted && form.controls.email.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="first" class="col-sm-2 col-form-label" [class.text-danger]="submitted && (form.controls.first.invalid || form.controls.last.invalid)">First & Last</label>
            <div class="col-sm-5">
                <input type="text" class="form-control" id="first" formControlName="first" [class.is-invalid]="submitted && form.controls.first.invalid">
            </div>
            <div class="col-sm-5">
                <input type="text" class="form-control" id="last" formControlName="last" [class.is-invalid]="submitted && form.controls.last.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="authorityId" class="col-sm-2 col-form-label" [class.text-danger]="submitted && form.controls.authorityId.invalid">Authority ID</label>
            <div class="col-sm-2">
                <input type="number" class="form-control" id="authorityId" formControlName="authorityId" [class.is-invalid]="submitted && form.controls.authorityId.invalid">
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isEnabled" formControlName="isEnabled">
                    <label class="form-check-label" for="isEnabled">Enabled</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="hasAdminAccess" formControlName="hasAdminAccess">
                    <label class="form-check-label" for="hasAdminAccess">Has Admin Access</label>
                </div>
            </div>
        </div>
        <fieldset class="form-group">
            <div class="row">
                <legend class="col-form-label col-sm-2 pt-0">Roles</legend>
                <div class="col-sm-10" formArrayName="roleList">
                    <div class="form-check" *ngFor="let role of rolesArray.controls; let idx = index" [formGroupName]="idx">
                        <input class="form-check-input" type="checkbox" name="roles" id="{{'role' + idx}}" formControlName="value">
                        <label class="form-check-label" for="{{'role' + idx}}">
                            {{role.get('name').value}}
                        </label>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>
