<div class="card mt-5 mb-5">
    <div class="card-header">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <div class="input-group">
                            <input class="form-control" placeholder="Search" formControlName="criteria">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="form.patchValue({criteria: null})" type="button">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <ngx-bootstrap-multiselect
                        [options]="typesOptions"
                        [texts]="{defaultTitle: 'Select Type'}"
                        [settings]="{dynamicTitleMaxItems: 1, buttonClasses: 'btn btn-outline-secondary'}"
                        formControlName="types">
                    </ngx-bootstrap-multiselect>
                </div>
                <div class="col-3">
                    <ngx-bootstrap-multiselect
                        [options]="activesOptions"
                        [texts]="{defaultTitle: 'Select Active Flag'}"
                        [settings]="{selectionLimit: 1, autoUnselect: true, buttonClasses: 'btn btn-outline-secondary'}"
                        formControlName="active">
                    </ngx-bootstrap-multiselect>
                </div>
            </div>
        </form>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="authorities?.length">
            <thead>
            <tr>
                <th class="text-nowrap" (click)="authoritiesSorter.sortNumber('AuthorityID')">
                    <i class="fa fa-key"></i>
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': authoritiesSorter.direction === 1, 'fa-sort-numeric-desc': authoritiesSorter.direction === -1}"
                       *ngIf="authoritiesSorter.sortKey === 'AuthorityID'"></i>
                </th>
                <th class="" (click)="authoritiesSorter.sortString('Name')">
                    Name
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-alpha-asc': authoritiesSorter.direction === 1, 'fa-sort-alpha-desc': authoritiesSorter.direction === -1}"
                       *ngIf="authoritiesSorter.sortKey === 'Name'"></i>
                </th>
                <th class="d-none d-md-block">Short Name</th>
                <th class="text-center">Integration</th>
                <th class="text-center" (click)="authoritiesSorter.sortNumber('AuthorityType')">
                    Type
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-amount-asc': authoritiesSorter.direction === 1, 'fa-sort-amount-desc': authoritiesSorter.direction === -1}"
                       *ngIf="authoritiesSorter.sortKey === 'AuthorityType'"></i>
                </th>
                <th class="text-center" (click)="authoritiesSorter.sortString('IsActiveText')">
                    Active
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-amount-asc': authoritiesSorter.direction === 1, 'fa-sort-amount-desc': authoritiesSorter.direction === -1}"
                       *ngIf="authoritiesSorter.sortKey === 'IsActiveText'"></i>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let authority of filter(authorities)">
                <td class="">{{authority.id}}</td>
                <td class=""><a [routerLink]="['view', authority.id]">{{authority.name}}</a></td>
                <td class="d-none d-md-block">{{authority.shortName}}</td>
                <td class="text-center">
                  <span *ngIf="authority.integration" class="badge badge-default badge-info text-white">
                    {{ authority.integration }}
                  </span>
                </td>
                <td class="text-center">
                    <span class="badge badge-default badge-info text-white">
                        <span [ngSwitch]="authority.type">
                            <span *ngSwitchCase="0">Verify Only</span>
                            <span *ngSwitchCase="1">Offline</span>
                            <span *ngSwitchCase="2">Online</span>
                            <span *ngSwitchCase="3">Voucher</span>
                            <span *ngSwitchCase="4">Non-Subscriber</span>
                        </span>
                    </span>
                </td>
                <td class="text-center">
                    <span class="badge badge-pill badge-default text-white"
                          [class.badge-danger]="!authority.isActive"
                          [class.badge-success]="authority.isActive">
                        {{authority.isActive ? 'Yes' : 'No'}}
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!authorities?.length" class="card-body">
        <p><em>There are no authorities.</em></p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6">
                <a class="btn btn-outline-secondary float-left" role="button"
                   [download]="getFilename()" [href]="buildDownloadUri(authorities)">
                    <i class="fa fa-download"></i> Download as CSV
                </a>
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
                <button class="btn btn-outline-primary" (click)="added.emit()">
                    <i class="fa fa-plus"></i> Add Authority
                </button>
            </div>
        </div>
    </div>
</div>
