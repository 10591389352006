import { Component, OnInit, Input } from '@angular/core';
import {MbscCardOptions} from '@mobiscroll/angular';

enum StatusColors {
    'DELAYED' = ''
}

@Component({
  selector: 'wic-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss']
})
export class StatusCardComponent {
    @Input() card;

    settings: MbscCardOptions = {
        theme: 'ios',
        themeVariant: 'light'
    };
}
