<div style="width: 75px; height: 50px">
    <canvas baseChart *ngIf="labels && data"
            [datasets]="[{data: data,backgroundColor:colors,borderColor:'#fff',borderWidth:1}]"
            [labels]="labels"
            [chartType]="'pie'"
            [colors]="colors"
            [options]="{
                animation: false,
                tooltips: {
                    enabled: false
                },
                layout: {
                    padding: {
                        top: 8
                    }
                }
            }"
            [legend]="false">
    </canvas>
</div>
