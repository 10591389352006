<div class="card mt-4">
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                <h5 class="float-left mb-0" *ngIf="!showFilter">Device Log Sessions</h5>
                <form [formGroup]="form" *ngIf="showFilter">
                    <div class="row">
                        <div class="col-4">
                            <input formControlName="criteria" placeholder="Search Criteria">
                        </div>
                        <div class="col-4">
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-1" *ngIf="!showToolbar">
                <i class="fa fa-refresh" (click)="refreshed.emit()"></i>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="!!sessions?.length">
            <thead>
            <tr>
                <th>Platform</th>
                <th>Manufacturer</th>
                <th *ngIf="!lite">Version</th>
                <th *ngIf="!lite">Cordova</th>
                <th>Registered At</th>
                <th>Last Shipment</th>
                <th class="text-right"><i class="fa fa-wrench"></i></th>
            </tr>
            </thead>
            <tbody *ngFor="let session of sessions">
            <tr>
                <td class="text-left">
                    <a href="" (click)="$event.preventDefault(); selected.emit(session)">{{session.deviceInfo.platform}}</a>
                </td>
                <td class="text-left">
                    <a href="" (click)="$event.preventDefault(); selected.emit(session)">{{session.deviceInfo.manufacturer}}</a>
                </td>
                <td class="text-left" *ngIf="!lite">
                    <span class="text-monospace">{{session.deviceInfo.version}}</span>
                </td>
                <td class="text-left" *ngIf="!lite">
                    <span class="text-monospace">{{session.deviceInfo.cordova}}</span>
                </td>
                <td><span class="badge badge-info">{{session.registeredAt | date:'yyyy-MM-dd hh:mm:ss'}}</span></td>
                <td><span class="badge badge-info">{{session.lastUpdatedAt | date:'yyyy-MM-dd hh:mm:ss'}}</span></td>
                <td class="text-right">
                    <a (click)="selected.emit(session)"><i class="fa fa-eye"></i></a>
                </td>
            </tr>
            <tr>
                <td [colSpan]="lite ? '2' : '3'" class="text-monospace">
                    <span *ngIf="!lite">Device ID:</span>
                    <span class="badge badge-udid">{{session.udid}}</span>
                </td>
                <td [colSpan]="lite ? '2' : '3'" class="text-monospace">
                    <span *ngIf="!lite">Session ID:</span>
                    <span class="badge badge-session">{{session.sessionId}}</span>
                </td>
                <td class="text-right">
                    <i class="fa fa-trash" (click)="deleted.emit(session)"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!sessions?.length" class="card-body">
        <p><em>There are no device logging sessions.</em></p>
    </div>
    <div class="card-footer" *ngIf="showToolbar">
        <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
            </div>
        </div>
    </div>
</div>
