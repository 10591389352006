import { Entity, Key } from "@briebug/ngrx-auto-entity";

@Entity({
  modelName:'StandardSubcategory',
  uriName:'standard-subcategories',
})
export class StandardSubcategory {
  @Key category: number;
  @Key subcategory: number;
  subcategoryName: string;
  uom: string;
  uomAbbrev: string;
  comment: string;
}