<div class="row">
    <div class="col-md-12">
        <wic-apl-import-detail
            [config]="config$ | async"
            (edited)="editConfig($event)"
            (run)="runJob($event)"
            (check)="checkJob($event)">
        </wic-apl-import-detail>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <wic-apl-transforms-table
            [transforms]="transforms$ | async"
            [isLoading]="isLoadingTransforms$ | async"
            [forDeletion]="forDeletionTransform$ | async"
            (added)="addTransform()"
            (edited)="editTransform($event)"
            (deleted)="deleteTransform($event)">
        </wic-apl-transforms-table>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <wic-apl-overrides-table
            [overrides]="overrides$ | async"
            [forDeletion]="forDeletionOverride$ | async"
            [isLoading]="isLoadingOverrides$ | async"
            [categories]="categories$ | async"
            [subCategories]="subCategories$ | async"
            [showToolbar]="false"
            [areShared]="false"
            (added)="addOverride()"
            (edited)="editOverride($event)"
            (deleted)="deleteOverride($event)"
            (refreshed)="refreshOverrides()">
        </wic-apl-overrides-table>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <wic-apl-import-jobs-table
            [jobs]="jobs$ | async"
            [forDeletion]="forDeletionJob$ | async"
            [isLoading]="isLoadingJobs$ | async"
            (deleted)="deleteJob($event)"
            (refreshed)="refreshJobs()">
        </wic-apl-import-jobs-table>
    </div>
</div>

<div class="row mb-5">
    <div class="col-md-12">
        <router-outlet></router-outlet>
    </div>
</div>
