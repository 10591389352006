import {Component, EventEmitter, Input, Output} from '@angular/core';
import {APLTransform} from '@models/APLTransform';

@Component({
    selector: 'wic-apl-transforms-table',
    templateUrl: './wic-apl-transforms-table.component.html',
    styleUrls: ['./wic-apl-transforms-table.component.sass']
})
export class WicAplTransformsTableComponent {
    @Input() transforms: APLTransform[];
    @Input() forDeletion: APLTransform;
    @Input() isLoading = false;
    @Input() showToolbar = false;

    @Output() added = new EventEmitter();
    @Output() edited = new EventEmitter<APLTransform>();
    @Output() deleted = new EventEmitter<APLTransform>();
    @Output() refreshed = new EventEmitter();
}
