<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <span class="badge badge-info" *ngIf="playlist?.PlaylistID">
                {{playlist.PlaylistID}}
            </span>
            {{playlist?.PlaylistID ? 'Edit' : 'Create'}} Banner Playlist
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label for="PlaylistName" class="col-4 col-form-label">Name</label>
            <div class="col-8">
                <input class="form-control" formControlName="PlaylistName" name="PlaylistName" id="PlaylistName">
            </div>
        </div>

        <div class="form-group row">
            <label for="IsActive" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="IsActive" formControlName="IsActive">
                    <label class="form-check-label" for="IsActive">Is Active</label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
