import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EnhancedIssuesTranslationsFeatureModule } from "./feature/enhanced-issues-translations-feature.module";
import { EnhancedIssuesModule } from "app/enhanced-issues/enhanced-issues.module";

@NgModule({
  imports: [
    CommonModule,
    EnhancedIssuesModule,
    EnhancedIssuesTranslationsFeatureModule,
  ],
  declarations: [],
  exports: []
})
export class EnhancedIssuesTranslationsModule {}