import { UsageStatisticsRecord } from '@models';

export enum ReportingJobStatus {
  STARTED = 'Started',
  RX_USAGE_COMPLETED = 'RxUsageCompleted',
  DEVICE_USAGE_COMPLETED = 'DeviceUsageCompleted',
  ITEM_USAGE_COMPLETED = 'ItemUsageCompleted',
  FINISHED = 'Finished',
  FAILED = 'Failed',
}

export class StatisticsReportingJob {
  id: string;
  appId: number;
  criteriaStartDate: string;
  criteriaEndDate: string;
  status: ReportingJobStatus;
  report: UsageStatisticsRecord[];
  createdAt: Date;
  updatedAt: Date;
}
