<form [formGroup]="form" (submit)="save(form)">
  <div class="modal-header">
    <h4 class="modal-title mb-0">
      <span class="badge badge-info" *ngIf="demoAccount?.cardNumber">
        {{ demoAccount.cardNumber }}
      </span>
      {{ demoAccount?.cardNumber ? 'Edit' : 'Create' }} Demo Account
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group row">
      <label for="name" class="col-4 col-lg-2 col-form-label">Name</label>
      <div class="col-8 col-lg-10">
        <input
          type="text"
          class="form-control"
          [class.is-invalid]="submitted && f.name.invalid"
          ngbTooltip="{{ submitted && f.name.invalid ? util.formatError(f.name.errors) : null }}"
          placement="left"
          tooltipClass="form-error"
          id="name"
          formControlName="name"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="cardNumber" class="col-4 col-lg-2 col-form-label">Card Number</label>
      <div class="col-8 col-lg-10">
        <input
          type="text"
          class="form-control"
          [class.is-invalid]="submitted && f.cardNumber.invalid"
          ngbTooltip="{{ submitted && f.cardNumber.invalid ? util.formatError(f.cardNumber.errors) : null }}"
          placement="left"
          maxlength="16"
          tooltipClass="form-error"
          id="cardNumber"
          formControlName="cardNumber"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="authorityId" class="col-4 col-lg-2 col-form-label">Authority</label>
      <div class="col-8 col-lg-10">
        <div ngbDropdown class="d-inline-block">
          <button
            class="btn btn-outline-secondary"
            [class.is-invalid]="submitted && f.authorityId.invalid"
            ngbTooltip="{{ submitted && f.authorityId.invalid ? util.formatError(f.authorityId.errors) : null }}"
            placement="left"
            tooltipClass="form-error"
            id="authorityId"
            ngbDropdownToggle
            type="button"
          >
            {{ currentAuthority?.name }}
          </button>
          <div ngbDropdownMenu aria-labelledby="authorityId">
            <div style="overflow: auto; max-height: 300px;">
              <button
                class="dropdown-item"
                type="button"
                *ngFor="let authority of authorities"
                (click)="chooseAuthority(authority)"
              >
                {{ authority.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-2"></div>
      <div class="col-sm-10">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="isCardActivated"
            formControlName="isCardActivated"
            [class.is-invalid]="submitted && f.isCardActivated.invalid"
          />
          <label
            class="form-check-label"
            for="isCardActivated"
            [class.text-danger]="submitted && f.isCardActivated.invalid"
            >Active</label
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    <button type="submit" class="btn btn-primary">Save</button>
  </div>
</form>
