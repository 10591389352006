import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

import {APLMessage, APLMessageText} from '@models';
import {NgbModal, NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {WicConfirmModalComponent} from '../../../../../wic-confirm-modal/wic-confirm-modal.component';
import { TranslationsFacade } from '../../../../../translations/feature/translations/translations.facade';

@Component({
    selector: 'wic-apl-message-texts',
    templateUrl: './wic-apl-message-texts.component.html',
    styleUrls: ['./wic-apl-message-texts.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WicAplMessageTextsComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() message: APLMessage;
    @Input() texts: APLMessageText[];
    @Output() saved = new EventEmitter<APLMessageText[]>();
    @Output() added = new EventEmitter<APLMessageText>();
    @Output() deleted = new EventEmitter<APLMessageText>();
    @ViewChild('texts', {static: true}) textTabs: NgbTabset;

    form: FormGroup;
    isSubmitted = false;

    get textsArray(): FormArray {
        return this.form.get('texts') as FormArray;
    }

    constructor(
        private fb: FormBuilder,
        private modal: NgbModal,
        private cd: ChangeDetectorRef,
        private translations: TranslationsFacade) {
    }

    ngOnInit() {
        this.form = this.buildForm();
    }

    ngOnChanges() {
        if (this.texts) {
            this.populateForm(this.form);
        }
    }

    ngAfterViewInit(): void {
        this.cd.detectChanges();
    }

    buildForm() {
        return this.fb.group({
            texts: this.fb.array([
                this.fb.group({
                    languageCode: 'en',
                    title: '',
                    message: ''
                }),
            ])
        });
    }

    populateForm(form: FormGroup) {
        if (!form) {
            return;
        }

        if (this.texts && this.texts.length) {
            const texts = this.form.get('texts') as FormArray;
            const missingLangs = this.texts.filter(
                lang => !texts.controls.some(ctrl => ctrl.value.languageCode === lang.languageCode)
            );

            for (const missingLang of missingLangs) {
                texts.push(this.fb.group({
                    languageCode: missingLang.languageCode,
                    title: missingLang.title,
                    message: missingLang.message
                }));
            }

            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < texts.controls.length; i++) {
                if (!this.texts.some(text => text.languageCode === texts.controls[i].value.languageCode)) {
                    texts.removeAt(i);
                }
            }

            const enText = this.texts.find(txt => txt.languageCode === 'en');
            texts.patchValue([enText]);
        }
    }

    save(form: FormGroup) {
        this.isSubmitted = true;
        if (form.invalid) {
            return;
        }

        const texts = form.value.texts.map(text => {
            text.messageId = this.message._id;
            const originalText = this.texts.find(t => t.languageCode === text.languageCode);
            text._id = originalText ? originalText._id : undefined;
            return text;
        });

        if (texts && texts.length) {
            this.saved.emit(texts);
        }
    }

    removeText(text: APLMessageText, index: number) {
        const modal = this.modal.open(WicConfirmModalComponent);
        modal.componentInstance.modalTitle = 'Delete Message for ' + text.languageCode.toLocaleUpperCase();
        modal.componentInstance.message = 'Aer you sure you wish to delete this message translation?';
        modal.result.then(() => {
            if (text.languageCode === 'en' && index === 0) {
                return;
            }

            const content = this.form.get('texts') as FormArray;
            content.removeAt(index);

            text = this.texts.find(t => t.languageCode === text.languageCode);
            if (!text) {
                return;
            }

            if (text._id) {
                this.deleted.emit(text);
            }
        }, () => {
        });
    }

    tabBeforeChange($event: NgbTabChangeEvent) {
        if (!this.form) {
            return;
        }

        if ($event.nextId === 'addLang') {
            $event.preventDefault();

            const content = this.form.get('texts') as FormArray;
            const modal = this.translations.openSelectLangModal(content);
            modal.result.then(langCode => {
                if (!langCode) {
                    return;
                }

                const text: APLMessageText = {
                    messageId: this.message._id,
                    languageCode: langCode.id,
                    title: '',
                    message: ''
                };
                this.added.emit(text);
                content.push(this.fb.group({
                    languageCode: langCode.id,
                    title: '',
                    message: ''
                }));
                this.cd.detectChanges();
                setTimeout(() => {
                    this.textTabs.select((content.length - 1).toString());
                }, 200);
            }, () => {
            });
        }
    }

    contentChanged() {
    }
}
