<div class="wic-nav" [ngClass]=" 'current-app-' + currentApp?.id " *ngIf="showLayout">
    <nav class="navbar navbar-dark navbar-expand-md fixed-top">
        <button class="btn btn-show-menu bg-transparent text-white" (click)="onToggleNav()">
            <i class="fa fa-bars fa-fw"></i>
        </button>
        <a class="navbar-brand justify-self-start" href="/admin">
            WIC Shopper
        </a>

        <div *ngIf="currentApp" class="btn-group" ngbDropdown role="group" aria-label="Account menu">
            <a class="nav-link text-light" ngbDropdownToggle>
                <i class="fa fa-user"></i>
                {{currentApp.name}}
            </a>
            <div *ngIf="availableApps?.length" class="dropdown-menu" ngbDropdownMenu>
                <a *ngFor="let app of availableApps" class="dropdown-item" (click)="appSelected.emit(app)">
                    {{ app.name }}
                </a>
            </div>
        </div>
        <div *ngIf="auth.user$ | async as user" class="btn-group" ngbDropdown role="group" aria-label="Account menu">
            <a class="nav-link text-light" ngbDropdownToggle>
                <i class="fa fa-user"></i>
                {{user.name}}
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" routerLink="/admin/profile">
                    <i class="fa fa-fw fa-id-card-o"></i>
                    Profile
                </a>
                <a class="dropdown-item" (click)="auth.logout()">
                    <i class="fa fa-fw fa-sign-out"></i>
                    Log Out
                </a>
            </div>
        </div>
    </nav>
    <wic-sidebar [menuItems]="menuItems" [areaPath]="areaPath" [showNarrow]="showNarrow"></wic-sidebar>
    <wic-full-screen-menu
        [menuItems]="menuItems" [areaPath]="areaPath" [display]="showFullMenu"
        (navigated)="onSelected()" (closed)="onSelected()"
    ></wic-full-screen-menu>
</div>
