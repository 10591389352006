<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <span class="badge badge-info" *ngIf="transform?._id">
                {{transform._id}}
            </span>
            {{transform?._id ? 'Edit' : 'Create'}} APL Record Transformation
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label for="name" class="col-4 col-form-label">Name</label>
            <div class="col-8">
                <input class="form-control" formControlName="name" name="name" id="name">
            </div>
        </div>
        <div class="form-group row">
            <label for="rule" class="col-4 col-form-label">Rule</label>
            <div class="col-8">
                <input class="form-control" formControlName="rule" name="rule" id="rule">
            </div>
        </div>
        <div class="form-group row">
            <label for="transform" class="col-4 col-form-label">Transformation</label>
            <div class="col-8">
                <textarea class="form-control" rows="2" formControlName="transform" name="transform" id="transform"></textarea>
            </div>
        </div>

        <div class="form-group row">
            <label for="isEnabled" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isEnabled" formControlName="isEnabled">
                    <label class="form-check-label" for="isEnabled">Is Enabled</label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
