import {buildState, EntityActionTypes, IEntityState, ofEntityType} from '@briebug/ngrx-auto-entity';
import {DeviceLogEntry} from '@models';
import {Actions, createEffect} from '@ngrx/effects';
import {Action, createAction, createReducer, createSelector, on, props} from '@ngrx/store';

export const levelShown = createAction(
    '[Log Viewer] Level shown',
    props<{ level: string }>()
);

export const levelHidden = createAction(
    '[Log Viewer] Level hidden',
    props<{ level: string }>()
);

export const tagAdded = createAction(
    '[Log Viewer] Tag added',
    props<{ tag: string }>()
);

export const tagRemoved = createAction(
    '[Log Viewer] Tag removed',
    props<{ tag: string }>()
);


export const {initialState, facade: DeviceLogEntryFacadeBase, entityState} = buildState(DeviceLogEntry, {
    levels: ['info', 'warn', 'error'],
    tags: []
});

export interface IDeviceEntryState extends IEntityState<DeviceLogEntry> {
    levels: string[];
    tags: string[];
}

const reduce = createReducer(
    initialState,
    on(levelShown, (state, {level}) => ({
        ...state,
        levels: (state.levels || []).includes(level) ? state.levels : [...(state.levels || []), level]
    })),
    on(levelHidden, (state, {level}) => ({
        ...state,
        levels: (state.levels || []).filter(currentLevel => currentLevel !== level)
    })),
    on(tagAdded, (state, {tag}) => ({
        ...state,
        tags: (state.tags || []).includes(tag) ? state.tags : [...(state.tags || []), tag]
    })),
    on(tagRemoved, (state, {tag}) => ({
        ...state,
        tags: (state.tags || []).filter(currentTag => currentTag !== tag)
    })),
);

export function deviceLogEntryReducer(state = initialState, action: Action): IDeviceEntryState {
    return reduce(state, action);
}

export const mapToLevels = (state: IDeviceEntryState) => state.levels || [];

export const currentLevels = createSelector(
    entityState,
    mapToLevels
);

export const mapToTags = (state: IDeviceEntryState) => state.tags || [];

export const currentTags = createSelector(
    entityState,
    mapToTags
);
