import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {APLImportConfig} from '@models/APLImportConfig';

@Component({
    selector: 'wic-apl-import-detail',
    templateUrl: './wic-apl-import-detail.component.html',
    styleUrls: ['./wic-apl-import-detail.component.sass']
})
export class WicAplImportDetailComponent implements OnChanges {
    @Input() config: APLImportConfig;
    @Output() edited = new EventEmitter<APLImportConfig>();
    @Output() run = new EventEmitter<APLImportConfig>();
    @Output() check = new EventEmitter<APLImportConfig>();

    showPass = false;
    runDisabled = true;

    private runCheckTimer = null;

    ngOnChanges() {
        if (!this.config) { return; }

        this.runDisabled = this.config.manualRun && this.config.manualRun.id !== 'not_found';
        if (this.runDisabled) {
            if (!this.runCheckTimer) {
                this.runCheckTimer = setInterval(() => {
                    this.check.emit(this.config);
                }, 10000);
            }
        } else {
            clearInterval(this.runCheckTimer);
            this.runCheckTimer = null;
        }
    }

    dots(length) {
        return new Array(length);
    }

    startRun() {
        if (!this.config.manualRun || this.config.manualRun.id === 'not_found') {
            this.runDisabled = true;
            this.run.emit();
        }
    }
}
