import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WikiArticle } from '@models';
import { Sorter } from '../../../util';

@Component({
    selector: 'wic-wiki-table',
    templateUrl: './wic-wiki-table.component.html',
    styleUrls: ['./wic-wiki-table.component.sass'],
})
export class WicWikiTableComponent implements OnInit {
    @Input() articles: WikiArticle[];
    @Output() refreshed = new EventEmitter();
    @Output() added = new EventEmitter();

    wikiSorter = new Sorter(() => this.articles, sorted => this.articles = sorted);

    constructor() { }

    ngOnInit() {
    }

}
