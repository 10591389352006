import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {APLJob} from '@models/APLJob';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class APLJobService implements IAutoEntityService<APLJob> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<APLJob[]> {
        const key = criteria.authorityId ? '/' + criteria.authorityId :
            criteria.configId ? '/' + criteria.configId : '';
        const url = `/admin/apl/imports/config/jobs${key}`;
        return this.http.get<APLJob[]>(environment.apiRoot + url);
    }

    create(entityInfo: IEntityInfo, entity: APLJob): Observable<APLJob> {
        return this.http.post<APLJob>(environment.apiRoot + '/admin/apl/imports/config/jobs',
            entity
        );
    }

    delete(entityInfo: IEntityInfo, entity: APLJob): Observable<APLJob> {
        return this.http.delete<APLJob>(environment.apiRoot +
            `/admin/apl/imports/config/jobs/${entity._id}`
        ).pipe(map(() => entity));
    }
}
