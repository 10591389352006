import { Component, OnInit } from '@angular/core';
import { AuthoritiesFacade } from '@facades/authorities.facade';
import { ReportingFacade } from '@facades/reporting.facade';
import { ModernStatisticsRecord } from '@models/ModernStatisticsRecord';
import { Store } from '@ngrx/store';
import { IAppState } from '@state/app.state';
import {
  modernStatisticsCriteria,
  modernStatisticsLoadDuration,
  modernStatisticsLoadedAt,
  modernStatisticsLoadStatus,
  modernStatisticsReport,
  modernStatisticsTotals,
} from '@state/reporting/modernstatistics.state';
import { StatisticsReportingJobsFacade } from '@state/reporting/statistics-reporting-jobs.facade';
import { Observable } from 'rxjs';
import { IAuthorityInfo } from './models';
import { IDateRange } from './wic-authorities-legacy-report/wic-authorities-legacy-report.component';

@Component({
  selector: 'wic-authorities',
  templateUrl: './wic-authorities.component.html',
  styleUrls: ['./wic-authorities.component.sass'],
})
export class WicAuthoritiesComponent implements OnInit {
  authoritiesInfo$: Observable<IAuthorityInfo>;

  modernStatisticsReport$: Observable<ModernStatisticsRecord[]>;
  modernStatisticsTotals$: Observable<ModernStatisticsRecord>;
  modernStatisticsCriteria$: Observable<{ startDate: string; endDate: string }>;
  modernStatisticsLoadedAt$: Observable<number>;
  modernStatisticsLoadDuration$: Observable<string>;
  modernStatisticsLoadingStatus$: Observable<boolean>;

  hideSelf = false;

  constructor(
    private store: Store<IAppState>,
    private authoritiesFacade: AuthoritiesFacade,
    public reporting: ReportingFacade,
    public reportingJobs: StatisticsReportingJobsFacade
  ) {}

  ngOnInit() {
    this.authoritiesInfo$ = this.authoritiesFacade.infoReport;

    this.modernStatisticsReport$ = this.store.select(modernStatisticsReport);
    this.modernStatisticsTotals$ = this.store.select(modernStatisticsTotals);
    this.modernStatisticsCriteria$ = this.store.select(modernStatisticsCriteria);
    this.modernStatisticsLoadedAt$ = this.store.select(modernStatisticsLoadedAt);
    this.modernStatisticsLoadDuration$ = this.store.select(modernStatisticsLoadDuration);
    this.modernStatisticsLoadingStatus$ = this.store.select(modernStatisticsLoadStatus);

    this.authoritiesFacade.loadAll();
  }

  dateRangeChanged(dateRange: IDateRange) {
    const beginDate = `${dateRange.startDate.year}-${dateRange.startDate.month}-${dateRange.startDate.day}`;
    const endDate = `${dateRange.endDate.year}-${dateRange.endDate.month}-${dateRange.endDate.day}`;
    this.reportingJobs.createReportingJob(beginDate, endDate);
  }

  modernDateRangeChanged(dateRange: IDateRange) {
    const startDate = `${dateRange.startDate.year}-${dateRange.startDate.month}-${dateRange.startDate.day}`;
    const endDate = `${dateRange.endDate.year}-${dateRange.endDate.month}-${dateRange.endDate.day}`;
    this.reporting.loadModern({ startDate, endDate });
  }
}
