import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {ObjectId} from '@models/types';

@Entity({
    modelName: 'ServerSettings'
})
export class ServerSettings {
    // tslint:disable-next-line:variable-name
    @Key _id: ObjectId;
    authorityId: number;
    productImageUrl: string;
    microBlogImageUrl: string;
}
