import {buildState} from '@briebug/ngrx-auto-entity';
import {APLMessageText} from '@models';

export const {initialState, selectors, facade: APLMessageTextFacadeBase} = buildState(APLMessageText);

export const {
    selectAll: allAPLMessageTexts,
    selectIsLoading: aplMessageTextsLoading,
    selectIsSaving: aplMessageTextSaving,
    selectIsDeleting: aplMessageTextDeleting,
    selectCurrentEntity: currentAPLMessageText
} = selectors;

export function aplMessageTextReducer(state = initialState) {
    return state;
}
