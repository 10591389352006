import { buildState } from "@briebug/ngrx-auto-entity";
import { SubcategoryTranslationGroup } from "./subcategory-translation-group.model";
import { Action, createReducer } from "@ngrx/store";


export const {
  initialState: initialSubcategoryTranslationGroupsState,
  facade: SubcategoryTranslationGroupsFacadeBase,
  actions: {
    loadAll: loadAllSubcategoryTranslationGroups,
    create: createSubcategoryTranslationGroup,
    createSuccess: createSubcategoryTranslationGroupSuccess,
    replace: replaceTranslationGroup,
    replaceSuccess: replaceTranslationGroupSuccess,
    delete: deleteTranslationGroup,
    deleteSuccess: deleteTranslationGroupSuccess,
  },
  selectors: {
    selectAll: allSubcategoryTranslationGroups,
  }
} = buildState(SubcategoryTranslationGroup);

const reduce = createReducer(initialSubcategoryTranslationGroupsState);

export function subcategoryTranslationGroupsReducer(state = initialSubcategoryTranslationGroupsState, action: Action) {
  return reduce(state, action);
}

export const SUBCATEGORY_TRANSLATION_GROUPS_FEATURE_KEY = 'subcategoryTranslationGroup';