import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {buildState, Create, Delete, IEntityState, Update} from '@briebug/ngrx-auto-entity';
import {User} from '@models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, createAction, createReducer, props, Store} from '@ngrx/store';
import {AppState} from '@state/app.state';
import {UsersUIService} from '@state/users/users-ui.service';
import {defer, EMPTY, from, iif, of} from 'rxjs';
import {catchError, exhaustMap, filter, map, switchMap, tap} from 'rxjs/operators';

export const {
    initialState: initialUserState,
    facade: UsersFacadeBase
} = buildState(User);


export const addNewUser = createAction(
    '[Users] Add new user'
);

export const editExistingUser = createAction(
    '[Users] Edit existing user',
    props<{ user: User }>()
);

export const confirmDeleteUser = createAction(
    '[Users] Confirm delete',
    props<{ user: User }>()
);

const reduce = createReducer(
    initialUserState
);

export function userReducer(state = initialUserState, action: Action): IEntityState<User> {
    return reduce(state, action);
}

@Injectable()
export class UserEffects {
    constructor(private actions$: Actions, private store: Store<AppState>, private router: Router, private usersUI: UsersUIService) {
    }

    addNewUser$ = createEffect(
        () => this.actions$.pipe(
            ofType(addNewUser),
            switchMap(() => from(this.usersUI.showUserModal()).pipe(
                catchError(() => of(null)),
                filter(user => !!user),

                map(user => new Create(User, user)),
            )),
        ),
    );

    editExistingUser$ = createEffect(
        () => this.actions$.pipe(
            ofType(editExistingUser),
            switchMap(({user}) => from(this.usersUI.showUserModal(user)).pipe(
                switchMap(edited =>
                    iif(
                        () => !!edited.newPassword,
                        defer(() => from(this.usersUI.showPasswordChallenge()).pipe(
                            filter(isValid => isValid),
                            map(() => ({...edited, newPasswordCheck: edited.newPassword}))
                        )),
                        of(edited)
                    )
                ),
                catchError(() => of(null)),
                filter(edited => !!edited),
                map(edited => new Update(User, edited))
            )),
        ),
    );

    confirmUserDeletion$ = createEffect(
        () => this.actions$.pipe(
            ofType(confirmDeleteUser),
            exhaustMap(({user}) =>
                from(this.usersUI.showConfirmDeleteModal(user)).pipe(
                    filter(result => result === 'yes'),
                    map(() => new Delete(User, user)),
                    tap(() => this.router.navigateByUrl('/admin/shared/users'))
                )
            )
        )
    );
}
