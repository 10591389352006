import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'DeviceLogEntry',
    comparer: (a: DeviceLogEntry, b: DeviceLogEntry) => b.timestamp.localeCompare(a.timestamp)
})
export class DeviceLogEntry {
    // tslint:disable-next-line:variable-name
    @Key _id: string;
    udid: string;
    sessionId: string;
    timestamp: string;
    type: string;
    tags: string[];
    message: string;
    shippedAt: string;
}
