import {createFeatureSelector, createSelector} from '@ngrx/store';

import {Authority} from '@models';
import {IGroupedLookup, ILookup} from '@models/types';
import {Dictionary} from '@ngrx/entity/src/models';
import {adapter, IAuthoritiesState} from './authorities.reducers';

export const selectAuthoritiesState = createFeatureSelector<IAuthoritiesState>('authorities');

export const {
    selectAll: allAuthorities,
    selectEntities: authoritiesEntities,
    selectIds: authoritiesIds,
    selectTotal: authoritiesCount,
} = adapter.getSelectors(selectAuthoritiesState);

export const selectCurrentAuthorityId = createSelector(
    selectAuthoritiesState,
    (state: IAuthoritiesState) => state.selectedAuthorityId
);

export const selectCurrentAuthority = createSelector(
    authoritiesEntities,
    selectCurrentAuthorityId,
    (entities: Dictionary<Authority>, id: number) => {
        return id && entities[id];
    }
);

export const allActiveAuthorities = createSelector(
    allAuthorities,
    (authorities: Authority[]): Authority[] =>
        authorities.filter(authority => authority.isActive)
);

export const allAuthoritiesAsLookup = createSelector(
    allAuthorities,
    (authorities: Authority[]): ILookup[] => authorities
        .map(authority => ({
            id: authority.id,
            name: `(${authority.id}) ${authority.name}`,
        }))
);

export const allAuthoritiesAsGroupedLookup = createSelector(
    allAuthorities,
    (authorities: Authority[]): IGroupedLookup[] => authorities
        .map(authority => ({
            value: authority.id,
            group: authority.name.substr(0, 1),
            text: `(${authority.id}) ${authority.name}`,
        }))
);

export const activeAuthorities = createSelector(
    allAuthorities,
    (authorities: Authority[]): Authority[] => authorities.filter(authority => authority.isActive)
);

export const activeAuthoritiesAsLookup = createSelector(
    activeAuthorities,
    (authorities: Authority[]): ILookup[] => authorities
        .map(authority => ({
            id: authority.id,
            name: authority.name,
        }))
);

export const activeProviderAuthorities = createSelector(
    activeAuthorities,
    (authorities: Authority[]): Authority[] => authorities.filter(authority => authority.isProvider)
);

export const activeProviderAuthoritiesAsLookup = createSelector(
    activeProviderAuthorities,
    (authorities: Authority[]): ILookup[] => authorities
        .map(authority => ({
            id: authority.id,
            name: authority.name,
        }))
);
