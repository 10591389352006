import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthorityFlag} from '@models';

@Component({
    selector: 'wic-authority-flags-table',
    templateUrl: './wic-authority-flags-table.component.html',
    styleUrls: ['./wic-authority-flags-table.component.sass']
})
export class WicAuthorityFlagsTableComponent implements OnInit {
    @Input() flags: AuthorityFlag[];
    @Output() refreshed = new EventEmitter();
    @Output() edited = new EventEmitter<AuthorityFlag>();

    constructor() {
    }

    ngOnInit() {
    }
}
