<div class="container">
    <router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>
</div>

<div *ngIf="!hideSelf">
    <div class="row">
        <div class="col-md-12">
            <wic-enhanced-devices-table
                [devices]="enhancedDevices.sorted$ | async"
                [showToolbar]="true"
                (toggled)="enhancedDevices.toggle($event)"
                (refreshed)="enhancedDevices.loadAll()"
                (deleted)="enhancedDevices.delete($event)">
            </wic-enhanced-devices-table>
        </div>
    </div>
</div>
