import {buildState} from '@briebug/ngrx-auto-entity';
import {AuthorityFlag} from '@models';

const {initialState, selectors} = buildState(AuthorityFlag);

export const {
    selectAll: allAuthorityFlags
} = selectors;

export function authorityFlagReducer(state = initialState) {
    return state;
}
