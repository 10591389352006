import { Component, EventEmitter, Input, Output } from "@angular/core";
import { EnhancedIssue } from "../feature/enhanced-issue.model";

@Component({
  selector: 'wic-enhanced-issues',
  templateUrl: './wic-enhanced-issues.component.html',
})
export class WicEnhancedIssuesComponent {
  @Input() enhancedIssues: EnhancedIssue[];
  @Output() addIssue = new EventEmitter();
  @Output() editIssue = new EventEmitter<EnhancedIssue>();
  @Output() deleteIssue = new EventEmitter<EnhancedIssue>();
  @Output() movedUp = new EventEmitter<EnhancedIssue>();
  @Output() movedDown = new EventEmitter<EnhancedIssue>();

  constructor() {}

  up(enhancedIssue: EnhancedIssue) {
    if (enhancedIssue.displayOrder > 0) {
        this.movedUp.emit(enhancedIssue);
    }
  }

  down(enhancedIssue: EnhancedIssue) {
    if (enhancedIssue.displayOrder < this.enhancedIssues.length - 1) {
      this.movedDown.emit(enhancedIssue);
    }
  }
}