import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity('AuthorityLocation')
export class AuthorityLocation {
    @Key locationId: string;
    authorityId: string;
    clinicGroup: string;
    status: number;
    isActive: boolean;
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phoneNumber1: string;
    phoneNumber2: string;
    emailAddress: string;
    webAddress: string;
    longitude: number;
    latitude: number;
    locationDetails: string;
}
