import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { exhaustMap, first, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { login, logout } from './auth.actions';

@Injectable()
export class AuthEffects {
  loginUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(login),
        exhaustMap(({ previousUrl }) => this.auth.loginWithRedirect())
      ),
    { dispatch: false }
  );

  setToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        switchMap(() => this.auth.user$.pipe(first())),
        switchMap(() => this.auth.getAccessTokenSilently()),
        tap(res => localStorage.setItem('token', res)),
      ),
    { dispatch: false }
  );

  logoutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => this.auth.logout({ returnTo: `${environment.host}/login` }))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private auth: AuthService) {}
}
