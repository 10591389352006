export class UsageStatisticsRecord {
    authorityId: number;
    authority: string;
    cardRegistrations: number;
    dailyRxUsers: number;
    uniqueRxUsers: number;
    devRegistrations: number;
    devActivity: number;
    devActivityTotal: number;
    uniqueItemLookups: number;
    totalItemCount: number;
    rxItemCount: number;
    noRxItemCount: number;
}
