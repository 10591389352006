import {Component, Input} from '@angular/core';
import {AppointmentImport} from '@models';

@Component({
  selector: 'wic-appointment-import-detail',
  templateUrl: './wic-appointment-import-detail.component.html',
  styleUrls: ['./wic-appointment-import-detail.component.sass']
})
export class WicAppointmentImportDetailComponent {
    @Input() import: AppointmentImport;
}
