<div class="card mt-4">
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                <h5 class="float-left mb-0" *ngIf="!showFilter">Micro-Blogs</h5>
                <form [formGroup]="form" *ngIf="showFilter">
                    <div class="row">
                        <div class="col-6">

                        </div>
                    </div>
                </form>
            </div>
            <div class="col-1" *ngIf="!showToolbar">
                <i class="fa fa-plus" (click)="added.emit()"></i>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="!!microBlogs?.length">
            <thead>
            <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Created At</th>
                <th>Activation</th>
                <th>Expiration</th>
                <th>Is Active</th>
                <th class="text-right"><i class="fa fa-wrench"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let microBlog of microBlogs">
                <td><a [routerLink]="['view', microBlog._id]">{{microBlog.title}}</a></td>
                <td>{{microBlog.type | titlecase}}</td>
                <td><span class="text-monospace">{{microBlog.dateCreated}}</span></td>
                <td><span class="badge badge-success text-light">{{microBlog.activationDate}}</span></td>
                <td><span class="badge badge-danger text-light">{{microBlog.expirationDate}}</span></td>
                <td>
                    <span class="badge badge-success text-light" *ngIf="microBlog.isActive">Active</span>
                    <span class="badge badge-danger text-light" *ngIf="!microBlog.isActive">Inactive</span>
                </td>
                <td class="text-right">
                    <i class="fa fa-trash" (click)="deleted.emit(microBlog)"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!microBlogs?.length" class="card-body">
        <p *ngIf="!isLoading"><em>There are no micro-blogs.</em></p>
        <p *ngIf="isLoading"><i class="fa fa-spin fa-spinner"></i> Loading micro-blogs...</p>
    </div>
    <div class="card-footer" *ngIf="showToolbar">
        <div class="row">
            <div class="col-md-6">
                <!--<a class="btn btn-outline-secondary float-left" role="button"-->
                <!--[download]="getFilename()" [href]="buildDownloadUri(demoAccounts)">-->
                <!--<i class="fa fa-download"></i> Download as CSV-->
                <!--</a>-->
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
                <button class="btn btn-outline-primary" (click)="added.emit()">
                    <i class="fa fa-plus"></i> Add Blog
                </button>
            </div>
        </div>
    </div>
</div>
