<div class="row mt-4" *ngIf="report?.deviceInfo">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    Screenshot
                </h5>
            </div>
            <img class="card-image w-100" [src]="report.screenshot" *ngIf="report.screenshot">
            <div class="card-body" *ngIf="!report.screenshot">A screenshot was not included with this error report.</div>
        </div>
    </div>
</div>

