<div class="filter-container">
    <div>
        <span *ngFor="let option of selected" class="badge filter mr-1"
              [ngStyle]="{ backgroundColor: getColor(option) }"
              (click)="emitChanges(option)">
            {{ option }}
            <i class="fa fa-times filter-remove"></i>
        </span>
    </div>

    <div>
        <button *ngIf="(displayOptions | keyvalue).length !== 0" #addButton
                class="btn btn-outline-secondary" (click)="vertical.instance.show()">
            <i class="fa fa-fw fa-plus" ></i>

            <mbsc-popup [options]="verticalSettings" #vertical="mobiscroll" [anchor]="addButton">
                <mbsc-listview [options]="lvSettings">
                    <mbsc-listview-item *ngFor="let item of displayOptions | keyvalue"
                                        (click)="vertical.instance.hide(); emitChanges(item.key)">
                        {{ item.key }}
                    </mbsc-listview-item>
                </mbsc-listview>
            </mbsc-popup>
        </button>
    </div>
</div>
