<div class="card mt-5 mb-5">
    <div class="card-header">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <div class="input-group">
                            <input class="form-control" placeholder="Search" formControlName="criteria">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="form.patchValue({criteria: null})" type="button">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <ngx-bootstrap-multiselect
                        [options]="authorities"
                        [texts]="{defaultTitle: 'Select Authority'}"
                        [settings]="{
                            dynamicTitleMaxItems: 1,
                            selectionLimit: 1,
                            autoUnselect: true,
                            containerClasses: 'w-100',
                            buttonClasses: 'btn btn-outline-secondary w-100 text-left'
                        }"
                        formControlName="authority">
                    </ngx-bootstrap-multiselect>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <div class="input-group">
                            <input class="form-control" placeholder="Start (yyyy-mm-dd)"
                                   name="startDate" formControlName="startDate"
                                   ngbDatepicker #sd="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="sd.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-outline-secondary" (click)="form.patchValue({startDate: null})" type="button">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <div class="input-group">
                            <input class="form-control" placeholder="End (yyyy-mm-dd)"
                                   name="endDate" formControlName="endDate"
                                   ngbDatepicker #ed="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="ed.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-outline-secondary" (click)="form.patchValue({endDate: null})" type="button">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="appointments?.length">
            <thead>
            <tr>
                <th class="text-nowrap">Authority ID</th>
                <th class="text-nowrap">Card No./HHID</th>
                <th class="text-nowrap">Location Name</th>
                <th class="text-nowrap text-center">Appointment Time</th>
                <th class="text-center">City</th>
                <th class="text-center">State</th>
                <th class="text-center">Zip</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let appointment of appointments">
                <td class="">{{appointment.authorityId}}</td>
                <td class="dynamic-filter">
                    <a [routerLink]="['view', appointment._id]">{{appointment.householdId || appointment.cardNumber}}</a>
                    &nbsp;<i class="fa fa-filter" (click)="form.patchValue({criteria: appointment.householdId || appointment.cardNumber})"></i>
                </td>
                <td class="dynamic-filter">
                    <a [routerLink]="['view', appointment._id]">{{appointment.locationName}}</a>
                    &nbsp;<i class="fa fa-filter" (click)="form.patchValue({criteria: appointment.locationName})"></i>
                </td>
                <td class="text-center">{{appointment.appointmentTime}}</td>
                <td class="text-center dynamic-filter">{{appointment.city}}
                    <i class="fa fa-filter" (click)="form.patchValue({criteria: appointment.city})"></i></td>
                <td class="text-center dynamic-filter">{{appointment.state}}
                    <i class="fa fa-filter" (click)="form.patchValue({criteria: appointment.state})"></i></td>
                <td class="text-center dynamic-filter">{{appointment.zip}}
                    <i class="fa fa-filter" (click)="form.patchValue({criteria: appointment.zip})"></i></td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="appointments?.length">
        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-8">
                <ngb-pagination
                    class="d-flex justify-content-center"
                    [collectionSize]="totalCount"
                    [pageSize]="50"
                    [maxSize]="10"
                    [rotate]="true"
                    [ellipses]="true"
                    [page]="currentPage"
                    (pageChange)="refresh($event)"
                    aria-label="Pagination">
                </ngb-pagination>
            </div>
            <div class="col-md-2">

            </div>
        </div>
    </div>
    <div *ngIf="!appointments?.length" class="card-body">
        <p><em>There are no appointments.</em></p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6">
                <!--<a class="btn btn-outline-secondary float-left" role="button"-->
                   <!--[download]="getFilename()" [href]="buildDownloadUri(appointments)">-->
                    <!--<i class="fa fa-download"></i> Download as CSV-->
                <!--</a>-->
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refresh()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
            </div>
        </div>
    </div>
</div>
