import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthorityFlag} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wic-authority-flag-edit',
    templateUrl: './wic-authority-flag-edit.component.html',
    styleUrls: ['./wic-authority-flag-edit.component.sass']
})
export class WicAuthorityFlagEditComponent implements OnInit {
    @Input() flag: AuthorityFlag;

    form: FormGroup;
    submitted = false;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);
        this.populateForm(this.flag);
    }

    private buildForm(formBuilder) {
        const form = formBuilder.group({
            Name: [null, Validators.required],
            PropertyName: [null, Validators.required],
            Bit: [null, Validators.required],
            Mask: [null, Validators.required],
            IsEditable: [null, Validators.required],
            IsEnabled: [null, Validators.required],
        });

        return form;
    }

    populateForm(flag) {
        this.form.patchValue({
            ...flag
        });
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        const flag = {
            ...value,
            FlagID: this.flag.FlagID
        };

        this.activeModal.close(flag);
    }

}
