<div class="card mt-5" *ngIf="!!conflicts && conflicts?.length">
    <div class="card-header">
        <div class="row">
            <div class="col-12">
                <h5 class="float-left mb-0">{{ title }}</h5>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0">
            <thead>
            <tr>
                <th class="text-nowrap">Category</th>
                <th class="text-nowrap">Sub-Category</th>
                <th class="text-nowrap">{{ valCountLabel }}</th>
                <th class="text-nowrap">{{ valLabel }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let conflict of conflicts">
                <td class="">
                    <span class="badge badge-info text-white">{{conflict.Category}}</span>
                </td>
                <td class="">
                    <span class="badge badge-info text-white">{{conflict.SubCategory}}</span>
                </td>
                <td>
                    {{conflict.ValCount}}
                </td>
                <td class="text-nowrap">
                    <span class="text-monospace text-nowrap">{{conflict.Vals}}</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
