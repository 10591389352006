import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'Partner'
})
export class Partner {
    @Key PartnerID: number;
    PartnerName: string;
    IsActive: boolean;
}
