<div class="container">
    <router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>
</div>

<div *ngIf="!hideSelf">
    <div class="row">
        <div class="col-md-12">
            <wic-authorities-basic-report
                [authoritiesInfo]="authoritiesInfo$ | async">
            </wic-authorities-basic-report>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-12">
            <wic-authorities-legacy-report
                [isLoading]="reportingJobs.isLoading$ | async"
                [loadDuration]="reportingJobs.loadingDuration$ | async"
                [loadedAt]="reportingJobs.loadEndDate$ | async"
                [report]="reportingJobs.usageStatisticsReports$ | async"
                [criteria]="reportingJobs.reportCriteria$ | async"
                [status]="reportingJobs.reportingJobStatus$ | async"
                [failed]="reportingJobs.reportFailed$ | async"
                (dateRangeChanged)="dateRangeChanged($event)">
            </wic-authorities-legacy-report>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-12">
            <wic-modern-statistics-report
                [isLoading]="modernStatisticsLoadingStatus$ | async"
                [report]="modernStatisticsReport$ | async"
                [totals]="modernStatisticsTotals$ | async"
                [criteria]="modernStatisticsCriteria$ | async"
                [loadedAt]="modernStatisticsLoadedAt$ | async"
                [loadDuration]="modernStatisticsLoadDuration$ | async"
                (dateRangeChanged)="modernDateRangeChanged($event)">
            </wic-modern-statistics-report>
        </div>
    </div>
</div>
