import {Pipe, PipeTransform} from '@angular/core';
import {DeviceLogEntry} from '@models';

@Pipe({name: 'filterByTags'})
export class FilterByTagsPipe implements PipeTransform {
    transform(entries: DeviceLogEntry[], tags: string[]): DeviceLogEntry[] {
        return (!!tags && !!tags.length && !!entries && !!entries.length)
            ? entries.filter(entry =>
                tags.every(tag => entry.tags.includes(tag))
            )
            : entries;
    }
}
