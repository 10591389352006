import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WikiArticle } from '@models/WikiArticle';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@state/app.state';
import { FetchWikiBySlugAction } from '@state/wiki/wiki.actions';
import { currentWikiBySlug } from '@state/wiki/wiki.selectors';
import { NgxMdService } from 'ngx-md';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';

@Component({
    selector: 'wic-wiki-article',
    templateUrl: './wic-wiki-article.component.html',
    styleUrls: ['./wic-wiki-article.component.sass'],
})
export class WicWikiArticleComponent implements OnInit {
    article$: Observable<WikiArticle>;

    constructor(private markdown: NgxMdService, private store: Store<IAppState>, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        const defaultPrefixes = ['mailto:', 'http://', 'https://'];
        const linkRenderer = this.markdown.renderer.link.bind(this.markdown.renderer);
        this.markdown.renderer.link = (href: string, title: string, text: string) => {
            if (defaultPrefixes.some(prefix => href.startsWith(prefix))) {
                return linkRenderer(href, title, text);
            }

            return `<a href='/admin/wiki/articles/${href}' title='${title}'>${text}</a>`;
        };

        this.article$ = this.store.pipe(select(currentWikiBySlug));

        this.activatedRoute.params.pipe(
            filter(params => !!params.slug),
            map(params => params.slug)
        ).subscribe(slug => {
            this.store.dispatch(new FetchWikiBySlugAction(slug));
        });
    }
}
