import { Injectable } from '@angular/core';
import { Deselect, Select, SelectByKey } from '@briebug/ngrx-auto-entity';
import { MicroBlog } from '@models';
import { ILookup, ObjectId } from '@models/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@state/app.state';
import {
  allMicroBlogs,
  createMBlog,
  currentMicroBlog,
  deleteMBlog,
  isMicroBlogsLoading,
  loadMBlog,
  loadMBlogs,
  updateMBlog,
} from '@state/micro-blogs.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WicMicroBlogEditComponent } from '../wic-admin/wic-micro-blogs/wic-micro-blog-edit/wic-micro-blog-edit.component';
import { WicConfirmModalComponent } from '../wic-confirm-modal/wic-confirm-modal.component';

@Injectable({
  providedIn: 'root',
})
export class MicroBlogsFacade {
  constructor(private store: Store<IAppState>, private modal: NgbModal) {}

  // region Selections
  get all(): Observable<MicroBlog[]> {
    return this.store.pipe(select(allMicroBlogs));
  }

  get current(): Observable<MicroBlog> {
    return this.store.pipe(select(currentMicroBlog));
  }

  get isLoading(): Observable<boolean> {
    return this.store.pipe(select(isMicroBlogsLoading));
  }

  selectGroups(): Observable<ILookup[]> {
    return this.all.pipe(
      map(blogs => {
        const set = new Set(
          blogs.reduce((groups, blog) => {
            return [...groups, ...(blog.groups || [])];
          }, [])
        );
        return [...set];
      }),
      map(groups =>
        groups.map(group => ({
          id: group,
          name: group,
        }))
      )
    );
  }
  // endregion

  // region Dispatches
  setCurrent(microBlog: MicroBlog) {
    this.store.dispatch(new Select(MicroBlog, microBlog));
  }

  select(id: number) {
    this.store.dispatch(new SelectByKey(MicroBlog, id));
  }

  deselect() {
    this.store.dispatch(new Deselect(MicroBlog));
  }

  load(id: ObjectId) {
    this.store.dispatch(loadMBlog({ id }));
  }

  loadAll() {
    this.store.dispatch(loadMBlogs());
  }

  save(microBlog: MicroBlog) {
    if (!microBlog) {
      return;
    }

    this.store.dispatch(microBlog._id ? updateMBlog({ microBlog }) : createMBlog({ microBlog }));
  }

  delete(microBlog: MicroBlog) {
    if (!microBlog) {
      return;
    }

    const modal = this.modal.open(WicConfirmModalComponent);
    modal.componentInstance.modalTitle = 'Delete Micro Blog';
    modal.componentInstance.message = 'Are you sure you wish to delete this micro blog?';
    modal.result.then(
      () => {
        this.store.dispatch(deleteMBlog({ microBlog }));
      },
      () => {}
    );
  }
  // endregion

  // region UI
  showEdit(microBlog: MicroBlog, authorities?: ILookup[], groups?: ILookup[]) {
    this.showEditModal(microBlog, authorities, groups).then(
      (editedMicroBlog: MicroBlog) => {
        this.save(editedMicroBlog);
      },
      () => {}
    );
  }

  showCreate(authorities?: ILookup[], groups?: ILookup[]) {
    this.showEditModal(null, authorities, groups).then(
      microBlog => {
        this.save({ ...microBlog });
      },
      () => {}
    );
  }
  // endregion

  // region Helpers
  private showEditModal(microBlog: MicroBlog, authorities?: ILookup[], groups?: ILookup[]): Promise<MicroBlog> {
    const modalReference = this.modal.open(WicMicroBlogEditComponent, { size: 'lg' });
    modalReference.componentInstance.microBlog = microBlog || {};
    modalReference.componentInstance.authorities = authorities;
    modalReference.componentInstance.groups = groups;
    return modalReference.result;
  }
  // endregion
}
