<router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>

<div class="row" *ngIf="!hideSelf">
    <div class="col-md-12">
        <wic-authorities-table
            [authorities]="authorities.all$ | async"
            (added)="add()"
            (refreshed)="refresh()">
        </wic-authorities-table>
    </div>
</div>
