<div style="padding: 16px 0">
    <mbsc-card [options]="settings" class="shadow" style="margin: 0 1em">
        <mbsc-card-header class="mbsc-card-header card-status-title">
            <div>
                <mbsc-card-subtitle class="mbsc-bold mbsc-txt-s">{{ card.cardNumber }}</mbsc-card-subtitle>
                <span class="mbsc-bold">
                {{ card.status }}
            </span>
            </div>
            <span *ngIf="card.status === 'ERRORED'" style="margin-top: auto; margin-bottom: auto">
            <i class="fa fa-circle" style="color: #eb445a"></i>
        </span>
            <span *ngIf="card.status === 'IMPORTED'" style="margin-top: auto; margin-bottom: auto">
            <i class="fa fa-circle" style="color: #7cda87"></i>
        </span>
        </mbsc-card-header>
    </mbsc-card>
</div>
