<form [formGroup]="form" (submit)="save(form, authority, account)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
        <span class="badge badge-info" *ngIf="authority.id">
            {{authority.id}}
        </span>
            {{authority.id ? 'Edit' : 'Create'}} Authority
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{f.tigVersion.invalid | json}}
    </div>
    <div class="modal-body">
        <div class="form-group row" *ngIf="!authority.id">
            <label for="authorityId" class="col-sm-2 col-form-label" [class.text-danger]="submitted && f.id.invalid">ID</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="authorityId" formControlName="id" [class.is-invalid]="submitted && f.id.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="name" class="col-sm-2 col-form-label" [class.text-danger]="submitted && f.name.invalid">Name</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="name" formControlName="name" [class.is-invalid]="submitted && f.name.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="shortName" class="col-sm-2 col-form-label">Short Name</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="shortName" formControlName="shortName">
            </div>
        </div>
        <div class="form-group row">
            <label for="type" class="col-sm-2 col-form-label" [class.text-danger]="submitted && f.type.invalid">Type</label>
            <div class="col-sm-10">
                <select id="type" formControlName="type"  [class.is-invalid]="submitted && f.type.invalid">
                    <option *ngFor="let type of types" [ngValue]="type.AuthorityType">{{type.Name}}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isActive" formControlName="isActive"  [class.is-invalid]="submitted && f.isActive.invalid">
                    <label class="form-check-label" for="isActive" [class.text-danger]="submitted && f.isActive.invalid">Activate authority for use</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="tigVersion" class="col-sm-2 col-form-label" [class.text-danger]="submitted && f.tigVersion.invalid">TIG Version</label>
            <div class="col-sm-10">
                <input type="number" class="form-control" id="tigVersion" formControlName="tigVersion"  [class.is-invalid]="submitted && f.tigVersion.invalid">
            </div>
        </div>
      <div class="form-group row">
            <label for="appId" class="col-sm-2 col-form-label" [class.text-danger]="submitted && f.appId.invalid">App</label>
            <div class="col-sm-10">
                <select id="appId" formControlName="appId"  [class.is-invalid]="submitted && f.appId.invalid">
                  <option *ngFor="let app of availableApps.all$ | async" [ngValue]="app.id">{{ app.name }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="emailAddresses" class="col-sm-2 col-form-label">Email Addresses</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="emailAddresses" formControlName="emailAddresses">
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="sftpEnabled" formControlName="sftpEnabled">
                    <label class="form-check-label" for="sftpEnabled">Enable SFTP access</label>
                </div>
            </div>
        </div>
        <div class="form-group row" *ngIf="form.controls['sftpEnabled'].value">
            <label for="type" class="col-sm-2 col-form-label"></label>
            <div class="col-sm-10">
                <textarea class="form-control" rows="4" placeholder="Paste public key here"
                          formControlName="publicKey"></textarea>
            </div>
        </div>
        <fieldset class="form-group">
            <div class="row">
                <legend class="col-form-label col-sm-2 pt-0">Flags</legend>
                <div class="col-sm-10" formArrayName="FlagList">
                    <div class="form-check" *ngFor="let flag of flagsArray.controls; let idx = index" [formGroupName]="idx">
                        <input class="form-check-input" type="checkbox" name="flags" id="{{'flag' + idx}}"
                               formControlName="Value">
                        <label class="form-check-label" for="{{'flag' + idx}}">
                            {{flag.get('Name').value}}
                        </label>
                    </div>
                </div>
            </div>
        </fieldset>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
