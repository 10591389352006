import { LOADING_END, LOADING_START, LoadingActions } from './loading.actions';

export interface ILoadingState {
    loading: boolean;
}

const initialState: ILoadingState = {
    loading: false,
};

export function reducer(state: ILoadingState = initialState, action: LoadingActions): ILoadingState {
    switch (action.type) {
        case LOADING_START: {
            return {...state, loading: true};
        }

        case LOADING_END: {
            return {...state, loading: false};
        }

        default: {
            return state;
        }
    }
}
