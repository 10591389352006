<form [formGroup]="form">
    <div class="row mt-4 mb-4" *ngIf="microBlog">
        <div class="col-md-7">
            <div class="card">
                <div class="card-header">
                    <h5 class="float-left mb-0">
                        Content
                    </h5>
                    <i class="fa fa-fw fa-save float-right m-1" (click)="save(microBlog, form)"
                       ngbTooltip="Save" placement="left"></i>
                </div>
                <div class="card-body">
                    <ngb-tabset formArrayName="localizedContent" #localizedContent
                                (tabChange)="tabBeforeChange($event)">
                        <ngb-tab *ngFor="let lc of localizedContentArray.controls; let i = index"
                                 [title]="lc.value.lang"
                                 [formGroupName]="i" id="{{i}}">
                            <ng-template ngbTabContent>
                            <textarea class="form-control my-3"
                                      formControlName="content"
                                      name="body"
                                      [rows]="5"
                                      maxlength="350"
                                      placeholder="Post Body (Max 350 chars)..."
                                    (change)="contentChanged()">
                            </textarea>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="+ Add" id="addLang">
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="card">
                <div class="card-header">
                    <h5 class="float-left mb-0">
                        Image
                    </h5>
                    <i class="fa fa-fw fa-save float-right m-1" (click)="save(microBlog, form)"
                       ngbTooltip="Save" placement="left"></i>
                </div>
                <div class="card-body d-flex justify-content-center">
                    <a class="align-self-center text-center" role="button" *ngIf="!form.get('image').value">
                        <i class="fa fa-5x fa-plus text-muted" (click)="add.click();"></i>
                    </a>
                    <img style="max-width: 100%; max-height: 250px; height: auto"
                         [src]="createImageUrl(form.get('image').value)"
                         (click)="add.click();"
                         *ngIf="form.get('image').value"/>
                    <input type="file" [hidden]="true" accept="image/*"
                           (change)="addImage($event.target.files)" #add>
                </div>
            </div>
        </div>
    </div>
</form>
