import { Injectable } from '@angular/core';
import { Create, Deselect, LoadAll, SelectByKey, Update } from '@briebug/ngrx-auto-entity';

import { BannerPlaylist } from '@models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@state/app.state';
import { allBannerPlaylists, createPlaylist, currentBannerPlaylist, deleteBannerPlaylist, deletePlaylist, loadPlaylists, updatePlaylist } from '@state/banner-playlist.state';
import { Observable } from 'rxjs';
// tslint:disable-next-line:max-line-length
import { WicBannerPlaylistDetailEditComponent } from '../wic-admin/wic-authorities/wic-authority-banners/wic-banner-playlist-detail-edit/wic-banner-playlist-detail-edit.component';
import { WicConfirmModalComponent } from '../wic-confirm-modal/wic-confirm-modal.component';

@Injectable({
    providedIn: 'root'
})
export class BannerPlaylistsFacade {
    constructor(private store: Store<IAppState>, private modal: NgbModal) {
    }

    // region Selections
    get all(): Observable<BannerPlaylist[]> {
        return this.store.pipe(select(allBannerPlaylists));
    }

    get current(): Observable<BannerPlaylist> {
        return this.store.pipe(select(currentBannerPlaylist));
    }

    // endregion

    // region Dispatches
    loadAll() {
        this.store.dispatch(loadPlaylists());
    }

    create(playlist: BannerPlaylist) {
        this.store.dispatch(createPlaylist({ bannerPlaylist: playlist}));
    }

    update(playlist: BannerPlaylist) {
        this.store.dispatch(updatePlaylist({ bannerPlaylist: playlist }));
    }

    select(playlistId: number) {
        this.store.dispatch((new SelectByKey(BannerPlaylist, playlistId)));
    }

    deselect() {
        this.store.dispatch(new Deselect(BannerPlaylist));
    }

    delete(entity: BannerPlaylist) {
        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = 'Confirm Banner Playlist Deletion';
        modalReference.componentInstance.message = 'Are you sure you want to delete this Banner Playlist?';
        modalReference.result
            .then(() => {
                this.store.dispatch(deletePlaylist({ bannerPlaylist: entity}));
            }, () => {});
    }

    // endregion

    // region UI Helpers
    showEdit(playlist: BannerPlaylist) {
        this.showEditModal(playlist)
            .then(edited => {
                this.update(edited);
            }, () => {
            });
    }

    showCreate() {
        this.showEditModal().then(created => {
            this.create(created);
        }, () => {
        });
    }

    // endregion

    // region Helpers
    private showEditModal(playlist?: BannerPlaylist): Promise<BannerPlaylist> {
        const modalReference = this.modal.open(WicBannerPlaylistDetailEditComponent, {size: 'lg'});
        modalReference.componentInstance.playlist = playlist || {};
        return modalReference.result;
    }

    // endregion
}
