<div class="row">
    <div class="col-md-12">
        <wic-benefit-import-detail [import]="import$ | async">
        </wic-benefit-import-detail>
    </div>
</div>
<div class="row mb-5">
    <div class="col-md-12">
        <wic-data-import-log [progressLog]="log$ | async">
        </wic-data-import-log>
    </div>
</div>
<div class="row mb-5">
    <div class="col-md-12">
        <wic-import-parse-errors [parseErrors]="parseErrors$ | async">
        </wic-import-parse-errors>
    </div>
</div>
