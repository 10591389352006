<div class="card mt-5 mb-5">
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="users?.length">
            <thead>
            <tr>
                <th class="" (click)="usersSorter.sortString('username')">
                    <i class="fa fa-fw fa-id-badge"></i> Username
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-alpha-asc': usersSorter.direction === 1, 'fa-sort-alpha-desc': usersSorter.direction === -1}"
                       *ngIf="usersSorter.sortKey === 'username'"></i>
                </th>
                <th class="text-nowrap" (click)="usersSorter.sortString('first')">
                    First Name
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-alpha-asc': usersSorter.direction === 1, 'fa-sort-alpha-desc': usersSorter.direction === -1}"
                       *ngIf="usersSorter.sortKey === 'first'"></i>
                </th>
                <th class="text-nowrap" (click)="usersSorter.sortString('last')">
                    Last Name
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-alpha-asc': usersSorter.direction === 1, 'fa-sort-alpha-desc': usersSorter.direction === -1}"
                       *ngIf="usersSorter.sortKey === 'last'"></i>
                </th>
                <th class="text-left text-nowrap">
                    <i class="fa fa-fw fa-clock-o"></i> Last Login
                </th>
                <th class="text-center" (click)="usersSorter.sortNumber('authorityId')">
                    Authority
                    <i class="fa fa-fw text-medium fa-sort-numeric-asc"
                       [ngClass]="{'fa-sort-numeric-asc': usersSorter.direction === 1, 'fa-sort-numeric-desc': usersSorter.direction === -1}"
                       *ngIf="usersSorter.sortKey === 'authorityId'"></i>
                </th>
                <th class="text-center" (click)="usersSorter.sortNumber('isEnabled')">
                    Enabled
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-amount-asc': usersSorter.direction === 1, 'fa-sort-amount-desc': usersSorter.direction === -1}"
                       *ngIf="usersSorter.sortKey === 'isEnabled'"></i>
                </th>
                <th class="text-center">Roles</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of users">
                <td class="">
                    <a [routerLink]="['view', user._id]">{{user.username}}</a>
                </td>
                <td class="text-nowrap">{{user.first}}</td>
                <td class="text-nowrap">{{user.last}}</td>
                <td class="text-left">
                    <span *ngIf="user.lastLogin">
                        <span class="badge badge-default badge-info text-white mr-1">
                            {{user.lastLogin?.split('T')[0]}}
                        </span>
                        <span class="badge badge-default badge-info text-white">
                            {{user.lastLogin?.split('T')[1]}}
                        </span>
                    </span>
                    <span class="badge badge-default badge-info text-white" *ngIf="!user.lastLogin">
                            Never
                    </span>
                </td>
                <td class="text-center">
                    <span class="badge badge-pill badge-default text-white"
                          [class.badge-success]="!user.authorityId"
                          [class.badge-warning]="user.authorityId"
                          placement="left"
                          ngbTooltip="{{user.authorityId ? 'Access to Authority ' + user.authorityId : 'Access to All Authorities'}}">
                        {{user.authorityId || 'All'}}
                    </span>
                </td>
                <td class="text-center">
                    <span class="badge badge-pill badge-default text-white"
                          [class.badge-danger]="!user.isEnabled"
                          [class.badge-success]="user.isEnabled">
                        {{user.isEnabled ? 'Yes' : 'No'}}
                    </span>
                </td>
                <td class="text-center">
                    <span class="badge badge-default badge-info text-white"
                          *ngFor="let role of user.roles">
                        {{role}}
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!users?.length" class="card-body">
        <p><em>There are no users.</em></p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6">
                <a class="btn btn-outline-secondary float-left" role="button"
                   [download]="getFilename()" [href]="buildDownloadUri(users)">
                    <i class="fa fa-download"></i> Download as CSV
                </a>
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
                <button class="btn btn-outline-primary" (click)="added.emit()">
                    <i class="fa fa-user-plus"></i> Add User
                </button>
            </div>
        </div>
    </div>
</div>
