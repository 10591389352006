import {Entity, Key} from '@briebug/ngrx-auto-entity';

import {ObjectId} from '@models/types';

@Entity({
    modelName: 'APLImportConfig'
})
export class APLImportConfig {
    // tslint:disable-next-line:variable-name
    @Key _id?: ObjectId;
    authorityId: number;
    authority?: string;
    lastRun: string;
    ftpUrl: string;
    ftpPort?: number;
    ftpRoot?: string;
    username: string;
    password: string;
    aplGlob: string;
    isEnabled: boolean;
    isSecure?: boolean;
    isZipped?: boolean;
    filterDuplicates?: boolean;
    requiresTransformation?: boolean;
    requiresZeroFill?: boolean;
    requiresOverrides?: boolean;
    applySharedOverrides?: boolean;
    manualRun?: any;
    skipped?: boolean;
    succeeded?: boolean;
    appId: number;
}
