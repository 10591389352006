<div>
    <div class="modal-header">
        <h4 class="modal-title">{{modalTitle}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center">
                <a class="align-self-center text-center" role="button" *ngIf="!form.get('image').value">
                    <i class="fa fa-5x fa-plus text-muted" (click)="add.click();"></i>
                </a>
                <img style="max-width: 100%; max-height: 250px; height: auto"
                     [src]="createImageUrl(form.get('image').value)"
                     (click)="add.click();"
                     *ngIf="form.get('image').value"/>
                <input type="file" [hidden]="true" accept="image/*"
                       (change)="addImage($event.target.files)" #add>
            </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" [disabled]="!changed" (click)="save(form)">Save</button>
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    </div>
</div>
