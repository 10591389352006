import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceLogSessionFacade} from '@facades/device-log-session.facade';
import {EnhancedDeviceFacade} from '@facades/enhanced-device.facade';
import {ErrorReportsFacade} from '@facades/error-reports.facade';
import {DeviceLogSession, ErrorReport} from '@models';

@Component({
    selector: 'wic-support-tools',
    templateUrl: './support-tools.component.html',
    styleUrls: ['./support-tools.component.sass']
})
export class SupportToolsComponent {
    hideSelf = false;

    constructor(
        public sessions: DeviceLogSessionFacade,
        public enhancedDevices: EnhancedDeviceFacade,
        public errorReports: ErrorReportsFacade,
        private route: ActivatedRoute,
        private router: Router) {
        sessions.loadAll();
        enhancedDevices.loadAll();
        errorReports.loadPage({ page: 1, size: 20 });
    }

    viewReport(report: ErrorReport): void {
        this.router.navigate(['error-reports', report._id], {
            relativeTo: this.route
        });
    }

    viewSession(session: DeviceLogSession): void {
        this.router.navigate(['logs', session.udid, 'sessions', session.sessionId], {
            relativeTo: this.route
        });
    }
}
