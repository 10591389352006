<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <span class="badge badge-info" *ngIf="message?._id">
                {{message._id}}
            </span>
            {{message?._id ? 'Edit' : 'Create'}} APL Message
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label for="name" class="col-4 col-lg-2 col-form-label" [class.text-danger]="submitted && f.name.invalid">Name</label>
            <div class="col-8 col-lg-10">
                <input class="form-control" formControlName="name" id="name" required
                       [class.is-invalid]="submitted && f.name.invalid">
            </div>
        </div>

        <div class="form-group row">
            <label for="isActive" class="col-4 col-lg-2 col-form-label"></label>
            <div class="col-8 col-lg-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isActive" formControlName="isActive">
                    <label class="form-check-label" for="isActive" [class.text-danger]="submitted && f.isActive.invalid">Is Active</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="onBenefits" class="col-4  col-lg-2 col-form-label"></label>
            <div class="col-8  col-lg-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="onBenefits" formControlName="onBenefits">
                    <label class="form-check-label" for="onBenefits" [class.text-danger]="submitted && f.onBenefits.invalid">Display on Benefits</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="onDetail" class="col-4  col-lg-2 col-form-label"></label>
            <div class="col-8  col-lg-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="onDetail" formControlName="onDetail">
                    <label class="form-check-label" for="onDetail" [class.text-danger]="submitted && f.onDetail.invalid">Display on Item Detail</label>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="icon" class="col-4 col-lg-2 col-form-label">Icon</label>
            <div class="col-4 col-lg-5">
                <label class="custom-file form-control">
                    <input #file class="custom-file-input"
                           id="icon"
                           type="file"
                           accept="image/png"
                           (change)="imageChanged($event)"/>
                    <span class="custom-file-control"></span>
                </label>
            </div>
            <div class="col-4 col-lg-5">
                <img class="apl-message-image" *ngIf="form.get('icon').value"
                     [src]="createImageUrl(form.get('icon').value)"/>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-4 col-lg-2 col-form-label"></div>
            <div class="col-8 col-lg-10">
                <small class="text-muted">For transparency support, please use *.png images with 78px height/width.</small>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
