import { Component, Input, OnInit } from '@angular/core';
import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { CategoriesFacade } from '@facades/categories.facade';
import { SubCategoriesFacade } from '@facades/subcategory.facade';
import { Category, DemoAccountBenefitGroup, SubCategory } from '@models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '@services';
import { merge, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BenefitForm, BenefitGroupForm } from './benefit-group.form';

@Component({
  selector: 'wic-add-edit-benefit-group',
  templateUrl: './add-edit-benefit-group.component.html',
  styleUrls: ['./add-edit-benefit-group.component.scss'],
})
export class AddEditBenefitGroupComponent implements OnInit {
  @Input() benefitGroup?: DemoAccountBenefitGroup;

  form: BenefitGroupForm;
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    public categories: CategoriesFacade,
    public subCategories: SubCategoriesFacade,
    public util: UtilService
  ) {}

  ngOnInit(): void {
    console.log('BenefitGroup', this.benefitGroup);
    this.form = new BenefitGroupForm(this.benefitGroup);
    console.log(this.form.value);
  }

  currentCategory$(benefit: BenefitForm): Observable<string> {
    return merge(benefit.categoryId.valueChanges, of(null)).pipe(
      switchMap(() => this.categories.entities$),
      map((categories: IEntityDictionary<Category>) => categories[benefit.categoryId.value]?.description),
    );
  }

  currentSubCategory$(benefit: BenefitForm): Observable<string> {
    return merge(benefit.subCategoryId.valueChanges, of(null)).pipe(
      switchMap(() => this.subCategories.entities$),
      map(
        (categories: IEntityDictionary<SubCategory>) =>
          categories[`${benefit.categoryId.value}_${benefit.subCategoryId.value}`]?.description
      ),
    );
  }

  byCategory$(benefit: BenefitForm): Observable<SubCategory[]> {
    return merge(benefit.categoryId.valueChanges, of(null)).pipe(
      switchMap(() => this.subCategories.all$),
      map(subCats => subCats.filter(subCat => subCat.categoryId === +benefit.categoryId.value))
    );
  }

  save() {
    this.submitted = true;
    console.log(this.form.value);
    console.log(this.form.updatedModel);
    if (this.form.invalid) {
      return;
    }

    this.activeModal.close(this.form.updatedModel);
  }
}
