<div class="row">
    <div class="col-md-12">
        <wic-micro-blog-detail #detail
            [microBlog]="microBlog$ | async"
            [authorities]="authorities$ | async">
<!--            (edited)="edit($event)">-->
        </wic-micro-blog-detail>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <wic-micro-blog-content #content
            [microBlog]="microBlog$ | async">
<!--            (saved)="save($event)">-->
        </wic-micro-blog-content>
    </div>
</div>
