import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Observable} from 'rxjs';

import {MenuButton} from '@models';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MenuButtonService implements IAutoEntityService<MenuButton> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<MenuButton[]> {
        return this.http.get<MenuButton[]>(environment.apiRoot + `/admin/authorities/${criteria.linkTypeId}/menubuttons`);
    }

    create(entityInfo: IEntityInfo, entity: MenuButton): Observable<MenuButton> {
        return this.http.post<MenuButton>(environment.apiRoot + `/admin/authorities/${entity.linkTypeId}/menubuttons`, entity);
    }

    update(entityInfo: IEntityInfo, entity: MenuButton): Observable<MenuButton> {
        return this.http.put<MenuButton>(environment.apiRoot +
            `/admin/authorities/${entity.linkTypeId}/menubuttons/${entity.linkId}`, entity);
    }

    delete(entityInfo: IEntityInfo, entity: MenuButton): Observable<MenuButton> {
        return this.http.delete<MenuButton>(environment.apiRoot + `/admin/authorities/${entity.linkTypeId}/menubuttons/${entity.linkId}`)
            .pipe(map(() => entity));
    }

    move(entity: MenuButton, newIndex: number): Observable<MenuButton[]> {
        return this.http.patch<MenuButton[]>(environment.apiRoot +
            `/admin/authorities/${entity.linkTypeId}/menubuttons/${entity.linkId}`, entity, {
            params: {
                to: newIndex.toString()
            }
        });
    }
}
