import {Component, Input} from '@angular/core';
import {ErrorReport} from '@models';

@Component({
    selector: 'wic-error-report-detail',
    templateUrl: './error-report-detail.component.html',
    styleUrls: ['./error-report-detail.component.scss']
})
export class ErrorReportDetailComponent {
    @Input() report: ErrorReport;
}
