import {buildState} from '@briebug/ngrx-auto-entity';
import {SubCategory} from '@models';

export const {
  initialState,
  selectors: {
    selectAll: allSubCategories,
    selectCurrentEntity: currentSubCategory
  },
  facade: SubCategoriesFacadeBase
} = buildState(SubCategory);

export function subCategoryReducer(state = initialState) {
    return state;
}
