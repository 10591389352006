import { Injectable } from '@angular/core';
import { AvailableAppsFacadeBase } from '@state/available-apps/available-apps.state';
import { Store } from '@ngrx/store';
import { AvailableApp } from '@state/available-apps/models/available-app.entity';

@Injectable()
export class AvailableAppsFacade extends AvailableAppsFacadeBase {
  constructor(store: Store) {
    super(AvailableApp, store);
  }
}
