import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'wic-spark-pie',
    templateUrl: './spark-pie.component.html',
    styleUrls: ['./spark-pie.component.sass'],
})
export class SparkPieComponent implements OnInit {
    @Input() data: number[];
    @Input() labels: string[];

    @Input() colors: string[] = ['#007bff', '#409cff', '#80bfff', '#bfdfff'];

    constructor() {
    }

    ngOnInit() {
    }

}
