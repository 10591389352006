import {Component, OnInit} from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';

import {IAppState} from '@state/app.state';
import {AuthorityMapping} from '@state/authority-mappings/model';
import {Observable} from 'rxjs';

import {filter, map} from 'rxjs/operators';
import * as actions from '../../state/authority-mappings/authority-mappings.actions';
import {selectAuthorityMappings, selectAuthorityMappingsErrors} from '../../state/authority-mappings';
import {WicConfirmModalComponent} from '../../wic-confirm-modal/wic-confirm-modal.component';

@Component({
    selector: 'wic-authority-mappings',
    templateUrl: './wic-authority-mappings.component.html',
    styleUrls: ['./wic-authority-mappings.component.sass'],
})
export class WicAuthorityMappingsComponent implements OnInit {
    authorityMappings$: Observable<AuthorityMapping[]>;
    selectedAuthorityMapping: AuthorityMapping = {} as AuthorityMapping;
    errors$: Observable<any[]>;

    ngOnInit() {
        this.authorityMappings$ = this.store.pipe(
            select(selectAuthorityMappings),
            filter(mappings => !!mappings),
            map(mappings => mappings.map(am => ({...am})))
        );
        this.errors$ = this.store.pipe(select(selectAuthorityMappingsErrors));
        this.store.dispatch(new actions.FetchAuthorityMappingsAction());
    }

    onSaved(authorityMapping: AuthorityMapping) {
        const action = authorityMapping._id ? new actions.UpdateAuthorityMappingAction(authorityMapping) :
            new actions.CreateAuthorityMappingAction(authorityMapping);
        this.store.dispatch(action);
        this.selectedAuthorityMapping = {} as AuthorityMapping;
    }

    onCanceled() {
        this.selectedAuthorityMapping = {} as AuthorityMapping;
    }

    onDelete(authorityMapping: AuthorityMapping) {
        this.store.dispatch(new actions.DeleteAuthorityMappingAction(authorityMapping));
    }

    confirmDelete(authorityMapping: AuthorityMapping) {
        const modalReference = this.modalService
            .open(WicConfirmModalComponent);

        modalReference.componentInstance.modalTitle = 'Confirm Authority Mapping Deletion';
        modalReference.componentInstance.message = 'Are you sure you want to delete this authority mapping?';
        modalReference.result
            .then(() => {
                this.onDelete(authorityMapping);
            }, () => {
            });
    }

    onDismissError() {
        this.store.dispatch(new actions.DismissAuthorityMappingsErrorAction());
    }

    constructor(
        private store: Store<IAppState>,
        private modalService: NgbModal
    ) {
    }
}
