import { Action } from '@ngrx/store';

import { type } from '../shared/utils';
import { AuthorityMapping } from './model';

export const CREATE_AUTHORITY_MAPPING = type('[AuthorityMapping] Create authority mapping');
export const CREATE_AUTHORITY_MAPPING_FAILURE = type('[AuthorityMapping] Create authority mapping failed');
export const CREATE_AUTHORITY_MAPPING_SUCCESS = type('[AuthorityMapping] Create authority mapping succeeded');
export const DELETE_AUTHORITY_MAPPING = type('[AuthorityMapping] Delete authority mapping');
export const DELETE_AUTHORITY_MAPPING_FAILURE = type('[AuthorityMapping] Delete authority mapping failed');
export const DELETE_AUTHORITY_MAPPING_SUCCESS = type('[AuthorityMapping] Delete authority mapping succeeded');
export const DISMISS_AUTHORITY_MAPPINGS_ERROR = type('[AuthorityMapping] Dismiss error');
export const FETCH_AUTHORITY_MAPPINGS = type('[AuthorityMapping] Fetch authority mappings');
export const FETCH_AUTHORITY_MAPPINGS_FAILURE = type('[AuthorityMapping] Fetch authority mappings failed');
export const FETCH_AUTHORITY_MAPPINGS_SUCCESS = type('[AuthorityMapping] Fetch authority mappings succeeded');
export const UPDATE_AUTHORITY_MAPPING = type('[AuthorityMapping] Update authority mapping');
export const UPDATE_AUTHORITY_MAPPING_FAILURE = type('[AuthorityMapping] Update authority mapping failed');
export const UPDATE_AUTHORITY_MAPPING_SUCCESS = type('[AuthorityMapping] Update authority mapping succeeded');

export class FetchAuthorityMappingsAction implements Action {
    readonly type = FETCH_AUTHORITY_MAPPINGS;
}

export class FetchAuthorityMappingsSuccessAction {
    readonly type = FETCH_AUTHORITY_MAPPINGS_SUCCESS;

    constructor(public payload: AuthorityMapping[]) {}
}

export class FetchAuthorityMappingsFailureAction {
    readonly type = FETCH_AUTHORITY_MAPPINGS_FAILURE;

    constructor(public payload: any) {}
}

export class CreateAuthorityMappingAction {
    readonly type = CREATE_AUTHORITY_MAPPING;

    constructor(public payload: AuthorityMapping) {}
}

export class CreateAuthorityMappingSuccessAction {
    readonly type = CREATE_AUTHORITY_MAPPING_SUCCESS;

    constructor(public payload: AuthorityMapping) {}
}

export class CreateAuthorityMappingFailureAction {
    readonly type = CREATE_AUTHORITY_MAPPING_FAILURE;

    constructor(public payload: any) {}
}

export class UpdateAuthorityMappingAction {
    readonly type = UPDATE_AUTHORITY_MAPPING;

    constructor(public payload: AuthorityMapping) {}
}

export class UpdateAuthorityMappingSuccessAction implements Action {
    readonly type = UPDATE_AUTHORITY_MAPPING_SUCCESS;
}

export class UpdateAuthorityMappingFailureAction implements Action {
    readonly type = UPDATE_AUTHORITY_MAPPING_FAILURE;

    constructor(public payload: any) {}
}

export class DeleteAuthorityMappingAction implements Action {
    readonly type = DELETE_AUTHORITY_MAPPING;

    constructor(public payload: AuthorityMapping) {}
}

export class DeleteAuthorityMappingSuccessAction implements Action {
    readonly type = DELETE_AUTHORITY_MAPPING_SUCCESS;

    constructor(public payload: AuthorityMapping) {}
}

export class DeleteAuthorityMappingFailureAction implements Action {
    readonly type = DELETE_AUTHORITY_MAPPING_FAILURE;

    constructor(public payload: any) {}
}

export class DismissAuthorityMappingsErrorAction implements Action {
    readonly type = DISMISS_AUTHORITY_MAPPINGS_ERROR;
}

export type AuthorityMappingsActions =
    | CreateAuthorityMappingAction
    | CreateAuthorityMappingFailureAction
    | CreateAuthorityMappingSuccessAction
    | DeleteAuthorityMappingAction
    | DeleteAuthorityMappingSuccessAction
    | DeleteAuthorityMappingFailureAction
    | DismissAuthorityMappingsErrorAction
    | FetchAuthorityMappingsAction
    | FetchAuthorityMappingsFailureAction
    | FetchAuthorityMappingsSuccessAction
    | UpdateAuthorityMappingAction
    | UpdateAuthorityMappingFailureAction
    | UpdateAuthorityMappingSuccessAction;
