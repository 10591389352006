import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {APLItem} from '@models';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AplItemService implements IAutoEntityService<APLItem> {
    constructor(private http: HttpClient) {
    }

    update(entityInfo: IEntityInfo, item: APLItem): Observable<APLItem> {
        return this.http.put<APLItem>(environment.apiRoot + `/authorities/${item.authorityId}/items/${item.itemId}`, item);
    }
}
