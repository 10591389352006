import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BenefitInfo} from '@models/BenefitInfo';
import {ILookup} from '@models/types';
import * as moment from 'moment';

@Component({
    selector: 'wic-card-check-benefits-table',
    templateUrl: './card-check-benefits-table.component.html',
    styleUrls: ['./card-check-benefits-table.component.scss']
})
export class CardCheckBenefitsTableComponent implements OnInit, OnChanges {
    @Input() cardNumber?: string;
    @Input() benefitInfo: BenefitInfo;
    @Input() authorities: ILookup[];
    @Output() searched = new EventEmitter<{
        cardNumber: string, householdId: string, authority: number, startDate?: string, endDate?: string
    }>();
    @Output() cardSpun = new EventEmitter<number>();

    form: FormGroup;

    constructor(private builder: FormBuilder) {
    }

    ngOnInit() {
        this.form = this.builder.group({
            cardNumber: [null, Validators.required],
            householdId: null,
            authorityId: [null, Validators.required],
            startDate: null,
            endDate: null
        });

        const today = moment();
        const criteria = {
            cardNumber: this.cardNumber,
            authorityId: [],
            startDate: {
                year: today.year(),
                month: today.month() + 1,
                day: today.date(),
            },
            endDate: {
                year: today.year(),
                month: today.month() + 1,
                day: today.date(),
            },
        };

        this.form.patchValue(criteria);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.cardNumber && !!this.form) {
            this.form.patchValue({
                cardNumber: this.cardNumber
            });
        }
    }

    search() {
        if (this.form.invalid) {
            return;
        }

        const criteria = {
            ...this.form.value,
            authorityId: this.form.value.authorityId[0],
            startDate: moment({...this.form.value.startDate, month: this.form.value.startDate.month - 1}).format('YYYY-MM-DD'),
            endDate: moment({...this.form.value.endDate, month: this.form.value.endDate.month - 1}).format('YYYY-MM-DD')
        };

        this.searched.emit(criteria);
    }
}
