import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {APLImportConfig} from '@models/APLImportConfig';

@Component({
    selector: 'wic-apl-imports-table',
    templateUrl: './wic-apl-imports-table.component.html',
    styleUrls: ['./wic-apl-imports-table.component.sass']
})
export class WicAplImportsTableComponent implements OnInit {
    @Input() configs: APLImportConfig[];
    @Input() forDeletion: APLImportConfig;
    @Input() isLoading: boolean;
    @Output() added = new EventEmitter();
    @Output() deleted = new EventEmitter<APLImportConfig>();
    @Output() refreshed = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

}
