import { ModuleWithProviders, NgModule } from '@angular/core';
import { AzureStorageConfig } from '../core/types';
import { StorageService } from './storage.service';
import { AZURE_STORAGE_CONFIG_TOKEN } from './tokens';

@NgModule()
export class AzureStorageModule {
  static forRoot(config: AzureStorageConfig): ModuleWithProviders<AzureStorageModule> {
    return {
      ngModule: AzureStorageModule,
      providers: [
        {
          provide: AZURE_STORAGE_CONFIG_TOKEN,
          useValue: config,
        },
        StorageService
      ],
    };
  }
}
