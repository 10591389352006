<div class="card mt-5">
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                <h5 class="float-left mb-0">Import Job History</h5>
            </div>
            <div class="col-1" *ngIf="!showToolbar">
                <i class="fa fa-refresh" [class.fa-spin]="isLoading" (click)="refreshed.emit()"></i>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="jobs?.length">
            <thead>
            <tr>
                <th class="text-nowrap">Job ID</th>
                <th class="text-nowrap">Start Time</th>
                <th class="text-nowrap">End Time</th>
                <th class="text-center">Was Run</th>
                <th class="text-center">Status</th>
                <th class="text-center"><i class="fa fa-wrench"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let job of jobs" [class.bg-danger]="job._id === forDeletion?._id">
                <td class="" [class.text-white]="job._id === forDeletion?._id">
                    <a [class.text-white]="job._id === forDeletion?._id"
                       [routerLink]="['job', job._id]">{{job._id}}</a>
                </td>
                <td class="" [class.text-white]="job._id === forDeletion?._id">
                    <span class="text-monospace">{{job.startTime | date:'yy-MM-dd hh:mm aa'}}</span>
                </td>
                <td class="" [class.text-white]="job._id === forDeletion?._id">
                    <span class="text-monospace">{{job.endTime | date:'yy-MM-dd hh:mm aa'}}</span>
                </td>
                <td class="text-center">
                    <span class="badge badge-warning text-white" *ngIf="job.skipped">
                        <i class="fa fa-warning"></i> Skipped
                    </span>
                    <span class="badge badge-success text-white" *ngIf="!job.skipped">
                        <i class="fa fa-check"></i> Run
                    </span>
                </td>
                <td class="text-center">
                    <span class="badge badge-warning text-white" *ngIf="job.aborted === true">
                        <i class="fa fa-warning"></i> Aborted
                    </span>
                    <span class="badge badge-danger text-white" *ngIf="job.succeeded === false">
                        <i class="fa fa-times"></i> Failed
                    </span>
                    <span class="badge badge-success text-white" *ngIf="job.succeeded === true">
                        <i class="fa fa-check"></i> Succeeded
                    </span>
                </td>
                <td class="text-right" [class.text-white]="job._id === forDeletion?._id">
                    <i class="fa fa-trash" (click)="deleted.emit(job);"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!jobs?.length" class="card-body">
        <p *ngIf="isLoading"><i class="fa fa-spinner fa-spin"></i> Loading APL import jobs...</p>
        <p *ngIf="!isLoading"><em>There are no APL import jobs.</em></p>
    </div>
    <div class="card-footer" *ngIf="showToolbar">
        <div class="row">
            <div class="col-md-6">
                <!--<a class="btn btn-outline-secondary float-left" role="button"-->
                <!--[download]="getFilename()" [href]="buildDownloadUri(appointments)">-->
                <!--<i class="fa fa-download"></i> Download as CSV-->
                <!--</a>-->
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh" [class.fa-spin]="isLoading"></i> Refresh
                </button>
            </div>
        </div>
    </div>
</div>
