import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter} from 'rxjs/operators';

import {APLOverride, Authority, CategoryLookup, SubCategoryLookup} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '@services';

@Component({
    selector: 'wic-apl-override-edit',
    templateUrl: './wic-apl-override-edit.component.html',
    styleUrls: ['./wic-apl-override-edit.component.sass']
})
export class WicAplOverrideEditComponent implements OnInit {
    @Input() override: APLOverride;
    @Input() categories: CategoryLookup[];
    @Input() subCategories: SubCategoryLookup[];

    get f() {
        return this.form.controls;
    }

    filteredSubCategories: SubCategoryLookup[];

    form: FormGroup;
    submitted = false;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public util: UtilService
        ) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);
        this.populateForm(this.override);
    }

    private buildForm(formBuilder) {
        const form = formBuilder.group({
            authorityId: [null],
            allAuths: [true, Validators.required],
            categoryId: [null, Validators.required],
            subCategoryId: [null],
            description: [null],
            uom: [null],
            packageSize: [null],
            isEnabled: [true]
        });

        form.get('categoryId').valueChanges.pipe(
            filter(() => !!this.subCategories)
        ).subscribe(categoryId => {
            this.filteredSubCategories = categoryId ? this.subCategories.filter(item => +item.categoryId === +categoryId) : [];
            const subCategory = this.override ? this.filteredSubCategories.find(
                sc => +sc.categoryId === +this.override.categoryId && +sc.subCategoryId === +this.override.subCategoryId
            ) : null;
            form.patchValue({subCategoryId: subCategory ? subCategory.subCategoryId : null});
        });

        return form;
    }

    populateForm(override) {
        this.form.patchValue({
            ...override,
            categoryId: +override.categoryId,
            subCategoryId: +override.subCategoryId,
        });
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        const override = {
            ...value,
            _id: !this.override._id ? undefined : this.override._id,
            authorityId: value.authorityId ? +value.authorityId : null,
            categoryId: +value.categoryId,
            subCategoryId: value.subCategoryId ? +value.subCategoryId : null
        };
        this.activeModal.close(override);
    }
}
