<router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>

<div class="row" *ngIf="!hideSelf">
    <div class="col-md-12">
        <div class="card mt-5 mb-5">
            <div class="">
                <mbsc-form [options]="formSettings">
                    <mbsc-form-group>
                        <mbsc-segmented-group [(ngModel)]="importsDisplay">
                            <mbsc-segmented value="legacy">Legacy</mbsc-segmented>
                            <mbsc-segmented value="modern">Modern</mbsc-segmented>
                        </mbsc-segmented-group>
                    </mbsc-form-group>
                </mbsc-form>
            </div>

            <wic-benefit-imports-table
                *ngIf="importsDisplay === 'legacy'"
                [localBenefitImports]="localBenefitImports$ | async"
                (refreshed)="refresh()">
            </wic-benefit-imports-table>

            <ng-container *ngIf="importsDisplay !== 'legacy'">
                <wic-import-job-list
                    [benefitImportJobs]="benefitImportJobs.benefitImportJobs$ | async">
                </wic-import-job-list>

                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-6 text-right">
                            <button class="btn btn-outline-secondary mr-2" (click)="benefitImportJobs.refresh()">
                                <i class="fa fa-refresh"></i> Refresh
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
