import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {MicroBlog} from '@models';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MicroBlogService implements IAutoEntityService<MicroBlog> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: { appId: string }): Observable<MicroBlog[]> {
        return this.http.get<MicroBlog[]>(`${environment.apiRoot}/admin/apps/${criteria.appId}/microblogs`);
    }

    load(entityInfo: IEntityInfo, id: string, criteria: { appId: string }): Observable<MicroBlog> {
        return this.http.get<MicroBlog>(`${environment.apiRoot}/admin/apps/${criteria.appId}/microblogs/${id}`);
    }

    create(entityInfo: IEntityInfo, blog: MicroBlog, criteria: { appId: string }): Observable<MicroBlog> {
        return this.http.post<MicroBlog>(`${environment.apiRoot}/admin/apps/${criteria.appId}/microblogs`, blog);
    }

    update(entityInfo: IEntityInfo, blog: MicroBlog, criteria: { appId: string }): Observable<MicroBlog> {
        return this.http.put<MicroBlog>(`${environment.apiRoot}/admin/apps/${criteria.appId}/microblogs/${blog._id}`, blog);
    }

    delete(entityInfo: IEntityInfo, blog: MicroBlog, criteria: { appId: string }): Observable<MicroBlog> {
        return this.http.delete(`${environment.apiRoot}/admin/apps/${criteria.appId}/microblogs/${blog._id}`).pipe(
            map(() => blog)
        );
    }
}