import { WikiArticle } from '@models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
    CREATE_WIKI_SUCCESS,
    DELETE_WIKI_SUCCESS,
    FETCH_WIKI_BY_SLUG_FAILURE,
    FETCH_WIKI_BY_SLUG_SUCCESS,
    FETCH_WIKI_FAILURE,
    FETCH_WIKI_SUCCESS,
    FETCH_WIKIS_SUCCESS,
    SELECT_WIKI,
    SELECT_WIKI_BY_ID,
    SELECT_WIKI_BY_SLUG,
    UPDATE_WIKI_SUCCESS,
    WikiActions,
} from '@state/wiki/wiki.actions';

export interface IWikiState extends EntityState<WikiArticle> {
    loading: boolean;
    selectedId: string;
    selectedSlug: string;
}

export const adapter: EntityAdapter<WikiArticle> = createEntityAdapter({
    selectId: (article: WikiArticle) => article._id,
});

const initialState: IWikiState = adapter.getInitialState({
    loading: false,
    selectedId: null,
    selectedSlug: null,
});

export function wikiReducer(state: IWikiState = initialState, action: WikiActions): IWikiState {
    switch (action.type) {
        case SELECT_WIKI:
            return { ...state, selectedId: action.payload ? action.payload._id : null };
        case SELECT_WIKI_BY_ID:
            return { ...state, selectedId: action.id };
        case SELECT_WIKI_BY_SLUG:
            return { ...state, selectedSlug: action.slug };
        case CREATE_WIKI_SUCCESS:
            return adapter.addOne(action.payload, { ...state, selectedId: null });
        case DELETE_WIKI_SUCCESS:
            return adapter.removeOne(action.payload, { ...state, selectedId: null });
        case FETCH_WIKIS_SUCCESS:
            return adapter.addAll(action.payload, state);
        case FETCH_WIKI_SUCCESS:
            return state.ids.length ?
                adapter.updateOne({
                    id: action.payload._id,
                    changes: action.payload,
                }, { ...state, selectedId: action.payload._id }) :
                adapter.addOne(action.payload, { ...state, selectedId: action.payload._id });
        case FETCH_WIKI_BY_SLUG_SUCCESS:
            return state.ids.length ?
                adapter.updateOne({
                    id: action.payload._id,
                    changes: action.payload,
                }, { ...state, selectedSlug: action.payload.slug }) :
                adapter.addOne(action.payload, { ...state, selectedSlug: action.payload.slug });
        case FETCH_WIKI_FAILURE:
            return { ...state, selectedId: null };
        case FETCH_WIKI_BY_SLUG_FAILURE:
            return { ...state, selectedSlug: null };
        case UPDATE_WIKI_SUCCESS:
            return adapter.updateOne({
                id: action.payload._id,
                changes: action.payload,
            }, state);
        default:
            return state;
    }
}
