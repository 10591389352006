export class ModernStatisticsRecord {
    dateRange: string;
    authorityId: number;
    authority: string;
    totalCards: number;
    uniqueShoppingTrips: number;
    totalShoppingTrips: number;
    registrations: number;
    deviceRegistrations: number;
    deviceUsage: number;
    uniqueProductChecks: number;
    totalProductChecks: number;
}
