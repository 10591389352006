<div class="container">
    <router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>
</div>

<div *ngIf="!hideSelf">
    <div class="row mt-4 mb-4">
        <div class="col-md-12">
            <wic-sub-category-configs-table
                [isLoading]="isLoading$ | async"
                [subCategoryConfigs]="subCategoryConfigs$ | async"
                [categories]="categoriesLookup$ | async"
                (added)="add()"
                (edited)="edit($event)"
                (deleted)="delete($event)"
                (refreshed)="refresh()">
            </wic-sub-category-configs-table>
        </div>
    </div>
</div>
