import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Authority, AuthorityAccount, AuthorityFlag} from '@models';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'wic-authority-detail',
    templateUrl: './wic-authority-detail.component.html',
    styleUrls: ['./wic-authority-detail.component.sass'],
})
export class WicAuthorityDetailComponent implements OnChanges {
    @Input() authority: Authority;
    @Input() account: AuthorityAccount;
    @Input() flags: AuthorityFlag[];
    @Output() edited = new EventEmitter<Authority>();
    @Output() logo = new EventEmitter<Authority>();

    apiUrl = environment.apiRoot;
    noLogoFound = false;

    logoUrl = null;

    ngOnChanges() {
        if (this.authority) {
            setTimeout(() => {
                const rand = Math.random();
                this.logoUrl = this.apiUrl + '/authorities/' + this.authority.id + '/logo?cache=no&' + rand;
                this.noLogoFound = false;
            }, 100);
        }
    }

    isSet(authority: Authority, flag) {
        return (authority.flags & flag.Mask) !== 0; //tslint:disable-line
    }
}
