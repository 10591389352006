import {Component, Input, OnInit} from '@angular/core';
import {IAPLImportStatusCounts, IHistogram} from '@facades/apl-reporting.facade';

@Component({
  selector: 'wic-apl-imports-status',
  templateUrl: './wic-apl-imports-status.component.html',
  styleUrls: ['./wic-apl-imports-status.component.sass']
})
export class WicAplImportsStatusComponent {
    @Input() aplImportStatusCounts: IAPLImportStatusCounts;
    @Input() itemCounts: number[];
    @Input() itemLabels: string[];
    @Input() categoriesCounts: number[];
    @Input() categoriesLabels: string[];
    @Input() subCategoriesCounts: number[];
    @Input() subCategoriesLabels: string[];
}
