import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {APLImportConfig} from '@models/APLImportConfig';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class APLImportConfigService implements IAutoEntityService<APLImportConfig> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: { appId: string }): Observable<APLImportConfig[]> {
        return this.http.get<APLImportConfig[]>(environment.apiRoot + `/admin/apps/${criteria.appId}/apl/imports/config`);
    }

    create(entityInfo: IEntityInfo, entity: APLImportConfig, criteria: { appId: string }): Observable<APLImportConfig> {
        return this.http.post<APLImportConfig>(environment.apiRoot + `/admin/apps/${criteria.appId}/apl/imports/config`,
            entity
        );
    }

    update(entityInfo: IEntityInfo, entity: APLImportConfig, criteria: { appId: string }): Observable<APLImportConfig> {
        return this.http.put<APLImportConfig>(environment.apiRoot +
            `/admin/apps/${criteria.appId}/apl/imports/config/${entity.authorityId}`,
            entity
        );
    }

    delete(entityInfo: IEntityInfo, entity: APLImportConfig, criteria: { appId: string }): Observable<APLImportConfig> {
        return this.http.delete<APLImportConfig>(environment.apiRoot +
            `/admin/apps/${criteria.appId}/apl/imports/config/${entity.authorityId}`
        ).pipe(map(() => entity));
    }
}
