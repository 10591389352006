import { Injectable } from '@angular/core';
import { CreateSuccess, DeleteSuccess, ReplaceSuccess, UpdateSuccess } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ModalControllerService } from 'app/services/modal-controller.service';
import { map, switchMap } from 'rxjs/operators';
import { presentModal } from '../../util/operators';
import { EnhancedIssue } from './enhanced-issue.model';
import { EnhancedIssuesUiService } from './enhanced-issues-ui.service';
import {
  addEditEnhancedIssue,
  confirmDeleteEnhancedIssue,
  loadAllByAuthority,
  moveOrderEnhancedIssue,
} from './enhanced-issues.actions';
import {
  createEnhancedIssue,
  createEnhancedIssueSuccess,
  deleteEnhancedIssue,
  deleteEnhancedIssueSuccess,
  loadAllEnhancedIssues,
  moveEnhancedIssue,
  moveEnhancedIssueSuccess,
  replaceEnhancedIssue,
  replaceEnhancedIssueSuccess,
} from './enhanced-issues.state';

@Injectable()
export class EnhancedIssuesEffects {
  constructor(
    private actions$: Actions,
    private readonly modals: ModalControllerService,
    private enhancedIssueUIService: EnhancedIssuesUiService
  ) {}

  loadAllByAuthority$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllByAuthority),
      map(({ authorityId }) => loadAllEnhancedIssues({ criteria: { parents: { authorities: authorityId } } }))
    )
  );

  addEditEnhancedIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addEditEnhancedIssue),
      switchMap(({ enhancedIssue }) => this.enhancedIssueUIService.openAddEditEnhancedIssue(enhancedIssue)),
      map(entity =>
        entity.issueId
          ? replaceEnhancedIssue({ entity, criteria: { parents: { authorities: entity.authorityId } } })
          : createEnhancedIssue({ entity, criteria: { parents: { authorities: entity.authorityId } } })
      )
    )
  );

  deleteEnhancedIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmDeleteEnhancedIssue),
      presentModal(() =>
        this.modals.createConfirmModal(
          'Delete Enhanced Issue?',
          'Are you sure you wish to delete? This process is irreversible and cannot be undone!'
        )
      ),
      map(([, { enhancedIssue }]) =>
        deleteEnhancedIssue({
          entity: enhancedIssue,
          criteria: { parents: { authorities: enhancedIssue.authorityId } },
        })
      )
    )
  );

  refreshEnhancedIssues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        createEnhancedIssueSuccess,
        replaceEnhancedIssueSuccess,
        moveEnhancedIssueSuccess,
        deleteEnhancedIssueSuccess
      ),
      map(
        (
          action:
            | ReplaceSuccess<EnhancedIssue>
            | CreateSuccess<EnhancedIssue>
            | DeleteSuccess<EnhancedIssue>
            | UpdateSuccess<EnhancedIssue>
        ) => action.entity
      ),
      map((enhancedIssue: EnhancedIssue) =>
        loadAllEnhancedIssues({ criteria: { parents: { authorities: enhancedIssue.authorityId } } })
      )
    )
  );

  moveEnhancedIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveOrderEnhancedIssue),
      map(({ enhancedIssue, newIndex }) =>
        moveEnhancedIssue({
          entity: enhancedIssue,
          criteria: {
            parents: { authorities: enhancedIssue.authorityId },
            query: { newIndex },
          },
        })
      )
    )
  );
}
