import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {APLItem} from '@models';
import {aplItemDeleted, aplItemsFiltered, aplItemsSearched, aplItemUnDeleted} from '@state/apl-item/apl-item.actions';
import {aplItemsLength, filteredAPLItems, filteredAPLItemsLength} from '@state/apl-item/apl-item.selectors';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class APLItemFacade {
    get filteredAPLItems$(): Observable<APLItem[]> {
        return this.store.select(filteredAPLItems);
    }

    get aplItemsLength$(): Observable<number> {
        return this.store.select(aplItemsLength);
    }

    get filteredAPLItemsLength$(): Observable<number> {
        return this.store.select(filteredAPLItemsLength);
    }

    delete(item: APLItem): void {
        this.store.dispatch(aplItemDeleted({item}));
    }

    unDelete(item: APLItem): void {
        this.store.dispatch(aplItemUnDeleted({item}));
    }

    setFilterCriteria(filterCriteria: string): void {
        this.store.dispatch(aplItemsFiltered({filterCriteria}));
    }

    searchAPLItems(searchCriteria: any): void {
        this.store.dispatch(aplItemsSearched({searchCriteria}));
    }

    constructor(private store: Store<IAppState>, private modal: NgbModal) {
    }
}
