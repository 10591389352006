import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BannerPlaylistsFacade} from '@facades/banner-playlists.facade';
import {BannersFacade} from '@facades/banners.facade';
import {PartnersFacade} from '@facades/partners.facade';
import {Banner, BannerPlaylist, Partner} from '@models';
import {Observable} from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';

@Component({
    selector: 'wic-banner-playlist-view',
    templateUrl: './wic-banner-playlist-view.component.html',
    styleUrls: ['./wic-banner-playlist-view.component.sass']
})
export class WicBannerPlaylistViewComponent implements OnInit {
    bannerPlaylist$: Observable<BannerPlaylist>;
    banners$: Observable<Banner[]>;
    partners$: Observable<Partner[]>;

    constructor(
        private route: ActivatedRoute,
        private bannersFacade: BannersFacade,
        private bannerPlaylistsFacade: BannerPlaylistsFacade,
        private partnersFacade: PartnersFacade
    ) {
    }

    ngOnInit() {
        this.bannerPlaylist$ = this.bannerPlaylistsFacade.current.pipe(
            filter(playlist => !!playlist),
            tap(playlist => this.bannersFacade.loadByPlaylist(playlist))
        );
        this.partners$ = this.partnersFacade.all.pipe(
            filter(partners => !!partners && !!partners.length),
            tap(partners => {
                this.banners$ = this.bannersFacade.all.pipe(
                    filter(banners => !!banners && !!banners.length),
                    debounceTime(100),
                    map(banners => banners.map(banner => ({
                        ...banner,
                        Partner: partners.find(partner => partner.PartnerID === banner.PartnerID)
                    })))
                );
            })
        );

        this.partnersFacade.loadAll();

        this.route.paramMap.pipe(
            filter(params => params.has('id') || params.has('bannerId')),
            map(params => +(params.get('id') || params.get('bannerId'))),
        ).subscribe(id => this.bannerPlaylistsFacade.select(id));
    }

    refreshBanners(playlist: BannerPlaylist) {
        this.bannersFacade.loadByPlaylist(playlist);
    }

    editPlaylist(playlist: BannerPlaylist) {
        this.bannerPlaylistsFacade.showEdit(playlist);
    }

    addBanner({ playlist, partners }: { playlist: BannerPlaylist, partners: Partner[] }) {
        this.bannersFacade.showCreate(playlist, partners);
    }

    editBanner({ banner, playlist, partners }: { banner: Banner, playlist: BannerPlaylist, partners: Partner[] }) {
        this.bannersFacade.showEdit(playlist, partners, banner);
    }

    deleteBanner(banner: Banner) {
        this.bannersFacade.showDelete(banner);
    }

    moveBanner({ banner, to }: { banner: Banner; to: number; }) {
        this.bannersFacade.move(banner, to);
    }
}
