import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wic-alert-modal',
    templateUrl: './wic-alert-modal.component.html',
    styleUrls: ['./wic-alert-modal.component.sass']
})
export class WicAlertModalComponent {
    @Input() modalTitle: string;
    @Input() message: string;

    constructor(public activeModal: NgbActiveModal) {
    }
}
