import { ILookup } from "@models/types";
import { StandardCategory } from "./standard-category.model";
import { allStandardCategories } from "./standard-categories.state";
import { createSelector } from "@ngrx/store";
import { allSubcategoryTranslationGroups } from "app/subcategory-translations/feature/subcategory-translation-groups/subcategory-translation-groups.state";

export const createLookupsStandardCategories = (categories: StandardCategory[]) =>
  categories.map((category): ILookup => ({ id: category.category, name: category.categoryName }));

export const standardCategoriesAsLookup = createSelector(allStandardCategories, categories => createLookupsStandardCategories(categories));

export const standardCategoriesMinusExisting = createSelector(standardCategoriesAsLookup, allSubcategoryTranslationGroups, (categories, subcategoryTranslationGroups) =>
  categories.filter(category => !subcategoryTranslationGroups.some(subcategoryTranslationGroup => subcategoryTranslationGroup.category === category.id))
);