import {Injectable} from '@angular/core';
import {LoadAll, Select} from '@briebug/ngrx-auto-entity';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {CategoryLookup, CategoryTree, SubCategoryLookup} from '@models';
import {IAppState} from '@state/app.state';
import {allCategoryLookups, categoryLookupTree, currentCategoryLookup} from '@state/category-lookups.state';
import {allSubCategoryLookups, currentSubCategoryLookup} from '@state/sub-category-lookup.state';

@Injectable({
    providedIn: 'root'
})
export class CategoryLookupsFacade {
    constructor(private store: Store<IAppState>) {
    }

    // region Selections
    allCategories$ = this.store.pipe(select(allCategoryLookups));
    allSubCategories$ = this.store.pipe(select(allSubCategoryLookups));
    categoryTree$ = this.store.pipe(select(categoryLookupTree));
    currentCategory$ = this.store.pipe(select(currentCategoryLookup));
    currentSubCategory$ = this.store.pipe(select(currentSubCategoryLookup));
    // endregion

    // region Dispatches
    setCurrentCategory(category: CategoryLookup) {
        this.store.dispatch(new Select(CategoryLookup, category));
    }

    setCurrentSubCategory(category: SubCategoryLookup) {
        this.store.dispatch(new Select(SubCategoryLookup, category));
    }

    loadAll() {
        this.loadAllCategories();
        this.loadAllSubCategories();
    }

    loadAllCategories() {
        this.store.dispatch(new LoadAll(CategoryLookup));
    }

    loadAllSubCategories() {
        this.store.dispatch(new LoadAll(SubCategoryLookup));
    }
    // endregion
}
