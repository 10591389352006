import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {ObjectId} from '@models/types';

@Entity({
    modelName: 'SubCategoryConfig'
})
export class SubCategoryConfig {
    // tslint:disable-next-line:variable-name
    @Key _id?: ObjectId;
    categoryId: number;
    categoryDescription: string;
    subCategoryId: number;
    subCategoryDescription: string;
    image?: string;
    linkDetails?: LinkDetail[];
}

export class LinkDetail {
    label: string;
    url: string;
    icon?: string;
}
