import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { AuthorityMappingsService } from '@services';
import {
    CREATE_AUTHORITY_MAPPING,
    CreateAuthorityMappingAction,
    CreateAuthorityMappingFailureAction,
    CreateAuthorityMappingSuccessAction,
    DELETE_AUTHORITY_MAPPING,
    DeleteAuthorityMappingAction,
    DeleteAuthorityMappingFailureAction,
    DeleteAuthorityMappingSuccessAction,
    FETCH_AUTHORITY_MAPPINGS,
    FetchAuthorityMappingsFailureAction,
    FetchAuthorityMappingsSuccessAction,
    UPDATE_AUTHORITY_MAPPING,
    UpdateAuthorityMappingAction,
    UpdateAuthorityMappingFailureAction,
    UpdateAuthorityMappingSuccessAction,
} from './authority-mappings.actions';
import { AuthorityMapping } from './model';

@Injectable()
export class AuthorityMappingsEffects {
    @Effect()
    fetchAuthorityMappings$ = this.actions.pipe(
        ofType(FETCH_AUTHORITY_MAPPINGS),
        exhaustMap(() => this.authorityMappingsService.list().pipe(
            map(authorityMappings => new FetchAuthorityMappingsSuccessAction(authorityMappings)),
            catchError(err => of(new FetchAuthorityMappingsFailureAction(err)))
        ))
    );

    @Effect()
    createAuthorityMapping$ = this.actions.pipe(
        ofType(CREATE_AUTHORITY_MAPPING),
        map((action: CreateAuthorityMappingAction) => action.payload),
        exhaustMap((authorityMapping: AuthorityMapping) => this.authorityMappingsService.create(authorityMapping).pipe(
            map(resAuthorityMapping => new CreateAuthorityMappingSuccessAction(resAuthorityMapping)),
            catchError(err => of(new CreateAuthorityMappingFailureAction(err)))
        ))
    );

    @Effect()
    updateAuthorityMapping$ = this.actions.pipe(
        ofType(UPDATE_AUTHORITY_MAPPING),
        map((action: UpdateAuthorityMappingAction) => action.payload),
        exhaustMap((authorityMapping: AuthorityMapping) => this.authorityMappingsService.update(authorityMapping).pipe(
            map(() => new UpdateAuthorityMappingSuccessAction()),
            catchError(err => of(new UpdateAuthorityMappingFailureAction(err)))
        ))
    );

    @Effect()
    deleteAuthorityMapping: Observable<Action> = this.actions.pipe(
        ofType(DELETE_AUTHORITY_MAPPING),
        map((action: DeleteAuthorityMappingAction) => action.payload),
        exhaustMap((authorityMapping: AuthorityMapping) => this.authorityMappingsService.delete(authorityMapping.binCode).pipe(
            map(() => new DeleteAuthorityMappingSuccessAction(authorityMapping)),
            catchError(err => of(new DeleteAuthorityMappingFailureAction(err)))
        ))
    );

    constructor(private actions: Actions, private authorityMappingsService: AuthorityMappingsService) {
    }
}
