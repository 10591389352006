<div class="container">
    <router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>
</div>

<div *ngIf="!hideSelf">
    <div class="row">
        <div class="col-md-12">
            <wic-error-reports-table
                [errorReports]="errorReports.mostRecent$ | async"
                [showToolbar]="true"
                [lite]="true"
                (refreshed)="errorReports.loadAll()"
                (selected)="viewReport($event)">
            </wic-error-reports-table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <wic-device-logs-table
                [sessions]="sessions.sorted$ | async"
                [showToolbar]="true"
                [lite]="true"
                (refreshed)="sessions.loadAll()"
                (selected)="viewSession($event)"
                (deleted)="sessions.delete($event)">
            </wic-device-logs-table>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <wic-enhanced-devices-table
                        [devices]="enhancedDevices.sorted$ | async"
                        [showToolbar]="true"
                        [lite]="true"
                        (toggled)="enhancedDevices.toggle($event)"
                        (refreshed)="enhancedDevices.loadAll()"
                        (deleted)="enhancedDevices.delete($event)">
                    </wic-enhanced-devices-table>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                </div>
            </div>
        </div>
    </div>
</div>
