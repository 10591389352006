import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {DeviceLogEntry} from '@models';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export interface EntryCriteria {
    udid: string;
    sessionId: string;
    since?: string;
}

@Injectable({
    providedIn: 'root'
})
export class DeviceLogEntryService implements IAutoEntityService<DeviceLogEntry> {
    constructor(private http: HttpClient) {
    }

    loadAll(info: IEntityInfo, criteria: EntryCriteria): Observable<DeviceLogEntry[]> {
        return this.http.get<DeviceLogEntry[]>(
            `${environment.apiRoot}/devices/${criteria.udid}/logs/sessions/${criteria.sessionId}/entries`
        );
    }

    loadMany(info: IEntityInfo, criteria: EntryCriteria): Observable<DeviceLogEntry[]> {
        return this.http.get<DeviceLogEntry[]>(
            `${environment.apiRoot}/devices/${criteria.udid}/logs/sessions/${criteria.sessionId}/entries`, {
            params: {
                ...(criteria.since && {since: criteria.since})
            }
        });
    }
}
