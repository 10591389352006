export class WikiArticle {
    // tslint:disable-next-line:variable-name
    _id?: string;
    title: string;
    slug: string;
    markdown: string;
    author: string;
    createdAt: string;
    updatedAt: string;
}
