<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
        <span class="badge badge-info">
            {{binCode.BINCode}}
        </span>
            Edit BIN Code
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <label for="name" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.name.invalid">Name</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="name" formControlName="name"
                       [class.is-invalid]="submitted && f.name.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="BINCode" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.BINCode.invalid">BIN Code</label>
            <div class="col-sm-10">
                <input type="number" class="form-control" id="BINCode" formControlName="BINCode"
                       [class.is-invalid]="submitted && f.BINCode.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="endpoint" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.endpoint.invalid">Endpoint</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="endpoint" formControlName="endpoint"
                       [class.is-invalid]="submitted && f.endpoint.invalid">
            </div>
        </div>

        <ng-container *ngIf="f.integration.value !== 'cdpRest'; else tokenCredentials">
        <div class="form-group row">
            <label for="username" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.username.invalid">Username</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="username" formControlName="username"
                       [class.is-invalid]="submitted && f.username.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="password" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.password.invalid">Password</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="password" formControlName="password"
                       [class.is-invalid]="submitted && f.password.invalid">
            </div>
        </div>
        </ng-container>
        <ng-template #tokenCredentials>
            <div class="form-group row">
                <label for="clientId" class="col-sm-2 col-form-label"
                       [class.text-danger]="submitted && f.clientId.invalid">Client Id</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="clientId" formControlName="clientId"
                           [class.is-invalid]="submitted && f.clientId.invalid">
                </div>
            </div>
            <div class="form-group row">
                <label for="clientSecret" class="col-sm-2 col-form-label"
                       [class.text-danger]="submitted && f.clientSecret.invalid">Client Secret</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="clientSecret" formControlName="clientSecret"
                           [class.is-invalid]="submitted && f.clientSecret.invalid">
                </div>
            </div>
            <div class="form-group row">
                <label for="clientUrl" class="col-sm-2 col-form-label"
                       [class.text-danger]="submitted && f.clientUrl.invalid">Client Url</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="clientUrl" formControlName="clientUrl"
                           [class.is-invalid]="submitted && f.clientUrl.invalid">
                </div>
            </div>
        </ng-template>

        <div class="form-group row">
            <label for="systemId" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.systemId.invalid">System ID</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="systemId" formControlName="systemId"
                       [class.is-invalid]="submitted && f.systemId.invalid">
            </div>
        </div>

        <div class="form-group row">
            <label for="integration" class="col-sm-2 col-form-label"
                [class.text-danger]="submitted && f.integration.invalid">Integration</label>
            <div class="col-sm-10">
                <select class="form-control"
                        formControlName="integration"
                        id="integration"
                        name="integration">
                    <option value="" selected hidden>(Choose Integration)</option>
                    <option value="cdp">CDP Universal</option>
                    <option value="cdpr">CDP REST (Offline)</option>
                    <option value="wem">WEM</option>
                    <option value="conduent">Conduent</option>
                    <option value="cdpRest">CDP REST</option>
                    <option value="solutran">Solutran</option>
                    <option value="local">Local (Imported to JPMA)</option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isEnabled" formControlName="isEnabled">
                    <label class="form-check-label" for="isEnabled">Enable BIN Code integration to third party</label>
                </div>
            </div>
        </div>

        <div class="form-group row">
          <label for="proxyId" class="col-sm-2 col-form-label"
                 [class.text-danger]="submitted && f.proxyId.invalid">Proxy Authority ID</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="proxyId" formControlName="proxyId"
                   [class.is-invalid]="submitted && f.proxyId.invalid">
          </div>
        </div>

        <div class="form-group row">
            <label for="mISSystemId" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.mISSystemId.invalid">WIC MIS System ID</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="mISSystemId" formControlName="mISSystemId"
                       [class.is-invalid]="submitted && f.mISSystemId.invalid">
            </div>
        </div>

        <div class="form-group row">
            <label for="connectSystemId" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.connectSystemId.invalid">WIC Connect System ID</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="connectSystemId" formControlName="connectSystemId"
                       [class.is-invalid]="submitted && f.connectSystemId.invalid">
            </div>
        </div>

        <div class="form-group row">
            <label for="workstationId" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.workstationId.invalid">Workstation ID</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="workstationId" formControlName="workstationId"
                       [class.is-invalid]="submitted && f.workstationId.invalid">
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
