import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CardsService {
    constructor(private http: HttpClient) {
    }

    sampleCardNumber(authorityId): Observable<{ cardNumber: string }> {
        return this.http.get<{ cardNumber: string }>(`${environment.apiRoot}/admin/cards/sample`, {
            params: {
                authorityId
            }
        });
    }
}
