<router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>

<div *ngIf="!hideSelf">
    <div class="row">
        <div class="col-md-12">
            <wic-user-detail
                [user]="users.current$ | async"
                (edited)="users.editExistingUser($event)"
                (deleted)="users.confirmDelete($event)">
            </wic-user-detail>
        </div>
    </div>
</div>
