import {Component} from '@angular/core';
import {BenefitImportJobFacade} from '@facades/benefit-import-job.facade';
import {MbscCardOptions, MbscFormOptions} from '@mobiscroll/angular';
import {CardStatusFacade} from '@facades/card-status.facade';

@Component({
    selector: 'wic-import-job-details',
    templateUrl: './import-job-details.component.html',
    styleUrls: ['./import-job-details.component.scss']
})
export class ImportJobDetailsComponent {
    formSettings: MbscFormOptions = {
        theme: 'ios',
        themeVariant: 'light'
    };
    settings: MbscCardOptions = {
        theme: 'ios',
        themeVariant: 'light'
    };

    cardStatusFilters = {
        IDENTIFIED: '#888888',
        PARSED: '#888888',
        STAGED: '#888888',
        PUBLISHED: '#7cda87',
        ERRORED: '#f04141',
        DELAYED: '#ffce00',
    };
    legacyCardStatusFilters = {
        PENDING: '#888888',
        ERRORED: '#f04141',
        DELAYED: '#ffce00',
        IMPORTED: '#7cda87',
    };

    constructor(public benefitImportJobs: BenefitImportJobFacade, public cardStatuses: CardStatusFacade) {
    }
}
