import { AfterViewInit,ChangeDetectorRef,Component,Input,OnChanges,OnInit} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilService } from "@services";
import { EnhancedIssue } from "../../feature/enhanced-issue.model";
import { EnhancedIssueTranslation } from "app/enhanced-issues-translations/feature/enhanced-issue-translations.model";
import { EnhancedIssuesTranslationsFacade } from '../../../enhanced-issues-translations/feature/enhanced-issues-translations.facade';

@Component({
  selector: 'wic-enhanced-issues-add-edit',
  templateUrl: './enhanced-issues-add-edit-modal.component.html',
})
export class EnhancedIssuesAddEditComponent implements OnInit {
  @Input() enhancedIssue: EnhancedIssue;
  
  form: FormGroup;

  get f() {
    return this.form.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    public util: UtilService,
    public enhancedIssueTranslationsFacade: EnhancedIssuesTranslationsFacade
  ) {}

  ngOnInit() {
    this.form = this.buildForm();
    this.populateForm();
  }

  private buildForm() {
    return this.formBuilder.group({
      issueName: [undefined, Validators.required],
      link: [undefined, Validators.required],
    });
  }

  populateForm() {
    if (!this.form) {
      return
    }
    
    this.form.patchValue({
      issueName: this.enhancedIssue.issueName,
      link: this.enhancedIssue.link
    });
  }

  save() {
    const value = this.form.value;

    if (!this.form.valid) {
        return;
    }

    const enhancedIssue = {
      ...(this.enhancedIssue || {}),
      ...value,
    };
    
    this.activeModal.close(enhancedIssue);
  }
  
  addTranslations(enhancedIssueTranslations: EnhancedIssueTranslation[]) {
    this.enhancedIssueTranslationsFacade.addNewLanguage(enhancedIssueTranslations, this.enhancedIssue);
  }

  deleteTranslations(enhancedIssueTranslations: EnhancedIssueTranslation[]) {
    this.enhancedIssueTranslationsFacade.deleteLanguage(enhancedIssueTranslations, this.enhancedIssue);
  }

  updateEnhancedIssueTranslations(enhancedIssueTranslations: EnhancedIssueTranslation[]) {
    this.enhancedIssueTranslationsFacade.updateEnhancedIssueTranslations(enhancedIssueTranslations, this.enhancedIssue);
  }
}