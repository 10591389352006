import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Observable} from 'rxjs';

import {APLMessage} from '@models';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class APLMessageService implements IAutoEntityService<APLMessage> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<APLMessage[]> {
        const url = (criteria && criteria.authorityId) ?
            `/admin/authorities/${criteria.authorityId}/apl/messages` : '/admin/apl/messages';
        return this.http.get<APLMessage[]>(environment.apiRoot + url);
    }

    create(entityInfo: IEntityInfo, entity: APLMessage): Observable<APLMessage> {
        return this.http.post<APLMessage>(environment.apiRoot + '/admin/apl/messages', entity);
    }

    update(entityInfo: IEntityInfo, entity: APLMessage): Observable<APLMessage> {
        return this.http.put<APLMessage>(environment.apiRoot + `/admin/apl/messages/${entity._id}`, entity);
    }

    delete(entityInfo: IEntityInfo, entity: APLMessage): Observable<APLMessage> {
        return this.http.delete<APLMessage>(environment.apiRoot + `/admin/apl/messages/${entity._id}`)
            .pipe(map(() => entity));
    }
}
