import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UtilService } from '@services';

@Pipe({
  name: 'formatFormError'
})
export class FormatFormErrorPipe implements PipeTransform {
  constructor(private util: UtilService) {
  }

  transform(control: AbstractControl): string {
    return control.touched ? this.util.formatError(control.errors) : null;
  }

}
