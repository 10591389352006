<div class="row">
    <div class="col-md-12">
        <wic-sub-category-config-detail
            [subCategoryConfig]="subCategoryConfig$ | async"
            [categories]="categories$ | async"
            [subCategories]="subCategories$ | async"
            (edited)="editConfig($event)">
        </wic-sub-category-config-detail>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <wic-sub-category-config-line-items
            [subCategoryConfig]="subCategoryConfig$ | async"
            (added)="updateLineItem($event, null)"
            (edited)="updateLineItem($event.subCategoryConfig, $event.linkDetail)"
            (deleted)="deleteLineItem($event.subCategoryConfig, $event.linkDetail)">
        </wic-sub-category-config-line-items>
    </div>
</div>
