<div class="row">
    <div class="col-md-12">
        <wic-authorities-basic-report
            [authoritiesInfo]="authoritiesInfo$ | async">
        </wic-authorities-basic-report>
    </div>
    <div class="col-md-12">
        <wic-apl-imports-status
            [aplImportStatusCounts]="importStatusCounts$ | async"
            [itemCounts]="itemCounts$ | async"
            [itemLabels]="itemLabels$ | async"
            [categoriesCounts]="categoryCounts$ | async"
            [categoriesLabels]="categoryLabels$ | async"
            [subCategoriesCounts]="subCategoryCounts$ | async"
            [subCategoriesLabels]="subCategoryLabels$ | async">
        </wic-apl-imports-status>
    </div>
    <div class="col-md-12 mt-5">
        <wic-modern-statistics-report
            [isLoading]="modernStatisticsLoadingStatus$ | async"
            [report]="modernStatisticsReport$ | async"
            [totals]="modernStatisticsTotals$ | async"
            (dateRangeChanged)="modernDateRangeChanged($event)">
        </wic-modern-statistics-report>
    </div>
</div>
