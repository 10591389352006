import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UsersFacade} from '@facades/users.facade';
import {filter, map} from 'rxjs/operators';

@Component({
    selector: 'wic-user-view',
    templateUrl: './wic-user-view.component.html',
    styleUrls: ['./wic-user-view.component.sass'],
})
export class WicUserViewComponent implements OnInit {
    hideSelf = false;

    constructor(public users: UsersFacade, private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.activatedRoute.params.pipe(
            filter(params => !!params.id),
            map(params => params.id)
        ).subscribe(id => {
            this.users.selectByKey(id);
        });
    }
}
