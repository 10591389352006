import {Injectable} from '@angular/core';
import {ErrorReport} from '@models';
import {Store} from '@ngrx/store';
import {AppState} from '@state/app.state';
import {ErrorReportsFacadeBase, mostRecentReports} from '@state/error-report.state';

@Injectable({providedIn: 'root'})
export class ErrorReportsFacade extends ErrorReportsFacadeBase {
    mostRecent$ = this.store.select(mostRecentReports);

    constructor(private store: Store<AppState>) {
        super(ErrorReport, store);
    }
}
