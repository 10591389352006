import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'LocalBenefit'
})
export class LocalBenefit {
    // tslint:disable-next-line:variable-name
    @Key _id: string;
    authorityId: number;
    cardNumber: string;
    lastImportedDate: string;
    startDateUTC: string;
    endDateUTC: string;
    benefits: LocalBenefitEntry[];
}

export class LocalBenefitEntry {
    categoryId: number;
    subCategoryId: number;
    quantity: number;
    beginDate: string;
    endDate: string;
}
