import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DeviceLogEntryFacade} from '@facades/device-log-entry.facade';
import {DeviceLogSessionFacade} from '@facades/device-log-session.facade';
import {DeviceLogEntry} from '@models';
import * as moment from 'moment';
import {asyncScheduler, interval, Observable} from 'rxjs';
import {filter, map, observeOn, startWith, switchMap, tap, withLatestFrom} from 'rxjs/operators';

const DONT_LOAD_MORE = -1;

@Component({
    selector: 'wic-device-log-entries-list',
    templateUrl: './device-log-entries-list.component.html',
    styleUrls: ['./device-log-entries-list.component.scss']
})
export class DeviceLogEntriesListComponent implements OnInit, OnDestroy {
    sortedEntries$: Observable<DeviceLogEntry[]>;
    lastLoadedAt = undefined;

    constructor(public sessions: DeviceLogSessionFacade, public entries: DeviceLogEntryFacade, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.sortedEntries$ = this.route.paramMap.pipe(
            observeOn(asyncScheduler),
            filter(params => params.has('udid') && params.has('sessionId')),
            map(params => ({udid: params.get('udid'), sessionId: params.get('sessionId')})),
            tap(({udid, sessionId}) => this.sessions.selectByKey(`${udid}_${sessionId}`)),
            tap(keys => this.entries.loadAll(keys)),
            tap(() => this.lastLoadedAt = new Date().toISOString()),
            switchMap(keys => this.entries.sorted$.pipe(
                tap(entries => this.lastLoadedAt = (!!entries && !!entries.length) ? entries[0].shippedAt : this.lastLoadedAt),
                withLatestFrom(interval(10000).pipe(
                    startWith(DONT_LOAD_MORE),
                    map(shouldLoad => moment(this.lastLoadedAt).isAfter(moment().add(-6, 'hours')) ? shouldLoad : DONT_LOAD_MORE),
                    tap(shouldLoad => shouldLoad !== DONT_LOAD_MORE ? this.entries.loadMany({...keys, since: this.lastLoadedAt}) : false)
                )),
                map(([entries]) => entries)
            )),
        );
    }

    ngOnDestroy(): void {
        this.entries.clear();
    }
}
