import { Component, EventEmitter, Injectable, Input, OnChanges, Output } from '@angular/core';
import { AuthFacade } from '@facades/auth.facade';

@Component({
  selector: 'wic-error',
  templateUrl: './wic-error.component.html',
  styleUrls: ['./wic-error.component.sass'],
})
@Injectable()
export class WicErrorComponent implements OnChanges {
  @Input() public errors: any[];
  @Output() public dismiss: EventEmitter<any> = new EventEmitter<any>();

  isExpanded = false;

  ngOnChanges() {
    if (this.errors && this.errors.length && this.errors[0].message === 'No JWT present or has expired') {
      this.auth.logout();
    }
  }

  constructor(private auth: AuthFacade) {}
}
