import {Component, Input} from '@angular/core';
import {ErrorReport} from '@models';

@Component({
    selector: 'wic-error-report-screenshot',
    templateUrl: './error-report-screenshot.component.html',
    styleUrls: ['./error-report-screenshot.component.scss']
})
export class ErrorReportScreenshotComponent {
    @Input() report: ErrorReport;
}
