import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { loadAllForTranslations } from "./standard-categories.actions";
import { map } from "rxjs/operators";
import { loadAllStandardCategories } from "./standard-categories.state";

@Injectable()
export class StandardCategoriesEffects {

  constructor(
    private actions$: Actions
  ) {}

  loadAllForTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllForTranslations),
      map(() => loadAllStandardCategories())
    )
  );
}