import { Component, OnInit } from '@angular/core';
import { APLImportConfigsFacade } from '@facades/apl-import-configs.facade';
import { APLOverridesFacade } from '@facades/apl-overrides.facade';
import { AuthoritiesFacade } from '@facades/authorities.facade';
import { CategoryLookupsFacade } from '@facades/category-lookups.facade';
import { APLImportConfig, APLOverride, Authority, CategoryLookup, SubCategoryLookup } from '@models';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

@Component({
  selector: 'wic-apl-imports',
  templateUrl: './wic-apl-imports.component.html',
  styleUrls: ['./wic-apl-imports.component.sass'],
})
export class WicAplImportsComponent implements OnInit {
  configs$: Observable<APLImportConfig[]>;
  forDeletionConfig$: Observable<APLImportConfig>;
  isLoadingConfigs$: Observable<boolean>;
  overrides$: Observable<APLOverride[]>;
  forDeletionOverride$: Observable<APLOverride>;
  isLoadingOverrides$: Observable<boolean>;
  categories$: Observable<CategoryLookup[]>;
  subCategories$: Observable<SubCategoryLookup[]>;

  hideSelf = false;

  private authorities: Authority[];
  private configs: APLImportConfig[];
  private overrides: APLOverride[];
  private categories: CategoryLookup[];
  private subCategories: SubCategoryLookup[];

  constructor(
    private aplImportConfigsFacade: APLImportConfigsFacade,
    private aplOverrideFacade: APLOverridesFacade,
    private authoritiesFacade: AuthoritiesFacade,
    private categoryLoookupsFacade: CategoryLookupsFacade
  ) {}

  ngOnInit() {
    this.forDeletionConfig$ = this.aplImportConfigsFacade.forDeletion.asObservable();
    this.isLoadingConfigs$ = this.aplImportConfigsFacade.selectIsLoading;

    this.overrides$ = this.aplOverrideFacade.selectAll().pipe(tap(overrides => (this.overrides = overrides)));

    this.forDeletionOverride$ = this.aplOverrideFacade.forDeletion.asObservable();
    this.isLoadingOverrides$ = this.aplOverrideFacade.selectIsLoading();

    this.authoritiesFacade.all$
      .pipe(
        first(authorities => !!authorities && !!authorities.length),
        tap(authorities => {
          this.configs$ = this.aplImportConfigsFacade.selectAll.pipe(
            tap(configs => (this.configs = configs)),
            map(configs =>
              configs.map(config => ({
                ...config,
                authority: (authorities.find(authority => authority.id === config.authorityId) || ({} as Authority))
                  .name,
              }))
            )
          );
        })
      )
      .subscribe(authorities => (this.authorities = authorities));

    this.categories$ = this.categoryLoookupsFacade.allCategories$.pipe(
      tap(categories => (this.categories = categories))
    );

    this.subCategories$ = this.categoryLoookupsFacade.allSubCategories$.pipe(
      tap(subCategories => (this.subCategories = subCategories))
    );

    this.authoritiesFacade.loadAll();
    this.categoryLoookupsFacade.loadAll();

    this.refreshConfigs();
    this.refreshOverrides();
  }

  addConfig() {
    this.aplImportConfigsFacade.showCreate(this.configs, this.authorities);
  }

  deleteConfig(config: APLImportConfig) {
    this.aplImportConfigsFacade.showDelete(config);
  }

  addOverride() {
    this.aplOverrideFacade.showCreate(this.overrides, null, this.categories, this.subCategories);
  }

  editOverride(override: APLOverride) {
    this.aplOverrideFacade.showEdit(override, this.overrides, this.categories, this.subCategories);
  }

  deleteOverride(override: APLOverride) {
    this.aplOverrideFacade.showDelete(override);
  }

  refreshConfigs() {
    this.aplImportConfigsFacade.loadAll();
  }

  refreshOverrides() {
    this.aplOverrideFacade.loadAllShared();
  }

  deactivated() {
    this.hideSelf = false;
    this.refreshOverrides();
  }
}
