<div class="sub-navbar" *ngIf="showLayout">
    <div class="container">
        <div class="row">
            <div class="sub-navbar-column col-xs-12 d-none d-md-block">
                <div class="sub-navbar-header mx-3">
                    <h3 class="muted">{{area}}</h3>
                </div>
            </div>
            <ol class="breadcrumb ml-3">
                <li class="breadcrumb-item">
                    <a [routerLink]="'/admin'">
                        <i class="fa fa-fw fa-home"></i>
                    </a>
                </li>
                <li class="breadcrumb-item" [class.active]="last" *ngFor="let crumb of crumbs; let last = last">
                    <a *ngIf="!last" [routerLink]="[crumb.path]">{{crumb.title}}</a>
                    <span *ngIf="last">{{crumb.title}}</span>
                </li>
            </ol>
        </div>
    </div>
</div>
