import {ModernStatisticsRecord} from '@models/ModernStatisticsRecord';
import {Action, createAction, createReducer, createSelector, on, props} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import * as moment from 'moment';

export const reportsLoaded = createAction(
    '[Reporting] Load reports'
);

export const loadModernStatisticsReport = createAction(
    '[Reporting] Load modern statistics report',
    props<{ startDate?: string, endDate?: string }>()
);

export const modernStatisticsReportLoaded = createAction(
    '[Reporting] Modern statistics report loaded',
    props<{ report: ModernStatisticsRecord[] }>()
);

export const errorLoadingModernStatisticsReport = createAction(
    '[Reporting] Error loading modern statistics report',
    props<{ error: any }>()
);

export interface IModernStatisticsState {
    modernStatisticsReport?: ModernStatisticsRecord[];
    pendingCriteria?: { startDate: string, endDate: string };
    criteria?: { startDate: string, endDate: string };
    isLoading: boolean;
    loadStart?: string;
    loadEnd?: string;
    loadDuration?: string;
    loadedAt?: number;
}

export const initialState: IModernStatisticsState = {
    isLoading: false
};

const reduce = createReducer(
    initialState,
    on(loadModernStatisticsReport, (state, {startDate, endDate}) => ({
        ...state,
        isLoading: true,
        pendingCriteria: {startDate, endDate},
        loadDuration: undefined,
        loadStart: moment().format(),
        loadEnd: undefined,
    })),
    on(modernStatisticsReportLoaded, (state, {report}) => ({
        ...state,
        isLoading: false,
        pendingCriteria: undefined,
        criteria: {startDate: state.pendingCriteria.startDate, endDate: state.pendingCriteria.endDate},
        modernStatisticsReport: report,
        loadEnd: moment().format(),
        loadDuration: `${moment.duration(moment().diff(state.loadStart)).asSeconds()} seconds`,
        loadedAt: Date.now()
    })),
    on(errorLoadingModernStatisticsReport, (state) => ({
        ...state,
        isLoading: false,
    }))
);

export function modernStatisticsReducer(state: IModernStatisticsState, action: Action): IModernStatisticsState {
    return reduce(state, action);
}

export const getModernStatisticsState = (state: IAppState): IModernStatisticsState =>
    state.modernStatistics;

export const getReport = (state: IModernStatisticsState): ModernStatisticsRecord[] =>
    state.modernStatisticsReport;

export const getCriteria = (state: IModernStatisticsState): { startDate: string, endDate: string } =>
    state.criteria;

export const getIsLoading = (state: IModernStatisticsState): boolean =>
    state.isLoading;

export const getLoadedAt = (state: IModernStatisticsState): number =>
    state.loadedAt;

export const getLoadDuration = (state: IModernStatisticsState): string =>
    state.loadDuration;

export const makeEmptyRecord = () => ({
    dateRange: null,
    authorityId: null,
    authority: null,
    totalCards: 0,
    uniqueShoppingTrips: 0,
    totalShoppingTrips: 0,
    registrations: 0,
    deviceRegistrations: 0,
    deviceUsage: 0,
    uniqueProductChecks: 0,
    totalProductChecks: 0
});

export const mapToTotals = (state: IModernStatisticsState): ModernStatisticsRecord =>
    (!state.modernStatisticsReport || !state.modernStatisticsReport.length)
        ? makeEmptyRecord()
        : state.modernStatisticsReport.reduce((totals, record) => ({
            ...totals,
            totalCards: totals.totalCards + (record.totalCards || 0),
            uniqueShoppingTrips: totals.uniqueShoppingTrips + (record.uniqueShoppingTrips || 0),
            totalShoppingTrips: totals.totalShoppingTrips + (record.totalShoppingTrips || 0),
            registrations: totals.registrations + (record.registrations || 0),
            deviceRegistrations: totals.deviceRegistrations + (record.deviceRegistrations || 0),
            deviceUsage: totals.deviceUsage + (record.deviceUsage || 0),
            uniqueProductChecks: totals.uniqueProductChecks + (record.uniqueProductChecks || 0),
            totalProductChecks: totals.totalProductChecks + (record.totalProductChecks || 0)
        }), makeEmptyRecord());

export const modernStatisticsReport = createSelector(getModernStatisticsState, getReport);
export const modernStatisticsTotals = createSelector(getModernStatisticsState, mapToTotals);
export const modernStatisticsCriteria = createSelector(getModernStatisticsState, getCriteria);
export const modernStatisticsLoadedAt = createSelector(getModernStatisticsState, getLoadedAt);
export const modernStatisticsLoadDuration = createSelector(getModernStatisticsState, getLoadDuration);
export const modernStatisticsLoadStatus = createSelector(getModernStatisticsState, getIsLoading);
