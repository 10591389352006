<div class="row mt-5" *ngIf="article$ | async as article; else notFound">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                <h2 class="text-muted">{{article.title}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="markdown mt-3 pt-3">
                    <ngx-md [data]="article.markdown"></ngx-md>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #notFound>Article not found...</ng-template>
