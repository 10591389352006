<div class="card mt-5 mb-5">
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="playlists?.length">
            <thead>
            <tr>
                <th class="text-nowrap"><i class="fa fa-key"></i></th>
                <th class="">Name</th>
                <th class="text-center">Active</th>
                <th class="text-center"><i class="fa fa-wrench"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let playlist of playlists">
                <td class="">{{playlist.PlaylistID}}</td>
                <td class=""><a href="" (click)="$event.preventDefault(); edited.emit(playlist);">{{playlist.PlaylistName}}</a></td>
                <td class="text-center">
                    <span class="badge badge-pill badge-default text-white"
                          [class.badge-danger]="!playlist.IsActive"
                          [class.badge-success]="playlist.IsActive">
                        {{playlist.IsActive ? 'Yes' : 'No'}}
                    </span>
                </td>
                <td class="text-center">
                    <i class="fa fa-edit" (click)="edited.emit(playlist)"></i>
                    <i class="fa fa-trash" style="padding-left: 8px" (click)="remove.emit(playlist)"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!playlists?.length" class="card-body">
        <p><em>There are no banner playlists.</em></p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
                <button class="btn btn-outline-primary mr-2" (click)="added.emit()">
                    <i class="fa fa-plus"></i> Add Playlist
                </button>
            </div>
        </div>
    </div>
</div>
