import {Component, Input} from '@angular/core';
import {Crumb} from '@models';

@Component({
    selector: 'wic-sub-nav',
    templateUrl: './sub-nav.component.html',
    styleUrls: ['./sub-nav.component.sass'],
})
export class SubNavComponent {
    @Input() showLayout: boolean;
    @Input() crumbs: Crumb[];
    @Input() area: string;
    @Input() areaPath: string;
}
