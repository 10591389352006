import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {APLMessageText} from '@models';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class APLMessageTextService implements IAutoEntityService<APLMessageText> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<APLMessageText[]> {
        return this.http.get<APLMessageText[]>(environment.apiRoot + `/admin/apl/messages/${criteria.messageId}/texts`);
    }

    create(entityInfo: IEntityInfo, entity: APLMessageText): Observable<APLMessageText> {
        return this.http.post<APLMessageText>(environment.apiRoot +
            `/admin/apl/messages/${entity.messageId}/texts/${entity.languageCode}`,
            entity
        );
    }

    updateMany(entityInfo: IEntityInfo, entities: APLMessageText[], criteria?: any): Observable<APLMessageText[]> {
        return this.http.put<APLMessageText[]>(environment.apiRoot +
            `/admin/apl/messages/${criteria.messageId}/texts`,
            entities
        );
    }

    update(entityInfo: IEntityInfo, entity: APLMessageText): Observable<APLMessageText> {
        return this.http.put<APLMessageText>(environment.apiRoot +
            `/admin/apl/messages/${entity.messageId}/texts/${entity.languageCode}`,
            entity
        );
    }

    delete(entityInfo: IEntityInfo, entity: APLMessageText): Observable<APLMessageText> {
        return this.http.delete<APLMessageText>(environment.apiRoot +
            `/admin/apl/messages/${entity.messageId}/texts/${entity.languageCode}`
        ).pipe(map(() => entity));
    }

    deleteMany(entityInfo: IEntityInfo, entities: APLMessageText[]): Observable<APLMessageText[]> {
        return this.http.request<APLMessageText>('DELETE', environment.apiRoot +
            `/admin/apl/messages/${entities[0].messageId}/texts`, {
            body: entities
        }).pipe(map(() => entities));
    }
}
