import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {CardImage} from '@models/CardImage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

const BASE_URL = '/admin/cards/images';

@Injectable()
export class CardImagesService implements IAutoEntityService<CardImage> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: { authorityId: number }): Observable<CardImage[]> {
        return this.http.get<CardImage[]>(`${environment.apiRoot}${BASE_URL}`);
    }

    load(entityInfo: IEntityInfo, id: any, criteria: { authorityId: number }): Observable<CardImage> {
        return this.http.get<CardImage>(`${environment.apiRoot}${BASE_URL}/${id}`);
    }

    create(entityInfo: IEntityInfo, entity: CardImage, criteria: { authorityId: number }): Observable<CardImage> {
        return this.http.post<CardImage>(`${environment.apiRoot}${BASE_URL}`, entity);
    }

    update(entityInfo: IEntityInfo, entity: CardImage, criteria: { authorityId: number }): Observable<CardImage> {
        return this.http.put<CardImage>(`${environment.apiRoot}${BASE_URL}/${entity._id}`, entity);
    }
}
