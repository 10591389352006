<form (submit)="save()">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            Edit Slide
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label for="bgColor" class="col-5 col-lg-4 col-form-label">Background Color</label>
            <div class="col-7 col-lg-8">
                <input class="form-control" id="bgColor" name="bgColor" cpOutputFormat="hex"
                       cpPosition="bottom" [cpOKButton]="true" [cpCancelButton]="true"
                       [(colorPicker)]="bgColor" [style.background]="bgColor">
            </div>
        </div>

        <div class="form-group row">
            <label for="iconColor" class="col-5 col-lg-4 col-form-label">Icon Color</label>
            <div class="col-7 col-lg-8">
                <input class="form-control" id="iconColor" name="iconColor" cpOutputFormat="hex"
                       cpPosition="bottom" [cpOKButton]="true" [cpCancelButton]="true"
                       [(colorPicker)]="iconColor" [style.background]="iconColor">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
