import {Component, OnInit} from '@angular/core';
import {UsersFacade} from '@facades/users.facade';

@Component({
    selector: 'wic-user-list',
    templateUrl: './wic-user-list.component.html',
    styleUrls: ['./wic-user-list.component.sass'],
})
export class WicUserListComponent implements OnInit {
    hideSelf = false;

    constructor(public users: UsersFacade) {
    }

    ngOnInit() {
        this.users.loadAll();
    }
}
