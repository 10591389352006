import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WicConfirmModalComponent} from '../wic-confirm-modal/wic-confirm-modal.component';

@Injectable({
    providedIn: 'root'
})
export class ModalControllerService {
    constructor(private modal: NgbModal) {
    }

    createConfirmModal(title: string, message: string): Promise<any> {
        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = title;
        modalReference.componentInstance.message = message;
        return modalReference.result;
    }
}
