import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';

import {WikiArticle} from '@models';
import {WikiService} from '@services';

import {
    CREATE_WIKI,
    CreateWikiAction,
    CreateWikiFailureAction,
    CreateWikiSuccessAction,
    DELETE_WIKI,
    DeleteWikiAction,
    DeleteWikiFailureAction,
    DeleteWikiSuccessAction,
    FETCH_WIKI,
    FETCH_WIKI_BY_SLUG,
    FETCH_WIKIS,
    FetchWikiAction,
    FetchWikiBySlugAction,
    FetchWikiBySlugFailureAction,
    FetchWikiBySlugSuccessAction,
    FetchWikiFailureAction,
    FetchWikisFailureAction,
    FetchWikisSuccessAction,
    FetchWikiSuccessAction,
    UPDATE_WIKI,
    UpdateWikiAction,
    UpdateWikiFailureAction,
    UpdateWikiSuccessAction,
} from './wiki.actions';

@Injectable()
export class WikiEffects {
    @Effect()
    fetchWikis$ = this.actions.pipe(
        ofType(FETCH_WIKIS),
        exhaustMap(() => this.wikiService.list().pipe(
            map(wikis => new FetchWikisSuccessAction(wikis)),
            catchError(err => of(new FetchWikisFailureAction(err)))
        ))
    );

    @Effect()
    fetchWiki$ = this.actions.pipe(
        ofType(FETCH_WIKI),
        map((action: FetchWikiAction) => action.wikiId),
        exhaustMap((wikiId: string) => this.wikiService.get(wikiId).pipe(
            map(article => new FetchWikiSuccessAction(article)),
            catchError(err => of(new FetchWikiFailureAction(err)))
        ))
    );

    @Effect()
    fetchWikiBySlug$ = this.actions.pipe(
        ofType(FETCH_WIKI_BY_SLUG),
        map((action: FetchWikiBySlugAction) => action.slug),
        exhaustMap((slug: string) => this.wikiService.get(slug).pipe(
            map(article => new FetchWikiBySlugSuccessAction(article)),
            catchError(err => of(new FetchWikiBySlugFailureAction(err)))
        ))
    );

    @Effect()
    createWiki$ = this.actions.pipe(
        ofType(CREATE_WIKI),
        map((action: CreateWikiAction) => action.payload),
        exhaustMap((article: WikiArticle) => this.wikiService.create(article).pipe(
            map(created => new CreateWikiSuccessAction(created)),
            catchError(err => of(new CreateWikiFailureAction(err)))
        ))
    );

    @Effect()
    updateWiki$ = this.actions.pipe(
        ofType(UPDATE_WIKI),
        map((action: UpdateWikiAction) => action.payload),
        exhaustMap((article: WikiArticle) => this.wikiService.update(article).pipe(
            map(updated => new UpdateWikiSuccessAction(updated)),
            catchError(err => of(new UpdateWikiFailureAction(err)))
        ))
    );

    @Effect()
    deleteWiki$ = this.actions.pipe(
        ofType(DELETE_WIKI),
        map((action: DeleteWikiAction) => action.payload),
        exhaustMap((article: WikiArticle) => this.wikiService.delete(article._id).pipe(
            map(id => new DeleteWikiSuccessAction(id)),
            catchError(err => of(new DeleteWikiFailureAction(err)))
        ))
    );

    constructor(private actions: Actions, private wikiService: WikiService) {
    }
}
