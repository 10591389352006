import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {APLImportConfig} from '@models';
import {ObjectId} from '@models/types';

@Entity({
    modelName: 'APLJob'
})
export class APLJob {
    // tslint:disable-next-line:variable-name
    @Key _id: ObjectId;
    authorityId: number;
    configId: ObjectId;
    startTime: string;
    endTime: string;
    aborted?: boolean;
    succeeded?: boolean;
    skipped?: boolean;
    isManual?: boolean;
    uomConflicts?: APLJobConflict[];
    pkgConflicts?: APLJobConflict[];
    descriptionConflicts?: APLJobConflict[];
    config?: APLImportConfig;
    importResults: APLImportResults;
    error?: any;
    log?: APLJobLog[];
    foundMultipleAPLFiles?: boolean;
    foundMultipleFilesInZip?: boolean;
    foundALPFiles?: string[];
    aplFilename?: string;
}

export class APLJobConflict {
    Category: number;
    SubCategory: number;
    Vals: string;
    ValCount: number;
}

export class APLImportResults {
    items: {
        added: number;
        updated: number;
        deleted: number;
    };
    categories: {
        updated: number;
    };
    subCategories: {
        updated: number;
    };
}

export class APLJobLog {
    type: string;
    timestamp: string;
    message: string;
}
