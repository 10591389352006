import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {APLJobsFacade} from '@facades/apl-jobs.facade';
import {APLJob} from '@models/APLJob';
import {Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'wic-apl-import-job-view',
    templateUrl: './wic-apl-import-job-view.component.html',
    styleUrls: ['./wic-apl-import-job-view.component.sass']
})
export class WicAplImportJobViewComponent implements OnInit {
    job$: Observable<APLJob>;

    constructor(private route: ActivatedRoute, private aplJobsFacade: APLJobsFacade) {
    }

    ngOnInit() {
        this.job$ = this.route.params.pipe(
            tap(params => {
                this.aplJobsFacade.setCurrent(params.id);
            }),
            switchMap(() => this.aplJobsFacade.selectCurrent())
        );
    }
}
