import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {CategoryLookup, SubCategoryLookup} from '@models';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CategoryLookupService implements IAutoEntityService<CategoryLookup> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<CategoryLookup[]> {
        return this.http.get<CategoryLookup[]>(`${environment.apiRoot}/admin/subcategoryconfigs/categories`);
    }
}
