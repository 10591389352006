import { Component } from '@angular/core';
import { AuthorityLocationFacade } from '@facades/authority-location.facade';
import { AuthorityLocation } from '@models/AuthorityLocation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WicAuthorityLocationEditComponent } from '../wic-authority-location-edit/wic-authority-location-edit.component';

@Component({
    selector: 'wic-wic-authority-locations',
    templateUrl: './wic-authority-locations.component.html',
    styleUrls: ['./wic-authority-locations.component.scss']
})
export class WicAuthorityLocationsComponent {
    constructor(public locations: AuthorityLocationFacade, private modals: NgbModal) {
    }

    addEdit(location?: AuthorityLocation) {
        const modal = this.modals.open(WicAuthorityLocationEditComponent, {size: 'lg'});
        if (location) {
            modal.componentInstance.location = location;
        }
        modal.result.then((res: AuthorityLocation) => this.locations.saveLocation(res), () => null);
    }

    selectLocation(location: AuthorityLocation) {
        this.locations.select(location);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

}
