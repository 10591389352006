import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthFacade } from '@facades/auth.facade';
import { WikiArticle } from '@models';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@state/app.state';
import { CreateWikiAction, FetchWikiAction, UpdateWikiAction } from '@state/wiki/wiki.actions';
import { currentWikiById } from '@state/wiki/wiki.selectors';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'wic-wiki-edit',
    templateUrl: './wic-wiki-edit.component.html',
    styleUrls: ['./wic-wiki-edit.component.sass'],
})
export class WicWikiEditComponent implements OnInit {
    article$: Observable<WikiArticle>;
    workingArticle: WikiArticle;

    constructor(private store: Store<IAppState>, private activatedRoute: ActivatedRoute, public auth: AuthFacade) { }

    ngOnInit() {
        this.activatedRoute.params.pipe(
            map(params => params.id)
        ).subscribe(id => {
            if (!id) {
                this.article$ = of({} as WikiArticle);
            } else {
                this.store.dispatch(new FetchWikiAction(id));
                this.article$ = this.store.pipe(
                    select(currentWikiById)
                );
            }
        });
    }

    saveArticle(article: WikiArticle) {
        if (article._id) {
            this.store.dispatch(new UpdateWikiAction(article));
        } else {
            this.store.dispatch(new CreateWikiAction(article));
        }
    }
}
