/* tslint:disable */
import {Injectable} from '@angular/core';
import {Create, Delete, LoadAll, Select, Update} from '@briebug/ngrx-auto-entity';
import {MenuButton, Authority} from '@models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {Observable, Subject} from 'rxjs';
import {WicAuthorityMenuEditComponent} from '../wic-admin/wic-authorities/wic-authority-view/wic-authority-menu-edit/wic-authority-menu-edit.component';
import {WicConfirmModalComponent} from '../wic-confirm-modal/wic-confirm-modal.component';
import {allMenuButtons, currentMenuButton, menuButtonsLoading, MoveMenuButton} from '@state/menu-button.state';

/* tslint:enable */

@Injectable({
    providedIn: 'root'
})
export class MenuButtonsFacade {
    forDeletion$: Subject<MenuButton>;
    forEdit$: Subject<MenuButton>;

    constructor(private store: Store<IAppState>, private modal: NgbModal) {
        this.forDeletion$ = new Subject<MenuButton>();
        this.forEdit$ = new Subject<MenuButton>();
    }

    // region Selections
    selectAll(): Observable<MenuButton[]> {
        return this.store.pipe(select(allMenuButtons));
    }

    selectCurrent(): Observable<MenuButton> {
        return this.store.pipe(select(currentMenuButton));
    }

    selectIsLoading(): Observable<boolean> {
        return this.store.pipe(select(menuButtonsLoading));
    }
    // endregion

    // region Dispatches
    setCurrent(button: MenuButton) {
        this.store.dispatch(new Select(MenuButton, button));
    }

    loadByAuthority(authorityId) {
        this.store.dispatch(new LoadAll(MenuButton, {linkTypeId: authorityId}));
    }

    create(button: MenuButton) {
        this.store.dispatch(new Create(MenuButton, button));
    }

    update(button: MenuButton) {
        this.store.dispatch(new Update(MenuButton, button));
    }

    delete(button: MenuButton) {
        this.store.dispatch(new Delete(MenuButton, button));
    }

    move(button: MenuButton, newIndex: number) {
        this.store.dispatch(new MoveMenuButton(button, newIndex));
    }
    // endregion

    // region UI
    showEdit(button: MenuButton) {
        this.showEditModal(button)
            .then((edited: MenuButton) => {
                this.update(edited);
            }, () => {
            });
    }

    showCreate(authority: Authority) {
        this.showEditModal({
            linkType: 'au',
            linkTypeId: +authority.id
        } as MenuButton)
            .then(created => {
                this.create({
                    ...created,
                });
            }, () => {
            });
    }

    showDelete(button: MenuButton) {
        if (!button) {
            return;
        }

        this.forDeletion$.next(button);

        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = 'Confirm Menu Button Deletion';
        modalReference.componentInstance.message = 'Are you sure you want to delete this button?';
        modalReference.result
            .then(() => {
                this.delete(button);
                this.forDeletion$.next(null);
            }, () => {
                this.forDeletion$.next(null);
            });
    }

    // endregion

    // region Helpers
    private showEditModal(button: MenuButton): Promise<MenuButton> {
        const modalReference = this.modal.open(WicAuthorityMenuEditComponent, {size: 'lg'});
        modalReference.componentInstance.button = button || {};
        return modalReference.result;
    }
    // endregion
}
