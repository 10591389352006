<div class="full-screen-menu" [hidden]="!display">
    <nav class="navbar navbar-dark navbar-expand-md fixed-top bg-primary">
        <button class="btn btn-show-menu bg-transparent text-white" (click)="closed.emit()">
            <i class="fa fa-times fa-fw"></i>
        </button>
        <a class="navbar-brand justify-self-start" href="/admin">
            WIC Shopper
        </a>
    </nav>

    <div class="menu">
        <ul class="nav flex-column wic-main-menu-full">
            <li class="nav-item" *ngFor="let item of menuItems">
                <a class="nav-link text-nowrap"
                   [routerLink]="[item.path]"
                   [routerLinkActive]="'active'"
                   (click)="navigated.emit()">
                    <i class="fa fa-fw fa-lg" [ngClass]="item.icon"></i>
                    <span class="pl-1 wic-menu-text">{{item.title}}</span>
                </a>
                <ul class="nav flex-column wic-sub-menu-full" *ngIf="item.subMenuItems?.length">
                    <li class="pl-1 nav-item" *ngFor="let subItem of item.subMenuItems">
                        <a class="nav-link text-nowrap"
                           [routerLink]="[subItem.path]"
                           [routerLinkActive]="'active'"
                           (click)="navigated.emit()">
                            <span class="wic-submenu-chevron"><i class="fa fa-fw fa-ellipsis-v ml-2"></i></span>
                            <span class="wic-submenu-text">{{subItem.title}}</span>
                            <span class="wic-submenu-chevron float-right"><i class="fa fa-fw fa-chevron-left"></i></span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
