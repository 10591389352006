import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {APLSummary} from '@models/APLSummary';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class APLSummaryService implements IAutoEntityService<APLSummary> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo): Observable<APLSummary[]> {
        return this.http.get<APLSummary[]>(environment.apiRoot + '/admin/apl'
        );
    }
}
