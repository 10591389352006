import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {AuthorityAccount} from '@models';
import {
    AuthorityAccountActions,
    CREATE_AUTHORITY_ACCOUNT_SUCCESS,
    DELETE_AUTHORITY_ACCOUNT_SUCCESS,
    FETCH_AUTHORITY_ACCOUNT_FAILURE,
    FETCH_AUTHORITY_ACCOUNT_SUCCESS,
    FETCH_AUTHORITY_ACCOUNTS_SUCCESS,
    SELECT_AUTHORITY_ACCOUNT,
    SELECT_AUTHORITY_ACCOUNT_BY_ID, UPDATE_AUTHORITY_ACCOUNT_SUCCESS,
} from './accounts.actions';
import { createReducer, on } from '@ngrx/store';
import { createAuthoritySuccess } from '@state/authorities/authorities.actions';

export interface IAuthorityAccountsState extends EntityState<AuthorityAccount> {
    selectedAuthorityAccountId?: string;
}

export const adapter: EntityAdapter<AuthorityAccount> = createEntityAdapter({
    selectId: (account: AuthorityAccount) => account._id,
});

const initialState: IAuthorityAccountsState = adapter.getInitialState({
    selectedAuthorityAccountId: null,
});

const reduce = createReducer(initialState,
  on(createAuthoritySuccess, (state, {account}) => adapter.addOne(account, state))
  );

export function authorityAccountsReducer(
    state: IAuthorityAccountsState = initialState,
    action: AuthorityAccountActions): IAuthorityAccountsState {
    switch (action.type) {
        case SELECT_AUTHORITY_ACCOUNT:
            return {...state, selectedAuthorityAccountId: action.payload ? action.payload._id : null};
        case SELECT_AUTHORITY_ACCOUNT_BY_ID:
            return {...state, selectedAuthorityAccountId: action.payload};
        case CREATE_AUTHORITY_ACCOUNT_SUCCESS:
            return adapter.addOne(action.payload, {...state, selectedAuthorityAccountId: null});
        case DELETE_AUTHORITY_ACCOUNT_SUCCESS:
            return adapter.removeOne(action.payload, {...state, selectedAuthorityAccountId: null});
        case FETCH_AUTHORITY_ACCOUNTS_SUCCESS:
            return adapter.addAll(action.payload, state);
        case FETCH_AUTHORITY_ACCOUNT_SUCCESS:
            return adapter.addAll([action.payload], {...state, selectedAuthorityAccountId: action.payload._id});
        case FETCH_AUTHORITY_ACCOUNT_FAILURE:
            return {...state, selectedAuthorityAccountId: null};
        case UPDATE_AUTHORITY_ACCOUNT_SUCCESS:
            return adapter.updateOne({
                id: action.payload._id,
                changes: action.payload,
            }, {...state, selectedAuthorityAccountId: action.payload._id});
        default:
            return reduce(state, action);
    }
}
