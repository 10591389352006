import { IEntityState } from '@briebug/ngrx-auto-entity';
import {
  APLImportConfig,
  APLItem,
  APLMessage,
  APLMessageMapping,
  APLMessageText,
  APLOverride,
  AppointmentImport,
  AppointmentReminder,
  AuthorityFlag,
  Banner,
  BannerPlaylist,
  BinCode,
  Category,
  CategoryLookup,
  DemoAccount,
  DeviceLogSession,
  EnhancedDevice,
  ErrorReport,
  MenuButton,
  MicroBlog,
  Partner,
  ServerSettings,
  SubCategory,
  SubCategoryConfig,
  SubCategoryLookup,
  User,
} from '@models';
import { APLJob } from '@models/APLJob';
import { APLSummary } from '@models/APLSummary';
import { APLTransform } from '@models/APLTransform';
import { AuthorityLocation } from '@models/AuthorityLocation';
import { BenefitImportJob } from '@models/BenefitImportJob';
import { BenefitInfo } from '@models/BenefitInfo';
import { CardImage } from '@models/CardImage';
import { CardLegacyStatus } from '@models/CardLegacyStatus';
import { CardStatus } from '@models/CardStatus';
import { DeviceLogEntry } from '@models/DeviceLogEntry';
import { LocalBenefit } from '@models/LocalBenefit';
import { LocalBenefitImport } from '@models/LocalBenefitImport';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { aplImportConfigReducer } from '@state/apl-import-config.state';
import { aplItemReducer } from '@state/apl-item/apl-item.state';
import { aplJobReducer } from '@state/apl-job.state';
import { aplMessageMappingReducer } from '@state/apl-message-mappings.state';
import { aplMessageTextReducer } from '@state/apl-message-text.state';
import { aplMessageReducer } from '@state/apl-message.state';
import { aplOverrideReducer } from '@state/apl-override.state';
import { aplSummaryReducer } from '@state/apl-summary.state';
import { aplTransformReducer } from '@state/apl-transform.state';
import { appointmentImportReducer } from '@state/appointment-imports.state';
import { appointmentReminderReducer } from '@state/appointment-reminder.state';
import { authorityAccountsReducer, IAuthorityAccountsState } from '@state/authorities/accounts.reducers';
import { authorityLocationReducer } from '@state/authorities/authority-locations/authority-locations.state';
import { authorityTypesReducer, IAuthorityTypesState } from '@state/authorities/types.reducers';
import { authorityFlagReducer } from '@state/authority-flags.state';
import { availableAppsReducer, AvailableAppsState } from '@state/available-apps/available-apps.state';
import { bannerPlaylistReducer } from '@state/banner-playlist.state';
import { bannerReducer } from '@state/banner.state';
import { benefitImportJobReducer } from '@state/benefit-import-job/benefit-import-job.state';
import { benefitInfoReducer } from '@state/benefit-info.state';
import { binCodeReducer } from '@state/bincode.state';
import { cardImageReducer } from '@state/card-image.state';
import { cardLegacyStatusReducer } from '@state/card-status/card-legacy-status.state';
import { cardStatusReducer } from '@state/card-status/card-status.state';
import { cardReducer, ICardState } from '@state/card.state';
import { categoryReducer } from '@state/categories.state';
import { categoryLookupReducer } from '@state/category-lookups.state';
import { demoAccountsReducer } from '@state/demo-accounts/demo-accounts.state';
import { deviceLogEntryReducer } from '@state/device-log-entry.state';
import { deviceLogSessionReducer } from '@state/device-log-session.state';
import { enhancedDeviceReducer } from '@state/enhanced-device.state';
import { errorReportReducer } from '@state/error-report.state';
import { localBenefitImportReducer } from '@state/local-benefit-import.state';
import { localBenefitReducer } from '@state/local-benefit.state';
import { menuButtonsReducer } from '@state/menu-button.state';
import { microBlogReducer } from '@state/micro-blogs.state';
import { partnerReducer } from '@state/partner.state';
import { IModernStatisticsState, modernStatisticsReducer } from '@state/reporting/modernstatistics.state';
import {
  statisticsReportingJobsReducer,
  StatisticsReportingJobsState,
  STATISTICS_REPORTING_JOBS_FEATURE_KEY,
} from '@state/reporting/statistics-reporting-jobs.state';
import { serverSettingsReducer } from '@state/server-settings.state';
import { IRouterStateUrl } from '@state/shared/utils';
import { subCategoryReducer } from '@state/sub-categories.state';
import { subCategoryConfigReducer } from '@state/sub-category-configs.state';
import { subCategoryLookupReducer } from '@state/sub-category-lookup.state';
import { userReducer } from '@state/users/user.state';
// import {IUserState, userReducer} from '@state/users/users.reducer';
import { IWikiState, wikiReducer } from '@state/wiki/wiki.reducer';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '../../environments/environment';
import { authoritiesReducer, IAuthoritiesState } from './authorities/authorities.reducers';
import { ILoadingState as loadingState, reducer as loadingReducer } from './loading/loading.reducers';

export interface IAppState {
  aplImportConfig: IEntityState<APLImportConfig>;
  aplJob: IEntityState<APLJob>;
  aplMessage: IEntityState<APLMessage>;
  aplMessageMapping: IEntityState<APLMessageMapping>;
  aplMessageText: IEntityState<APLMessageText>;
  aplOverride: IEntityState<APLOverride>;
  aplSummary: IEntityState<APLSummary>;
  aplTransform: IEntityState<APLTransform>;
  appointmentImport: IEntityState<AppointmentImport>;
  appointmentReminder: IEntityState<AppointmentReminder>;
  authorities: IAuthoritiesState;
  authorityLocation: IEntityState<AuthorityLocation>;
  authorityAccounts: IAuthorityAccountsState;
  authorityFlag: IEntityState<AuthorityFlag>;
  authorityTypes: IAuthorityTypesState;
  availableApp: AvailableAppsState;
  banner: IEntityState<Banner>;
  bannerPlaylist: IEntityState<BannerPlaylist>;
  benefitInfo: IEntityState<BenefitInfo>;
  binCode: IEntityState<BinCode>;
  card: ICardState;
  cardImage: IEntityState<CardImage>;
  category: IEntityState<Category>;
  categoryLookup: IEntityState<CategoryLookup>;
  deviceLogSession: IEntityState<DeviceLogSession>;
  deviceLogEntry: IEntityState<DeviceLogEntry>;
  demoAccount: IEntityState<DemoAccount>;
  enhancedDevice: IEntityState<EnhancedDevice>;
  errorReport: IEntityState<ErrorReport>;
  loading: loadingState;
  localBenefit: IEntityState<LocalBenefit>;
  localBenefitImport: IEntityState<LocalBenefitImport>;
  benefitImportJob: IEntityState<BenefitImportJob>;
  cardStatus: IEntityState<CardStatus>;
  cardLegacyStatus: IEntityState<CardLegacyStatus>;
  menuButton: IEntityState<MenuButton>;
  microBlog: IEntityState<MicroBlog>;
  modernStatistics: IModernStatisticsState;
  partner: IEntityState<Partner>;
  router: RouterReducerState<IRouterStateUrl>;
  serverSettings: IEntityState<ServerSettings>;
  [STATISTICS_REPORTING_JOBS_FEATURE_KEY]: StatisticsReportingJobsState;
  subCategory: IEntityState<SubCategory>;
  subCategoryLookup: IEntityState<SubCategoryLookup>;
  subCategoryConfig: IEntityState<SubCategoryConfig>;
  aplItem: IEntityState<APLItem>;
  // users: IUserState;
  user: IEntityState<User>;
  wiki: IWikiState;
}

export type AppState = IAppState;

export const appState: ActionReducerMap<AppState> = {
  aplImportConfig: aplImportConfigReducer,
  aplJob: aplJobReducer,
  aplMessage: aplMessageReducer,
  aplMessageMapping: aplMessageMappingReducer,
  aplMessageText: aplMessageTextReducer,
  aplOverride: aplOverrideReducer,
  aplSummary: aplSummaryReducer,
  aplTransform: aplTransformReducer,
  appointmentImport: appointmentImportReducer,
  appointmentReminder: appointmentReminderReducer,
  authorities: authoritiesReducer,
  authorityLocation: authorityLocationReducer,
  authorityAccounts: authorityAccountsReducer,
  authorityFlag: authorityFlagReducer,
  authorityTypes: authorityTypesReducer,
  availableApp: availableAppsReducer,
  card: cardReducer,
  cardImage: cardImageReducer,
  banner: bannerReducer,
  bannerPlaylist: bannerPlaylistReducer,
  benefitInfo: benefitInfoReducer,
  binCode: binCodeReducer,
  category: categoryReducer,
  categoryLookup: categoryLookupReducer,
  deviceLogSession: deviceLogSessionReducer,
  deviceLogEntry: deviceLogEntryReducer,
  demoAccount: demoAccountsReducer,
  enhancedDevice: enhancedDeviceReducer,
  errorReport: errorReportReducer,
  loading: loadingReducer,
  localBenefit: localBenefitReducer,
  localBenefitImport: localBenefitImportReducer,
  benefitImportJob: benefitImportJobReducer,
  cardStatus: cardStatusReducer,
  cardLegacyStatus: cardLegacyStatusReducer,
  menuButton: menuButtonsReducer,
  microBlog: microBlogReducer,
  modernStatistics: modernStatisticsReducer,
  partner: partnerReducer,
  router: routerReducer,
  serverSettings: serverSettingsReducer,
  [STATISTICS_REPORTING_JOBS_FEATURE_KEY]: statisticsReportingJobsReducer,
  subCategory: subCategoryReducer,
  subCategoryLookup: subCategoryLookupReducer,
  subCategoryConfig: subCategoryConfigReducer,
  aplItem: aplItemReducer,
  // users: userReducer,
  user: userReducer,
  wiki: wikiReducer,
};

export const appMetaReducers: Array<MetaReducer<AppState>> = !environment.production ? [storeFreeze] : [];
