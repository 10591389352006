// tslint:disable:max-line-length
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DemoAccountsFacade } from '@facades/demo-accounts.facade';
import { mobiscroll, MbscModule } from '@mobiscroll/angular';
import {DatePipe} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {JwtHelperService, JwtModule} from '@auth0/angular-jwt';
import {NgrxAutoEntityModule} from '@briebug/ngrx-auto-entity';

import {
    APLItem,
    APLMessage,
    APLMessageMapping,
    APLMessageText,
    APLOverride,
    AppointmentImport,
    AppointmentReminder,
    AuthorityFlag,
    Banner,
    BannerPlaylist,
    BinCode,
    Category,
    CategoryLookup,
    DemoAccount,
    DeviceLogSession,
    EnhancedDevice,
    ErrorReport,
    MenuButton,
    MicroBlog,
    Partner,
    ServerSettings,
    SubCategory,
    SubCategoryConfig,
    SubCategoryLookup,
    User
} from '@models';
import {APLImportConfig} from '@models/APLImportConfig';
import {APLJob} from '@models/APLJob';
import {APLSummary} from '@models/APLSummary';
import {APLTransform} from '@models/APLTransform';
import { AuthorityLocation } from '@models/AuthorityLocation';
import {BenefitInfo} from '@models/BenefitInfo';

import {CardImage} from '@models/CardImage';
import {DeviceLogEntry} from '@models/DeviceLogEntry';
import {LocalBenefit} from '@models/LocalBenefit';
import {LocalBenefitImport} from '@models/LocalBenefitImport';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import {PrismModule} from '@ngx-prism/core';
import {
    APLMessageService,
    APLMessageTextService,
    AppointmentImportService,
    AppointmentReminderService,
    AuthoritiesService,
    AuthorityAccountsService,
    AuthorityMappingsService,
    AuthService,
    CardImagesService,
    CategoryLookupService,
    CategoryService,
    DemoAccountsService,
    MenuButtonService,
    MicroBlogService,
    ReportingService,
    ServerSettingsService,
    SubCategoryConfigService,
    SubCategoryLookupService,
    SubCategoryService,
    UsersService,
    UtilService,
    WikiService
} from '@services';
import { DemoAccountsUIService } from '@state/demo-accounts/demo-accounts-ui.service';

import {StateModule} from '@state/state.module';

// import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {FileUploadModule} from 'ng2-file-upload';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxMdModule} from 'ngx-md';

import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {ChartingModule} from './charting/charting.module';

import {DisableControlDirective} from './directives/disable-control.directive';
import {FullScreenMenuComponent} from './layout/nav/full-screen-menu/full-screen-menu.component';
import {NavLeftComponent} from './layout/nav/nav-left/nav-left.component';
import {NavComponent} from './layout/nav/nav.component';
import {SidebarComponent} from './layout/nav/sidebar/sidebar.component';
import {SubNavComponent} from './layout/sub-nav/sub-nav.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {AsKeyPipe} from './pipes/as-key.pipe';
import {CategoryCollapsedPipe} from './pipes/category-collapsed.pipe';
import {CategoryExpandedPipe} from './pipes/category-expanded.pipe';
import {FilterByLevelsPipe} from './pipes/filter-by-levels-pipe.pipe';
import {FilterByTagsPipe} from './pipes/filter-by-tags.pipe';
import {FilterSubCategoryByCategoryPipe} from './pipes/filter-sub-category-by-category.pipe';
import {HexPipe} from './pipes/hex.pipe';
import {IfCategoryShownPipe} from './pipes/if-category-shown.pipe';
import {IfFalsyPipe} from './pipes/if-falsy.pipe';
import {IfIncludedPipe} from './pipes/if-included.pipe';
import {IsLockedPipe} from './pipes/is-locked.pipe';
import {IsUnlockedPipe} from './pipes/is-unlocked.pipe';
import {NgbDateFormatterPipe} from './pipes/ngb-date-formatter.pipe';
import {NotPipe} from './pipes/not.pipe';
import {SortByCategoryIdPipe} from './pipes/sort-by-category-id.pipe';
import {SortByCategorySubCategoryIdsPipe} from './pipes/sort-by-category-sub-category-ids.pipe';
import {SortByNumericPipe} from './pipes/sort-by-numeric.pipe';
import {SortByPipe} from './pipes/sort-by.pipe';
import {SubCategoryCountPipe} from './pipes/sub-category-count.pipe';
import {ToCapsPipe} from './pipes/to-caps.pipe';
import {APLImportConfigService} from './services/apl-import-config.service';
import {APLJobService} from './services/apl-job.service';
import {AplMessageMappingService} from './services/apl-message-mapping.service';
import {APLOverrideService} from './services/apl-override.service';
import {APLSummaryService} from './services/apl-summary.service';
import {APLTransformService} from './services/apl-transform.service';
import {AuthorityFlagService} from './services/authority-flag.service';
import { AuthorityLocationsService } from './services/authority-locations.service';
import {BannerPlaylistService} from './services/banner-playlists.service';
import {BannerService} from './services/banner.service';
import {BenefitInfoService} from './services/benefit-info.service';
import {BinCodeService} from './services/bincode.service';
import {DeviceLogEntryService} from './services/device-log-entry.service';
import {DeviceLogSessionService} from './services/device-log-session.service';
import {EnhancedDeviceService} from './services/enhanced-device.service';
import {ErrorReportsService} from './services/error-reports.service';
import {LocalBenefitImportService} from './services/local-benefit-import.service';
import {LocalBenefitService} from './services/local-benefit.service';
import {PartnerService} from './services/partner.service';
import { ComponentsModule } from './shared/components/components.module';

import {DeviceLogEntriesListComponent} from './wic-admin/support-tools/device-logs/device-log-entries-list/device-log-entries-list.component';
import {DeviceLogEntriesTableComponent} from './wic-admin/support-tools/device-logs/device-log-entries-table/device-log-entries-table.component';
import {DeviceLogsTableComponent} from './wic-admin/support-tools/device-logs/device-logs-table/device-logs-table.component';
import {DeviceLogsComponent} from './wic-admin/support-tools/device-logs/device-logs.component';
import {EnhancedDevicesTableComponent} from './wic-admin/support-tools/enhanced-devices/enhanced-devices-table/enhanced-devices-table.component';
import {EnhancedDevicesComponent} from './wic-admin/support-tools/enhanced-devices/enhanced-devices.component';
import {ErrorReportDetailComponent} from './wic-admin/support-tools/error-reports/error-report-view/error-report-detail/error-report-detail.component';
import {ErrorReportDeviceInfoComponent} from './wic-admin/support-tools/error-reports/error-report-view/error-report-device-info/error-report-device-info.component';
import {ErrorReportViewComponent} from './wic-admin/support-tools/error-reports/error-report-view/error-report-view.component';
import {ErrorReportsTableComponent} from './wic-admin/support-tools/error-reports/error-reports-table/error-reports-table.component';
import {ErrorReportsComponent} from './wic-admin/support-tools/error-reports/error-reports.component';
import {SupportRequestsComponent} from './wic-admin/support-tools/support-requests/support-requests.component';
import {SupportToolsComponent} from './wic-admin/support-tools/support-tools.component';
import {AdminRoutes, WicAdminComponent} from './wic-admin/wic-admin.component';
import {WicAplImportsStatusComponent} from './wic-admin/wic-apl/wic-apl-imports-status/wic-apl-imports-status.component';
import {WicAplImportEditComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-import-edit/wic-apl-import-edit.component';
import {WicAplImportJobConflictTableComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-import-job-conflict-table/wic-apl-import-job-conflict-table.component';
import {WicAplImportJobDetailComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-import-job-detail/wic-apl-import-job-detail.component';
import {WicAplImportJobErrorDetailComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-import-job-error-detail/wic-apl-import-job-error-detail.component';
import {WicAplImportJobLogTableComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-import-job-log-table/wic-apl-import-job-log-table.component';
import {WicAplImportJobViewComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-import-job-view/wic-apl-import-job-view.component';
import {WicAplImportJobsTableComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-import-jobs-table/wic-apl-import-jobs-table.component';
import {WicAplImportDetailComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-import-view/wic-apl-import-detail/wic-apl-import-detail.component';
import {WicAplImportViewComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-import-view/wic-apl-import-view.component';
import {WicAplImportsTableComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-imports-table/wic-apl-imports-table.component';
import {WicAplImportsComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-imports.component';
import {WicAplOverrideEditComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-override-edit/wic-apl-override-edit.component';
import {WicAplOverridesTableComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-overrides-table/wic-apl-overrides-table.component';
import {WicAplTransformEditComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-transform-edit/wic-apl-transform-edit.component';
import {WicAplTransformsTableComponent} from './wic-admin/wic-apl/wic-apl-imports/wic-apl-transforms-table/wic-apl-transforms-table.component';
import {WicAplMessageEditComponent} from './wic-admin/wic-apl/wic-apl-messages/wic-apl-message-edit/wic-apl-message-edit.component';
import {WicAplMappingsEditorComponent} from './wic-admin/wic-apl/wic-apl-messages/wic-apl-message-view/wic-apl-mappings-editor/wic-apl-mappings-editor.component';
import {WicAplMappingsMatrixComponent} from './wic-admin/wic-apl/wic-apl-messages/wic-apl-message-view/wic-apl-mappings-matrix/wic-apl-mappings-matrix.component';
import {WicAplMessageDetailComponent} from './wic-admin/wic-apl/wic-apl-messages/wic-apl-message-view/wic-apl-message-detail/wic-apl-message-detail.component';
import {WicAplMessageTextsComponent} from './wic-admin/wic-apl/wic-apl-messages/wic-apl-message-view/wic-apl-message-texts/wic-apl-message-texts.component';
import {WicAplMessageViewComponent} from './wic-admin/wic-apl/wic-apl-messages/wic-apl-message-view/wic-apl-message-view.component';
import {WicAplMessagesTableComponent} from './wic-admin/wic-apl/wic-apl-messages/wic-apl-messages-table/wic-apl-messages-table.component';
import {WicAplMessagesComponent} from './wic-admin/wic-apl/wic-apl-messages/wic-apl-messages.component';
import {WicAplSummaryTableComponent} from './wic-admin/wic-apl/wic-apl-summary-table/wic-apl-summary-table.component';
import {WicAplComponent} from './wic-admin/wic-apl/wic-apl.component';
import {WicAppointmentImportDetailComponent} from './wic-admin/wic-appointments/wic-appointment-import-view/wic-appointment-import-detail/wic-appointment-import-detail.component';
import {WicAppointmentImportViewComponent} from './wic-admin/wic-appointments/wic-appointment-import-view/wic-appointment-import-view.component';
import {WicAppointmentImportsTableComponent} from './wic-admin/wic-appointments/wic-appointment-imports-table/wic-appointment-imports-table.component';
import {WicAppointmentImportsComponent} from './wic-admin/wic-appointments/wic-appointment-imports/wic-appointment-imports.component';
import {WicAppointmentRemindersListComponent} from './wic-admin/wic-appointments/wic-appointment-reminders-list/wic-appointment-reminders-list.component';
import {WicAppointmentRemindersTableComponent} from './wic-admin/wic-appointments/wic-appointment-reminders-table/wic-appointment-reminders-table.component';
import {WicAppointmentsComponent} from './wic-admin/wic-appointments/wic-appointments.component';
import {WicAuthoritiesBasicReportComponent} from './wic-admin/wic-authorities/wic-authorities-basic-report/wic-authorities-basic-report.component';
import {WicAuthoritiesLegacyReportComponent} from './wic-admin/wic-authorities/wic-authorities-legacy-report/wic-authorities-legacy-report.component';
import {WicAuthoritiesTableComponent} from './wic-admin/wic-authorities/wic-authorities-table/wic-authorities-table.component';
import {WicAuthoritiesComponent} from './wic-admin/wic-authorities/wic-authorities.component';
import {WicAuthorityBannerPlaylistsTableComponent} from './wic-admin/wic-authorities/wic-authority-banners/wic-authority-banner-playlists-table/wic-authority-banner-playlists-table.component';
import {WicAuthorityBannersComponent} from './wic-admin/wic-authorities/wic-authority-banners/wic-authority-banners.component';
import {WicBannerPlaylistDetailEditComponent} from './wic-admin/wic-authorities/wic-authority-banners/wic-banner-playlist-detail-edit/wic-banner-playlist-detail-edit.component';
import {WicBannerPlaylistDetailComponent} from './wic-admin/wic-authorities/wic-authority-banners/wic-banner-playlist-view/wic-banner-playlist-detail/wic-banner-playlist-detail.component';
import {WicBannerPlaylistEntriesTableComponent} from './wic-admin/wic-authorities/wic-authority-banners/wic-banner-playlist-view/wic-banner-playlist-entries-table/wic-banner-playlist-entries-table.component';
import {WicBannerPlaylistEntryEditComponent} from './wic-admin/wic-authorities/wic-authority-banners/wic-banner-playlist-view/wic-banner-playlist-entry-edit/wic-banner-playlist-entry-edit.component';
import {WicBannerPlaylistViewComponent} from './wic-admin/wic-authorities/wic-authority-banners/wic-banner-playlist-view/wic-banner-playlist-view.component';
import {WicAuthorityFlagEditComponent} from './wic-admin/wic-authorities/wic-authority-flags/wic-authority-flag-edit/wic-authority-flag-edit.component';
import {WicAuthorityFlagsTableComponent} from './wic-admin/wic-authorities/wic-authority-flags/wic-authority-flags-table/wic-authority-flags-table.component';
import {WicAuthorityFlagsComponent} from './wic-admin/wic-authorities/wic-authority-flags/wic-authority-flags.component';
import {WicAuthorityListComponent} from './wic-admin/wic-authorities/wic-authority-list/wic-authority-list.component';
import {WicAuthorityTypesTableComponent} from './wic-admin/wic-authorities/wic-authority-types/wic-authority-types-table/wic-authority-types-table.component';
import {WicAuthorityTypesComponent} from './wic-admin/wic-authorities/wic-authority-types/wic-authority-types.component';
import {WicAuthorityBannerPlaylistEditComponent} from './wic-admin/wic-authorities/wic-authority-view/wic-authority-banner-playlist-edit/wic-authority-banner-playlist-edit.component';
import {WicAuthorityBannerPlaylistComponent} from './wic-admin/wic-authorities/wic-authority-view/wic-authority-banner-playlist/wic-authority-banner-playlist.component';
import {WicAuthorityBincodeEditComponent} from './wic-admin/wic-authorities/wic-authority-view/wic-authority-bincode-edit/wic-authority-bincode-edit.component';
import {WicAuthorityBincodesComponent} from './wic-admin/wic-authorities/wic-authority-view/wic-authority-bincodes/wic-authority-bincodes.component';
import {WicAuthorityCardimageComponent} from './wic-admin/wic-authorities/wic-authority-view/wic-authority-cardimage/wic-authority-cardimage.component';
import {WicAuthorityDetailEditComponent} from './wic-admin/wic-authorities/wic-authority-view/wic-authority-detail-edit/wic-authority-detail-edit.component';
import {WicAuthorityDetailComponent} from './wic-admin/wic-authorities/wic-authority-view/wic-authority-detail/wic-authority-detail.component';
import {WicAuthorityMenuEditComponent} from './wic-admin/wic-authorities/wic-authority-view/wic-authority-menu-edit/wic-authority-menu-edit.component';
import {WicAuthorityMenuComponent} from './wic-admin/wic-authorities/wic-authority-view/wic-authority-menu/wic-authority-menu.component';
import {WicAuthorityViewComponent} from './wic-admin/wic-authorities/wic-authority-view/wic-authority-view.component';
import {WicAuthorityMappingsFormComponent} from './wic-admin/wic-authority-mappings/wic-authority-mappings-form/wic-authority-mappings-form.component';
import {WicAuthorityMappingsTableComponent} from './wic-admin/wic-authority-mappings/wic-authority-mappings-table/wic-authority-mappings-table.component';
import {WicAuthorityMappingsComponent} from './wic-admin/wic-authority-mappings/wic-authority-mappings.component';
import {WicBenefitImportDetailComponent} from './wic-admin/wic-benefits/wic-benefit-import-view/wic-benefit-import-detail/wic-benefit-import-detail.component';
import {WicBenefitImportViewComponent} from './wic-admin/wic-benefits/wic-benefit-import-view/wic-benefit-import-view.component';
import {WicBenefitImportsTableComponent} from './wic-admin/wic-benefits/wic-benefit-imports-table/wic-benefit-imports-table.component';
import {WicBenefitImportsComponent} from './wic-admin/wic-benefits/wic-benefit-imports/wic-benefit-imports.component';
import {WicBenefitsListComponent} from './wic-admin/wic-benefits/wic-benefits-list/wic-benefits-list.component';
import {WicBenefitsTableComponent} from './wic-admin/wic-benefits/wic-benefits-table/wic-benefits-table.component';
import {WicBenefitsComponent} from './wic-admin/wic-benefits/wic-benefits.component';
import {WicDashboardComponent} from './wic-admin/wic-dashboard/wic-dashboard.component';
import {WicDemoAccountEditComponent} from './wic-admin/wic-demo-accounts/wic-demo-account-edit/wic-demo-account-edit.component';
import {WicDemoAccountsTableComponent} from './wic-admin/wic-demo-accounts/wic-demo-accounts-table/wic-demo-accounts-table.component';
import {WicDemoAccountsUploaderComponent} from './wic-admin/wic-demo-accounts/wic-demo-accounts-uploader/wic-demo-accounts-uploader.component';
import {WicDemoAccountsComponent} from './wic-admin/wic-demo-accounts/wic-demo-accounts.component';
import {WicMicroBlogEditComponent} from './wic-admin/wic-micro-blogs/wic-micro-blog-edit/wic-micro-blog-edit.component';
import {WicMicroBlogContentComponent} from './wic-admin/wic-micro-blogs/wic-micro-blog-view/wic-micro-blog-content/wic-micro-blog-content.component';
import {WicMicroBlogDeckComponent} from './wic-admin/wic-micro-blogs/wic-micro-blog-view/wic-micro-blog-content/wic-micro-blog-deck/wic-micro-blog-deck.component';
import {WicSlideEditComponent} from './wic-admin/wic-micro-blogs/wic-micro-blog-view/wic-micro-blog-content/wic-micro-blog-deck/wic-slide-edit/wic-slide-edit.component';
import {WicMicroBlogPostComponent} from './wic-admin/wic-micro-blogs/wic-micro-blog-view/wic-micro-blog-content/wic-micro-blog-post/wic-micro-blog-post.component';
import {WicMicroBlogDetailComponent} from './wic-admin/wic-micro-blogs/wic-micro-blog-view/wic-micro-blog-detail/wic-micro-blog-detail.component';
import {WicMicroBlogViewComponent} from './wic-admin/wic-micro-blogs/wic-micro-blog-view/wic-micro-blog-view.component';
import {WicMicroBlogTableComponent} from './wic-admin/wic-micro-blogs/wic-micro-blogs-table/wic-micro-blogs-table.component';
import {WicMicroBlogComponent} from './wic-admin/wic-micro-blogs/wic-micro-blogs.component';
import {WicSubCategoryConfigEditComponent} from './wic-admin/wic-sub-category-configs/wic-sub-category-config-edit/wic-sub-category-config-edit.component';
import {WicSubCategoryConfigLinkEditComponent} from './wic-admin/wic-sub-category-configs/wic-sub-category-config-link-edit/wic-sub-category-config-link-edit.component';
import {WicSubCategoryConfigDetailComponent} from './wic-admin/wic-sub-category-configs/wic-sub-category-config-view/wic-sub-category-config-detail/wic-sub-category-config-detail.component';
import {WicSubCategoryConfigLineItemsComponent} from './wic-admin/wic-sub-category-configs/wic-sub-category-config-view/wic-sub-category-config-line-items/wic-sub-category-config-line-items.component';
import {WicSubCategoryConfigViewComponent} from './wic-admin/wic-sub-category-configs/wic-sub-category-config-view/wic-sub-category-config-view.component';
import {WicSubCategoryConfigsTableComponent} from './wic-admin/wic-sub-category-configs/wic-sub-category-configs-table/wic-sub-category-configs-table.component';
import {WicSubCategoryConfigsComponent} from './wic-admin/wic-sub-category-configs/wic-sub-category-configs.component';
import {WicUserEditComponent} from './wic-admin/wic-users/wic-user-edit/wic-user-edit.component';
import {WicUserListComponent} from './wic-admin/wic-users/wic-user-list/wic-user-list.component';
import {WicUserDetailComponent} from './wic-admin/wic-users/wic-user-view/wic-user-detail/wic-user-detail.component';
import {WicUserViewComponent} from './wic-admin/wic-users/wic-user-view/wic-user-view.component';
import {WicUsersTableComponent} from './wic-admin/wic-users/wic-users-table/wic-users-table.component';
import {WicUsersComponent} from './wic-admin/wic-users/wic-users.component';
import {WicWikiArticleComponent} from './wic-admin/wic-wiki/wic-wiki-article/wic-wiki-article.component';
import {WicWikiEditComponent} from './wic-admin/wic-wiki/wic-wiki-edit/wic-wiki-edit.component';
import {WicWikiEditorComponent} from './wic-admin/wic-wiki/wic-wiki-edit/wic-wiki-editor/wic-wiki-editor.component';
import {WicWikiPreviewComponent} from './wic-admin/wic-wiki/wic-wiki-edit/wic-wiki-preview/wic-wiki-preview.component';
import {WicWikiListComponent} from './wic-admin/wic-wiki/wic-wiki-list/wic-wiki-list.component';
import {WicWikiTableComponent} from './wic-admin/wic-wiki/wic-wiki-table/wic-wiki-table.component';
import {WicWikiComponent} from './wic-admin/wic-wiki/wic-wiki.component';
import {WicAlertModalComponent} from './wic-alert-modal/wic-alert-modal.component';
import {WicConfirmModalComponent} from './wic-confirm-modal/wic-confirm-modal.component';
import {WicDataImportLogComponent} from './wic-data-import-log/wic-data-import-log.component';
import {WicErrorComponent} from './wic-error/wic-error.component';
import {WicImageUploaderModalComponent} from './wic-image-uploader-modal/wic-image-uploader-modal.component';
import {WicImportParseErrorsComponent} from './wic-import-parse-errors/wic-import-parse-errors.component';
import {LoginRoutes, WicLoginComponent} from './wic-login/wic-login.component';
import {WicSelectItemModalComponent} from './wic-select-item-modal/wic-select-item-modal.component';
import {WicValueInputModalComponent} from './wic-value-input-modal/wic-value-input-modal.component';
import { PasswordChallengeModalComponent } from './password-challenge-modal/password-challenge-modal.component';
import {SplitPipe} from './pipes/split.pipe';
import {ErrorReportScreenshotComponent} from './wic-admin/support-tools/error-reports/error-report-view/error-report-screenshot/error-report-screenshot.component';
import {ModernStatisticsReportComponent} from './wic-admin/modern-statistics-report/modern-statistics-report.component';
import {CardCheckComponent} from './wic-admin/wic-benefits/card-check/card-check.component';
import {CardCheckBenefitsTableComponent} from './wic-admin/wic-benefits/card-check-benefits-table/card-check-benefits-table.component';
import {HasMessagesPipe} from './wic-admin/wic-apl/wic-apl-messages/wic-apl-message-view/wic-apl-mappings-matrix/has-messages.pipe';
import {UserProfileComponent} from './wic-admin/user-profile/user-profile.component';
import {DatePickerFormatPipe} from './pipes/date-picker-format.pipe';
import {AplSearchComponent} from './wic-admin/wic-apl/apl-search/apl-search.component';
import {AplSearchCriteriaComponent} from './wic-admin/wic-apl/apl-search/apl-search-criteria/apl-search-criteria.component';
import {AplItemService} from './services/apl-item.service';
import {BenefitImportJob} from '@models/BenefitImportJob';
import {BenefitImportJobService} from './services/benefit-import-job.service';
import { ImportJobDetailsComponent } from './wic-admin/wic-benefits/import-job-details/import-job-details.component';
import { ImportJobListComponent } from './wic-admin/wic-benefits/import-job-list/import-job-list.component';
import { StatusCardComponent } from './wic-admin/wic-benefits/import-job-details/status-card/status-card.component';
import { FilterSelectComponent } from './wic-admin/wic-benefits/import-job-details/filter-select/filter-select.component';
import {CardStatus} from '@models/CardStatus';
import {CardStatusService} from './services/card-status.service';
import {CardLegacyStatus} from '@models/CardLegacyStatus';
import {CardLegacyStatusService} from './services/card-legacy-status.service';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {JobStatusPipe} from './pipes/job-status.pipe';
import { ImportJobStatusComponent } from './wic-admin/wic-benefits/import-job-details/import-job-status/import-job-status.component';
import {StatusCountPipe} from './pipes/status-count.pipe';
import { WicAuthorityLocationsComponent } from './wic-admin/wic-authorities/wic-authority-view/wic-authority-locations/wic-authority-locations.component';
import { WicAuthorityLocationEditComponent } from './wic-admin/wic-authorities/wic-authority-view/wic-authority-location-edit/wic-authority-location-edit.component';
import { AddEditBenefitGroupComponent } from './wic-admin/wic-demo-accounts/add-edit-benefit-group/add-edit-benefit-group.component';
import { ReplicateCardModalComponent } from './wic-admin/wic-demo-accounts/replicate-card-modal/replicate-card-modal.component';
import { FormatFormErrorPipe } from './pipes/format-form-error.pipe';
import { AzureStorageModule } from '../../azure-storage/lib/angular';
import { EnhancedIssuesModule } from './enhanced-issues/enhanced-issues.module';
import { EnhancedIssuesTranslationsModule } from './enhanced-issues-translations/enhanced-issues-translations.module';
import { LanguageModule } from './translations/feature/languages/language.module';
import { StandardCategoriesModule } from './standard-categories/standard-categories.module';
// ltslint:enable:max-line-length

mobiscroll.settings = {
    theme: 'bootstrap',
    desktopSettings: true,
};

const appRoutes: Routes = [
    ...LoginRoutes,
    ...AdminRoutes,
    {path: '', redirectTo: '/admin', pathMatch: 'full'},
    {path: 'not-found', component: NotFoundComponent},
    {path: '**', component: NotFoundComponent}
];

export function tokenGetter() {
    return localStorage.getItem('token');
}

@NgModule({
    declarations: [
        AppComponent,
        DisableControlDirective,
        NgbDateFormatterPipe,
        NavComponent,
        SidebarComponent,
        NavLeftComponent,
        SubNavComponent,
        HexPipe,
        IfIncludedPipe,
        FilterByLevelsPipe,
        FilterByTagsPipe,
        WicAdminComponent,
        WicAplComponent,
        WicAplImportDetailComponent,
        WicAplImportEditComponent,
        WicAplImportJobConflictTableComponent,
        WicAplImportJobDetailComponent,
        WicAplImportJobErrorDetailComponent,
        WicAplImportJobLogTableComponent,
        WicAplImportJobViewComponent,
        WicAplImportJobsTableComponent,
        WicAplImportViewComponent,
        WicAplImportsComponent,
        WicAplImportsTableComponent,
        WicAplMessageDetailComponent,
        WicAplMessageEditComponent,
        WicAplMessageTextsComponent,
        WicAplMessageViewComponent,
        WicAplMessagesComponent,
        WicAplMessagesTableComponent,
        WicAplOverrideEditComponent,
        WicAplOverridesTableComponent,
        WicAplSummaryTableComponent,
        WicAplTransformEditComponent,
        WicAplTransformsTableComponent,
        WicAppointmentImportsComponent,
        WicAppointmentImportsTableComponent,
        WicAppointmentRemindersListComponent,
        WicAppointmentRemindersTableComponent,
        WicAppointmentsComponent,
        WicAuthoritiesBasicReportComponent,
        WicAuthoritiesComponent,
        WicAuthoritiesLegacyReportComponent,
        WicAuthoritiesTableComponent,
        WicAuthorityBannersComponent,
        WicAuthorityBannerPlaylistComponent,
        WicAuthorityBannerPlaylistsTableComponent,
        WicAuthorityBincodeEditComponent,
        WicAuthorityBincodesComponent,
        WicAuthorityCardimageComponent,
        WicAuthorityDetailComponent,
        WicAuthorityDetailEditComponent,
        WicAuthorityFlagEditComponent,
        WicAuthorityFlagsComponent,
        WicAuthorityFlagsTableComponent,
        WicAuthorityListComponent,
        WicAuthorityMappingsComponent,
        WicAuthorityMappingsFormComponent,
        WicAuthorityMappingsTableComponent,
        WicAuthorityMenuComponent,
        WicAuthorityMenuEditComponent,
        WicAuthorityTypesComponent,
        WicAuthorityTypesTableComponent,
        WicAuthorityViewComponent,
        WicBannerPlaylistViewComponent,
        WicConfirmModalComponent,
        WicDashboardComponent,
        WicDemoAccountEditComponent,
        WicDemoAccountsComponent,
        WicDemoAccountsTableComponent,
        WicDemoAccountsUploaderComponent,
        WicErrorComponent,
        WicImageUploaderModalComponent,
        WicLoginComponent,
        WicMicroBlogComponent,
        WicMicroBlogContentComponent,
        WicMicroBlogDeckComponent,
        WicMicroBlogDetailComponent,
        WicMicroBlogEditComponent,
        WicMicroBlogPostComponent,
        WicMicroBlogTableComponent,
        WicMicroBlogViewComponent,
        WicSelectItemModalComponent,
        WicSubCategoryConfigDetailComponent,
        WicSubCategoryConfigEditComponent,
        WicSubCategoryConfigLineItemsComponent,
        WicSubCategoryConfigLinkEditComponent,
        WicSubCategoryConfigViewComponent,
        WicSubCategoryConfigsComponent,
        WicSubCategoryConfigsTableComponent,
        WicUserDetailComponent,
        WicUserEditComponent,
        WicUserListComponent,
        WicUserViewComponent,
        WicUsersComponent,
        WicUsersTableComponent,
        WicValueInputModalComponent,
        WicWikiArticleComponent,
        WicWikiComponent,
        WicWikiEditComponent,
        WicWikiEditorComponent,
        WicWikiListComponent,
        WicWikiPreviewComponent,
        WicWikiTableComponent,
        WicBannerPlaylistDetailComponent,
        WicBannerPlaylistEntriesTableComponent,
        WicBannerPlaylistDetailEditComponent,
        WicBannerPlaylistEntryEditComponent,
        WicAuthorityBannerPlaylistEditComponent,
        WicBenefitsComponent,
        WicBenefitsListComponent,
        WicBenefitsTableComponent,
        WicBenefitImportsComponent,
        WicBenefitImportsTableComponent,
        WicBenefitImportViewComponent,
        WicBenefitImportDetailComponent,
        WicDataImportLogComponent,
        WicAplImportsStatusComponent,
        NotFoundComponent,
        FullScreenMenuComponent,
        WicAppointmentImportViewComponent,
        WicAppointmentImportDetailComponent,
        WicSlideEditComponent,
        WicImportParseErrorsComponent,
        WicAlertModalComponent,
        SupportToolsComponent,
        EnhancedDevicesComponent,
        DeviceLogsComponent,
        ErrorReportsComponent,
        ErrorReportsTableComponent,
        SupportRequestsComponent,
        DeviceLogsTableComponent,
        DeviceLogEntriesListComponent,
        DeviceLogEntriesTableComponent,
        EnhancedDevicesTableComponent,
        ToCapsPipe,
        IfFalsyPipe,
        WicAplMappingsEditorComponent,
        WicAplMappingsMatrixComponent,
        SubCategoryCountPipe,
        SortByPipe,
        SortByCategoryIdPipe,
        SortByCategorySubCategoryIdsPipe,
        SortByNumericPipe,
        CategoryCollapsedPipe,
        FilterSubCategoryByCategoryPipe,
        IfCategoryShownPipe,
        CategoryExpandedPipe,
        IsLockedPipe,
        IsUnlockedPipe,
        NotPipe,
        AsKeyPipe,
        ErrorReportViewComponent,
        ErrorReportDetailComponent,
        ErrorReportDeviceInfoComponent,
        SplitPipe,
        ErrorReportScreenshotComponent,
        ModernStatisticsReportComponent,
        CardCheckComponent,
        CardCheckBenefitsTableComponent,
        HasMessagesPipe,
        UserProfileComponent,
        DatePickerFormatPipe,
        JobStatusPipe,
        StatusCountPipe,
        PasswordChallengeModalComponent,
        AplSearchComponent,
        AplSearchCriteriaComponent,
        ImportJobListComponent,
        ImportJobDetailsComponent,
        StatusCardComponent,
        FilterSelectComponent,
        ImportJobStatusComponent,
        WicAuthorityLocationsComponent,
        WicAuthorityLocationEditComponent,
        AddEditBenefitGroupComponent,
        ReplicateCardModalComponent,
        FormatFormErrorPipe,
    ],
    imports: [
        MbscModule,
        ScrollingModule,
        DragDropModule,
        // MultiselectDropdownModule,
        BrowserModule,
        FileUploadModule,
        FormsModule,
        HttpClientModule,
        AuthModule.forRoot(environment.auth),
        // JwtModule.forRoot({
        //     config: {
        //         tokenGetter,
        //         whitelistedDomains: environment.auth0Whitelist,
        //         blacklistedRoutes: environment.auth0Blacklist,
        //     },
        // }),
        NgbModule,
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes),
        StateModule.forRoot(),
        StoreRouterConnectingModule.forRoot(),
        ChartingModule,
        NgxMdModule.forRoot(),
        AzureStorageModule.forRoot(environment.azureConfig),
        NgrxAutoEntityModule,
        ColorPickerModule,
        NgxBootstrapMultiselectModule,
        ComponentsModule,
        EnhancedIssuesModule,
        EnhancedIssuesTranslationsModule,
        StandardCategoriesModule,
        LanguageModule,
    ],
    providers: [
        // Entity to service mappings:
        {provide: APLImportConfig, useClass: APLImportConfigService},
        {provide: APLJob, useClass: APLJobService},
        {provide: APLMessage, useClass: APLMessageService},
        {provide: APLMessageText, useClass: APLMessageTextService},
        {provide: APLMessageMapping, useClass: AplMessageMappingService},
        {provide: APLOverride, useClass: APLOverrideService},
        {provide: APLSummary, useClass: APLSummaryService},
        {provide: APLTransform, useClass: APLTransformService},
        {provide: APLItem, useClass: AplItemService},
        {provide: AppointmentReminder, useClass: AppointmentReminderService},
        {provide: AppointmentImport, useClass: AppointmentImportService},
        {provide: AuthorityFlag, useClass: AuthorityFlagService},
        {provide: AuthorityLocation, useClass: AuthorityLocationsService},
        {provide: Banner, useClass: BannerService},
        {provide: BannerPlaylist, useClass: BannerPlaylistService},
        {provide: BenefitInfo, useClass: BenefitInfoService},
        {provide: BinCode, useClass: BinCodeService},
        {provide: CardImage, useClass: CardImagesService},
        {provide: Category, useClass: CategoryService},
        {provide: DeviceLogSession, useClass: DeviceLogSessionService},
        {provide: DeviceLogEntry, useClass: DeviceLogEntryService},
        {provide: DemoAccount, useClass: DemoAccountsService},
        {provide: EnhancedDevice, useClass: EnhancedDeviceService},
        {provide: ErrorReport, useClass: ErrorReportsService},
        {provide: LocalBenefit, useClass: LocalBenefitService},
        {provide: LocalBenefitImport, useClass: LocalBenefitImportService},
        {provide: BenefitImportJob, useClass: BenefitImportJobService},
        {provide: CardStatus, useClass: CardStatusService},
        {provide: CardLegacyStatus, useClass: CardLegacyStatusService},
        {provide: MenuButton, useClass: MenuButtonService},
        {provide: MicroBlog, useClass: MicroBlogService},
        {provide: Partner, useClass: PartnerService},
        {provide: SubCategory, useClass: SubCategoryService},
        {provide: SubCategoryConfig, useClass: SubCategoryConfigService},
        {provide: CategoryLookup, useClass: CategoryLookupService},
        {provide: SubCategoryLookup, useClass: SubCategoryLookupService},
        {provide: ServerSettings, useClass: ServerSettingsService},
        {provide: User, useClass: UsersService},
        // Facades

        // General services
        AuthoritiesService,
        AuthorityAccountsService,
        AuthorityMappingsService,
        DemoAccountsService,
        CardImagesService,
        JwtHelperService,
        MenuButtonService,
        ReportingService,
        AuthService,
        UtilService,
        WikiService,
        DemoAccountsFacade,
        DemoAccountsUIService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },

        // Pipes
        DatePipe
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        WicAlertModalComponent,
        WicConfirmModalComponent,
        WicValueInputModalComponent,
        WicSelectItemModalComponent,
        WicImageUploaderModalComponent,
        PasswordChallengeModalComponent,
        WicAplImportEditComponent,
        WicAplOverrideEditComponent,
        WicAplTransformEditComponent,
        WicAplMessageEditComponent,
        WicAuthorityDetailEditComponent,
        WicAuthorityMenuEditComponent,
        WicAuthorityBincodeEditComponent,
        WicAuthorityBannerPlaylistEditComponent,
        WicAuthorityLocationEditComponent,
        WicAuthorityFlagEditComponent,
        WicBannerPlaylistDetailEditComponent,
        WicBannerPlaylistEntryEditComponent,
        WicUserEditComponent,
        WicDemoAccountEditComponent,
        AddEditBenefitGroupComponent,
        WicMicroBlogEditComponent,
        WicSubCategoryConfigEditComponent,
        WicSubCategoryConfigLinkEditComponent,
        WicSlideEditComponent,
    ],
  exports: [
    NgbModule,
  ],
})
export class AppModule {
}
