import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Observable, throwError} from 'rxjs';

import {IAutoEntityService, IEntityInfo, IEntityWithPageInfo, Page} from '@briebug/ngrx-auto-entity';
import {AppointmentReminder} from '@models/Appointment';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class AppointmentReminderService implements IAutoEntityService<AppointmentReminder> {
    constructor(private http: HttpClient) {
    }

    load(entityInfo: IEntityInfo, keys: any): Observable<AppointmentReminder> {
        return this.http.get<AppointmentReminder>(
            environment.apiRoot + `/admin/authorities/${keys.authorityId}/appointments/${keys.appointmentId}`
        );
    }

    loadPage(entityInfo: IEntityInfo, page: Page, criteria?: any): Observable<IEntityWithPageInfo<AppointmentReminder>> {
        let fromObject: any = {
            page: (page.page || 1).toString(),
            size: (page.size || 25).toString(),
        };

        if (criteria.criteria) {
            fromObject = {
                ...fromObject,
                criteria: criteria.criteria,
            };
        }

        if (criteria.startDate) {
            fromObject = {
                ...fromObject,
                startDate: moment({
                    ...criteria.startDate,
                    month: criteria.startDate.month - 1,
                    hour: 0,
                    minute: 0,
                    second: 0,
                }).format(),
            };
        }

        if (criteria.endDate) {
            fromObject = {
                ...fromObject,
                endDate: moment({
                    ...criteria.endDate,
                    month: criteria.endDate.month - 1,
                    hour: 11,
                    minute: 59,
                    second: 59,
                }).format(),
            };
        }

        const params = new HttpParams({fromObject});

        return this.http
            .get<any>(environment.apiRoot + `/admin/authorities/${criteria.authorityId}/appointments`, {params})
            .pipe(
                map(appointmentsMeta => {
                    return {
                        pageInfo: {
                            page: {
                                page: page.page || 1,
                                size: page.size || 25
                            },
                            totalCount: appointmentsMeta.pageInfo && appointmentsMeta.pageInfo.length ?
                                appointmentsMeta.pageInfo[0].totalCount : 0,
                        },
                        entities: appointmentsMeta.appointmentReminders || [],
                    };
                })
            );
    }

    create(entityInfo: IEntityInfo, entity: AppointmentReminder): Observable<AppointmentReminder> {
        return throwError({
            code: 40000,
            message: 'Not supported',
        });
    }

    update(entityInfo: IEntityInfo, entity: AppointmentReminder): Observable<AppointmentReminder> {
        return throwError({
            code: 40000,
            message: 'Not supported',
        });
    }

    replace(entityInfo: IEntityInfo, entity: AppointmentReminder): Observable<AppointmentReminder> {
        return throwError({
            code: 40000,
            message: 'Not supported',
        });
    }

    delete(entityInfo: IEntityInfo, entity: AppointmentReminder): Observable<AppointmentReminder> {
        return throwError({
            code: 40000,
            message: 'Not supported',
        });
    }

// getByAuthorityId(authorityId, page: Page, sort: SortDetail): Observable<AppointmentResponse> {
//     return this.http
//         .get<AppointmentResponse>(
//             `${environment.apiRoot}${BASE_URL}/${authorityId}/${page.offset}/${page.limit}/${sort.sortBy}/${sort.sortDir}`
//         );
// }
//
// getByAuthorityIdAndCardNumber(authorityId, cardNumber, page: Page, sort: SortDetail): Observable<AppointmentResponse> {
//     return this.http
//         .get<AppointmentResponse>(
//             `${environment.apiRoot}${BASE_URL}/${authorityId}/${cardNumber}/${page.offset}/${page.limit}/${sort.sortBy}/${sort.sortDir}`
//         );
// }
}
