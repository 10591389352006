import { buildState } from '@briebug/ngrx-auto-entity';
import { BannerPlaylist } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, createAction, props } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { currentAvailableAppId } from './available-apps/available-apps.state';
import { Injectable } from '@angular/core';

export const loadPlaylists = createAction('[BannerPlaylist] Load Playlists');
export const createPlaylist = createAction('[BannerPlaylist] Create Playlist', props<{ bannerPlaylist: BannerPlaylist }>());
export const updatePlaylist = createAction('[BannerPlaylist] Update Playlist', props<{ bannerPlaylist: BannerPlaylist }>());
export const deletePlaylist = createAction('[BannerPlaylist] Delete Playlist', props<{ bannerPlaylist: BannerPlaylist }>());


export const {
    initialState: initialBannerPlaylistsState,
    selectors: {
        selectAll: allBannerPlaylists,
        selectCurrentEntity: currentBannerPlaylist
    },
    actions: {
        loadAll: loadAllBannerPlaylists,
        create: createBannerPlaylist,
        update: updateBannerPlaylist,
        delete: deleteBannerPlaylist,
    }
} = buildState(BannerPlaylist);

export function bannerPlaylistReducer(state = initialBannerPlaylistsState) {
    return state;
}

@Injectable()
export class BannerPlaylistEffect {

  constructor(private actions$: Actions, private store: Store) {}

  loadPlaylists$ = createEffect(() => 
    this.actions$.pipe(
      ofType(loadPlaylists),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([, appId]) => loadAllBannerPlaylists({criteria: { appId }}))
    )
  );
  
  createPlaylist$ = createEffect(() => 
    this.actions$.pipe(
      ofType(createPlaylist),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([{bannerPlaylist}, appId, ]) => createBannerPlaylist({
        entity: bannerPlaylist,
        criteria: { appId }
      }))
    )
  );

  updatePlayList$ = createEffect(() => 
    this.actions$.pipe(
      ofType(updatePlaylist),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([{bannerPlaylist}, appId, ]) => updateBannerPlaylist({
        entity: bannerPlaylist,
        criteria: { appId }
      }))
    )
  );

  deletePlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePlaylist),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([{bannerPlaylist}, appId, ]) => deleteBannerPlaylist({
        entity: bannerPlaylist,
        criteria: { appId }
      }))
    )
  );
}
