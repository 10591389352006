import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

import {CategoryLookup, SubCategoryConfig, SubCategoryLookup} from '@models';

@Component({
    selector: 'wic-sub-category-config-detail',
    templateUrl: './wic-sub-category-config-detail.component.html',
    styleUrls: ['./wic-sub-category-config-detail.component.sass']
})
export class WicSubCategoryConfigDetailComponent implements OnInit, OnChanges {
    @Input() subCategoryConfig: SubCategoryConfig;
    @Input() categories: CategoryLookup[];
    @Input() subCategories: SubCategoryLookup[];

    @Output() edited = new EventEmitter();

    currentCategory: CategoryLookup;
    currentSubCategory: SubCategoryLookup;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.subCategoryConfig && this.categories) {
            this.currentCategory = this.categories.find(category =>
                +category.categoryId === +this.subCategoryConfig.categoryId
            );
        }

        if (this.subCategoryConfig && this.subCategories) {
            this.currentSubCategory = this.subCategories.find(subCategory =>
                +subCategory.categoryId === +this.subCategoryConfig.categoryId &&
                +subCategory.subCategoryId === +this.subCategoryConfig.subCategoryId
            );
        }
    }

    createImageUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
