<form class="mt-3" [formGroup]="form" (submit)="save(form)">
    <div class="form-group row">
        <div class="col-sm-12">
            <input type="text" class="form-control" id="title" formControlName="title" placeholder="Title">
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-12">
            <input type="text" class="form-control" id="slug" formControlName="slug" placeholder="Slug">
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-12">
            <textarea rows="20" class="form-control" id="markdown" formControlName="markdown" placeholder="Markdown">
            </textarea>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-10">
        </div>
        <div class="col-sm-2">
            <button class="btn btn-outline-primary">Save</button>
        </div>
    </div>
</form>
