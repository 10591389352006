import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wic-wic-apl-mappings-editor',
  templateUrl: './wic-apl-mappings-editor.component.html',
  styleUrls: ['./wic-apl-mappings-editor.component.scss']
})
export class WicAplMappingsEditorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
