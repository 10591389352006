import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AvailableApps } from '@state/available-apps/models/available-app.entity';
import { StatisticsReportingJob } from '@state/reporting/statistics-reporting-job.entity';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class StatisticsReportingJobsService {
  constructor(private http: HttpClient) {}

  pollReportingJob(correlationId: string, appId: AvailableApps, criteria: { beginDate: string; endDate: string }) {
    return this.http.get<StatisticsReportingJob>(`${environment.apiRoot}/admin/apps/${appId}/reports/usagestatistics`, {
      params: {
        ...criteria,
        correlationId,
      },
    });
  }
}
