import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MicroBlog} from '@models';
import {ILookup} from '@models/types';

@Component({
    selector: 'wic-micro-blog-detail',
    templateUrl: './wic-micro-blog-detail.component.html',
    styleUrls: ['./wic-micro-blog-detail.component.sass']
})
export class WicMicroBlogDetailComponent implements OnInit {
    @Input() microBlog: MicroBlog;
    @Input() authorities: ILookup[];
    @Output() edited = new EventEmitter<MicroBlog>();

    constructor() {
    }

    ngOnInit() {
    }
}
