import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WikiArticle } from '@models';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@state/app.state';
import { FetchWikisAction } from '@state/wiki/wiki.actions';
import { allWikis } from '@state/wiki/wiki.selectors';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'wic-wiki-list',
    templateUrl: './wic-wiki-list.component.html',
    styleUrls: ['./wic-wiki-list.component.sass'],
})
export class WicWikiListComponent implements OnInit {
    articles$: Observable<WikiArticle[]>;
    hideSelf = false;

    constructor(private store: Store<IAppState>, private router: Router) { }

    ngOnInit() {
        this.articles$ = this.store.pipe(select(allWikis),
            tap(articles => {
                console.log(articles);
            })
        );
        this.refresh();
    }

    refresh() {
        this.store.dispatch(new FetchWikisAction());
    }

    addArticle() {
        this.router.navigateByUrl('/admin/wiki/list/new');
    }
}
