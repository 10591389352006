<div class="card mt-5 mb-5">
    <div class="card-header">
        <h5 class="float-left mb-0">
            Active Authority &amp; Category Mappings
        </h5>
    </div>
    <div *ngIf="isInitializing">
        <h3><i class="fa fa-fw fa-spinner fa-spin"></i> Initializing matrix...please wait...</h3>
    </div>
    <form [formGroup]="form" *ngIf="!isInitializing && form">
        <div class="scroll-container">
            <table class="table table-sm table-striped table-hover fixed-header mb-0">
                <thead>
                <tr>
                    <th class="no-border" colspan="2" rowspan="2"></th>
                    <th class="no-border"></th>
                    <th *ngFor="let authority of authorities | sortByNumeric:'id'">
                        <span class="badge badge-info w-100">A</span>
                    </th>
                </tr>
                <tr>
                    <th class="no-border"></th>
                    <th *ngFor="let authority of authorities | sortByNumeric:'id'">
                        <span class="badge w-100" [ngClass]="(formChecksum$ | async | hasMessages:{authority: authority}:form) ? 'badge-primary' : 'badge-info'"
                              container="body" [openDelay]="500"
                              [ngbTooltip]="authority.name">{{authority.id}}</span>
                    </th>
                </tr>
                <tr>
                    <td class="no-border" colspan="2"></td>
                    <td class="no-border">
                        <span class="text-right d-block w-100">
                            <span class="badge badge-info text-white" (click)="unlockAll(authorities, unlockedAuthorities, 'id')"
                                ngbTooltip="Unlock all authorities">
                                <i class="fa fa-unlock"></i>
                            </span>
                            <span class="badge badge-info text-white ml-1" (click)="lockAll(unlockedAuthorities)"
                                  ngbTooltip="Lock all authorities">
                                <i class="fa fa-lock"></i>
                            </span>
                        </span>
                        <span class="text-left d-block w-25">
                            <span class="badge badge-info text-white" (click)="unlockAll(tree, unlockedCategories, 'categoryId')"
                                  ngbTooltip="Unlock all categories" placement="left">
                                <i class="fa fa-fw fa-unlock"></i>
                            </span>
                            <span class="badge badge-info text-white" (click)="lockAll(unlockedCategories)"
                                  ngbTooltip="Lock all categories" placement="left">
                                <i class="fa fa-fw fa-lock"></i>
                            </span>
                        </span>
                    </td>
                    <td class="text-center" *ngFor="let authority of authorities | sortByNumeric:'id'">
                        <span class="badge text-white" (click)="toggleUnlocked(authority, unlockedAuthorities, 'id')"
                              ngbTooltip="(Un)lock authority" placement="top"
                              [class.badge-primary]="authority | asKey:'id' | isUnlocked:unlockedAuthorities"
                              [class.badge-secondary]="authority | asKey:'id' | isUnlocked:unlockedAuthorities | not">
                            <i class="fa fa-fw"
                               [class.fa-unlock]="authority | asKey:'id' | isUnlocked:unlockedAuthorities"
                               [class.fa-lock]="authority | asKey:'id' | isUnlocked:unlockedAuthorities | not"></i>
                        </span>
                        <span class="badge badge-danger text-white" (click)="bulkRemove(authority)"
                              ngbTooltip="Exclude authority" placement="top">
                            <i class="fa fa-fw fa-minus"></i>
                        </span>
                        <span class="badge badge-success text-white" (click)="bulkAdd(authority)"
                              ngbTooltip="Include authority" placement="bottom">
                            <i class="fa fa-fw fa-plus"></i>
                        </span>
                    </td>
                </tr>
                </thead>
                <tbody *ngFor="let category of tree">
                <tr>
                    <th>
                        <span class="badge badge-info w-100">C</span>
                    </th>
                    <th>
                        <span class="badge w-100" [ngClass]="(formChecksum$ | async | hasMessages:{category: category}:form) ? 'badge-primary' : 'badge-info'"
                              container="body" [openDelay]="500"
                              [ngbTooltip]="category.description">{{category.categoryId}}</span>
                    </th>
                    <td class="text-nowrap">
                        <span class="badge text-white" (click)="toggleUnlocked(category, unlockedCategories, 'categoryId')"
                              ngbTooltip="(Un)lock category" placement="left"
                              [class.badge-primary]="category | asKey:'categoryId' | isUnlocked:unlockedCategories"
                              [class.badge-secondary]="category | asKey:'categoryId' | isUnlocked:unlockedCategories | not">
                            <i class="fa fa-fw"
                               [class.fa-unlock]="category | asKey:'categoryId' | isUnlocked:unlockedCategories"
                               [class.fa-lock]="category | asKey:'categoryId' | isUnlocked:unlockedCategories | not"></i>
                        </span>
                        <span class="badge badge-danger text-white ml-1" (click)="bulkRemove(category)"
                              ngbTooltip="Exclude category" placement="left">
                            <i class="fa fa-minus"></i>
                        </span>
                        <span class="badge badge-success text-white ml-1" (click)="bulkAdd(category)"
                              ngbTooltip="Include category" placement="right">
                            <i class="fa fa-plus"></i>
                        </span>
                    </td>
                    <td [colSpan]="authorities?.length || 0">
                        <span class="badge badge-secondary text-white"
                              (click)="toggle(category)">
                            <i class="fa fa-fw"
                               [class.fa-chevron-right]="category | categoryCollapsed:shownCategories"
                               [class.fa-chevron-down]="category | categoryExpanded:shownCategories"
                            ></i>
                        </span>
                    </td>
                </tr>
                <tr *ngFor="let subcategory of category.subCategories | ifCategoryShown:category:shownCategories:(isPreppingSave || isSaving || isDeleting)">
                    <th>
                        <span class="badge badge-warning text-white">S</span>
                    </th>
                    <th>
                        <span class="badge w-100" [ngClass]="(formChecksum$ | async | hasMessages:{category: category, subCategory: subcategory}:form) ? 'badge-primary' : 'badge-info'"
                              container="body" [openDelay]="500"
                              [ngbTooltip]="subcategory.description">{{subcategory.subCategoryId}}</span>
                    </th>
                    <td class="text-nowrap">
                        <span class="badge text-white" (click)="toggleLocked(subcategory, lockedSubCategories, 'categoryId', 'subCategoryId')"
                              ngbTooltip="(Un)lock subcategory" placement="left"
                              [class.badge-primary]="(subcategory | asKey:'categoryId':'subCategoryId' | isLocked:lockedSubCategories | not) && (category | asKey:'categoryId' | isUnlocked:unlockedCategories)"
                              [class.badge-secondary]="(subcategory | asKey:'categoryId':'subCategoryId' | isLocked:lockedSubCategories) || (category | asKey:'categoryId' | isUnlocked:unlockedCategories | not)">
                            <i class="fa fa-fw"
                               [class.fa-unlock]="subcategory | asKey:'categoryId':'subCategoryId' | isLocked:lockedSubCategories | not"
                               [class.fa-lock]="subcategory | asKey:'categoryId':'subCategoryId' | isLocked:lockedSubCategories"></i>
                        </span>
                        <span class="badge badge-danger text-white ml-1" (click)="bulkRemove(subcategory)"
                              ngbTooltip="Exclude subcategory" placement="left">
                            <i class="fa fa-minus"></i>
                        </span>
                        <span class="badge badge-success text-white ml-1" (click)="bulkAdd(subcategory)"
                              ngbTooltip="Include subcategory" placement="right">
                            <i class="fa fa-plus"></i>
                        </span>
                    </td>
                    <td *ngFor="let authority of authorities | sortByNumeric:'id'"
                        class="text-center" container="body" [openDelay]="700"
                        [ngbTooltip]="authority.name + ': ' + subcategory.description">
                        <input type="checkbox" [formControl]="authorityControls(category, subcategory, authority)">
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-md-6">
                </div>
                <div class="col-md-6 text-right">
                    <button class="btn btn-outline-secondary mr-2" (click)="canceled.emit()">
                        <i class="fa fa-refresh"></i> Cancel
                    </button>
                    <button class="btn btn-outline-primary" (click)="save()" [disabled]="isPreppingSave || isSaving || isDeleting">
                        <i class="fa fa-plus" *ngIf="!(isPreppingSave || isSaving || isDeleting)"></i><i class="fa fa-spinner fa-spin" *ngIf="isPreppingSave || isSaving || isDeleting"></i> Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
