<div class="form-group">
    <div class="input-group">
        <mbsc-select
            *ngIf="control"
            placeholder="Select Authority"
            class="w-100 form-control"
            label="Authority"
            headerText="Authority Selection"
            [formControl]="control"
            [data]="authorities"
            [options]="selectOptions"
        ></mbsc-select>
        <mbsc-select
            *ngIf="!control"
            placeholder="Select Authority"
            class="w-100 form-control"
            label="Authority"
            headerText="Authority Selection"
            [data]="authorities"
            [options]="selectOptions"
        ></mbsc-select>
    </div>
</div>
