<div class="row mt-4" *ngIf="report">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    Error Report Details
                </h5>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Id</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-info">{{report._id}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Title</dt>
                        <dd class="col-md-9">
                            {{report.title}}
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="report.message">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Message</dt>
                        <dd class="col-md-9">
                            {{report.message}}
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="report.detailedMessage">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Detailed Message</dt>
                        <dd class="col-md-9">
                            {{report.detailedMessage}}
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Device ID</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-udid">{{report.uniqueDeviceId}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Session ID</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-session">{{report.sessionId}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                  <dl class="row mb-0">
                      <dt class="col-md-3">Authority ID</dt>
                      <dd class="col-md-9">
                          {{report.authorityId}}
                      </dd>
                  </dl>
              </li>
              <li class="list-group-item">
                <dl class="row mb-0">
                    <dt class="col-md-3">Card Number</dt>
                    <dd class="col-md-9">
                        {{report.cardNumber ? report.cardNumber : 'N/A'}}
                    </dd>
                </dl>
            </li>
            </ul>
        </div>
    </div>
</div>
