/* tslint:disable */
import {Injectable} from '@angular/core';
import {LoadAll} from '@briebug/ngrx-auto-entity';
import {APLSummary} from '@models/APLSummary';
import {select, Store} from '@ngrx/store';
import {allAPLSummaries} from '@state/apl-summary.state';
import {IAppState} from '@state/app.state';
import {Observable} from 'rxjs';

/* tslint:enable */

@Injectable({
    providedIn: 'root'
})
export class APLSummariesFacade {
    constructor(private store: Store<IAppState>) {
    }

    // region Selections
    get summaries(): Observable<APLSummary[]> {
        return this.store.pipe(select(allAPLSummaries));
    }
    // endregion

    // region Dispatches
    loadAll() {
        this.store.dispatch(new LoadAll(APLSummary));
    }
    // endregion
}
