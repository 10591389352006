<div class="row mt-4" *ngIf="config">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    APL Import Config Details
                </h5>
                <i class="fa fa-fw fa-pencil float-right m-1" (click)="edited.emit(config)"
                   ngbTooltip="Edit Details" placement="left"></i>
                <i class="fa fa-fw fa-play float-right m-1" (click)="run.emit(config)"
                   ngbTooltip="Run Now" placement="left" *ngIf="!runDisabled"></i>
                <i class="fa fa-fw fa-spinner fa-spin float-right m-1"
                   ngbTooltip="Running..." placement="left" *ngIf="runDisabled"></i>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Id</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-info">{{config._id}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Is Enabled</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-success]="config.isEnabled"
                                  [class.badge-danger]="!config.isEnabled">
                                {{config.isEnabled ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Authority</dt>
                        <dd class="col-md-9">{{config.authorityId}}</dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">FTP Url</dt>
                        <dd class="col-md-9">
                            <span class="text-monospace">
                                <span *ngIf="config.isSecure">s</span>ftp://{{config.ftpUrl}}<span *ngIf="config.ftpPort">:{{config.ftpPort}}</span>
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Root Path</dt>
                        <dd class="col-md-9">
                            <span class="">{{config.ftpRoot}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Pattern</dt>
                        <dd class="col-md-9">
                            <span class="text-monospace">{{config.aplGlob}}</span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Username</dt>
                        <dd class="col-md-9">
                            <span class="">{{config.username}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Password</dt>
                        <dd class="col-md-9">
                            <span class="text-monospace" *ngIf="!showPass">
                                <span *ngFor="let dot of dots(config.password?.length || 8)">*</span>
                            </span>
                            <span class="text-monospace" *ngIf="showPass">{{config.password}}</span>
                            <i class="fa fa-eye text-muted ml-2" (mousedown)="showPass = true" (mouseup)="showPass = false"></i>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Requires SFTP</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-success]="config.isSecure"
                                  [class.badge-danger]="!config.isSecure">
                                {{config.isSecure ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Is Zipped</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-success]="config.isZipped"
                                  [class.badge-danger]="!config.isZipped">
                                {{config.isZipped ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Filter Duplicates</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-success]="config.filterDuplicates"
                                  [class.badge-danger]="!config.filterDuplicates">
                                {{config.filterDuplicates ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Zero-Fill</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-success]="config.requiresZeroFill"
                                  [class.badge-danger]="!config.requiresZeroFill">
                                {{config.requiresZeroFill ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Transformation</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-success]="config.requiresTransformation"
                                  [class.badge-danger]="!config.requiresTransformation">
                                {{config.requiresTransformation ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Overrides</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-success]="config.requiresOverrides"
                                  [class.badge-danger]="!config.requiresOverrides">
                                {{config.requiresOverrides ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="config.requiresOverrides">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Apply Shared Overrides?</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-success]="config.applySharedOverrides"
                                  [class.badge-danger]="!config.applySharedOverrides">
                                {{config.applySharedOverrides ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
