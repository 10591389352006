import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EntityActionTypes, LoadAll, LoadMany, ofEntityType} from '@briebug/ngrx-auto-entity';
import {BenefitImportJob} from '@models/BenefitImportJob';
import {debounceTime, filter, map, withLatestFrom} from 'rxjs/operators';
import {currentBenefitImportJobKey} from '@state/benefit-import-job/benefit-import-job.state';
import {CardStatus} from '@models/CardStatus';
import {loadCardStatusRange, statusFiltersUpdated} from '@state/card-status/card-status.actions';
import {cardStatusFilters, cardStatusLength} from '@state/card-status/card-status.selectors';
import {Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';

@Injectable()
export class CardStatusEffects {
    loadRelatedStatuses$ = createEffect(() => this.actions$.pipe(
        ofEntityType(BenefitImportJob, EntityActionTypes.SelectByKey),
        withLatestFrom(this.store.select(currentBenefitImportJobKey), this.store.select(cardStatusFilters)),
        map(([, jobId, filters]) => new LoadAll(CardStatus, {jobId, filters: filters.join()}))
    ));

    reloadFilteredStatuses$ = createEffect(() => this.actions$.pipe(
        ofType(statusFiltersUpdated),
        withLatestFrom(this.store.select(currentBenefitImportJobKey)),
        map(([{statusFilters}, jobId]) => new LoadAll(CardStatus, {jobId, filters: statusFilters.join()}))
    ));

    loadPagedStatuses$ = createEffect(() => this.actions$.pipe(
        ofType(loadCardStatusRange),
        debounceTime(100),
        withLatestFrom(
            this.store.select(currentBenefitImportJobKey),
            this.store.select(cardStatusLength),
            this.store.select(cardStatusFilters)),
        filter(([{range}, jobId, length]) => range.end >= (length - 10)),
        map(([{range}, jobId, length, filters]) => ({
            jobId,
            skip: range.end,
            limit: (range.end - range.start) + 10,
            filters: filters.join()
        })),
        map((criteria) => new LoadMany(CardStatus, criteria))
    ));

    constructor(
        private readonly store: Store<IAppState>,
        private readonly actions$: Actions) {
    }
}
