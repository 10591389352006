import {buildState, IEntityState} from '@briebug/ngrx-auto-entity';
import {ErrorReport} from '@models';
import {createSelector} from '@ngrx/store';

export const {initialState, facade: ErrorReportsFacadeBase, selectors: {selectAllSorted: sortedErrorReports}} = buildState(ErrorReport);

export function errorReportReducer(state = initialState): IEntityState<ErrorReport> {
    return state;
}

export const mapToMostRecent = (reports: ErrorReport[]) =>
    reports.slice(0, 20);

export const mostRecentReports = createSelector(
    sortedErrorReports,
    mapToMostRecent
);
