import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthFacade} from '@facades/auth.facade';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {filter, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Component({
    selector: 'wic-password-challenge-modal',
    templateUrl: './password-challenge-modal.component.html',
    styleUrls: ['./password-challenge-modal.component.scss']
})
export class PasswordChallengeModalComponent implements OnInit {
    form: FormGroup;
    submitted = false;
    incorrect = false;

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private auth: AuthFacade, private http: HttpClient) {
    }

    ngOnInit() {
        this.form = this.buildForm();
    }

    private buildForm() {
        return this.formBuilder.group({
            password: [undefined, Validators.required]
        });
    }

    verify(form: FormGroup) {
        this.submitted = true;

        if (form.invalid) {
            return;
        }

        this.http.post<{isValid: boolean}>(`${environment.apiRoot}/admin/verify`, {
            // TODO:: FIX
            // username: this.auth.username,
            password: this.form.value.password
        }).pipe(
            tap(({isValid}) => this.incorrect = !isValid),
            filter(({isValid}) => isValid),
        ).subscribe(() => this.activeModal.close(true));
    }
}
