import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {LocalBenefitImport} from '@models/LocalBenefitImport';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalBenefitImportService implements IAutoEntityService<LocalBenefitImport> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<LocalBenefitImport[]> {
        const url = (criteria && criteria.authorityId) ?
            `/admin/authorities/${criteria.authorityId}/benefits/imports` :
            '/admin/authorities/benefits/imports';
        return this.http.get<LocalBenefitImport[]>(environment.apiRoot + url);
    }
}
