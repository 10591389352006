<div class="row mt-4" *ngIf="playlist">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    <span class="badge badge-info" ngbTooltip="P ID" placement="right">{{playlist.PlaylistID}}</span>
                    Details
                </h5>
                <i class="fa fa-fw fa-pencil float-right m-1" (click)="edited.emit(playlist)"
                   ngbTooltip="Edit Details" placement="left"></i>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Name</dt>
                        <dd class="col-md-9">
                            {{playlist.PlaylistName}}
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Is Active</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-default text-white"
                                  [class.badge-danger]="!playlist.IsActive"
                                  [class.badge-success]="playlist.IsActive">
                                {{playlist.IsActive ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
