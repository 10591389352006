import {buildState, IEntityState} from '@briebug/ngrx-auto-entity';
import {DeviceLogSession} from '@models';
import {createSelector} from '@ngrx/store';

export const {
    initialState,
    facade: DeviceLogSessionFacadeBase,
    selectors: {
        selectAllSorted: sortedDeviceLogSessions
    }
} = buildState(DeviceLogSession);

export function deviceLogSessionReducer(state = initialState): IEntityState<DeviceLogSession> {
    return state;
}

export const mapToSessionsByDeviceId = (sessions: DeviceLogSession[], props) =>
    (sessions && Array.isArray(sessions))
        ? sessions.filter(session => session.sessionId === props.sessionId && session.udid === props.udid)
        : [];

export const sessionsByDeviceId = createSelector(
    sortedDeviceLogSessions,
    mapToSessionsByDeviceId
);
