import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthorityMapping} from '@state/authority-mappings/model';
import {environment} from '../../environments/environment';

const BASE_URL = '/admin/authorities/mappings';

@Injectable()
export class AuthorityMappingsService {

    constructor(private http: HttpClient) {
    }

    list(): Observable<AuthorityMapping[]> {
        return this.http.get<AuthorityMapping[]>(`${environment.apiRoot}${BASE_URL}`);
    }

    create(authorityMapping: AuthorityMapping): Observable<AuthorityMapping> {
        return this.http.post<AuthorityMapping>(`${environment.apiRoot}${BASE_URL}`, authorityMapping);
    }

    update(authorityMapping: AuthorityMapping): Observable<AuthorityMapping> {
        return this.http.put<AuthorityMapping>(`${environment.apiRoot}${BASE_URL}`, authorityMapping);
    }

    delete(binCode: string): Observable<any> {
        return this.http.delete(`${environment.apiRoot}${BASE_URL}/${binCode}`);
    }
}
