import {buildState} from '@briebug/ngrx-auto-entity';
import {CardImage} from '@models/CardImage';
import {createSelector} from '@ngrx/store';

const {initialState, selectors, entityState} = buildState(CardImage);

export const {
    selectAll: allCardImages
} = selectors;

export const authorityCardImage = createSelector(
    allCardImages,
    (cardImages, props) => cardImages.find(ci => ci.authorityId === props.authorityId)
);

export function cardImageReducer(state = initialState) {
    return state;
}
