import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, createAction, createReducer, createSelector, on, props} from '@ngrx/store';
import {AppState} from '@state/app.state';
import {filter, map, switchMap} from 'rxjs/operators';
import {CardsService} from '../services/cards.service';

export interface ICardState {
    sampledCardNumber?: string;
}

export const initialCardState: ICardState = {};

export const sampleRandomCardNumber = createAction(
    '[Cards] Sample Random',
    props<{ authorityId: number }>()
);

export const randomCardNumberSampled = createAction(
    '[Cards] Random Sample',
    props<{ cardNumber: string }>()
);

const reduce = createReducer(
    initialCardState,
    on(randomCardNumberSampled, (state, {cardNumber}) => ({
        ...state,
        sampledCardNumber: cardNumber
    }))
);

export function cardReducer(state = initialCardState, action: Action): ICardState {
    return reduce(state, action);
}

@Injectable()
export class CardEffects {
    constructor(private actions$: Actions, private cards: CardsService) {
    }

    sampleRandomCardNumber$ = createEffect(
        () => this.actions$.pipe(
            ofType(sampleRandomCardNumber),
            switchMap(({authorityId}) => this.cards.sampleCardNumber(authorityId).pipe(
                filter(({cardNumber}) => !!cardNumber),
                map(({cardNumber}) => randomCardNumberSampled({cardNumber}))
            ))
        )
    );
}

export const getCardState = (state: AppState): ICardState =>
    state.card;

export const getSampledCardNumber = (state: ICardState): string =>
    state.sampledCardNumber;

export const sampledCardNumber = createSelector(
    getCardState,
    getSampledCardNumber
);
