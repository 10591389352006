import {createAction, props} from '@ngrx/store';
import {APLItem} from '@models';

export const aplItemsFiltered = createAction(
    '[APLItem] APL Items Filtered',
    props<{ filterCriteria: string }>()
);

export const aplItemDeleted = createAction(
    '[APLItem] APL Item Deleted',
    props<{ item: APLItem }>()
);

export const aplItemUnDeleted = createAction(
    '[APLItem] APL Item UnDeleted',
    props<{ item: APLItem }>()
);

export const aplItemsSearched = createAction(
    '[APLItem] APL Items Searched',
    props<{ searchCriteria: any }>()
);
