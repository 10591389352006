<div>
    <div class="modal-header">
        <h4 class="modal-title">{{modalTitle}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="isLoading$ | async">
          <div class="col-12"><i class="fa fa-spinner fa-spin"></i></div>
        </div>
        <div class="row" *ngIf="!(isLoading$ | async)">
            <div class="col-4"><label for="value">{{label}}</label></div>
            <div class="col-8">
                <select [(ngModel)]="value" id="value" name="value">
                    <option value="" selected hidden>Select {{label}}</option>
                    <option *ngFor="let item of items$ | async" [ngValue]="item">{{item.name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" [disabled]="isLoading$ | async" (click)="activeModal.close(value)">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">No</button>
    </div>
</div>
