import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {APLImportConfig} from '@models';

@Entity({
    modelName: 'APLSummary'
})
export class APLSummary {
    @Key authorityId: number;
    authority?: string;
    itemCount: number;
    catCount: number;
    subCatCount: number;
    lastUpdated: string;
    config?: APLImportConfig;
}
