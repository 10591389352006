import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { NgrxAutoEntityModule } from '@briebug/ngrx-auto-entity';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EntityService } from '../../entity.service';
import { EnhancedIssuesEffects } from './enhanced-issues.effects';
import { EnhancedIssuesFacade } from './enhanced-issues.facade';
import { EnhancedIssue } from './enhanced-issue.model';
import { ENHANCED_ISSUES_FEATURE_KEY, enhancedIssueReducer } from './enhanced-issues.state';
import { EnhancedIssuesUiService } from './enhanced-issues-ui.service';

@NgModule({
  imports: [
    HttpClientModule,
    NgrxAutoEntityModule.forFeature(),
    EffectsModule.forFeature([EnhancedIssuesEffects]),
    StoreModule.forFeature(ENHANCED_ISSUES_FEATURE_KEY, enhancedIssueReducer),
  ],
  providers: [      
    EnhancedIssuesFacade,
    EnhancedIssuesUiService,

    { provide: EnhancedIssue, useClass: EntityService },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
})
export class EnhancedIssuesFeatureModule {}
