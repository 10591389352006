import {Component, Input} from '@angular/core';
import {IAuthorityInfo} from '../models';

@Component({
    selector: 'wic-authorities-basic-report',
    templateUrl: './wic-authorities-basic-report.component.html',
    styleUrls: ['./wic-authorities-basic-report.component.sass'],
})
export class WicAuthoritiesBasicReportComponent {
    @Input() authoritiesInfo: IAuthorityInfo;
}
