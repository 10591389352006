<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
        <span class="badge badge-info">
            {{authority.id}}
        </span>
            Choose Banner Playlist
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <label for="bannerPlaylistId" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.bannerPlaylistId.invalid">Playlist</label>
            <div class="col-sm-10">
                <select class="form-control"
                        formControlName="bannerPlaylistId"
                        id="bannerPlaylistId"
                        name="bannerPlaylistId">
                    <option value="" selected hidden>(Choose Playlist)</option>
                    <option [ngValue]="-1">[Remove Applied Playlist]</option>
                    <option [ngValue]="playlist.PlaylistID" *ngFor="let playlist of playlists">
                        {{playlist.PlaylistName}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
