import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'wic-wiki',
    templateUrl: './wic-wiki.component.html',
    styleUrls: ['./wic-wiki.component.sass'],
})
export class WicWikiComponent implements OnInit {
    timer = null;
    constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        // TODO: Remove if it works without.
        // this.timer = setTimeout(() => {
        //     if (!this.activatedRoute.firstChild) {
        //         this.router.navigateByUrl('/admin/wiki/articles/home');
        //       // this.router.navigateByUrl(`/apps/${1}/admin/wiki/articles/home`);
        //     }
        // }, 450);
    }

    activated($event) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        console.log($event);
    }

    deactivated($event) {
        console.log($event);
        // this.timer = setTimeout(() => {
        //     if (!this.activatedRoute.firstChild) {
        //         this.router.navigateByUrl('/admin/wiki/articles/home');
        //     }
        // }, 450);
    }
}
