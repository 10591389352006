import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import * as moment from 'moment';
import {from, Observable, ObservableInput} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

export const NEW = 'new';

export const switchCombine = <T, I, R>(inner: ObservableInput<I>) =>
    (source: Observable<T>) => source.pipe(
        switchMap((outerValue: T) => from(inner).pipe(
            map((innerValue: I): [T, I] => [outerValue, innerValue]))
        )
    );

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */
const typeCache: { [label: string]: boolean } = {};

export function type<T>(label: T | ''): T {
    if (typeCache[label as string]) {
        throw new Error(`Action type "${label}" is not unique"`);
    }

    typeCache[label as string] = true;

    return label as T;
}

export interface IRouterStateUrl {
    url: string;
    queryParams: Params;
    timestamp: string;
}

export class CustomRouterStateSerializer implements RouterStateSerializer<IRouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): IRouterStateUrl {
        const { url } = routerState;
        const queryParams = routerState.root.queryParams;
        const timestamp = moment().format();

        return { url, queryParams, timestamp };
    }
}

/**
 * This function flattens and maps an array recursively,
 * with optional custom selection of child array if
 * mapping an array of objects
 */
export const flatMap = (value, mapper, selector) =>
    Array.isArray(value) ?
        [].concat(...value.map(v =>
            [].concat(
                mapper(v),
                ...flatMap(selector ? selector(v) : v, mapper, selector)
            )
        )) : mapper(value);

export class Sorter {
    sortKey = null;

    direction = 1;

    constructor(protected getter: () => any[], protected setter: (sorted: any[]) => void, protected defaultDirection = 1) {
        this.direction = defaultDirection;
    }

    sortString(key: string, defaultDirection = this.defaultDirection) {
        this.updateKeyAndDirection(key, defaultDirection);

        const sortable = this.getter();
        const sorted = sortable.sort((a, b) => {
            const aval = a[key] || '';
            const bval = b[key] || '';
            let diff = aval.toString().localeCompare(bval.toString(), 'standard', { numeric: true });
            diff = diff * this.direction;
            return diff;
        });

        this.setter(sorted);
    }

    sortNumber(key: string, defaultDirection = this.defaultDirection) {
        this.updateKeyAndDirection(key, defaultDirection);

        const sortable = this.getter();
        const sorted = sortable.sort((a, b) => {
            const aval = a[key] || 0;
            const bval = b[key] || 0;
            let diff = aval - bval;
            diff = diff * this.direction;
            return diff;
        });

        this.setter(sorted);
    }

    sortDate(key: string, defaultDirection = this.defaultDirection) {
        this.updateKeyAndDirection(key, defaultDirection);

        const sortable = this.getter();
        const sorted = sortable.sort((a, b) => {
            const aval = a[key] || new Date('1970-01-01T00:00:00');
            const bval = b[key] || new Date('1970-01-01T00:00:00');
            let diff = aval - bval;
            diff = diff * this.direction;
            return diff;
        });

        this.setter(sorted);
    }

    protected updateKeyAndDirection(key: string, defaultDirection = this.defaultDirection) {
        if (key === this.sortKey) {
            this.direction *= -1;
        } else {
            this.sortKey = key;
            this.direction = defaultDirection;
        }
    }
}

export class CompositeKeySorter extends Sorter {
    sortComposite(key: string, value: (obj) => string, defaultDirection = this.defaultDirection) {
        this.updateKeyAndDirection(key, defaultDirection);

        const sortable = this.getter();
        const sorted = sortable.sort((a, b) => {
            const aval = value(a) || '';
            const bval = value(b) || '';
            let diff = aval.localeCompare(bval, 'standard', { numeric: true });
            diff = diff * this.direction;
            return diff;
        });

        this.setter(sorted);
    }
}
