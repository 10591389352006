import { Injectable } from '@angular/core';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {
    FETCH_AUTHORITY_FLAGS,
    FetchAuthorityFlagsFailureAction,
    FetchAuthorityFlagsSuccessAction,
} from '@state/authorities/flags.actions';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { AuthoritiesService } from '@services';

@Injectable()
export class AuthorityFlagsEffects {
    @Effect()
    fetchAuthorities = this.actions.pipe(
        ofType(FETCH_AUTHORITY_FLAGS),
        exhaustMap(() => this.authoritiesService.listFlags().pipe(
            map(flags => new FetchAuthorityFlagsSuccessAction(flags)),
            catchError(err => of(new FetchAuthorityFlagsFailureAction(err)))
        ))
    );

    // @Effect()
    // fetchAuthority = this.actions.ofType(FETCH_AUTHORITY)
    //     .pipe(
    //         exhaustMap((action: FetchAuthorityAction) => {
    //             return this.AuthoritiesService.fetchAuthority(action.payload)
    //                 .pipe(
    //                     map(authority => new FetchAuthoritySuccessAction(authority)),
    //                     catchError(err =>
    //                         of(new FetchAuthorityFailureAction(err))
    //                     )
    //                 );
    //         })
    //     );
    //
    // @Effect()
    // createAuthority = this.actions.ofType(CREATE_AUTHORITY)
    //     .pipe(
    //         map((action: CreateAuthorityAction) => action.payload),
    //         exhaustMap((authority: Authority) => {
    //             return this.AuthoritiesService.createAuthority(authority)
    //                 .pipe(
    //                     map(created => new CreateAuthoritySuccessAction(created)),
    //                     catchError(err =>
    //                         of(new CreateAuthorityFailureAction(err))
    //                     )
    //                 );
    //         })
    //     );
    //
    // @Effect()
    // updateAuthority: Observable<Action> = this.actions.ofType(UPDATE_AUTHORITY)
    //     .pipe(
    //         map((action: UpdateAuthorityAction) => action.payload),
    //         exhaustMap((authority: Authority) => {
    //             return this.AuthoritiesService.updateAuthority(authority)
    //                 .pipe(
    //                     map(updated => new UpdateAuthoritySuccessAction(updated)),
    //                     catchError(err => of(new UpdateAuthorityFailureAction(err)))
    //                 );
    //         })
    //     );
    //
    // @Effect()
    // deleteAuthority = this.actions.ofType(DELETE_AUTHORITY)
    //     .pipe(
    //         map((action: DeleteAuthorityAction) => action.payload),
    //         exhaustMap((authority: Authority) => {
    //             return this.AuthoritiesService.deleteAuthority(authority._id)
    //                 .pipe(
    //                     map(id => new DeleteAuthoritySuccessAction(id)),
    //                     catchError(err => of(new DeleteAuthorityFailureAction(err)))
    //                 );
    //         })
    //     );

    constructor(private actions: Actions, private authoritiesService: AuthoritiesService) {
    }
}
