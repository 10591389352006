<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <span class="badge badge-info" *ngIf="banner?.BannerID">
                {{banner.BannerID}}
            </span>
            {{banner?.BannerID ? 'Edit' : 'Create'}} Banner
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label for="PartnerID" class="col-sm-2 col-form-label">Partner</label>
            <div class="col-sm-10">
                <select class="form-control"
                        formControlName="PartnerID"
                        id="PartnerID"
                        name="PartnerID">
                    <option value="" selected hidden>(Choose Partner)</option>
                    <option [ngValue]="partner.PartnerID" *ngFor="let partner of partners">
                        {{partner.PartnerName}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label for="BannerName" class="col-2 col-form-label">Name</label>
            <div class="col-10">
                <input class="form-control" formControlName="BannerName" name="BannerName" id="BannerName">
            </div>
        </div>
        <div class="form-group row">
            <label for="IsActive" class="col-2 form-check-label">Active</label>
            <div class="col-10">
                <input type="checkbox" class="ml-1 form-check-input" formControlName="IsActive" name="IsActive" id="IsActive">
            </div>
        </div>
      <div class="form-group row">
            <label for="IsPriority" class="col-2 form-check-label">Priority</label>
            <div class="col-10">
                <input type="checkbox" class="ml-1 form-check-input" formControlName="IsPriority" name="IsPriority" id="IsPriority">
            </div>
        </div>
        <div class="form-group row">
            <label for="BannerDescription" class="col-2 col-form-label">Description</label>
            <div class="col-10">
                <input class="form-control" formControlName="BannerDescription" name="BannerDescription" id="BannerDescription">
            </div>
        </div>
        <div class="form-group row">
            <label for="LinkURL" class="col-2 col-form-label">Link URL</label>
            <div class="col-10">
                <input class="form-control" formControlName="LinkURL" name="LinkURL" id="LinkURL">
            </div>
        </div>

        <div class="form-group row">
            <label for="image" class="col-2 col-form-label">Image</label>
            <div class="col-10">
                <label class="custom-file form-control">
                    <input #file class="custom-file-input"
                           id="image"
                           type="file"
                           accept="image/jpeg,image/png,image/gif"
                           (change)="imageChanged($event)"/>
                    <span class="custom-file-control"></span>
                </label>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-2 col-form-label"></label>
            <div class="col-10">
                <img class="" *ngIf="form.get('Image').value" style="max-width: 630px"
                     [src]="createImageUrl(form.get('Image').value)"/>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-2 col-form-label"></div>
            <div class="col-10">
                <small class="text-muted">For optimal performance, please use images 750x150px in size.</small>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
