import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {ObjectId} from '@models/types';

@Entity({
    modelName: 'APLMessage'
})
export class APLMessage {
    // tslint:disable-next-line:variable-name
    @Key _id?: ObjectId;
    name: string;
    isActive: boolean;
    onBenefits: boolean;
    onDetail: boolean;
    icon: string;
}

@Entity({
    modelName: 'APLMessageText'
})
export class APLMessageText {
    // tslint:disable-next-line:variable-name
    @Key _id?: ObjectId;
    messageId: ObjectId;
    languageCode: string;
    title: string;
    message: string;
}

@Entity({
    modelName: 'APLMessageMapping'
})
export class APLMessageMapping {
    // tslint:disable-next-line:variable-name
    _id: ObjectId;
    @Key authorityId: number;
    @Key categoryId: number;
    @Key subCategoryId: number;
    messageId: ObjectId;
}
