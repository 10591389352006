<div class="card mt-5">
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                <h5 class="float-left mb-0">Record Transformations</h5>
            </div>
            <div class="col-1" *ngIf="!showToolbar">
                <i class="fa fa-plus" (click)="added.emit()"></i>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="transforms?.length">
            <thead>
            <tr>
                <th class="text-nowrap">Name</th>
                <th class="text-nowrap">Enabled</th>
                <th class="text-nowrap"><i class="fa fa-code-fork"></i> Match Rule</th>
                <th class="text-nowrap"><i class="fa fa-file-code-o"></i> Transformation</th>
                <th class="text-center"><i class="fa fa-wrench"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let transform of transforms" [class.bg-danger]="transform._id === forDeletion?._id">
                <td class="" [class.text-white]="transform._id === forDeletion?._id">
                    {{transform.name}}
                </td>
                <td class="">
                    <span class="badge badge-pill text-white"
                          [class.badge-danger]="!transform.isEnabled"
                          [class.badge-success]="transform.isEnabled">{{transform.isEnabled ? 'Yes' : 'No'}}</span>
                </td>
                <td class="" [class.text-white]="transform._id === forDeletion?._id">
                    <span>{{transform.rule}}</span>
                </td>
                <td class="" [class.text-white]="transform._id === forDeletion?._id">
                    <span>{{transform.transform}}</span>
                </td>
                <td class="text-right" [class.text-white]="transform._id === forDeletion?._id">
                    <i class="fa fa-edit" (click)="edited.emit(transform);"></i>
                    <i class="fa fa-trash" (click)="deleted.emit(transform);"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!transforms?.length" class="card-body">
        <p *ngIf="isLoading"><i class="fa fa-spinner fa-spin"></i> Loading transformation rules...</p>
        <p *ngIf="!isLoading"><em>There are no transformation rules for this authority.</em></p>
    </div>
    <div class="card-footer" *ngIf="showToolbar">
        <div class="row">
            <div class="col-md-6">
                <!--<a class="btn btn-outline-secondary float-left" role="button"-->
                <!--[download]="getFilename()" [href]="buildDownloadUri(appointments)">-->
                <!--<i class="fa fa-download"></i> Download as CSV-->
                <!--</a>-->
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh" [class.fa-spin]="isLoading"></i> Refresh
                </button>
                <button class="btn btn-outline-primary" (click)="added.emit()">
                    <i class="fa fa-plus"></i> Add Transformation
                </button>
            </div>
        </div>
    </div>
</div>
