import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DemoAccount, DemoAccountBenefitGroup } from '@models';
import { ILookup } from '@models/types';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

const createDemoBenefitCSVChunk = (demo: DemoAccount) =>
  demo.groups
    ?.map(
      group =>
        `${demo.authorityId},"${demo.name}","${group.cardNumber}",${group.cardNumber.slice(0, 8)},${group.beginDate},${
          group.endDate
        }`
    )
    .join('\r\n');

@Component({
  selector: 'wic-demo-accounts-table',
  templateUrl: './wic-demo-accounts-table.component.html',
  styleUrls: ['./wic-demo-accounts-table.component.scss'],
})
export class WicDemoAccountsTableComponent implements OnInit {
  @Input() demoAccounts$: Observable<DemoAccount[]>;
  @Input() authorities: ILookup[];
  @Input() loading = true;
  @Input() saving = false;
  @Input() showFilter = true;
  @Input() showToolbar = true;
  @Output() deleted = new EventEmitter<DemoAccount>();
  @Output() refreshed = new EventEmitter();
  @Output() added = new EventEmitter();
  @Output() replicate = new EventEmitter();
  @Output() addGroup = new EventEmitter<DemoAccount>();
  @Output() toggleActive = new EventEmitter<DemoAccount>();
  @Output() editGroup = new EventEmitter<{ demoAccount: DemoAccount; benefitGroup: DemoAccountBenefitGroup }>();
  @Output() deleteGroup = new EventEmitter<{ demoAccount: DemoAccount; benefitGroup: DemoAccountBenefitGroup }>();
  @Output() selected = new EventEmitter<DemoAccount>();

  authorityForm: FormControl;
  activeAccount: DemoAccount;
  filteredDemoAccounts$: Observable<DemoAccount[]>;
  downloadURI$: Observable<SafeUrl>;
  filename = `Download_${new Date().toISOString()}`;

  constructor(private formBuilder: FormBuilder, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.authorityForm = this.formBuilder.control([]);

    this.filteredDemoAccounts$ = combineLatest([this.authorityForm.valueChanges, this.demoAccounts$]).pipe(
      debounceTime(500),
      map(([ids, demoAccounts]: [number[], DemoAccount[]]) =>
        ids.length ? demoAccounts.filter(account => ids.includes(account.authorityId)) : demoAccounts
      )
    );

    this.downloadURI$ = this.demoAccounts$.pipe(
      map(
        demoAccounts =>
          'AuthorityID,Name,Card Number,Bin Code,Start Date,End Date\r\n' +
          demoAccounts.map(createDemoBenefitCSVChunk).join('')
      ),
      map(csv => this.sanitizer.bypassSecurityTrustUrl(`data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`))
    );
  }

  trackDemoAccountBy(index: number, demoAccount: DemoAccount) {
    return demoAccount.cardNumber;
  }

  emitEditGroup(demoAccount: DemoAccount, benefitGroup: DemoAccountBenefitGroup) {
    this.editGroup.emit({ demoAccount, benefitGroup });
  }

  emitDeleteGroup(demoAccount: DemoAccount, benefitGroup: DemoAccountBenefitGroup) {
    this.deleteGroup.emit({ demoAccount, benefitGroup });
  }
}
