import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {APLOverride, CategoryLookup, SubCategoryLookup} from '@models';

@Component({
    selector: 'wic-apl-overrides-table',
    templateUrl: './wic-apl-overrides-table.component.html',
    styleUrls: ['./wic-apl-overrides-table.component.sass']
})
export class WicAplOverridesTableComponent implements OnInit {
    @Input() overrides: APLOverride[];
    @Input() forDeletion: APLOverride;
    @Input() categories: CategoryLookup[];
    @Input() subCategories: SubCategoryLookup[];
    @Input() isLoading: boolean;
    @Input() showToolbar = true;
    @Input() areShared = true;

    @Output() added = new EventEmitter();
    @Output() edited = new EventEmitter<APLOverride>();
    @Output() deleted = new EventEmitter<APLOverride>();
    @Output() refreshed = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

}
