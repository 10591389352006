import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'ifIncluded'})
export class IfIncludedPipe implements PipeTransform {
  transform(value: any, possibilities: any[]): any {
    return !!possibilities && !!possibilities.length && possibilities.includes(value)
      ? value
      : undefined;
  }
}
