import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import { IAppState } from '@state/app.state';
import {FileUploader} from 'ng2-file-upload';
import {environment} from '../../../../environments/environment';
import * as loadingActions from '../../../state/loading/loading.actions';

const URL = `${environment.apiRoot}/admin/demo-accounts/upload`;

@Component({
    selector: 'wic-demo-accounts-uploader',
    templateUrl: './wic-demo-accounts-uploader.component.html',
    styleUrls: ['./wic-demo-accounts-uploader.component.sass'],
})
export class WicDemoAccountsUploaderComponent implements OnInit {
    uploader: FileUploader = new FileUploader({
        url: URL,
        itemAlias: 'demo-accounts',
        authToken: localStorage.getItem('token'),
    });

    constructor(private store: Store<IAppState>) {
    }

    ngOnInit() {
        this.uploader.onSuccessItem = this.onSuccessItem.bind(this);
        this.uploader.onErrorItem = this.onErrorItem.bind(this);
    }

    upload() {
        this.store.dispatch(new loadingActions.LoadingStartAction());
        this.uploader.uploadAll();
    }

    onSuccessItem(item: any, response: string, status: number, headers: any) {
        const demoAccounts = JSON.parse(response)
            .map(demoAccount => {
                delete demoAccount.type;
                return demoAccount;
            });

        // this.store.dispatch(new demoAccountActions.UploadDemoAccountSuccessAction(demoAccounts));
        this.store.dispatch(new loadingActions.LoadingEndAction());
    }

    onErrorItem(item: any, response: string, status: number, headers: any) {
        // this.store.dispatch(new demoAccountActions.UploadDemoAccountFailureAction(JSON.parse(response)));
        this.store.dispatch(new loadingActions.LoadingEndAction());
    }
}
