import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { AuthorityLocation } from '@models/AuthorityLocation';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthorityLocationsService implements IAutoEntityService<AuthorityLocation> {

    constructor(private http: HttpClient) {}

    loadAll(entityInfo: IEntityInfo, criteria): Observable<AuthorityLocation[]> {
        return this.http.get<AuthorityLocation[]>(`${environment.apiRoot}/admin/authorities/${criteria.authorityId}/offices`);
    }

    create(entityInfo: IEntityInfo, location: AuthorityLocation): Observable<AuthorityLocation> {
        return this.http.post<AuthorityLocation>(
            `${environment.apiRoot}/admin/authorities/${location.authorityId}/offices`,
            location
        );
    }

    update(entityInfo: IEntityInfo, location: AuthorityLocation): Observable<AuthorityLocation> {
        return this.http.patch<AuthorityLocation>(
            `${environment.apiRoot}/admin/authorities/${location.authorityId}/offices/${location.locationId}`,
            location
        );
    }

    delete(entityInfo: IEntityInfo, location: AuthorityLocation): Observable<AuthorityLocation> {
        return this.http.delete<AuthorityLocation>(
            `${environment.apiRoot}/admin/authorities/${location.authorityId}/offices/${location.locationId}`
        );
    }
}
