import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IEntityInfo} from '@briebug/ngrx-auto-entity';
import {EnhancedDevice} from '@models/EnhancedDevice';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SessionCriteria, SessionKey} from './device-log-session.service';

@Injectable({
    providedIn: 'root'
})
export class EnhancedDeviceService {
    constructor(private http: HttpClient) {
    }

    load(info: IEntityInfo, key: SessionKey): Observable<EnhancedDevice> {
        return this.http.get<EnhancedDevice>(`${environment.apiRoot}/devices/${key.udid}/enhanced`);
    }

    loadAll(info: IEntityInfo, criteria: SessionCriteria = {}): Observable<EnhancedDevice[]> {
        return this.http.get<EnhancedDevice[]>(`${environment.apiRoot}/devices/enhanced`);
    }

    loadMany(info: IEntityInfo, criteria: SessionCriteria = {}): Observable<EnhancedDevice[]> {
        return this.http.get<EnhancedDevice[]>(`${environment.apiRoot}/devices/enhanced`);
    }

    update(info: IEntityInfo, entity: EnhancedDevice): Observable<EnhancedDevice> {
        return this.http.put<EnhancedDevice>(`${environment.apiRoot}/devices/${entity.udid}/enhanced`, entity);
    }

    delete(entityInfo: IEntityInfo, entity: EnhancedDevice): Observable<EnhancedDevice> {
        return this.http.delete(`${environment.apiRoot}/devices/${entity.udid}/enhanced`).pipe(
            map(() => entity)
        );
    }
}
