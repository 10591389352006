<div class="card mt-5">
    <div class="card-body">
        <!--<h6 class="card-title text-muted text-right">Authority Distributions</h6>-->
        <div class="row">
            <div class="col-md-4 text-center text-nowrap mb-1">
                <wic-doughnut-labeled
                    [data]="[authoritiesInfo.activeCount,authoritiesInfo.inactiveCount]"
                    [labels]="['Active','Inactive']"
                    [colors]="['#7cda87','#ff8680']"
                    chartTitle="Active/Inactive">
                </wic-doughnut-labeled>
            </div>
            <div class="col-md-4 text-center text-nowrap mb-1">
                <wic-doughnut-labeled
                    [data]="[authoritiesInfo.providerCount,authoritiesInfo.offlineCount+authoritiesInfo.verifyCount]"
                    [labels]="['Provider','Offline/Verify Only']"
                    [colors]="['#7cda87','#ff8680']"
                    chartTitle="Online/Offline">
                </wic-doughnut-labeled>
            </div>
            <div class="col-md-4 text-center text-nowrap mb-1">
                <wic-doughnut-labeled
                    [data]="[authoritiesInfo.providerCount,authoritiesInfo.offlineCount,authoritiesInfo.verifyCount,authoritiesInfo.voucherCount,authoritiesInfo.nonSubCount]"
                    [labels]="['Online','Offline','Verify','Voucher','Non-Sub']"
                    [colors]="['#7cda87','#ff8680','#d9cc41','#d9cc41']"
                    chartTitle="Types">
                </wic-doughnut-labeled>
            </div>
        </div>
    </div>
</div>
