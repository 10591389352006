import {Injectable} from '@angular/core';
import {Load, LoadAll, Select} from '@briebug/ngrx-auto-entity';
import {ServerSettings} from '@models';
import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {allServerSettings, currentServerSettings, rootServerSettings} from '@state/server-settings.state';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ServerSettingsFacade {
    constructor(private store: Store<IAppState>) {
    }

    // region Selections
    selectAll(): Observable<ServerSettings[]> {
        return this.store.pipe(select(allServerSettings));
    }

    selectRoot(): Observable<ServerSettings> {
        return this.store.pipe(select(rootServerSettings));
    }

    selectCurrent(): Observable<ServerSettings> {
        return this.store.pipe(select(currentServerSettings));
    }
    // endregion

    // region Dispatches
    setCurrent(settings: ServerSettings) {
        this.store.dispatch(new Select(ServerSettings, settings));
    }

    loadAll() {
        this.store.dispatch(new LoadAll(ServerSettings));
    }
    // endregion
}
