import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {APLTransform} from '@models/APLTransform';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '@services';

@Component({
    selector: 'wic-apl-transform-edit',
    templateUrl: './wic-apl-transform-edit.component.html',
    styleUrls: ['./wic-apl-transform-edit.component.sass']
})
export class WicAplTransformEditComponent implements OnInit {
    @Input() transform: APLTransform;

    form: FormGroup;
    submitted = false;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public util: UtilService
    ) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);
        this.populateForm(this.transform);
    }

    private buildForm(formBuilder) {
        const form = formBuilder.group({
            name: [null, Validators.required],
            rule: [null, Validators.required],
            transform: [null, Validators.required],
            isEnabled: [true, Validators.required]
        });

        return form;
    }

    populateForm(transform) {
        this.form.patchValue({
            ...transform
        });
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        const transform = {
            ...value,
            authorityId: this.transform.authorityId,
            _id: !this.transform._id ? undefined : this.transform._id
        };
        this.activeModal.close(transform);
    }
}
