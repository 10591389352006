import {Pipe, PipeTransform} from '@angular/core';
import {CategoryLookup, SubCategoryLookup} from '@models';

@Pipe({name: 'ifCategoryShown', pure: false})
export class IfCategoryShownPipe implements PipeTransform {
    transform(
        subCategories: SubCategoryLookup[], category: CategoryLookup, shown: number[], altHideFlag: boolean = false
    ): SubCategoryLookup[] {
        return !altHideFlag && shown.some(id => category.categoryId === id) ? subCategories : [];
    }
}
