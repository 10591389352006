import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {Banner, BannerPlaylist, Partner} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wic-banner-playlist-entry-edit',
    templateUrl: './wic-banner-playlist-entry-edit.component.html',
    styleUrls: ['./wic-banner-playlist-entry-edit.component.sass']
})
export class WicBannerPlaylistEntryEditComponent implements OnInit {
    @Input() banner: Banner;
    @Input() playlist: BannerPlaylist;
    @Input() partners: Partner[];

    form: FormGroup;
    submitted = false;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);
        this.populateForm(this.banner);
    }

    private buildForm(formBuilder) {
        const form = formBuilder.group({
            PartnerID: [null, Validators.required],
            BannerName: [null, Validators.required],
            BannerDescription: [null],
            LinkURL: [null, Validators.required],
            Image: [null, Validators.required],
            IsActive: [true, Validators.required],
            IsPriority: [true, Validators.required]
        });

        return form;
    }

    populateForm(banner) {
        this.form.patchValue({
            ...banner,
            Image: banner.ImageType && banner.ImageData ? `data:${banner.ImageType};base64,${banner.ImageData}` : null
        });
    }

    imageChanged(event) {
        if (!event.target.files.length) {
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            this.form.patchValue({Image: reader.result});
            this.changeDetectorRef.markForCheck();
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    createImageUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        const [ type, raw ] = (value.Image || '').substring(5).split(';');
        const [ , data ] = (raw || '').split(',');

        const banner = {
            ...value,
            Image: undefined,
            PlaylistID: this.playlist.PlaylistID,
            BannerID: this.banner.BannerID,
            ImageType: type || undefined,
            ImageData: data || undefined
        };

        this.activeModal.close(banner);
    }
}
