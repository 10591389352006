import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'wic-users',
    templateUrl: './wic-users.component.html',
    styleUrls: ['./wic-users.component.sass'],
})
export class WicUsersComponent implements OnInit {
    hideSelf = false;

    constructor() { }

    ngOnInit() {
    }

}
