<div class="text-right mb-2">
  <button class="btn btn-outline-secondary" (click)="dateRangeChanged.emit(dateRange)" type="button">
    Run Modern Report
  </button>
</div>

<div class="card">
    <div class="card-header">
        <form class="float-left form-inline">
            <div class="form-group mr-3">
                <div class="input-group">
                    <input class="form-control" placeholder="Start Date" [(ngModel)]="dateRange.startDate"
                           name="startDate" ngbDatepicker #startDate="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="startDate.toggle()" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group mr-3">
                <div class="input-group">
                    <input class="form-control" placeholder="End Date" [(ngModel)]="dateRange.endDate"
                           name="endDate" ngbDatepicker #endDate="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="endDate.toggle()" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group mr-3">
                <button class="btn btn-outline-secondary" (click)="dateRangeChanged.emit(dateRange)" type="button">
                    <i class="fa fa-refresh" [ngClass]="{'fa-spin': isLoading}"></i>
                </button>
            </div>
        </form>
        <h5 class="float-right mt-2 text-muted">Usage Statistics</h5>
    </div>
    <div>
        <span class="text-muted float-left ml-2 my-2" *ngIf="isLoading">
            <i class="fa fa-spinner fa-spin"></i> Generating report...
        </span>
        <small class="text-mited float-left ml-2 my-2" *ngIf="!isLoading && loadDuration">Results from {{criteria?.startDate || (dateRange.startDate | datePickerFormat:'MM/dd/yyyy')}} to {{criteria?.endDate || (dateRange.endDate | datePickerFormat:'MM/dd/yyyy')}}</small>
        <small class="text-muted float-right mr-2 my-2" *ngIf="!isLoading && loadDuration">Generated in {{loadDuration}} at {{loadedAt | date:'short'}}</small>
    </div>
    <div class="">
        <table class="table table-sm table-striped fixed-header mb-0" *ngIf="report && report.length">
            <thead>
            <tr>
                <th class="" (click)="reportSorter.sortString('authority', 1)" colspan="2">
                    Authority
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-alpha-asc': reportSorter.direction === 1, 'fa-sort-alpha-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'authority'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('totalCards')">
                    Total Cards
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'totalCards'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('uniqueShoppingTrips')">
                    Unique Trips
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'uniqueShoppingTrips'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('totalShoppingTrips')">
                    Total Trips
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'totalShoppingTrips'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('registrations')">
                    New Registrations
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'registrations'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('deviceRegistrations')">
                    Device Reg.
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'deviceRegistrations'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('deviceUsage')">
                    Device Usage
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'deviceUsage'"></i>
                </th>
                <th class="text-nowrap" (click)="reportSorter.sortNumber('uniqueProductChecks')">
                    Unique Products
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-amount-asc': reportSorter.direction === 1, 'fa-sort-amount-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'uniqueProductChecks'"></i>
                </th>
                <th class="text-nowrap" (click)="reportSorter.sortNumber('totalProductChecks')">
                    Total Products
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-amount-asc': reportSorter.direction === 1, 'fa-sort-amount-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'totalProductChecks'"></i>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let record of report">
                <td class="align-middle">
                    <img [src]="apiUrl + '/authorities/' + record.authorityId + '/logo'" style="max-width: 50px; max-height: 50px">
                </td>
                <td class="align-middle">{{record.authority}}</td>
                <td class="align-middle">{{record.totalCards}}</td>
                <td class="align-middle">{{record.uniqueShoppingTrips}}</td>
                <td class="align-middle">{{record.totalShoppingTrips}}</td>
                <td class="align-middle">{{record.registrations}}</td>
                <td class="align-middle">{{record.deviceRegistrations}}</td>
                <td class="align-middle">{{record.deviceUsage}}</td>
                <td class="align-middle">{{record.uniqueProductChecks}}</td>
                <td class="align-middle">{{record.totalProductChecks}}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <th class="align-middle"></th>
                <th class="align-middle">{{totals.authority}}</th>
                <th class="align-middle">{{totals.totalCards}}</th>
                <th class="align-middle">{{totals.uniqueShoppingTrips}}</th>
                <th class="align-middle">{{totals.totalShoppingTrips}}</th>
                <th class="align-middle">{{totals.registrations}}</th>
                <th class="align-middle">{{totals.deviceRegistrations}}</th>
                <th class="align-middle">{{totals.deviceUsage}}</th>
                <th class="align-middle">{{totals.uniqueProductChecks}}</th>
                <th class="align-middle">{{totals.totalProductChecks}}</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <div class="card-footer bg-transparent">
        <a class="btn btn-outline-primary float-left" role="button"
           [download]="getFilename(dateRange)" [href]="buildDownloadUri(report)">
            <i class="fa fa-download"></i> Download as CSV
        </a>
        <span class="text-muted float-right mt-2" *ngIf="isLoading">
            <i class="fa fa-spinner fa-spin"></i> Generating report...
        </span>
    </div>
</div>
