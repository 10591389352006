import {Action} from '@ngrx/store';

import {Authority, BinCode} from '@models';
import {type} from '../shared/utils';

export const CREATE_BINCODE = type('[BINCodes] Create authority');
export const CREATE_BINCODE_SUCCESS = type('[BINCodes] Create authority succeeded');
export const CREATE_BINCODE_FAILURE = type('[BINCodes] Create authority failed');
export const DELETE_BINCODE = type('[BINCodes] Delete authority');
export const DELETE_BINCODE_SUCCESS = type('[BINCodes] Delete authority succeeded');
export const DELETE_BINCODE_FAILURE = type('[BINCodes] Delete authority failed');
export const DISMISS_BINCODES_ERROR = type('[BINCodes] Dismiss error');
export const FETCH_BINCODES = type('[BINCodes] Fetch authorities');
export const FETCH_BINCODES_SUCCESS = type('[BINCodes] Fetch authorities succeeded');
export const FETCH_BINCODES_FAILURE = type('[BINCodes] Fetch authorities failed');
export const FETCH_BINCODE = type('[BINCodes] Fetch authority');
export const FETCH_BINCODE_SUCCESS = type('[BINCodes] Fetch authority succeeded');
export const FETCH_BINCODE_FAILURE = type('[BINCodes] Fetch authority failed');
export const UPDATE_BINCODE = type('[BINCodes] Update authority');
export const UPDATE_BINCODE_SUCCESS = type('[BINCodes] Update authority succeeded');
export const UPDATE_BINCODE_FAILURE = type('[BINCodes] Update authority failed');

export const SELECT_BINCODE = type('[BINCodes] Select authority');
export const SELECT_BINCODE_BY_ID = type('[BINCodes] Select authority by id');

export class CreateBINCodeAction implements Action {
    readonly type = CREATE_BINCODE;

    constructor(public payload: BinCode) {
    }
}

export class CreateBINCodeSuccessAction implements Action {
    readonly type = CREATE_BINCODE_SUCCESS;

    constructor(public payload: BinCode) {
    }
}

export class CreateBINCodeFailureAction implements Action {
    readonly type = CREATE_BINCODE_FAILURE;

    constructor(public payload: any) {
    }
}

export class DeleteBINCodeAction implements Action {
    readonly type = DELETE_BINCODE;

    constructor(public payload: BinCode) {
    }
}

export class DeleteBINCodeSuccessAction implements Action {
    readonly type = DELETE_BINCODE_SUCCESS;

    constructor(public payload: string) {
    }
}

export class DeleteBINCodeFailureAction implements Action {
    readonly type = DELETE_BINCODE_FAILURE;

    constructor(public payload: any) {
    }
}

export class DismissBINCodesErrorAction implements Action {
    readonly type = DISMISS_BINCODES_ERROR;
}

export class FetchBINCodesAction implements Action {
    readonly type = FETCH_BINCODES;

    constructor(public payload: Authority) {
    }
}

export class FetchBINCodesSuccessAction implements Action {
    readonly type = FETCH_BINCODES_SUCCESS;

    constructor(public payload: BinCode[]) {
    }
}

export class FetchBINCodesFailureAction implements Action {
    readonly type = FETCH_BINCODES_FAILURE;

    constructor(public payload: any) {
    }
}

export class FetchBINCodeAction implements Action {
    readonly type = FETCH_BINCODE;

    constructor(public payload: string) {
    }
}

export class FetchBINCodeSuccessAction implements Action {
    readonly type = FETCH_BINCODE_SUCCESS;

    constructor(public payload: BinCode) {
    }
}

export class FetchBINCodeFailureAction implements Action {
    readonly type = FETCH_BINCODE_FAILURE;

    constructor(public payload: any) {
    }
}

export class UpdateBINCodeAction implements Action {
    readonly type = UPDATE_BINCODE;

    constructor(public payload: BinCode) {
    }
}

export class UpdateBINCodeSuccessAction implements Action {
    readonly type = UPDATE_BINCODE_SUCCESS;

    constructor(public payload: BinCode) {
    }
}

export class UpdateBINCodeFailureAction implements Action {
    readonly type = UPDATE_BINCODE_FAILURE;

    constructor(public payload: any) {
    }
}

export class SelectBINCodeAction implements Action {
    readonly type = SELECT_BINCODE;

    constructor(public payload: BinCode) {
    }
}

export class SelectBINCodeByIdAction implements Action {
    readonly type = SELECT_BINCODE_BY_ID;

    constructor(public payload: string) {
    }
}

export type BINCodeActions =
    | CreateBINCodeAction
    | CreateBINCodeFailureAction
    | CreateBINCodeSuccessAction
    | DeleteBINCodeAction
    | DeleteBINCodeFailureAction
    | DeleteBINCodeSuccessAction
    | DismissBINCodesErrorAction
    | FetchBINCodesAction
    | FetchBINCodesFailureAction
    | FetchBINCodesSuccessAction
    | FetchBINCodeAction
    | FetchBINCodeFailureAction
    | FetchBINCodeSuccessAction
    | UpdateBINCodeAction
    | UpdateBINCodeFailureAction
    | UpdateBINCodeSuccessAction
    | SelectBINCodeAction
    | SelectBINCodeByIdAction;
