import { Component, OnInit } from '@angular/core';
import {EnhancedDeviceFacade} from '@facades/enhanced-device.facade';
import {EnhancedDevice} from '@models';

@Component({
  selector: 'wic-enhanced-devices',
  templateUrl: './enhanced-devices.component.html',
  styleUrls: ['./enhanced-devices.component.sass']
})
export class EnhancedDevicesComponent {
    hideSelf = false;

  constructor(public enhancedDevices: EnhancedDeviceFacade) {
      enhancedDevices.loadAll();
  }
}
