import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthorityAccount} from '@models';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthorityAccountsService {

    constructor(private http: HttpClient) {
    }

    get(authorityId: number): Observable<AuthorityAccount> {
        return this.http.get<AuthorityAccount>(`${environment.apiRoot}/admin/authorities/${authorityId}/account`);
    }

    create(account: AuthorityAccount): Observable<AuthorityAccount> {
        return this.http.post<AuthorityAccount>(`${environment.apiRoot}/admin/authorities/${account.authorityId}/account`, account);
    }

    update(account: AuthorityAccount): Observable<AuthorityAccount> {
        return this.http.patch<AuthorityAccount>(`${environment.apiRoot}/admin/authorities/${account.authorityId}/account`, account);
    }

    delete(authorityId: number): Observable<any> {
        return this.http.delete(`${environment.apiRoot}/admin/authorities/${authorityId}/account`);
    }
}
