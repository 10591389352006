import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { AvailableApp } from '@state/available-apps/models/available-app.entity';
import { Action, createReducer } from '@ngrx/store';

export interface AvailableAppsState extends IEntityState<AvailableApp> {}

export const {
  actions: {
    loadAll: loadAllAvailableApps,
    loadAllSuccess: loadAllAvailableAppsSuccess,
    selectByKey: selectAvailableAppByKey,
    selected: availableAppSelected
  },
  selectors: {
    selectCurrentEntityKey: currentAvailableAppId
  },
  initialState: initialAvailableAppsState,
  facade: AvailableAppsFacadeBase
} = buildState(AvailableApp);

const reduce = createReducer(initialAvailableAppsState)

export function availableAppsReducer(state: AvailableAppsState = initialAvailableAppsState, action: Action) {
  return reduce(state, action);
}

