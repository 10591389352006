import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { unixTimeToDate } from '../transform-utils';

@Entity({
  modelName: 'Translation',
  uriName: 'translations',
  transform: [unixTimeToDate]
})
export class Translation {
  // tslint:disable-next-line:variable-name
  @Key _id?: string;
  group: string;
  key: string;
  lang: string;
  value?: string;
  updatedAt?: Date;
}
