import { Component, Input, OnInit } from '@angular/core';
import { WikiArticle } from '@models';
import { NgxMdService } from 'ngx-md';

import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';

@Component({
    selector: 'wic-wiki-preview',
    templateUrl: './wic-wiki-preview.component.html',
    styleUrls: ['./wic-wiki-preview.component.sass'],
})
export class WicWikiPreviewComponent implements OnInit {
    @Input() article: WikiArticle;

    constructor(private markdown: NgxMdService) { }

    ngOnInit() {
        const defaultPrefixes = ['mailto:', 'http://', 'https://'];
        const linkRenderer = this.markdown.renderer.link.bind(this.markdown.renderer);
        this.markdown.renderer.link = (href: string, title: string, text: string) => {
            if (defaultPrefixes.some(prefix => href.startsWith(prefix))) {
                return linkRenderer(href, title, text);
            }

            return `<a href='/admin/wiki/articles/${href}' title='${title}'>${text}</a>`;
        };
    }
}
