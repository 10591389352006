import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {ObjectId} from '@models/types';

@Entity({
    modelName: 'APLTransform'
})
export class APLTransform {
    // tslint:disable-next-line:variable-name
    @Key _id: ObjectId;
    authorityId: number;
    isEnabled: boolean;
    name: string;
    rule: string;
    transform: number;
}
