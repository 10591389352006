import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Authority, AuthorityAccount, AuthorityFlag, AuthorityType} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AvailableAppsFacade } from '@state/available-apps/available-apps.facade';

@Component({
    selector: 'wic-authority-detail-edit',
    templateUrl: './wic-authority-detail-edit.component.html',
    styleUrls: ['./wic-authority-detail-edit.component.sass'],
})
export class WicAuthorityDetailEditComponent implements OnInit {
    @Input() authority: Authority;
    @Input() account: AuthorityAccount;
    @Input() flags: AuthorityFlag[];
    @Input() types: AuthorityType[];
    form: FormGroup;
    submitted = false;

    get f() {
        return this.form.controls;
    }

    get flagsArray(): FormArray {
        return this.form.get('FlagList') as FormArray;
    }

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, public availableApps: AvailableAppsFacade) {
    }

    ngOnInit() {
        const formFlags = this.flags
            .filter(flag => flag.IsEnabled && flag.IsEditable)
            .map(flag => ({
                Name: flag.Name,
                Mask: flag.Mask,
                Value: (this.authority.flags & flag.Mask) !== 0, // tslint:disable-line no-bitwise
            }));
        this.form = this.buildForm();
        this.form.patchValue(this.authority as any);
        if (this.account) {
            this.form.patchValue({
                ...this.account,
                publicKey: this.account.publicKey ? atob(this.account.publicKey) : null,
            });
        }
        this.form.setControl('FlagList', this.formBuilder.array(
            formFlags.map(formFlag => this.formBuilder.group(formFlag as any)))
        );

        this.form.controls.sftpEnabled.valueChanges.subscribe(value => {
            const control =  this.form.controls.publicKey;
            if (value) {
               control.setValidators(Validators.required);
            } else {
                control.clearValidators();
            }
            control.updateValueAndValidity();
        });
    }

    private buildForm() {
        return this.formBuilder.group({
            id: [null, Validators.required],
            name: [null, Validators.required],
            shortName: [null],
            type: [null, Validators.required],
            isActive: [false, Validators.required],
            tigVersion: [null, Validators.required],
            emailAddresses: [null],
            flags: [0, Validators.required],
            bannerPlaylistID: [null],
            FlagList: this.formBuilder.array([]),
            sftpEnabled: [null],
            publicKey: [null],
            appId: [1, Validators.required]
        });
    }

    save(form: FormGroup, authority: Authority, account: AuthorityAccount) {
        this.submitted = true;
        if (!form.valid) {
            return;
        }

        const setFlags = this.flagsArray.controls
            .map(control => control.value)
            .filter(value => value.Value);
        const updatedFlags = setFlags
            .reduce((flags, flag) => (flags | flag.Mask), 0); //tslint:disable-line

        const updatedAuthority = {
            id: authority.id || undefined,
            ...form.value,
            flags: updatedFlags,
            sftpEnabled: undefined,
            publicKey: undefined,
            FlagList: undefined
        };
        const updatedAccount = {
            ...(account || {}),
            authorityId: updatedAuthority.id,
            sftpEnabled: form.value.sftpEnabled,
            publicKey: btoa(form.value.publicKey),
        };
        this.activeModal.close([updatedAuthority, updatedAccount]);
    }
}
