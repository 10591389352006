import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Authority, CategoryLookup, SubCategoryLookup} from '@models';

interface What {
    authority?: Authority;
    category?: CategoryLookup;
    subCategory?: SubCategoryLookup;
}

export const isAuthority = ({authority}: What): boolean =>
    !!authority;

export const isOnlyCategory = ({category, subCategory}: What): boolean =>
    !!category && !subCategory;

export const getGroups = (group: FormGroup): FormGroup[] =>
    Object.keys(group.controls)
        .map(key => group.controls[key] as FormGroup);

export const checkAuthority = ({authority}: What, form: FormGroup): boolean =>
    getGroups(form)
        .reduce((cfound, cgroup) =>
            cfound || getGroups(cgroup)
                .reduce((sfound, sgroup) =>
                    sfound || sgroup.controls[`a${authority.id}`].value === true, false
                )
            , false
        );


export const checkAuthorities = (controls: { [key: string]: AbstractControl; }): boolean =>
    Object.keys(controls).reduce((found, key) =>
        found || controls[key].value === true, false
    );

export const checkCategory = ({category, subCategory}: What, form: FormGroup): boolean =>
    checkAuthorities(((form
        .controls[`c${category.categoryId}`] as FormGroup)
        .controls[`s${subCategory.subCategoryId}`] as FormGroup)
        .controls
    );

export const checkAllSubcategories = ({category}: What, form: FormGroup): boolean =>
    getGroups((form
        .controls[`c${category.categoryId}`] as FormGroup)
    ).reduce((sfound, sgroup) =>
        sfound || checkAuthorities(sgroup.controls), false
    );

@Pipe({name: 'hasMessages'})
export class HasMessagesPipe implements PipeTransform {
    transform(checksum: number, what: What, form: FormGroup): boolean {
        return isAuthority(what)
            ? checkAuthority(what, form)
            : isOnlyCategory(what)
                ? checkAllSubcategories(what, form)
                : checkCategory(what, form);
    }
}
