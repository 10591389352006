import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthorityMapping} from '@state/authority-mappings/model';

@Component({
    selector: 'wic-authority-mappings-table',
    templateUrl: './wic-authority-mappings-table.component.html',
    styleUrls: ['./wic-authority-mappings-table.component.sass'],
})
export class WicAuthorityMappingsTableComponent {
    @Input() authorityMappings: AuthorityMapping[];
    @Output() selected: EventEmitter<AuthorityMapping> = new EventEmitter<AuthorityMapping>();
    @Output() deleted: EventEmitter<AuthorityMapping> = new EventEmitter<AuthorityMapping>();

    activeMapping: AuthorityMapping;

    onEditComplete() {
        this.activeMapping = null;
    }
}
