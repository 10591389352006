<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-6">
            <wic-select-authority [authorities]="authorities" [formControl]="$any(form.get('authorities'))"></wic-select-authority>
        </div>
        <div class="col-6">
            <div class="form-group">
                <div class="input-group">
                    <mbsc-select formControlName="categories" placeholder="Select Category" class="w-100 form-control"
                                 groupLabel="Category" label="SubCategory" headerText="Category Selection"
                                 [data]="categories"
                                 [options]="desktopWheelSettings">
                    </mbsc-select>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-9">
            <div class="form-group">
                <div class="input-group">
                    <input id="search" class="form-control" placeholder="Filter Results" formControlName="filter">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="form.patchValue({filter: ''})" type="button">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <div class="input-group">
                    <button class="btn btn-outline-secondary search-button" type="submit" >
                        <i class="fa fa-search"></i> Search
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
