import { createSelector } from '@ngrx/store';
import { allLanguages } from './languages.state';
import { allTranslations } from '../translations/translations.state';
import { Language } from './language.entity';
import { ILookup } from '@models/types';

export const createLookupsFromLanguages = (languages: Language[]) =>
  languages.map((lang): ILookup => ({ id: lang.code, name: lang.label }));

export const languagesAsLookup = createSelector(allLanguages, languages => createLookupsFromLanguages(languages));

export const languagesAsLookupWithoutEnglish = createSelector(languagesAsLookup, languages =>
  languages.filter(({ id }) => id !== 'en')
);

export const languageLookupsWithoutTranslations = createSelector(languagesAsLookup, allTranslations, (languages, translations) =>
  languages.filter(
    lang => !translations.some(trans => trans.lang === lang.id)
  )
);
