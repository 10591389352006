<div class="text-right mb-2">
  <button class="btn btn-outline-secondary" (click)="dateRangeChanged.emit(dateRange)" type="button">
    Run Report
  </button>
</div>

<div class="card">
    <div class="card-header">
        <form class="float-left form-inline">
            <div class="form-group mr-3">
                <div class="input-group">
                    <input class="form-control" placeholder="Start Date" [(ngModel)]="dateRange.startDate"
                           name="startDate" ngbDatepicker #startDate="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="startDate.toggle()" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group mr-3">
                <div class="input-group">
                    <input class="form-control" placeholder="End Date" [(ngModel)]="dateRange.endDate"
                           name="endDate" ngbDatepicker #endDate="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="endDate.toggle()" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group mr-3">
                <button class="btn btn-outline-secondary" (click)="dateRangeChanged.emit(dateRange)" type="button">
                    <i class="fa fa-refresh" [ngClass]="{'fa-spin': isLoading}"></i>
                </button>
            </div>
        </form>
        <h5 class="float-right mt-2 text-muted">Usage Statistics</h5>
    </div>
    <div>
        <span class="text-muted float-left ml-2 my-2" *ngIf="isLoading">
            <i class="fa fa-spinner fa-spin"></i>
            Generating report...
        </span>
       <span class="float-right badge badge-pill badge-info mr-2 my-2" *ngIf="isLoading"> {{ status || 'Sent' }}</span>

        <small class="text-muted float-left ml-2 my-2" *ngIf="!isLoading">
          Results from {{criteria?.beginDate || (dateRange.startDate | datePickerFormat:'MM/dd/yyyy')}} to {{criteria?.endDate || (dateRange.endDate | datePickerFormat:'MM/dd/yyyy')}}
        </small>
        <small class="text-danger float-right mr-2 my-2" *ngIf="failed || status === 'Failed'">Report Failed.</small>
        <small class="text-muted float-right mr-2 my-2" *ngIf="!isLoading && loadDuration">
          Generated in {{loadDuration}} seconds at {{loadedAt | date:'short'}}
        </small>
    </div>
    <div class="">
        <table class="table table-sm table-striped fixed-header mb-0" *ngIf="report && report.length">
            <thead>
            <tr>
                <th class="" (click)="reportSorter.sortString('authority', 1)" colspan="2">
                    Authority
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-alpha-asc': reportSorter.direction === 1, 'fa-sort-alpha-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'authority'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('cardRegistrations')">
                    Card Reg.
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'cardRegistrations'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('uniqueRxUsers')">
                    Unique Rx Users
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'uniqueRxUsers'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('dailyRxUsers')">
                    Daily Rx Users
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'dailyRxUsers'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('devRegistrations')">
                    Dev Reg.
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'devRegistrations'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('devActivity')">
                    Dev Activity
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'devActivity'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('devActivityTotal')">
                    Dev Activity Total
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'devActivityTotal'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('uniqueItemLookups')">
                    Unique Items
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'uniqueItemLookups'"></i>
                </th>
                <th class="" (click)="reportSorter.sortNumber('totalItemCount')">
                    Total Items
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-numeric-asc': reportSorter.direction === 1, 'fa-sort-numeric-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'totalItemCount'"></i>
                </th>
                <th class="text-nowrap" (click)="reportSorter.sortNumber('rxItemCount')">
                    Rx | NO Rx
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-amount-asc': reportSorter.direction === 1, 'fa-sort-amount-desc': reportSorter.direction === -1}"
                       *ngIf="reportSorter.sortKey === 'rxItemCount'"></i>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let record of report">
                <td class="align-middle">
                    <img [src]="apiUrl + '/authorities/' + record.authorityId + '/logo'"
                         style="max-width: 50px; max-height: 50px">
                </td>
                <td class="align-middle">{{record.authority}}</td>
                <td class="align-middle">{{record.cardRegistrations}}</td>
                <td class="align-middle">{{record.uniqueRxUsers}}</td>
                <td class="align-middle">{{record.dailyRxUsers}}</td>
                <td class="align-middle">{{record.devRegistrations}}</td>
                <td class="align-middle">{{record.devActivity}}</td>
                <td class="align-middle">{{record.devActivityTotal}}</td>
                <td class="align-middle">{{record.uniqueItemLookups}}</td>
                <td class="align-middle">{{record.totalItemCount}}</td>
                <td class="align-middle">
                    <div ngbTooltip="{{record.rxItemCount}} | {{record.noRxItemCount}}" placement="left"
                         style="height: 50px"
                         *ngIf="record.rxItemCount > 0 || record.noRxItemCount > 0">
                        <wic-spark-pie [data]="[record.rxItemCount, record.noRxItemCount]"
                                       [labels]="['Rx', 'NO Rx']" [colors]="['#7cda87','#ff8680']">
                        </wic-spark-pie>
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <th class="align-middle"></th>
                <th class="align-middle">{{totals.authority}}</th>
                <th class="align-middle">{{totals.cardRegistrations}}</th>
                <th class="align-middle">{{totals.uniqueRxUsers}}</th>
                <th class="align-middle">{{totals.dailyRxUsers}}</th>
                <th class="align-middle">{{totals.devRegistrations}}</th>
                <th class="align-middle">{{totals.devActivity}}</th>
                <th class="align-middle">{{totals.devActivityTotal}}</th>
                <th class="align-middle">{{totals.uniqueItemLookups}}</th>
                <th class="align-middle">{{totals.totalItemCount}}</th>
                <th class="align-middle">
                    <div ngbTooltip="{{totals.rxItemCount}} | {{totals.noRxItemCount}}" placement="left"
                         style="height: 50px">
                        <wic-spark-pie [data]="[totals.rxItemCount, totals.noRxItemCount]"
                                       [labels]="['Rx', 'NO Rx']" [colors]="['#7cda87','#ff8680']">
                        </wic-spark-pie>
                    </div>
                </th>
            </tr>
            </tfoot>
        </table>
    </div>
    <div class="card-footer bg-transparent">
        <a class="btn btn-outline-primary float-left" role="button"
           [download]="getFilename(dateRange)" [href]="buildDownloadUri(report)">
            <i class="fa fa-download"></i> Download as CSV
        </a>
    </div>
</div>
