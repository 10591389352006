import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { Language } from './language.entity';
import { LanguageFacadeBase } from './languages.state';
import { languagesAsLookup, languagesAsLookupWithoutEnglish } from './language.selectors';

@Injectable()
export class LanguagesFacade extends LanguageFacadeBase {
  asLookup$ = this.store.select(languagesAsLookup);
  asLookupWithoutEnglish$ = this.store.select(languagesAsLookupWithoutEnglish);

  constructor(private store: Store<AppState>) {
    super(Language, store);
  }
}
