<div class="card mt-5 mb-5">
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="messages?.length">
            <thead>
            <tr>
                <th class="text-nowrap">Message ID</th>
                <th class="text-nowrap">Name</th>
                <th class="text-center">Is Active</th>
                <th class="text-center">On Benefits</th>
                <th class="text-center">On Detail</th>
                <th class="text-center"><i class="fa fa-wrench"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let message of messages" [class.bg-danger]="message._id === forDeletion?._id">
                <td class="" [class.text-white]="message._id === forDeletion?._id">
                    <a [class.text-white]="message._id === forDeletion?._id"
                       [routerLink]="['view', message._id]">{{message._id}}</a>
                </td>
                <td>
                    {{message.name}}
                </td>
                <td class="text-center">
                    <span class="badge badge-pill text-white" [class.badge-success]="message.isActive" [class.badge-danger]="!message.isActive">
                        {{message.isActive ? 'Yes' : 'No'}}
                    </span>
                </td>
                <td class="text-center">
                    <span class="badge badge-pill text-white" [class.badge-success]="message.onBenefits" [class.badge-danger]="!message.onBenefits">
                        {{message.onBenefits ? 'Yes' : 'No'}}
                    </span>
                </td>
                <td class="text-center">
                    <span class="badge badge-pill text-white" [class.badge-success]="message.onDetail" [class.badge-danger]="!message.onDetail">
                        {{message.onDetail ? 'Yes' : 'No'}}
                    </span>
                </td>
                <td class="text-right" [class.text-white]="message._id === forDeletion?._id">
                    <i class="fa fa-trash" (click)="deleted.emit(message);"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!messages?.length" class="card-body">
        <p><em>There are no apl messages.</em></p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6">
                <!--<a class="btn btn-outline-secondary float-left" role="button"-->
                <!--[download]="getFilename()" [href]="buildDownloadUri(appointments)">-->
                <!--<i class="fa fa-download"></i> Download as CSV-->
                <!--</a>-->
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
                <button class="btn btn-outline-primary" (click)="added.emit()">
                    <i class="fa fa-plus"></i> Add Message
                </button>
            </div>
        </div>
    </div>
</div>
