import {Action} from '@ngrx/store';

import {AuthorityAccount} from '@models';
import {type} from '../shared/utils';

export const CREATE_AUTHORITY_ACCOUNT = type('[Authority Accounts] Create authority');
export const CREATE_AUTHORITY_ACCOUNT_SUCCESS = type('[Authority Accounts] Create authority succeeded');
export const CREATE_AUTHORITY_ACCOUNT_FAILURE = type('[Authority Accounts] Create authority failed');
export const DELETE_AUTHORITY_ACCOUNT = type('[Authority Accounts] Delete authority');
export const DELETE_AUTHORITY_ACCOUNT_SUCCESS = type('[Authority Accounts] Delete authority succeeded');
export const DELETE_AUTHORITY_ACCOUNT_FAILURE = type('[Authority Accounts] Delete authority failed');
export const DISMISS_AUTHORITY_ACCOUNTS_ERROR = type('[Authority Accounts] Dismiss error');
export const FETCH_AUTHORITY_ACCOUNTS = type('[Authority Accounts] Fetch authorities');
export const FETCH_AUTHORITY_ACCOUNTS_SUCCESS = type('[Authority Accounts] Fetch authorities succeeded');
export const FETCH_AUTHORITY_ACCOUNTS_FAILURE = type('[Authority Accounts] Fetch authorities failed');
export const FETCH_AUTHORITY_ACCOUNT = type('[Authority Accounts] Fetch authority');
export const FETCH_AUTHORITY_ACCOUNT_SUCCESS = type('[Authority Accounts] Fetch authority succeeded');
export const FETCH_AUTHORITY_ACCOUNT_FAILURE = type('[Authority Accounts] Fetch authority failed');
export const UPDATE_AUTHORITY_ACCOUNT = type('[Authority Accounts] Update authority');
export const UPDATE_AUTHORITY_ACCOUNT_SUCCESS = type('[Authority Accounts] Update authority succeeded');
export const UPDATE_AUTHORITY_ACCOUNT_FAILURE = type('[Authority Accounts] Update authority failed');

export const SELECT_AUTHORITY_ACCOUNT = type('[Authority Accounts] Select authority');
export const SELECT_AUTHORITY_ACCOUNT_BY_ID = type('[Authority Accounts] Select authority by id');

export class CreateAuthorityAccountAction implements Action {
    readonly type = CREATE_AUTHORITY_ACCOUNT;

    constructor(public payload: AuthorityAccount) {
    }
}

export class CreateAuthorityAccountSuccessAction implements Action {
    readonly type = CREATE_AUTHORITY_ACCOUNT_SUCCESS;

    constructor(public payload: AuthorityAccount) {
    }
}

export class CreateAuthorityAccountFailureAction implements Action {
    readonly type = CREATE_AUTHORITY_ACCOUNT_FAILURE;

    constructor(public payload: any) {
    }
}

export class DeleteAuthorityAccountAction implements Action {
    readonly type = DELETE_AUTHORITY_ACCOUNT;

    constructor(public payload: AuthorityAccount) {
    }
}

export class DeleteAuthorityAccountSuccessAction implements Action {
    readonly type = DELETE_AUTHORITY_ACCOUNT_SUCCESS;

    constructor(public payload: string) {
    }
}

export class DeleteAuthorityAccountFailureAction implements Action {
    readonly type = DELETE_AUTHORITY_ACCOUNT_FAILURE;

    constructor(public payload: any) {
    }
}

export class DismissAuthorityAccountsErrorAction implements Action {
    readonly type = DISMISS_AUTHORITY_ACCOUNTS_ERROR;
}

export class FetchAuthorityAccountsAction implements Action {
    readonly type = FETCH_AUTHORITY_ACCOUNTS;
}

export class FetchAuthorityAccountsSuccessAction implements Action {
    readonly type = FETCH_AUTHORITY_ACCOUNTS_SUCCESS;

    constructor(public payload: AuthorityAccount[]) {
    }
}

export class FetchAuthorityAccountsFailureAction implements Action {
    readonly type = FETCH_AUTHORITY_ACCOUNTS_FAILURE;

    constructor(public payload: any) {'';
    }
}

export class FetchAuthorityAccountAction implements Action {
    readonly type = FETCH_AUTHORITY_ACCOUNT;

    constructor(public authorityId: number) {
    }
}

export class FetchAuthorityAccountSuccessAction implements Action {
    readonly type = FETCH_AUTHORITY_ACCOUNT_SUCCESS;

    constructor(public payload: AuthorityAccount) {
    }
}

export class FetchAuthorityAccountFailureAction implements Action {
    readonly type = FETCH_AUTHORITY_ACCOUNT_FAILURE;

    constructor(public payload: any) {
    }
}

export class UpdateAuthorityAccountAction implements Action {
    readonly type = UPDATE_AUTHORITY_ACCOUNT;

    constructor(public payload: AuthorityAccount) {
    }
}

export class UpdateAuthorityAccountSuccessAction implements Action {
    readonly type = UPDATE_AUTHORITY_ACCOUNT_SUCCESS;

    constructor(public payload: AuthorityAccount) {
    }
}

export class UpdateAuthorityAccountFailureAction implements Action {
    readonly type = UPDATE_AUTHORITY_ACCOUNT_FAILURE;

    constructor(public payload: any) {
    }
}

export class SelectAuthorityAccountAction implements Action {
    readonly type = SELECT_AUTHORITY_ACCOUNT;

    constructor(public payload: AuthorityAccount) {
    }
}

export class SelectAuthorityAccountByIdAction implements Action {
    readonly type = SELECT_AUTHORITY_ACCOUNT_BY_ID;

    constructor(public payload: string) {
    }
}

export type AuthorityAccountActions =
    | CreateAuthorityAccountAction
    | CreateAuthorityAccountFailureAction
    | CreateAuthorityAccountSuccessAction
    | DeleteAuthorityAccountAction
    | DeleteAuthorityAccountFailureAction
    | DeleteAuthorityAccountSuccessAction
    | DismissAuthorityAccountsErrorAction
    | FetchAuthorityAccountsAction
    | FetchAuthorityAccountsFailureAction
    | FetchAuthorityAccountsSuccessAction
    | FetchAuthorityAccountAction
    | FetchAuthorityAccountFailureAction
    | FetchAuthorityAccountSuccessAction
    | UpdateAuthorityAccountAction
    | UpdateAuthorityAccountFailureAction
    | UpdateAuthorityAccountSuccessAction
    | SelectAuthorityAccountAction
    | SelectAuthorityAccountByIdAction;
