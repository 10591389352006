import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DeviceLogSession} from '@models/DeviceLogSession';

@Component({
    selector: 'wic-device-logs-table',
    templateUrl: './device-logs-table.component.html',
    styleUrls: ['./device-logs-table.component.sass']
})
export class DeviceLogsTableComponent implements OnInit {
    @Input() sessions: DeviceLogSession[];
    @Input() showFilter = false;
    @Input() showToolbar = true;
    @Input() lite = false;
    @Output() refreshed = new EventEmitter();
    @Output() selected = new EventEmitter<DeviceLogSession>();
    @Output() deleted = new EventEmitter<DeviceLogSession>();

    form: FormGroup;

    constructor(private builder: FormBuilder) {
    }

    ngOnInit() {
        this.buildForm(this.builder);
    }

    buildForm(builder: FormBuilder) {
        return builder.group({
            criteria: ['']
        });
    }

}
