import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService} from '@briebug/ngrx-auto-entity';

import {User} from '@models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class UsersService implements IAutoEntityService<User> {
    constructor(private http: HttpClient) {
    }

    loadAll(): Observable<User[]> {
        return this.http.get<User[]>(`${environment.apiRoot}/admin/users`);
    }

    load(_, userId: string): Observable<User> {
        return this.http.get<User>(`${environment.apiRoot}/admin/users/${userId}`);
    }

    create(_, user: User): Observable<User> {
        return this.http.post<User>(`${environment.apiRoot}/admin/users`, user);
    }

    update(_, user: User): Observable<User> {
        return this.http.patch<User>(`${environment.apiRoot}/admin/users/${user._id}`, user);
    }

    replace(_, user: User): Observable<User> {
        return this.http.put<User>(`${environment.apiRoot}/admin/users/${user._id}`, user);
    }

    delete(_, user: User): Observable<User> {
        return this.http.delete(`${environment.apiRoot}/admin/users/${user._id}`).pipe(
            map(() => user)
        );
    }
}
