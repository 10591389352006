import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BinCode} from '@models';

@Component({
    selector: 'wic-authority-bincodes',
    templateUrl: './wic-authority-bincodes.component.html',
    styleUrls: ['./wic-authority-bincodes.component.sass'],
})
export class WicAuthorityBincodesComponent implements OnInit {
    @Input() binCodes: BinCode[];
    @Output() refreshed = new EventEmitter();
    @Output() added = new EventEmitter();
    @Output() edited = new EventEmitter<BinCode>();
    @Output() deleted = new EventEmitter<BinCode>();

    constructor() {
    }

    ngOnInit() {
    }

}
