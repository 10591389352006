/* tslint:disable */
import {Injectable} from '@angular/core';
import {Delete, LoadAll, SelectByKey} from '@briebug/ngrx-auto-entity';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {Observable, Subject} from 'rxjs';

import {APLJob} from '@models/APLJob';
import {WicConfirmModalComponent} from '../wic-confirm-modal/wic-confirm-modal.component';
import {allAPLJobs, aplJobsLoading, currentAPLJob} from '@state/apl-job.state';
/* tslint:enable */

@Injectable({
    providedIn: 'root'
})
export class APLJobsFacade {
    forDeletion: Subject<APLJob>;

    constructor(private store: Store<IAppState>, private modal: NgbModal) {
        this.forDeletion = new Subject<APLJob>();
    }

    // region Selections
    selectAll(): Observable<APLJob[]> {
        return this.store.pipe(select(allAPLJobs));
    }

    selectCurrent(): Observable<APLJob> {
        return this.store.pipe(select(currentAPLJob));
    }

    selectIsLoading(): Observable<boolean> {
        return this.store.pipe(select(aplJobsLoading));
    }
    // endregion

    // region Dispatches
    setCurrent(jobId: string) {
        this.store.dispatch(new SelectByKey(APLJob, jobId));
    }

    loadAll() {
        this.store.dispatch(new LoadAll(APLJob));
    }

    loadByConfig(configId: string) {
        this.store.dispatch(new LoadAll(APLJob, {configId}));
    }

    delete(job: APLJob) {
        if (!job) {
            return;
        }

        this.store.dispatch(new Delete(APLJob, job));
    }
    // endregion

    // region UI Helpers
    showDelete(job: APLJob) {
        if (!job) {
            return;
        }

        this.forDeletion.next(job);

        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = 'Confirm APL Import Job Deletion';
        modalReference.componentInstance.message = 'Are you sure you want to delete this APL import job log?';
        modalReference.result
            .then(() => {
                this.delete(job);
                this.forDeletion.next(null);
            }, () => {
                this.forDeletion.next(null);
            });
    }
    // endregion
}
