import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Language } from './language.entity';

@Injectable({ providedIn: 'root' })
export class LanguagesService implements IAutoEntityService<Language> {
  constructor(private http: HttpClient) {}

  loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<Language[]> {
    return this.http.get<Language[]>(environment.apiRoot + '/admin/translations/languages');
  }

  create(entityInfo: IEntityInfo, entity: any, criteria?: any, originalEntity?: Language): Observable<Language> {
    return this.http.post<Language>(environment.apiRoot + '/admin/translations/languages', entity);
  }

  update(entityInfo: IEntityInfo, entity: Language, criteria?: any, originalEntity?: Language): Observable<Language> {
    return this.http.put<Language>(environment.apiRoot + `/admin/translations/languages/${entity.code}`, entity);
  }

  delete(entityInfo: IEntityInfo, entity: any, criteria?: any, originalEntity?: Language): Observable<Language> {
    return this.http.delete<Language>(environment.apiRoot + `/admin/translations/languages/${entity.code}`);
  }
}
