import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {APLMessage, APLMessageText, Category, SubCategory} from '@models';

@Component({
    selector: 'wic-apl-message-detail',
    templateUrl: './wic-apl-message-detail.component.html',
    styleUrls: ['./wic-apl-message-detail.component.sass']
})
export class WicAplMessageDetailComponent implements OnInit {
    @Input() message: APLMessage;
    @Input() texts: APLMessageText[];

    @Output() edited = new EventEmitter<APLMessage>();

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
    }

    createImageUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
