<div class="card mt-5 mb-5">
  <div class="card-header">
    <h5 class="float-left mb-0">Banners</h5>
    <i class="fa fa-fw fa-plus float-right m-1" (click)="added.emit({ playlist: playlist, partners: partners })"></i>
    <i class="fa fa-fw fa-refresh float-right m-1" (click)="refreshed.emit(playlist)"></i>
  </div>
  <div class="">
    <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="banners?.length">
      <thead>
        <tr>
          <th class="text-nowrap"><i class="fa fa-key"></i></th>
          <th class="">Partner</th>
          <th class="">Name</th>
          <th class="">Active</th>
          <th class="">Priority</th>
          <th class="">Description</th>
          <th class="">Link URL</th>
          <th class="">Image</th>
          <th class="text-center"><i class="fa fa-wrench"></i></th>
        </tr>
      </thead>
      <tbody cdkDropList class="banner-drag-list" (cdkDropListDropped)="drop($event)">
        <tr cdkDrag class="banner-drag-box" *ngFor="let banner of banners">
          <td class="">
            <span class="badge badge-info">
              {{ banner.BannerID }}
            </span>
          </td>
          <td class="">{{ banner.Partner?.PartnerName }}</td>
          <td class="">{{ banner.BannerName }}</td>
          <td class="text-center">
            <span
              class="badge badge-pill badge-default text-white"
              [class.badge-danger]="!banner.IsActive"
              [class.badge-success]="banner.IsActive"
              >{{ banner.IsActive ? 'Yes' : 'No' }}
            </span>
          </td>
          <td class="text-center">
            <span
              class="badge badge-pill badge-default text-white"
              [class.badge-danger]="!banner.IsPriority"
              [class.badge-success]="banner.IsPriority"
              >{{ banner.IsPriority ? 'Yes' : 'No' }}
            </span>
          </td>
          <td class="">{{ banner.BannerDescription }}</td>
          <td class="">
            <input class="invisible-input" [value]="banner.LinkURL" />
          </td>
          <td class="text-center">
            <img [src]="createImageUrl(banner.ImageType, banner.ImageData)" style="max-width: 200px;" />
          </td>
          <td class="text-center">
            <i
              class="fa fa-edit"
              (click)="
                edited.emit({
                  playlist: playlist,
                  banner: banner,
                  partners: partners
                })
              "
            ></i>
            <i class="fa fa-trash pl-1" (click)="deleted.emit(banner)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="!banners?.length" class="card-body">
    <p><em>There are no banner in this playlist.</em></p>
  </div>
</div>
