import {Injectable} from '@angular/core';
import {User} from '@models';
import {Store} from '@ngrx/store';
import {AppState} from '@state/app.state';
import {addNewUser, confirmDeleteUser, editExistingUser, UsersFacadeBase} from '@state/users/user.state';

@Injectable({providedIn: 'root'})
export class UsersFacade extends UsersFacadeBase {
    constructor(private store: Store<AppState>) {
        super(User, store);
    }

    addNewUser(): void {
        this.store.dispatch(addNewUser());
    }

    editExistingUser(user: User): void {
        this.store.dispatch(editExistingUser({user}));
    }

    confirmDelete(user: User): void {
        this.store.dispatch(confirmDeleteUser({user}));
    }
}
