<div class="card mt-5 mb-5">
    <div class="card-header">
        <wic-apl-search-criteria (searchCriteria)="aplItems.searchAPLItems($event)"
                                 (filter)="aplItems.setFilterCriteria($event)"
                                 [authorities]="authorities.allAsGroupedLookup$ | async"
                                 [categories]="categoryConfigs.allAsGroupedLookup$ | async"></wic-apl-search-criteria>
    </div>
    <div class="" *ngIf="aplItems.filteredAPLItemsLength$ | async">
        <table class="table table-sm table-striped table-hover fixed-header mb-0">
            <thead>
            <tr>
                <th class="d-none d-md-block">
                    Authority
                </th>
                <th class="">
                    AuthID-(Cat:SubCat)
                </th>
                <th class="">
                    Item Number
                </th>
                <th class="">
                    Description
                </th>
                <th class="text-center">
                    Deleted
                </th>
                <th class="text-center">
                    <i class="fa fa-wrench"></i>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of aplItems.filteredAPLItems$ | async">
                <td class="">
                    {{ item.authority }}
                </td>
                <td class="">
                    {{item.authorityId}}-({{ item.category }}:{{ item.subCategory }})
                </td>
                <td class="">{{item.itemNumber}}</td>
                <td class="">{{item.description}}</td>
                <td class="text-center">
                    <i *ngIf="item.deletedAt" class="fa fa-times" style="color: red"></i>
                </td>
                <td class="text-center">
                    <i class="fa fa-trash" *ngIf="!item.deletedAt" (click)="aplItems.delete(item)"></i>
                    <i class="fa fa-trash-o" *ngIf="item.deletedAt" (click)="aplItems.unDelete(item)"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="aplItems.aplItemsLength$ | async as itemLength">
        <div class="row">
            <div class="col-3">
                {{ aplItems.filteredAPLItemsLength$ | async }} Item(s) Shown
            </div>
            <div class="col-6">
            </div>
            <div class="col-3 mbsc-align-right">
                {{ itemLength }} Item(s) Found
            </div>
        </div>
    </div>
</div>
