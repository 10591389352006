import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  currentAvailableAppId,
  loadAllAvailableApps,
  loadAllAvailableAppsSuccess,
  selectAvailableAppByKey,
} from '@state/available-apps/available-apps.state';
import { AvailableApps } from '@state/available-apps/models/available-app.entity';
import { allRouteParams, currentRouteParams } from '@state/router.selectors';
import { Router } from '@angular/router';

@Injectable()
export class AvailableAppsEffects {
  constructor(private readonly actions$: Actions, private readonly store: Store, private readonly router: Router) {
  }

  watchForRouteChange$ = createEffect(() =>
    this.store.select(allRouteParams).pipe(
      filter(route => !!route?.app),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      filter(([{app}, currentApp]) => app !== currentApp),
      map(([{ app }]) => selectAvailableAppByKey({ key: app }))
    )
  );

  watchForCurrentAppChange$ = createEffect(() =>
    this.store.select(currentAvailableAppId).pipe(
      withLatestFrom(this.store.select(allRouteParams)),
      filter(([currentApp, params]) => params?.app && params.app !== currentApp),
      map(([currentApp]) => ({
        appId: currentApp,
        suffix:  this.router.url.split('/')[4]
      })),
      switchMap(({ appId, suffix }) => this.router.navigate(['/apps', appId, 'admin', suffix])),
    ),
    {dispatch: false}
  );


  loadAvailableApps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => loadAllAvailableApps())
    ),
  );

  selectDefaultApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllAvailableAppsSuccess),
      withLatestFrom(this.store.select(allRouteParams)),
      map(([, params]) => params?.app || AvailableApps.WICShopper),
      map((key) => selectAvailableAppByKey({ key }))
    ),
  );
}
