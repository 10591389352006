<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
        <span class="badge badge-info">
            {{button.linkId}}
        </span>
            Edit Menu Button
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <label for="text" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.text.invalid">Text</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="text" formControlName="text"
                       [class.is-invalid]="submitted && f.text.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="url" class="col-sm-2 col-form-label"
                   [class.text-danger]="submitted && f.url.invalid">Url</label>
            <div class="col-sm-10">
                <input type="text" class="form-control" id="url" formControlName="url"
                       [class.is-invalid]="submitted && f.url.invalid">
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isActive" formControlName="isActive">
                    <label class="form-check-label" for="isActive">Activate and display in app</label>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="imageDataUrl" class="col-4 col-lg-2 col-form-label">Image</label>
            <div class="col-4 col-lg-5">
                <label class="custom-file form-control">
                    <input #file class="custom-file-input"
                           id="imageDataUrl"
                           type="file"
                           accept="image/*"
                           (change)="imageChanged($event)"/>
                    <span class="custom-file-control"></span>
                </label>
            </div>
            <div class="col-4 col-lg-5">
                <img class="menu-button-image" *ngIf="f.imageDataUrl.value"
                     [src]="imageDataUrl"/>
                <img class="menu-button-image" *ngIf="!f.imageDataUrl.value && button.linkId"
                    [src]="menuButtonUrl">
            </div>
        </div>
        <div class="form-group row">
            <div class="col-4 col-lg-2 col-form-label"></div>
            <div class="col-8 col-lg-10">
                <small class="text-muted">For optimal performance, please use images with 96px height/width.</small>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
