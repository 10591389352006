<div class="card mt-5">
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                <h5 class="float-left mb-0"><span *ngIf="areShared">Shared </span>Sub-Category Overrides</h5>
            </div>
            <div class="col-1" *ngIf="!showToolbar">
                <i class="fa fa-plus" (click)="added.emit()"></i>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="overrides?.length">
            <thead>
            <tr>
                <th class="text-nowrap">Authority</th>
                <th class="text-nowrap">Category</th>
                <th class="text-nowrap">Sub Category</th>
                <th class="text-nowrap">Description</th>
                <th class="text-center">Unit of Measure</th>
                <th class="text-center">Package Size</th>
                <th class="text-center"><i class="fa fa-wrench"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let override of overrides" [class.bg-danger]="override._id === forDeletion?._id">
                <td class="" [class.text-white]="override._id === forDeletion?._id">
                    <a [class.text-white]="override._id === forDeletion?._id" href=""
                       (click)="$event.preventDefault(); edited.emit(override)">{{override.authorityId || 'All Authorities'}}</a>
                </td>
                <td class="" [class.text-white]="override._id === forDeletion?._id">
                    <span class="badge badge-info text-white">{{override.categoryId}}</span>
                </td>
                <td class="" [class.text-white]="override._id === forDeletion?._id">
                    <span class="badge badge-info text-white">{{override.subCategoryId}}</span>
                </td>
                <td class="">
                    <span *ngIf="!override.description"><i class="fa fa-recycle"></i></span>
                    <span *ngIf="override.description">{{override.description}}</span>
                </td>
                <td class="">
                    <span *ngIf="!override.uom"><i class="fa fa-recycle"></i></span>
                    <span *ngIf="override.uom">{{override.uom}}</span>
                </td>
                <td class="">
                    <span *ngIf="!override.packageSize"><i class="fa fa-recycle"></i></span>
                    <span *ngIf="override.packageSize">{{override.packageSize}}</span>
                </td>
                <td class="text-right" [class.text-white]="override._id === forDeletion?._id">
                    <i class="fa fa-edit" (click)="edited.emit(override);"></i>
                    <i class="fa fa-trash" (click)="deleted.emit(override);"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!overrides?.length" class="card-body">
        <p *ngIf="isLoading"><i class="fa fa-spinner fa-spin"></i> Loading sub-category overrides...</p>
        <p *ngIf="!isLoading"><em>There are no sub-category overrides<span *ngIf="!areShared"> for this authority</span>.</em></p>
    </div>
    <div class="card-footer" *ngIf="showToolbar">
        <div class="row">
            <div class="col-md-6">
                <!--<a class="btn btn-outline-secondary float-left" role="button"-->
                <!--[download]="getFilename()" [href]="buildDownloadUri(appointments)">-->
                <!--<i class="fa fa-download"></i> Download as CSV-->
                <!--</a>-->
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh" [class.fa-spin]="isLoading"></i> Refresh
                </button>
                <button class="btn btn-outline-primary" (click)="added.emit()">
                    <i class="fa fa-plus"></i> Add <span *ngIf="areShared">Shared</span> Override
                </button>
            </div>
        </div>
    </div>
</div>
