import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { Partner } from '@models/Partner';

@Entity({
    modelName: 'Banner',
})
export class Banner {
    @Key BannerID: number;
    PlaylistID: number;
    PartnerID: number;
    PlayOrder: number;
    BannerName: string;
    BannerDescription: string;
    LinkURL: string;
    IsActive: boolean;
    IsPriority: boolean;
    ImageType?: string;
    ImageData?: string;

    Partner?: Partner;
}
