import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { from,Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { EnhancedIssuesAddEditComponent } from "../modals/enhanced-issues-add-edit-modal/enhanced-issues-add-edit-modal.component";
import { EnhancedIssue } from "./enhanced-issue.model";

@Injectable()
export class EnhancedIssuesUiService {
  constructor(private modal: NgbModal) {}

  openAddEditEnhancedIssue(enhancedIssue: EnhancedIssue): Observable<EnhancedIssue> {
    return this.openComponentModal(EnhancedIssuesAddEditComponent, { enhancedIssue });
  }

  private openComponentModal<T>(component, props: Record<string, any>): Observable<T> {
    const modal = this.modal.open(component, { size: 'lg' });
    Object.keys(props).forEach(key => (modal.componentInstance[key] = props[key]));
    return from(modal.result.catch(() => {})).pipe(filter(res => !!res));
  }
}