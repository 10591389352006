import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {BinCode} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '@services';

@Component({
  selector: 'wic-wic-authority-bincode-edit',
  templateUrl: './wic-authority-bincode-edit.component.html',
  styleUrls: ['./wic-authority-bincode-edit.component.sass']
})
export class WicAuthorityBincodeEditComponent implements OnInit {
    @Input() binCode: BinCode;

    get f() {
        return this.form.controls;
    }

    form: FormGroup;
    submitted = false;

    constructor(
        private sanitizer: DomSanitizer,
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public util: UtilService
    ) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);
        this.populateForm(this.binCode);
    }

    private buildForm(formBuilder) {
        const form = formBuilder.group({
            name: [null, Validators.required],
            endpoint: [null, Validators.required],
            BINCode: [null, Validators.required],
            username: [undefined],
            password: [undefined],
            clientId: [undefined],
            clientSecret: [undefined],
            clientUrl: [undefined],
            systemId: [9],
            integration: ['cdp', Validators.required],
            isEnabled: [true],
            proxyId: [undefined],
            mISSystemId: [''],
            connectSystemId: [''],
            workstationId: [''],
        });

        return form;
    }

    populateForm(binCode) {
        if (binCode) {
            this.form.patchValue({
                ...binCode,
            });
        }
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        const binCode = {
            ...(this.binCode || {}),
            ...value,
        };
        this.activeModal.close(binCode);
    }

}
