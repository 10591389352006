import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {APLItem} from '@models';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AplSearchService {
    constructor(private http: HttpClient) {
    }

    searchApl({authorities, categories}): Observable<APLItem[]> {
        return this.http.post<APLItem[]>(environment.apiRoot + `/items/search`, {
            authorities,
            categories
        });
    }
}
