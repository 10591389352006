export class SuccessFailTuple {
    successful: number[];
    failed: number[];
}

export class SimpleData {
    label: string;
    color: string;
    data: number[];
}
