import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wic-image-uploader-modal',
    templateUrl: './wic-image-uploader-modal.component.html',
    styleUrls: ['./wic-image-uploader-modal.component.sass']
})
export class WicImageUploaderModalComponent implements OnInit {
    @Input() modalTitle: string;
    @Input() currentUri: string;

    changed = false;
    form: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private sanitizer: DomSanitizer,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.form = this.buildForm();
        this.populateForm(this.form);
    }

    buildForm() {
        return this.fb.group({
            image: [null]
        });
    }

    populateForm(form: FormGroup) {
        if (!form) {
            return;
        }

        if (this.currentUri) {
            form.patchValue({
                image: this.currentUri
            });
        }
    }

    addImage(files: File[]) {
        if (!files.length) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.changed = true;
            this.form.patchValue({
                image: reader.result
            });
        };
    }

    createImageUrl(uri: string) {
        if (uri.startsWith('data:')) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(uri);
        } else {
            return this.currentUri;
        }
    }

    save(form: FormGroup) {
        const url = form.value.image;
        if (url.startsWith('data:')) {
            const [, data] = form.value.image.split(':');
            const [mime, encoded] = data.split(',');
            const [mimeType, type] = mime.split(';');

            const imageMeta = {
                mimeType,
                type,
                encoded,
                url,
                changed: this.changed
            };
            this.activeModal.close(imageMeta);
        } else {
            this.activeModal.close({url, changed: this.changed});
        }
    }
}
