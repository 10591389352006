import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'BannerPlaylist'
})
export class BannerPlaylist {
    @Key PlaylistID: number;
    PlaylistName: string;
    IsActive: boolean;
    AppID: number;
}
