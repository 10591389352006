import { getSelectors } from '@ngrx/router-store';
import { IAppState } from '@state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store/src/reducer';
import { ActivatedRouteSnapshot, Params } from '@angular/router';

export const {
  selectRouteParams: currentRouteParams,
  selectUrl: currentUrl,
  selectCurrentRoute: currentRoute
} = getSelectors((state: IAppState) => state.router);

export const routerState = createFeatureSelector<RouterReducerState>('router')

const extractParamsRecursively = (route: ActivatedRouteSnapshot): Params => {
  return route.children.reduce((params, child) => ({
    ...params,
    ...extractParamsRecursively(child)
  }), route.params)
};

export const allRouteParams = createSelector(
  routerState,
  (state) => state?.state?.root ? extractParamsRecursively(state.state.root) : {}
)
