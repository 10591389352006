import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceLogEntry, DeviceLogSession} from '@models';

const DEFAULT_LEVELS = ['info', 'warn', 'error'];

@Component({
    selector: 'wic-device-log-entries-table',
    templateUrl: './device-log-entries-table.component.html',
    styleUrls: ['./device-log-entries-table.component.scss']
})
export class DeviceLogEntriesTableComponent implements OnInit {
    @Input() session: DeviceLogSession;
    @Input() entries: DeviceLogEntry[];
    @Input() isLoading = false;
    @Input() filteredLevels = DEFAULT_LEVELS;
    @Input() filteredTags = [];

    @Output() levelHidden = new EventEmitter<string>();
    @Output() levelShown = new EventEmitter<string>();
    @Output() tagAdded = new EventEmitter<string>();
    @Output() tagRemoved = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {
    }

    toggleLevel(level: string): void {
        (this.filteredLevels || []).includes(level)
            ? this.levelHidden.emit(level)
            : this.levelShown.emit(level);
    }

    toggleTag(tag: string): void {
        (this.filteredTags || []).includes(tag)
            ? this.tagRemoved.emit(tag)
            : this.tagAdded.emit(tag);
    }
}
