import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'DeviceLogSession',
    comparer: (a: DeviceLogSession, b: DeviceLogSession) => a.lastUpdatedAt.localeCompare(b.lastUpdatedAt)
})
export class DeviceLogSession {
    @Key udid: string;
    @Key sessionId: string;
    deviceInfo: DeviceInfo;
    registeredAt: string;
    lastUpdatedAt: string;
}

export interface DeviceInfo {
    cordova: string;
    model: string;
    platform: string;
    udid: string;
    uuid: string;
    version: string;
    manufacturer: string;
    isVirtual: boolean;
    serial: string;
}
