import { Action } from '@ngrx/store';
import { type } from '../shared/utils';

export const LOADING_START = type('[Loading] Start');
export const LOADING_END = type('[Loading] End');

export class LoadingStartAction implements Action {
    readonly type = LOADING_START;
}

export class LoadingEndAction implements Action {
    readonly type = LOADING_END;
}

export type LoadingActions =
    | LoadingStartAction
    | LoadingEndAction;
