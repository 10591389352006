<div class="row mt-4">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0 text-nowrap">Custom Menu Buttons</h5>
                <i class="fa fa-fw fa-plus float-right m-1" (click)="added.emit()"></i>
                <i class="fa fa-fw fa-refresh float-right m-1" (click)="refreshed.emit()"></i>
            </div>
            <div class="">
                <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="buttons?.length">
                    <thead>
                    <tr>
                        <th class="text-center"><i class="fa fa-key"></i></th>
                        <th class="text-center">Order</th>
                        <th class="">Text</th>
                        <th class="d-none d-md-table-cell">Url</th>
                        <!--<th class="text-center text-nowrap">Color</th>-->
                        <th class="text-center text-nowrap">Has Icon</th>
                        <th class="text-center">Active</th>
                        <th class="text-right"><i class="fa fa-wrench"></i></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let button of buttons">
                        <td class="text-center">
                            <a href="#" (click)="edited.emit(button); $event.preventDefault();" role="button">{{button.linkId}}</a>
                        </td>
                        <td class="text-center">{{button.order}}</td>
                        <td class="">
                            <a href="#" (click)="edited.emit(button); $event.preventDefault();" role="button">{{button.text}}</a>
                        </td>
                        <td class="d-none d-md-table-cell">
                            <input class="invisible-textarea" [value]="button.url">
                        </td>
                        <!--<td class="text-center">-->
                            <!--<span class="badge badge-default badge-info"-->
                                  <!--[ngStyle]="{-->
                                    <!--'background-color': '#' + (button.backgroundColor || 0).toString(16),-->
                                    <!--'color':  '#' + (button.textColor || 0).toString(16)-->
                                  <!--}">-->
                                <!--{{button.backgroundColor}}-->
                            <!--</span>-->
                        <!--</td>-->
                        <td class="text-center">
                            <span class="badge badge-pill badge-default text-white"
                                  [class.badge-danger]="!button.hasIcon"
                                  [class.badge-success]="button.hasIcon">
                                {{button.hasIcon ? 'Yes' : 'No'}}
                            </span>
                        </td>
                        <td class="text-center">
                            <span class="badge badge-pill badge-default text-white"
                                  [class.badge-danger]="!button.isActive"
                                  [class.badge-success]="button.isActive">
                                {{button.isActive ? 'Yes' : 'No'}}
                            </span>
                        </td>
                        <td class="text-right">
                            <i class="fa fa-arrow-up" [class.text-medium]="button.order <= 0" role="button" (click)="up(button)"></i>
                            <i class="fa fa-arrow-down" role="button" (click)="movedDown.emit(button)"></i>
                            &nbsp;
                            <i class="fa fa-trash" role="button" (click)="deleted.emit(button)"></i>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="!buttons?.length" class="col-sm-12">
                <p><em>This authority currently has no custom menu buttons.</em></p>
            </div>
        </div>
    </div>
</div>
