<form #authorityMappingForm="ngForm" novalidate
      (ngSubmit)="saved.emit(authorityMapping); resetForm()">
    <div class="header">
        <h4> {{ !authorityMapping._id ? 'Create' : 'Edit' }} </h4>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <input type="text" class="form-control"
                       [(ngModel)]="authorityMapping.name"
                       name="name"
                       placeholder="Name (required)">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <input type="number" class="form-control"
                       [(ngModel)]="authorityMapping.binCode"
                       name="binCode"
                       placeholder="Bin Code">
            </div>
        </div>
    </div>
    <div class="form-group">
        <select class="form-control"
                [(ngModel)]="authorityMapping.integration"
                id="integration"
                name="integration">
            <option value="" selected hidden>Integration</option>
            <option>cdp</option>
            <option>wem</option>
            <option>xerox</option>
            <option>conduent</option>
        </select>
    </div>
    <!-- dynamic form -->
    <div class="row" *ngIf="authorityMapping.integration">
        <div class="col-md-6">
            <div class="form-group">
                <input type="text" class="form-control"
                       [(ngModel)]="authorityMapping.endpoint"
                       name="wsdl"
                       placeholder="WSDL Endpoint">
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-check">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox"
                           [(ngModel)]="authorityMapping.useProxy"
                           name="useProxy" disabled>
                    Use Proxy
                </label>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-check">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox"
                           [(ngModel)]="authorityMapping.isEnabled"
                           name="enabled">
                    Enabled
                </label>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="authorityMapping.integration === 'cdp'">
        <div class="col-md-6">
            <div class="form-group">
                <input type="text" class="form-control"
                       [(ngModel)]="authorityMapping.username"
                       name="username"
                       placeholder="Username (required)">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <input type="text" class="form-control"
                       [(ngModel)]="authorityMapping.password"
                       name="password"
                       placeholder="Password (required)">
            </div>
        </div>
    </div>
    <!--end of form-->
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <button class="btn btn-primary" type="submit">
                    Submit
                </button>
                <button class="btn" type="button"
                        (click)="canceled.emit(authorityMapping); resetForm()">
                    Cancel
                </button>
            </div>
        </div>
    </div>


</form>
