import {Injectable} from '@angular/core';
import {buildState, EntityActionTypes, LoadAll, LoadAllSuccess, LoadSuccess, ofEntityType, UpdateSuccess} from '@briebug/ngrx-auto-entity';
import {MenuButton} from '@models';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {MenuButtonService} from '@services';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';

const {initialState, selectors} = buildState(MenuButton);

export const {
    selectAll: allMenuButtons,
    selectIsLoading: menuButtonsLoading,
    selectIsSaving: menuButtonSaving,
    selectIsDeleting: menuButtonDeleting,
    selectCurrentEntity: currentMenuButton
} = selectors;

export function menuButtonsReducer(state = initialState) {
    return state;
}

export enum MenuButtonTypes {
    Move = '[MenuButton] Move'
}

export class MoveMenuButton implements Action {
    readonly type = MenuButtonTypes.Move;

    constructor(public button: MenuButton, public newIndex: number) {
    }
}

@Injectable()
export class MenuButtonEffects {
    @Effect()
    reloadMenuButtons$: Observable<Action> = this.actions.pipe(
        ofEntityType(MenuButton, EntityActionTypes.CreateSuccess, EntityActionTypes.UpdateSuccess, EntityActionTypes.DeleteSuccess),
        map((action: UpdateSuccess<MenuButton>) => action.entity),
        map((button: MenuButton) => {
            return new LoadAll(MenuButton, {linkTypeId: button.linkTypeId});
        })
    );

    @Effect()
    moveMenuButton$: Observable<Action> = this.actions.pipe(
        ofType<MoveMenuButton>(MenuButtonTypes.Move),
        exhaustMap(action =>
            this.menuButtonService.move(action.button, action.newIndex)
                .pipe(
                    map(menuButtons => {
                        return new LoadAllSuccess(MenuButton, menuButtons);
                    }),
                    catchError(err => {
                        return of(new LoadSuccess(MenuButton, action.button));
                    })
                )
        )
    );

    constructor(private actions: Actions, private menuButtonService: MenuButtonService) {

    }
}
