import { Entity, Key } from "@briebug/ngrx-auto-entity";
import { SubcategoryTranslation } from "../subcategory-translations/subcategory-translation.model";

@Entity({
  modelName:'SubcategoryTranslationGroup',
  uriName:'subcategory-translation-groups'
})
export class SubcategoryTranslationGroup {
  @Key groupId: number;
  category: number;
  groupName: string;
  lang: string;
  translationValue: string;
  subcategoryTranslations: SubcategoryTranslation[]
}