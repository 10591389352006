import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LocalBenefitImport} from '@models/LocalBenefitImport';

@Component({
    selector: 'wic-benefit-imports-table',
    templateUrl: './wic-benefit-imports-table.component.html',
    styleUrls: ['./wic-benefit-imports-table.component.sass']
})
export class WicBenefitImportsTableComponent {
    @Input() localBenefitImports: LocalBenefitImport[];
    @Output() refreshed = new EventEmitter();
}
