import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BannerPlaylist} from '@models';

@Component({
    selector: 'wic-authority-banner-playlists-table',
    templateUrl: './wic-authority-banner-playlists-table.component.html',
    styleUrls: ['./wic-authority-banner-playlists-table.component.sass']
})
export class WicAuthorityBannerPlaylistsTableComponent implements OnInit {
    @Input() playlists: BannerPlaylist[];
    @Output() refreshed = new EventEmitter();
    @Output() added = new EventEmitter();
    @Output() edited = new EventEmitter<BannerPlaylist>();
    @Output() remove = new EventEmitter<BannerPlaylist>();

    constructor() {
    }

    ngOnInit() {
    }

}
