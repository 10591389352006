<div class="container">
    <router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>
</div>

<div *ngIf="!hideSelf">
    <div class="row">
        <div class="col-md-12">
            <wic-device-logs-table
                [sessions]="sessions.sorted$ | async"
                [showToolbar]="true"
                (refreshed)="sessions.loadAll()"
                (selected)="viewSession($event)"
                (deleted)="sessions.delete($event)">
            </wic-device-logs-table>
        </div>
    </div>
</div>
