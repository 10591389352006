import {Component, OnInit} from '@angular/core';

import {AuthoritiesFacade} from '@facades/authorities.facade';
import {combineLatest} from 'rxjs';
import {first} from 'rxjs/operators';

@Component({
    selector: 'wic-authority-list',
    templateUrl: './wic-authority-list.component.html',
    styleUrls: ['./wic-authority-list.component.sass'],
})
export class WicAuthorityListComponent implements OnInit {
    hideSelf = false;

    constructor(public authorities: AuthoritiesFacade) {
    }

    ngOnInit() {
        this.authorities.loadFlags();
        this.authorities.loadTypes();

        this.authorities.loadAll();
    }

    add() {
        combineLatest([this.authorities.flags$, this.authorities.types$])
            .pipe(first(([flags, types]) => !!flags && !!flags.length && !!types && !!types.length))
            .subscribe(([flags, types]) => this.authorities.showCreate(flags, types));
    }

    refresh() {
        this.authorities.loadAll();
    }
}
