<div class="card mt-4">
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                <h5 class="float-left mb-0" *ngIf="!showFilter">Enhanced Devices</h5>
                <form [formGroup]="form" *ngIf="showFilter">
                    <div class="row">
                        <div class="col-4">
                            <input formControlName="criteria" placeholder="Search Criteria">
                        </div>
                        <div class="col-4">
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-1" *ngIf="!showToolbar">
                <i class="fa fa-refresh" (click)="refreshed.emit()"></i>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="!!devices?.length">
            <thead>
            <tr>
                <th>Platform</th>
                <th>Manufacturer</th>
                <th *ngIf="!lite">Version</th>
                <th *ngIf="!lite">Cordova</th>
                <th *ngIf="!lite">Registered At</th>
                <th>Is Allowed</th>
                <th class="text-right"><i class="fa fa-wrench"></i></th>
            </tr>
            </thead>
            <tbody *ngFor="let device of devices">
            <tr>
                <td class="text-left">
<!--                    <a [routerLink]="['edit', device.udid]">{{device.platform}}</a>-->
                    {{device.platform}}
                </td>
                <td class="text-left">
<!--                    <a [routerLink]="['edit', device.udid]">{{device.manufacturer}}</a>-->
                    {{device.manufacturer}}
                </td>
                <td class="text-left" *ngIf="!lite">
                    <span class="text-monospace">{{device.version}}</span>
                </td>
                <td class="text-left" *ngIf="!lite">
                    <span class="text-monospace">{{device.cordova}}</span>
                </td>
                <td *ngIf="!lite">
                    <span class="badge badge-info">{{device.registeredAt | date:'yyyy-MM-dd hh:mm:ss'}}</span>
                </td>
                <td>
                    <span class="badge badge-pill badge-success" role="button" *ngIf="device.allowed" (click)="toggled.emit({device: device, allowed: false})">Yes</span>
                    <span class="badge badge-pill badge-danger" role="button" *ngIf="!device.allowed" (click)="toggled.emit({device: device, allowed: true})">No</span>
                </td>
                <td class="text-right">
<!--                    <a [routerLink]="['edit', device.udid]"><i class="fa fa-pencil"></i></a>-->
                </td>
            </tr>
            <tr>
                <td [colSpan]="lite ? '3' : '4'" class="text-monospace">
                    <span *ngIf="!lite">Device ID:</span>
                    <span class="badge badge-udid">{{device.udid}}</span>
                </td>
                <td class="text-right">
                    <i class="fa fa-trash" (click)="deleted.emit(device)"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!devices?.length" class="card-body">
        <p><em>There are no enhanced devices.</em></p>
    </div>
    <div class="card-footer" *ngIf="showToolbar">
        <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
            </div>
        </div>
    </div>
</div>
