<div class="row mt-4" *ngIf="report?.deviceInfo">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    Device Info
                </h5>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-4">Is Cordova</dt>
                        <dd class="col-md-8">
                            <span class="badge badge-pill text-white"
                                  [class.badge-info]="report.deviceInfo.cordova !== 'n/a'"
                                  [class.badge-danger]="report.deviceInfo.cordova === 'n/a'">{{report.deviceInfo.cordova}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-4">Manufacturer</dt>
                        <dd class="col-md-8">
                            {{report.deviceInfo.manufacturer}}
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-4">Model</dt>
                        <dd class="col-md-8">
                            {{report.deviceInfo.model}}
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-4">Platform</dt>
                        <dd class="col-md-8">
                            {{report.deviceInfo.platform}}
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-4">Version</dt>
                        <dd class="col-md-8">
                            <span class="badge badge-info">{{report.deviceInfo.version}}</span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-4">Is Emulator</dt>
                        <dd class="col-md-8">
                            <span class="badge badge-pill text-white"
                                  [class.badge-success]="report.deviceInfo.isVirtual"
                                  [class.badge-danger]="!report.deviceInfo.isVirtual">{{report.deviceInfo.isVirtual ? 'Yes' : 'No'}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-4">App Version</dt>
                        <dd class="col-md-8">
                            <span class="badge badge-info">{{report.deviceInfo.appVersion}}</span>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
