import {Pipe, PipeTransform} from '@angular/core';
import {DeviceLogEntry} from '@models';

@Pipe({name: 'filterByLevel'})
export class FilterByLevelsPipe implements PipeTransform {
    transform(entries: DeviceLogEntry[], levels: string[]): DeviceLogEntry[] {
        return (!!levels && !!levels.length && !!entries)
            ? entries.filter(entry =>
                levels.some(level => Intl.Collator(undefined, {sensitivity: 'base'}).compare(entry.type, level) === 0)
            )
            : entries;
    }
}
