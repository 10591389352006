<div class="row mt-4" *ngIf="authority">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    <span class="badge badge-info" ngbTooltip="Authority ID" placement="right">{{authority.id}}</span>
                    Details
                </h5>
                <i class="fa fa-fw fa-pencil float-right m-1" (click)="edited.emit(authority)"
                   ngbTooltip="Edit Details" placement="left"></i>
                <i class="fa fa-fw fa-image float-right m-1" (click)="logo.emit(authority)"
                   ngbTooltip="Change Logo" placement="left"></i>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h5 class="card-title">{{authority.name}}</h5>
                        <p class="card-text" *ngIf="authority.shortName">{{authority.shortName}}</p>
                    </div>
                    <div class="col-md-4">
                        <img [src]="logoUrl" (error)="noLogoFound = true" *ngIf="!noLogoFound && logoUrl" class="float-right">
                        <i class="fa fa-ban fa-5x notFoundLogo float-right" *ngIf="noLogoFound && logoUrl"
                           ngbTooltip="No logo configured" placement="left"></i>
                    </div>
                </div>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Type</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-default badge-info text-white">
                                <span [ngSwitch]="authority.type">
                                    <span *ngSwitchCase="0">Verify Only</span>
                                    <span *ngSwitchCase="1">Offline</span>
                                    <span *ngSwitchCase="2">Online</span>
                                    <span *ngSwitchCase="3">Voucher</span>
                                    <span *ngSwitchCase="4">Non-Subscriber</span>
                                </span>
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Active</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-default text-white"
                                  [class.badge-danger]="!authority.isActive"
                                  [class.badge-success]="authority.isActive">
                                {{authority.isActive ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">TIG Version</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-warning text-white">
                                {{authority.tigVersion}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="!!authority.emailAddresses">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Email Addresses</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-dark text-white mr-1"
                                  *ngFor="let email of authority.emailAddresses | split">
                                {{email}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="false">
                    <dl class="row mb-0">
                        <dt class="col-md-3">SFTP Access</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-default text-white"
                                  [class.badge-danger]="!account || !account.sftpEnabled"
                                  [class.badge-success]="account && account.sftpEnabled">
                                {{(account && account.sftpEnabled) ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Flags</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white mr-1"
                                  [ngClass]="isSet(authority, flag) ? 'badge-success' : 'badge-danger'"
                                  *ngFor="let flag of flags"
                                  [hidden]="!flag.IsEnabled">
                                {{flag.Name}}
                            </span>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
