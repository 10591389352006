import {Component, Input, OnInit} from '@angular/core';
import {LocalBenefitImport} from '@models/LocalBenefitImport';
import {BenefitImportJob} from '@models/BenefitImportJob';

@Component({
  selector: 'wic-import-job-status',
  templateUrl: './import-job-status.component.html',
  styleUrls: ['./import-job-status.component.scss']
})
export class ImportJobStatusComponent implements OnInit {
    @Input() benefitImportJob: BenefitImportJob;

  constructor() { }

  ngOnInit() {
  }

}
