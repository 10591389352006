import { Injectable } from "@angular/core";
import { IAppState } from "@state/app.state";
import { Store } from "@ngrx/store";
import { StandardSubcategoriesFacadeBase } from "./standard-subcategories.state";
import { StandardSubcategory } from "./standard-subcategory.model";

@Injectable({
  providedIn: 'root',
})
export class StandardSubcategoriesFacade extends StandardSubcategoriesFacadeBase {

  constructor(protected store: Store<IAppState>) {
    super(StandardSubcategory, store);
  }

}