import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StandardCategoriesFeatureModule } from "./feature/standard-categories/standard-categories-feature.module";
import { StandardSubcategoriesFeatureModule } from "./feature/standard-subcategories/standard-subcategories-feature.module";

@NgModule({
  imports: [
    CommonModule,
    StandardCategoriesFeatureModule,
    StandardSubcategoriesFeatureModule
  ]
})
export class StandardCategoriesModule {}