import {Component, OnInit} from '@angular/core';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {LocalBenefitsFacade} from '@facades/local-benefits.facade';
import {LocalBenefit} from '@models/LocalBenefit';
import {ILookup} from '@models/types';
import {Observable} from 'rxjs';

@Component({
    selector: 'wic-benefits-list',
    templateUrl: './wic-benefits-list.component.html',
    styleUrls: ['./wic-benefits-list.component.sass']
})
export class WicBenefitsListComponent implements OnInit {
    hideSelf = false;
    private page = 1;

    constructor(public localBenefits: LocalBenefitsFacade, public authorities: AuthoritiesFacade) {
    }

    ngOnInit() {
        this.authorities.loadAll();

        this.refresh({
            page: 1
        });
    }

    refresh(searchCriteria) {
        this.localBenefits.loadPage(searchCriteria.page, searchCriteria);
    }
}
