import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BannerPlaylist} from '@models';

@Component({
    selector: 'wic-banner-playlist-detail',
    templateUrl: './wic-banner-playlist-detail.component.html',
    styleUrls: ['./wic-banner-playlist-detail.component.sass']
})
export class WicBannerPlaylistDetailComponent implements OnInit {
    @Input() playlist: BannerPlaylist;
    @Output() edited = new EventEmitter<BannerPlaylist>();

    constructor() {
    }

    ngOnInit() {
    }

}
