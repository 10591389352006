import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'CardStatus',
})
export class CardStatus {
    // tslint:disable-next-line:variable-name
    @Key _id?: string;
    @Key cardNumber: string;
    jobId: string;
    status: CardImportStatus;
    delayed: boolean;
    details?: any;
    error?: any;
    timestamp: string;
}

export enum CardImportStatus {
    identified = 'IDENTIFIED',
    gathered = 'GATHERED',
    parsed = 'PARSED',
    staged = 'STAGED',
    published = 'PUBLISHED',
    error = 'ERRORED',
}
