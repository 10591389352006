import { Key, Entity } from '@briebug/ngrx-auto-entity';

export enum AvailableApps {
  WICShopper = 1,
  DocumentUpload = 2,
  SFMNP = 3,
}

@Entity('AvailableApp', { uriName: 'apps' })
export class AvailableApp {
  @Key id: number;
  name: string;
}
