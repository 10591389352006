import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {ObjectId} from '@models/types';

export type MicroBlogType = 'post' | 'deck';

@Entity({
    modelName: 'MicroBlog'
})
export class MicroBlog {
    // tslint:disable-next-line:variable-name
    @Key _id?: ObjectId;
    type: MicroBlogType;
    title: string;
    authorities: number[];
    groups: string[];
    showTitleInSlide: boolean;
    localizedContent?: MicroBlogContent[];
    image?: string;
    deck?: Slide[];
    dateCreated: string;
    activationDate?: object;
    expirationDate?: object;
    isActive: boolean;
    appId: number;
}

export class MicroBlogContent {
    lang: string;
    content: string;
}

export class SelectedAuthority {
    id: string;
    itemName: string;
}

export class Slide {
    image: string;
    bgColor?: string;
    iconColor?: string;
}
