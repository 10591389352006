import { FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Benefit, Category, DemoAccountBenefitGroup, SubCategory } from '@models';
import { NgbDate, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { format, parse } from 'date-fns';

export const formatBenefitDate = (date: Date) => format(date, 'yyyy-MM-dd');

export const subCategoryMatchesCategory = (categoryControlName: string): ValidatorFn => (input: FormControl): ValidationErrors | null => {
    if (!input.value) {
      return null;
    }

    const formGroup = input.root as FormGroup;
    if (!formGroup || !formGroup.controls) {
      return null;
    }

    const categoryControl = formGroup.controls[categoryControlName];
    if (!categoryControl) {
      return null;
    }

    // return subCategory.categoryId === category.categoryId ? null : {subCategoryMatchesCategory: 'Mismatched Category'};
  };

export class BenefitGroupForm extends FormGroup {
  beginDate: BenefitDateControl = this.get('beginDate') as BenefitDateControl;
  endDate: BenefitDateControl = this.get('endDate') as BenefitDateControl;
  benefits: BenefitsFormArray = this.get('benefits') as BenefitsFormArray;
  model: Partial<DemoAccountBenefitGroup>;

  get updatedModel(): Partial<DemoAccountBenefitGroup> {
    return {
      ...this.model,
      ...this.value,
      beginDate: this.beginDate.updatedModel,
      endDate: this.endDate.updatedModel,
      benefits: this.benefits.updatedModel
    };
  }

  constructor(model?: DemoAccountBenefitGroup) {
    super({
      beginDate: new BenefitDateControl(new Date(model?.beginDate)),
      endDate: new BenefitDateControl(new Date(model?.endDate)),
      benefits: new BenefitsFormArray(model?.benefits)
    });
    this.model = model || {};
  }

  addBenefit() {
    this.benefits.push(new BenefitForm());
  }
}

export class BenefitDateControl extends FormControl {
  get updatedModel(): string {
    return this.value && parse(`${this.value.year}-${this.value.month}-${this.value.day}`, 'yyyy-M-d', new Date()).toISOString();
  }

  constructor(date: Date) {
    super({ day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }, Validators.required);
  }
}

export class BenefitsFormArray extends FormArray {
  benefitForms: BenefitForm[] = this.controls as BenefitForm[];

  get updatedModel(): Partial<Benefit>[] {
    return this.benefitForms.map(form => form.updatedModel);
  }

  constructor(benefits: Benefit[] = []) {
    super(benefits.map(benefit => new BenefitForm(benefit)));
  }
}

export class BenefitForm extends FormGroup {
  categoryId: FormControl = this.get('categoryId') as FormControl;
  subCategoryId: FormControl = this.get('subCategoryId') as FormControl;
  quantity: FormControl = this.get('quantity') as FormControl;
  availableQuantity: FormControl = this.get('availableQuantity') as FormControl;
  model: Partial<Benefit>;

  get updatedModel(): Partial<Benefit> {
    return {
      ...this.model,
      categoryId: +this.value.categoryId,
      subCategoryId: +this.value.subCategoryId,
      quantity: +this.value.quantity,
      availableQuantity: +this.value.availableQuantity
    };
  }

  constructor(model?: Benefit) {
    super({
      categoryId: new FormControl(model?.categoryId || null, Validators.required),
      subCategoryId: new FormControl(model?.subCategoryId || 0, Validators.required),
      quantity: new FormControl(model?.quantity || 1, Validators.required),
      availableQuantity: new FormControl(model?.availableQuantity || 1, Validators.required),
    });
    this.model = model || {};
  }

  setCategory(category: Category) {
    this.categoryId.patchValue(category.categoryId);
  }

  setSubCategory(subCategory: SubCategory) {
    this.subCategoryId.patchValue(subCategory.subCategoryId);
  }
}
