import { Injectable } from '@angular/core';
import { Authority } from '@models';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AuthoritiesService, AuthorityAccountsService } from '@services';
import { availableAppSelected, currentAvailableAppId } from '@state/available-apps/available-apps.state';
import { AvailableApps } from '@state/available-apps/models/available-app.entity';
import { showReplicateAgencyCard } from '@state/demo-accounts/demo-accounts.state';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  FetchAuthoritiesFailureAction,
  FetchAuthoritiesSuccessAction,
  FETCH_AUTHORITIES,
  SetAuthorityLogoAction,
  SetAuthorityLogoFailureAction,
  SetAuthorityLogoSuccessAction,
  SET_AUTHORITY_LOGO,
  UpdateAuthorityAction,
  UpdateAuthorityFailureAction,
  UpdateAuthoritySuccessAction,
  UPDATE_AUTHORITY, createAuthority, createAuthoritySuccess, createAuthorityFailure,
} from './authorities.actions';

@Injectable()
export class AuthoritiesEffects {
  @Effect()
  fetchAuthorities$ = this.actions$.pipe(
    ofType(FETCH_AUTHORITIES, showReplicateAgencyCard, availableAppSelected),
    withLatestFrom(this.store.select(currentAvailableAppId)),
    exhaustMap(([, appId]) =>
      this.authoritiesService.list(appId ? +appId : AvailableApps.WICShopper, true).pipe(
        map(Authorities => new FetchAuthoritiesSuccessAction(Authorities)),
        catchError(err => of(new FetchAuthoritiesFailureAction(err)))
      )
    )
  );

  // @Effect()
  // fetchAuthority$ = this.actions.ofType(FETCH_AUTHORITY)
  //     .pipe(
  //         exhaustMap((action: FetchAuthorityAction) => {
  //             return this.authoritiesService.fetchAuthority(action.payload)
  //                 .pipe(
  //                     map(authority => new FetchAuthoritySuccessAction(authority)),
  //                     catchError(err =>
  //                         of(new FetchAuthorityFailureAction(err))
  //                     )
  //                 );
  //         })
  //     );
  //
  // @Effect()
  // createAuthority$ = this.actions.ofType(CREATE_AUTHORITY)
  //     .pipe(
  //         map((action: CreateAuthorityAction) => action.payload),
  //         exhaustMap((authority: Authority) => {
  //             return this.authoritiesService.createAuthority(authority)
  //                 .pipe(
  //                     map(created => new CreateAuthoritySuccessAction(created)),
  //                     catchError(err =>
  //                         of(new CreateAuthorityFailureAction(err))
  //                     )
  //                 );
  //         })
  //     );

  createAuthority$ = createEffect(() => this.actions$.pipe(
    ofType(createAuthority),
    exhaustMap(({ authority, account }) => this.authoritiesService.create(authority).pipe(
      switchMap((auth) => this.accounts.create({ ...account, authorityId: auth.id }).pipe(
        map((acc) => createAuthoritySuccess({ authority: auth, account: acc }))
      )),
      catchError((err) => of(createAuthorityFailure({ authority, account, err })))
    )),
  ))

  @Effect()
  updateAuthority$: Observable<Action> = this.actions$.pipe(
    ofType(UPDATE_AUTHORITY),
    map((action: UpdateAuthorityAction) => action.payload),
    exhaustMap((authority: Authority) =>
      this.authoritiesService.update(authority).pipe(
        map(updated => new UpdateAuthoritySuccessAction(updated)),
        catchError(err => of(new UpdateAuthorityFailureAction(err)))
      )
    )
  );

  @Effect()
  setAuthorityLogo$ = this.actions$.pipe(
    ofType(SET_AUTHORITY_LOGO),
    exhaustMap((action: SetAuthorityLogoAction) =>
      this.authoritiesService.setLogo(action.authority, action.authorityLogo).pipe(
        map(authority => new SetAuthorityLogoSuccessAction(authority)),
        catchError(err => of(new SetAuthorityLogoFailureAction(err)))
      )
    )
  );

  // @Effect()
  // deleteAuthority$ = this.actions.ofType(DELETE_AUTHORITY)
  //     .pipe(
  //         map((action: DeleteAuthorityAction) => action.payload),
  //         exhaustMap((authority: Authority) => {
  //             return this.authoritiesService.deleteAuthority(authority._id)
  //                 .pipe(
  //                     map(id => new DeleteAuthoritySuccessAction(id)),
  //                     catchError(err => of(new DeleteAuthorityFailureAction(err)))
  //                 );
  //         })
  //     );

  constructor(private actions$: Actions, private authoritiesService: AuthoritiesService, private accounts: AuthorityAccountsService, private store: Store) {}
}
