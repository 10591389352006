import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wic-confirm-modal',
  templateUrl: './wic-confirm-modal.component.html',
  styleUrls: ['./wic-confirm-modal.component.sass'],
})
export class WicConfirmModalComponent {
    @Input() modalTitle: string;
    @Input() message: string;

    constructor(public activeModal: NgbActiveModal) { }

}
