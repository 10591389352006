import {buildState} from '@briebug/ngrx-auto-entity';
import {CategoryLookup, SubCategoryLookup} from '@models';
import {ILookup} from '@models/types';
import {createSelector} from '@ngrx/store';
import {allSubCategoryLookups} from '@state/sub-category-lookup.state';

const {initialState, selectors} = buildState(CategoryLookup);

export const {
    selectAllSorted: allCategoryLookups,
    selectCurrentEntity: currentCategoryLookup
} = selectors;

export const categoryLookupsAsLookup = createSelector(
    allCategoryLookups,
    (categories: CategoryLookup[]): ILookup[] => categories
        .map(category => {
            return {
                id: category.categoryId,
                name: category.description,
            };
        })
);

export function categoryLookupReducer(state = initialState) {
    return state;
}

export const categoryLookupTree = createSelector(
    allCategoryLookups,
    allSubCategoryLookups,
    (categories: CategoryLookup[], subCategories: SubCategoryLookup[]) =>
        categories && categories.length && subCategories && subCategories.length ?
            categories.map(category => ({
                ...category,
                subCategories: subCategories.filter(subCategory => subCategory.categoryId === category.categoryId)
            }))
            : []
);
