import {from, of, OperatorFunction, pipe} from 'rxjs';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {RouterNavigatedAction, SerializedRouterStateSnapshot} from '@ngrx/router-store';
import { ActivatedRouteSnapshot } from '@angular/router';

export const presentModal = <T, U, R>(modalFn: (arg0?: T) => Promise<U>): OperatorFunction<T, [U, T]> =>
    pipe(
        switchMap((arg: T) => from(modalFn(arg)).pipe(
            map((ret: U) => [ret, arg]),
            catchError((err) => of(null)),
        )),
        filter((result: [U, T]) => !!result),
    );

const resolveParamProp = (param: string, { firstChild, routeConfig }: ActivatedRouteSnapshot) =>
    routeConfig.path.includes('/') ? routeConfig.path.substring(param.length + 2) : routeConfig.path;

const recursivelyFindRouteParam = (route: ActivatedRouteSnapshot, param: string, currentParams) =>
    route.firstChild
        ? recursivelyFindRouteParam(route.firstChild, param, route.firstChild.params[param] ? {
            ...currentParams,
            [resolveParamProp(param, route)]: route.firstChild.params[param]
        } : currentParams)
        : currentParams;

export const extractAllParamsMatching = (param: string) =>
    pipe(
        map(({ root }: SerializedRouterStateSnapshot) => recursivelyFindRouteParam(root, param, {}))
    );

export const routeIncludesPath = (url: string) =>
    pipe(
        map((r: RouterNavigatedAction) => r.payload.routerState),
        filter((routerState: SerializedRouterStateSnapshot) =>
            routerState.url.includes(url)
        )
    );

export const routeDoesNotMatchPath = (url: RegExp) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter((routerState: SerializedRouterStateSnapshot) =>
      !url.test(routerState.url)
    )
  );
