import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Benefit, Category, DemoAccount, LinkDetail, SubCategory, SubCategoryConfig} from '@models';

@Component({
    selector: 'wic-sub-category-config-line-items',
    templateUrl: './wic-sub-category-config-line-items.component.html',
    styleUrls: ['./wic-sub-category-config-line-items.component.sass']
})
export class WicSubCategoryConfigLineItemsComponent implements OnInit, OnChanges {
    @Input() subCategoryConfig: SubCategoryConfig;
    @Input() linkForDeletion: LinkDetail;
    @Input() linkForEdit: LinkDetail;
    @Output() added = new EventEmitter<SubCategoryConfig>();
    @Output() edited = new EventEmitter<{subCategoryConfig: SubCategoryConfig, linkDetail: LinkDetail}>();
    @Output() deleted = new EventEmitter<{subCategoryConfig: SubCategoryConfig, linkDetail: LinkDetail}>();

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
    }

    ngOnChanges() {

    }

    createImageUrl(uri) {
        if (!uri) {
            return;
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(uri);
    }
}
