import {Entity, Key} from '@briebug/ngrx-auto-entity';

export class Authority {
    id: number;
    type: number;
    flags: number;
    name: string;
    shortName: string;
    bannerPlaylistId?: number;
    tigVersion?: number;
    emailAddresses?: string;

    isActive: boolean;
    isProvider: boolean;
    isOfflineOrVerifyOnly?: boolean;
    allowScan?: boolean;
    showScan?: boolean;
    showVendors?: boolean;
    showOffices?: boolean;
    hasLocations?: boolean;
    benefitsHaveNoExpiration?: boolean;
    allowCaptureBenefits: boolean;
    hasApptReminders?: boolean;
    hideFutureBenefits?: boolean;
    showLastShoppingTrip?: boolean;
    showLastImport?: boolean;
    useHHIDForAppts?: boolean;
    disableCVVCalc?: boolean;
    disableCerealCalc?: boolean;
    disableGrainCalc?: boolean;
    enableRedemptionHistory?: boolean;
    enableEnhancedIssues?: boolean;
    enableAplSearchNoBenefits?: boolean;
    integration?: string;
}

export class AuthorityAccount {
    // tslint:disable-next-line:variable-name
    _id: string;
    authorityId: number;
    sftpEnabled: boolean;
    publicKey: string;
}

@Entity({
    modelName: 'AuthorityFlag'
})
export class AuthorityFlag {
    @Key FlagID: number;
    IsEditable: boolean;
    IsEnabled: boolean;
    Bit: number;
    Mask: number;
    Name: string;
    PropertyName: string;
}

export class AuthorityType {
    AuthorityType: number;
    Name: string;
    Comment?: string;
}
