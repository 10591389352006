import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {Authority, DemoAccount} from '@models';
import {UtilService} from '@services';
import { CardNumberControl } from '../demo-account.form';

@Component({
    selector: 'wic-demo-account-edit',
    templateUrl: './wic-demo-account-edit.component.html',
    styleUrls: ['./wic-demo-account-edit.component.sass']
})
export class WicDemoAccountEditComponent implements OnInit, OnChanges {
    @Input() demoAccount: DemoAccount;
    @Input() authorities: Authority[];

    get f() {
        return this.form.controls;
    }

    form: FormGroup;
    currentAuthority: Authority;
    submitted = false;

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, public util: UtilService) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);
        if (this.demoAccount && this.form) {
            this.form.patchValue(this.demoAccount);
        }
        if (this.authorities && this.demoAccount) {
            this.currentAuthority = this.authorities.find(authority => authority.id === this.demoAccount.authorityId);
        }
        if (this.authorities && this.authorities.length === 1) {
            this.currentAuthority = this.authorities[0];
            this.form.patchValue({
                authorityId: +this.currentAuthority.id,
            });
        }
    }

    ngOnChanges() {
        if (this.demoAccount && this.form) {
            this.form.patchValue(this.demoAccount);
        }

        if (this.authorities && this.demoAccount) {
            this.currentAuthority = this.authorities.find(authority => authority.id === this.demoAccount.authorityId);
        }
    }

    private buildForm(formBuilder) {
        return formBuilder.group({
            name: [null, [Validators.required, Validators.minLength(3)]],
            cardNumber: new CardNumberControl(),
            authorityId: [null, Validators.required],
            isCardActivated: [true, Validators.required]
        });
    }

    chooseAuthority(authority: Authority) {
        this.currentAuthority = authority;
        this.form.patchValue({
            authorityId: +authority.id,
        });
    }

    save(form) {
        this.submitted = true;
        if (form.invalid) {
            return;
        }

        this.activeModal.close({
            ...this.demoAccount,
            ...form.value,
        });
    }
}
