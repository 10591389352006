import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {APLJobsFacade} from '@facades/apl-jobs.facade';
import {APLOverridesFacade} from '@facades/apl-overrides.facade';
import {APLTransformsFacade} from '@facades/apl-transforms.facade';
import {CategoryLookupsFacade} from '@facades/category-lookups.facade';
import {APLOverride, Authority, CategoryLookup, SubCategoryLookup} from '@models';
import {APLImportConfig} from '@models/APLImportConfig';
import {APLJob} from '@models/APLJob';
import {APLTransform} from '@models/APLTransform';
import {Observable} from 'rxjs';
import {filter, first, map, tap, withLatestFrom} from 'rxjs/operators';
import {APLImportConfigsFacade} from '@facades/apl-import-configs.facade';
import {AuthoritiesFacade} from '@facades/authorities.facade';

@Component({
    selector: 'wic-apl-import-view',
    templateUrl: './wic-apl-import-view.component.html',
    styleUrls: ['./wic-apl-import-view.component.sass']
})
export class WicAplImportViewComponent implements OnInit {
    config$: Observable<APLImportConfig>;
    jobs$: Observable<APLJob[]>;
    overrides$: Observable<APLOverride[]>;
    transforms$: Observable<APLTransform[]>;
    forDeletionOverride$: Observable<APLOverride>;
    forDeletionTransform$: Observable<APLTransform>;
    forDeletionJob$: Observable<APLJob>;
    isLoadingOverrides$: Observable<boolean>;
    isLoadingTransforms$: Observable<boolean>;
    isLoadingJobs$: Observable<boolean>;
    categories$: Observable<CategoryLookup[]>;
    subCategories$: Observable<SubCategoryLookup[]>;

    private authorities: Authority[];
    private authorityId: number;
    private config: APLImportConfig;
    private configs: APLImportConfig[];
    private overrides: APLOverride[];
    private categories: CategoryLookup[];
    private subCategories: SubCategoryLookup[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private aplImportConfigsFacade: APLImportConfigsFacade,
        private aplJobsFacade: APLJobsFacade,
        private authoritiesFacade: AuthoritiesFacade,
        private aplOverrideFacade: APLOverridesFacade,
        private aplTransformFacade: APLTransformsFacade,
        private categoryLoookupsFacade: CategoryLookupsFacade
    ) {
    }

    ngOnInit() {
        this.config$ = this.aplImportConfigsFacade.selectAll.pipe(
            withLatestFrom(
                this.activatedRoute.params.pipe(
                    filter(params => !!params),
                    map(params => params.id)
                )
            ),
            map(([configs, id]) => configs.find(config => config._id === id)),
            filter(config => !!config),
            tap(config => {
                this.config = config;
                this.authorityId = config.authorityId;
                this.aplOverrideFacade.loadAllByAuthority(config.authorityId);
                this.aplTransformFacade.loadAllByAuthority(config.authorityId);
                this.aplJobsFacade.loadByConfig(config._id);
            })
        );

        this.authoritiesFacade.all$.pipe(
            first(authorities => !!authorities && !!authorities.length)
        ).subscribe(authorities => this.authorities = authorities);

        this.jobs$ = this.aplJobsFacade.selectAll();

        this.overrides$ = this.aplOverrideFacade.selectAll().pipe(
            tap(overrides => this.overrides = overrides)
        );

        this.transforms$ = this.aplTransformFacade.selectAll();

        this.categories$ = this.categoryLoookupsFacade.allCategories$.pipe(
            tap(categories => this.categories = categories)
        );

        this.subCategories$ = this.categoryLoookupsFacade.allSubCategories$.pipe(
            tap(subCategories => this.subCategories = subCategories)
        );

        this.forDeletionOverride$ = this.aplOverrideFacade.forDeletion.asObservable();
        this.forDeletionTransform$ = this.aplTransformFacade.forDeletion.asObservable();
        this.forDeletionJob$ = this.aplJobsFacade.forDeletion.asObservable();
        this.isLoadingOverrides$ = this.aplOverrideFacade.selectIsLoading();
        this.isLoadingTransforms$ = this.aplTransformFacade.selectIsLoading();
        this.isLoadingJobs$ = this.aplJobsFacade.selectIsLoading();

        this.authoritiesFacade.loadAll();
        this.aplImportConfigsFacade.loadAll();
    }

    runJob(config: APLImportConfig) {
        this.aplImportConfigsFacade.runJob(config);
    }

    checkJob(config: APLImportConfig) {
        this.aplImportConfigsFacade.checkJob(config);
    }

    editConfig(config: APLImportConfig) {
        this.aplImportConfigsFacade.showEdit(config, this.configs, this.authorities);
    }

    addTransform() {
        this.aplTransformFacade.showCreate(this.authorityId);
    }

    editTransform(transform: APLTransform) {
        this.aplTransformFacade.showEdit(transform);
    }

    deleteTransform(transform: APLTransform) {
        this.aplTransformFacade.showDelete(transform);
    }

    addOverride() {
        this.aplOverrideFacade.showCreate(this.overrides, this.authorityId, this.categories, this.subCategories);
    }

    editOverride(override: APLOverride) {
        this.aplOverrideFacade.showEdit(override, this.overrides, this.categories, this.subCategories);
    }

    deleteOverride(override: APLOverride) {
        this.aplOverrideFacade.showDelete(override);
    }

    refreshOverrides() {
        this.aplOverrideFacade.loadAllShared();
    }

    refreshJobs() {
        if (this.config) {
            this.aplJobsFacade.loadByConfig(this.config._id);
        }
    }

    deleteJob(job: APLJob) {
        this.aplJobsFacade.showDelete(job);
    }
}
