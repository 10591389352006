import {APLItem, Authority} from '@models';
import {createSelector} from '@ngrx/store';
import {allAPLItems, aplItemState, APLItemState} from '@state/apl-item/apl-item.state';
import {allAuthorities} from '@state/authorities/authorities.selectors';

type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];

const getArrayLength = <T>(entities: T[]): number => entities.length;
const getFilterCriteria = (state: APLItemState): string => state.filterCriteria;

const matchPropsIncludingCriteria = <T, K extends KeysMatching<T, string>>(criteria, ...props: Array<K>) => (entity: T) =>
    props.some((prop) => (entity[prop] as unknown as string).includes(criteria));

const filterAPLItemsByCriteria = (criteria: string, entities: APLItem[]): APLItem[] =>
    criteria ? entities.filter(matchPropsIncludingCriteria(criteria, 'description', 'itemNumber')) : entities;

const mapToItemsWithAuthority = (aplItems: APLItem[], authorities: Authority[]): APLItem[] =>
    aplItems.map((item) => ({
        ...item,
        authority: (authorities.find(({id}) => id === item.authorityId) || {name: 'None'}).name
    }));

const aplItemFilter = createSelector(
    aplItemState,
    getFilterCriteria
);

const aplItemsWithAuthority = createSelector(
    allAPLItems,
    allAuthorities,
    mapToItemsWithAuthority
);

export const filteredAPLItems = createSelector(
    aplItemFilter,
    aplItemsWithAuthority,
    filterAPLItemsByCriteria
);

export const aplItemsLength = createSelector(
    allAPLItems,
    getArrayLength
);

export const filteredAPLItemsLength = createSelector(
    filteredAPLItems,
    getArrayLength
);
