import { Component, OnInit } from '@angular/core';
import {APLImportConfigsFacade} from '@facades/apl-import-configs.facade';
import {APLReportingFacade, IAPLImportStatusCounts} from '@facades/apl-reporting.facade';
import {APLSummariesFacade} from '@facades/apl-summaries.facade';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import { ReportingFacade } from '@facades/reporting.facade';
import {APLSummary} from '@models/APLSummary';
import {ModernStatisticsRecord} from '@models/ModernStatisticsRecord';
import {Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {loadModernStatisticsReport, modernStatisticsReport, modernStatisticsTotals} from '@state/reporting/modernstatistics.state';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IAuthorityInfo} from '../wic-authorities/models';
import {IDateRange} from '../wic-authorities/wic-authorities-legacy-report/wic-authorities-legacy-report.component';

@Component({
  selector: 'wic-dashboard',
  templateUrl: './wic-dashboard.component.html',
  styleUrls: ['./wic-dashboard.component.sass'],
})
export class WicDashboardComponent implements OnInit {
    authoritiesInfo$: Observable<IAuthorityInfo>;

    importStatusCounts$: Observable<IAPLImportStatusCounts>;
    itemCounts$: Observable<number[]>;
    itemLabels$: Observable<string[]>;
    categoryCounts$: Observable<number[]>;
    categoryLabels$: Observable<string[]>;
    subCategoryCounts$: Observable<number[]>;
    subCategoryLabels$: Observable<string[]>;

    modernStatisticsReport$: Observable<ModernStatisticsRecord[]>;
    modernStatisticsTotals$: Observable<ModernStatisticsRecord>;
    modernStatisticsLoadingStatus$: Observable<boolean>;

    constructor(
        private store: Store<IAppState>,
        private authoritiesFacade: AuthoritiesFacade,
        private aplReportingFacade: APLReportingFacade,
        private aplSummariesFacade: APLSummariesFacade,
        private aplImportConfigsFacade: APLImportConfigsFacade,
        public reporting: ReportingFacade) {
    }

    ngOnInit() {
        this.authoritiesInfo$ = this.authoritiesFacade.infoReport;

        this.importStatusCounts$ = this.aplReportingFacade.importStatusCounts;
        this.itemCounts$ = this.aplReportingFacade.itemCounts.pipe(map(series => series.map(dataPoint => dataPoint.count)));
        this.itemLabels$ = this.aplReportingFacade.itemCounts.pipe(map(series => series.map(dataPoint => dataPoint.name)));
        this.categoryCounts$ = this.aplReportingFacade.categoriesHistogram.pipe(
            map(histogram => histogram.map(counts => counts.count))
        );
        this.categoryLabels$ = this.aplReportingFacade.categoriesHistogram.pipe(
            map(histogram => histogram.map(counts => counts.value.toString()))
        );
        this.subCategoryCounts$ = this.aplReportingFacade.subCategoriesHistogram.pipe(
            map(histogram => histogram.map(counts => counts.count))
        );
        this.subCategoryLabels$ = this.aplReportingFacade.subCategoriesHistogram.pipe(
            map(histogram => histogram.map(counts => `${counts.value}-${counts.value + 9}`))
        );

        this.modernStatisticsReport$ = this.store.select(modernStatisticsReport);
        this.modernStatisticsTotals$ = this.store.select(modernStatisticsTotals);

        this.authoritiesFacade.loadAll();
        this.aplSummariesFacade.loadAll();
        this.aplImportConfigsFacade.loadAll();
    }

    modernDateRangeChanged(dateRange: IDateRange) {
        const startDate = `${dateRange.startDate.year}-${dateRange.startDate.month}-${dateRange.startDate.day}`;
        const endDate = `${dateRange.endDate.year}-${dateRange.endDate.month}-${dateRange.endDate.day}`;
        this.reporting.loadModern({startDate, endDate});
    }
}
