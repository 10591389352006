<div class="card mt-5 mb-5">
    <div class="card-header">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-3">
                    <ngx-bootstrap-multiselect
                        [options]="authorities"
                        [texts]="{defaultTitle: 'Select Authority'}"
                        [settings]="{
                            dynamicTitleMaxItems: 1,
                            selectionLimit: 1,
                            autoUnselect: true,
                            containerClasses: 'w-100',
                            buttonClasses: 'btn btn-outline-secondary w-100 text-left'
                        }"
                        formControlName="authorityId">
                    </ngx-bootstrap-multiselect>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <div class="input-group">
                            <input class="form-control" placeholder="Card Number" formControlName="cardNumber"
                                   maxlength="16">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="form.patchValue({cardNumber: null})"
                                        type="button">
                                    <i class="fa fa-times"></i>
                                </button>
                                <button class="btn btn-outline-secondary" [disabled]="!(form?.value?.authorityId?.length > 0)"
                                        (click)="cardSpun.emit(form.value.authorityId[0])"
                                        type="button">
                                    <i class="fa fa-random"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <div class="input-group">
                            <input class="form-control" placeholder="Household ID" formControlName="householdId"
                                   maxlength="10">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="form.patchValue({householdId: null})"
                                        type="button">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <button class="btn btn-outline-secondary mr-2 text-center" (click)="search()">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-3"></div>
                <div class="col-4">
                    <div class="form-group">
                        <div class="input-group">
                            <input class="form-control" placeholder="Start (yyyy-mm-dd)"
                                   name="startDate" formControlName="startDate"
                                   ngbDatepicker #sd="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="sd.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-outline-secondary" (click)="form.patchValue({startDate: null})"
                                        type="button">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <div class="input-group">
                            <input class="form-control" placeholder="End (yyyy-mm-dd)"
                                   name="endDate" formControlName="endDate"
                                   ngbDatepicker #ed="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="ed.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-outline-secondary" (click)="form.patchValue({endDate: null})"
                                        type="button">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="card-body" *ngIf="benefitInfo">
        <h5 class="card-title">Benefit Information</h5>
        <p>
            For card or household <strong>{{benefitInfo?.householdId || benefitInfo?.cardNumber}}</strong> of authority
            <strong>{{benefitInfo.authorityId}}</strong>
        </p>
    </div>
    <div class="card-body" *ngIf="benefitInfo">
        <div class="card-group">
            <div class="card">
                <div class="card-header">
                    Standard Info
                </div>
                <ul class="list-group">
                    <li class="list-group-item">Starts: {{benefitInfo.startDate}}</li>
                    <li class="list-group-item">Ends: {{benefitInfo.endDate}}</li>
                    <li class="list-group-item">GUID: {{benefitInfo.entryGUID}}</li>
                    <li class="list-group-item">Activated: {{benefitInfo.isCardActivated}}</li>
                </ul>
            </div>
            <div class="card">
                <div class="card-header">
                    Extended Info
                </div>
                <ul class="list-group">
                    <li class="list-group-item">Last Imported: {{benefitInfo.lastImportedDate}}</li>
                    <li class="list-group-item">Benefits Updated: {{benefitInfo.benefitsUpdatedAt}}</li>
                    <li class="list-group-item">Last Shopped: {{benefitInfo.lastShoppedDate}}</li>
                    <li class="list-group-item">Last Vendor: {{benefitInfo.lastVendorShopped}}</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="benefitInfo">
            <thead>
            <tr>
                <th class="text-nowrap">Category</th>
                <th class="text-nowrap">Sub Category</th>
                <th class="text-nowrap text-center">Quantity</th>
                <th class="text-center text-center">Begin Date</th>
                <th class="text-center text-center">End Date</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let benefit of benefitInfo.benefits">
                <td class="">{{benefit.categoryId}}</td>
                <td class="">{{benefit.subCategoryId}}</td>
                <td class="">{{benefit.quantity}}</td>
                <td class="text-center">{{benefit.beginDate}}</td>
                <td class="text-center">{{benefit.endDate}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
