<form [formGroup]="form">
  <div class="modal-header">
    <h4 class="modal-title mb-0">{{ benefitGroup ? 'Edit' : 'Create' }} Benefit Group</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="beginDate" class="col-2 col-form-label">Begin Date</label>
      <div class="col-4 input-group">
        <input
          class="form-control"
          [class.is-invalid]="submitted && form.beginDate.invalid"
          placement="left"
          tooltipClass="form-error"
          ngbTooltip="{{ submitted && form.beginDate.invalid ? util.formatError(form.beginDate.errors) : null }}"
          placeholder="yyyy-mm-dd"
          id="beginDate"
          name="beginDate"
          formControlName="beginDate"
          ngbDatepicker
          #startDate="ngbDatepicker"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="startDate.toggle()" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>

      <label for="endDate" class="col-2 col-form-label">End Date</label>
      <div class="col-4 input-group">
        <input
          class="form-control"
          [class.is-invalid]="submitted && form.endDate.invalid"
          placement="left"
          tooltipClass="form-error"
          ngbTooltip="{{ submitted && form.endDate.invalid ? util.formatError(form.endDate.errors) : null }}"
          placeholder="yyyy-mm-dd"
          id="endDate"
          name="endDate"
          formControlName="endDate"
          ngbDatepicker
          #endDate="ngbDatepicker"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="endDate.toggle()" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
    </div>

    <h4>Benefits</h4>

    <div class="form-group row">
      <div class="col-3">
        <b>Category</b>
      </div>
      <div class="col-3">
        <b>SubCategory</b>
      </div>
      <div class="col-3">
        <b>Available Quantity</b>
      </div>
      <div class="col-3">
        <b>Quantity</b>
      </div>
    </div>

    <div *ngFor="let benefit of form.benefits.benefitForms" class="form-group row">
      <div class="col-3">
        <div ngbDropdown class="d-inline-block">
          <button
            class="btn btn-outline-secondary"
            [class.btn-outline-danger]="submitted && benefit.categoryId.invalid"
            ngbTooltip="{{
              submitted && benefit.categoryId.invalid ? util.formatError(benefit.categoryId.errors) : null
            }}"
            placement="left"
            tooltipClass="form-error"
            id="categoryId"
            ngbDropdownToggle
            type="button"
          >
            {{ currentCategory$(benefit) | async }}
          </button>
          <div ngbDropdownMenu aria-labelledby="categoryId">
            <div style="overflow: auto; max-height: 300px;">
              <button
                class="dropdown-item"
                type="button"
                *ngFor="let category of categories.all$ | async"
                (click)="benefit.setCategory(category)"
              >
                <span class="badge badge-info">
                  {{ category?.categoryId }}
                </span>
                {{ category?.description }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="text-secondary" *ngIf="!benefit.categoryId.value">
          <strong>Choose a Category</strong>
        </div>
        <div ngbDropdown class="d-inline-block" *ngIf="!!benefit.categoryId.value">
          <button
            class="btn btn-outline-secondary"
            [class.btn-outline-danger]="submitted && benefit.subCategoryId.invalid"
            ngbTooltip="{{
              submitted && benefit.subCategoryId.invalid ? util.formatError(benefit.subCategoryId.errors) : null
            }}"
            placement="left"
            tooltipClass="form-error"
            id="subCategoryId"
            ngbDropdownToggle
            type="button"
          >
            {{ currentSubCategory$(benefit) | async }}
          </button>
          <div ngbDropdownMenu aria-labelledby="subCategoryId">
            <div style="overflow: auto; max-height: 300px;">
              <button
                class="dropdown-item"
                type="button"
                *ngFor="let subCategory of byCategory$(benefit) | async"
                (click)="benefit.setSubCategory(subCategory)"
              >
                <span class="badge badge-info">
                  {{ subCategory?.subCategoryId }}
                </span>
                {{ subCategory?.description }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <input
          type="number"
          class="form-control"
          [class.is-invalid]="submitted && benefit.availableQuantity.invalid"
          ngbTooltip="{{
            submitted && benefit.availableQuantity.invalid ? util.formatError(benefit.availableQuantity.errors) : null
          }}"
          placement="left"
          tooltipClass="form-error"
          id="availableQuantity"
          [formControl]="benefit.availableQuantity"
        />
      </div>

      <div class="col-3">
        <input
          type="number"
          class="form-control"
          [class.is-invalid]="submitted && benefit.quantity.invalid"
          ngbTooltip="{{ submitted && benefit.quantity.invalid ? util.formatError(benefit.quantity.errors) : null }}"
          placement="left"
          tooltipClass="form-error"
          id="quantity"
          [formControl]="benefit.quantity"
        />
      </div>
    </div>

    <div class="text-right">
      <button type="submit" class="btn btn-primary" (click)="form.addBenefit()">
        <i class="fa fa-plus"></i>Add Benefit
      </button>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    <button type="submit" class="btn btn-primary" (click)="save()">Save</button>
  </div>
</form>
