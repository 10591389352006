/* tslint:disable */
import {Injectable} from '@angular/core';
import {Create, Delete, LoadAll, Select, Update} from '@briebug/ngrx-auto-entity';
import {APLOverride, Authority, CategoryLookup, SubCategoryLookup} from '@models';
import {ObjectId} from '@models/types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {WicAplOverrideEditComponent} from '../wic-admin/wic-apl/wic-apl-imports/wic-apl-override-edit/wic-apl-override-edit.component';
import {WicConfirmModalComponent} from '../wic-confirm-modal/wic-confirm-modal.component';
import {allAPLOverrides, aplOverridesLoading} from '@state/apl-override.state';

/* tslint:enable */

@Injectable({
    providedIn: 'root'
})
export class APLOverridesFacade {
    forEdit: Subject<APLOverride>;
    forDeletion: Subject<APLOverride>;

    constructor(private store: Store<IAppState>, private modal: NgbModal) {
        this.forEdit = new Subject<APLOverride>();
        this.forDeletion = new Subject<APLOverride>();
    }

    // region Selections
    selectAll(): Observable<APLOverride[]> {
        return this.store.pipe(select(allAPLOverrides));
    }

    selectOne(id: ObjectId): Observable<APLOverride[]> {
        return this.selectAll().pipe(
            map(overrides => overrides.filter(override => override._id === id))
        );
    }

    selectIsLoading(): Observable<boolean> {
        return this.store.pipe(select(aplOverridesLoading));
    }
    // endregion

    // region Dispatches
    setCurrent(override: APLOverride) {
        this.store.dispatch(new Select(APLOverride, override));
    }

    loadAllShared() {
        this.store.dispatch(new LoadAll(APLOverride, {authorityId: null}));
    }

    loadAllByAuthority(authorityId: number) {
        this.store.dispatch(new LoadAll(APLOverride, {authorityId}));
    }

    create(override: APLOverride) {
        if (!override || override._id) {
            return;
        }

        this.store.dispatch(new Create(APLOverride, override));
    }

    update(override: APLOverride) {
        if (!override || !override._id) {
            return;
        }

        this.store.dispatch(new Update(APLOverride, override));
    }

    delete(override: APLOverride) {
        if (!override) {
            return;
        }

        this.store.dispatch(new Delete(APLOverride, override));
    }
    // endregion

    // region UI Helpers
    showEdit(
        override: APLOverride,
        overrides: APLOverride[],
        categories?: CategoryLookup[],
        subCategories?: SubCategoryLookup[]) {
        if (!override) {
            return;
        }

        this.forEdit.next(override);

        this.showEditModal(override, overrides, categories, subCategories)
            .then((edited: APLOverride) => {
                this.update(edited);
                this.forEdit.next(null);
            }, () => {
                this.forEdit.next(null);
            });
    }

    showCreate(
        overrides: APLOverride[],
        authoroityId?: number,
        categories?: CategoryLookup[],
        subCategories?: SubCategoryLookup[]) {
        this.showEditModal({
            authorityId: authoroityId || null,
            isEnabled: true
        } as APLOverride, overrides, categories, subCategories)
            .then(created => {
                this.create({...created});
            }, () => {
            });
    }

    showDelete(override: APLOverride) {
        if (!override) {
            return;
        }

        this.forDeletion.next(override);

        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = 'Confirm APL Override Deletion';
        modalReference.componentInstance.message = 'Are you sure you want to delete this APL override?';
        modalReference.result
            .then(() => {
                this.delete(override);
                this.forDeletion.next(null);
            }, () => {
                this.forDeletion.next(null);
            });
    }
    // endregion

    // region Helpers
    private showEditModal(
        override: APLOverride,
        overrides: APLOverride[],
        categories?: CategoryLookup[],
        subCategories?: SubCategoryLookup[]): Promise<APLOverride> {
        const modalReference = this.modal.open(WicAplOverrideEditComponent, {size: 'lg'});
        modalReference.componentInstance.override = override || {};
        modalReference.componentInstance.overrides = overrides || [];
        modalReference.componentInstance.categories = categories || [];
        modalReference.componentInstance.subCategories = subCategories || [];
        return modalReference.result;
    }
    // endregion
}
