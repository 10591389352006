import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wic-user-edit',
    templateUrl: './wic-user-edit.component.html',
    styleUrls: ['./wic-user-edit.component.sass'],
})
export class WicUserEditComponent implements OnInit {
    @Input() user: User;

    form: FormGroup;
    submitted = false;

    get rolesArray(): FormArray {
        return this.form.get('roleList') as FormArray;
    }

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.form = this.buildForm();
        this.form.patchValue({...this.user, password: null});
        this.form.setControl('roleList', this.formBuilder.array([
            this.formBuilder.group({name: 'admin', value: this.user.roles.includes('admin')}),
            this.formBuilder.group({name: 'authority', value: this.user.roles.includes('authority')}),
            this.formBuilder.group({name: 'api', value: this.user.roles.includes('api')}),
        ]));

        if ((this.form.controls.roleList as FormArray).controls[1].value.value) {
            this.form.controls.authorityId.setValidators(Validators.required);
            this.form.controls.authorityId.updateValueAndValidity();
        }

        (this.form.controls.roleList as FormArray).controls[1].valueChanges.subscribe(({value}) =>
            (
                value
                    ? this.form.controls.authorityId.setValidators(Validators.required)
                    : this.form.controls.authorityId.clearValidators(),
                    this.form.controls.authorityId.updateValueAndValidity()
            )
        );
    }

    private buildForm() {
        return this.formBuilder.group({
            username: [null, Validators.required],
            password: [undefined],
            email: [null, [Validators.required, Validators.email]],
            first: [null, Validators.required],
            last: [null, Validators.required],
            isEnabled: [false, Validators.required],
            hasAdminAccess: [false, Validators.required],
            authorityId: [null],
            roles: [null, Validators.required],
            roleList: this.formBuilder.array([]),
        });
    }

    save() {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        const user = {
            _id: this.user._id,
            ...this.form.value,
            password: undefined,
            newPassword: this.form.value.password,
            roles: this.form.value.roleList.filter(role => role.value).map(role => role.name),
            roleList: undefined,
        };
        this.activeModal.close(user);
    }
}
