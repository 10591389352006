<form [formGroup]="form">
    <div class="row mt-4 mb-4" *ngIf="texts">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="float-left mb-0">
                        Message Texts
                    </h5>
                    <i class="fa fa-fw fa-save float-right m-1" (click)="save(form)"
                       ngbTooltip="Save" placement="left"></i>
                </div>
                <div class="card-body">
                    <ngb-tabset formArrayName="texts" #texts
                                (tabChange)="tabBeforeChange($event)">
                        <ngb-tab *ngFor="let text of textsArray.controls; let i = index"
                                 [formGroupName]="i" id="{{i}}">
                            <ng-template ngbTabTitle>
                                <span [class.text-danger]="isSubmitted && textsArray.at(i).get('message').invalid">{{text.value.languageCode}}</span>
                                <span *ngIf="i > 0">&nbsp;<i class="fa fa-trash" (click)="$event.preventDefault(); removeText(text.value, i)"></i></span>
                            </ng-template>
                            <ng-template ngbTabContent>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input class="form-control my-3" formControlName="title" placeholder="Title">
                                        </div>
                                        <div class="form-group">
                                            <textarea class="form-control my-3"
                                                      [class.is-invalid]="isSubmitted && textsArray.at(i).get('message').invalid"
                                                      formControlName="message"
                                                      name="body"
                                                      [rows]="5"
                                                      maxlength="350"
                                                      required
                                                      placeholder="Message (Max 350 chars)..."
                                                      (change)="contentChanged()">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="+ Add" id="addLang">
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</form>
