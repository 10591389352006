import { Injectable } from '@angular/core';
import {buildState} from '@briebug/ngrx-auto-entity';
import { MicroBlog } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, createAction, props } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { currentAvailableAppId } from './available-apps/available-apps.state';

export const loadMBlog = createAction('[MicroBlogs] Load MicroBlog', props<{ id: string }>())
export const loadMBlogs = createAction('[MicroBlogs] Load MicroBlogs');
export const createMBlog = createAction('[MicroBlogs] Create MicroBlog', props<{ microBlog: MicroBlog }>());
export const updateMBlog = createAction('[MicroBlogs] Update MicroBlog', props<{ microBlog: MicroBlog }>());
export const deleteMBlog = createAction('[MicroBlogs] Delete MicroBlog', props<{ microBlog: MicroBlog }>());

export const {
  initialState: initialMicroBlogsState,
  selectors: {
    selectAll: allMicroBlogs,
    selectIsLoading: isMicroBlogsLoading,
    selectCurrentEntity: currentMicroBlog
  },
  actions: {
      load: loadMicroBlog,
      loadAll: loadAllMicroBlogs,
      create: createMicroBlog,
      update: updateMicroBlog,
      delete: deleteMicroBlog,
  }
} = buildState(MicroBlog);

export function microBlogReducer(state = initialMicroBlogsState): any {
    return state;
}

@Injectable()
export class MicroBlogEffects {
  
  constructor(private actions$: Actions, private store: Store) {}

  loadMBlogs$ = createEffect(() => 
    this.actions$.pipe(
      ofType(loadMBlogs),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([, appId]) => loadAllMicroBlogs({criteria: { appId }}))
    )
  );

  loadMBlog$ = createEffect(() => 
    this.actions$.pipe(
      ofType(loadMBlog),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([{id}, appId]) => loadMicroBlog({ 
        keys:id,
        criteria: appId
      }))
    )
  ); 

  createMBlog$ = createEffect(() => 
    this.actions$.pipe(
      ofType(createMBlog),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([{microBlog}, appId]) => createMicroBlog({
        entity: {...microBlog, appId: +appId},
        criteria: appId
      }))
    )
  );

  updateMBlog$ = createEffect(() => 
    this.actions$.pipe(
      ofType(updateMBlog),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([{microBlog}, appId]) => updateMicroBlog({
        entity: microBlog,
        criteria: appId
      }))
    )
  );

  deleteMicroBlog$ = createEffect(() => 
    this.actions$.pipe(
      ofType(deleteMBlog),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([{microBlog}, appId]) => deleteMicroBlog({
        entity: microBlog,
        criteria: appId 
      }))
    )
  );
}