import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo, IEntityWithPageInfo, Page} from '@briebug/ngrx-auto-entity';
import {ErrorReport} from '@models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

const BASE_URL = '/admin/support/errors/reports';

@Injectable({
    providedIn: 'root'
})
export class ErrorReportsService implements IAutoEntityService<ErrorReport> {
    constructor(private http: HttpClient) {
    }

    loadAll(info: IEntityInfo, criteria?: any): Observable<ErrorReport[]> {
        return this.http.get<ErrorReport[]>(`${environment.apiRoot}${BASE_URL}`);
    }

    loadPage(info: IEntityInfo, page: Page, criteria?: any): Observable<IEntityWithPageInfo<ErrorReport>> {
        return this.http.get<{ totalCount: number, reports: ErrorReport[] }>(`${environment.apiRoot}${BASE_URL}`, {
            params: {
                offset: `${(page.size * (page.page - 1))}`,
                limit: `${page.size}`
            }
        }).pipe(
            map(({totalCount, reports}): IEntityWithPageInfo<ErrorReport> => ({
                pageInfo: {
                    totalCount,
                    page
                },
                entities: reports
            }))
        );
    }
}
