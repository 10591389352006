import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BannerPlaylist} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wic-banner-playlist-detail-edit',
  templateUrl: './wic-banner-playlist-detail-edit.component.html',
  styleUrls: ['./wic-banner-playlist-detail-edit.component.sass']
})
export class WicBannerPlaylistDetailEditComponent implements OnInit {
    @Input() playlist: BannerPlaylist;

    form: FormGroup;
    submitted = false;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);
        this.populateForm(this.playlist);
    }

    private buildForm(formBuilder) {
        const form = formBuilder.group({
            PlaylistName: [null, Validators.required],
            IsActive: [false, Validators.required],
        });

        return form;
    }

    populateForm(playlist) {
        this.form.patchValue({
            ...playlist
        });
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        const playlist = {
            ...value,
            PlaylistID: this.playlist.PlaylistID
        };

        this.activeModal.close(playlist);
    }

}
