import {buildState} from '@briebug/ngrx-auto-entity';
import {APLJob} from '@models/APLJob';

const {initialState, selectors} = buildState(APLJob);

export const {
    selectAll: allAPLJobs,
    selectIsLoading: aplJobsLoading,
    selectIsSaving: aplJobSaving,
    selectIsDeleting: aplJobeleting,
    selectCurrentEntity: currentAPLJob
} = selectors;

export function aplJobReducer(state = initialState) {
    return state;
}
