import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'wic-doughnut-labeled',
    templateUrl: './doughnut-labeled.component.html',
    styleUrls: ['./doughnut-labeled.component.sass'],
})
export class DoughnutLabeledComponent implements OnInit {
    @Input() data: number[];
    @Input() labels: string[];
    @Input() totalLabel: string;
    @Input() chartTitle: string;
    @Input() excludeLast = false;

    @Input() colors: string[] = ['#bfdfff'];

    constructor() {
    }

    ngOnInit() {
    }

    sumTotal(data) {
        if (!data || !data.length) { return 0; }
        const total = data ? data.reduce((sum, item) => sum + item, 0) : [];
        return this.excludeLast ? total - data[data.length - 1] : total;
    }
}
