import {createAction, props} from '@ngrx/store';

export const benefitImportsRouteVisit = createAction(
    '[BenefitImportJob] Route Visited',
    props<{ id: string }>()
);

export const refreshBenefitImportJob = createAction(
    '[BenefitImportJob] Refresh Job'
);
