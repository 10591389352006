import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthorityMapping} from '@state/authority-mappings/model';

const defaultValues: AuthorityMapping = {
    integration: '',
    name: '',
    username: '',
    binCode: '',
    endpoint: '',
    useProxy: false,
    isEnabled: true,
};

@Component({
    selector: 'wic-authority-mappings-form',
    templateUrl: './wic-authority-mappings-form.component.html',
    styleUrls: ['./wic-authority-mappings-form.component.sass'],
})
export class WicAuthorityMappingsFormComponent {
    @Input() authorityMapping: AuthorityMapping;
    @Output() saved: EventEmitter<AuthorityMapping> = new EventEmitter<AuthorityMapping>();
    @Output() canceled: EventEmitter<AuthorityMapping> = new EventEmitter<AuthorityMapping>();

    resetForm() {
        this.authorityMapping = {...defaultValues};
    }
}
