import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorReportsFacade} from '@facades/error-reports.facade';
import {ErrorReport} from '@models';

@Component({
    selector: 'wic-error-reports',
    templateUrl: './error-reports.component.html',
    styleUrls: ['./error-reports.component.sass']
})
export class ErrorReportsComponent implements OnDestroy {
    hideSelf = false;

    constructor(public errorReports: ErrorReportsFacade, private route: ActivatedRoute, private router: Router) {
        this.errorReports.loadPage({page: 1, size: 50});
    }

    ngOnDestroy() {
        this.errorReports.loadPage({page: 1, size: 20});
    }

    viewReport(report: ErrorReport): void {
        this.router.navigate([report._id], {
            relativeTo: this.route
        });
    }
}
