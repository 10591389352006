/* tslint:disable:max-line-length */
import {Component, OnInit} from '@angular/core';
import { NavigationEnd, PRIMARY_OUTLET, Router, Routes } from '@angular/router';
import {AuthFacade} from '@facades/auth.facade';
import {MenuItem} from '@models';
import {Crumb} from '@models/Crumb';
import {Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {merge, Observable, of} from 'rxjs';
import {delay, filter, map, withLatestFrom} from 'rxjs/operators';
import {AdminRequiredGuard} from '../guards/admin-required.guard';
import {AuthGuard} from '@auth0/auth0-angular';
import {LayoutService} from '../services/layout.service';

import {flatMap} from '../util';
import {DeviceLogEntriesListComponent} from './support-tools/device-logs/device-log-entries-list/device-log-entries-list.component';
import {DeviceLogsComponent} from './support-tools/device-logs/device-logs.component';
import {EnhancedDevicesComponent} from './support-tools/enhanced-devices/enhanced-devices.component';
import {ErrorReportViewComponent} from './support-tools/error-reports/error-report-view/error-report-view.component';
import {ErrorReportsComponent} from './support-tools/error-reports/error-reports.component';
import {SupportToolsComponent} from './support-tools/support-tools.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {WicAplImportJobViewComponent} from './wic-apl/wic-apl-imports/wic-apl-import-job-view/wic-apl-import-job-view.component';
import {WicAplImportViewComponent} from './wic-apl/wic-apl-imports/wic-apl-import-view/wic-apl-import-view.component';
import {WicAplImportsComponent} from './wic-apl/wic-apl-imports/wic-apl-imports.component';
import {WicAplMessageViewComponent} from './wic-apl/wic-apl-messages/wic-apl-message-view/wic-apl-message-view.component';
import {WicAplMessagesComponent} from './wic-apl/wic-apl-messages/wic-apl-messages.component';
import {WicAplComponent} from './wic-apl/wic-apl.component';
import {WicAppointmentImportViewComponent} from './wic-appointments/wic-appointment-import-view/wic-appointment-import-view.component';
import {WicAppointmentImportsComponent} from './wic-appointments/wic-appointment-imports/wic-appointment-imports.component';
import {WicAppointmentRemindersListComponent} from './wic-appointments/wic-appointment-reminders-list/wic-appointment-reminders-list.component';
import {WicAuthoritiesComponent} from './wic-authorities/wic-authorities.component';
import {WicAuthorityBannersComponent} from './wic-authorities/wic-authority-banners/wic-authority-banners.component';
import {WicBannerPlaylistViewComponent} from './wic-authorities/wic-authority-banners/wic-banner-playlist-view/wic-banner-playlist-view.component';
import {WicAuthorityFlagsComponent} from './wic-authorities/wic-authority-flags/wic-authority-flags.component';
import {WicAuthorityListComponent} from './wic-authorities/wic-authority-list/wic-authority-list.component';
import {WicAuthorityTypesComponent} from './wic-authorities/wic-authority-types/wic-authority-types.component';
import { WicAuthorityLocationsComponent } from './wic-authorities/wic-authority-view/wic-authority-locations/wic-authority-locations.component';
import {WicAuthorityViewComponent} from './wic-authorities/wic-authority-view/wic-authority-view.component';
import {CardCheckComponent} from './wic-benefits/card-check/card-check.component';
import {WicBenefitImportViewComponent} from './wic-benefits/wic-benefit-import-view/wic-benefit-import-view.component';
import {WicBenefitImportsComponent} from './wic-benefits/wic-benefit-imports/wic-benefit-imports.component';
import {WicBenefitsListComponent} from './wic-benefits/wic-benefits-list/wic-benefits-list.component';
import {WicDashboardComponent} from './wic-dashboard/wic-dashboard.component';
import {WicDemoAccountsComponent} from './wic-demo-accounts/wic-demo-accounts.component';
import {WicMicroBlogViewComponent} from './wic-micro-blogs/wic-micro-blog-view/wic-micro-blog-view.component';
import {WicMicroBlogComponent} from './wic-micro-blogs/wic-micro-blogs.component';
import {WicSubCategoryConfigViewComponent} from './wic-sub-category-configs/wic-sub-category-config-view/wic-sub-category-config-view.component';
import {WicSubCategoryConfigsComponent} from './wic-sub-category-configs/wic-sub-category-configs.component';
import {WicUserListComponent} from './wic-users/wic-user-list/wic-user-list.component';
import {WicUserViewComponent} from './wic-users/wic-user-view/wic-user-view.component';
import {WicWikiArticleComponent} from './wic-wiki/wic-wiki-article/wic-wiki-article.component';
import {WicWikiEditComponent} from './wic-wiki/wic-wiki-edit/wic-wiki-edit.component';
import {WicWikiListComponent} from './wic-wiki/wic-wiki-list/wic-wiki-list.component';
import {WicWikiComponent} from './wic-wiki/wic-wiki.component';
import {AplSearchComponent} from './wic-apl/apl-search/apl-search.component';
import {ImportJobDetailsComponent} from './wic-benefits/import-job-details/import-job-details.component';
import { AvailableAppsFacade } from '@state/available-apps/available-apps.facade';

/* tslint:enable:max-line-length */

@Component({
    selector: 'wic-admin',
    templateUrl: './wic-admin.component.html',
    styleUrls: ['./wic-admin.component.sass'],
})
export class WicAdminComponent implements OnInit {
    showLayout$: Observable<boolean>;
    hideLayout$: Observable<boolean>;
    showNarrow$: Observable<boolean>;
    fullName$: Observable<string>;
    // snack$: Observable<Snack>;

    // claims: any;
    crumbs: Crumb[];
    menuItems: MenuItem[];
    area: string;
    areaPath: string;
    showUserMenu = false;

    constructor(
        private store: Store<IAppState>,
        public availableApps: AvailableAppsFacade,
        private auth: AuthFacade,
        private router: Router,
        public layout: LayoutService) {
    }

    ngOnInit(): void {
        this.showLayout$ = of(true); // this.store.select(uiSelectors.showLayout);
        this.showNarrow$ = of(false); // this.store.select(uiSelectors.showNarrowSidebar);
        this.fullName$ = of('Test'); // this.store.select(authSelectors.fullName);
        // this.snack$ = this.store.select(uiSelectors.latestSnack);

        this.hideLayout$ = this.showLayout$.pipe(map(flag => !flag));

        merge(
            of(null).pipe(delay(500)),
            this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        ).pipe(
            withLatestFrom(this.auth.claims$, this.availableApps.currentKey$),
            filter(([, claims]) => !!claims)
        ).subscribe(([, claims, appId]) => {
            this.menuItems = this.buildMenu(claims, appId);

            try {
                this.area = this.router.routerState.root.firstChild.firstChild.firstChild.snapshot.data.area;
                this.areaPath = this.router.routerState.root.firstChild.firstChild.firstChild.snapshot.pathFromRoot
                    .map(pfr => pfr.url.join('/')).join('/');
            } catch (err) {
                this.area = null;
                this.areaPath = null;
            }

            this.crumbs = this.buildBreadcrumbs();
        });
    }

    private buildBreadcrumbs() {
        return flatMap(
            this.router.routerState.root.children.filter(c => c.outlet === PRIMARY_OUTLET), ar => {
                return ar.snapshot.data && ar.snapshot.data.isBreadcrumb ? {
                    path: ar.snapshot.pathFromRoot.map(pfr => pfr.url.join('/')).join('/'),
                    title: ar.snapshot.data.title,
                } : null;
            }, ar => ar.children.filter(c => c.outlet === PRIMARY_OUTLET)
        ).filter(b => !!b);
    }

    private buildMenu(claims, appId) {
        const menuRoot = this.router.config
            .find(route => route.data && route.data.isMenuRoot);
        return menuRoot.children[0].children
            .filter(route =>
                ['', '**'].indexOf(route.path) === -1 && route.data && route.data.isMenuItem &&
                (!route.data.showForRoles || claims.roles.some(role => route.data.showForRoles.includes(role)))
            )
            .map(route => {
                return ({
                    path: `/apps/${appId}/admin/` + route.path,
                    title: route.data.title,
                    icon: route.data.icon,
                    subMenuItems: flatMap(
                        (route.children || ((route as any)._loadedConfig || {}).routes || []).filter(c =>
                            (!c.data?.showForRoles || claims.roles.some(role => c.data.showForRoles.includes(role)))
                        ), r => {
                            return r.data && r.data.isMenuItem ? {
                                path: r.data.path || `/apps/${appId}/admin/` + route.path + '/' + r.path,
                                title: r.data.title,
                            } : null;
                        }, r => (r.children || [])
                    ).filter(r => {
                        return !!r;
                    }),
                });
            });
    }

    toggleSidebar() {
        // this.store.dispatch(new uiActions.ToggleSidebarAction());
    }

    // dismissSnack() {
    //     this.store.dispatch(new uiActions.DismissSnackAction());
    // }

    logout() {
        this.showUserMenu = false;
        // this.store.dispatch(new authActions.LogoutAction());
    }
}

export let AdminRoutes: Routes = [
  {
    path: 'apps/:app',
    canActivate: [AuthGuard, AdminRequiredGuard],
    data: {title: 'Admin', isMenuRoot: true},
    children: [
      {
        path: 'admin',
        component: WicAdminComponent,
        canActivate: [AuthGuard, AdminRequiredGuard],
        children: [
          {
            path: 'retailer-management',
            canActivate: [AuthGuard],
            loadChildren: () => import('../retailer-management/retailer-management.module').then(m => m.RetailerManagementModule),
            data: {
              title: 'Retailer Management', area: 'Retailers', icon: 'fa-building',
              isBreadcrumb: true, isMenuItem: true,
            },
          },
          {
            path: 'profile', component: UserProfileComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Profile', area: 'User', icon: 'fa-user',
              isBreadcrumb: true, isMenuItem: false,
            },
          },
          {
            path: 'dashboard', component: WicDashboardComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Dashboard', area: 'Analytics', icon: 'fa-bar-chart',
              isBreadcrumb: true, isMenuItem: true,
            },
          },
          {
            path: 'wiki', component: WicWikiComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'WIC Wiki', area: 'Wiki', icon: 'fa-book',
              isBreadcrumb: true, isMenuItem: true,
            },
            children: [
              {
                path: 'articles/:slug', component: WicWikiArticleComponent,
                canActivate: [AuthGuard],
                data: {title: 'Article', isBreadcrumb: true, isMenuItem: false},
              },
              {
                path: 'list', component: WicWikiListComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Articles', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
                children: [
                  {
                    path: 'new', component: WicWikiEditComponent,
                    canActivate: [AuthGuard, AdminRequiredGuard],
                    data: {title: 'New', isBreadcrumb: true, isMenuItem: false},
                  },
                  {
                    path: 'edit/:id', component: WicWikiEditComponent,
                    canActivate: [AuthGuard, AdminRequiredGuard],
                    data: {title: 'Edit', isBreadcrumb: true, isMenuItem: false},
                  },
                ],
              },
              { path: '', pathMatch: 'full', redirectTo: 'articles/home'}
            ],
          },
          {
            path: 'notifications',
            canActivate: [AuthGuard],
            loadChildren: () => import('../notifications/notifications.module').then(m => m.NotificationsModule),
            data: {
              title: 'Notification Events', area: 'Notifications', icon: 'fa-bell',
              isBreadcrumb: true, isMenuItem: true,
            },
          },
          {
            path: 'support', component: SupportToolsComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Support Tools', area: 'Support', icon: 'fa-ambulance',
              isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']
            },
            children: [
              {
                path: 'error-reports', component: ErrorReportsComponent,
                canActivate: [AuthGuard],
                data: {title: 'Error Reports', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
                children: [
                  {
                    path: ':id', component: ErrorReportViewComponent,
                    canActivate: [AuthGuard],
                    data: {title: 'Report', isBreadcrumb: true, isMenuItem: false}
                  }
                ]
              },
              {
                path: 'logs', component: DeviceLogsComponent,
                canActivate: [AuthGuard],
                data: {title: 'Device Logs', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
                children: [
                  {
                    path: ':udid/sessions/:sessionId', component: DeviceLogEntriesListComponent,
                    canActivate: [AuthGuard],
                    data: {title: 'Session', isBreadcrumb: true, isMenuItem: false}
                  }
                ]
              },
              {
                path: 'enhanced', component: EnhancedDevicesComponent,
                canActivate: [AuthGuard],
                data: {title: 'Enhanced Devices', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
              },
            ]
          },
          {
            path: 'appointments', component: WicAppointmentRemindersListComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Appointments', area: 'Appointments', icon: 'fa-calendar',
              isBreadcrumb: true, isMenuItem: true,
            },
            children: [{
              path: 'imports', component: WicAppointmentImportsComponent,
              canActivate: [AuthGuard],
              data: {title: 'Imports', isBreadcrumb: true, isMenuItem: true},
              children: [
                {
                  path: 'view/:id', component: WicAppointmentImportViewComponent,
                  canActivate: [AuthGuard],
                  data: {title: 'View', isBreadcrumb: true, isMenuItem: false},
                },
              ],
            }],
          },
          {
            path: 'benefits', component: WicBenefitsListComponent,
            canActivate: [AuthGuard],
            data: {
              title: 'Benefits', area: 'Benefits', icon: 'fa-credit-card-alt',
              isBreadcrumb: true, isMenuItem: true,
            },
            children: [{
              path: 'imports', component: WicBenefitImportsComponent,
              canActivate: [AuthGuard],
              data: {title: 'Imports', isBreadcrumb: true, isMenuItem: true},
              children: [
                {
                  path: ':id', component: ImportJobDetailsComponent,
                  canActivate: [AuthGuard],
                  data: {title: 'View', isBreadcrumb: true, isMenuItem: false},
                },
                {
                  path: 'view/:id', component: WicBenefitImportViewComponent,
                  canActivate: [AuthGuard],
                  data: {title: 'View', isBreadcrumb: true, isMenuItem: false},
                },
              ],
            }, {
              path: 'card-check', component: CardCheckComponent,
              canActivate: [AuthGuard],
              data: {title: 'Card Check', isBreadcrumb: true, isMenuItem: true},
            }],
          },
          {
            path: 'translations',
            canActivate: [AuthGuard],
            loadChildren: () => import('../translations/translations.module').then(m => m.TranslationsModule),
            data: {
              title: 'Translations', area: 'Translations', icon: 'fa-language',
              isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin'],
            },
          },
          {
            path: 'shared', component: WicAuthoritiesComponent,
            canActivate: [AuthGuard, AdminRequiredGuard],
            data: {
              title: 'Shared Features', area: 'Shared', icon: 'fa-cogs',
              isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin'],
            },
            children: [
              {
                path: 'subcatconfig', component: WicSubCategoryConfigsComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Subcategory Config', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
                children: [
                  {
                    path: 'view/:id', component: WicSubCategoryConfigViewComponent,
                    canActivate: [AuthGuard, AdminRequiredGuard],
                    data: {title: 'View', isBreadcrumb: true},
                  },
                ],
              },
              {
                path: 'microblogs', component: WicMicroBlogComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Micro Blogging', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
                children: [
                  {
                    path: 'view/:id', component: WicMicroBlogViewComponent,
                    canActivate: [AuthGuard, AdminRequiredGuard],
                    data: {title: 'View', isBreadcrumb: true},
                  },
                ],
              },
              {
                path: 'demoaccounts', component: WicDemoAccountsComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Demo Accounts', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
              },
              {
                path: 'users', component: WicUserListComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Users', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
                children: [
                  {
                    path: 'view/:id', component: WicUserViewComponent,
                    canActivate: [AuthGuard, AdminRequiredGuard],
                    data: {title: 'View', isBreadcrumb: true},
                  },
                ],
              },
            ],
          },
          {
            path: 'authorities', component: WicAuthoritiesComponent,
            canActivate: [AuthGuard, AdminRequiredGuard],
            data: {
              title: 'Authority Management', area: 'Authorities', icon: 'fa-cart-plus',
              isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin'],
            },
            children: [
              {
                path: 'list', component: WicAuthorityListComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Authorities', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
                children: [
                  {
                    path: 'view/:id', component: WicAuthorityViewComponent,
                    canActivate: [AuthGuard, AdminRequiredGuard],
                    data: {title: 'View', isBreadcrumb: true},
                    children: [
                      {
                        path: 'banners/:bannerId', component: WicBannerPlaylistViewComponent,
                        canActivate: [AuthGuard, AdminRequiredGuard],
                        data: {title: 'Banners', isBreadcrumb: true},
                      },
                      {
                        path: 'locations', component: WicAuthorityLocationsComponent,
                        canActivate: [AuthGuard, AdminRequiredGuard],
                        data: {title: 'Locations', isBreadcrumb: true},
                      },
                    ]
                  },
                ],
              },
              {
                path: 'banners', component: WicAuthorityBannersComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Banner Playlists', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
                children: [
                  {
                    path: 'view/:id', component: WicBannerPlaylistViewComponent,
                    canActivate: [AuthGuard, AdminRequiredGuard],
                    data: {title: 'View', isBreadcrumb: true},
                  },
                ],
              },
              {
                path: 'flags', component: WicAuthorityFlagsComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Flags', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
              },
              {
                path: 'types', component: WicAuthorityTypesComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Types', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
              }
            ],
          },
          {
            path: 'imports',
            canActivate: [AuthGuard],
            loadChildren: () => import('../import-jobs/import-jobs.module').then(m => m.ImportJobsModule),
            data: {
              title: 'Imports', area: 'Import Management', icon: 'fa-folder-open',
              isBreadcrumb: true, isMenuItem: true,
            },
          },
          {
            path: 'apl', component: WicAplComponent,
            canActivate: [AuthGuard, AdminRequiredGuard],
            data: {
              title: 'Authorized Products', area: 'APL', icon: 'fa-cutlery',
              isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin'],
            },
            children: [
              {
                path: 'search', component: AplSearchComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Search APLs', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
              },
              {
                path: 'substitutions',
                loadChildren: () =>
                  import('../features/substitution-configs/substitution-configs.module').then(m => m.SubstitutionConfigsModule),
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Substitutions', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
              },
              {
                path: 'messages', component: WicAplMessagesComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'APL Messages', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
                children: [
                  {
                    path: 'view/:id', component: WicAplMessageViewComponent,
                    canActivate: [AuthGuard, AdminRequiredGuard],
                    data: {title: 'View', isBreadcrumb: true}
                  },
                ]
              },
              {
                path: 'import', component: WicAplImportsComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Import Management', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
                children: [
                  {
                    path: 'view/:id', component: WicAplImportViewComponent,
                    canActivate: [AuthGuard, AdminRequiredGuard],
                    data: {title: 'View', isBreadcrumb: true},
                    children: [
                      {
                        path: 'job/:id', component: WicAplImportJobViewComponent,
                        canActivate: [AuthGuard, AdminRequiredGuard],
                        data: {title: 'Job', isBreadcrumb: true}
                      },
                    ]
                  },
                ]
              },
            ],
          },
          {
            path: 'business', component: WicAuthoritiesComponent,
            canActivate: [AuthGuard, AdminRequiredGuard],
            data: {
              title: 'Vendors & Partners', area: 'Business', icon: 'fa-handshake-o',
              isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin'],
            },
            children: [
              {
                path: 'vendors', component: WicAuthoritiesComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Vendors', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
              },
              {
                path: 'partners', component: WicAuthoritiesComponent,
                canActivate: [AuthGuard, AdminRequiredGuard],
                data: {title: 'Partners', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
              },
            ],
          },
          {
            path: 'mobile-ordering',
            loadChildren: () =>
              import('../features/mobile-orders/mobile-orders.module').then(m => m.MobileOrdersModule),
            canActivate: [AuthGuard, AdminRequiredGuard],
            data: {title: 'Mobile Ordering', isBreadcrumb: true, isMenuItem: true, showForRoles: ['admin']},
          },
          {path: '', redirectTo: 'dashboard', pathMatch: 'prefix'}
        ],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'admin'
      }
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'apps/1/admin'
  }
];
