import { Pipe, PipeTransform } from '@angular/core';
import {SubCategoryLookup} from '@models';

@Pipe({name: 'sortByCategorySubCategoryIds'})
export class SortByCategorySubCategoryIdsPipe implements PipeTransform {
  transform(subCategories: SubCategoryLookup[]): SubCategoryLookup[] {
    return subCategories.sort(
        (a, b) => (a.categoryId * 1000 + a.subCategoryId) - (b.categoryId * 1000 + b.subCategoryId)
    );
  }
}
