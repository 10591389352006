import { createSelector } from '@ngrx/store';
import { statisticsReportingJobsState } from '@state/reporting/statistics-reporting-jobs.state';
import { differenceInSeconds } from 'date-fns';

export const isReportLoading = createSelector(statisticsReportingJobsState, state => state.isLoading);
export const reportStartDate = createSelector(statisticsReportingJobsState, state => state.loadStart);
export const reportEndDate = createSelector(statisticsReportingJobsState, state => state.loadEnd);
export const reportingJob = createSelector(statisticsReportingJobsState, state => state.reportingJob);
export const reportCriteria = createSelector(statisticsReportingJobsState, state => state.criteria);
export const reportFailed = createSelector(statisticsReportingJobsState, state => state.failed);
export const reportCorrelationId = createSelector(statisticsReportingJobsState, state => state.correlationId);

export const reportLoadingDuration = createSelector(reportStartDate, reportEndDate, (startDate, endDate) =>
  startDate && endDate && differenceInSeconds(endDate, startDate)
);

export const usageStatisticsReports = createSelector(reportingJob, job => job?.report);
export const reportingJobStatus = createSelector(reportingJob, job => job?.status);


