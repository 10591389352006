import {buildState} from '@briebug/ngrx-auto-entity';
import {createSelector} from '@ngrx/store';

import {AppointmentImport} from '@models';

const {initialState, selectors, entityState} = buildState(AppointmentImport);

export const {
    selectAll: allAppointmentImports,
    selectCurrentEntity: currentAppointmentImport
} = selectors;

export const appointmentImportsPage = createSelector(entityState, state => {
    return (state && state.currentPage) ? state.currentPage || 1 : 1;
});
export const appointmentImportPageableCount = createSelector(entityState, state => {
    return state ? state.totalCount : 0;
});

export const currentAppointmentImportLog = createSelector(currentAppointmentImport, impt => impt && impt.progress);
export const currentAppointmentParseErrors = createSelector(currentAppointmentImport, impt =>
    impt && (impt.parseError || []).slice(0, 100)
);

export function appointmentImportReducer(state = initialState) {
    return state;
}
