import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Authority} from '@models';
import {CardImage} from '@models/CardImage';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'wic-authority-cardimage',
  templateUrl: './wic-authority-cardimage.component.html',
  styleUrls: ['./wic-authority-cardimage.component.sass'],
})
export class WicAuthorityCardimageComponent implements OnChanges {
    @Input() authority: Authority;
    @Input() cardImage: CardImage;
    @Output() edited = new EventEmitter<{authority: Authority, cardImage: CardImage}>();

    apiUrl = environment.apiRoot;

    cardUrl = null;

    ngOnChanges() {
        this.sync();
    }

    sync() {
        if (this.authority) {
            setTimeout(() => {
                const rand = Math.random();
                this.cardUrl = this.apiUrl + '/authorities/' + this.authority.id + '/card?cache=no&' + rand;
            }, 100);
        }
    }

    edit(authority: Authority, cardImage: CardImage) {
        this.edited.emit({authority, cardImage});
    }
}
