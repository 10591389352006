import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Authority, AuthorityAccount, AuthorityFlag, AuthorityType, BinCode, Category, MetaImage, SubCategory} from '@models';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { AvailableApps } from '@state/available-apps/models/available-app.entity';

const BASE_URL = '/authorities';

@Injectable()
export class AuthoritiesService {
    constructor(private http: HttpClient) {
    }

    list(appId = AvailableApps.WICShopper, includeInactive = false): Observable<Authority[]> {
        return this.http.get<Authority[]>(
          `${environment.apiRoot}/admin/apps/${appId}/authorities?includeInactive=${includeInactive ? 'true' : 'false'}`
        );
    }

    listBinCodes(authorityId: number): Observable<BinCode[]> {
        return this.http.get<BinCode[]>(`${environment.apiRoot}/admin/authorities/${authorityId}/bincodes`);
    }

    listFlags(): Observable<AuthorityFlag[]> {
        return this.http.get<AuthorityFlag[]>(`${environment.apiRoot}/admin/authorities/flags`);
    }

    create(authority: Authority): Observable<Authority> {
        return this.http.post<Authority>(`${environment.apiRoot}/admin/authorities`, authority);
    }

    update(authority: Authority): Observable<Authority> {
        return this.http.patch<Authority>(`${environment.apiRoot}/admin/authorities/${authority.id}`, authority);
    }

    setLogo(authority: Authority, logo: MetaImage): Observable<Authority> {
        return this.http.patch<Authority>(`${environment.apiRoot}/admin/authorities/${authority.id}/logo`, {
            authorityId: authority.id,
            ...logo
        }).pipe(
            map(() => authority)
        );
    }

    fetchAuthorities(): Observable<Authority[]> {
        return this.http.get<Authority[]>(`${environment.apiRoot}${BASE_URL}`);
    }

    fetchBinCodes(authorityId: number): Observable<BinCode[]> {
        return this.http.get<BinCode[]>(`${environment.apiRoot}${BASE_URL}/${authorityId}/bincodes`);
    }

    fetchCategories(authorityId: number): Observable<Category[]> {
        return this.http.get<Category[]>(`${environment.apiRoot}${BASE_URL}/${authorityId}/apl/categories`);
    }

    fetchSubCategories(authorityId: number, categoryId: number): Observable<SubCategory[]> {
        return this.http.get<SubCategory[]>(`${environment.apiRoot}${BASE_URL}/${authorityId}/apl/categories/${categoryId}/subcategories`);
    }

    fetchTypes(): Observable<AuthorityType[]> {
        return this.http.get<AuthorityType[]>(`${environment.apiRoot}/admin/authorities/types`);
    }

    fetchAuthorityAccount(authorityId: number): Observable<AuthorityAccount> {
        return this.http.get<AuthorityAccount>(`${environment.apiRoot}/admin/authorities/${authorityId}/account`);
    }
}
