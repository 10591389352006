import { Inject, Injectable } from '@angular/core';
import { ContainerClient } from '@azure/storage-blob';
import { getContainerClient } from '../core/browser';
import { AzureStorageConfig, FileUpload } from '../core/types';
import { createBlobInContainer } from '../core/uploads';
import { AZURE_STORAGE_CONFIG_TOKEN } from './tokens';

@Injectable()
export class StorageService {
  client: ContainerClient;

  constructor(
    @Inject(AZURE_STORAGE_CONFIG_TOKEN)
    private readonly config: AzureStorageConfig
  ) {}

  uploadFile(file: FileUpload, sasUrl: string, fileKey?: string) {
    const client = getContainerClient(sasUrl, this.config.containerName);
    return createBlobInContainer(client, file, fileKey);
  }
}
