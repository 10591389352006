import {buildState} from '@briebug/ngrx-auto-entity';
import {SubCategoryLookup} from '@models';
import {createSelector} from '@ngrx/store';

const {initialState, selectors} = buildState(SubCategoryLookup);

export const {
    selectAllSorted: allSubCategoryLookups,
    selectCurrentEntity: currentSubCategoryLookup
} = selectors;

export function subCategoryLookupReducer(state = initialState) {
    return state;
}

