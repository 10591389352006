import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MbscSelectOptions} from '@mobiscroll/angular';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'wic-apl-search-criteria',
    templateUrl: './apl-search-criteria.component.html',
    styleUrls: ['./apl-search-criteria.component.scss']
})
export class AplSearchCriteriaComponent implements OnInit {
    @Input() authorities;
    @Input() categories;
    @Output() searchCriteria = new EventEmitter();
    @Output() filter = new EventEmitter();
    form: FormGroup;

    desktopWheelSettings: MbscSelectOptions = {
        display: 'bubble',
        touchUi: false,
        select: 'multiple',
        filter: true,
        groupLabel: '&nbsp;',
        group: {
            header: true,
            groupWheel: true,
        },
    };

    constructor(private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.form = this.buildForm();
        this.form.controls.filter.valueChanges.pipe(
            debounceTime(50)
        ).subscribe((val) => this.filter.emit(val));
    }

    private buildForm() {
        return this.formBuilder.group({
            authorities: [[]],
            categories: [[]],
            filter: ''
        });
    }

    onSubmit() {
        this.searchCriteria.emit(this.form.value);
    }
}
