<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <span class="badge badge-info" *ngIf="config?._id">
                {{config._id}}
            </span>
            {{config?._id ? 'Edit' : 'Create'}} APL Import Config
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label for="authorityId" class="col-4 col-form-label" [class.text-danger]="submitted && f.authorityId.invalid">Authority</label>
            <div class="col-8">
                <div ngbDropdown class="d-inline-block w-100">
                    <button class="btn btn-outline-secondary w-100"
                            [class.btn-outline-danger]="submitted && f.authorityId.invalid"
                            [class.text-danger]="submitted && f.authorityId.invalid"
                            ngbTooltip="{{(submitted && f.authorityId.invalid) ? util.formatError(f.authorityId.errors) : null}}"
                            placement="left" tooltipClass="form-error" id="authorityId" ngbDropdownToggle type="button">
                        {{currentAuthority?.name}}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="authorityId">
                        <div style="overflow: auto; max-height: 300px">
                            <button class="dropdown-item" type="button"
                                    *ngFor="let authority of authorities"
                                    (click)="chooseAuthority(authority)">
                                {{authority.name}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="ftpUrl" class="col-4 col-form-label" [class.text-danger]="submitted && f.ftpUrl.invalid">FTP Url</label>
            <div class="col-8">
                <input class="form-control" formControlName="ftpUrl" name="ftpUrl" id="ftpUrl"
                       [class.is-invalid]="submitted && f.ftpUrl.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="ftpPort" class="col-4 col-form-label">FTP Port</label>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="ftpPort" name="ftpPort" id="ftpPort">
            </div>
        </div>
        <div class="form-group row">
            <label for="ftpRoot" class="col-4 col-form-label">FTP Root Path</label>
            <div class="col-8">
                <input class="form-control" formControlName="ftpRoot" name="ftpRoot" id="ftpRoot">
            </div>
        </div>
        <div class="form-group row">
            <label for="aplGlob" class="col-4 col-form-label" [class.text-danger]="submitted && f.aplGlob.invalid">APL File Pattern</label>
            <div class="col-8">
                <input class="form-control" formControlName="aplGlob" name="aplGlob" id="aplGlob"
                       [class.is-invalid]="submitted && f.aplGlob.invalid">
            </div>
        </div>

        <div class="form-group row">
            <label for="username" class="col-4 col-form-label" [class.text-danger]="submitted && f.username.invalid">Username</label>
            <div class="col-8">
                <input class="form-control" formControlName="username" name="username" id="username"
                       [class.is-invalid]="submitted && f.username.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="password" class="col-4 col-form-label" [class.text-danger]="submitted && f.password.invalid">Password</label>
            <div class="col-8">
                <input type="password" class="form-control" formControlName="password" name="password" id="password"
                       [class.is-invalid]="submitted && f.password.invalid">
            </div>
        </div>
        <div class="form-group row">
            <label for="isSecure" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isSecure" formControlName="isSecure">
                    <label class="form-check-label" for="isSecure">Requires SFTP (Secure Transfer)</label>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="isEnabled" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isEnabled" formControlName="isEnabled">
                    <label class="form-check-label" for="isEnabled">Is Enabled</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="isZipped" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isZipped" formControlName="isZipped">
                    <label class="form-check-label" for="isZipped">Is Zipped</label>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="filterDuplicates" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="filterDuplicates" formControlName="filterDuplicates">
                    <label class="form-check-label" for="filterDuplicates">Filter out duplicate item records</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="requiresZeroFill" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="requiresZeroFill" formControlName="requiresZeroFill">
                    <label class="form-check-label" for="requiresZeroFill">Require Sub-Category Zero Fill</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="requiresTransformation" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="requiresTransformation" formControlName="requiresTransformation">
                    <label class="form-check-label" for="requiresTransformation">Require Transformation</label>
                </div>
            </div>
        </div>
        <div class="form-group row" *ngIf="f.requiresTransformation.value === true">
            <div class="col-4 col-form-label"></div>
            <div class="col-8">
                <small class="text-muted">Configure transformation in Import Config detail view</small>
            </div>
        </div>
        <div class="form-group row">
            <label for="requiresOverrides" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="requiresOverrides" formControlName="requiresOverrides">
                    <label class="form-check-label" for="requiresOverrides">Require Overrides</label>
                </div>
            </div>
        </div>
        <div class="form-group row" *ngIf="f.requiresOverrides.value === true">
            <label for="applySharedOverrides" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="applySharedOverrides" formControlName="applySharedOverrides">
                    <label class="form-check-label" for="applySharedOverrides">Apply Shared Overrides</label>
                </div>
            </div>
        </div>
        <div class="form-group row" *ngIf="f.requiresOverrides.value === true">
            <div class="col-4 col-form-label"></div>
            <div class="col-8">
                <small class="text-muted">Configure overrides in Import Config detail view</small>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
