import { Action, createAction, props } from '@ngrx/store';

import { Authority, AuthorityAccount, MetaImage } from '@models';
import {type} from '../shared/utils';

export const CREATE_AUTHORITY = type('[Authorities] Create authority');
export const CREATE_AUTHORITY_SUCCESS = type('[Authorities] Create authority succeeded');
export const CREATE_AUTHORITY_FAILURE = type('[Authorities] Create authority failed');
export const DELETE_AUTHORITY = type('[Authorities] Delete authority');
export const DELETE_AUTHORITY_SUCCESS = type('[Authorities] Delete authority succeeded');
export const DELETE_AUTHORITY_FAILURE = type('[Authorities] Delete authority failed');
export const DISMISS_AUTHORITIES_ERROR = type('[Authorities] Dismiss error');
export const FETCH_AUTHORITIES = type('[Authorities] Fetch authorities');
export const FETCH_AUTHORITIES_SUCCESS = type('[Authorities] Fetch authorities succeeded');
export const FETCH_AUTHORITIES_FAILURE = type('[Authorities] Fetch authorities failed');
export const FETCH_AUTHORITY = type('[Authorities] Fetch authority');
export const FETCH_AUTHORITY_SUCCESS = type('[Authorities] Fetch authority succeeded');
export const FETCH_AUTHORITY_FAILURE = type('[Authorities] Fetch authority failed');
export const UPDATE_AUTHORITY = type('[Authorities] Update authority');
export const UPDATE_AUTHORITY_SUCCESS = type('[Authorities] Update authority succeeded');
export const UPDATE_AUTHORITY_FAILURE = type('[Authorities] Update authority failed');

export const SET_AUTHORITY_LOGO = type('[Authorities] Set authority logo');
export const SET_AUTHORITY_LOGO_SUCCESS = type('[Authorities] Set authority logo succeeded');
export const SET_AUTHORITY_LOGO_FAILURE = type('[Authorities] Set authority logo failed');

export const SELECT_AUTHORITY = type('[Authorities] Select authority');
export const SELECT_AUTHORITY_BY_ID = type('[Authorities] Select authority by id');

export const createAuthority = createAction('[Authorities] Create Authority', props<{ authority: Authority; account?: AuthorityAccount; }>());
export const createAuthoritySuccess = createAction('[Authorities] Create Authority Success', props<{ authority: Authority; account?: AuthorityAccount; }>());
export const createAuthorityFailure = createAction('[Authorities] Create Authority Failure', props<{ authority: Authority; account?: AuthorityAccount; err: any; }>());


export class CreateAuthorityAction implements Action {
    readonly type = CREATE_AUTHORITY;

    constructor(public payload: Authority) {
    }
}

export class CreateAuthoritySuccessAction implements Action {
    readonly type = CREATE_AUTHORITY_SUCCESS;

    constructor(public payload: Authority) {
    }
}

export class CreateAuthorityFailureAction implements Action {
    readonly type = CREATE_AUTHORITY_FAILURE;

    constructor(public payload: any) {
    }
}

export class DeleteAuthorityAction implements Action {
    readonly type = DELETE_AUTHORITY;

    constructor(public payload: Authority) {
    }
}

export class DeleteAuthoritySuccessAction implements Action {
    readonly type = DELETE_AUTHORITY_SUCCESS;

    constructor(public payload: string) {
    }
}

export class DeleteAuthorityFailureAction implements Action {
    readonly type = DELETE_AUTHORITY_FAILURE;

    constructor(public payload: any) {
    }
}

export class DismissAuthoritiesErrorAction implements Action {
    readonly type = DISMISS_AUTHORITIES_ERROR;
}

export class FetchAuthoritiesAction implements Action {
    readonly type = FETCH_AUTHORITIES;
}

export class FetchAuthoritiesSuccessAction implements Action {
    readonly type = FETCH_AUTHORITIES_SUCCESS;

    constructor(public payload: Authority[]) {
    }
}

export class FetchAuthoritiesFailureAction implements Action {
    readonly type = FETCH_AUTHORITIES_FAILURE;

    constructor(public payload: any) {
    }
}

export class FetchAuthorityAction implements Action {
    readonly type = FETCH_AUTHORITY;

    constructor(public payload: string) {
    }
}

export class FetchAuthoritySuccessAction implements Action {
    readonly type = FETCH_AUTHORITY_SUCCESS;

    constructor(public payload: Authority) {
    }
}

export class FetchAuthorityFailureAction implements Action {
    readonly type = FETCH_AUTHORITY_FAILURE;

    constructor(public payload: any) {
    }
}

export class UpdateAuthorityAction implements Action {
    readonly type = UPDATE_AUTHORITY;

    constructor(public payload: Authority) {
    }
}

export class UpdateAuthoritySuccessAction implements Action {
    readonly type = UPDATE_AUTHORITY_SUCCESS;

    constructor(public payload: Authority) {
    }
}

export class UpdateAuthorityFailureAction implements Action {
    readonly type = UPDATE_AUTHORITY_FAILURE;

    constructor(public payload: any) {
    }
}

export class SetAuthorityLogoAction implements Action {
    readonly type = SET_AUTHORITY_LOGO;

    constructor(public authority: Authority, public authorityLogo: MetaImage) {
    }
}

export class SetAuthorityLogoSuccessAction implements Action {
    readonly type = SET_AUTHORITY_LOGO_SUCCESS;

    constructor(public payload: Authority) {
    }
}

export class SetAuthorityLogoFailureAction implements Action {
    readonly type = SET_AUTHORITY_LOGO_FAILURE;

    constructor(public payload: any) {
    }
}

export class SelectAuthorityAction implements Action {
    readonly type = SELECT_AUTHORITY;

    constructor(public payload: Authority) {
    }
}

export class SelectAuthorityByIdAction implements Action {
    readonly type = SELECT_AUTHORITY_BY_ID;

    constructor(public payload: number) {
    }
}

export type AuthorityActions =
    | CreateAuthorityAction
    | CreateAuthorityFailureAction
    | CreateAuthoritySuccessAction
    | DeleteAuthorityAction
    | DeleteAuthorityFailureAction
    | DeleteAuthoritySuccessAction
    | DismissAuthoritiesErrorAction
    | FetchAuthoritiesAction
    | FetchAuthoritiesFailureAction
    | FetchAuthoritiesSuccessAction
    | FetchAuthorityAction
    | FetchAuthorityFailureAction
    | FetchAuthoritySuccessAction
    | UpdateAuthorityAction
    | UpdateAuthorityFailureAction
    | UpdateAuthoritySuccessAction
    | SetAuthorityLogoAction
    | SetAuthorityLogoSuccessAction
    | SetAuthorityLogoFailureAction
    | SelectAuthorityAction
    | SelectAuthorityByIdAction;
