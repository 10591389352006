import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

import { AuthoritiesService } from '@services';
import { FETCH_BINCODES, FetchBINCodesAction, FetchBINCodesFailureAction, FetchBINCodesSuccessAction } from './bincodes.actions';

@Injectable()
export class BINCodesEffects {
    @Effect()
    fetchBINCodes$ = this.actions.pipe(
        ofType(FETCH_BINCODES),
        exhaustMap((action: FetchBINCodesAction) =>
            this.authoritiesService.listBinCodes(action.payload.id).pipe(
                map(binCodes => new FetchBINCodesSuccessAction(binCodes)),
                catchError(err => of(new FetchBINCodesFailureAction(err)))
            ))
    );

    // @Effect()
    // fetchBINCode$ = this.actions.ofType(FETCH_BINCODE)
    //     .pipe(
    //         exhaustMap((action: FetchBINCodeAction) => {
    //             return this.BINCodesService.fetchBINCode(action.payload)
    //                 .pipe(
    //                     map(authority => new FetchBINCodeSuccessAction(authority)),
    //                     catchError(err =>
    //                         of(new FetchBINCodeFailureAction(err))
    //                     )
    //                 );
    //         })
    //     );
    //
    // @Effect()
    // createBINCode$ = this.actions.ofType(CREATE_BINCODE)
    //     .pipe(
    //         map((action: CreateBINCodeAction) => action.payload),
    //         exhaustMap((binCode: BINCode) => {
    //             return this.BINCodesService.createBINCode(binCode)
    //                 .pipe(
    //                     map(created => new CreateBINCodeSuccessAction(created)),
    //                     catchError(err =>
    //                         of(new CreateBINCodeFailureAction(err))
    //                     )
    //                 );
    //         })
    //     );
    //
    // @Effect()
    // updateBINCode$ = this.actions.ofType(UPDATE_BINCODE)
    //     .pipe(
    //         map((action: UpdateBINCodeAction) => action.payload),
    //         exhaustMap((binCode: BINCode) => {
    //             return this.BINCodesService.updateBINCode(binCode)
    //                 .pipe(
    //                     map(updated => new UpdateBINCodeSuccessAction(updated)),
    //                     catchError(err => of(new UpdateBINCodeFailureAction(err)))
    //                 );
    //         })
    //     );
    //
    // @Effect()
    // deleteBINCode$ = this.actions.ofType(DELETE_BINCODE)
    //     .pipe(
    //         map((action: DeleteBINCodeAction) => action.payload),
    //         exhaustMap((binCode: BINCode) => {
    //             return this.BINCodesService.deleteBINCode(binCode._id)
    //                 .pipe(
    //                     map(id => new DeleteBINCodeSuccessAction(id)),
    //                     catchError(err => of(new DeleteBINCodeFailureAction(err)))
    //                 );
    //         })
    //     );

    constructor(private actions: Actions, private authoritiesService: AuthoritiesService) {
    }
}
