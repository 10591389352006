import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wic-support-requests',
  templateUrl: './support-requests.component.html',
  styleUrls: ['./support-requests.component.sass']
})
export class SupportRequestsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
