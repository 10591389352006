import {Injectable} from '@angular/core';
import {LoadAll, SelectByKey} from '@briebug/ngrx-auto-entity';
import {AppointmentImport} from '@models';
import {IImportParseError, IImportStep} from '@models/types';
import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {
    allAppointmentImports,
    currentAppointmentImport,
    currentAppointmentImportLog,
    currentAppointmentParseErrors
} from '@state/appointment-imports.state';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppointmentImportsFacade {
    constructor(private store: Store<IAppState>) {
    }

    // region Selections
    get all(): Observable<AppointmentImport[]> {
        return this.store.pipe(select(allAppointmentImports));
    }

    get selected(): Observable<AppointmentImport> {
        return this.store.pipe(select(currentAppointmentImport));
    }

    get selectedLog(): Observable<IImportStep[]> {
        return this.store.pipe(select(currentAppointmentImportLog));
    }
    get selectedParseErrors(): Observable<IImportParseError[]> {
        return this.store.pipe(select(currentAppointmentParseErrors));
    }
    // endregion

    // region Dispatches
    selectByKey(id) {
        this.store.dispatch(new SelectByKey(AppointmentImport, id));
    }

    loadAll() {
        this.store.dispatch(new LoadAll(AppointmentImport));
    }
    // endregion
}
