import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';

import {Credential} from '@models';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthService {
    constructor(private http: HttpClient) {
    }

    authenticate(credential: Credential): Observable<any> {
        return this.http.post(`${environment.apiRoot}/admin/login`, credential);
    }

    logout(): Observable<any> {
        // localStorage.removeItem('token');
        return of(null);
    }

    resetPassword(email: string): Observable<{wasSuccessful: boolean, error?: string}> {
        return this.http.post<{wasSuccessful: boolean, error?: string}>(`${environment.apiRoot}/admin/reset`, {email});
    }

    changePassword(request: any): Observable<{wasSuccessful: boolean, error?: string}> {
        return this.http.post<{ wasSuccessful: boolean, error?: string }>(`${environment.apiRoot}/admin/change`, request);
    }
}
