<div class="w-100 h-100">
    <ng-template #noData>
        <div class="w-100 h-75 text-dark"><small>No data</small></div>
    </ng-template>
    <canvas baseChart *ngIf="barLabels && dataSets && barLabels.length && dataSets.length; else noData"
            [labels]="barLabels"
            [datasets]="dataSets"
            [chartType]="'bar'"
            [colors]="colors"
            [options]="{
                        animation: {
                            duration: duration,
                            easing: easing
                        },
                        showLines: false,
                        scales: {
                            xAxes:[{gridLines: {display: false}, ticks: {display: false},  stacked: true}],
                            yAxes:[{gridLines: {display: false}, ticks: {display: false}, stacked: true}]
                        }
                    }"
            [legend]="false"></canvas>
    <span class="text-nowrap text-muted mt-5 mb-5" *ngIf="chartTitle">{{chartTitle}}</span>
</div>
