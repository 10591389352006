import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { currentAvailableAppId } from '@state/available-apps/available-apps.state';
import { AvailableApps } from '@state/available-apps/models/available-app.entity';
import { of, race, timer } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  createReportingJob,
  createReportingJobFailure,
  createReportingJobSuccess,
  pollReportingJob,
  pollReportingJobFailure,
  pollReportingJobSuccess,
} from './statistics-reporting-jobs.actions';
import { reportCorrelationId, reportCriteria } from './statistics-reporting-jobs.selectors';
import { StatisticsReportingJobsService } from './statistics-reporting-jobs.service';
import { ReportingJobStatus } from '@state/reporting/statistics-reporting-job.entity';

@Injectable()
export class StatisticsReportingJobsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly statisticsReportingJobs: StatisticsReportingJobsService,
    private readonly store: Store
  ) {}

  createReportingJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createReportingJob),
      withLatestFrom(this.store.select(reportCorrelationId), this.store.select(currentAvailableAppId)),
      switchMap(([{ beginDate, endDate }, correlationId, appId]) =>
        this.statisticsReportingJobs
          .pollReportingJob(correlationId, appId as AvailableApps, { beginDate, endDate })
          .pipe(
            map(reportingJob => createReportingJobSuccess({ reportingJob, correlationId })),
            catchError(error => of(createReportingJobFailure({ error })))
          )
      )
    )
  );

  createPollInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createReportingJobSuccess),
      mergeMap(({ correlationId }) =>
        timer(5000, 5000).pipe(
          takeUntil(
            race(
              this.actions$.pipe(
                ofType(pollReportingJobSuccess),
                filter(({ reportingJob }) => reportingJob?.id === correlationId),
                first(({reportingJob}) => [ReportingJobStatus.FINISHED, ReportingJobStatus.FAILED].includes(reportingJob.status))
              ),
              this.actions$.pipe(
                ofType(pollReportingJobFailure, createReportingJob),
                first()
              )
            )
          ),
          map(() => pollReportingJob({ correlationId }))
        )
      )
    )
  )

  pollReportingJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollReportingJob),
      withLatestFrom(this.store.select(reportCriteria), this.store.select(currentAvailableAppId)),
      switchMap(([{ correlationId }, { beginDate, endDate }, appId]) =>
        this.statisticsReportingJobs
          .pollReportingJob(correlationId, appId as AvailableApps, { beginDate, endDate })
          .pipe(
            map(reportingJob => pollReportingJobSuccess({ reportingJob })),
            catchError(error => of(pollReportingJobFailure({ error })))
          )
      )
    )
  )
}
