import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {AuthorityFlag} from '@models';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthorityFlagService implements IAutoEntityService<AuthorityFlag> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: any): Observable<AuthorityFlag[]> {
        return this.http.get<AuthorityFlag[]>(environment.apiRoot +
            '/admin/authorities/flags'
        );
    }

    update(entityInfo: IEntityInfo, entity: AuthorityFlag): Observable<AuthorityFlag> {
        return this.http.put<AuthorityFlag>(environment.apiRoot +
            `/admin/authorities/flags/${entity.FlagID}`,
            entity
        );
    }
}
