<div class="row mt-4">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">Slides</h5>
                <i class="fa fa-fw fa-save float-right m-1" (click)="save(microBlog, deck)"
                   ngbTooltip="Save" placement="left"></i>
            </div>
        </div>
    </div>
</div>

<div class="row mt-4" *ngIf="deck">
    <div class="col-12 d-flex flex-wrap">
        <div class="w-25 pb-4" [class.pr-4]="(i+1) % 4 !== 0" *ngFor="let slide of deck; let i = index">
            <div class="card">
                <img class="card-img-top" [src]="createImageUrl(slide.image)">
                <div class="card-header d-flex p-0">
                    <span class="flex-fill" [style.background]="slide.bgColor || '#ffffff'" ngbTooltip="Background Color" placement="top">&nbsp;</span>
                    <span class="flex-fill" [style.background]="slide.iconColor || '#000000'" ngbTooltip="Icon Color" placement="top">&nbsp;</span>
                </div>
                <div class="card-body d-flex">
                    <a class="card-link flex-fill" [class.text-muted]="i === 0"
                       (click)="moveSlideLeft(i)" role="button">
                        <i class="fa fa-chevron-left"></i>
                    </a>
                    <span class="d-flex">
                        <a class="card-link align-self-start text-center"
                           (click)="editSlide(i)" role="button">
                            <i class="fa fa-pencil"></i>
                        </a>
                        <a class="card-link align-self-end text-center"
                           (click)="removeSlide(i)" role="button">
                            <i class="fa fa-trash"></i>
                        </a>
                    </span>
                    <a class="card-link flex-fill text-right" [class.text-muted]="i === deck.length-1"
                       (click)="moveSlideRight(i)" role="button">
                        <i class="fa fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="w-25 pb-4" [class.pr-4]="(deck.length+1) % 4 !== 0">
            <div class="card h-100">
                <div class="card-body d-flex justify-content-center">
                    <a class="align-self-center text-center" role="button">
                        <i class="fa fa-5x fa-plus text-muted" (click)="add.click();"></i>
                        <input type="file" [hidden]="true" accept="image/*"
                               (change)="addFiles($event.target.files)" #add>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

