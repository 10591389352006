import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Observable} from 'rxjs';

import {AppointmentImport} from '@models/Appointment';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AppointmentImportService implements IAutoEntityService<AppointmentImport> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<AppointmentImport[]> {
        const url = (criteria && criteria.authorityId) ?
            `/admin/authorities/${criteria.authorityId}/appointments/imports` :
            '/admin/authorities/appointments/imports';
        return this.http.get<AppointmentImport[]>(environment.apiRoot + url);
    }
}
