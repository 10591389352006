<div class="row mt-4" *ngIf="parseErrors?.length">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">Parsing Errors (Job aborts at 25 errors)</h5>
            </div>
            <div class="">
                <table class="table table-sm table-striped table-hover fixed-header mb-0">
                    <thead>
                    <tr>
                        <th class="text-nowrap">Line #</th>
                        <th class="text-nowrap">Error</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let error of parseErrors">
                        <td class="">{{error.line}}</td>
                        <td class="">{{error.message}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
