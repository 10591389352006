import {Component, OnInit} from '@angular/core';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {AuthorityFlag} from '@models';

@Component({
    selector: 'wic-authority-flags',
    templateUrl: './wic-authority-flags.component.html',
    styleUrls: ['./wic-authority-flags.component.sass']
})
export class WicAuthorityFlagsComponent implements OnInit {
    constructor(public authorities: AuthoritiesFacade) {
    }

    ngOnInit() {
        this.authorities.loadFlags();
    }

    refreshFlags() {
        this.authorities.loadFlags();
    }

    editFlag(flag: AuthorityFlag) {
        this.authorities.showFlagEdit(flag);
    }
}
