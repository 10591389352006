import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'EnhancedIssue',
  uriName: 'enhanced-issues'
})
export class EnhancedIssue {
  @Key issueId: number;
  authorityId: number;
  displayOrder: number;
  link: string;
  issueName: string;
} 