import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { AuthoritiesService } from '@services';
import {
    FETCH_AUTHORITY_TYPES,
    FetchAuthorityTypesFailureAction,
    FetchAuthorityTypesSuccessAction,
} from '@state/authorities/types.actions';

@Injectable()
export class AuthorityTypesEffects {
    @Effect()
    fetchAuthorityTypes$ = this.actions.pipe(
        ofType(FETCH_AUTHORITY_TYPES),
        exhaustMap(() => this.authoritiesService.fetchTypes().pipe(
            map(types => new FetchAuthorityTypesSuccessAction(types)),
            catchError(err => of(new FetchAuthorityTypesFailureAction(err)))
        ))
    );

    constructor(private actions: Actions, private authoritiesService: AuthoritiesService) {
    }
}
