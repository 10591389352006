import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../../shared/components/components.module';
import { WicEnhancedIssueTranslationsComponent } from './wic-enhanced-issue-translations.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, ComponentsModule, NgbModule],
    declarations: [WicEnhancedIssueTranslationsComponent],
    exports: [WicEnhancedIssueTranslationsComponent],
})
export class EnhancedIssueTranslationsModule {
}
