import {buildState} from '@briebug/ngrx-auto-entity';
import {APLMessageMapping} from '@models';

export const {initialState, selectors, facade: APLMessageMappingFacadeBase} = buildState(APLMessageMapping);

export const {
    selectAll: allAPLMessageMappings,
    selectIsLoading: aplMessageMappingsLoading
} = selectors;

export function aplMessageMappingReducer(state = initialState) {
    return state;
}

