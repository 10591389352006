import {Pipe, PipeTransform} from '@angular/core';
import {JobStatus} from '@models/BenefitImportJob';

const jobStatusMap = {
    [JobStatus.initiated]: 'badge-primary',
    [JobStatus.downloaded]: 'badge-secondary',
    [JobStatus.inProgress]: 'badge-info',
    [JobStatus.completed]: 'badge-success',
    [JobStatus.failed]: 'badge-danger',
};

@Pipe({name: 'jobStatus'})
export class JobStatusPipe implements PipeTransform {
    transform(key: JobStatus): string {
        return jobStatusMap[key] || 'badge-info';
    }
}
