<router-outlet  (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>

<div class="row" *ngIf="!hideSelf">
    <div class="col-md-12">
        <wic-apl-imports-status
            [aplImportStatusCounts]="importStatusCounts$ | async"
            [itemCounts]="itemCounts$ | async"
            [itemLabels]="itemLabels$ | async"
            [categoriesCounts]="categoryCounts$ | async"
            [categoriesLabels]="categoryLabels$ | async"
            [subCategoriesCounts]="subCategoryCounts$ | async"
            [subCategoriesLabels]="subCategoryLabels$ | async">
        </wic-apl-imports-status>
    </div>
    <div class="col-md-12">
        <wic-apl-summary-table [summaries]="summaries$ | async"></wic-apl-summary-table>
    </div>
</div>
