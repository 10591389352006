<form [formGroup]="form">
  <div class="modal-header">
    <h4 class="modal-title mb-0">
      Replicate Card to Demo Account
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group row">
      <label class="col-4 col-form-label">Authority</label>

      <div class="col-8">
        <wic-select-authority
          [authorities]="authorities.allAsGroupedLookup$ | async"
          [control]="f.authorityId"
          [multi]="false"
        ></wic-select-authority>
      </div>
    </div>

    <div class="form-group row">
      <label for="name" class="col-4 col-form-label">Name</label>
      <div class="col-8 input-group">
        <input
          type="text"
          class="form-control"
          [class.is-invalid]="f.name.invalid && f.name.touched"
          [ngbTooltip]="f.name | formatFormError"
          placement="bottom-left"
          tooltipClass="form-error"
          id="name"
          formControlName="name"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="cardNumber" class="col-4 col-form-label">Card Number</label>
      <div class="input-group col-8">
        <input
          type="text"
          class="form-control"
          [class.is-invalid]="f.cardNumber.invalid && f.cardNumber.touched"
          [ngbTooltip]="f.cardNumber | formatFormError"
          placement="bottom-left"
          maxlength="16"
          tooltipClass="form-error"
          id="cardNumber"
          formControlName="cardNumber"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="demoCardNumber" class="col-4 col-form-label">Demo Card Number</label>
      <div class="input-group col-8">
        <input
          type="text"
          class="form-control"
          [class.is-invalid]="f.demoCardNumber.invalid && f.demoCardNumber.touched"
          [ngbTooltip]="f.demoCardNumber | formatFormError"
          placement="bottom-left"
          maxlength="16"
          tooltipClass="form-error"
          id="demoCardNumber"
          formControlName="demoCardNumber"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Save</button>
  </div>
</form>
