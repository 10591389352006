
<div class="row pt-5">
    <div class="col-md-4">
        <button class="btn btn-secondary mb-3" (click)="addEdit()">
            Add New Location
        </button>
        <div class="list-group">
            <div *ngFor="let location of locations.all$ | async"
                 class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                 [ngClass]="{ active: location.locationId === (locations.current$ |async)?.locationId}"
                 (click)="selectLocation(location)"
            >
                {{ location.name }}
                <i class="fa fa-circle text-{{location.isActive ? 'success': 'danger'}}"></i>
            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div *ngIf="locations.current$ | async as location" class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <span class="d-flex">
                    {{ location.name }}
                    <span class="badge badge-info mt-auto mb-auto ml-2">{{location.locationId}}</span>
                </span>

                <i class="fa fa-circle text-{{location.isActive ? 'success': 'danger'}}"></i>
            </div>
            <div class="card-body d-flex justify-content-between">
                <div>
                    <p>
                        {{location.address1}} <br>
                        {{location.city}} {{location.state}}, {{location.zip}}
                    </p>
                    {{location.phoneNumber1}}
                    {{location.phoneNumber2}}

                    {{location.emailAddress}}
                    {{location.webAddress}}

                    <p *ngIf="location.locationDetails">
                        {{location.locationDetails}}
                    </p>
                </div>
                <div class="w-50">
                    <p>
                        <strong>Lat:</strong>
                        {{location.latitude}}
                    </p>
                    <p>
                        <strong>Long:</strong>
                        {{location.longitude}}
                    </p>
                    <p>
                        <strong>Status:</strong>
                        {{location.status}}
                    </p>
                    <p>
                        <strong>Group:</strong>
                        {{location.clinicGroup}}
                    </p>
                </div>
            </div>
            <div class="card-footer text-right">
                <button class="btn btn-secondary mr-2" (click)="addEdit(location)">
                    <i class="fa fa-pencil"></i>
                </button>
                <button class="btn btn-danger" (click)="locations.delete(location)">
                    <i class="fa fa-trash"></i>
                </button>
            </div>
        </div>
    </div>
</div>
