import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { FileUpload } from './types';

const getBlobUrl = (containerClient: ContainerClient, name: string) =>
  containerClient.containerName.includes('https://')
      ? `${containerClient.containerName}/${name}`
      : `https://${containerClient.accountName}.blob.core.windows.net/${containerClient.containerName}/${name}`;

// Uploads the image to the container
export const createBlobInContainer = async (
  containerClient: ContainerClient,
  file: FileUpload,
  fileKey?: string
): Promise<string> => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = {
    blobHTTPHeaders: { blobContentType: file.type },
    ...(fileKey ? { tags: { fileKey } } : {}),
  };

  // upload file
  await blobClient.uploadData(file.data, options);
  return getBlobUrl(containerClient, file.name);
};

// Retrieves Url if the blob exists otherwise returns null
export const retrieveUrlIfExists = async (containerClient: ContainerClient, fileName: string) =>
  (await containerClient.getBlobClient(fileName).exists()) ? getBlobUrl(containerClient, fileName) : null;

// Finds blob by fileKey index, typically set on upload.
export const findBlobByFileKey = async (blobClient: BlobServiceClient, fileKey: string): Promise<string> => {
  for await (const blob of blobClient.findBlobsByTags(`fileKey='${fileKey}'`)) {
    return getBlobUrl(blobClient.getContainerClient(blob.containerName), blob.name);
  }
};

// Returns list of blobs in container to display
export const getBlobsInContainer = async (containerClient: ContainerClient): Promise<string[]> => {
  const returnedBlobUrls: string[] = [];

  // get list of blobs in container
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(getBlobUrl(containerClient, blob.name));
  }

  return returnedBlobUrls;
};
