import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Page} from '@briebug/ngrx-auto-entity';
import {ErrorReport} from '@models';
import * as moment from 'moment';

@Component({
    selector: 'wic-error-reports-table',
    templateUrl: './error-reports-table.component.html',
    styleUrls: ['./error-reports-table.component.scss']
})
export class ErrorReportsTableComponent implements OnInit {
    @Input() errorReports: ErrorReport[];
    @Input() showFilter = false;
    @Input() showToolbar = true;
    @Input() lite = false;
    @Input() currentPage: Page;
    @Input() totalCount: number;
    @Output() refreshed = new EventEmitter<{ page: number, criteria?: string, authority?: number, startDate?: string, endDate?: string }>();
    @Output() selected = new EventEmitter<ErrorReport>();

    lastCriteria = null;

    form: FormGroup;

    constructor(private builder: FormBuilder) {
    }

    ngOnInit() {
        this.buildForm(this.builder);
    }

    buildForm(builder: FormBuilder) {
        return builder.group({
            criteria: ['']
        });
    }

    refresh(page?) {
        if (this.lastCriteria.endDate && this.form.value.endDate) {
            const last = moment(this.lastCriteria.endDate);
            const current = moment(this.form.value.endDate);
            page = last.isAfter(current) ? 1 : page;
        }
        this.refreshed.emit({
            page: page || this.currentPage.page,
            ...this.lastCriteria,
        });
    }
}
