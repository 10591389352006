import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'DemoAccount',
  comparer: (a: DemoAccount, b: DemoAccount) => a.cardNumber.localeCompare(b.cardNumber, undefined, { numeric: true }),
})
export class DemoAccount {
  @Key cardNumber: string;
  name: string;
  authorityId: number;
  isCardActivated: boolean;
  groups?: DemoAccountBenefitGroup[];
}

export class DemoAccountBenefitGroup {
  @Key id: number;
  cardNumber?: string;
  beginDate: string;
  endDate: string;

  benefits: Benefit[];
}

export class Benefit {
  @Key id: number;
  groupId?: number;
  categoryId: number;
  subCategoryId: number;
  quantity: number;
  availableQuantity: number;
}
