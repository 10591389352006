import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MenuButton} from '@models';

@Component({
    selector: 'wic-authority-menu',
    templateUrl: './wic-authority-menu.component.html',
    styleUrls: ['./wic-authority-menu.component.sass'],
})
export class WicAuthorityMenuComponent {
    @Input() buttons: MenuButton[];
    @Output() refreshed = new EventEmitter();
    @Output() added = new EventEmitter();
    @Output() edited = new EventEmitter<MenuButton>();
    @Output() deleted = new EventEmitter();
    @Output() movedUp = new EventEmitter<MenuButton>();
    @Output() movedDown = new EventEmitter<MenuButton>();

    up(button: MenuButton) {
        if (button.order > 0) {
            this.movedUp.emit(button);
        }
    }
}
