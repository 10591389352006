<div class="card mt-4">
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                <h5 class="float-left mb-0" *ngIf="!showFilter">Sub Category Configurationsß</h5>
                <form [formGroup]="form" *ngIf="showFilter">
                    <div class="row">
                        <div class="col-6">
                            <ngx-bootstrap-multiselect
                                class="w-100"
                                [options]="categories"
                                [texts]="{defaultTitle: 'Select Category'}"
                                [settings]="{dynamicTitleMaxItems: 1, buttonClasses: 'btn btn-outline-secondary'}"
                                formControlName="category">
                            </ngx-bootstrap-multiselect>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-1" *ngIf="!showToolbar">
                <i class="fa fa-plus" (click)="added.emit()"></i>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="!!subCategoryConfigs?.length">
        <thead>
        <tr>
            <th>Category</th>
            <th>Sub Category</th>
            <th class="text-center">Image</th>
            <th class="text-right"><i class="fa fa-wrench"></i></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let config of filter(subCategoryConfigs)">
            <td>
                <span class="badge badge-info">{{ config.categoryId }}</span>&nbsp;
                <a [routerLink]="['/admin/shared/subcatconfig/view', config._id]">{{ config.categoryDescription | titlecase }}</a>
            </td>
            <td>
                <span class="badge badge-info">{{ config.subCategoryId }}</span>&nbsp;
                <a [routerLink]="['/admin/shared/subcatconfig/view', config._id]">{{ config.subCategoryDescription | titlecase }}</a>
            </td>
            <td class="text-center">
                <img *ngIf="config?.image" [src]="config.image" class="subcat-image"/>
            </td>
            <td class="text-right">
                <i class="fa fa-edit" (click)="edited.emit(config);"></i>
                <i class="fa fa-trash" (click)="deleted.emit(config);"></i>
            </td>
        </tr>
        </tbody>
        </table>
    </div>
    <div *ngIf="!subCategoryConfigs?.length" class="card-body">
        <p *ngIf="!isLoading"><em>There are no sub category configurations.</em></p>
        <p *ngIf="isLoading"><i class="fa fa-spin fa-spinner"></i> Loading sub category configs...</p>
    </div>
    <div class="card-footer" *ngIf="showToolbar">
        <div class="row">
            <div class="col-md-6">
                <!--<a class="btn btn-outline-secondary float-left" role="button"-->
                   <!--[download]="getFilename()" [href]="buildDownloadUri(demoAccounts)">-->
                    <!--<i class="fa fa-download"></i> Download as CSV-->
                <!--</a>-->
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
                <button class="btn btn-outline-primary" (click)="added.emit()">
                    <i class="fa fa-plus"></i> Add Config
                </button>
            </div>
        </div>
    </div>
</div>
