import {Action} from '@ngrx/store';

import {AuthorityType} from '@models';
import {type} from '../shared/utils';

export const DISMISS_AUTHORITY_TYPES_ERROR = type('[Authority Types] Dismiss error');
export const FETCH_AUTHORITY_TYPES = type('[Authority Types] Fetch Authority Types');
export const FETCH_AUTHORITY_TYPES_SUCCESS = type('[Authority Types] Fetch Authority Types succeeded');
export const FETCH_AUTHORITY_TYPES_FAILURE = type('[Authority Types] Fetch Authority Types failed');

export class DismissAuthorityTypesErrorAction implements Action {
    readonly type = DISMISS_AUTHORITY_TYPES_ERROR;
}

export class FetchAuthorityTypesAction implements Action {
    readonly type = FETCH_AUTHORITY_TYPES;
}

export class FetchAuthorityTypesSuccessAction implements Action {
    readonly type = FETCH_AUTHORITY_TYPES_SUCCESS;

    constructor(public payload: AuthorityType[]) {
    }
}

export class FetchAuthorityTypesFailureAction implements Action {
    readonly type = FETCH_AUTHORITY_TYPES_FAILURE;

    constructor(public payload: any) {
    }
}

export type AuthorityTypesActions =
    | DismissAuthorityTypesErrorAction
    | FetchAuthorityTypesAction
    | FetchAuthorityTypesFailureAction
    | FetchAuthorityTypesSuccessAction;
