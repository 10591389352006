import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Banner} from '@models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BannerService implements IAutoEntityService<Banner> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: { playlistId: number }): Observable<Banner[]> {
        return this.http.get<Banner[]>(environment.apiRoot +
            `/admin/banners/playlists/${criteria.playlistId}/entries`
        );
    }

    create(entityInfo: IEntityInfo, entity: Banner): Observable<Banner> {
        return this.http.post<Banner>(environment.apiRoot +
            `/admin/banners/playlists/${entity.PlaylistID}/entries`, entity
        );
    }

    update(entityInfo: IEntityInfo, entity: Banner): Observable<Banner> {
        return this.http.put<Banner>(environment.apiRoot +
            `/admin/banners/playlists/${entity.PlaylistID}/entries/${entity.BannerID}`, entity
        );
    }

    delete(entityInfo: IEntityInfo, entity: Banner): Observable<Banner> {
        return this.http.request<Banner>('DELETE', environment.apiRoot +
            `/admin/banners/playlists/${entity.PlaylistID}/entries/${entity.BannerID}`, {
            body: entity
        }).pipe(map(() => entity));
    }

    move(entity: Banner, newIndex: number): Observable<Banner[]> {
        return this.http.patch<Banner[]>(environment.apiRoot +
            `/admin/banners/playlists/${entity.PlaylistID}/entries/${entity.BannerID}`, entity, {
                params: {
                    to: newIndex.toString()
                }
            }
        );
    }
}
