import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MicroBlog} from '@models';

@Component({
    selector: 'wic-micro-blog-table',
    templateUrl: './wic-micro-blogs-table.component.html',
    styleUrls: ['./wic-micro-blogs-table.component.sass'],
})
export class WicMicroBlogTableComponent implements OnInit {
    @Input() isLoading: boolean;
    @Input() microBlogs: MicroBlog[];
    @Output() selected = new EventEmitter<MicroBlog>();
    @Output() added = new EventEmitter();
    @Output() deleted = new EventEmitter<MicroBlog>();
    @Output() refreshed = new EventEmitter();

    @Input() showToolbar = true;
    @Input() showFilter = true;

    form: FormGroup;
    constructor(private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.form = this.buildForm();
    }

    private buildForm() {
        return this.formBuilder.group({
        });
    }
}
