<div class="">
    <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="benefitImportJobs?.length">
        <thead>
        <tr>
            <th class="text-nowrap">Authority ID</th>
            <th class="text-nowrap">Username</th>
            <th class="text-nowrap text-center">Imported At</th>
            <th class="text-center">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let job of benefitImportJobs">
            <td class="">{{job.authorityId}}</td>
            <td class=""><a [routerLink]="[job._id]">{{job.username}}</a></td>
            <td class="text-center">{{job.timestamp}}</td>
            <td class="text-center">
                    <span class="badge badge-pill text-white {{ job.status | jobStatus}}">
                        <i *ngIf="job.succeeded === true" class="fa fa-check"></i>
                        <i *ngIf="job.succeeded === false" class="fa fa-times"></i>
                        {{job.status}}
                    </span>
            </td>
        </tr>
        </tbody>
    </table>
</div>
<div *ngIf="!benefitImportJobs?.length" class="card-body">
    <p><em>There are no benefit import logs.</em></p>
</div>
