import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'CardLegacyStatus',
})
export class CardLegacyStatus {
    // tslint:disable-next-line:variable-name
    @Key _id?: string;
    @Key cardNumber: string;
    jobId: string;
    status: CardLegacyImportStatus;
    details?: any;
    error?: any;

    delayed: boolean;
    timestamp: string;
}

export enum CardLegacyImportStatus {
    pending = 'PENDING',
    imported = 'IMPORTED',
    error = 'ERROR',
}
