<router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>

<div class="row" *ngIf="!hideSelf">
    <div class="col-md-12">
        <wic-benefits-table
            [benefits]="localBenefits.all$ | async"
            [authorities]="authorities.allAsLookup$ | async"
            [currentPage]="localBenefits.currentPage$ | async"
            [totalCount]="localBenefits.totalPageableCount$ | async"
            (refreshed)="refresh($event)">
        </wic-benefits-table>
    </div>
</div>
