import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbActiveModal, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import {MicroBlog} from '@models';
import {ILookup} from '@models/types';
import {UtilService} from '@services';

@Component({
    selector: 'wic-micro-blog-edit',
    templateUrl: './wic-micro-blog-edit.component.html',
    styleUrls: ['./wic-micro-blog-edit.component.sass']
})
export class WicMicroBlogEditComponent implements OnInit {
    @Input() microBlog: MicroBlog;
    @Input() authorities: ILookup[];
    @Input() groups: ILookup[];

    submitted = false;
    form: FormGroup;

    get f() {
        return this.form.controls;
    }

    constructor(private sanitizer: DomSanitizer,
                private fb: FormBuilder,
                public activeModal: NgbActiveModal,
                public util: UtilService,
                private ngbDateParser: NgbDateParserFormatter) {
    }

    ngOnInit() {
        this.form = this.buildForm();
        this.form.controls.authorities.valueChanges.subscribe(val => {
            console.log(val);
        });
        if (this.microBlog) {
            this.populateForm(this.microBlog);
        }
    }

    buildForm() {
        const form = this.fb.group({
            title: [null, Validators.required],
            type: [null, Validators.required],
            showTitleInSlide: [false, Validators.required],
            isActive: [true, Validators.required],
            activationDate: [null, Validators.required],
            expirationDate: [null, Validators.required],
            authorities: [[]],
            groups: [[]],
            group: [null]
        });

        return form;
    }

    populateForm(microBlog) {
        const patchVal = {
            ...microBlog,
            activationDate: (typeof microBlog.activationDate === 'string') ?
                this.ngbDateParser.parse(microBlog.activationDate) : microBlog.activationDate,
            expirationDate: (typeof microBlog.expirationDate === 'string') ?
                this.ngbDateParser.parse(microBlog.expirationDate) : microBlog.expirationDate,
            authorities: microBlog.authorities,
            groups: microBlog.groups
        };
        this.form.patchValue(patchVal);
    }

    save(form) {
        this.submitted = true;
        if (!form.valid) {
            return;
        }

        this.activeModal.close({
            ...this.microBlog,
            ...form.value,
            dateCreated: this.microBlog ? this.microBlog.dateCreated : moment().format(),
            group: undefined,
            groups: form.value.group ? [form.value.group] : form.value.groups,
            activationDate: this.ngbDateParser.format(form.value.activationDate),
            expirationDate: this.ngbDateParser.format(form.value.expirationDate)
        });
    }
}
