import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'EnhancedDevice',
    uriName: 'enhanced'
})
export class EnhancedDevice {
    @Key udid: string;
    // tslint:disable-next-line:variable-name
    _id: string;
    cordova: string;
    model: string;
    platform: string;
    uuid: string;
    version: string;
    manufacturer: string;
    isVirtual?: boolean;
    allowed: boolean;
    registeredAt: string;
}
