import { Injectable } from '@angular/core';
import {buildState, IEntityState} from '@briebug/ngrx-auto-entity';
import {APLImportConfig} from '@models/APLImportConfig';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, props, Store } from '@ngrx/store';
import { UPDATE_CONFIG_MANUAL_RUN, UpdateConfigManualRun} from '@state/apl-import.state';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { currentAvailableAppId } from './available-apps/available-apps.state';

export const loadImportConfig = createAction('[APLImportConfig] Load APLImportConfig', props<{ id: string }>())
export const loadImportConfigs = createAction('[APLImportConfig] Load APLImportConfigs');
export const createImportConfig = createAction('[APLImportConfig] Create APLImportConfig', props<{ aplImportConfig: APLImportConfig }>());
export const updateImportConfig = createAction('[APLImportConfig] Update APLImportConfig', props<{ aplImportConfig: APLImportConfig }>());
export const deleteImportConfig = createAction('[APLImportConfig] Delete APLImportConfig', props<{ aplImportConfig: APLImportConfig }>());


export const {
  initialState: initialAplImportConfigState,
  selectors: {
    selectAll: allAPLImportConfigs,
    selectIsLoading: isAPLImportConfigsLoading,
    selectCurrentEntity: currentAPLImportConfig,
  },
  actions: {
    load: loadAplImportConfig,
    loadAll: loadAllAplImportConfigs,
    create: createAplImportConfig,
    update: updateAplImportConfig,
    delete: deleteAplImportConfig,
  }
} = buildState(APLImportConfig);

export function aplImportConfigReducer(state = initialAplImportConfigState, action: UpdateConfigManualRun): IEntityState<APLImportConfig> {
    switch (action.type) {
        case UPDATE_CONFIG_MANUAL_RUN: {
            const entity = Object
                .values(state.entities)
                .find((e: APLImportConfig) => e.authorityId === action.authorityId) as APLImportConfig;
            const id = entity._id;
            return !id ? state : {
                ...state,
                entities: {
                    ...state.entities,
                    [id]: state.entities[id] ? {
                        ...state.entities[id],
                        manualRun: action.manualRun
                    } : state.entities[id]
                }
            };
        }
    }
    return state;
}

@Injectable()
export class APLImportConfigEffects {
  
  constructor(private actions$: Actions, private store: Store) {}
  
  loadImportConfig$ = createEffect(() => 
    this.actions$.pipe(
      ofType(loadImportConfig),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([{id} , appId]) => loadAplImportConfig({
        keys: id,
        criteria: appId
      }))
    )
  );

  loadImportConfigs$ = createEffect(() => 
    this.actions$.pipe(
      ofType(loadImportConfigs),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([, appId]) => loadAllAplImportConfigs({criteria: { appId }}))
  )
  );

  createImportConfig$ = createEffect(() => 
    this.actions$.pipe(
      ofType(createImportConfig),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([{aplImportConfig}, appId ]) => createAplImportConfig({
        entity: {...aplImportConfig, appId: +appId},
        criteria: appId
      }))
    )
  );

  updateImportConfig$ = createEffect(() => 
    this.actions$.pipe(
      ofType(updateImportConfig),
      withLatestFrom(this.store.select(updateImportConfig)),
      map(([{aplImportConfig}, appId]) => updateAplImportConfig({
        entity: aplImportConfig,
        criteria: appId
      }))
    )
  );

  deleteImportConfig$ = createEffect(() => 
    this.actions$.pipe(
      ofType(deleteImportConfig),
      withLatestFrom(this.store.select(currentAvailableAppId)),
      map(([{aplImportConfig}, appId]) => deleteAplImportConfig({
        entity: aplImportConfig,
        criteria: appId
      }))
    )
  );
}
