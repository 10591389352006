import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

import {APLMessagesFacade} from '@facades/apl-messages.facade';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {CategoryLookupsFacade} from '@facades/category-lookups.facade';
import {APLMessage, Authority, CategoryLookup, SubCategoryLookup} from '@models';

@Component({
    selector: 'wic-apl-messages',
    templateUrl: './wic-apl-messages.component.html',
    styleUrls: ['./wic-apl-messages.component.sass']
})
export class WicAplMessagesComponent implements OnInit, OnDestroy {
    hideSelf = false;

    forDeletion$: Observable<APLMessage>;

    private destroyed = new Subject();

    constructor(
        public aplMessages: APLMessagesFacade,
        private authorities: AuthoritiesFacade,
        public categoryLookups: CategoryLookupsFacade) {
    }

    ngOnInit() {
        this.forDeletion$ = this.aplMessages.forDeletion$.asObservable();

        this.categoryLookups.loadAll();
        this.aplMessages.loadAll();
        this.authorities.loadAll();
    }

    ngOnDestroy() {
        this.destroyed.next();
    }

    add() {
        this.aplMessages.showCreate();
    }

    delete(message: APLMessage) {
        this.aplMessages.showDelete(message);
    }

    refresh() {
        this.aplMessages.loadAll();
    }
}
