import {Injectable} from '@angular/core';
import {DeviceLogSession} from '@models';
import {Store} from '@ngrx/store';
import {AppState} from '@state/app.state';
import {DeviceLogSessionFacadeBase, sessionsByDeviceId} from '@state/device-log-session.state';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DeviceLogSessionFacade extends DeviceLogSessionFacadeBase {
    sessionsByDevice$(sessionId: string, udid: string): Observable<DeviceLogSession[]> {
        return this.store.select(sessionsByDeviceId, {sessionId, udid});
    }

    constructor(private store: Store<AppState>) {
        super(DeviceLogSession, store);
    }
}
