import { createAction, props } from "@ngrx/store";
import { EnhancedIssue } from "./enhanced-issue.model";


export const loadAllByAuthority = createAction('[Enhanced Issues] Load All By Authority', props<{ authorityId: number }>());

export const addEditEnhancedIssue = createAction('[Enhanced Issues] Add/Edit Enhanced Issue', props<{ enhancedIssue: EnhancedIssue }>());

export const confirmDeleteEnhancedIssue = createAction('[Enhanced Issues] Confirm Delete', props<{ enhancedIssue: EnhancedIssue }>());

export const moveOrderEnhancedIssue = createAction('[Enhanced Issues] Move Order', props<{ enhancedIssue: EnhancedIssue, newIndex: number }>())
