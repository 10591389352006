import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../../shared/components/components.module';
import { EnhancedIssuesAddEditComponent } from './enhanced-issues-add-edit-modal/enhanced-issues-add-edit-modal.component';
import { EnhancedIssueTranslationsModule } from '../wic-enhanced-issue-translations/wic-enhanced-issue-translations.module';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, ComponentsModule, EnhancedIssueTranslationsModule],
    declarations: [EnhancedIssuesAddEditComponent],
    exports: [EnhancedIssuesAddEditComponent],
})
export class EnhancedIssuesModalModule {
}
