import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplicateCard } from '@state/demo-accounts/models/replicate-card.model';
import { from, Observable } from 'rxjs';
import { ReplicateCardModalComponent } from '../../wic-admin/wic-demo-accounts/replicate-card-modal/replicate-card-modal.component';

@Injectable()
export class DemoAccountsUIService {
  constructor(private modals: NgbModal) {}

  showReplicateAgencyCard(authorityId?: number): Observable<ReplicateCard> {
    const modal = this.modals.open(ReplicateCardModalComponent);
    modal.componentInstance.authorityId = authorityId;
    return from(modal.result);
  }
}
