import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthFacade } from '@facades/auth.facade';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AdminRequiredGuard implements CanActivate {
  constructor(private auth: AuthFacade, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.claims$.pipe(
      map(claims => !!claims?.roles?.includes('admin')),
      tap(canActivate => canActivate || this.router.navigate(['/not-found']))
    );
  }
}
