import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as R from 'ramda';
import {SimpleData} from '@models';

@Component({
    selector: 'wic-stacked-bar-simple',
    templateUrl: './stacked-bar-simple.component.html',
    styleUrls: ['./stacked-bar-simple.component.sass'],
})
export class StackedBarSimpleComponent implements OnInit, OnChanges {
    @Input() data: SimpleData[];
    @Input() labels: string[];
    @Input() easing = 'linear';
    @Input() duration = 0;
    @Input() chartTitle: string;

    dataSets: any[];
    barLabels: Array<string | number>;
    colors: string[] = ['#75828a'];

    constructor() {
    }

    ngOnInit() {
        this._transformData();
    }

    ngOnChanges(changes: SimpleChanges) {
        this._transformData();
    }

    private _transformData() {
        if (this.data) {
            this.dataSets = this.data.map(d => ({
                label: d.label,
                data: d.data,
                backgroundColor: d.color,
            }));

            if (this.data.length && this.data[0].data) {
                this.barLabels = this.labels || R.range(1, this.data[0].data.length).reverse();
                this.colors = this.data.map(d => d.color);
            } else {
                this.barLabels = this.labels || [];
                this.colors = [];
            }
        }
    }
}
