import { ReportingJobStatus, StatisticsReportingJob } from '@state/reporting/statistics-reporting-job.entity';
import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import {
  createReportingJob, createReportingJobFailure,
  createReportingJobSuccess, pollReportingJobFailure,
  pollReportingJobSuccess,
} from '@state/reporting/statistics-reporting-jobs.actions';
import uuid from 'uuid/v4';

export interface StatisticsReportingJobsState {
  criteria?: { beginDate: string, endDate: string };
  correlationId?: string;
  reportingJob?: StatisticsReportingJob;
  isLoading: boolean;
  failed: boolean;
  loadStart?: Date;
  loadEnd?: Date;
}

export const initialStatisticsReportingJobsState: StatisticsReportingJobsState = {
  isLoading: false,
  failed: false,
}

const reduce = createReducer(
  initialStatisticsReportingJobsState,
  on(createReportingJob, (state, {beginDate, endDate}) => ({
    ...state,
    isLoading: true,
    failed: false,
    loadStart: new Date(),
    loadEnd: undefined,
    criteria: { beginDate, endDate },
    correlationId: uuid()
  })),
  on(pollReportingJobSuccess, createReportingJobSuccess, (state, {reportingJob}) => ({
    ...state,
    reportingJob,
    isLoading: ![ReportingJobStatus.FAILED, ReportingJobStatus.FINISHED].includes(reportingJob.status),
    loadEnd: new Date(),
  })),
  on(pollReportingJobFailure, createReportingJobFailure, (state) => ({
    ...state,
    failed: true,
    isLoading: false,
    loadEnd: new Date(),
  })),
);

export function statisticsReportingJobsReducer(state = initialStatisticsReportingJobsState, action: Action) {
  return reduce(state, action);
}

export const STATISTICS_REPORTING_JOBS_FEATURE_KEY = 'statisticsReportingJob'

export const statisticsReportingJobsState = createFeatureSelector<StatisticsReportingJobsState>(STATISTICS_REPORTING_JOBS_FEATURE_KEY);
