<div class="card mt-5">
    <div class="card-body">
        <!--<h6 class="card-title text-muted text-right">Authority Distributions</h6>-->
        <div class="row">
            <div class="col-md-3 text-center text-nowrap mb-1">
                <wic-doughnut-labeled
                    *ngIf="!!aplImportStatusCounts"
                    [data]="[aplImportStatusCounts.skipped,aplImportStatusCounts.failed,aplImportStatusCounts.succeeded]"
                    [labels]="['Skipped','Failed', 'Succeeded']"
                    [colors]="['#d9cc41','#ff8680','#7cda87']"
                    chartTitle="Imports Status">
                </wic-doughnut-labeled>
            </div>
            <div class="col-md-3 text-center text-nowrap mb-1">
                <wic-stacked-bar-simple
                    *ngIf="!!itemCounts"
                    [data]="[{data: itemCounts, label: 'Items', color: '#d9cc41'}]"
                    [labels]="itemLabels"
                    chartTitle="Items by Authority">
                </wic-stacked-bar-simple>
            </div>
            <div class="col-md-3 text-center text-nowrap mb-1">
                <wic-stacked-bar-simple
                    *ngIf="!!categoriesCounts && !!categoriesLabels"
                    [data]="[{data: categoriesCounts, label: 'Authorities with', color: '#8a8a8a'}]"
                    [labels]="categoriesLabels"
                    chartTitle="Category Counts">
                </wic-stacked-bar-simple>
            </div>
            <div class="col-md-3 text-center text-nowrap mb-1">
                <wic-stacked-bar-simple
                    *ngIf="!!subCategoriesCounts && !!subCategoriesLabels"
                    [data]="[{data: subCategoriesCounts, label: 'Authorities with', color: '#8a8a8a'}]"
                    [labels]="subCategoriesLabels"
                    chartTitle="Subcategory Counts">
                </wic-stacked-bar-simple>
            </div>
        </div>
    </div>
</div>
