import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthFacade } from '@facades/auth.facade';
import { Store } from '@ngrx/store';
import { IAppState } from '@state/app.state';

@Component({
  selector: 'wic-login',
  templateUrl: './wic-login.component.html',
  styleUrls: ['./wic-login.component.sass'],
})
export class WicLoginComponent {
  constructor(private store: Store<IAppState>, private route: ActivatedRoute, private auth: AuthFacade) {}

  login() {
    this.auth.login(this.route.snapshot.queryParams.previousUrl);
  }
}

export let LoginRoutes = [
  {
    path: 'login',
    component: WicLoginComponent,
    data: { title: 'Login', isMenuItem: false },
  },
];
