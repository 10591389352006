import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {APLJob} from '@models/APLJob';

@Component({
    selector: 'wic-apl-import-job-error-detail',
    templateUrl: './wic-apl-import-job-error-detail.component.html',
    styleUrls: ['./wic-apl-import-job-error-detail.component.sass']
})
export class WicAplImportJobErrorDetailComponent implements OnChanges {
    @Input() job: APLJob;

    props: Array<{ name: string, value: any, isObject: boolean }>;

    ngOnChanges() {
        if (this.job && this.job.error) {
            this.props = Object.keys(this.job.error).map(key => ({
                name: key,
                value: this.job.error[key],
                isObject: typeof this.job.error[key] === 'object'
            }));
        } else {
            this.props = null;
        }
    }
}
