import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Authority} from '@models';
import {
  AuthorityActions,
  CREATE_AUTHORITY_SUCCESS, createAuthoritySuccess,
  DELETE_AUTHORITY_SUCCESS,
  FETCH_AUTHORITIES_SUCCESS,
  FETCH_AUTHORITY_SUCCESS,
  SELECT_AUTHORITY,
  SELECT_AUTHORITY_BY_ID,
  SET_AUTHORITY_LOGO_SUCCESS,
  UPDATE_AUTHORITY_SUCCESS,
} from './authorities.actions';
import { createReducer, on } from '@ngrx/store';

export interface IAuthoritiesState extends EntityState<Authority> {
    selectedAuthorityId?: number;
}

export const adapter: EntityAdapter<Authority> = createEntityAdapter({
    selectId: (authority: Authority) => authority.id,
});

const initialState: IAuthoritiesState = adapter.getInitialState({
    selectedAuthorityId: null,
});

const reduce = createReducer(initialState,
  on(createAuthoritySuccess, (state, {authority}) => adapter.addOne(authority, state))
)

export function authoritiesReducer(state: IAuthoritiesState = initialState, action: AuthorityActions): IAuthoritiesState {
    switch (action.type) {
        case SELECT_AUTHORITY:
            return {...state, selectedAuthorityId: action.payload ? action.payload.id : null};
        case SELECT_AUTHORITY_BY_ID:
            return {...state, selectedAuthorityId: action.payload};
        case CREATE_AUTHORITY_SUCCESS:
            return adapter.addOne(action.payload, {...state, selectedAuthorityId: null});
        case DELETE_AUTHORITY_SUCCESS:
            return adapter.removeOne(action.payload, {...state, selectedAuthorityId: null});
        case FETCH_AUTHORITIES_SUCCESS:
            return adapter.addAll(action.payload, state);
        case SET_AUTHORITY_LOGO_SUCCESS:
        case FETCH_AUTHORITY_SUCCESS:
            return adapter.updateOne({
                id: action.payload.id,
                changes: action.payload,
            }, state);
        case UPDATE_AUTHORITY_SUCCESS:
            return adapter.updateOne({
                id: action.payload.id,
                changes: action.payload,
            }, state);
        default:
            return reduce(state, action);
    }
}
