import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceLogSessionFacade} from '@facades/device-log-session.facade';
import {ErrorReportsFacade} from '@facades/error-reports.facade';
import {DeviceLogSession} from '@models';
import {Observable, Subject} from 'rxjs';
import {filter, map, switchMap, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'wic-error-report-view',
    templateUrl: './error-report-view.component.html',
    styleUrls: ['./error-report-view.component.scss']
})
export class ErrorReportViewComponent implements OnInit, OnDestroy {
    logSessions$: Observable<DeviceLogSession[]>;

    private signal = new Subject();

    constructor(
        public errorReports: ErrorReportsFacade,
        public deviceLogs: DeviceLogSessionFacade,
        private route: ActivatedRoute,
        private router: Router) {
    }

    ngOnInit() {
        this.route.paramMap.pipe(
            filter(params => params.has('id')),
            map(params => params.get('id')),
            takeUntil(this.signal)
        ).subscribe(id => this.errorReports.selectByKey(id));

        this.logSessions$ = this.errorReports.current$.pipe(
            switchMap(report => this.deviceLogs.sessionsByDevice$(report.sessionId, report.uniqueDeviceId))
        );
    }

    ngOnDestroy(): void {
        this.signal.next();
        this.signal.complete();
    }

    viewLogSession(session: DeviceLogSession) {
        this.router.navigate(['../../logs', session.udid, 'sessions', session.sessionId], {
            relativeTo: this.route
        });
    }
}
