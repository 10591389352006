import { Component, Input, OnInit } from '@angular/core';
import { ILookup } from '@models/types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguagesFacade } from 'app/translations/feature/languages/languages.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'wic-select-item-modal',
  templateUrl: './wic-select-item-modal.component.html',
  styleUrls: ['./wic-select-item-modal.component.sass']
})
export class WicSelectItemModalComponent implements OnInit {
  isLoading$ = this.language.isLoading$;

  @Input() modalTitle: string;
  @Input() label: string;
  @Input() items$: Observable<ILookup[]>;

  value: ILookup;

  constructor(public activeModal: NgbActiveModal, public language: LanguagesFacade) {}
  
  ngOnInit() {
    this.language.loadAllIfNecessary();
  }
}
