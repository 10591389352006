<div class="row mt-2">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                <span *ngIf="article?.slug">URL: <a href="/admin/wiki/articles/{{article.slug}}">/admin/wiki/articles/{{article.slug}}</a></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h2 class="text-muted">{{article?.title}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="markdown mt-3 pt-3">
                    <ngx-md [data]="article?.markdown"></ngx-md>
                </div>
            </div>
        </div>
    </div>
</div>
