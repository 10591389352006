import {Component, OnInit} from '@angular/core';
import {LoadAll} from '@briebug/ngrx-auto-entity';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {filter, takeWhile, tap} from 'rxjs/operators';

import {SubCategoryConfigsFacade} from '@facades/sub-category-configs.facade';
import {CategoryLookup, SubCategoryConfig, SubCategoryLookup} from '@models';
import {ILookup} from '@models/types';
import {IAppState} from '@state/app.state';
import {allCategoryLookups, categoryLookupsAsLookup} from '@state/category-lookups.state';
import {allSubCategoryConfigs, isSubCategoryConfigsLoading} from '@state/sub-category-configs.state';
import {allSubCategoryLookups} from '@state/sub-category-lookup.state';

@Component({
    templateUrl: './wic-sub-category-configs.component.html',
    styleUrls: ['./wic-sub-category-configs.component.sass'],
})
export class WicSubCategoryConfigsComponent implements OnInit {
    isLoading$: Observable<boolean>;
    subCategoryConfigs$: Observable<SubCategoryConfig[]>;
    categoriesLookup$: Observable<ILookup[]>;
    categories: CategoryLookup[];
    subCategories: SubCategoryLookup[];

    hideSelf = false;

    private subCategoryConfigs: SubCategoryConfig[];

    constructor(private store: Store<IAppState>,
                private modalService: NgbModal,
                private subCategoryConfigFacade: SubCategoryConfigsFacade) {
    }

    ngOnInit() {
        this.refresh();

        this.isLoading$ = this.store.pipe(select(isSubCategoryConfigsLoading));
        this.subCategoryConfigs$ = this.store.pipe(
            select(allSubCategoryConfigs),
            tap(configs => this.subCategoryConfigs = configs)
        );
        this.categoriesLookup$ = this.store.pipe(select(categoryLookupsAsLookup));

        let awaitingLists = true;
        combineLatest([
            this.store.pipe(select(allCategoryLookups)),
            this.store.pipe(select(allSubCategoryLookups))
        ]).pipe(
            filter(([categories, subCategories]) => !!categories.length && !!subCategories.length),
            takeWhile(() => awaitingLists)
        ).subscribe(([categories, subCategories]) => {
            this.categories = categories;
            this.subCategories = subCategories;
            awaitingLists = false;
        });
    }

    refresh() {
        this.subCategoryConfigFacade.loadAll();
        this.store.dispatch(new LoadAll(CategoryLookup));
        this.store.dispatch(new LoadAll(SubCategoryLookup));
    }

    add() {
        this.subCategoryConfigFacade.showCreate(this.subCategoryConfigs, this.categories, this.subCategories);
    }

    edit(config: SubCategoryConfig) {
        this.subCategoryConfigFacade.showEdit(config, this.subCategoryConfigs, this.categories, this.subCategories);
    }

    save(subCatConfig: SubCategoryConfig) {
        if (subCatConfig._id) {
            this.subCategoryConfigFacade.update(subCatConfig);
        } else {
            this.subCategoryConfigFacade.create(subCatConfig);
        }
    }

    delete(config: SubCategoryConfig) {
        this.subCategoryConfigFacade.showDelete(config);
    }
}
