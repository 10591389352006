<div>
    <div class="row mb-5">
        <div class="col-md-12">
            <wic-device-log-entries-table
                [session]="sessions.current$ | async"
                [entries]="sortedEntries$ | async"
                [isLoading]="entries.isLoading$ | async"
                [filteredLevels]="entries.currentLevels$ | async"
                [filteredTags]="entries.currentTags$ | async"
                (levelHidden)="entries.hideLevel($event)"
                (levelShown)="entries.showLevel($event)"
                (tagAdded)="entries.addTag($event)"
                (tagRemoved)="entries.removeTag($event)">
            </wic-device-log-entries-table>
        </div>
    </div>
</div>
