import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ReportingService} from '@services';
import {
    errorLoadingModernStatisticsReport,
    loadModernStatisticsReport,
    modernStatisticsReportLoaded
} from '@state/reporting/modernstatistics.state';
import {of} from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { currentAvailableAppId } from '@state/available-apps/available-apps.state';
import { AvailableApps } from '@state/available-apps/models/available-app.entity';

@Injectable({providedIn: 'root'})
export class ModernstatisticsEffects {
    constructor(private actions$: Actions, private store: Store, private reporting: ReportingService) {
    }

    loadModernStatisticsReport$ = createEffect(
        () => this.actions$.pipe(
            ofType(loadModernStatisticsReport),
            withLatestFrom(this.store.select(currentAvailableAppId)),
            switchMap(([{startDate, endDate}, appId]) =>
                this.reporting.modernReport(appId as AvailableApps, startDate, endDate).pipe(
                    map(report => modernStatisticsReportLoaded({report})),
                    catchError(error => of(errorLoadingModernStatisticsReport({error})))
                )
            )
        )
    );
}
