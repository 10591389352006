import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {BannerPlaylist} from '@models';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BannerPlaylistService implements IAutoEntityService<BannerPlaylist> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: { appId: string }): Observable<BannerPlaylist[]> {
        return this.http.get<BannerPlaylist[]>(environment.apiRoot +
            `/admin/apps/${criteria.appId}/banners/playlists`
        );
    }

    create(entityInfo: IEntityInfo, entity: BannerPlaylist, criteria: { appId: string}): Observable<BannerPlaylist> {
        return this.http.post<BannerPlaylist>(environment.apiRoot +
            `/admin/apps/${criteria.appId}/banners/playlists`, entity
        );
    }

    update(entityInfo: IEntityInfo, entity: BannerPlaylist, criteria: { appId: string }): Observable<BannerPlaylist> {
      return this.http.put<BannerPlaylist>(environment.apiRoot +
        `/admin/apps/${criteria.appId}/banners/playlists/${entity.PlaylistID}`, entity
      );
    }

    delete(entityInfo: IEntityInfo, entity: BannerPlaylist, criteria: { appId: string }): Observable<BannerPlaylist> {
        return this.http.delete<BannerPlaylist>(environment.apiRoot +
            `/admin/apps/${criteria.appId}/banners/playlists/${entity.PlaylistID}`,
        ).pipe(map(() => entity));
    }
}
