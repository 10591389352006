import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {DeviceLogSession} from '@models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

export interface SessionKey {
    udid: string;
    sessionId: string;
}

export interface SessionCriteria {
    criteria?: string;
    startDate?: string;
    endDate?: string;
}

@Injectable({providedIn: 'root'})
export class DeviceLogSessionService implements IAutoEntityService<DeviceLogSession> {
    constructor(private http: HttpClient) {
    }

    load(info: IEntityInfo, key: SessionKey): Observable<DeviceLogSession> {
        return this.http.get<DeviceLogSession>(`${environment.apiRoot}/devices/${key.udid}/logs/sessions/${key.sessionId}`);
    }

    loadAll(info: IEntityInfo, criteria: SessionCriteria = {}): Observable<DeviceLogSession[]> {
        return this.http.get<DeviceLogSession[]>(`${environment.apiRoot}/devices/logs/sessions`, {
            params: {
                ...(criteria.criteria && {criteria: criteria.criteria}),
                ...(criteria.startDate && {startDate: criteria.startDate}),
                ...(criteria.endDate && {endDate: criteria.endDate})
            }
        });
    }

    loadMany(info: IEntityInfo, criteria: SessionCriteria): Observable<DeviceLogSession[]> {
        return this.http.get<DeviceLogSession[]>(`${environment.apiRoot}/devices/logs/sessions`, {
            params: {
                ...(criteria.criteria && {criteria: criteria.criteria}),
                ...(criteria.startDate && {startDate: criteria.startDate}),
                ...(criteria.endDate && {endDate: criteria.endDate})
            }
        });
    }

    delete(entityInfo: IEntityInfo, entity: DeviceLogSession, criteria?: any): Observable<DeviceLogSession> {
        return this.http.delete(`${environment.apiRoot}/devices/${entity.udid}/logs/sessions/${entity.sessionId}`).pipe(
            map(() => entity)
        );
    }
}
