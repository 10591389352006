import {buildState, IEntityState, Load, LoadAll, SelectByKey} from '@briebug/ngrx-auto-entity';
import {BenefitImportJob} from '@models/BenefitImportJob';
import {Action, createReducer, createSelector, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {ROUTER_NAVIGATION} from '@ngrx/router-store';
import {extractAllParamsMatching, routeIncludesPath} from '../../util/operators';
import {benefitImportsRouteVisit, refreshBenefitImportJob} from '@state/benefit-import-job/benefit-import-job.actions';
import {iif, interval, of} from 'rxjs';
import {IAppState} from '@state/app.state';

export interface BenefitImportJobState extends IEntityState<BenefitImportJob> {
    filterCriteria?: string;
}

export const {
    initialState: initialBenefitImportJobState,
    entityState: benefitImportJobState,
    selectors: {
        selectAll: allBenefitImportJobs,
        selectCurrentEntity: currentBenefitImportJob,
        selectCurrentEntityKey: currentBenefitImportJobKey
    }
} = buildState(BenefitImportJob, {} as BenefitImportJobState);

export const currentJobProgress = createSelector(
    currentBenefitImportJob,
    job => job && job.progress
);

const reduce = createReducer(
    initialBenefitImportJobState,
);

export function benefitImportJobReducer(state = initialBenefitImportJobState, action: Action): BenefitImportJobState {
    return reduce(state, action);
}

@Injectable()
export class BenefitImportJobEffects {
    listenForRouteVisit$ = createEffect(() => this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        routeIncludesPath('benefits/imports'),
        extractAllParamsMatching('id'),
        map((params) => params['imports']), // tslint:disable-line:no-string-literal
        map((id) => benefitImportsRouteVisit({id})),
    ));

    autoReloadImportJob$ = createEffect(() => this.actions$.pipe(
        ofType(benefitImportsRouteVisit),
        filter(({id}) => !!id),
        switchMap(({id}) =>
            // Possibly move timer to state, that way it can be adjusted
            interval(30000).pipe(
                map(() => new Load(BenefitImportJob, id)),
                takeUntil(this.actions$.pipe(ofType(benefitImportsRouteVisit)))
            )
        )
    ));

    reloadBenefitImportJob$ = createEffect(() => this.actions$.pipe(
        ofType(refreshBenefitImportJob),
        withLatestFrom(this.store.select(currentBenefitImportJobKey)),
        map(([, id]) => new Load(BenefitImportJob, id))
    ));

    loadBenefitImportJob$ = createEffect(() => this.actions$.pipe(
        ofType(benefitImportsRouteVisit),
        switchMap(({id}) =>
            iif(() => !!id,
                of(new Load(BenefitImportJob, id)),
                of(new LoadAll(BenefitImportJob))
            )
        ),
    ));

    selectBenefitImportJob$ = createEffect(() => this.actions$.pipe(
        ofType(benefitImportsRouteVisit),
        filter(({id}) => !!id),
        map(({id}) => new SelectByKey(BenefitImportJob, id))
    ));

    constructor(
        private readonly actions$: Actions,
        private store: Store<IAppState>) {
    }
}
