import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {APLMessage, CategoryLookup, SubCategoryLookup} from '@models';

@Component({
    selector: 'wic-apl-messages-table',
    templateUrl: './wic-apl-messages-table.component.html',
    styleUrls: ['./wic-apl-messages-table.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WicAplMessagesTableComponent implements OnInit {
    @Input() messages: APLMessage[];
    @Input() categories: CategoryLookup[];
    @Input() subCategories: SubCategoryLookup[];
    @Input() forDeletion: APLMessage;

    @Output() added = new EventEmitter();
    @Output() deleted = new EventEmitter<APLMessage>();
    @Output() refreshed = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    getCategory(categoryId: number): string {
        const category = ((this.categories && this.categories.length) ?
            this.categories.find(c => c.categoryId === categoryId) || {} : {}) as CategoryLookup;
        return category.description;
    }

    getSubCategory(categoryId: number, subCategoryId: number): string {
        const subCategory = ((this.subCategories && this.subCategories.length) ?
            this.subCategories.find(sc =>
                sc.categoryId === categoryId && sc.subCategoryId === subCategoryId) || {} : {}
            ) as SubCategoryLookup;
        return subCategory.description;
    }
}
