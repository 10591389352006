import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {MenuButton} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '@services';

@Component({
    selector: 'wic-authority-menu-edit',
    templateUrl: './wic-authority-menu-edit.component.html',
    styleUrls: ['./wic-authority-menu-edit.component.sass']
})
export class WicAuthorityMenuEditComponent implements OnInit {
    @Input() button: MenuButton;
    menuButtonUrl: string;
    imageDataUrl: SafeResourceUrl;

    get f() {
        return this.form.controls;
    }

    form: FormGroup;
    submitted = false;

    constructor(
        private sanitizer: DomSanitizer,
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public util: UtilService
    ) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);

        this.populateForm(this.button);
        this.menuButtonUrl = this.createMenuButtonUrl(this.button);
    }

    private buildForm(formBuilder) {
        const form = formBuilder.group({
            text: [null, Validators.required],
            url: [null, Validators.required],
            imageDataUrl: [undefined],
            isActive: [true]
        });

        return form;
    }

    populateForm(button) {
        this.form.patchValue({
            ...button,
        });
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        const button = {
            ...this.button,
            ...value,
        };
        this.activeModal.close(button);
    }

    imageChanged(event) {
        if (!event.target.files.length) {
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            this.form.patchValue({imageDataUrl: reader.result});
            this.imageDataUrl = this.createImageUrl(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    createImageUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    createMenuButtonUrl(button) {
        const rand = Math.random();
        return '/api/v1/menus/buttons/' + button.linkId + '?cache=no&' + rand;
    }
}
