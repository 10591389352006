import {Injectable} from '@angular/core';
import {IAppState} from '@state/app.state';
import {Store} from '@ngrx/store';
import {allCardStatuses} from '@state/card-status/card-status.state';
import {allCardLegacyStatuses} from '@state/card-status/card-legacy-status.state';
import {CardStatusVirtualSource} from '@state/card-status/card-status-virtual.selectors';
import {
    legacyStatusFiltersUpdated,
    loadCardLegacyStatusRange,
    loadCardStatusRange,
    statusFiltersUpdated,
} from '@state/card-status/card-status.actions';
import {
    cardLegacyStatusFilters,
    cardLegacyStatusLength,
    cardStatusFilters,
    cardStatusLength
} from '@state/card-status/card-status.selectors';

@Injectable({
    providedIn: 'root'
})
export class CardStatusFacade {
    cardStatuses$ = this.store.select(allCardStatuses);
    cardStatusFilters$ = this.store.select(cardStatusFilters);
    cardStatusLength$ = this.store.select(cardStatusLength);
    cardStatusesVirtualSource$ = new CardStatusVirtualSource(this.store, allCardStatuses, loadCardStatusRange);
    cardLegacyStatuses$ = this.store.select(allCardLegacyStatuses);
    cardLegacyStatusFilters$ = this.store.select(cardLegacyStatusFilters);
    cardLegacyStatusLength$ = this.store.select(cardLegacyStatusLength);
    cardLegacyStatusesVirtualSource$ = new CardStatusVirtualSource(this.store, allCardLegacyStatuses, loadCardLegacyStatusRange);

    constructor(private store: Store<IAppState>) {
    }

    updateStatusFilters(statusFilters: string[]) {
        this.store.dispatch(statusFiltersUpdated({statusFilters}));
    }

    updateLegacyStatusFilters(statusFilters: string[]) {
        this.store.dispatch(legacyStatusFiltersUpdated({statusFilters}));
    }
}
