import {buildState} from '@briebug/ngrx-auto-entity';
import {createSelector} from '@ngrx/store';

import {AppointmentReminder} from '@models';

const {initialState, selectors, entityState} = buildState(AppointmentReminder);

export const {
    selectAll: allAppointmentReminders
} = selectors;

export const appointmentRemindersPage = createSelector(entityState, state => {
    return (state && state.currentPage) ? state.currentPage.page || 1 : 1;
});
export const appointmentRemindersPageableCount = createSelector(entityState, state => {
    return state ? state.totalPageableCount : 0;
});

export function appointmentReminderReducer(state = initialState) {
    return state;
}
