import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LocalBenefit} from '@models/LocalBenefit';
import {ILookup} from '@models/types';
import {debounceTime} from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'wic-benefits-table',
  templateUrl: './wic-benefits-table.component.html',
  styleUrls: ['./wic-benefits-table.component.sass']
})
export class WicBenefitsTableComponent implements OnInit {
    @Input() benefits: LocalBenefit[];
    @Input() authorities: ILookup[];
    @Input() currentPage: number;
    @Input() totalCount: number;
    @Output() refreshed = new EventEmitter<{page: number, criteria?: string, authority?: number, startDate?: string, endDate?: string}>();

    lastCriteria = null;

    form: FormGroup;

    constructor(private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        const today = moment();
        this.lastCriteria = {
            startDate: {
                year: today.year(),
                month: today.month() + 1,
                day: 1,
            },
        };

        this.form = this.buildForm();
        this.form.patchValue(this.lastCriteria);

        this.form.valueChanges.pipe(debounceTime(700)).subscribe(() => {
            this.lastCriteria = {
                ...this.form.value,
                authority: this.form.value.authority && this.form.value.authority.length ? this.form.value.authority[0] : 0
            };
            this.refresh();
        });
    }

    private buildForm() {
        return this.formBuilder.group({
            criteria: null,
            authority: null,
            startDate: null,
            endDate: null,
        });
    }

    refresh(page?) {
        if (this.lastCriteria.endDate && this.form.value.endDate) {
            const last = moment(this.lastCriteria.endDate);
            const current = moment(this.form.value.endDate);
            page = last.isAfter(current) ? 1 : page;
        }
        this.refreshed.emit({
            page: page || this.currentPage,
            ...this.lastCriteria,
        });
    }
}
