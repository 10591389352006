<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <span class="badge badge-info" *ngIf="microBlog?._id">
                {{microBlog._id}}
            </span>
            {{microBlog?._id ? 'Edit' : 'Create'}} Micro Blog
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label for="title" class="col-5 col-lg-2 col-form-label" [class.text-danger]="submitted && f.title.invalid">Title</label>
            <div class="col-7 col-lg-10">
                <input class="form-control" formControlName="title" id="title" required
                       [class.is-invalid]="submitted && f.title.invalid">
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isActive" formControlName="isActive">
                    <label class="form-check-label" for="isActive">Activate this blog entry</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="showTitleInSlide"
                           formControlName="showTitleInSlide">
                    <label class="form-check-label" for="showTitleInSlide">Show title in slide</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="type" class="col-5 col-lg-2 col-form-label" [class.text-danger]="submitted && f.type.invalid">Type</label>
            <div class="col-7 col-lg-10">
                <select class="form-control"
                        [class.is-invalid]="submitted && !form.get('type').valid"
                        formControlName="type" id="type" required>
                    <option value="" disabled>Select Type</option>
                    <option value="post">Post</option>
                    <option value="deck">Deck</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="groups" class="col-5 col-lg-2 col-form-label" [class.text-danger]="submitted && f.title.invalid">Group</label>
            <div class="col-3 col-lg-5">
                <ngx-bootstrap-multiselect
                    [options]="groups"
                    [texts]="{defaultTitle: 'Select Group'}"
                    [settings]="{
                        selectionLimit: 1,
                        autoUnselect: true,
                        closeOnSelect: true,
                        dynamicTitleMaxItems: 1,
                        containerClasses: 'w-100',
                        buttonClasses: 'btn btn-outline-secondary w-100 text-left'
                    }"
                    formControlName="groups">
                </ngx-bootstrap-multiselect>
            </div>
            <div class="col-1">Or</div>
            <div class="col-3 col-lg-4">
                <input class="form-inline form-control" placeholder="New Group Name" formControlName="group" id="group">
            </div>
        </div>
        <div class="form-group row">
            <label for="type" class="col-5 col-lg-2 col-form-label" [class.text-danger]="submitted && f.type.invalid">Authorities</label>
            <div class="col-7 col-lg-10">
                <ngx-bootstrap-multiselect
                    [options]="authorities"
                    [texts]="{defaultTitle: 'Select Authority'}"
                    [settings]="{
                        dynamicTitleMaxItems: 1,
                        containerClasses: 'w-100',
                        buttonClasses: 'btn btn-outline-secondary w-100 text-left'
                    }"
                    formControlName="authorities">
                </ngx-bootstrap-multiselect>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-5 col-lg-2 col-form-label"></div>
            <div class="col-7 col-lg-10">
                <small class="text-muted">Leave authorities empty to apply to all authorities.</small>
            </div>
        </div>
        <div class="form-group row">
            <label for="activationDate" class="col-5 col-lg-2 col-form-label"
                   [class.text-danger]="submitted && f.activationDate.invalid">Activate On</label>
            <div class="col-7 col-lg-10 input-group">
                <input class="form-control" [class.is-invalid]="submitted && f.activationDate.invalid" required
                       placement="left" tooltipClass="form-error"
                       ngbTooltip="{{(submitted && f.activationDate.invalid) ? util.formatError(f.activationDate.errors) : null}}"
                       placeholder="yyyy-mm-dd" id="activationDate" name="activationDate"
                       formControlName="activationDate" ngbDatepicker #activationDate="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="activationDate.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="expirationDate" class="col-5 col-lg-2 col-form-label"
                   [class.text-danger]="submitted && f.expirationDate.invalid">Expire On</label>
            <div class="col-7 col-lg-10 input-group">
                <input class="form-control" [class.is-invalid]="submitted && f.expirationDate.invalid" required
                       placement="left" tooltipClass="form-error"
                       ngbTooltip="{{(submitted && f.expirationDate.invalid) ? util.formatError(f.expirationDate.errors) : null}}"
                       placeholder="yyyy-mm-dd" id="expirationDate" name="expirationDate"
                       formControlName="expirationDate" ngbDatepicker #expirationDate="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" [class.btn-outline-danger]=""
                            (click)="expirationDate.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
