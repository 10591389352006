import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'APLItem',
})
export class APLItem {
    @Key itemId: number;
    authorityId: number;
    authority?: string;
    category: number;
    subCategory: number;
    itemNumber: string;
    status: number;
    description: string;
    size: number;
    price: number;
    priceType: number;
    effectiveDate: string;
    endDate: string;
    purchaseIndicator: boolean;
    addedAt: string;
    updatedAt: string;
    deletedAt: string;
    unitOfMeasure: string;
    packageSize: number;
}
