// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { HttpInterceptorConfig } from '@auth0/auth0-angular';

const redirectUri = 'https://ws-dev-api.jpma.com/';
const api = 'https://ws-dev-api.jpma.com';

const httpInterceptor: HttpInterceptorConfig = {
  allowedList: [
    {
      uriMatcher: uri => uri.includes(`${api}/api/v1/admin/`) || uri.includes(`${api}/api/v3/admin/`),
      tokenOptions: {
        audience: api,
      },
    },
  ],
};

export const environment = {
    production: false,
    host: api,
    apiRoot: 'https://ws-dev-api.jpma.com/api/v1',
    apiRoot3: 'https://ws-dev-api.jpma.com/api/v3',
    auth: {
      // domain: 'dev-vm3uacai.us.auth0.com',
      // clientId: 'mR14ZvzS8s1a0oPhlEffd2mk9wqoZtLB',
      domain: 'dev-a8zf3o48.us.auth0.com',
      clientId: 'gwvHisgEoQgf8ncIOfCSDpm8BwqlxQCp',
      httpInterceptor,
      audience: api,
      redirectUri,
    },
    azureConfig: {
        accountName: 'jpmastorage',
        containerName: 'imports',
    }
};
