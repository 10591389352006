import {Component, Input, OnInit} from '@angular/core';
import {Slide} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wic-slide-edit',
    templateUrl: './wic-slide-edit.component.html',
    styleUrls: ['./wic-slide-edit.component.sass']
})
export class WicSlideEditComponent implements OnInit {
    @Input() slide: Slide = {} as Slide;

    bgColor: string;
    iconColor: string;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.bgColor = this.slide.bgColor || '#ffffff';
        this.iconColor = this.slide.iconColor || '#000000';
    }

    save() {
        this.activeModal.close({
            ...this.slide,
            bgColor: this.bgColor || this.slide.bgColor,
            iconColor: this.iconColor || this.slide.iconColor
        });
    }
}
