import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadModernStatisticsReport } from '@state/reporting/modernstatistics.state';

@Injectable({ providedIn: 'root' })
export class ReportingFacade {
  constructor(private store: Store) {}

  loadModern(dateRange: { startDate?: string; endDate?: string } = {}) {
    this.store.dispatch(loadModernStatisticsReport(dateRange));
  }
}
