import {Injectable} from '@angular/core';
import {BenefitInfo} from '@models/BenefitInfo';
import {Store} from '@ngrx/store';
import {AppState} from '@state/app.state';
import {BenefitInfoFacadeBase, currentRawBenefitInfo, originalRawBenefitInfo} from '@state/benefit-info.state';
import {map, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BenefitInfoFacade extends BenefitInfoFacadeBase {
    currentRaw$ = this.store.select(currentRawBenefitInfo);
    originalRaw$ = this.store.select(originalRawBenefitInfo);
    hasOriginalRaw$ = this.originalRaw$.pipe(map(raw => !!raw));
    noOriginalRaw$ = this.originalRaw$.pipe(map(raw => !raw));

    constructor(private store: Store<AppState>) {
        super(BenefitInfo, store);
    }
}
