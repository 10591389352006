import {Entity, Key} from '@briebug/ngrx-auto-entity';
import { CardImportStatus } from './CardStatus';
import { CardLegacyImportStatus } from './CardLegacyStatus';

@Entity({
    modelName: 'BenefitImportJob',
})
export class BenefitImportJob {
    // tslint:disable-next-line:variable-name
    @Key _id?: string;
    authorityId: number;
    filename: string;
    username: string;
    timestamp: string;
    succeeded: boolean;
    lastActivity: string;
    status: JobStatus;
    parseError?: ParseError[];
    progress?: JobProgress[];
    statusCounts?: {
        total: number;
        [CardImportStatus.identified]: number;
        [CardImportStatus.parsed]: number;
        [CardImportStatus.staged]: number;
        [CardImportStatus.published]: number;
        [CardImportStatus.error]: number;
    };
    legacyStatusCounts?: {
        total: number;
        [CardLegacyImportStatus.pending]: number;
        [CardLegacyImportStatus.imported]: number;
        [CardLegacyImportStatus.error]: number;
    };
}

export class ParseError {
    line: number;
    message: string;
}

export class JobProgress {
    step: string;
    timestamp?: string;
    note?: string;
    details?: any;
}

export enum JobStatus {
    initiated = 'INITIATED',
    downloaded = 'DOWNLOADED',
    inProgress = 'IN_PROGRESS',
    completed = 'COMPLETED',
    failed = 'FAILED',
}
