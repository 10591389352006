import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hex'
})
export class HexPipe implements PipeTransform {

  transform(value?: number, args?: any): any {
    return value ? `0x${('00000000' + value.toString(16)).substr(-8)}` : null;
  }

}
