<div class="card mt-4">
  <div class="d-flex card-header justify-content-between align-items-center">
    <h5 class="mb-0" *ngIf="!showFilter">Demo Accounts</h5>
    <div *ngIf="showFilter" class="col-6">
      <ngx-bootstrap-multiselect
        [options]="authorities"
        [texts]="{ defaultTitle: 'Select Authority' }"
        [settings]="{ dynamicTitleMaxItems: 1, buttonClasses: 'btn btn-outline-secondary' }"
        [formControl]="authorityForm"
      ></ngx-bootstrap-multiselect>

      <button class="btn btn-outline-secondary ml-2" (click)="replicate.emit()">
        Replicate Card
      </button>
    </div>

    <div>
      <span class="text-muted" *ngIf="loading && (demoAccounts$ | async).length === 0">
        <i class="fa fa-spinner fa-spin"></i> Loading...
      </span>
      <button *ngIf="!showToolbar" class="btn btn-outline-secondary" (click)="added.emit()">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>

  <div *ngIf="demoAccounts$ | async as demoAccounts; else empty">
    <ngb-accordion #accordion="ngbAccordion" class="flush" [closeOthers]="true">
      <ngb-panel *ngFor="let demoAccount of demoAccounts; trackBy: trackDemoAccountBy">
        <ng-template ngbPanelHeader>
          <div class="d-flex justify-content-between align-items-center demo-account-card">
            <div>
              <button
                type="button"
                class="btn btn-link no-underline"
                (click)="selected.emit(demoAccount)"
                ngbPanelToggle
              >
                <b>{{ demoAccount.name }}</b>
              </button>
              <p class="d-flex align-items-center">
                {{ demoAccount.cardNumber }}
                <span class="badge badge-secondary ml-3">Authority {{ demoAccount.authorityId }}</span>
              </p>
            </div>

            <i class="fa fa-circle text-{{ demoAccount.isCardActivated ? 'success' : 'danger' }}"></i>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div *ngIf="demoAccount.groups?.length" class="list-group list-group-flush demo-account-benefits">
            <a *ngFor="let group of demoAccount.groups" class="list-group-item list-group-item-action">
              <div class="row">
                <div class="row col-7">
                  <div class="col-4 text-center">
                    <b>Category</b>
                  </div>
                  <div class="col-4 text-center">
                    <b>SubCategory</b>
                  </div>
                  <div class="col-4 text-center">
                    <small>Available / Quantity</small>
                  </div>
                </div>

                <div class="col-5 text-right">
                  <span class="badge badge-info p-2 mt-2">
                    {{ group.beginDate | date: 'shortDate' }} - {{ group.endDate | date: 'shortDate' }}
                  </span>
                </div>
              </div>

              <div class="row">
                <div class="col-7 row">
                  <ng-container *ngFor="let benefit of group.benefits">
                    <div class="col-4 text-center">
                      <p>{{ benefit.categoryId }}</p>
                    </div>
                    <div class="col-4 text-center">
                      <p>{{ benefit.subCategoryId }}</p>
                    </div>
                    <div class="col-4 text-center">
                      <small>{{ benefit.availableQuantity }} / {{ benefit.quantity }}</small>
                    </div>
                  </ng-container>
                </div>
                <div class="col-5 d-flex justify-content-end align-items-end">
                  <button
                    type="button"
                    class="btn btn-outline-primary mr-2"
                    (click)="emitEditGroup(demoAccount, group)"
                  >
                    <i class="fa fa-edit"></i>
                  </button>

                  <button type="button" class="btn btn-outline-danger" (click)="emitDeleteGroup(demoAccount, group)">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </a>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-3 mx-5">
            <div>
              <button class="btn btn-outline-secondary mr-3" [disabled]="saving" (click)="toggleActive.emit(demoAccount)">
                Set {{ demoAccount.isCardActivated ? 'Inactive' : 'Active' }}
                <i *ngIf="saving" class="fa fa-spinner fa-spin"></i>
              </button>
              <span class="text-muted" *ngIf="loading"> <i class="fa fa-spinner fa-spin"></i> Loading... </span>
            </div>
            <div>
              <button class="btn btn-outline-danger mr-3" (click)="deleted.emit(demoAccount)">
                <i class="fa fa-trash"></i> Delete Demo Account
              </button>
              <button class="btn btn-outline-primary" (click)="addGroup.emit(demoAccount)">
                <i class="fa fa-plus"></i> Add Benefit Group
              </button>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

  <ng-template #empty>
    <div class="card-body">
      <p><em>No demo accounts that match criteria. Try changing authority filter criteria or adding a new one.</em></p>
    </div>
  </ng-template>

  <div class="card-footer" *ngIf="showToolbar">
    <div class="row">
      <div class="col-md-6">
        <a class="btn btn-outline-secondary" role="button" [download]="filename" [href]="downloadURI$ | async">
          <i class="fa fa-download"></i> Download as CSV
        </a>
      </div>
      <div class="col-md-6 text-right">
        <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
          <i class="fa fa-refresh"></i> Refresh
        </button>
        <button class="btn btn-outline-primary" (click)="added.emit()">
          <i class="fa fa-plus"></i> Add Demo Account
        </button>
      </div>
    </div>
  </div>
</div>
