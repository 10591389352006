import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {IImportParseError, IImportStep} from '@models/types';

@Entity({
    modelName: 'LocalBenefitImport'
})
export class LocalBenefitImport {
    // tslint:disable-next-line:variable-name
    @Key _id?: string;
    authorityId: number;
    filename: string;
    username: string;
    timestamp: string;
    oldCountRemoved: number;
    newCountAdded: number;
    newCountSkipped: number;
    progress: IImportStep[];
    parseError?: IImportParseError[];
    parseErrorCount?: number;
    succeeded?: boolean;
}
