import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Observable} from 'rxjs';

import {SubCategory} from '@models';
import {environment} from '../../environments/environment';

@Injectable()
export class SubCategoryService implements IAutoEntityService<SubCategory> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, keys: any): Observable<SubCategory[]> {
        return this.http.get<SubCategory[]>(
            environment.apiRoot + `/admin/authorities/${keys.authorityId}/subcategories`
        );
    }
}
