import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { Language } from './language.entity';

export interface LanguagesState extends IEntityState<Language> {}

export const {
  initialState: initialLanguageState,
  entityState: languageState,
  selectors: { selectAll: allLanguages },
  facade: LanguageFacadeBase,
} = buildState(Language, {} as LanguagesState);

const reduce = createReducer(initialLanguageState);

export function languageReducer(state = initialLanguageState, action: Action) {
  return reduce(state, action);
}

export const LANGUAGES_FEATURE_KEY = 'language';
