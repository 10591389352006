import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '@state/app.state';
import {sampledCardNumber, sampleRandomCardNumber} from '@state/card.state';

@Injectable({
    providedIn: 'root'
})
export class CardsFacade {
    sampledCardNumber$ = this.store.select(sampledCardNumber);

    constructor(private store: Store<AppState>) {
    }

    sampleRandomCard(authorityId: number) {
        this.store.dispatch(sampleRandomCardNumber({authorityId}));
    }
}
