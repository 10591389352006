import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wic-appointments',
  templateUrl: './wic-appointments.component.html',
  styleUrls: ['./wic-appointments.component.sass'],
})
export class WicAppointmentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
