import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class APLImportService {
    constructor(private http: HttpClient) {
    }

    run(authorityId: number): Observable<any> {
        return this.http.post(environment.apiRoot + `/admin/apl/imports/${authorityId}/run`, {});
    }

    check(authorityId: number): Observable<any> {
        return this.http.get(environment.apiRoot + `/admin/apl/imports/${authorityId}/run`);
    }
}
