import { Injectable } from '@angular/core';
import { AuthService, IdToken } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { login, logout } from '@state/auth/auth.actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root'})
export class AuthFacade {
  claims$: Observable<IdToken & { roles: string[] }> = this.auth.idTokenClaims$.pipe(
    map(token => ({ ...token, roles: token[environment.host + '/roles']?.map(role => role.toLowerCase()) || [] }))
  );
  user$ = this.auth.user$;

  constructor(private store: Store<AppState>, private auth: AuthService) {}

  login(previousUrl?: string) {
    this.store.dispatch(login({ previousUrl }));
  }

  logout() {
    this.store.dispatch(logout());
  }
}
