import { WikiArticle } from '@models';
import { Dictionary } from '@ngrx/entity/src/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { adapter, IWikiState } from './wiki.reducer';

export const wikiState = createFeatureSelector<IWikiState>('wiki');

export const {
    selectAll: allWikis,
    selectEntities: wikiEntities,
    selectIds: wikiIds,
    selectTotal: wikiCount,
} = adapter.getSelectors(wikiState);

export const currentWikiId = createSelector(
    wikiState,
    (state: IWikiState) => state.selectedId
);

export const currentWikiSlug = createSelector(
    wikiState,
    (state: IWikiState) => state.selectedSlug
);

export const currentWikiById = createSelector(
    wikiEntities,
    currentWikiId,
    (entities: Dictionary<WikiArticle>, id: string) => {
        return id && entities[id];
    }
);

export const currentWikiBySlug = createSelector(
    wikiIds,
    wikiEntities,
    currentWikiSlug,
    (ids: string[], entities: Dictionary<WikiArticle>, slug: string) => {
        return slug && ids.map(id => entities[id]).find(entity => entity.slug === slug);
    }
);
