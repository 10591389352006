import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wic-benefits',
  templateUrl: './wic-benefits.component.html',
  styleUrls: ['./wic-benefits.component.sass']
})
export class WicBenefitsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
