import {createAction, props} from '@ngrx/store';

export const loadCardStatusRange = createAction(
    '[CardStatus] Load Card Status By Range',
    props<{ range: { start: number, end: number } }>()
);

export const statusFiltersUpdated = createAction(
    '[CardStatus] Status Filters Updated',
    props<{ statusFilters: string[] }>()
);

export const loadCardLegacyStatusRange = createAction(
    '[CardLegacyStatus] Load Card Legacy Status By Range',
    props<{ range: { start: number, end: number } }>()
);

export const legacyStatusFiltersUpdated = createAction(
    '[CardLegacyStatus] Legacy Status Filters Updated',
    props<{ statusFilters: string[] }>()
);
