import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {APLJob} from '@models/APLJob';

@Component({
    selector: 'wic-apl-import-jobs-table',
    templateUrl: './wic-apl-import-jobs-table.component.html',
    styleUrls: ['./wic-apl-import-jobs-table.component.sass']
})
export class WicAplImportJobsTableComponent implements OnInit {
    @Input() jobs: APLJob[];
    @Input() showToolbar = false;
    @Input() forDeletion: APLJob;
    @Input() isLoading = false;

    @Output() viewed = new EventEmitter<APLJob>();
    @Output() deleted = new EventEmitter<APLJob>();
    @Output() refreshed = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

}
