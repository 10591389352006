import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {APLMessageMappingsFacade} from '@facades/apl-message-mappings.facade';
import {APLMessageTextsFacade} from '@facades/apl-message-texts.facade';
import {APLMessagesFacade} from '@facades/apl-messages.facade';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {CategoryLookupsFacade} from '@facades/category-lookups.facade';
import {APLMessage, APLMessageMapping, APLMessageText} from '@models';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, publishReplay, refCount, share, tap, withLatestFrom} from 'rxjs/operators';

@Component({
    selector: 'wic-apl-message-view',
    templateUrl: './wic-apl-message-view.component.html',
    styleUrls: ['./wic-apl-message-view.component.sass']
})
export class WicAplMessageViewComponent implements OnInit {
    message$: Observable<APLMessage>;
    mappings$: Observable<APLMessageMapping[]>;
    texts$: Observable<APLMessageText[]>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private aplMessages: APLMessagesFacade,
        public aplMessageMappings: APLMessageMappingsFacade,
        private aplMessageTexts: APLMessageTextsFacade,
        public authorities: AuthoritiesFacade,
        public categoryLookups: CategoryLookupsFacade) {
    }

    ngOnInit() {
        this.message$ = this.aplMessages.all$.pipe(
            filter(messages => !!messages && !!messages.length),
            withLatestFrom(
                this.activatedRoute.params.pipe(
                    filter(params => !!params),
                    map(params => params.id)
                )
            ),
            map(([messages, id]) => messages.find(message => message._id === id)),
            filter(message => !!message),
            distinctUntilChanged(),
            tap(message => this.aplMessageTexts.loadByMessage(message._id)),
            tap(message => this.aplMessageMappings.loadByMapping(message._id)),
            publishReplay(1),
            refCount()
        );

        this.mappings$ = this.aplMessageMappings.all$.pipe(
            withLatestFrom(
               this.aplMessageMappings.isLoading$,
               this.aplMessageMappings.isDeleting$
            ),
            filter(([, isLoading, isDeleting]) => !isLoading && !isDeleting),
            map(([mappings]) => mappings || []),
            distinctUntilChanged()
        );

        this.texts$ = this.aplMessageTexts.all$.pipe(filter(texts => !!texts));
    }

    editMessage(message: APLMessage) {
        this.aplMessages.showEdit(message);
    }

    addText(text: APLMessageText) {
        this.aplMessageTexts.create(text);
    }

    deleteText(text: APLMessageText) {
        this.aplMessageTexts.delete(text);
    }

    saveTexts(texts: APLMessageText[]) {
        this.aplMessageTexts.updateAll(texts);
    }

    includeMappings(mappings: APLMessageMapping[]) {
        this.aplMessageMappings.include(mappings);
    }

    excludeMappings(mappings: APLMessageMapping[]) {
        this.aplMessageMappings.exclude(mappings);
    }
}
