<div class="row">
    <div class="col-md-12">
        <wic-import-job-status [benefitImportJob]="benefitImportJobs.selected$ | async">
        </wic-import-job-status>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="card mt-5 mb-5">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-4 text-center">
                        <h5>Job Progresses</h5>
                    </div>
                    <div class="col-md-4 text-center">
                        <h5>Card Statuses</h5>
                        <wic-filter-select [options]="cardStatusFilters" [selected]="cardStatuses.cardStatusFilters$ | async"
                                           (selectedChange)="cardStatuses.updateStatusFilters($event)">
                        </wic-filter-select>
                    </div>
                    <div class="col-md-4 text-center">
                        <h5>Legacy Card Statuses</h5>
                        <wic-filter-select [options]="legacyCardStatusFilters" [selected]="cardStatuses.cardLegacyStatusFilters$ | async"
                                           (selectedChange)="cardStatuses.updateLegacyStatusFilters($event)">
                        </wic-filter-select>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="row no-wrap">
                    <div class="col-md-4">
                        <cdk-virtual-scroll-viewport class="list-container" itemSize="125">
                            <mbsc-card [options]="settings" collapsible
                                       class="shadow"
                                       *cdkVirtualFor="let progress of benefitImportJobs.currentJobProgress$ | async">
                                <mbsc-card-header class="mbsc-card-header">
                                    <mbsc-card-subtitle class="mbsc-bold mbsc-txt-s">{{ progress.timestamp }}</mbsc-card-subtitle>
                                    <div class="mbsc-bold">{{ progress.step }}</div>
                                </mbsc-card-header>
                                <mbsc-card-content>
                                    {{progress.details | json}}
                                </mbsc-card-content>
                            </mbsc-card>
                        </cdk-virtual-scroll-viewport>
                    </div>

                    <div class="col-md-4">
                        <cdk-virtual-scroll-viewport class="list-container" itemSize="109">
                            <wic-status-card *cdkVirtualFor="let card of cardStatuses.cardStatusesVirtualSource$"
                                             [card]="card">
                            </wic-status-card>
                        </cdk-virtual-scroll-viewport>
                    </div>

                    <div class="col-md-4">
                        <cdk-virtual-scroll-viewport class="list-container" itemSize="109">
                            <wic-status-card *cdkVirtualFor="let card of cardStatuses.cardLegacyStatusesVirtualSource$"
                                             [card]="card">
                            </wic-status-card>
                        </cdk-virtual-scroll-viewport>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-md-4">
                        <button class="btn btn-outline-secondary mr-2" (click)="benefitImportJobs.refresh()">
                            <i class="fa fa-refresh"></i>
                            Refresh
                        </button>
                    </div>
                    <div class="col-md-4 flex-display">
                        <h6>Status Count:</h6>
                        <h6>{{ cardStatuses.cardStatusLength$ | async }}</h6>
                    </div>
                    <div class="col-md-4 flex-display">
                        <h6>Legacy Status Count:</h6>
                        <h6>{{ cardStatuses.cardLegacyStatusLength$ | async }}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
