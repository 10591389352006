import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {CategoryLookupsFacade} from '@facades/category-lookups.facade';
import {SubCategoryConfigsFacade} from '@facades/sub-category-configs.facade';
import {CategoryLookup, LinkDetail, SubCategoryConfig, SubCategoryLookup} from '@models';
import {combineLatest, Observable} from 'rxjs';
import {filter, first, map, tap} from 'rxjs/operators';
import {switchCombine} from '../../../util';

@Component({
    selector: 'wic-sub-category-config-view',
    templateUrl: './wic-sub-category-config-view.component.html',
    styleUrls: ['./wic-sub-category-config-view.component.sass']
})
export class WicSubCategoryConfigViewComponent implements OnInit {
    subCategoryConfig$: Observable<SubCategoryConfig>;
    categories$: Observable<CategoryLookup[]>;
    subCategories$: Observable<SubCategoryLookup[]>;

    subCategoryConfigs: SubCategoryConfig[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private subCategoryConfigsFacade: SubCategoryConfigsFacade,
        private categoryLookupsFacade: CategoryLookupsFacade) {
    }

    ngOnInit() {
        this.subCategoryConfig$ = this.subCategoryConfigsFacade.selectCurrent();

        this.subCategoryConfigsFacade.loadAll();
        this.categoryLookupsFacade.loadAll();

        this.activatedRoute.params.pipe(
            switchCombine(this.subCategoryConfigsFacade.selectAll()),
            first(([params, configs]) => !!params && !!configs && !!configs.length),
            tap(([, configs]) => this.subCategoryConfigs = configs),
            map(([params, configs]) => configs.find(config => config._id === params.id)),
            filter(config => !!config)
        ).subscribe(config => {
            this.subCategoryConfigsFacade.setCurrent(config);
        });
    }

    editConfig(config: SubCategoryConfig) {
        combineLatest([
            this.categoryLookupsFacade.allCategories$,
            this.categoryLookupsFacade.allSubCategories$
        ]).pipe(first()).subscribe(([categories, subCategories]) =>
            this.subCategoryConfigsFacade.showEdit(config, this.subCategoryConfigs, categories, subCategories)
        );
    }

    updateLineItem(config: SubCategoryConfig, linkDetail: LinkDetail) {
        if (linkDetail) {
            this.subCategoryConfigsFacade.showEditLink(config, linkDetail);
        } else {
            this.subCategoryConfigsFacade.showCreateLink(config);
        }
    }

    deleteLineItem(config: SubCategoryConfig, linkDetail: LinkDetail) {
        this.subCategoryConfigsFacade.showDeleteLink(config, linkDetail);
    }
}
