import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'sortBy'})
export class SortByPipe implements PipeTransform {
    transform<T>(list: T[], ...props: Array<keyof T>): T[] {
        return list.sort((a, b) =>
            props.reduce((key, prop) => key + String(a[prop]), '')
                .localeCompare(
                    props.reduce((key, prop) => key + String(b[prop]), ''),
                    null, {numeric: true}
                )
        );
    }
}
