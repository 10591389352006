import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EntityActionTypes, LoadAll, LoadMany, ofEntityType} from '@briebug/ngrx-auto-entity';
import {BenefitImportJob} from '@models/BenefitImportJob';
import {debounceTime, filter, map, withLatestFrom} from 'rxjs/operators';
import {currentBenefitImportJobKey} from '@state/benefit-import-job/benefit-import-job.state';
import {CardLegacyStatus} from '@models/CardLegacyStatus';
import {legacyStatusFiltersUpdated, loadCardLegacyStatusRange, statusFiltersUpdated} from '@state/card-status/card-status.actions';
import {cardLegacyStatusFilters, cardLegacyStatusLength} from '@state/card-status/card-status.selectors';
import {Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {CardStatus} from '@models/CardStatus';

@Injectable()
export class CardLegacyStatusEffects {
    loadRelatedStatuses$ = createEffect(() => this.actions$.pipe(
        ofEntityType(BenefitImportJob, EntityActionTypes.SelectByKey),
        withLatestFrom(this.store.select(currentBenefitImportJobKey), this.store.select(cardLegacyStatusFilters)),
        map(([, jobId, statusFilters]) => new LoadAll(CardLegacyStatus, {jobId, filters: statusFilters.join()}))
    ));

    reloadFilteredStatuses$ = createEffect(() => this.actions$.pipe(
        ofType(legacyStatusFiltersUpdated),
        withLatestFrom(this.store.select(currentBenefitImportJobKey)),
        map(([{statusFilters}, jobId]) => new LoadAll(CardLegacyStatus, {jobId, filters: statusFilters.join()}))
    ));

    loadPagedStatuses$ = createEffect(() => this.actions$.pipe(
        ofType(loadCardLegacyStatusRange),
        debounceTime(100),
        withLatestFrom(
            this.store.select(currentBenefitImportJobKey),
            this.store.select(cardLegacyStatusLength),
            this.store.select(cardLegacyStatusFilters)
        ),
        filter(([{range}, jobId, length]) => range.end >= (length - 10)),
        map(([{range}, jobId, length, statusFilters]) => ({
            jobId,
            skip: range.end,
            limit: (range.end - range.start) + 10,
            filters: statusFilters.join()
        })),
        map(({jobId, skip, limit}) => new LoadMany(CardLegacyStatus, {jobId, skip, limit}))
    ));

    constructor(
        private readonly store: Store<IAppState>,
        private readonly actions$: Actions) {
    }
}
