<form [formGroup]="form">
  <ngb-tabset formArrayName="langs" #langs
      (tabChange)="tabBeforeChange($event)">
    <ngb-tab *ngFor="let lang of langArray.controls; let i = index"
        [formGroupName]="i" id="{{i}}">
      <ng-template ngbTabTitle>
        <span [class.text-danger]="langArray.at(i).get('enhancedIssueTranslations').invalid" >{{lang.value.languageCode}}</span>
        <span *ngIf="i > 0">&nbsp;<i class="fa fa-trash" (click)="$event.preventDefault(); removeLanguage(lang.value.languageCode, i);"></i></span>
      </ng-template>
      <ng-template ngbTabContent>
        <div formArrayName="enhancedIssueTranslations">
          <div *ngFor="let translation of lang.get('enhancedIssueTranslations')['controls']; let j=index">
            <div [formGroupName]="j">
              <div [ngSwitch]=" translation.value.translationKey">
                <div *ngSwitchCase="'label'" class="form-group row mt-4">
                  <label for="translationValue" class="col-sm-2 col-form-label">Label</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control my-3" id="translationValue" formControlName="translationValue" required>
                  </div>
                </div>
                <div *ngSwitchCase="'info'" class="form-group row mt-4">
                  <label for="translationValue" class="col-sm-2 col-form-label">Info</label>
                  <div class="col-sm-10">
                    <textarea class="form-control my-3"
                        formControlName="translationValue"
                        id="translationValue"
                        [rows]="4"
                        maxlength="1024"
                    >
                    </textarea>
                  </div>
                </div>
                <div *ngSwitchCase="'buttonText'" class="form-group row mt-4">
                  <label for="translationValue" class="col-sm-2 col-form-label">Button Text</label>
                  <div class="col-sm-10">
                    <input class="form-control my-3" id="translationValue" formControlName="translationValue" required>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab> 
    <ngb-tab *ngIf="enhancedIssueTranslations.length > 0" title="+ Add" id="addLang">
    </ngb-tab>
</ngb-tabset>
<div *ngIf="enhancedIssueTranslations.length > 0" class="modal-footer">
  <button type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="saveTranslations(form)" >Save Translations</button>
</div>
</form>