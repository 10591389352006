import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {UsageStatisticsRecord} from '@models/UsageStatistics';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import {environment} from '../../../../environments/environment';
import {Sorter} from '../../../util';
import { ReportingJobStatus } from '@state/reporting/statistics-reporting-job.entity';

export interface IDateRange {
    startDate: NgbDateStruct;
    endDate: NgbDateStruct;
}

@Component({
    selector: 'wic-authorities-legacy-report',
    templateUrl: './wic-authorities-legacy-report.component.html',
    styleUrls: ['./wic-authorities-legacy-report.component.sass'],
})
export class WicAuthoritiesLegacyReportComponent implements OnInit, OnChanges {
    @Input() isLoading: boolean;
    @Input() failed: boolean
    @Input() loadDuration: number;
    @Input() loadedAt: Date;
    @Input() criteria: { beginDate: string, endDate: string };
    @Input() report: UsageStatisticsRecord[];
    @Input() status: ReportingJobStatus;
    @Output() dateRangeChanged = new EventEmitter<IDateRange>();

    totals: UsageStatisticsRecord;
    dateRange: IDateRange;

    apiUrl = environment.apiRoot;

    reportSorter = new Sorter(() => [...this.report], sorted => this.report = sorted, -1);

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        const start = moment();
        const end = moment().add(1, 'day');
        this.dateRange = {
            startDate: {
                year: start.year(),
                month: start.month() + 1,
                day: start.date(),
            },
            endDate: {
                year: end.year(),
                month: end.month() + 1,
                day: end.date(),
            },
        };
    }

    ngOnChanges() {
        if (!this.report) {
            return;
        }

        this.totals = this.report.reduce(
            (totals, record) => totals ? {
                authorityId: 0,
                authority: 'Totals',
                cardRegistrations: totals.cardRegistrations + record.cardRegistrations,
                dailyRxUsers: totals.dailyRxUsers + record.dailyRxUsers,
                uniqueRxUsers: totals.uniqueRxUsers + record.uniqueRxUsers,
                devRegistrations: totals.devRegistrations + record.devRegistrations,
                devActivity: totals.devActivity + record.devActivity,
                devActivityTotal: totals.devActivityTotal + record.devActivityTotal,
                uniqueItemLookups: totals.uniqueItemLookups + record.uniqueItemLookups,
                totalItemCount: totals.totalItemCount + record.totalItemCount,
                rxItemCount: totals.rxItemCount + record.rxItemCount,
                noRxItemCount: totals.noRxItemCount + record.noRxItemCount,
            } : record
        );
    }

    getFilename(dateRange: IDateRange) {
        if (!dateRange) {
            return;
        }

        const beginDate = `${dateRange.startDate.year}-${dateRange.startDate.month}-${dateRange.startDate.day}`;
        const endDate = `${dateRange.endDate.year}-${dateRange.endDate.month}-${dateRange.endDate.day}`;
        return `Usage_Report_${beginDate}_${endDate}.csv`;
    }

    buildDownloadUri(report: UsageStatisticsRecord[]) {
        if (!report) {
            return;
        }

        /* tslint:disable:max-line-length */
        const csv = report.reduce((lines, record) =>
            lines + `${record.authorityId},${record.authority},${record.cardRegistrations},${record.uniqueRxUsers},${record.dailyRxUsers},${record.devRegistrations},${record.devActivity},${record.devActivityTotal},${record.uniqueItemLookups},${record.totalItemCount},${record.rxItemCount},${record.noRxItemCount}\r\n`
            , 'AuthorityID,Authority,Card Registrations,Unique Rx Users,Daily Rx Users,Device Registrations,Device Activity,Device Activity Total,Unique Item Lookups,Total Item Count,Rx Item Count,NO Rx Item Count\r\n');
        /* tslint:enable:max-line-length */

        return this.sanitizer.bypassSecurityTrustUrl(`data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`);
    }
}
