import { Injectable } from '@angular/core';
import { User } from '@models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordChallengeModalComponent } from '../../password-challenge-modal/password-challenge-modal.component';
import { WicUserEditComponent } from '../../wic-admin/wic-users/wic-user-edit/wic-user-edit.component';
import { WicConfirmModalComponent } from '../../wic-confirm-modal/wic-confirm-modal.component';

@Injectable({
    providedIn: 'root'
})
export class UsersUIService {
    constructor(private modals: NgbModal) {
    }

    showPasswordChallenge(): Promise<boolean> {
        const ref = this.modals.open(PasswordChallengeModalComponent, {
            windowClass: 'danger-dialog'
        });
        return ref.result;
    }

    showUserModal(user?: User): Promise<User> {
        const ref = this.modals.open(WicUserEditComponent, {
            size: 'lg',
        });
        ref.componentInstance.user = user || {isEnabled: true, roles: ['authority']};
        return ref.result;
    }

    showConfirmDeleteModal(user?: User): Promise<string> {
        const ref = this.modals.open(WicConfirmModalComponent, {
            size: 'lg'
        });
        ref.componentInstance.modalTitle = `Delete user ${user.username}?`;
        // tslint:disable-next-line:max-line-length
        ref.componentInstance.message = `Are you sure you wish to delete ${user.first} ${user.last}'s user ${user.username}? This process is irreversible and cannot be undone!`;
        return ref.result;
    }
}
