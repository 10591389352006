import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {Category, LinkDetail, SubCategory, SubCategoryConfig} from '@models';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'wic-sub-category-config-link-edit',
    templateUrl: './wic-sub-category-config-link-edit.component.html',
    styleUrls: ['./wic-sub-category-config-link-edit.component.sass']
})
export class WicSubCategoryConfigLinkEditComponent implements OnInit {
    @Input() subCatConfig: SubCategoryConfig;
    @Input() linkDetail: LinkDetail;

    submitted = false;
    form: FormGroup;

    constructor(private sanitizer: DomSanitizer,
                private fb: FormBuilder,
                public activeModal: NgbActiveModal,
                private modalService: NgbModal,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.form = this.buildForm();
        if (this.linkDetail) {
            this.populateForm(this.linkDetail);
        }
    }

    buildForm() {
        const form = this.fb.group({
            label: [null, Validators.required],
            url: [null, Validators.required],
            icon: [null]
        });

        return form;
    }

    populateForm(linkDetail) {
        this.form.patchValue({
            ...linkDetail
        });
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        const linkDetails = (this.subCatConfig.linkDetails || []).filter((link: LinkDetail) => link.label !== value.label);

        const subCatConfig = {
            ...this.subCatConfig,
            linkDetails: [
                ...linkDetails,
                value
            ]
        };
        this.activeModal.close(subCatConfig);
    }

    imageChanged(event) {
        if (!event.target.files.length) {
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            this.form.patchValue({icon: reader.result});
            this.changeDetectorRef.markForCheck();
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    createImageUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
