import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SubCategoryConfig} from '@models';
import {ILookup} from '@models/types';
import {CompositeKeySorter} from '../../../util';

@Component({
    selector: 'wic-sub-category-configs-table',
    templateUrl: './wic-sub-category-configs-table.component.html',
    styleUrls: ['./wic-sub-category-configs-table.component.sass'],
})
export class WicSubCategoryConfigsTableComponent implements OnInit, OnChanges {
    @Input() isLoading: boolean;
    @Input() subCategoryConfigs: SubCategoryConfig[];
    @Input() categories: ILookup[];
    @Input() showFilter = true;
    @Input() showToolbar = true;
    @Output() refreshed = new EventEmitter();
    @Output() added = new EventEmitter();
    @Output() edited = new EventEmitter<SubCategoryConfig>();
    @Output() deleted = new EventEmitter<SubCategoryConfig>();

    hideSelf = false;

    subCategoryConfigsSorter = new CompositeKeySorter(() => this.subCategoryConfigs, sorted => this.subCategoryConfigs = sorted);

    form: FormGroup;
    private filteredCategoryIds: number[];

    constructor(private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.form = this.buildForm();

        this.form.controls.category.valueChanges.subscribe(ids => {
            this.filteredCategoryIds = ids;
        });
    }

    ngOnChanges() {
        if (this.subCategoryConfigs) {
            this.subCategoryConfigsSorter.sortComposite('cid_scid', (scc: SubCategoryConfig) => scc.categoryId + '_' + scc.subCategoryId);
        }
    }

    private buildForm() {
        return this.formBuilder.group({
            category: null,
        });
    }

    filter(configs: SubCategoryConfig[]): SubCategoryConfig[] {
        const filtered = configs.filter(config =>
            this.filteredCategoryIds && this.filteredCategoryIds.length ?
                this.filteredCategoryIds.some(id => +config.categoryId === +id) : true
        );

        return filtered;
    }
}
