import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {APLOverride, BinCode} from '@models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BinCodeService implements IAutoEntityService<BinCode> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: any): Observable<BinCode[]> {
        return this.http.get<BinCode[]>(environment.apiRoot +
            `/admin/authorities/${criteria.authorityId}/bincodes`
        );
    }

    create(entityInfo: IEntityInfo, entity: BinCode): Observable<BinCode> {
        return this.http.post<BinCode>(environment.apiRoot +
            `/admin/authorities/${entity.AuthorityID}/bincodes`,
        entity
        );
    }

    update(entityInfo: IEntityInfo, entity: BinCode): Observable<BinCode> {
        return this.http.patch<BinCode>(environment.apiRoot +
            `/admin/authorities/${entity.AuthorityID}/bincodes`,
            entity
        );
    }

    delete(entityInfo: IEntityInfo, entity: BinCode): Observable<BinCode> {
        return this.http.delete<BinCode>(environment.apiRoot +
            `/admin/authorities/${entity.AuthorityID}/bincodes/${entity.BINCode}`
        ).pipe(map(() => entity));
    }
}
