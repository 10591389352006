import {buildState, IEntityState} from '@briebug/ngrx-auto-entity';
import {Action, createReducer, on} from '@ngrx/store';
import {CardLegacyStatus} from '@models/CardLegacyStatus';
import {legacyStatusFiltersUpdated} from '@state/card-status/card-status.actions';

export interface CardLegacyStatusState extends IEntityState<CardLegacyStatus> {
    statusFilters?: string[];
}

export const {
    initialState: initialCardLegacyStatusState,
    entityState: cardLegacyStatusState,
    selectors: {
        selectAll: allCardLegacyStatuses
    }
} = buildState(CardLegacyStatus, { statusFilters: ['ERRORED'] } as CardLegacyStatusState);

export const setStatusFilters = (state, {statusFilters}): CardLegacyStatusState => ({...state, statusFilters});

const reduce = createReducer(
    initialCardLegacyStatusState,
    on(legacyStatusFiltersUpdated, setStatusFilters)
);

export function cardLegacyStatusReducer(state = initialCardLegacyStatusState, action: Action): CardLegacyStatusState {
    return reduce(state, action);
}

