import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ChartsModule} from 'ng2-charts';
import {DoughnutLabeledComponent} from './doughnut-labeled/doughnut-labeled.component';
import {SparkPieComponent} from './spark-pie/spark-pie.component';
import {StackedBarSimpleComponent} from './stacked-bar-simple/stacked-bar-simple.component';

@NgModule({
    imports: [
        CommonModule,
        ChartsModule,
    ],
    declarations: [
        StackedBarSimpleComponent,
        DoughnutLabeledComponent,
        SparkPieComponent,
    ],
    exports: [
        StackedBarSimpleComponent,
        DoughnutLabeledComponent,
        SparkPieComponent,
    ],
})
export class ChartingModule {
}
