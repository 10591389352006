<div class="wic-error-block" *ngIf="errors && errors.length">
    <div class="alert alert-danger alert-dismissible rounded-0 fade show wic-error-alert" role="alert">
        <button type="button" class="btn btn-link btn-sm" (click)="isExpanded = !isExpanded"
                *ngIf="!isExpanded && errors.length > 1">
            <i class="fa" [class.fa-arrow-down]="!isExpanded" [class.fa-arrow-up]="isExpanded"></i>
        </button>
        <button type="button" class="close" (click)="this.dismiss.emit();" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <strong>Holy guacamole!</strong> {{ !errors ? 'An error has occurred.' : !errors[0].message ? errors[0] :  errors[0].message}}
    </div>
    <div class="wic-error-list" [class.wic-error-single]="!isExpanded">
        <div *ngFor="let error of errors; let i = index">
            <div *ngIf="i !== 0" class="alert alert-danger alert-dismissible rounded-0 fade show wic-error-alert" role="alert">
                <button type="button" class="btn btn-link btn-sm" (click)="isExpanded = !isExpanded"
                        *ngIf="(!isExpanded && errors.length > 1 && !i) || (isExpanded && i === errors.length - 1)">
                    <i class="fa" [class.fa-arrow-down]="!isExpanded" [class.fa-arrow-up]="isExpanded"></i>
                </button>
                <button type="button" class="close" (click)="this.dismiss.emit();" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <strong>Holy guacamole!</strong> {{ error.message }}
            </div>
        </div>
    </div>
</div>
