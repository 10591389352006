import {Injectable} from '@angular/core';
import {EnhancedDevice} from '@models';
import {Store} from '@ngrx/store';
import {AppState} from '@state/app.state';
import { EnhancedDeviceFacadeBase } from '@state/enhanced-device.state';

@Injectable({providedIn: 'root'})
export class EnhancedDeviceFacade extends EnhancedDeviceFacadeBase {
    constructor(private store: Store<AppState>) {
        super(EnhancedDevice, store);
    }

    toggle({device, allowed}: {device: EnhancedDevice, allowed: boolean}): void {
        this.update({
            ...device,
            allowed
        });
    }
}
