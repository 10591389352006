import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {ObjectId} from '@models/types';

@Entity({
    modelName: 'APLOverride'
})
export class APLOverride {
    // tslint:disable-next-line:variable-name
    @Key _id: ObjectId;
    authorityId: number;
    categoryId: number;
    isEnabled: boolean;
    subCategoryId?: number;
    description?: string;
    uom?: string;
    packageSize?: number;
}
