<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <span class="badge badge-info" *ngIf="subCatConfig?._id">
                {{subCatConfig._id}}
            </span>
            {{subCatConfig?._id ? 'Edit' : 'Create'}} Sub Category Config
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label for="categoryId" class="col-4 col-lg-2 col-form-label" [class.text-danger]="submitted && !form.get('categoryId').valid">Category</label>
            <div class="col-8 col-lg-10">
                <select class="form-control" [class.is-invalid]="submitted && !form.get('categoryId').valid"
                        formControlName="categoryId" name="categoryId" id="categoryId">
                    <option value="" disabled>Select Category</option>
                    <option *ngFor="let category of categories" [value]="category.categoryId">
                        {{category.description | titlecase }} ({{category.categoryId}})
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="subCategoryId" class="col-4 col-lg-2 col-form-label" [class.text-danger]="submitted && !form.get('subCategoryId').valid">Sub-Category</label>
            <div class="col-8 col-lg-10">
                <select class="form-control" *ngIf="form.get('categoryId').value"
                        [class.is-invalid]="submitted && !form.get('subCategoryId').valid"
                        formControlName="subCategoryId" name="subCategoryId" id="subCategoryId">
                    <option value="" disabled>Select Sub-Category</option>
                    <option *ngFor="let subCategory of filteredSubCategories" [value]="subCategory.subCategoryId">
                        {{subCategory.description | titlecase }} ({{subCategory.subCategoryId}})
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="image" class="col-4 col-lg-2 col-form-label">Image</label>
            <div class="col-4 col-lg-5">
                <label class="custom-file form-control">
                    <input #file class="custom-file-input"
                           id="image"
                           type="file"
                           accept="image/jpeg"
                           (change)="imageChanged($event)"/>
                    <span class="custom-file-control"></span>
                </label>
            </div>
            <div class="col-4 col-lg-5">
                <img class="sub-cat-image" *ngIf="form.get('image').value"
                     [src]="createImageUrl(form.get('image').value)"/>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-4 col-lg-2 col-form-label"></div>
            <div class="col-8 col-lg-10">
                <small class="text-muted">For optimal performance, please use *.jpeg images with 78px height/width.</small>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
