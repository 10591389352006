<router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>

<div class="row" *ngIf="!hideSelf">
    <div class="col-md-12">
        <wic-appointment-imports-table
            [apptImports]="apptImports$ | async"
            (refreshed)="refresh()">
        </wic-appointment-imports-table>
    </div>
</div>
