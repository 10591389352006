<div class="modal-header">
    <h4 class="modal-title mb-0">
        Please verify your password
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div>
            Your password is required to make these changes. Please verify your password to continue:
        </div>
        <div class="form-group row">
            <label for="password" class="col-sm-2 col-form-label" [class.text-danger]="submitted && (form.controls.password.invalid || incorrect)">Password</label>
            <div class="col-sm-10">
                <input type="password" class="form-control" id="password" formControlName="password" [class.is-invalid]="submitted && form.controls.password.invalid">
            </div>
        </div>
        <div class="form-error">
            <span class="text-danger" *ngIf="incorrect">Your password was incorrect. Try again.</span>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-red-outline" (click)="activeModal.close(false)">Cancel</button>
    <button type="button" class="btn btn-red" (click)="verify(form)">Verify</button>
</div>
