<div class="row mt-4" *ngIf="subCategoryConfig">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    Configuration Details
                </h5>
                <i class="fa fa-fw fa-pencil float-right m-1" (click)="edited.emit(subCategoryConfig)"
                   ngbTooltip="Edit Details" placement="left"></i>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Id</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-info">{{subCategoryConfig._id}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Category</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-info">{{subCategoryConfig.categoryId}}</span>&nbsp;
                            <span class="text-monospace">{{currentCategory?.description}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">SubCategory</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-info">{{subCategoryConfig.subCategoryId}}</span>&nbsp;
                            <span class="text-monospace">{{currentSubCategory?.description}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Image</dt>
                        <dd class="col-md-9">
                            <img class="sub-cat-image" [src]="createImageUrl(subCategoryConfig.image)"/>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
