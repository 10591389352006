import {Injectable} from '@angular/core';
import {Create, LoadAll, Update} from '@briebug/ngrx-auto-entity';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {Partner} from '@models';
import {IAppState} from '@state/app.state';
import {allPartners} from '@state/partner.state';
// tslint:disable-next-line:max-line-length
import {WicBannerPlaylistEntryEditComponent} from '../wic-admin/wic-authorities/wic-authority-banners/wic-banner-playlist-view/wic-banner-playlist-entry-edit/wic-banner-playlist-entry-edit.component';

@Injectable({
    providedIn: 'root'
})
export class PartnersFacade {
    constructor(private store: Store<IAppState>, private modal: NgbModal) {
    }

    // region Selections
    get all(): Observable<Partner[]> {
        return this.store.pipe(select(allPartners));
    }
    // endregion

    // region Dispatches
    loadAll() {
        this.store.dispatch(new LoadAll(Partner));
    }

    create(partner: Partner) {
        this.store.dispatch(new Create(Partner, partner));
    }

    update(partner: Partner) {
        this.store.dispatch(new Update(Partner, partner));
    }

    // endregion

    // region UI Helpers
    showEdit(partner: Partner) {
        this.showEditModal(partner)
            .then(edited => {
                this.update(edited);
            }, () => {
            });
    }

    showCreate() {
        this.showEditModal().then(created => {
            this.create(created);
        }, () => {
        });
    }

    // endregion

    // region Helpers
    private showEditModal(partner?: Partner): Promise<Partner> {
        const modalReference = this.modal.open(WicBannerPlaylistEntryEditComponent, {size: 'lg'});
        modalReference.componentInstance.partner = partner;
        return modalReference.result;
    }

    // endregion
}
