import {Component, OnInit} from '@angular/core';
import {LoadAll} from '@briebug/ngrx-auto-entity';
import {LocalBenefitImportsFacade} from '@facades/local-benefit-imports.facade';
import {AppointmentImport} from '@models';
import {LocalBenefitImport} from '@models/LocalBenefitImport';
import {Observable} from 'rxjs';
import { MbscFormOptions } from '@mobiscroll/angular';
import { BenefitImportJobFacade } from '@facades/benefit-import-job.facade';

@Component({
    selector: 'wic-benefit-imports',
    templateUrl: './wic-benefit-imports.component.html',
    styleUrls: ['./wic-benefit-imports.component.sass']
})
export class WicBenefitImportsComponent implements OnInit {
    localBenefitImports$: Observable<LocalBenefitImport[]>;

    hideSelf = false;
    importsDisplay = 'legacy';

    formSettings: MbscFormOptions = {
        theme: 'ios',
        themeVariant: 'light'
    };

    constructor(private localBenefitImportsFacade: LocalBenefitImportsFacade, public benefitImportJobs: BenefitImportJobFacade) {
    }

    ngOnInit() {
        this.localBenefitImports$ = this.localBenefitImportsFacade.all;
        this.refresh();
    }

    refresh() {
        this.localBenefitImportsFacade.loadAll();
    }
}
