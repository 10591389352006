<div class="card mt-4">
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                <h5 class="float-left mb-0" *ngIf="!showFilter">Error Reports</h5>
                <form [formGroup]="form" *ngIf="showFilter">
                    <div class="row">
                        <div class="col-4">
                            <input formControlName="criteria" placeholder="Search Criteria">
                        </div>
                        <div class="col-4">
                        </div>
                        <div class="col-4">
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-1" *ngIf="!showToolbar">
                <i class="fa fa-refresh" (click)="refreshed.emit()"></i>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="!!errorReports?.length">
            <thead>
            <tr>
                <th>Title</th>
                <th>Device ID</th>
                <th>Session ID</th>
                <th>Reported At</th>
                <th class="text-right"><i class="fa fa-wrench"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let report of errorReports">
                <td class="text-left">
                    <a href="" (click)="$event.preventDefault(); selected.emit(report)">{{report.title}}</a>
                </td>
                <td class="text-left">
                    <span class="badge badge-udid">{{report.uniqueDeviceId}}</span>
                </td>
                <td class="text-left">
                    <span class="badge badge-session">{{report.sessionId}}</span>
                </td>
                <td><span class="badge badge-info">{{report.timestamp | date:'yyyy-MM-dd hh:mm:ss'}}</span></td>
                <td class="text-right">
                    <a (click)="selected.emit(report)"><i class="fa fa-eye"></i></a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="!lite && errorReports?.length">
        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-8">
                <ngb-pagination
                    class="d-flex justify-content-center"
                    [collectionSize]="totalCount"
                    [pageSize]="50"
                    [maxSize]="10"
                    [rotate]="true"
                    [ellipses]="true"
                    [page]="currentPage?.page || 1"
                    (pageChange)="refresh($event)"
                    aria-label="Pagination">
                </ngb-pagination>
            </div>
            <div class="col-md-2">

            </div>
        </div>
    </div>
    <div *ngIf="!errorReports?.length" class="card-body">
        <p><em>There are no error reports.</em></p>
    </div>
    <div class="card-footer" *ngIf="showToolbar">
        <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
            </div>
        </div>
    </div>
</div>
