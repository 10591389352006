<div class="container">
    <router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>
</div>

<div *ngIf="!hideSelf">
    <div class="row">
        <div class="col-md-12">
            <wic-error-reports-table
                [errorReports]="errorReports.sorted$ | async"
                [showToolbar]="true"
                [currentPage]="errorReports.currentPage$ | async"
                [totalCount]="errorReports.totalPageable$ | async"
                (refreshed)="errorReports.loadPage({page: $event.page, size: 50})"
                (selected)="viewReport($event)">
            </wic-error-reports-table>
        </div>
    </div>
</div>
