import {Action} from '@ngrx/store';

import {WikiArticle} from '@models';
import {type} from '../shared/utils';

export const CREATE_WIKI = type('[Wikis] Create wiki article');
export const CREATE_WIKI_SUCCESS = type('[Wikis] Create wiki article succeeded');
export const CREATE_WIKI_FAILURE = type('[Wikis] Create wiki article failed');
export const DELETE_WIKI = type('[Wikis] Delete wiki article');
export const DELETE_WIKI_SUCCESS = type('[Wikis] Delete wiki article succeeded');
export const DELETE_WIKI_FAILURE = type('[Wikis] Delete wiki article failed');
export const DISMISS_WIKIS_ERROR = type('[Wikis] Dismiss error');
export const FETCH_WIKIS = type('[Wikis] Fetch wiki articles');
export const FETCH_WIKIS_SUCCESS = type('[Wikis] Fetch wiki articles succeeded');
export const FETCH_WIKIS_FAILURE = type('[Wikis] Fetch wiki articles failed');
export const FETCH_WIKI = type('[Wikis] Fetch wiki article');
export const FETCH_WIKI_SUCCESS = type('[Wikis] Fetch wiki article succeeded');
export const FETCH_WIKI_FAILURE = type('[Wikis] Fetch wiki article failed');
export const FETCH_WIKI_BY_SLUG = type('[Wikis] Fetch wiki article by slug');
export const FETCH_WIKI_BY_SLUG_SUCCESS = type('[Wikis] Fetch wiki article by slug succeeded');
export const FETCH_WIKI_BY_SLUG_FAILURE = type('[Wikis] Fetch wiki article by slug failed');
export const UPDATE_WIKI = type('[Wikis] Update wiki article');
export const UPDATE_WIKI_SUCCESS = type('[Wikis] Update wiki article succeeded');
export const UPDATE_WIKI_FAILURE = type('[Wikis] Update wiki article failed');

export const SELECT_WIKI = type('[Wikis] Select wiki article');
export const SELECT_WIKI_BY_ID = type('[Wikis] Select wiki article by id');
export const SELECT_WIKI_BY_SLUG = type('[Wikis] Select wiki article by slug');

export class CreateWikiAction implements Action {
    readonly type = CREATE_WIKI;

    constructor(public payload: WikiArticle) {
    }
}

export class CreateWikiSuccessAction implements Action {
    readonly type = CREATE_WIKI_SUCCESS;

    constructor(public payload: WikiArticle) {
    }
}

export class CreateWikiFailureAction implements Action {
    readonly type = CREATE_WIKI_FAILURE;

    constructor(public payload: any) {
    }
}

export class DeleteWikiAction implements Action {
    readonly type = DELETE_WIKI;

    constructor(public payload: WikiArticle) {
    }
}

export class DeleteWikiSuccessAction implements Action {
    readonly type = DELETE_WIKI_SUCCESS;

    constructor(public payload: string) {
    }
}

export class DeleteWikiFailureAction implements Action {
    readonly type = DELETE_WIKI_FAILURE;

    constructor(public payload: any) {
    }
}

export class DismissWikisErrorAction implements Action {
    readonly type = DISMISS_WIKIS_ERROR;
}

export class FetchWikisAction implements Action {
    readonly type = FETCH_WIKIS;
}

export class FetchWikisSuccessAction implements Action {
    readonly type = FETCH_WIKIS_SUCCESS;

    constructor(public payload: WikiArticle[]) {
    }
}

export class FetchWikisFailureAction implements Action {
    readonly type = FETCH_WIKIS_FAILURE;

    constructor(public payload: any) {
    }
}

export class FetchWikiAction implements Action {
    readonly type = FETCH_WIKI;

    constructor(public wikiId: string) {
    }
}

export class FetchWikiSuccessAction implements Action {
    readonly type = FETCH_WIKI_SUCCESS;

    constructor(public payload: WikiArticle) {
    }
}

export class FetchWikiFailureAction implements Action {
    readonly type = FETCH_WIKI_FAILURE;

    constructor(public payload: any) {
    }
}

export class FetchWikiBySlugAction implements Action {
    readonly type = FETCH_WIKI_BY_SLUG;

    constructor(public slug: string) {
    }
}

export class FetchWikiBySlugSuccessAction implements Action {
    readonly type = FETCH_WIKI_BY_SLUG_SUCCESS;

    constructor(public payload: WikiArticle) {
    }
}

export class FetchWikiBySlugFailureAction implements Action {
    readonly type = FETCH_WIKI_BY_SLUG_FAILURE;

    constructor(public payload: any) {
    }
}

export class UpdateWikiAction implements Action {
    readonly type = UPDATE_WIKI;

    constructor(public payload: WikiArticle) {
    }
}

export class UpdateWikiSuccessAction implements Action {
    readonly type = UPDATE_WIKI_SUCCESS;

    constructor(public payload: WikiArticle) {
    }
}

export class UpdateWikiFailureAction implements Action {
    readonly type = UPDATE_WIKI_FAILURE;

    constructor(public payload: any) {
    }
}

export class SelectWikiAction implements Action {
    readonly type = SELECT_WIKI;

    constructor(public payload: WikiArticle) {
    }
}

export class SelectWikiByIdAction implements Action {
    readonly type = SELECT_WIKI_BY_ID;

    constructor(public id: string) {
    }
}

export class SelectWikiBySlugAction implements Action {
    readonly type = SELECT_WIKI_BY_SLUG;

    constructor(public slug: string) {
    }
}

export type WikiActions =
    | CreateWikiAction
    | CreateWikiFailureAction
    | CreateWikiSuccessAction
    | DeleteWikiAction
    | DeleteWikiFailureAction
    | DeleteWikiSuccessAction
    | DismissWikisErrorAction
    | FetchWikisAction
    | FetchWikisFailureAction
    | FetchWikisSuccessAction
    | FetchWikiAction
    | FetchWikiFailureAction
    | FetchWikiSuccessAction
    | FetchWikiBySlugAction
    | FetchWikiBySlugFailureAction
    | FetchWikiBySlugSuccessAction
    | UpdateWikiAction
    | UpdateWikiFailureAction
    | UpdateWikiSuccessAction
    | SelectWikiAction
    | SelectWikiByIdAction
    | SelectWikiBySlugAction;
