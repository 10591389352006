
<div class="modal-header">
  <h4 class="modal-title mb-0">
    Add/Edit Enhanced Issue
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group row">
      <label for="issueName" class="col-sm-2 col-form-label">Issue Name</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="issueName" formControlName="issueName">
      </div>
    </div>
    <div class="form-group row">
      <label for="link" class="col-sm-2 col-form-label">Link</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="link" formControlName="link">
      </div>
    </div>
  </form>
  <div class="form-group row" *ngIf="enhancedIssue.issueId">
    <h5 class="col-sm-2 ">
      Translations
    </h5>
  </div>
  <div class="col-12" *ngIf="enhancedIssue.issueId">
    <wic-enhanced-issue-translations
      [enhancedIssueTranslations]="enhancedIssueTranslationsFacade.all$ | async"
      (added)="addTranslations($event)"
      (delete)="deleteTranslations($event)"
      (save)="updateEnhancedIssueTranslations($event)">
    </wic-enhanced-issue-translations>
  </div>
</div>
  
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
  <button type="submit" class="btn btn-primary" (click)="save()" >Save</button>
</div>
