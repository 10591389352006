import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createReportingJob } from '@state/reporting/statistics-reporting-jobs.actions';
import {
  isReportLoading,
  reportCriteria,
  reportEndDate,
  reportFailed,
  reportingJobStatus,
  reportLoadingDuration,
  usageStatisticsReports,
} from './statistics-reporting-jobs.selectors';

@Injectable({ providedIn: 'root' })
export class StatisticsReportingJobsFacade {
  isLoading$ = this.store.select(isReportLoading);
  loadEndDate$ = this.store.select(reportEndDate);
  reportCriteria$ = this.store.select(reportCriteria);
  loadingDuration$ = this.store.select(reportLoadingDuration);
  usageStatisticsReports$ = this.store.select(usageStatisticsReports);
  reportingJobStatus$ = this.store.select(reportingJobStatus);
  reportFailed$ = this.store.select(reportFailed);

  constructor(private readonly store: Store) {}

  createReportingJob(beginDate: string, endDate: string) {
    this.store.dispatch(createReportingJob({ beginDate, endDate }));
  }
}
