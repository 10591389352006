import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {APLTransform} from '@models/APLTransform';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class APLTransformService implements IAutoEntityService<APLTransform> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<APLTransform[]> {
        return this.http.get<APLTransform[]>(environment.apiRoot +
            `/admin/apl/imports/config/transforms${criteria.authorityId ? '/' + criteria.authorityId : ''}`
        );
    }

    create(entityInfo: IEntityInfo, entity: APLTransform): Observable<APLTransform> {
        return this.http.post<APLTransform>(environment.apiRoot + '/admin/apl/imports/config/transforms',
            entity
        );
    }

    update(entityInfo: IEntityInfo, entity: APLTransform): Observable<APLTransform> {
        return this.http.put<APLTransform>(environment.apiRoot +
            `/admin/apl/imports/config/transforms/${entity._id}`,
            entity
        );
    }

    delete(entityInfo: IEntityInfo, entity: APLTransform): Observable<APLTransform> {
        return this.http.delete<APLTransform>(environment.apiRoot +
            `/admin/apl/imports/config/transforms/${entity._id}`
        ).pipe(map(() => entity));
    }
}
