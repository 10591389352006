<div class="row mt-4" *ngIf="import">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    {{import._id}} Benefit Import Job
                </h5>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Authority</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-info">{{import.authorityId}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Filename</dt>
                        <dd class="col-md-9">{{import.filename}}</dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">User</dt>
                        <dd class="col-md-9">
                            <span class="text-monospace">{{import.username}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Timestamp</dt>
                        <dd class="col-md-9">{{import.timestamp}}</dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Result</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-info]="import.succeeded !== true && import.succeeded !== false"
                                  [class.badge-success]="import.succeeded === true"
                                  [class.badge-danger]="import.succeeded === false">
                                <span *ngIf="import.succeeded === true"><i class="fa fa-check"></i> Succeeded</span>
                                <span *ngIf="import.succeeded === false"><i class="fa fa-times"></i> Failed</span>
                                <span *ngIf="import.succeeded !== false && import.succeeded !== true"><i class="fa fa-question"></i> Indeterminate</span>
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item" *ngIf="import.newCountAdded > 0">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Cards Added</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-success text-white">
                                {{import.newCountAdded}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="import.oldCountRemoved > 0">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Cards Removed</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-danger text-white">
                                {{import.oldCountRemoved}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="import.newCountSkipped > 0">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Records Skipped</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-warning text-white">
                                {{import.newCountSkipped}}
                            </span>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
