import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { DemoAccount } from '@models';
import { ReplicateCard } from '@state/demo-accounts/models/replicate-card.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const BASE_URL = '/admin/demo-accounts';

@Injectable()
export class DemoAccountsService implements IAutoEntityService<DemoAccount> {
  constructor(private http: HttpClient) {}

  loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<DemoAccount[]> {
    return this.http.get<DemoAccount[]>(`${environment.apiRoot}${BASE_URL}`);
  }

  load(entityInfo: IEntityInfo, keys: any, criteria?: any): Observable<DemoAccount> {
    return this.http.get<DemoAccount>(`${environment.apiRoot}${BASE_URL}/${keys}`);
  }

  create(entityInfo: IEntityInfo, demoAccount: DemoAccount, criteria?: any): Observable<DemoAccount> {
    return this.http.post<DemoAccount>(`${environment.apiRoot}${BASE_URL}`, demoAccount);
  }

  update(entityInfo: IEntityInfo, demoAccount: DemoAccount, criteria?: any): Observable<DemoAccount> {
    return this.http.put<DemoAccount>(`${environment.apiRoot}${BASE_URL}/${demoAccount.cardNumber}`, demoAccount);
  }

  delete(entityInfo: IEntityInfo, demoAccount: DemoAccount, criteria?: any): Observable<DemoAccount> {
    return this.http.delete(`${environment.apiRoot}${BASE_URL}/${demoAccount.cardNumber}`).pipe(map(() => demoAccount));
  }

  replicateAgencyCard(replicateCard: ReplicateCard) {
    return this.http.post<DemoAccount>(`${environment.apiRoot}${BASE_URL}/replicate`, replicateCard);
  }
}
