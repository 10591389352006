/* tslint:disable */
import { Injectable } from '@angular/core';
import { Authority, DemoAccount, DemoAccountBenefitGroup } from '@models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { IAppState } from '@state/app.state';
import { DemoAccountsFacadeBase, showReplicateAgencyCard } from '@state/demo-accounts/demo-accounts.state';
import { AddEditBenefitGroupComponent } from '../wic-admin/wic-demo-accounts/add-edit-benefit-group/add-edit-benefit-group.component';
import { WicDemoAccountEditComponent } from '../wic-admin/wic-demo-accounts/wic-demo-account-edit/wic-demo-account-edit.component';
import { WicConfirmModalComponent } from '../wic-confirm-modal/wic-confirm-modal.component';
/* tslint:enable */

@Injectable()
export class DemoAccountsFacade extends DemoAccountsFacadeBase {
  constructor(private store: Store<IAppState>, private modal: NgbModal) {
    super(DemoAccount, store);
  }

  showReplicateAgencyCard(authorityId?: number) {
    this.store.dispatch(showReplicateAgencyCard({ authorityId }));
  }

  showEdit(demoAccount: DemoAccount, authorities?: Authority[]) {
    this.showEditModal(demoAccount, authorities).then(
      (editedDemoAccount: DemoAccount) => {
        this.update(editedDemoAccount);
      },
      () => {}
    );
  }

  showCreate(authorities?: Authority[]) {
    this.showEditModal(null, authorities).then(
      demoAccount => {
        this.create({ ...demoAccount, groups: [] });
      },
      () => {}
    );
  }

  showAddEditBenefitGroup(demoAccount: DemoAccount, benefitGroup?: DemoAccountBenefitGroup) {
    const modal = this.modal.open(AddEditBenefitGroupComponent, { size: 'xl' });
    modal.componentInstance.benefitGroup = benefitGroup;

    modal.result.then(
      (group: DemoAccountBenefitGroup) => {
        this.update({
          ...demoAccount,
          groups: [...demoAccount.groups.filter(g => g?.id !== benefitGroup?.id), group],
        });
      },
      () => {}
    );
  }

  showDelete(demoAccount: DemoAccount) {
    if (!demoAccount) {
      return;
    }

    this.showConfirmModal('Confirm Demo Account Deletion', 'Are you sure you want to delete this demo account?').then(
      () => {
        this.delete(demoAccount);
      },
      () => {}
    );
  }

  showDeleteBenefitGroup(demoAccount: DemoAccount, group: DemoAccountBenefitGroup) {
    if (!demoAccount || !group) {
      return;
    }

    this.showConfirmModal(
      'Confirm Benefit Group Deletion',
      'Are you sure you want to remove this benefit group from the demo account?'
    ).then(
      () => {
        this.update({
          ...demoAccount,
          groups: [...demoAccount.groups.filter(g => g?.id !== group?.id)],
        });
      },
      () => {}
    );
  }

  private showEditModal(demoAccount: DemoAccount, authorities?: Authority[]): Promise<DemoAccount> {
    const modalReference = this.modal.open(WicDemoAccountEditComponent, { size: 'lg' });
    modalReference.componentInstance.demoAccount = demoAccount || {};
    modalReference.componentInstance.authorities = authorities;
    return modalReference.result;
  }

  private showConfirmModal(title: string, message: string): Promise<DemoAccount> {
    const modalReference = this.modal.open(WicConfirmModalComponent);
    modalReference.componentInstance.modalTitle = title;
    modalReference.componentInstance.message = message;
    return modalReference.result;
  }
}
