import { FormControl, Validators } from '@angular/forms';

export class CardNumberControl extends FormControl {
  constructor(cardNumber?: string) {
    super(cardNumber, [
      Validators.required,
      Validators.pattern(/\d{16}/g),
      Validators.minLength(16),
      Validators.maxLength(16),
    ]);
  }
}
