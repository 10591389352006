<div class="row mt-4" *ngIf="microBlog">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    Micro Blog Details
                </h5>
                <i class="fa fa-fw fa-pencil float-right m-1" (click)="edited.emit(microBlog)"
                   ngbTooltip="Edit Details" placement="left"></i>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Id</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-info">{{microBlog._id}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Title</dt>
                        <dd class="col-md-9">
                            {{microBlog.title}}
                            <span class="badge badge-secondary text-white" *ngIf="microBlog.showTitleInSlide">
                                <i class="fa fa-check"></i>
                                Shown in Slide
                            </span>
                            <span class="badge badge-secondary text-white" *ngIf="!microBlog.showTitleInSlide">
                                <i class="fa fa-times"></i>
                                Not Displayed
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Group</dt>
                        <dd class="col-md-9">
                            <span *ngIf="microBlog.groups && microBlog.groups.length">{{(microBlog.groups[0])}}</span>
                            <span *ngIf="!microBlog.groups || !microBlog.groups.length"><em>No Group Specified</em></span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Type</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-info">
                                <i class="fa"
                                   [class.fa-file-text-o]="microBlog.type === 'post'"
                                   [class.fa-file-image-o]="microBlog.type === 'deck'"
                                ></i>
                                {{microBlog.type}}
                            </span>&nbsp;
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Activation Date</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-success text-white">
                                {{microBlog.activationDate}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">End Date</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-danger text-white">
                                {{microBlog.expirationDate}}
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Is Active</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-success text-white" *ngIf="microBlog.isActive">
                                Active
                            </span>
                            <span class="badge badge-danger text-white" *ngIf="!microBlog.isActive">
                                Inactive
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Authorities</dt>
                        <dd class="col-md-9">
                            <span *ngIf="!microBlog.authorities || !microBlog.authorities.length">All Authorities</span>
                            <span class="badge badge-primary text-white mr-1"
                                  *ngFor="let authority of authorities">
                                <span class="badge badge-pill badge-secondary text-white">{{authority.id}}</span>
                                {{authority.name}}
                            </span>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
