import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CardLegacyStatus} from '@models/CardLegacyStatus';
import {CardStatus} from '@models/CardStatus';

@Injectable({
    providedIn: 'root'
})
export class CardLegacyStatusService implements IAutoEntityService<CardLegacyStatus> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: any): Observable<CardLegacyStatus[]> {
        const url = (criteria && criteria.jobId) ?
            `/admin/authorities/benefits/modern-imports/${criteria.jobId}/card-legacy-status` :
            '/admin/authorities/benefits/modern-imports/card-legacy-status';
        return this.http.get<CardLegacyStatus[]>(environment.apiRoot + url, {
            params: {
                ...( criteria.filters ? {filters: criteria.filters} : {})
            }
        });
    }

    loadMany(entityInfo: IEntityInfo, criteria: any): Observable<CardLegacyStatus[]> {
        const url = (criteria && criteria.jobId) ?
            `/admin/authorities/benefits/modern-imports/${criteria.jobId}/card-status` :
            '/admin/authorities/benefits/modern-imports/card-status';
        return this.http.get<CardLegacyStatus[]>(environment.apiRoot + url, {
            params: {
                skip: criteria.skip,
                limit: criteria.limit,
                ...( criteria.filters ? {filters: criteria.filters} : {})
            }
        });
    }
}
