import {Component, OnInit} from '@angular/core';
import { AuthFacade } from '@facades/auth.facade';

enum ProfileTabs {
    Profile = 'profile',
    Notifications = 'notifications'
}

@Component({
    selector: 'wic-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {
    active = ProfileTabs.Profile;

    constructor(public auth: AuthFacade) {}
}
