import {buildState} from '@briebug/ngrx-auto-entity';
import {ServerSettings} from '@models';
import {createSelector} from '@ngrx/store';

const {initialState, selectors, entityState} = buildState(ServerSettings);

export const {
    selectAll: allServerSettings,
    selectIsLoading: isServerSettingsLoading,
    selectCurrentEntity: currentServerSettings
} = selectors;

export const rootServerSettings = createSelector(entityState, allServerSettings, (state, all) =>
    all.find(setting => setting.authorityId === 0)
);

export function serverSettingsReducer(state = initialState): any {
    return state;
}
