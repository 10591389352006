import {Pipe, PipeTransform} from '@angular/core';
import {JobStatus} from '@models/BenefitImportJob';
import {CardLegacyImportStatus} from '@models/CardLegacyStatus';
import {CardImportStatus} from '@models/CardStatus';

const statusCountMap = {
    [CardLegacyImportStatus.pending]: 'badge-info',
    [CardLegacyImportStatus.imported]: 'badge-info',
    [CardLegacyImportStatus.error]: 'badge-info',
    [CardImportStatus.identified]: 'badge-info',
    [CardImportStatus.parsed]: 'badge-info',
    [CardImportStatus.staged]: 'badge-info',
    [CardImportStatus.published]: 'badge-info',
    [CardImportStatus.error]: 'badge-info',
};

@Pipe({name: 'statusCount'})
export class StatusCountPipe implements PipeTransform {
    transform(statusCounts: {[p: string]: number}) {
        return Object.entries(statusCounts)
            .filter(([key]) => key !== 'total')
            .map(([key, value]) => ({
                status: key,
                count: value || 0,
                css: statusCountMap[key]
            }));
    }
}
