<div class="row">
    <div class="col-md-12">
        <wic-apl-message-detail
            [message]="message$ | async"
            (edited)="editMessage($event)">
        </wic-apl-message-detail>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <wic-apl-message-texts
            [message]="message$ | async"
            [texts]="texts$ | async"
            (saved)="saveTexts($event)"
            (added)="addText($event)"
            (deleted)="deleteText($event)">
        </wic-apl-message-texts>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <wic-apl-mappings-matrix
            [message]="message$ | async"
            [mappings]="mappings$ | async"
            [authorities]="authorities.active$ | async"
            [tree]="categoryLookups.categoryTree$ | async"
            [isSaving]="aplMessageMappings.isSaving$ | async"
            [isDeleting]="aplMessageMappings.isDeleting$ | async"
            (saved)="includeMappings($event[0]); excludeMappings($event[1])">
        </wic-apl-mappings-matrix>
    </div>
</div>
