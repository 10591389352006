<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <span class="badge badge-info" *ngIf="subCatConfig?._id">
                {{subCatConfig._id}}
            </span>
            {{linkDetail ? 'Edit' : 'Create'}} Link Detail
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label for="label" class="col-4 col-lg-2 col-form-label" [class.text-danger]="submitted && !form.get('label').valid">Label</label>
            <div class="col-8 col-lg-10">
                <input class="form-control" type="text" formControlName="label" name="label" id="label" [maxlength]="12">
            </div>
        </div>
        <div class="form-group row">
            <label for="url" class="col-4 col-lg-2 col-form-label" [class.text-danger]="submitted && !form.get('url').valid">URL</label>
            <div class="col-8 col-lg-10">
                <input class="form-control" type="url" formControlName="url" name="url" id="url">
            </div>
        </div>
        <div class="form-group row">
            <label for="icon" class="col-4 col-lg-2 col-form-label">Icon</label>
            <div class="col-4 col-lg-5">
                <label class="custom-file form-control">
                    <input #file class="custom-file-input"
                           id="icon"
                           type="file"
                           accept="image/jpeg"
                           (change)="imageChanged($event)"/>
                    <span class="custom-file-control"></span>
                </label>
            </div>
            <div class="col-4 col-lg-5">
                <img class="sub-cat-image" *ngIf="form.get('icon').value"
                     [src]="createImageUrl(form.get('icon').value)"/>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-4 col-lg-2 col-form-label"></div>
            <div class="col-8 col-lg-10">
                <small class="text-muted">For optimal performance, please use *.jpeg images with 78px height/width.</small>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
