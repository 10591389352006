import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceLogSessionFacade} from '@facades/device-log-session.facade';
import {DeviceLogSession} from '@models';

@Component({
    selector: 'wic-device-logs',
    templateUrl: './device-logs.component.html',
    styleUrls: ['./device-logs.component.sass']
})
export class DeviceLogsComponent {
    hideSelf = false;

    constructor(public sessions: DeviceLogSessionFacade, private route: ActivatedRoute, private router: Router) {
        this.sessions.loadAll();
    }

    viewSession(session: DeviceLogSession): void {
        this.router.navigate([session.udid, 'sessions', session.sessionId], {
            relativeTo: this.route
        });
    }
}
