import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IAuthorityMappingState as AuthorityMappingsState } from './authority-mappings.reducers';

// Selector for the AuthorityMappingsState
export const selectAuthorityMappingsState = createFeatureSelector<AuthorityMappingsState>('authorityMappings');

// TODO: Uncomment when ready to implement NGRX Entity Library
// export const {
//     selectAll: selectAllAuthorityMappings,
//     selectEntities: selectAuthorityMappingsEntities,
//     selectIds: selectAuthorityMappingsIds,
//     selectTotal: selectAuthorityMappingsTotal,
// } = adapter.selectSelectors(selectAuthorityMappingsEntityState);

// Selectors for Authority Mappings
export const selectAuthorityMappings = createSelector(
    selectAuthorityMappingsState, state => state.authorityMappings
);

export const selectAuthorityMappingsErrors = createSelector(
    selectAuthorityMappingsState, state => state.errors
);
