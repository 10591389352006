import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import {AuthorityAccount} from '@models';
import {AuthorityAccountsService} from '@services';
import {
    CREATE_AUTHORITY_ACCOUNT,
    CreateAuthorityAccountAction,
    CreateAuthorityAccountFailureAction,
    CreateAuthorityAccountSuccessAction,
    DELETE_AUTHORITY_ACCOUNT,
    DeleteAuthorityAccountAction,
    DeleteAuthorityAccountFailureAction,
    DeleteAuthorityAccountSuccessAction,
    FETCH_AUTHORITY_ACCOUNT,
    FetchAuthorityAccountAction,
    FetchAuthorityAccountFailureAction,
    FetchAuthorityAccountSuccessAction,
    UPDATE_AUTHORITY_ACCOUNT,
    UpdateAuthorityAccountAction,
    UpdateAuthorityAccountFailureAction,
    UpdateAuthorityAccountSuccessAction,
} from './accounts.actions';

@Injectable()
export class AuthorityAccountsEffects {
    @Effect()
    fetchAuthorityAccount$ = this.actions.pipe(
        ofType(FETCH_AUTHORITY_ACCOUNT),
        exhaustMap((action: FetchAuthorityAccountAction) => this.accountsService.get(action.authorityId).pipe(
            map(authority => new FetchAuthorityAccountSuccessAction(authority)),
            catchError(err => of(new FetchAuthorityAccountFailureAction(err)))
        ))
    );

    @Effect()
    createAuthorityAccount$ = this.actions.pipe(
        ofType(CREATE_AUTHORITY_ACCOUNT),
        map((action: CreateAuthorityAccountAction) => action.payload),
        exhaustMap((authority: AuthorityAccount) => this.accountsService.create(authority).pipe(
            map(created => new CreateAuthorityAccountSuccessAction(created)),
            catchError(err => of(new CreateAuthorityAccountFailureAction(err)))
        ))
    );

    @Effect()
    updateAuthorityAccount$ = this.actions.pipe(
        ofType(UPDATE_AUTHORITY_ACCOUNT),
        map((action: UpdateAuthorityAccountAction) => action.payload),
        exhaustMap((authority: AuthorityAccount) => this.accountsService.update(authority).pipe(
            map(updated => new UpdateAuthorityAccountSuccessAction(updated)),
            catchError(err => of(new UpdateAuthorityAccountFailureAction(err)))
        ))
    );

    @Effect()
    deleteAuthorityAccount$ = this.actions.pipe(
        ofType(DELETE_AUTHORITY_ACCOUNT),
        map((action: DeleteAuthorityAccountAction) => action.payload),
        exhaustMap((account: AuthorityAccount) => this.accountsService.delete(account.authorityId).pipe(
            map(id => new DeleteAuthorityAccountSuccessAction(id)),
            catchError(err => of(new DeleteAuthorityAccountFailureAction(err)))
        ))
    );

    constructor(private actions: Actions, private accountsService: AuthorityAccountsService) {
    }
}
