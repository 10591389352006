<div>
    <div class="modal-header">
        <h4 class="modal-title">{{modalTitle}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-4"><label for="value">{{label}}</label></div>
            <div class="col-8"><input class="form-control" type="text" id="value" name="value" [(ngModel)]="value"></div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.close(value)">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">No</button>
    </div>
</div>
