<form [formGroup]="form">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            Location Edit
            <span class="badge badge-info"></span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-row">
            <div class="form-group col-md-8">
                <div class="input-group">
                    <input class="form-control" placeholder="Name" formControlName="name">
                </div>
            </div>
            <div class="form-group col-md-4">
                <div class="form-check text-center" style="margin-top: 6px;">
                    <input class="form-check-input" id="isActive" type="checkbox" formControlName="isActive">
                    <label class="form-check-label" for="isActive">Is Active</label>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="status">Status</label>
                <input class="form-control" id="status" placeholder="Status" formControlName="status">
            </div>
            <div class="form-group col-md-6">
                <label for="clinicGroup">Clinic Group</label>
                <input class="form-control" id="clinicGroup" formControlName="clinicGroup">
            </div>
        </div>

        <div class="form-group">
            <div class="input-group">
                <input class="form-control" placeholder="Address" formControlName="address1">
                <input class="form-control" placeholder="Address 2" formControlName="address2">
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <input class="form-control" placeholder="City" formControlName="city">
                <input class="form-control" placeholder="State" formControlName="state">
                <input class="form-control" placeholder="Zip" formControlName="zip">
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <input class="form-control" placeholder="Phone Number" formControlName="phoneNumber1">
                <input class="form-control" placeholder="Phone Number2" formControlName="phoneNumber2">
            </div>
        </div>
       
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="latitude">Latitude</label>
            <input class="form-control" id="latitude" placeholder="Latitude" formControlName="latitude">
            <div *ngIf="form.controls.latitude.touched && form.controls.latitude.errors?.notANumber" class="text-danger">
              Latitude must be a number
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="longitude">Longitude</label>
            <input class="form-control" id="longitude" placeholder="Longitude" formControlName="longitude">
            <div *ngIf="form.controls.longitude.touched && form.controls.longitude.errors?.notANumber" class="text-danger">
              Longitude must be a number
            </div>
          </div>
        </div>
        
        <div class="form-group">
            <div class="input-group">
                <textarea class="form-control" placeholder="Location Details"
                          formControlName="locationDetails"></textarea>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="submit()">Save</button>
    </div>
</form>
