<div class="card mt-5">
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="summaries?.length">
            <thead>
            <tr>
                <th class="text-nowrap">Authority</th>
                <th class="text-center">Items</th>
                <th class="text-center">Categories</th>
                <th class="text-center">Sub Categories</th>
                <th class="text-right">Last Imported</th>
                <th class="text-center">Executed</th>
                <th class="text-center">Status</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let summary of summaries">
                <td class="">
                    <a [routerLink]="['view', summary.authorityId]">{{summary.authority || summary.authorityId}}</a>
                </td>
                <td class="text-center">
                    <span class="badge badge-warning text-white">{{summary.itemCount}}</span>
                </td>
                <td class="text-center">
                    <span class="badge badge-info text-white">{{summary.catCount}}</span>
                </td>
                <td class="text-center">
                    <span class="badge badge-info text-white">{{summary.subCatCount}}</span>
                </td>
                <td class="text-right text-monospace">
                    {{summary.lastUpdated | date:'yyyy-MM-dd h:mm aa'}}
                </td>
                <td class="text-center">
                    <span class="badge badge-pill text-white" *ngIf="summary.config"
                          [class.badge-warning]="summary.config?.skipped === true"
                          [class.badge-success]="!summary.config?.skipped">
                        <i class="fa fa-warning" *ngIf="summary.config?.skipped === true"></i>
                        <i class="fa fa-check" *ngIf="!summary.config?.skipped"></i>
                        {{summary.config?.skipped === true ? 'Skipped' : 'Run'}}
                    </span>
                </td>
                <td class="text-center">
                    <span class="badge badge-pill text-white" *ngIf="summary.config"
                          [class.badge-info]="summary.config?.skipped === true"
                          [class.badge-success]="!summary.config?.skipped && summary.config?.succeeded"
                          [class.badge-danger]="!summary.config?.skipped && !summary.config?.succeeded">
                        {{summary.config?.skipped === true ? 'Indeterminate' : summary.config?.succeeded ? 'Succeeded' : 'Failed'}}
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!summaries?.length" class="card-body">
        <p *ngIf="isLoading"><i class="fa fa-spinner fa-spin"></i> Loading APLs...</p>
        <p *ngIf="!isLoading"><em>There are no APLs.</em></p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh" [class.fa-spin]="isLoading"></i> Refresh
                </button>
            </div>
        </div>
    </div>
</div>
