/* tslint:disable */
import {Injectable} from '@angular/core';
import {Create, Delete, LoadAll, Select, Update} from '@briebug/ngrx-auto-entity';
import {APLTransform} from '@models/APLTransform';
import {ObjectId} from '@models/types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {WicAplTransformEditComponent} from '../wic-admin/wic-apl/wic-apl-imports/wic-apl-transform-edit/wic-apl-transform-edit.component';
import {WicConfirmModalComponent} from '../wic-confirm-modal/wic-confirm-modal.component';
import {allAPLTransforms, aplTransformsLoading} from '@state/apl-transform.state';

/* tslint:enable */

@Injectable({
    providedIn: 'root'
})
export class APLTransformsFacade {
    forEdit: Subject<APLTransform>;
    forDeletion: Subject<APLTransform>;

    constructor(private store: Store<IAppState>, private modal: NgbModal) {
        this.forEdit = new Subject<APLTransform>();
        this.forDeletion = new Subject<APLTransform>();
    }

    // region Selections
    selectAll(): Observable<APLTransform[]> {
        return this.store.pipe(select(allAPLTransforms));
    }

    selectOne(id: ObjectId): Observable<APLTransform[]> {
        return this.selectAll().pipe(
            map(transforms => transforms.filter(transform => transform._id === id))
        );
    }

    selectIsLoading(): Observable<boolean> {
        return this.store.pipe(select(aplTransformsLoading));
    }
    // endregion

    // region Dispatches
    setCurrent(transform: APLTransform) {
        this.store.dispatch(new Select(APLTransform, transform));
    }

    loadAllByAuthority(authorityId: number) {
        this.store.dispatch(new LoadAll(APLTransform, {authorityId}));
    }

    create(transform: APLTransform) {
        if (!transform || transform._id) {
            return;
        }

        this.store.dispatch(new Create(APLTransform, transform));
    }

    update(transform: APLTransform) {
        if (!transform || !transform._id) {
            return;
        }

        this.store.dispatch(new Update(APLTransform, transform));
    }

    delete(transform: APLTransform) {
        if (!transform) {
            return;
        }

        this.store.dispatch(new Delete(APLTransform, transform));
    }
    // endregion

    // region UI Helpers
    showEdit(transform: APLTransform) {
        if (!transform) {
            return;
        }

        this.forEdit.next(transform);

        this.showEditModal(transform)
            .then((edited: APLTransform) => {
                this.update(edited);
                this.forEdit.next(null);
            }, () => {
                this.forEdit.next(null);
            });
    }

    showCreate(authorityId: number) {
        this.showEditModal({
            authorityId,
            isEnabled: true
        } as APLTransform)
            .then(created => {
                this.create({...created});
            }, () => {
            });
    }

    showDelete(transform: APLTransform) {
        if (!transform) {
            return;
        }

        this.forDeletion.next(transform);

        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = 'Confirm APL Transformation Deletion';
        modalReference.componentInstance.message = 'Are you sure you want to delete this APL transformation?';
        modalReference.result
            .then(() => {
                this.delete(transform);
                this.forDeletion.next(null);
            }, () => {
                this.forDeletion.next(null);
            });
    }
    // endregion

    // region Helpers
    private showEditModal(transform: APLTransform): Promise<APLTransform> {
        const modalReference = this.modal.open(WicAplTransformEditComponent, {size: 'lg'});
        modalReference.componentInstance.transform = transform || {};
        return modalReference.result;
    }
    // endregion
}
