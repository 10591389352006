import {Injectable} from '@angular/core';
import {LoadAll, SelectByKey} from '@briebug/ngrx-auto-entity';
import {LocalBenefitImport} from '@models/LocalBenefitImport';
import {IImportParseError, IImportStep} from '@models/types';
import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {
    allLocalBenefitImports,
    currentLocalBenefitImport,
    currentLocalBenefitImportLog,
    currentLocalBenefitParseErrors
} from '@state/local-benefit-import.state';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LocalBenefitImportsFacade {
    constructor(private store: Store<IAppState>) {
    }

    // region Selections
    get all(): Observable<LocalBenefitImport[]> {
        return this.store.pipe(select(allLocalBenefitImports));
    }

    get selected(): Observable<LocalBenefitImport> {
        return this.store.pipe(select(currentLocalBenefitImport));
    }

    get selectedLog(): Observable<IImportStep[]> {
        return this.store.pipe(select(currentLocalBenefitImportLog));
    }

    get selectedParseErrors(): Observable<IImportParseError[]> {
        return this.store.pipe(select(currentLocalBenefitParseErrors));
    }
    // endregion

    // region Dispatches
    selectByKey(id) {
        this.store.dispatch(new SelectByKey(LocalBenefitImport, id));
    }

    loadAll() {
        this.store.dispatch(new LoadAll(LocalBenefitImport));
    }
    // endregion
}
