import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {Authority, BannerPlaylist, BinCode} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '@services';

@Component({
  selector: 'wic-authority-banner-playlist-edit',
  templateUrl: './wic-authority-banner-playlist-edit.component.html',
  styleUrls: ['./wic-authority-banner-playlist-edit.component.sass']
})
export class WicAuthorityBannerPlaylistEditComponent implements OnInit {
    @Input() authority: Authority;
    @Input() playlists: BannerPlaylist[];

    get f() {
        return this.form.controls;
    }

    form: FormGroup;
    submitted = false;

    constructor(
        private sanitizer: DomSanitizer,
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public util: UtilService
    ) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);
        this.populateForm(this.authority);
    }

    private buildForm(formBuilder) {
        const form = formBuilder.group({
            bannerPlaylistId: [null, Validators.required]
        });

        return form;
    }

    populateForm(authority: Authority) {
        if (authority) {
            this.form.patchValue({
                ...authority,
            });
        }
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        value.bannerPlaylistId = value.bannerPlaylistId === -1 ? null :  value.bannerPlaylistId;

        const authority = {
            ...this.authority,
            ...value,
        };
        this.activeModal.close(authority);
    }

}
