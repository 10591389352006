import {buildState} from '@briebug/ngrx-auto-entity';
import {Partner} from '@models';

const {initialState, selectors} = buildState(Partner);

export const {
    selectAll: allPartners
} = selectors;

export function partnerReducer(state = initialState) {
    return state;
}
