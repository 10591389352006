import { buildState } from "@briebug/ngrx-auto-entity";
import { Action, createReducer } from '@ngrx/store';
import { EnhancedIssue } from "./enhanced-issue.model";


export const {
  initialState: initialEnhancedIssueState,
  facade: EnhancedIssuesFacadeBase,
  actions: {
    loadAll: loadAllEnhancedIssues,
    create: createEnhancedIssue,
    createSuccess: createEnhancedIssueSuccess,
    replace: replaceEnhancedIssue,
    replaceSuccess: replaceEnhancedIssueSuccess,
    delete: deleteEnhancedIssue,
    deleteSuccess: deleteEnhancedIssueSuccess,
    update: moveEnhancedIssue,
    updateSuccess: moveEnhancedIssueSuccess
  },
  selectors: {
    selectAll: allEnhancedIssues,
    selectCurrentEntity: currentEnhancedIssue,
  }
} = buildState(EnhancedIssue);

const reduce = createReducer(initialEnhancedIssueState);

export function enhancedIssueReducer(state = initialEnhancedIssueState, action: Action) {
  return reduce(state, action);
}

export const ENHANCED_ISSUES_FEATURE_KEY = 'enhancedIssue';
