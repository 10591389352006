import {createSelector} from '@ngrx/store';
import {allCardStatuses, CardStatusState, cardStatusState} from './card-status.state';
import {allCardLegacyStatuses, cardLegacyStatusState} from '@state/card-status/card-legacy-status.state';

export const getLength = <T>(statuses: T[]) => statuses.length;
export const getFilterStatuses = (state: CardStatusState) => state.statusFilters || [];

export const cardStatusLength = createSelector(allCardStatuses, getLength);

export const cardStatusFilters = createSelector(cardStatusState, getFilterStatuses);

export const cardLegacyStatusLength = createSelector(allCardLegacyStatuses, getLength);

export const cardLegacyStatusFilters = createSelector(cardLegacyStatusState, getFilterStatuses);
