import {buildState} from '@briebug/ngrx-auto-entity';
import {LocalBenefitImport} from '@models/LocalBenefitImport';
import {createSelector} from '@ngrx/store';

const {initialState, selectors, entityState} = buildState(LocalBenefitImport);

export const {
    selectAll: allLocalBenefitImports,
    selectCurrentEntity: currentLocalBenefitImport
} = selectors;

export const localBenefitImportsPage = createSelector(entityState, state => {
    return state ? state.currentPage : 1;
});
export const localBenefitImportPageableCount = createSelector(entityState, state => {
    return state ? state.totalPageableCount : 0;
});

export const currentLocalBenefitImportLog = createSelector(currentLocalBenefitImport, impt => impt && impt.progress);
export const currentLocalBenefitParseErrors = createSelector(currentLocalBenefitImport, impt =>
    impt && (impt.parseError || []).slice(0, 100)
);

export function localBenefitImportReducer(state = initialState) {
    return state;
}
