import { buildState } from "@briebug/ngrx-auto-entity";
import { Action, createReducer } from "@ngrx/store";
import { StandardCategory } from "./standard-category.model";


export const {
  initialState: initialStandardCategoriesState,
  facade: StandardCategoriesFacadeBase,
  actions: {
    loadAll: loadAllStandardCategories,
    create: createStandardCategory,
    createSuccess: createStandardCategorySuccess,
    replace: replaceStandardCategory,
    replaceSuccess: replaceStandardCategorySuccess,
    delete: deleteStandardCategory,
    deleteSuccess: deleteStandardCategorySuccess,
  },
  selectors: {
    selectAll: allStandardCategories,
  }
} = buildState(StandardCategory);

const reduce = createReducer(initialStandardCategoriesState);

export function standardCateogoryReducer(state = initialStandardCategoriesState, action: Action) {
  return reduce(state, action);
}

export const STANDARD_CATEGORY_FEATURE_KEY = 'standardCategory';