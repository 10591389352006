import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Authority, Banner, BannerPlaylist} from '@models';
import {interval, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'wic-authority-banner-playlist',
  templateUrl: './wic-authority-banner-playlist.component.html',
  styleUrls: ['./wic-authority-banner-playlist.component.sass'],
})
export class WicAuthorityBannerPlaylistComponent implements OnChanges {
    @Input() authority: Authority;
    @Input() playlist: BannerPlaylist;
    @Input() banners: Banner[];
    @Output() edited = new EventEmitter<Authority>();

    currentBanner$: Observable<Banner>;
    private bannerIndex = 0;
    apiUrl = environment.apiRoot;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.banners && changes.banners.currentValue) {
            this.currentBanner$ = interval(5000).pipe(
                filter(() => !!this.banners && !!this.banners.length),
                map(() => {
                    this.bannerIndex = this.bannerIndex + 1;
                    if (this.bannerIndex >= this.banners.length) {
                        this.bannerIndex = 0;
                    }
                    return this.banners[this.bannerIndex];
                })
            );
        }
    }
}
