import {Injectable} from '@angular/core';
import {buildState, EntityActionTypes, LoadAll, LoadAllSuccess, LoadSuccess, ofEntityType, UpdateSuccess} from '@briebug/ngrx-auto-entity';
import {Banner, MenuButton} from '@models';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {MenuButtonService} from '@services';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {BannerService} from '../services/banner.service';

const {initialState, selectors} = buildState(Banner);

export const {
    selectAll: allBanners
} = selectors;

export function bannerReducer(state = initialState) {
    return state;
}

export enum BannerTypes {
    Move = '[Banner] Move'
}

export class MoveBanner implements Action {
    readonly type = BannerTypes.Move;

    constructor(public banner: Banner, public newIndex: number) {
    }
}

@Injectable()
export class BannerEffects {
    @Effect()
    reloadBanners$: Observable<Action> = this.actions.pipe(
        ofEntityType(Banner, EntityActionTypes.CreateSuccess, EntityActionTypes.UpdateSuccess, EntityActionTypes.DeleteSuccess),
        map((action: UpdateSuccess<Banner>) => {
            return new LoadAll(Banner, {playlistId: action.entity.PlaylistID});
        })
    );

    @Effect()
    moveBanner$: Observable<Action> = this.actions.pipe(
        ofType<MoveBanner>(BannerTypes.Move),
        exhaustMap(action =>
            this.bannerService.move(action.banner, action.newIndex)
                .pipe(
                    map(banners => {
                        return new LoadAllSuccess(Banner, banners);
                    }),
                    catchError(err => {
                        console.log(err);
                        return of(new LoadSuccess(Banner, action.banner));
                    })
                )
        )
    );

    constructor(private actions: Actions, private bannerService: BannerService) {

    }
}
