import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from '@models';
import * as moment from 'moment';
import { Sorter } from '../../../util';

@Component({
    selector: 'wic-users-table',
    templateUrl: './wic-users-table.component.html',
    styleUrls: ['./wic-users-table.component.sass'],
})
export class WicUsersTableComponent implements OnInit {
    @Input() users: User[];
    @Output() added = new EventEmitter();
    @Output() viewed = new EventEmitter<User>();
    @Output() refreshed = new EventEmitter();

    usersSorter = new Sorter(() => this.users, sorted => this.users = sorted);

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit() {
    }

    getFilename() {
        const now = moment();
        return `Users_${now.format()}.csv`;
    }

    buildDownloadUri(users: User[]) {
        if (!users) { return; }

        /* tslint:disable:max-line-length */
        const csv = users.reduce((lines, record) =>
            lines + `${record._id},${record.username},${record.first},${record.last},${record.authorityId || '*'},${record.isEnabled},${record.lastLogin || 'unknown'},${record.roles.join(';')}\r\n`
            , 'User ID,Username,First Name,Last Name,Authority ID,Is Enabled,Last Login,Roles\r\n');
        /* tslint:enable:max-line-length */

        return this.sanitizer.bypassSecurityTrustUrl(`data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`);
    }
}
