export class LegacyReport {
    status: number;
    from: string;
    data: LegacyReportRange;
}

export class LegacyReportRange {
    begin: string;
    end: string;
    usage: LegacyUsageRecord[];
    rxusage: LegacyRXUsageRecord[];
    devusage: LegacyDeviceUsageRecord[];
}

export class LegacyUsageRecord {
    AuthorityID: number;
    Name: string;
    UserCount: number;
    ScanCount: number;
    RxCount: number;
}

export class LegacyRXUsageRecord {
    AuthorityID: number;
    Name: string;
    UserCount: number;
    RegistrationCount: number;
}

export class LegacyDeviceUsageRecord {
    AuthorityID: number;
    Name: string;
    DeviceRegistrations: number;
    DeviceActivity: number;
}
