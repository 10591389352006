import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {ObjectId} from '@models/types';

export class ImageFileRef {
    image?: File;
}

@Entity({
    modelName: 'CardImage'
})
export class CardImage  {
    // tslint:disable-next-line:variable-name
    @Key _id?: ObjectId;
    authorityId: number;
    mime?: string;
    image?: string;
}
