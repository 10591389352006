import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { AuthFacade } from '@facades/auth.facade';
import {MenuItem} from '@models';
import {LayoutService} from '../../services/layout.service';
import { AvailableApp } from '@state/available-apps/models/available-app.entity';

@Component({
    selector: 'wic-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class NavComponent {
    @Input() showLayout: boolean;
    @Input() showNarrow: boolean;
    @Input() fullName: string;
    @Input() menuItems: MenuItem[];
    @Input() areaPath: string;
    @Input() availableApps: AvailableApp[];
    @Input() currentApp: AvailableApp;

    @Output() toggleSidebar: EventEmitter<any> = new EventEmitter();
    @Output() logout: EventEmitter<any> = new EventEmitter();
    @Output() appSelected = new EventEmitter<AvailableApp>();

    navExpanded = false;
    userExpanded = false;
    showUserMenu = false;

    showFullMenu = false;

    constructor( private layout: LayoutService, public auth: AuthFacade) {}

    onToggleNav() {
        this.showFullMenu = !this.showFullMenu;
        this.showFullMenu ? this.layout.showNav() : this.layout.hideNav();
    }

    onSelected() {
        this.showFullMenu = false;
        this.layout.hideNav();
    }
}
