import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import {DemoAccount} from '@models/DemoAccount';
import { createAction, props } from '@ngrx/store';
import { ReplicateCard } from '@state/demo-accounts/models/replicate-card.model';

export const showReplicateAgencyCard = createAction('[DemoAccounts] Show Replicate Agency Card', props<{ authorityId?: number }>());
export const replicateAgencyCard = createAction('[DemoAccounts] Replicate Agency Card', props<{ replicateCard: ReplicateCard }>());

export interface DemoAccountsState extends IEntityState<DemoAccount> {
  filterAuthorities: number[];
}

export const {
  initialState,
  selectors: {
    selectAll: allDemoAccounts,
    selectIsLoading: demoAccountsLoading,
    selectIsSaving: demoAccountSaving,
    selectIsDeleting: demoAccountDeleting,
    selectCurrentEntity: currentDemoAccount
  },
  actions: {
    select: selectDemoAccount,
    load: loadDemoAccount,
    createSuccess: createDemoAccountSuccess
  },
  facade: DemoAccountsFacadeBase
} = buildState(DemoAccount);

export function demoAccountsReducer(state = initialState) {
    return state;
}
