<div class="row mt-4">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h5 class="float-left mb-0">Enhanced Issues</h5>
        <i class="fa fa-fw fa-plus float-right m-1" (click)="addIssue.emit()"></i>
      </div>
      <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="enhancedIssues?.length">
          <thead>
            <tr>
              <th class="text-center">Display Order</th>
              <th>Name</th>
              <th>Link</th>
              <th class="text-center"><i class="fa fa-wrench"></i></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let enhancedIssue of enhancedIssues">
              <td class="text-center">{{ enhancedIssue.displayOrder }}</td>
              <td>{{ enhancedIssue.issueName }}</td>
              <td>{{ enhancedIssue.link }}</td>
              <td class="text-center">
                <i class="fa fa-arrow-up" [class.text-medium]="enhancedIssue.displayOrder <= 0" role="button" (click)="up(enhancedIssue)"></i>
                <i class="fa fa-arrow-down" [class.text-medium]="enhancedIssue.displayOrder === enhancedIssues.length - 1" role="button" (click)="down(enhancedIssue)"></i>&nbsp;
                <i class="fa fa-trash m-1" role="button" (click)="deleteIssue.emit(enhancedIssue)"></i>
                <i class="fa fa-pencil m-1" role="button" (click)="editIssue.emit(enhancedIssue)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!enhancedIssues?.length" class="col-sm-12">
        <p><em>This authority currently has no enhanced issues.</em></p>
      </div>
    </div>
  </div>
</div>
      