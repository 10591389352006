import {Component, OnInit} from '@angular/core';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {SubCategoryConfigsFacade} from '@facades/sub-category-configs.facade';
import {APLItemFacade} from '@facades/apl-item.facade';

@Component({
    selector: 'wic-apl-search',
    templateUrl: './apl-search.component.html',
    styleUrls: ['./apl-search.component.scss']
})
export class AplSearchComponent implements OnInit {
    constructor(
        public authorities: AuthoritiesFacade,
        public categoryConfigs: SubCategoryConfigsFacade,
        public aplItems: APLItemFacade) {
    }

    ngOnInit() {
        this.categoryConfigs.loadAll();
    }
}
