<router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>

<div class="row" *ngIf="!hideSelf">
    <div class="col-md-12">
        <wic-apl-messages-table
            [messages]="aplMessages.all$ | async"
            [categories]="categoryLookups.allCategories$ | async"
            [subCategories]="categoryLookups.allSubCategories$ | async"
            [forDeletion]="forDeletion$ | async"
            (added)="add()"
            (deleted)="delete($event)"
            (refreshed)="refresh()">
        </wic-apl-messages-table>
    </div>
</div>
