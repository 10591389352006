<div class="row mt-5" *ngIf="job">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    <span class="badge badge-info">{{job._id}}</span>
                    Job Details
                </h5>
                <a [routerLink]="['../../']"><i class="fa fa-times float-right m-1"></i></a>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Start Time</dt>
                        <dd class="col-md-9">
                            <span class="text-monospace">
                                {{job.startTime}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">End Time</dt>
                        <dd class="col-md-9">
                            <span class="text-monospace">
                                {{job.endTime}}
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Authority</dt>
                        <dd class="col-md-9">{{job.authorityId}}</dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">APL Filename</dt>
                        <dd class="col-md-9"><i class="fa fa-fw fa-file-text-o"></i>{{job.aplFilename}}</dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Found Multiple APL Files</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-danger]="job.foundMultipleAPLFiles"
                                  [class.badge-success]="!job.foundMultipleAPLFiles">
                                <span *ngIf="job.foundMultipleAPLFiles"><i class="fa fa-times"></i> Yes</span>
                                <span *ngIf="!job.foundMultipleAPLFiles"><i class="fa fa-check"></i> No</span>
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item" *ngIf="job?.foundALPFiles">
                    <dl class="row mb-0">
                        <dt class="col-md-3">APL Files Found</dt>
                        <dd class="col-md-9"><i class="fa fa-fw fa-file-text-o"></i>{{job.foundALPFiles[0]}}</dd>
                    </dl>
                    <dl class="row mb-0">
                        <dt class="col-md-3"></dt>
                        <dd class="col-md-9" *ngFor="let file of job.foundALPFiles.slice(1)"><i
                            class="fa fa-fw fa-file-text-o"></i>{{file}}</dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Found Multiple Files in Zip</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-danger]="job.foundMultipleFilesInZip"
                                  [class.badge-success]="!job.foundMultipleFilesInZip">
                                <span *ngIf="job.foundMultipleFilesInZip"><i class="fa fa-times"></i> Yes</span>
                                <span *ngIf="!job.foundMultipleFilesInZip"><i class="fa fa-check"></i> No</span>
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Was Run</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-warning]="job.skipped"
                                  [class.badge-success]="!job.skipped">
                                <span *ngIf="job.skipped"><i class="fa fa-warning"></i> Skipped</span>
                                <span *ngIf="!job.skipped"><i class="fa fa-check"></i> Run</span>
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Manually Triggered</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-warning]="job.isManual"
                                  [class.badge-success]="!job.isManual">
                                <span *ngIf="job.isManual"><i class="fa fa-check"></i> Yes</span>
                                <span *ngIf="!job.isManual"><i class="fa fa-times"></i> No</span>
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Result</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white"
                                  [class.badge-info]="job.succeeded !== true && job.succeeded !== false"
                                  [class.badge-success]="job.succeeded === true"
                                  [class.badge-danger]="job.succeeded === false">
                                <span *ngIf="job.succeeded === true"><i class="fa fa-check"></i> Succeeded</span>
                                <span *ngIf="job.succeeded === false"><i class="fa fa-times"></i> Failed</span>
                                <span *ngIf="job.succeeded !== false && job.succeeded !== true"><i
                                    class="fa fa-question"></i> Indeterminate</span>
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item" *ngIf="job.importResults">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Items Added</dt>
                        <dd class="col-md-9">{{job.importResults.items.added}}</dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="job.importResults">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Items Updated</dt>
                        <dd class="col-md-9">{{job.importResults.items.updated}}</dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="job.importResults">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Items Deleted</dt>
                        <dd class="col-md-9">{{job.importResults.items.deleted}}</dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="job.importResults">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Categories Updated</dt>
                        <dd class="col-md-9">{{job.importResults.categories.updated}}</dd>
                    </dl>
                </li>
                <li class="list-group-item" *ngIf="job.importResults">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Sub-Categories Updated</dt>
                        <dd class="col-md-9">{{job.importResults.subCategories.updated}}</dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
