<div class="row mt-4" *ngIf="user">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    <span class="badge badge-info" ngbTooltip="Username" placement="right">{{user._id}}</span>
                    Details
                </h5>
                <i class="fa fa-fw fa-pencil float-right m-1" (click)="edited.emit(user)"
                   ngbTooltip="Edit Details" placement="left"></i>
                <i class="fa fa-fw fa-trash float-right m-1" (click)="deleted.emit(user)"
                   ngbTooltip="Delete User" placement="left"></i>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Name</dt>
                        <dd class="col-md-9">{{user.first}} {{user.last}}</dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Email</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-warning text-white">{{user.email}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Enabled</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-default text-white"
                                  [class.badge-danger]="!user.isEnabled"
                                  [class.badge-success]="user.isEnabled">
                                {{user.isEnabled ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Pending Password Reset</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-default text-white"
                                  [class.badge-danger]="!user.reset"
                                  [class.badge-success]="user.reset">
                                {{user.reset ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Admin Access</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-default text-white"
                                  [class.badge-danger]="!user.hasAdminAccess"
                                  [class.badge-success]="user.hasAdminAccess">
                                {{user.hasAdminAccess ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Roles</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-info text-white mr-1"
                                  *ngFor="let role of user.roles">
                                {{role}}
                            </span>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
