import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { EntityService } from "app/entity.service";
import { NgModule } from "@angular/core";
import { NgrxAutoEntityModule } from "@briebug/ngrx-auto-entity";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StandardSubcategoriesEffects } from "./standard-subcategories.effects";
import { STANDARD_SUBCATEGORY_FEATURE_KEY, standardSubcategoryReducer } from "./standard-subcategories.state";
import { StandardSubcategoriesFacade } from "./standard-subcategories.facade";
import { StandardSubcategory } from "./standard-subcategory.model";

@NgModule({
  imports: [
    HttpClientModule,
    NgrxAutoEntityModule.forFeature(),
    EffectsModule.forFeature([StandardSubcategoriesEffects]),
    StoreModule.forFeature(STANDARD_SUBCATEGORY_FEATURE_KEY, standardSubcategoryReducer),
  ],
  
  providers: [
    StandardSubcategoriesFacade,
    
    { provide: StandardSubcategory, useClass: EntityService},
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],

})
export class StandardSubcategoriesFeatureModule {}