import {Component, Input, OnInit} from '@angular/core';
import { APLJob, APLJobConflict } from '@models/APLJob';

@Component({
  selector: 'wic-apl-import-job-conflict-table',
  templateUrl: './wic-apl-import-job-conflict-table.component.html',
  styleUrls: ['./wic-apl-import-job-conflict-table.component.sass']
})
export class WicAplImportJobConflictTableComponent  {
    @Input() conflicts: APLJobConflict[];
    @Input() title: string;
    @Input() valLabel: string;
    @Input() valCountLabel: string;
}
