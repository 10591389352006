<div class="row">
    <div class="col-md-12">
        <wic-card-check-benefits-table
            [benefitInfo]="benefitInfo.current$ | async"
            [authorities]="authorities.allAsLookup$ | async"
            [cardNumber]="cards.sampledCardNumber$ | async"
            (cardSpun)="cards.sampleRandomCard($event)"
            (searched)="benefitInfo.loadAll($event)">
        </wic-card-check-benefits-table>
    </div>
</div>

<div class="row">
    <div [class.col-md-6]="benefitInfo.hasOriginalRaw$ | async" [class.col-md-12]="benefitInfo.noOriginalRaw$ | async">
        <pre *ngIf="benefitInfo.currentRaw$ | async" class="language-json">
            <code class="language-json" [innerHTML]="benefitInfo.currentRaw$ | async"></code>
        </pre>
    </div>
    <div class="col-md-6">
        <pre *ngIf="benefitInfo.originalRaw$ | async" [innerHTML]="benefitInfo.originalRaw$ | async">
            <code class="language-json" [innerHTML]="benefitInfo.originalRaw$ | async"></code>
        </pre>
    </div>
</div>
