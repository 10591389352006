import {Component, OnInit} from '@angular/core';
import {LoadAll} from '@briebug/ngrx-auto-entity';
import {select, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {filter, first, map, withLatestFrom} from 'rxjs/operators';

import {MicroBlogsFacade} from '@facades/micro-blogs.facade';
import {MicroBlog} from '@models';
import {ILookup} from '@models/types';
import {IAppState} from '@state/app.state';
import {FetchAuthoritiesAction} from '@state/authorities/authorities.actions';
import {activeProviderAuthoritiesAsLookup} from '@state/authorities/authorities.selectors';
import {allMicroBlogs, currentMicroBlog, isMicroBlogsLoading, loadAllMicroBlogs} from '@state/micro-blogs.state';
import { AuthoritiesFacade } from '@facades/authorities.facade';

@Component({
    templateUrl: './wic-micro-blogs.component.html',
    styleUrls: ['./wic-micro-blogs.component.sass'],
})
export class WicMicroBlogComponent implements OnInit {
    isLoading$: Observable<boolean>;
    microBlogs$: Observable<MicroBlog[]>;
    selectedMicroBlog$: Observable<MicroBlog>;
    authorities$: Observable<ILookup[]>;
    groups$: Observable<ILookup[]>;

    private authorities: ILookup[];

    hideSelf = false;

    constructor(
      private store: Store<IAppState>, 
      private microBlogsFacade: MicroBlogsFacade,
      private authoritiesFacade: AuthoritiesFacade) {
    }

    ngOnInit() {
        this.isLoading$ = this.microBlogsFacade.isLoading;
        this.microBlogs$ = this.microBlogsFacade.all;
        this.selectedMicroBlog$ = this.microBlogsFacade.current;

        this.authorities$ = this.store.pipe(select(activeProviderAuthoritiesAsLookup));
        this.store.pipe(
            select(activeProviderAuthoritiesAsLookup),
            filter((lookup: ILookup[]) => !!lookup && !!lookup.length),
            first()
        ).subscribe(authorities => this.authorities = authorities);

        this.groups$ = this.microBlogsFacade.selectGroups();

        this.store.dispatch(new FetchAuthoritiesAction());
        this.refresh();
    }

    add() {
        of(null).pipe(
            withLatestFrom(this.authorities$, this.groups$)
        ).subscribe(([, authorities, groups]) =>
            this.microBlogsFacade.showCreate(authorities, groups)
        );
    }

    delete(microBlog: MicroBlog) {
        this.microBlogsFacade.delete(microBlog);
    }

    refresh() {
      this.microBlogsFacade.loadAll()
    }
}
