import {buildState} from '@briebug/ngrx-auto-entity';
import {BinCode} from '@models';

const {initialState, selectors} = buildState(BinCode);

export const {
    selectAll: allBinCodes,
    selectIsLoading: binCodesLoading,
    selectIsSaving: binCodeSaving,
    selectIsDeleting: binCodeDeleting,
    selectCurrentEntity: currentBinCode
} = selectors;

export function binCodeReducer(state = initialState) {
    return state;
}
