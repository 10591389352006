/* tslint:disable */
import {Injectable} from '@angular/core';
import {Create, Delete, LoadAll, Select, Update} from '@briebug/ngrx-auto-entity';
import {Authority} from '@models';
import {APLImportConfig} from '@models/APLImportConfig';
import {ObjectId} from '@models/types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {CheckImportJob, RunImportJob} from '@state/apl-import.state';
import {IAppState} from '@state/app.state';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {allAPLImportConfigs, createImportConfig, currentAPLImportConfig, deleteImportConfig, isAPLImportConfigsLoading, loadImportConfig, loadImportConfigs, updateImportConfig} from '@state/apl-import-config.state';
import {WicAplImportEditComponent} from '../wic-admin/wic-apl/wic-apl-imports/wic-apl-import-edit/wic-apl-import-edit.component';
import {WicConfirmModalComponent} from '../wic-confirm-modal/wic-confirm-modal.component';
import { currentAvailableAppId } from '@state/available-apps/available-apps.state';
/* tslint:enable */

@Injectable({
    providedIn: 'root'
})
export class APLImportConfigsFacade {
    forDeletion: Subject<APLImportConfig>;

    constructor(private store: Store<IAppState>, private modal: NgbModal) {
        this.forDeletion = new Subject<APLImportConfig>();
    }
    
    // region Selections
    get selectAll(): Observable<APLImportConfig[]> {
        return this.store.pipe(select(allAPLImportConfigs));
    }

    get selectOne(): Observable<APLImportConfig> {
      return this.store.pipe(select(currentAPLImportConfig));
    }

    get selectIsLoading(): Observable<boolean> {
        return this.store.pipe(select(isAPLImportConfigsLoading));
    }
    // endregion

    // region Dispatches
    setCurrent(config: APLImportConfig) {
        this.store.dispatch(new Select(APLImportConfig, config));
    }

    load(id: ObjectId) {
      this.store.dispatch(loadImportConfig({ id }));
    }

    loadAll() {
        this.store.dispatch(loadImportConfigs());
    }

    create(aplImportConfig: APLImportConfig) {
      if (!aplImportConfig || !aplImportConfig.authorityId || aplImportConfig._id) {
        return;
      }
      
      this.store.dispatch(createImportConfig({ aplImportConfig }));
    }

    update(aplImportConfig: APLImportConfig) {
        if (!aplImportConfig || !aplImportConfig.authorityId || !aplImportConfig._id) {
            return;
        }

        this.store.dispatch(updateImportConfig({ aplImportConfig }));
    }

    delete(aplImportConfig: APLImportConfig) {
        if (!aplImportConfig) {
            return;
        }

        this.store.dispatch(deleteImportConfig({ aplImportConfig }));
    }
    // endregion

    // region UI Helpers
    runJob(config: APLImportConfig) {
        this.store.dispatch(new RunImportJob(config.authorityId));
    }

    checkJob(config: APLImportConfig) {
        this.store.dispatch(new CheckImportJob(config.authorityId));
    }

    showEdit(config: APLImportConfig, configs: APLImportConfig[], authorities?: Authority[]) {
        this.showEditModal(config, configs, authorities)
            .then((edited: APLImportConfig) => {
                this.update(edited);
            }, () => {
            });
    }

    showCreate(configs: APLImportConfig[], authorities?: Authority[]) {
        this.showEditModal({} as APLImportConfig, configs, authorities)
            .then(config => {
                this.create({...config});
            }, () => {
            });
    }

    showDelete(config: APLImportConfig) {
        if (!config) {
            return;
        }

        this.forDeletion.next(config);

        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = 'Confirm APL Import Config Deletion';
        modalReference.componentInstance.message = 'Are you sure you want to delete this APL import config?';
        modalReference.result
            .then(() => {
                this.delete(config);
                this.forDeletion.next(null);
            }, () => {
                this.forDeletion.next(null);
            });
    }
    // endregion

    // region Helpers
    private showEditModal(config: APLImportConfig, configs: APLImportConfig[], authorities?: Authority[]): Promise<APLImportConfig> {
        const modalReference = this.modal.open(WicAplImportEditComponent, {size: 'lg'});
        modalReference.componentInstance.config = config || {};
        modalReference.componentInstance.configs = configs || [];
        modalReference.componentInstance.authorities = authorities || [];
        return modalReference.result;
    }
    // endregion
}
