import {Injectable} from '@angular/core';
import {LoadAll, Select} from '@briebug/ngrx-auto-entity';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {SubCategory} from '@models';
import {IAppState} from '@state/app.state';
import { allSubCategories, currentSubCategory, SubCategoriesFacadeBase } from '@state/sub-categories.state';

@Injectable({
    providedIn: 'root'
})
export class SubCategoriesFacade extends SubCategoriesFacadeBase {
    constructor(private store: Store<IAppState>) {
      super(SubCategory, store);
    }

    // region Selections
    selectAll(): Observable<SubCategory[]> {
        return this.store.pipe(select(allSubCategories));
    }

    selectCurrent(): Observable<SubCategory> {
        return this.store.pipe(select(currentSubCategory));
    }
    // endregion

    // region Dispatches
    setCurrent(subCategory: SubCategory) {
        this.store.dispatch(new Select(SubCategory, subCategory));
    }

    loadByAuthority(authorityId: number) {
        this.store.dispatch(new LoadAll(SubCategory, {authorityId}));
    }
    // endregion
}
