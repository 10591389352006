<div class="row mt-4" *ngIf="benefitImportJob">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    Benefit Import Job {{benefitImportJob._id}}
                </h5>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Authority</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-info">{{benefitImportJob.authorityId}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Filename</dt>
                        <dd class="col-md-9">{{benefitImportJob.filename}}</dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">User</dt>
                        <dd class="col-md-9">
                            <span class="text-monospace">{{benefitImportJob.username}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Timestamp</dt>
                        <dd class="col-md-9">{{benefitImportJob.timestamp}}</dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Job Status</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill text-white {{ benefitImportJob.status | jobStatus}}">
                                <i *ngIf="benefitImportJob.succeeded === true" class="fa fa-check"></i>
                                <i *ngIf="benefitImportJob.succeeded === false" class="fa fa-times"></i>
                                {{benefitImportJob.status}}
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item" *ngIf="benefitImportJob.statusCounts">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Card Statuses</dt>
                        <dd class="col-md-9">
                            <span>
                                {{ benefitImportJob.statusCounts.total ? ('Total: ' + benefitImportJob.statusCounts.total) : 'No Cards Tracked' }}
                            </span>
                            <br>

                            <span *ngFor="let status of (benefitImportJob.statusCounts | statusCount)"
                                class="badge text-white mr-3 {{status.css}}">
                                {{status.status}} {{status.count}}
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item" *ngIf="benefitImportJob.legacyStatusCounts">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Legacy Card Statuses</dt>
                        <dd class="col-md-9">
                            <span>
                                {{ benefitImportJob.legacyStatusCounts.total ? ('Total: ' + benefitImportJob.legacyStatusCounts.total) : 'No Cards Tracked' }}
                            </span>
                            <br>

                            <span *ngFor="let status of (benefitImportJob.legacyStatusCounts | statusCount)"
                                  class="badge text-white mr-3 {{status.css}}">
                                {{status.status}} {{status.count}}
                            </span>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
