import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthorityType} from '@models';

@Component({
    selector: 'wic-authority-types-table',
    templateUrl: './wic-authority-types-table.component.html',
    styleUrls: ['./wic-authority-types-table.component.sass']
})
export class WicAuthorityTypesTableComponent implements OnInit {
    @Input() types: AuthorityType[];
    @Output() refreshed = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

}
