import { createFeatureSelector, createSelector } from '@ngrx/store';

import {AuthorityAccount} from '@models';
import {Dictionary} from '@ngrx/entity/src/models';
import { adapter, IAuthorityAccountsState } from './accounts.reducers';

export const selectAuthorityAccountsState = createFeatureSelector<IAuthorityAccountsState>('authorityAccounts');

export const {
    selectAll: selectAllAuthorityAccounts,
    selectEntities: selectAuthorityAccountsEntities,
    selectIds: selectAuthorityAccountsIds,
    selectTotal: selectAuthorityAccountsCount,
} = adapter.getSelectors(selectAuthorityAccountsState);

export const selectCurrentAuthorityAccountId = createSelector(
    selectAuthorityAccountsState,
    (state: IAuthorityAccountsState) => state.selectedAuthorityAccountId
);

export const selectCurrentAuthorityAccount = createSelector(
    selectAuthorityAccountsEntities,
    selectCurrentAuthorityAccountId,
    (entities: Dictionary<AuthorityAccount>, id: string) => {
        return id && entities[id];
    }
);
