import {buildState, IEntityState} from '@briebug/ngrx-auto-entity';
import {APLItem} from '@models';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {exhaustMap, map} from 'rxjs/operators';
import {aplItemDeleted, aplItemsFiltered, aplItemsSearched, aplItemUnDeleted} from '@state/apl-item/apl-item.actions';
import {AplSearchService} from '../../services/apl-search.service';
import {Action, createReducer, on} from '@ngrx/store';
import {ModalControllerService} from '../../services/modal-controller.service';
import {presentModal} from '../../util/operators';

export interface APLItemState extends IEntityState<APLItem> {
    filterCriteria?: string;
}

export const {
    initialState: initialAPLItemState,
    entityState: aplItemState,
    selectors: {
        selectAll: allAPLItems,
    },
    actions: {
        loadAllSuccess: loadAllAPLItemsSuccess,
        update: updateAPLItem
    }
} = buildState(APLItem, {} as APLItemState);

export const setAPLItemsFilterCriteria = (state, {filterCriteria}) => ({...state, filterCriteria});

const reduce = createReducer(
    initialAPLItemState,
    on(aplItemsFiltered, setAPLItemsFilterCriteria)
);

export function aplItemReducer(state = initialAPLItemState, action: Action): APLItemState {
    return reduce(state, action);
}

@Injectable()
export class APLItemEffects {
    searchApl$ = createEffect(() => this.actions$.pipe(
        ofType(aplItemsSearched),
        exhaustMap(({searchCriteria}) =>
            this.aplSearch.searchApl(searchCriteria).pipe(
                map((entities) => loadAllAPLItemsSuccess({entities}))
            )
        )
    ));

    deleteAPLItem$ = createEffect(() => this.actions$.pipe(
        ofType(aplItemDeleted),
        map(({item}) => item),
        presentModal(
            () => this.modal.createConfirmModal(
                'Confirm APL Item Delete',
                'Are you sure you want to delete this APL Item?'
            )
        ),
        map(([res, item]): APLItem => ({
            ...item,
            deletedAt: new Date().toISOString(),
        })),
        map((item: APLItem) => updateAPLItem({entity: item}))
    ));

    unDeleteAPLItem$ = createEffect(() => this.actions$.pipe(
        ofType(aplItemUnDeleted),
        map(({item}) => item),
        presentModal(
            () => this.modal.createConfirmModal(
                'Confirm APL Item Un-Delete',
                'Are you sure you want to un-delete this APL Item?'
            ),
        ),
        map(([res, item]): APLItem => ({
            ...item,
            deletedAt: null,
            updatedAt: new Date().toISOString()
        })),
        map((entity) => updateAPLItem({entity}))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly aplSearch: AplSearchService,
        private readonly modal: ModalControllerService) {
    }
}
