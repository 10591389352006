import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { EntityService } from "app/entity.service";
import { STANDARD_CATEGORY_FEATURE_KEY, standardCateogoryReducer } from "./standard-categories.state";
import { StandardCategory } from "./standard-category.model";
import { NgModule } from "@angular/core";
import { StandardCategoriesFacade } from "./standard-categories.facade";
import { NgrxAutoEntityModule } from "@briebug/ngrx-auto-entity";
import { StoreModule } from "@ngrx/store";
import { StandardCategoriesEffects } from "./standard-categories.effects";
import { EffectsModule } from "@ngrx/effects";


@NgModule({
  imports: [
    HttpClientModule,
    NgrxAutoEntityModule.forFeature(),
    EffectsModule.forFeature([StandardCategoriesEffects]),
    StoreModule.forFeature(STANDARD_CATEGORY_FEATURE_KEY, standardCateogoryReducer),
  ],
  
  providers: [
    StandardCategoriesFacade,
    
    { provide: StandardCategory, useClass: EntityService},
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],

})
export class StandardCategoriesFeatureModule {}