<div class="row">
    <div class="col-md-6">
        <wic-error-report-detail [report]="errorReports.current$ | async"></wic-error-report-detail>
    </div>
    <div class="col-md-6">
        <wic-error-report-device-info [report]="errorReports.current$ | async"></wic-error-report-device-info>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <wic-error-report-screenshot [report]="errorReports.current$ | async"></wic-error-report-screenshot>
    </div>
    <div class="col-md-8">
        <wic-device-logs-table
            [sessions]="logSessions$ | async"
            [showToolbar]="false"
            [showFilter]="false"
            [lite]="true"
            (refreshed)="deviceLogs.loadAll()"
            (selected)="viewLogSession($event)">
        </wic-device-logs-table>
    </div>
</div>
