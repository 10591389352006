import { createAction, props } from '@ngrx/store';
import { EnhancedIssueTranslation } from './enhanced-issue-translations.model';
import { EnhancedIssue } from '../../enhanced-issues/feature/enhanced-issue.model';

export const loadAllByEnhancedIssue = createAction(
  '[Enhanced Issues Translations] Load All By Enhanced Issue',
  props<{ enhancedIssue: EnhancedIssue }>()
);

export const updateEnhancedIssueTranslations = createAction(
  '[Enhanced Issues Translations] Update Enhanced Issue Translations',
  props<{ enhancedIssueTranslations: EnhancedIssueTranslation[], enhancedIssue: EnhancedIssue }>()
);

export const addNewLanguage = createAction(
  '[Enhanced Issues Translations] Add new language',
  props<{ enhancedIssueTranslations: EnhancedIssueTranslation[], enhancedIssue: EnhancedIssue }>()
);

export const deleteLanguage = createAction(
  '[Enhanced Issues Translations] Delete Language',
  props<{ enhancedIssueTranslations: EnhancedIssueTranslation[], enhancedIssue: EnhancedIssue }>()
)