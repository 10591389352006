import {buildState} from '@briebug/ngrx-auto-entity';
import {SubCategoryConfig} from '@models';
import {createSelector} from '@ngrx/store';
import {IGroupedLookup} from '@models/types';

export const {
    initialState: initialSubcategoryConfigState,
    selectors: {
        selectAll: allSubCategoryConfigs,
        selectIsLoading: isSubCategoryConfigsLoading,
        selectCurrentEntity: currentSubCategoryConfig
    }
} = buildState(SubCategoryConfig);

export function subCategoryConfigReducer(state = initialSubcategoryConfigState): any {
    return state;
}

export const allCategoryConfigGroupedLookup = createSelector(
    allSubCategoryConfigs,
    (configs): IGroupedLookup[] => configs.sort((a, b) => a.categoryId - b.categoryId).map((config) => ({
        value: `${config.categoryId}_${config.subCategoryId}`,
        group: `${config.categoryDescription || 'MISC'}`,
        text: `(${config.categoryId}:${config.subCategoryId}) ${config.subCategoryDescription}`,
    }))
);
