<div class="login">
    <div class="login-flex d-flex align-items-center justify-content-center">
        <div class="card login-card">
            <div class="card-header login-header login-label">
                <h3>WIC Shopper</h3>
            </div>
            <div class="card-block login-block">
                <div class="container text-center">
                  <button class="btn btn-primary btn-lg m-5 login-label" (click)="login()">
                    Log In
                  </button>
                </div>
            </div>
            <div class="card-footer login-footer text-center">
                <a href="http://www.jpma.com" target="_blank">
                    <img class="jpma-logo" src="http://www.jpma.com/wp-content/uploads/2016/04/jpmalogo-white-SMALL.png" alt="JPMA, Inc.">
                </a>
            </div>
        </div>
    </div>
</div>
