<wic-error [errors]="errors$ | async" (dismiss)="onDismissError()" ></wic-error>
<div class="container">
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="card">
                <div class="card-block">
                    <wic-authority-mappings-table
                        #authorityMappingsTable
                        [authorityMappings]="authorityMappings$ | async"
                        (selected)="selectedAuthorityMapping = $event"
                        (deleted)="confirmDelete($event)">
                    </wic-authority-mappings-table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="card">
                <div class="card-block">
                    <wic-authority-mappings-form
                        [authorityMapping]="selectedAuthorityMapping"
                        (saved)="onSaved($event); authorityMappingsTable.onEditComplete();"
                        (canceled)="onCanceled(); authorityMappingsTable.onEditComplete();">
                    </wic-authority-mappings-form>
                </div>
            </div>
        </div>
    </div>
</div>

