<div class="card mt-5">
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="configs?.length">
            <thead>
            <tr>
                <th class="text-nowrap">Authority</th>
                <th class="text-nowrap">FTP</th>
                <th class="text-nowrap">Username</th>
                <th class="text-center">Is Enabled</th>
                <th class="text-left">Last Imported</th>
                <th class="text-center">Skipped</th>
                <th class="text-center">Status</th>
                <th class="text-center"><i class="fa fa-wrench"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let config of configs" [class.bg-danger]="config._id === forDeletion?._id">
                <td class="" [class.text-white]="config._id === forDeletion?._id">
                    <span class="badge badge-info text-white">{{config.authorityId}}</span>
                    <a [class.text-white]="config._id === forDeletion?._id"
                       [routerLink]="['view', config._id]">{{config.authority || config.authorityId}}</a>
                </td>
                <td class="" [class.text-white]="config._id === forDeletion?._id">
                    <i class="fa fa-key" *ngIf="config.isSecure" ngbTooltip="Requires SFTP"></i>
                    {{config.ftpUrl}}<span *ngIf="config.ftpPort">:{{config.ftpPort}}</span>
                </td>
                <td class="">
                    <span class="badge badge-info">{{config.username}}</span>
                </td>
                <td class="text-center">
                    <span class="badge badge-pill text-white"
                          [class.badge-success]="config.isEnabled"
                          [class.badge-danger]="!config.isEnabled">
                        {{config.isEnabled ? 'Yes' : 'No'}}
                    </span>
                </td>
                <td class="text-left text-monospace" [class.text-white]="config._id === forDeletion?._id">
                    {{config.lastRun | date:'yyyy-MM-dd'}}
                </td>
                <td class="text-center">
                    <span class="badge badge-pill text-white"
                          [class.badge-warning]="config.skipped === true"
                          [class.badge-success]="!config.skipped">
                        <i class="fa fa-warning" *ngIf="config.skipped === true"></i>
                        <i class="fa fa-check" *ngIf="!config.skipped"></i>
                        {{config.skipped === true ? 'Skipped' : 'Run'}}
                    </span>
                </td>
                <td class="text-center">
                    <span class="badge badge-pill text-white"
                          [class.badge-info]="config.skipped === true"
                          [class.badge-success]="!config.skipped && config.succeeded"
                          [class.badge-danger]="!config.skipped && !config.succeeded">
                        {{config.skipped === true ? 'Indeterminate' : config.succeeded ? 'Succeeded' : 'Failed'}}
                    </span>
                </td>
                <td class="text-right" [class.text-white]="config._id === forDeletion?._id">
                    <i class="fa fa-trash" (click)="deleted.emit(config);"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!configs?.length" class="card-body">
        <p *ngIf="isLoading"><i class="fa fa-spinner fa-spin"></i> Loading APL import configs...</p>
        <p *ngIf="!isLoading"><em>There are no APL import configs.</em></p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6">
                <!--<a class="btn btn-outline-secondary float-left" role="button"-->
                <!--[download]="getFilename()" [href]="buildDownloadUri(appointments)">-->
                <!--<i class="fa fa-download"></i> Download as CSV-->
                <!--</a>-->
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh" [class.fa-spin]="isLoading"></i> Refresh
                </button>
                <button class="btn btn-outline-primary" (click)="added.emit()">
                    <i class="fa fa-plus"></i> Add Config
                </button>
            </div>
        </div>
    </div>
</div>
