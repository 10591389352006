<div class="row">
    <div class="col-md-12">
        <wic-apl-import-job-detail [job]="job$ | async">
        </wic-apl-import-job-detail>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <wic-apl-import-job-error-detail [job]="job$ | async">
        </wic-apl-import-job-error-detail>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <wic-apl-import-job-conflict-table [conflicts]="(job$ | async)?.uomConflicts"
                                           title="Possible UOM Conflicts"
                                           valLabel="UOMs" valCountLabel="UOM Count">
        </wic-apl-import-job-conflict-table>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <wic-apl-import-job-conflict-table [conflicts]="(job$ | async)?.pkgConflicts"
                                           title="Possible Package Size Conflicts"
                                           valLabel="Package Sizes" valCountLabel="Package Size Count">
        </wic-apl-import-job-conflict-table>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <wic-apl-import-job-conflict-table [conflicts]="(job$ | async)?.descriptionConflicts"
                                           title="Possible Description Conflicts"
                                           valLabel="Descriptions" valCountLabel="Description Count">
        </wic-apl-import-job-conflict-table>
    </div>
</div>

<div class="row mb-5">
    <div class="col-md-12">
        <wic-apl-import-job-log-table [job]="job$ | async">
        </wic-apl-import-job-log-table>
    </div>
</div>
