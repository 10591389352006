import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Authority} from '@models';
import {APLImportConfig} from '@models/APLImportConfig';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '@services';

@Component({
    selector: 'wic-apl-import-edit',
    templateUrl: './wic-apl-import-edit.component.html',
    styleUrls: ['./wic-apl-import-edit.component.sass']
})
export class WicAplImportEditComponent implements OnInit {
    @Input() config: APLImportConfig;
    @Input() configs: APLImportConfig[];
    @Input() authorities: Authority[];

    get f() {
        return this.form.controls;
    }

    filteredAuthorities: Authority[];

    form: FormGroup;
    currentAuthority: Authority;
    submitted = false;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public util: UtilService
    ) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);

        this.form.controls.aplGlob.valueChanges.subscribe(glob => {
            if (glob && glob.toLowerCase().indexOf('.zip') !== -1) {
                this.form.patchValue({isZipped: true});
            } else {
                this.form.patchValue({isZipped: false});
            }
        });
        this.populateForm(this.config);
    }

    private buildForm(formBuilder) {
        const form = formBuilder.group({
            authorityId: [null, Validators.required],
            ftpUrl: [null, Validators.required],
            ftpPort: [undefined],
            ftpRoot: [undefined],
            aplGlob: [null, Validators.required],
            username: [null, Validators.required],
            password: [null, Validators.required],
            isEnabled: [true],
            isSecure: [true],
            isZipped: [undefined],
            filterDuplicates: [undefined],
            requiresZeroFill: [undefined],
            requiresTransformation: [undefined],
            requiresOverrides: [undefined],
            applySharedOverrides: [true]
        });

        return form;
    }

    populateForm(config) {
        if (this.authorities && this.configs) {
            this.filteredAuthorities = this.authorities.filter(authority =>
                !this.configs.some(cfg => cfg.authorityId === authority.id)
            );
        }

        if (this.authorities && this.config) {
            this.currentAuthority = this.authorities.find(authority => config.authorityId === authority.id);
        }

        if (this.authorities && this.authorities.length === 1 && !this.currentAuthority) {
            this.currentAuthority = this.authorities[0];
        }

        this.form.patchValue({
            ...config,
            authorityId: this.currentAuthority ? +this.currentAuthority.id : null
        });
    }

    chooseAuthority(authority: Authority) {
        this.currentAuthority = authority;
        this.form.patchValue({
            authorityId: +authority.id,
        });
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        const config = {
            ...value,
            _id: !this.config._id ? undefined : this.config._id
        };
        this.activeModal.close(config);
    }
}
