import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { buildState } from '@briebug/ngrx-auto-entity';
import { AuthorityLocation } from '@models/AuthorityLocation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Action, createAction, createReducer, props, Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectCurrentAuthorityId } from '@state/authorities/authorities.selectors';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { routeIncludesPath } from '../../../util/operators';

export const {
    initialState: initialAuthorityLocationState,
    facade: AuthorityLocationFacadeBase,
    actions: {
        loadAll: loadAllAuthorityLocations,
        create: createLocation,
        update: updateLocation
    }
} = buildState(AuthorityLocation);

export const saveLocation = createAction('[AuthorityLocation] Save Location', props<{ location: AuthorityLocation }>());

const reduce = createReducer(initialAuthorityLocationState);

export function authorityLocationReducer(state = initialAuthorityLocationState, action: Action) {
    return reduce(state, action);
}

@Injectable()
export class AuthorityLocationEffects {
    constructor(private actions$: Actions, private store: Store<AppState>) {
    }

    loadLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            routeIncludesPath('/admin/authorities/list/view/'),
            switchMap(() => this.store.select(selectCurrentAuthorityId).pipe(
                filter(val => !!val)
            )),
            map(authorityId => loadAllAuthorityLocations({
                criteria: {
                    authorityId
                }
            })),
        ),
    );

    createLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveLocation),
            withLatestFrom(this.store.select(selectCurrentAuthorityId)),
            map(([{location}, authorityId]) =>
                location.locationId
                    ? updateLocation({entity: location})
                    : createLocation({entity: {...location, authorityId: authorityId + ''}})
            ),
        ),
    );
}
