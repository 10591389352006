import {Component, OnInit} from '@angular/core';
import {LoadPage} from '@briebug/ngrx-auto-entity';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {AppointmentReminder} from '@models/Appointment';
import {ILookup} from '@models/types';
import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {
    allAppointmentReminders,
    appointmentRemindersPage,
    appointmentRemindersPageableCount,
} from '@state/appointment-reminder.state';
import {Observable} from 'rxjs';
import {distinctUntilChanged, tap} from 'rxjs/operators';

const PAGE_SIZE = 100;

@Component({
    selector: 'wic-appointment-reminders-list',
    templateUrl: './wic-appointment-reminders-list.component.html',
    styleUrls: ['./wic-appointment-reminders-list.component.sass'],
})
export class WicAppointmentRemindersListComponent implements OnInit {
    appointments$: Observable<AppointmentReminder[]>;
    currentPage$: Observable<number>;
    totalPageableCount$: Observable<number>;

    hideSelf = false;

    private page = 1;

    constructor(private store: Store<IAppState>, public authorities: AuthoritiesFacade) {
    }

    ngOnInit() {
        this.appointments$ = this.store.pipe(select(allAppointmentReminders));
        this.currentPage$ = this.store.pipe(
            select(appointmentRemindersPage),
            distinctUntilChanged(),
            tap(currentPage => {
                console.log(currentPage);
            })
        );
        this.totalPageableCount$ = this.store.pipe(
            select(appointmentRemindersPageableCount),
            distinctUntilChanged(),
            tap(totalCount => {
                console.log(totalCount);
            })
        );

        this.authorities.loadAll();

        this.refresh({
            page: 1
        });
    }

    refresh(searchCriteria) {
        this.store.dispatch(new LoadPage(AppointmentReminder, {
            page: searchCriteria.page,
            size: PAGE_SIZE,
        }, {
            authorityId: searchCriteria.authority || 0,
            criteria: searchCriteria.criteria,
            startDate: searchCriteria.startDate,
            endDate: searchCriteria.endDate,
        }));
    }
}
