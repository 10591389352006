import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WikiArticle } from '@models';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class WikiService {
    constructor(private http: HttpClient) {
    }

    list(): Observable<WikiArticle[]> {
        return this.http.get<WikiArticle[]>(`${environment.apiRoot}/admin/wiki`);
    }

    get(id: string): Observable<WikiArticle> {
        return this.http.get<WikiArticle>(`${environment.apiRoot}/admin/wiki/${id}`);
    }

    getBySlug(slug: string): Observable<WikiArticle> {
        return this.http.get<WikiArticle>(`${environment.apiRoot}/admin/wiki/${slug}`);
    }

    create(article: WikiArticle): Observable<WikiArticle> {
        return this.http.post<WikiArticle>(`${environment.apiRoot}/admin/wiki`, article);
    }

    update(article: WikiArticle): Observable<WikiArticle> {
        return this.http.patch<WikiArticle>(`${environment.apiRoot}/admin/wiki/${article._id}`, article);
    }

    replace(article: WikiArticle): Observable<WikiArticle> {
        return this.http.put<WikiArticle>(`${environment.apiRoot}/admin/wiki/${article._id}`, article);
    }

    delete(id: string): Observable<any> {
        return this.http.delete(`${environment.apiRoot}/admin/wiki/${id}`);
    }
}
