import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'BinCode'
})
export class BinCode {
    // tslint:disable-next-line:variable-name
    @Key _id: string;
    AuthorityID: number;
    BINCode: string;

    name?: string;
    endpoint: string;
    integration: string;
    username?: string;
    password?: string;
    isEnabled: boolean;
    systemId: number;
    // conduent integration only?
    proxyId?: number;
    mISSystemId?: string;
    connectSystemId?: string;
    workstationId?: string;
}
