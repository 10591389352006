import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Select } from '@briebug/ngrx-auto-entity';
import { ILookup } from '@models/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@state/app.state';
import { Observable } from 'rxjs';
import { WicSelectItemModalComponent } from '../../../wic-select-item-modal/wic-select-item-modal.component';
import { Translation } from './translation.entity';
import {
  allTranslations,
  currentTranslation,
  loadTranslationsByGroupAndKey,
  removeTranslation,
  saveTranslation,
  showAddTranslation,
  TranslationsFacadeBase,
} from './translations.state';
import { languagesAsLookup } from '../languages/language.selectors';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class TranslationsFacade extends TranslationsFacadeBase {
  languages$ = this.store.select(languagesAsLookup);
  
  constructor(private store: Store<IAppState>, private modals: NgbModal) {
    super(Translation, store);
  }

  selectAll(): Observable<Translation[]> {
    return this.store.select(allTranslations);
  }

  selectCurrent(): Observable<Translation> {
    return this.store.pipe(select(currentTranslation));
  }

  setCurrent(translation: Translation) {
    this.store.dispatch(new Select(Translation, translation));
  }

  loadByGroupAndKey({ group, key }: Partial<Translation>) {
    this.store.dispatch(loadTranslationsByGroupAndKey({ group, key }));
  }

  add(template: Partial<Translation>) {
    this.store.dispatch(showAddTranslation({ template }));
  }

  save(translation: Translation): void {
    this.store.dispatch(saveTranslation({ translation }));
  }

  remove(translation: Translation) {
    this.store.dispatch(removeTranslation({ translation }));
  }

  // Used widely
  openSelectLangModal(content: FormArray) {
    const lookup$ = this.languages$.pipe(
      map((langs) => langs = langs.filter(item => !content.controls.some(ctrl => ctrl.value.languageCode === item.id)))
    );

    const modal = this.modals.open(WicSelectItemModalComponent);
    modal.componentInstance.modalTitle = 'Add Translation';
    modal.componentInstance.label = 'Language Code';
    modal.componentInstance.items$ = lookup$;
    return modal;
  }
}
