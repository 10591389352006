import {Component, Input, OnInit} from '@angular/core';
import {APLJob} from '@models/APLJob';

@Component({
    selector: 'wic-apl-import-job-detail',
    templateUrl: './wic-apl-import-job-detail.component.html',
    styleUrls: ['./wic-apl-import-job-detail.component.sass']
})
export class WicAplImportJobDetailComponent implements OnInit {
    @Input() job: APLJob;

    constructor() {
    }

    ngOnInit() {
    }

}
