import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Partner} from '@models';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PartnerService implements IAutoEntityService<Partner> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo): Observable<Partner[]> {
        return this.http.get<Partner[]>(environment.apiRoot + '/admin/partners');
    }

    create(entityInfo: IEntityInfo, entity: Partner): Observable<Partner> {
        return this.http.post<Partner>(environment.apiRoot +
            `/admin/partners/${entity.PartnerID}`, entity
        );
    }

    update(entityInfo: IEntityInfo, entity: Partner): Observable<Partner> {
        return this.http.put<Partner>(environment.apiRoot +
            `/admin/partners/${entity.PartnerID}`, entity
        );
    }
}
