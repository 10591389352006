import {Component, EventEmitter, Input, Output} from '@angular/core';
import {APLSummary} from '@models/APLSummary';

@Component({
  selector: 'wic-apl-summary-table',
  templateUrl: './wic-apl-summary-table.component.html',
  styleUrls: ['./wic-apl-summary-table.component.sass']
})
export class WicAplSummaryTableComponent  {
    @Input() summaries: APLSummary[];
    @Input() isLoading = false;
    @Output() refreshed = new EventEmitter();
}
