import {Injectable} from '@angular/core';
import {DeviceLogEntry} from '@models';
import {Store} from '@ngrx/store';
import {AppState} from '@state/app.state';
import {
    currentLevels,
    currentTags,
    DeviceLogEntryFacadeBase,
    levelHidden,
    levelShown,
    tagAdded,
    tagRemoved
} from '@state/device-log-entry.state';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DeviceLogEntryFacade extends DeviceLogEntryFacadeBase {
    get currentLevels$(): Observable<string[]> {
        return this.store.select(currentLevels);
    }

    get currentTags$(): Observable<string[]> {
        return this.store.select(currentTags);
    }

    constructor(private store: Store<AppState>) {
        super(DeviceLogEntry, store);
    }

    hideLevel(level: string): void {
        this.store.dispatch(levelHidden({level}));
    }

    showLevel(level: string): void {
        this.store.dispatch(levelShown({level}));
    }

    addTag(tag: string): void {
        this.store.dispatch(tagAdded({tag}));
    }

    removeTag(tag: string): void {
        this.store.dispatch(tagRemoved({tag}));
    }
}
