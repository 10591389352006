<div class="d-flex flex-column justify-content-center h-100">
    <div class="d-flex align-self-center align-items-center justify-content-center h-100 w-50">
        <div class="card">
            <div class="card-header">
                <h2 class="text-muted text-uppercase">
                    <i class="fa fa-warning"></i>
                    Something is missing
                </h2>
            </div>
            <div class="card-body">
                <h4>The page you are looking for could not be found!</h4>
            </div>
        </div>
    </div>
    <div class="d-flex align-self-end align-items-center justify-content-center bg-light w-100" style="height: 10em">
        <h1 class="text-gray">404 NOT FOUND</h1>
    </div>
</div>
