import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MicroBlog, ServerSettings, Slide} from '@models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {from} from 'rxjs';

import {WicConfirmModalComponent} from '../../../../../wic-confirm-modal/wic-confirm-modal.component';
import {WicSlideEditComponent} from './wic-slide-edit/wic-slide-edit.component';

@Component({
    selector: 'wic-micro-blog-deck',
    templateUrl: './wic-micro-blog-deck.component.html',
    styleUrls: ['./wic-micro-blog-deck.component.sass']
})
export class WicMicroBlogDeckComponent implements OnInit, OnChanges {
    @Input() microBlog: MicroBlog;
    @Input() rootSettings: ServerSettings;
    @Output() saved = new EventEmitter<MicroBlog>();

    deck: Slide[] = [];

    constructor(private sanitizer: DomSanitizer, private modal: NgbModal) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.microBlog && this.microBlog.deck) {
            this.deck = [...this.microBlog.deck];
        }
    }

    addFiles(files: FileList | File[]) {
        from(files).subscribe(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.deck = [
                    ...this.deck,
                    { image: reader.result.toString() }
                ];
            };
        });
    }

    moveSlideLeft(index: number) {
        if (index <= 0 || index >= this.deck.length) {
            return;
        }

        this.deck = [
            ...this.deck.slice(0, index - 1),
            this.deck[index],
            ...this.deck.slice(index - 1, index),
            ...this.deck.slice(index + 1)
        ];
    }

    moveSlideRight(index: number) {
        if (index < 0 || index >= this.deck.length - 1) {
            return;
        }

        this.deck = [
            ...this.deck.slice(0, index),
            ...this.deck.slice(index + 1, index + 2),
            this.deck[index],
            ...this.deck.slice(index + 2)
        ];
    }

    editSlide(index: number) {
        if (index < 0 || index >= this.deck.length) {
            return;
        }

        const modal = this.modal.open(WicSlideEditComponent);
        modal.componentInstance.slide = this.deck[index];
        modal.result.then(editedSlide => {
            this.deck = [
                ...this.deck.slice(0, index),
                editedSlide,
                ...this.deck.slice(index + 1)
            ];
        });
    }

    removeSlide(index: number) {
        if (index < 0 || index >= this.deck.length) {
            return;
        }

        const modal = this.modal.open(WicConfirmModalComponent);
        modal.componentInstance.modalTitle = 'Micro Blog Deck';
        modal.componentInstance.message = 'Remove this slide?';
        modal.result.then(() => {
            this.deck = [
                ...this.deck.slice(0, index),
                ...this.deck.slice(index + 1)
            ];
        });
    }

    createImageUrl(uri: string) {
        if (uri.startsWith('data:')) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(uri);
        } else {
            return this.rootSettings.microBlogImageUrl.replace(':filename', uri);
        }
    }

    save(microBlog: MicroBlog, deck: Slide[]) {
        this.saved.emit({
            ...this.microBlog,
            deck: [...deck]
        });
    }
}
