import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {APLOverride} from '@models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class APLOverrideService implements IAutoEntityService<APLOverride> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<APLOverride[]> {
        return this.http.get<APLOverride[]>(environment.apiRoot +
            `/admin/apl/imports/config/overrides${criteria.authorityId ? '/' + criteria.authorityId : ''}`
        );
    }

    create(entityInfo: IEntityInfo, entity: APLOverride): Observable<APLOverride> {
        return this.http.post<APLOverride>(environment.apiRoot + '/admin/apl/imports/config/overrides',
            entity
        );
    }

    update(entityInfo: IEntityInfo, entity: APLOverride): Observable<APLOverride> {
        return this.http.put<APLOverride>(environment.apiRoot +
            `/admin/apl/imports/config/overrides/${entity._id}`,
            entity
        );
    }

    delete(entityInfo: IEntityInfo, entity: APLOverride): Observable<APLOverride> {
        return this.http.delete<APLOverride>(environment.apiRoot +
            `/admin/apl/imports/config/overrides/${entity._id}`
        ).pipe(map(() => entity));
    }
}
