import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'ErrorReport',
    comparer: (a: ErrorReport, b: ErrorReport) => b.timestamp.localeCompare(a.timestamp)
})
export class ErrorReport {
    // tslint:disable-next-line:variable-name
    @Key _id: string;
    timestamp: string;
    title: string;
    message: string;
    detailedMessage?: string;
    uniqueDeviceId: string;
    sessionId: string;
    screenshot?: string;
    deviceInfo?: {
        cordova: string;
        model: string;
        platform: string;
        udid: string;
        version: string;
        manufacturer: string;
        isVirtual: string;
        appVersion: string;
    };
    authorityId?: number;
    cardNumber?: string;
}
