/* tslint:disable */
import {Injectable} from '@angular/core';
import {Create, Delete, LoadAll, Select, Update} from '@briebug/ngrx-auto-entity';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';

import {CategoryLookup, LinkDetail, SubCategoryConfig, SubCategoryLookup} from '@models';
import {IAppState} from '@state/app.state';
import {allCategoryConfigGroupedLookup, allSubCategoryConfigs, currentSubCategoryConfig} from '@state/sub-category-configs.state';
import {WicSubCategoryConfigEditComponent} from '../wic-admin/wic-sub-category-configs/wic-sub-category-config-edit/wic-sub-category-config-edit.component';
import {WicSubCategoryConfigLinkEditComponent} from '../wic-admin/wic-sub-category-configs/wic-sub-category-config-link-edit/wic-sub-category-config-link-edit.component';
import {WicConfirmModalComponent} from '../wic-confirm-modal/wic-confirm-modal.component';

/* tslint:enable */

@Injectable({
    providedIn: 'root'
})
export class SubCategoryConfigsFacade {
    allAsGroupedLookup$ = this.store.pipe(select(allCategoryConfigGroupedLookup));

    forDeletion: Subject<SubCategoryConfig>;
    forEdit: Subject<SubCategoryConfig>;

    forDeletionLink: Subject<LinkDetail>;
    forEditLink: Subject<LinkDetail>;

    constructor(private store: Store<IAppState>, private modal: NgbModal) {
        this.forDeletion = new Subject<SubCategoryConfig>();
        this.forEdit = new Subject<SubCategoryConfig>();

        this.forDeletionLink = new Subject<LinkDetail>();
        this.forEditLink = new Subject<LinkDetail>();
    }

    // region Selections
    selectAll(): Observable<SubCategoryConfig[]> {
        return this.store.pipe(select(allSubCategoryConfigs));
    }

    selectCurrent(): Observable<SubCategoryConfig> {
        return this.store.pipe(select(currentSubCategoryConfig));
    }

    // endregion

    // region Dispatches
    setCurrent(subCategoryConfig: SubCategoryConfig) {
        this.store.dispatch(new Select(SubCategoryConfig, subCategoryConfig));
    }

    loadAll() {
        this.store.dispatch(new LoadAll(SubCategoryConfig));
    }

    create(subCategoryConfig: SubCategoryConfig) {
        this.store.dispatch(new Create(SubCategoryConfig, subCategoryConfig));
    }

    update(subCategoryConfig: SubCategoryConfig) {
        this.store.dispatch(new Update(SubCategoryConfig, subCategoryConfig));
    }

    delete(subCategoryConfig: SubCategoryConfig) {
        this.store.dispatch(new Delete(SubCategoryConfig, subCategoryConfig));
    }

    // endregion

    // region UI
    showEdit(subCategoryConfig: SubCategoryConfig, subCategoryConfigs: SubCategoryConfig[],
             categories?: CategoryLookup[], subCategories?: SubCategoryLookup[]) {
        this.forEdit.next(subCategoryConfig);
        this.showEditModal(subCategoryConfig, subCategoryConfigs, categories, subCategories)
            .then((editedConfig: SubCategoryConfig) => {
                this.update(editedConfig);
                this.forEdit.next(null);
            }, () => {
                this.forEdit.next(null);
            });
    }

    showCreate(subCategoryConfigs: SubCategoryConfig[], categories?: CategoryLookup[], subCategories?: SubCategoryLookup[]) {
        this.forEdit.next(null);
        this.showEditModal(null, subCategoryConfigs, categories, subCategories)
            .then((editedConfig: SubCategoryConfig) => {
                this.create(editedConfig);
            }, () => {
            });
    }

    showDelete(subCategoryConfig: SubCategoryConfig) {
        if (!subCategoryConfig) {
            return;
        }

        this.forDeletion.next(subCategoryConfig);

        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = 'Confirm Sub Category Config Deletion';
        modalReference.componentInstance.message = 'Are you sure you want to delete the configuration for this sub category?';
        modalReference.result
            .then(() => {
                this.delete(subCategoryConfig);
                this.forDeletion.next(null);
            }, () => {
                this.forDeletion.next(null);
            });
    }

    showEditLink(subCategoryConfig: SubCategoryConfig, linkDetail: LinkDetail) {
        if (!subCategoryConfig || !linkDetail) {
            return;
        }

        this.forEditLink.next(linkDetail);

        this.showEditLinkModal(subCategoryConfig, linkDetail)
            .then((editedConfig: SubCategoryConfig) => {
                this.update(editedConfig);
                this.forEditLink.next(null);
            }, () => {
                this.forEditLink.next(null);
            });
    }

    showCreateLink(subCategoryConfig: SubCategoryConfig) {
        this.forEditLink.next(null);
        this.showEditLinkModal(subCategoryConfig, null)
            .then((editedConfig: SubCategoryConfig) => {
                this.update(editedConfig);
            }, () => {
            });
    }

    showDeleteLink(subCategoryConfig: SubCategoryConfig, linkDetail: LinkDetail) {
        if (!subCategoryConfig || !linkDetail) {
            return;
        }

        this.forDeletionLink.next(linkDetail);

        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = 'Confirm Link Deletion';
        modalReference.componentInstance.message = 'Are you sure you want to delete this link?';
        modalReference.result
            .then(() => {
                this.update({
                    ...subCategoryConfig,
                    linkDetails: [
                        ...subCategoryConfig.linkDetails.filter(link => link !== linkDetail)
                    ]
                });
                this.forDeletionLink.next(null);
            }, () => {
                this.forDeletionLink.next(null);
            });
    }

    // endregion

    // region Helpers
    private showEditModal(subCategoryConfig: SubCategoryConfig, subCateoryConfigs: SubCategoryConfig[],
                          categories?: CategoryLookup[], subCategories?: SubCategoryLookup[]): Promise<SubCategoryConfig> {
        const ref = this.modal.open(WicSubCategoryConfigEditComponent, {size: 'lg'});
        ref.componentInstance.categories = categories || [];
        ref.componentInstance.subCategories = subCategories || [];
        ref.componentInstance.subCatConfigs = subCateoryConfigs || [];
        ref.componentInstance.subCatConfig = subCategoryConfig;
        return ref.result;
    }

    private showEditLinkModal(subCategoryConfig: SubCategoryConfig, linkDetail: LinkDetail): Promise<SubCategoryConfig> {
        const ref = this.modal.open(WicSubCategoryConfigLinkEditComponent, {size: 'lg'});
        ref.componentInstance.subCatConfig = subCategoryConfig;
        ref.componentInstance.linkDetail = linkDetail;
        return ref.result;
    }

    // endregion
}
