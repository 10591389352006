<div class="row mt-4" *ngIf="authority">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">Card Image</h5>
                <i class="fa fa-fw fa-pencil float-right m-1" (click)="edit(authority, cardImage)"></i>
            </div>
            <div class="card-body text-center">
                <img class="auth-card-image" [src]="cardUrl">
            </div>
        </div>
    </div>
</div>
