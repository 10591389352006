import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthoritiesFacade } from '@facades/authorities.facade';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CardNumberControl } from '../demo-account.form';

export interface ReplicateCardControls {
  authorityId: FormControl;
  cardNumber: FormControl;
  householdId: FormControl;
  demoCardNumber: FormControl;
  name: FormControl;
}

@Component({
  selector: 'wic-replicate-card-modal',
  templateUrl: './replicate-card-modal.component.html',
  styleUrls: ['./replicate-card-modal.component.scss'],
})
export class ReplicateCardModalComponent implements OnInit {
  @Input() authorityId?: number;
  form: FormGroup;

  get f(): ReplicateCardControls {
    return this.form.controls as any;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private builder: FormBuilder,
    public authorities: AuthoritiesFacade
  ) {}

  ngOnInit(): void {
    this.form = this.builder.group({
      authorityId: this.builder.control(this.authorityId, Validators.required),
      cardNumber: new CardNumberControl(),
      householdId: this.builder.control(null),
      demoCardNumber: new CardNumberControl(),
      name: this.builder.control(null, Validators.required),
    });
  }

  save() {
    if (this.form.invalid) {
      return;
    }
    this.activeModal.close({ ...this.form.value });
  }
}
