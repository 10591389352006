import {Pipe, PipeTransform} from '@angular/core';
import {CategoryLookup} from '@models';

@Pipe({name: 'sortByCategoryId'})
export class SortByCategoryIdPipe implements PipeTransform {
    transform(categories: CategoryLookup[]): CategoryLookup[] {
        return categories.sort(
            (a, b) => a.categoryId - b.categoryId
        );
    }
}
