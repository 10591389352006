import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppointmentImport} from '@models/Appointment';

@Component({
    selector: 'wic-appointment-imports-table',
    templateUrl: './wic-appointment-imports-table.component.html',
    styleUrls: ['./wic-appointment-imports-table.component.sass'],
})
export class WicAppointmentImportsTableComponent {
    @Input() apptImports: AppointmentImport[];
    @Output() refreshed = new EventEmitter();
}
