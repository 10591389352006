import {Action} from '@ngrx/store';

import {AuthorityFlag} from '@models';
import {type} from '../shared/utils';

export const DISMISS_AUTHORITY_FLAGS_ERROR = type('[Authority Flags] Dismiss error');
export const FETCH_AUTHORITY_FLAGS = type('[Authority Flags] Fetch authority flags');
export const FETCH_AUTHORITY_FLAGS_SUCCESS = type('[Authority Flags] Fetch authority flags succeeded');
export const FETCH_AUTHORITY_FLAGS_FAILURE = type('[Authority Flags] Fetch authority flags failed');
export const UPDATE_AUTHORITY_FLAG = type('[Authority Flags] Update authority flag');
export const UPDATE_AUTHORITY_FLAG_SUCCESS = type('[Authority Flags] Update authority flag succeeded');
export const UPDATE_AUTHORITY_FLAG_FAILURE = type('[Authority Flags] Update authority flag failed');

export const SELECT_AUTHORITY_FLAG = type('[Authority Flags] Select authority flag');
export const SELECT_AUTHORITY_FLAG_BY_ID = type('[Authority Flags] Select authority flag by id');

export class DismissAuthorityFlagsErrorAction implements Action {
    readonly type = DISMISS_AUTHORITY_FLAGS_ERROR;
}

export class FetchAuthorityFlagsAction implements Action {
    readonly type = FETCH_AUTHORITY_FLAGS;
}

export class FetchAuthorityFlagsSuccessAction implements Action {
    readonly type = FETCH_AUTHORITY_FLAGS_SUCCESS;

    constructor(public payload: AuthorityFlag[]) {
    }
}

export class FetchAuthorityFlagsFailureAction implements Action {
    readonly type = FETCH_AUTHORITY_FLAGS_FAILURE;

    constructor(public payload: any) {
    }
}

export class UpdateAuthorityFlagAction implements Action {
    readonly type = UPDATE_AUTHORITY_FLAG;

    constructor(public payload: AuthorityFlag) {
    }
}

export class UpdateAuthorityFlagSuccessAction implements Action {
    readonly type = UPDATE_AUTHORITY_FLAG_SUCCESS;

    constructor(public payload: AuthorityFlag) {
    }
}

export class UpdateAuthorityFlagFailureAction implements Action {
    readonly type = UPDATE_AUTHORITY_FLAG_FAILURE;

    constructor(public payload: any) {
    }
}

export class SelectAuthorityFlagAction implements Action {
    readonly type = SELECT_AUTHORITY_FLAG;

    constructor(public payload: AuthorityFlag) {
    }
}

export class SelectAuthorityFlagByIdAction implements Action {
    readonly type = SELECT_AUTHORITY_FLAG_BY_ID;

    constructor(public payload: number) {
    }
}

export type AuthorityFlagsActions =
    | DismissAuthorityFlagsErrorAction
    | FetchAuthorityFlagsAction
    | FetchAuthorityFlagsFailureAction
    | FetchAuthorityFlagsSuccessAction
    | UpdateAuthorityFlagAction
    | UpdateAuthorityFlagFailureAction
    | UpdateAuthorityFlagSuccessAction
    | SelectAuthorityFlagAction
    | SelectAuthorityFlagByIdAction;
