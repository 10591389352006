import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppointmentImportsFacade} from '@facades/appointment-imports.facade';
import {AppointmentImport} from '@models';
import {IImportParseError, IImportStep} from '@models/types';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

@Component({
  selector: 'wic-wic-appointment-import-view',
  templateUrl: './wic-appointment-import-view.component.html',
  styleUrls: ['./wic-appointment-import-view.component.sass']
})
export class WicAppointmentImportViewComponent implements OnInit {
    import$: Observable<AppointmentImport>;
    log$: Observable<IImportStep[]>;
    parseErrors$: Observable<IImportParseError[]>;

    constructor(private activatedRoute: ActivatedRoute, private appointmentImportsFacade: AppointmentImportsFacade) {
    }

    ngOnInit() {
        this.import$ = this.appointmentImportsFacade.selected;
        this.log$ = this.appointmentImportsFacade.selectedLog;
        this.parseErrors$ = this.appointmentImportsFacade.selectedParseErrors;
        this.activatedRoute.paramMap.pipe(
            first(params => params.has('id')),
            map(params => params.get('id'))
        ).subscribe(id =>
            this.appointmentImportsFacade.selectByKey(id)
        );

        this.appointmentImportsFacade.loadAll();
    }
}
