import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {APLMessage, APLMessageMapping, Authority, CategoryLookup, CategoryTree, SubCategoryLookup} from '@models';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

const isAuthority = (item: Authority | CategoryTree | SubCategoryLookup): item is Authority =>
    (item as Authority).id != null;

const isSubCategory = (item: Authority | CategoryTree | SubCategoryLookup): item is SubCategoryLookup =>
    (item as SubCategoryLookup).subCategoryId != null;

const isCategory = (item: Authority | CategoryTree | SubCategoryLookup): item is CategoryTree =>
    !isSubCategory(item) && (item as CategoryTree).categoryId != null;

@Component({
    selector: 'wic-apl-mappings-matrix',
    templateUrl: './wic-apl-mappings-matrix.component.html',
    styleUrls: ['./wic-apl-mappings-matrix.component.scss']
})
export class WicAplMappingsMatrixComponent implements OnInit, OnChanges {
    @Input() mappings: APLMessageMapping[];
    @Input() message: APLMessage;
    @Input() authorities: Authority[];
    @Input() tree: CategoryTree[];
    @Input() isSaving: boolean;
    @Input() isDeleting: boolean;

    @Output() canceled = new EventEmitter();
    @Output() saved = new EventEmitter<[APLMessageMapping[], APLMessageMapping[]]>();

    formChecksum$: Observable<number>;

    isInitializing = true;
    isPreppingSave = false;
    shownCategories = [];
    unlockedAuthorities = [];
    unlockedCategories = [];
    lockedSubCategories = [];
    form: FormGroup;

    authorityControls(category: CategoryTree, subCategory: SubCategoryLookup, authority: Authority): FormControl {
        return ((this.form.controls[`c${category.categoryId}`] as FormGroup)
            .controls[`s${subCategory.subCategoryId}`] as FormGroup)
            .controls[`a${authority.id}`] as FormControl;
    }

    constructor(private builder: FormBuilder) {
    }

    ngOnInit(): void {
        this.isInitializing = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!this.authorities && !!this.authorities.length &&
            !!this.tree && !!this.tree.length &&
            !!this.mappings
        ) {
            this.isInitializing = true;
            const group = {};
            this.tree.forEach(category =>
                group[`c${category.categoryId}`] = new FormGroup(
                    category.subCategories.reduce((catGroup, subCategory) => (
                        catGroup['s' + subCategory.subCategoryId] = new FormGroup(
                            this.authorities.reduce((scatGroup, authority) => (
                                scatGroup['a' + authority.id] = new FormControl(false),
                                    scatGroup
                            ), {})
                        ), catGroup
                    ), {})
                )
            );

            this.form = this.builder.group(group);

            this.formChecksum$ = this.form.valueChanges.pipe(map(() => Math.random() * 100000000));

            if (!!this.mappings.length) {
                this.mappings.forEach(mapping => {
                    const cgroup = (this.form.controls['c' + mapping.categoryId] || {controls: {}}) as FormGroup;
                    const sgroup = (cgroup.controls['s' + mapping.subCategoryId] || {controls: {}}) as FormGroup;
                    const actrl = sgroup.controls['a' + mapping.authorityId] as FormControl;
                    // tslint:disable-next-line:no-unused-expression
                    actrl ? actrl.setValue(true) : void (0);
                });
            }
            this.isInitializing = false;
        }
    }

    save() {
        this.isPreppingSave = true;

        const included = Object.keys(this.form.controls).reduce((c, ckey) =>
                Object.keys((this.form.controls[ckey] as FormGroup).controls).reduce((s, skey) =>
                        Object.keys(((this.form.controls[ckey] as FormGroup).controls[skey] as FormGroup).controls).reduce((a, akey) =>
                                (((this.form.controls[ckey] as FormGroup).controls[skey] as FormGroup).controls[akey] as FormControl).value
                                    ? (a.push(({
                                        _id: ((this.mappings || []).find(mapping =>
                                                mapping.authorityId === +akey.substr(1) &&
                                                mapping.categoryId === +ckey.substr(1) &&
                                                mapping.subCategoryId === +skey.substr(1)
                                            ) || {_id: undefined}
                                        )._id,
                                        categoryId: +ckey.substr(1),
                                        subCategoryId: +skey.substr(1),
                                        authorityId: +akey.substr(1),
                                        messageId: this.message._id
                                    })), a)
                                    : a
                            , s)
                    , c)
            , []);

        const excluded = (this.mappings || []).filter(mapping =>
            !included.some(inc =>
                inc.authorityId === mapping.authorityId &&
                inc.categoryId === mapping.categoryId &&
                inc.subCategoryId === mapping.subCategoryId
            )
        );

        this.saved.emit([included, excluded]);
        setTimeout(() => this.isPreppingSave = false, 3000);
    }

    bulkRemove(item: Authority | CategoryTree | SubCategoryLookup): void {
        isAuthority(item)
            ? this.bulkSetAuthority(item, false)
            : isCategory(item)
            ? this.bulkSetCategory(item, false)
            : this.bulkSetSubCategory(item, false);
    }

    bulkAdd(item: Authority | CategoryTree | SubCategoryLookup): void {
        isAuthority(item)
            ? this.bulkSetAuthority(item, true)
            : isCategory(item)
            ? this.bulkSetCategory(item, true)
            : this.bulkSetSubCategory(item, true);
    }

    private bulkSetAuthority(authority: Authority, flag: boolean): void {
        if (!this.unlockedAuthorities.includes(`${authority.id}`)) {
            return;
        }

        for (const category of this.tree) {
            if (!this.unlockedCategories.includes(`${category.categoryId}`)) {
                continue;
            }

            const categoryGroup = this.form.controls[`c${category.categoryId}`] as FormGroup;
            for (const subCategory of category.subCategories) {
                if (this.lockedSubCategories.includes(`${subCategory.categoryId}_${subCategory.subCategoryId}`)) {
                    continue;
                }

                const subCategoryGroup = categoryGroup.controls[`s${subCategory.subCategoryId}`] as FormGroup;
                const authorityControl = subCategoryGroup.controls[`a${authority.id}`] as FormControl;
                authorityControl.setValue(flag);
            }
        }
    }

    private bulkSetCategory(category: CategoryTree, flag: boolean): void {
        if (!this.unlockedCategories.includes(`${category.categoryId}`)) {
            return;
        }

        const categoryGroup = this.form.controls[`c${category.categoryId}`] as FormGroup;
        for (const subCategory of category.subCategories) {
            if (this.lockedSubCategories.includes(`${subCategory.categoryId}_${subCategory.subCategoryId}`)) {
                continue;
            }

            const subCategoryGroup = categoryGroup.controls[`s${subCategory.subCategoryId}`] as FormGroup;
            for (const authority of this.authorities) {
                if (!this.unlockedAuthorities.includes(`${authority.id}`)) {
                    continue;
                }

                const authorityControl = subCategoryGroup.controls[`a${authority.id}`] as FormControl;
                authorityControl.setValue(flag);
            }
        }
    }

    private bulkSetSubCategory(subCategory: SubCategoryLookup, flag: boolean): void {
        if (
            !this.unlockedCategories.includes(`${subCategory.categoryId}`) ||
            this.lockedSubCategories.includes(`${subCategory.categoryId}_${subCategory.subCategoryId}`)
        ) {
            return;
        }

        const categoryGroup = this.form.controls[`c${subCategory.categoryId}`] as FormGroup;
        const subCategoryGroup = categoryGroup.controls[`s${subCategory.subCategoryId}`] as FormGroup;
        for (const authority of this.authorities) {
            if (!this.unlockedAuthorities.includes(`${authority.id}`)) {
                continue;
            }

            const authorityControl = subCategoryGroup.controls[`a${authority.id}`] as FormControl;
            authorityControl.setValue(flag);
        }

    }

    lockAll<T>(unlocks: string[]): void {
        unlocks.splice(0, unlocks.length);
    }

    unlockAll<T>(items: T[], unlocks: string[], ...props: Array<keyof T>): void {
        unlocks.splice(0, unlocks.length);
        items.forEach(item => unlocks.push(
            props.reduce((k, prop) => k ? `${k}_${item[prop]}` : `${item[prop]}`, '')
        ));
    }

    toggleUnlocked<T>(item: T, unlocks: string[], ...props: Array<keyof T>): void {
        const key = props.reduce((k, prop) => k ? `${k}_${item[prop]}` : `${item[prop]}`, '');
        const index = unlocks.indexOf(key);
        index > -1 ? this.lockUnlocked(index, unlocks) : this.unlockUnlocked(key, unlocks);
    }

    private lockUnlocked(index: number, unlocks: string[]): void {
        unlocks.splice(index, 1);
    }

    private unlockUnlocked(key: string, unlocks: string[]): void {
        unlocks.push(key);
    }

    toggleLocked<T>(item: T, locks: string[], ...props: Array<keyof T>): void {
        const key = props.reduce((k, prop) => k ? `${k}_${item[prop]}` : `${item[prop]}`, '');
        const index = locks.indexOf(key);
        index > -1 ? this.unlock(index, locks) : this.lock(key, locks);
    }

    private unlock(index: number, locks: string[]): void {
        locks.splice(index, 1);
    }

    private lock(key: string, locks: string[]): void {
        locks.push(key);
    }

    toggle(category: CategoryLookup) {
        const index = this.shownCategories.indexOf(category.categoryId);
        index > -1 ? this.hide(index) : this.show(category.categoryId);
    }

    private show(categoryId: number) {
        this.shownCategories.push(categoryId);
    }

    private hide(index: number) {
        this.shownCategories.splice(index, 1);
    }
}
