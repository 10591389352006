import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {MicroBlog} from '@models';

@Component({
    selector: 'wic-micro-blog-content',
    templateUrl: './wic-micro-blog-content.component.html',
    styleUrls: ['./wic-micro-blog-content.component.sass']
})
export class WicMicroBlogContentComponent implements OnInit {
    @Input() microBlog: MicroBlog;
    @Output() saved = new EventEmitter<MicroBlog>();

    constructor() {
    }

    ngOnInit() {
    }

}
