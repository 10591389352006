<div class="container">
    <router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>
</div>

<div *ngIf="!hideSelf">
    <div class="row mb-5">
        <div class="col-md-12">
            <wic-authority-banner-playlists-table
                [playlists]="playlists$ | async"
                (refreshed)="refresh()"
                (added)="add()"
                (edited)="view($event)"
                (remove)="remove($event)"
            ></wic-authority-banner-playlists-table>
        </div>
    </div>
</div>
