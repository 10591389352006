import {Component, Input} from '@angular/core';
import {BenefitImportJob} from '@models/BenefitImportJob';

@Component({
    selector: 'wic-import-job-list',
    templateUrl: './import-job-list.component.html',
    styleUrls: ['./import-job-list.component.sass']
})
export class ImportJobListComponent {
    @Input() benefitImportJobs: BenefitImportJob[];
}
