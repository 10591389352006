import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MbscSelectOptions } from '@mobiscroll/angular';
import { IGroupedLookup } from '@models/types';

@Component({
    selector: 'wic-select-authority',
    templateUrl: './select-authority.component.html',
    styleUrls: ['./select-authority.component.scss']
})
export class SelectAuthorityComponent implements OnInit {
    @Input() authorities: IGroupedLookup[];
    @Input() control: FormControl;
    @Input() multi = true;
    @Output() selected = new EventEmitter<IGroupedLookup>();

    selectOptions: MbscSelectOptions;

    ngOnInit() {
        this.selectOptions = {
            display: 'bubble',
            touchUi: false,
            select: this.multi ? 'multiple' : 'single',
            filter: true,
            groupLabel: '&nbsp;',
            group: {
                header: true,
                groupWheel: true,
            },
            onSet: ({valueText}: { valueText?: string }) =>
                this.selected.emit(this.authorities.find(auth => auth.text === valueText))
        };
    }
}
