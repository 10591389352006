import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BannerPlaylistsFacade} from '@facades/banner-playlists.facade';
import {BannerPlaylist} from '@models';
import {Observable} from 'rxjs';

@Component({
    selector: 'wic-authority-banners',
    templateUrl: './wic-authority-banners.component.html',
    styleUrls: ['./wic-authority-banners.component.sass']
})
export class WicAuthorityBannersComponent implements OnInit {
    playlists$: Observable<BannerPlaylist[]>;

    hideSelf = false;

    constructor(private router: Router, private route: ActivatedRoute, private bannerPlaylistsFacade: BannerPlaylistsFacade) {
    }

    ngOnInit() {
        this.playlists$ = this.bannerPlaylistsFacade.all;
        this.refresh();
    }

    refresh() {
        this.bannerPlaylistsFacade.loadAll();
    }

    add() {
        this.bannerPlaylistsFacade.showCreate();
    }

    view(playlist: BannerPlaylist) {
        this.router.navigate(['view', playlist.PlaylistID], { relativeTo: this.route });
    }

    remove(playlist: BannerPlaylist) {
        this.bannerPlaylistsFacade.delete(playlist);
    }
}
