import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Authority } from '@models';
import * as moment from 'moment';
import {Sorter} from '../../../util';

@Component({
    selector: 'wic-authorities-table',
    templateUrl: './wic-authorities-table.component.html',
    styleUrls: ['./wic-authorities-table.component.sass'],
})
export class WicAuthoritiesTableComponent implements OnInit {
    @Input() authorities: Authority[];
    @Output() refreshed = new EventEmitter();
    @Output() added = new EventEmitter();

    form: FormGroup;

    authoritiesSorter = new Sorter(() => this.authorities, sorted => this.authorities = sorted);

    typesOptions = [
        { id: 0, name: 'ITEM VERIFICATION ONLY' },
        { id: 1, name: 'OFFLINE WIC EBT' },
        { id: 2, name: 'ONLINE WIC EBT'},
        { id: 3, name: 'NUMBERED VOUCHER Check' },
        { id: 4, name: 'NON SUBSCRIBER' },
    ];

    activesOptions = [
        { id: true, name: 'Yes' },
        { id: false, name: 'No' },
    ];

    private criteria?: string = null;
    private types?: number[] = [];
    private active = true;

    constructor(private formBuilder: FormBuilder, private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.form = this.buildForm();

        this.form.controls.criteria.valueChanges.subscribe(criteria => {
            this.criteria = criteria;
        });

        this.form.controls.types.valueChanges.subscribe(types => {
            if (!types || (types && types.some(t => t === null))) {
                this.types = null;
                this.form.controls.types.reset();
            } else {
                this.types = types;
            }
        });

        this.form.controls.active.valueChanges.subscribe(active => {
            this.active = active && active.length ? active[0] : null;
        });
    }

    private buildForm() {
        return this.formBuilder.group({
            criteria: this.criteria,
            types: this.types,
            active: this.active,
        });
    }

    filter(authorities: Authority[]) {
        const filtered = authorities.filter(authority => {
            if (!this.criteria && (this.types === null || !this.types.length) && this.active === null) {
                return true;
            }

            let isMatch = false;
            if (this.criteria) {
                const regex = new RegExp(this.criteria, 'i');
                isMatch = regex.test(authority.name) || regex.test(authority.shortName);
            }

            if (this.types && this.types.length) {
                isMatch = isMatch || this.types.includes(authority.type);
            }

            if (this.active !== null) {
                isMatch = isMatch || this.active === authority.isActive;
            }

            return isMatch;
        });

        return filtered;
    }

    getFilename() {
        const now = moment();
        return `Authorities_${now.format()}.csv`;
    }

    buildDownloadUri(authorities: Authority[]) {
        if (!authorities) { return; }

        const csv = authorities.reduce((lines, record) =>
            lines + `${record.id},"${record.name}","${record.shortName}",${record.type},${record.isActive}\r\n`
            , 'AuthorityID,Name,Short Name,Type,Is Active\r\n');

        return this.sanitizer.bypassSecurityTrustUrl(`data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`);
    }
}
