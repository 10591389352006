import {buildState, IEntityState} from '@briebug/ngrx-auto-entity';
import {CardStatus} from '@models/CardStatus';
import {Action, createReducer, on} from '@ngrx/store';
import {statusFiltersUpdated} from '@state/card-status/card-status.actions';

export interface CardStatusState extends IEntityState<CardStatus> {
    statusFilters?: string[];
}

export const {
    initialState: initialCardStatusState,
    entityState: cardStatusState,
    selectors: {
        selectAll: allCardStatuses,
    }
} = buildState(CardStatus, { statusFilters: ['ERRORED'] } as CardStatusState);

export const setStatusFilters = (state, {statusFilters}): CardStatusState => ({...state, statusFilters});

const reduce = createReducer(
    initialCardStatusState,
    on(statusFiltersUpdated, setStatusFilters)
);

export function cardStatusReducer(state = initialCardStatusState, action: Action): CardStatusState {
    return reduce(state, action);
}
