<div class="row mt-4" *ngIf="session">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    <span class="badge badge-info">{{session.deviceInfo.model}}</span> Device Logging Session
                </h5>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Unique Device ID</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-udid">{{session.udid}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Session ID</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-session">{{session.sessionId}}</span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Device</dt>
                        <dd class="col-md-9">{{session.deviceInfo.manufacturer}} {{session.deviceInfo.model}}</dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Platform</dt>
                        <dd class="col-md-9">{{session.deviceInfo.platform}} {{session.deviceInfo.version}}</dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Serial #</dt>
                        <dd class="col-md-9">
                            <span class="text-monospace">{{session.deviceInfo.serial}}</span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Cordova</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill"
                                  [class.badge-success]="session.deviceInfo.cordova !== 'n/a'"
                                  [class.badge-danger]="session.deviceInfo.cordova === 'n/a'">
                                {{session.deviceInfo.cordova}}
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Platform Type</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill"
                                  [class.badge-warning]="session.deviceInfo.isVirtual"
                                  [class.badge-success]="!session.deviceInfo.isVirtual">
                                {{session.deviceInfo.isVirtual ? 'Emulator' : 'Device'}}
                            </span>
                        </dd>
                    </dl>
                </li>

                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Initiated At</dt>
                        <dd class="col-md-9 text-monospace">{{session.registeredAt | date:'MMM dd, y @ hh:mm:ss'}}</dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Updated At</dt>
                        <dd class="col-md-9 text-monospace">
                            <strong>{{session.lastUpdatedAt | date:'MMM dd, y @ hh:mm:ss'}}</strong>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>


<div class="card mt-4">
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                <h5 class="float-left mb-0">Log Entry Stream</h5>
            </div>
        </div>
    </div>
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                <span class="badge badge-pill badge-larger mr-2" role="button"
                      [ngClass]="'badge-' + ('trace' | ifIncluded:filteredLevels | ifFalsy:'OFF' | toCaps)"
                      (click)="toggleLevel('trace')">
                    Trace
                </span>
                <span class="badge badge-pill badge-larger mr-2" role="button"
                      [ngClass]="'badge-' + ('debug' | ifIncluded:filteredLevels | ifFalsy:'OFF' | toCaps)"
                      (click)="toggleLevel('debug')">
                    Debug
                </span>
                <span class="badge badge-pill badge-larger mr-2" role="button"
                      [ngClass]="'badge-' + ('info' | ifIncluded:filteredLevels | ifFalsy:'OFF' | toCaps)"
                      (click)="toggleLevel('info')">
                    Info
                </span>
                <span class="badge badge-pill badge-larger mr-2" role="button"
                      [ngClass]="'badge-' + ('warn' | ifIncluded:filteredLevels | ifFalsy:'OFF' | toCaps)"
                      (click)="toggleLevel('warn')">
                    Warn
                </span>
                <span class="badge badge-pill badge-larger mr-2" role="button"
                      [ngClass]="'badge-' + ('error' | ifIncluded:filteredLevels | ifFalsy:'OFF' | toCaps)"
                      (click)="toggleLevel('error')">
                    Error
                </span>
            </div>
        </div>
        <div class="row mt-3" *ngIf="!!filteredTags && !!filteredTags.length">
            <div class="col-11">
                <span class="badge badge-selected badge-out badge-larger mr-3" *ngFor="let tag of filteredTags" (click)="tagRemoved.emit(tag)">
                    {{tag}} <i class="fa fa-fw fa-times-circle"></i>
                </span>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="!!entries?.length">
            <thead>
            <tr>
                <td></td>
                <th>Timestamp</th>
                <th class="w-75">Message</th>
            </tr>
            </thead>
            <tbody *ngFor="let entry of entries | filterByLevel:filteredLevels | filterByTags:filteredTags">
            <tr>
                <td><span class="badge badge-pill" [ngClass]="'badge-' + entry.type">{{entry.type}}</span></td>
                <td><span class="badge badge-info">{{entry.timestamp | date:'yyyy-MM-dd hh:mm:ss'}}</span></td>
                <td class="text-left">
                    <input class="invisible-input" [value]="entry.message">
                </td>
            </tr>
            <tr>
                <td colspan="3" class="text-monospace">Tags:
                    <span class="badge badge-tag mr-2" role="button" (click)="toggleTag(tag)"
                          *ngFor="let tag of entry.tags">{{tag}}</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!entries?.length" class="card-body">
        <p *ngIf="!isLoading"><em>There are no log entries for this session yet.</em></p>
        <p *ngIf="isLoading"><em>Loading log entries...</em></p>
    </div>
</div>
