import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Pipe({name: 'datePickerFormat'})
export class DatePickerFormatPipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {
    }

    transform(date: NgbDateStruct, format?: string, timezone?: string, locale?: string): string | null {
        return this.datePipe.transform(`${date.year}-${date.month}-${date.day}`, format, timezone, locale);
    }
}
