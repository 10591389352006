<div class="row mt-4">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">BIN Codes</h5>
                <i class="fa fa-fw fa-plus float-right m-1" (click)="added.emit()"></i>
                <i class="fa fa-fw fa-refresh float-right m-1" (click)="refreshed.emit()"></i>
            </div>
            <div class="">
                <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="binCodes?.length">
                    <thead>
                    <tr>
                        <th class="">Code</th>
                        <th class="text-center">Benefits Enabled</th>
                        <th class="d-none d-md-table-cell">Benefits Endpoint</th>
                        <th class="text-center">Integration</th>
                        <th class="text-center"><i class="fa fa-wrench"></i></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let binCode of binCodes">
                        <td class="">{{binCode.BINCode}}</td>
                        <td class="text-center">
                            <span class="badge badge-pill badge-default text-white"
                                  [class.badge-danger]="!binCode.isEnabled"
                                  [class.badge-success]="binCode.isEnabled">
                                {{binCode.isEnabled ? 'Yes' : 'No'}}
                            </span>
                        </td>
                        <td class="d-none d-md-table-cell">
                            <input class="invisible-textarea" [value]="binCode.endpoint">
                        </td>
                        <td class="text-center">
                            <span class="badge badge-default badge-info text-white">
                                {{binCode.integration}}
                            </span>
                        </td>
                        <td class="text-center">
                            <i class="fa fa-trash m-1" (click)="deleted.emit(binCode)"></i>
                            <i class="fa fa-pencil m-1" (click)="edited.emit(binCode)"></i>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="!binCodes?.length" class="col-sm-12">
                <p><em>This authority currently has no bin codes.</em></p>
            </div>
        </div>
    </div>
</div>
