<div>
    <div class="modal-header">
        <h4 class="modal-title">{{modalTitle}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p [innerHTML]="message"></p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.close('yes')">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('no')">No</button>
    </div>
</div>
