import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, Subscription} from 'rxjs';
import {DefaultProjectorFn, MemoizedSelector, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {TypedAction} from '@ngrx/store/src/models';

export class CardStatusVirtualSource<T> extends DataSource<T | undefined> {
    private subscription = new Subscription();

    constructor(
        private store: Store<IAppState>,
        private selector: MemoizedSelector<any, T[], DefaultProjectorFn<T[]>>,
        private action: (props: { range: {start: number, end: number } } ) => TypedAction<any>) {
        super();
    }

    connect(collectionViewer: CollectionViewer): Observable<(T | undefined)[]> {
        this.subscription.add(collectionViewer.viewChange.subscribe(range => {
            this.store.dispatch(this.action({range}));
        }));
        return this.store.select(this.selector);
    }

    disconnect(): void {
        this.subscription.unsubscribe();
    }
}
