import {Injectable} from '@angular/core';
import {DeleteMany, LoadAll, UpdateMany, Upsert, UpsertMany} from '@briebug/ngrx-auto-entity';
import {APLMessageMapping} from '@models';
import {Store} from '@ngrx/store';
import {APLMessageMappingFacadeBase} from '@state/apl-message-mappings.state';
import {IAppState} from '@state/app.state';

@Injectable({
    providedIn: 'root'
})
export class APLMessageMappingsFacade extends APLMessageMappingFacadeBase {
    constructor(private store: Store<IAppState>) {
        super(APLMessageMapping, store);
    }

    // region Dispatches
    loadByMapping(messageId: string) {
        this.store.dispatch(new LoadAll(APLMessageMapping, {messageId}));
    }

    include(mappings: APLMessageMapping[]): void {
        this.store.dispatch(new UpsertMany(APLMessageMapping, mappings));
    }

    exclude(mappings: APLMessageMapping[]): void {
        this.store.dispatch(new DeleteMany(APLMessageMapping, mappings));
    }
    // endregion
}
