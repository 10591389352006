<div class="row mt-4">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">Banners</h5>
                <i class="fa fa-fw fa-eye float-right m-1" role="button" [routerLink]="['banners', playlist?.PlaylistID]"></i>
                <i class="fa fa-fw fa-pencil float-right m-1" role="button" (click)="edited.emit(authority)"></i>
            </div>
            <div class="card-body text-center">
                <span class="" *ngIf="!playlist">No active playlist</span>
                <span class="" *ngIf="!!playlist">{{playlist.PlaylistName}}</span>
                <img class="pt-1" [src]="apiUrl + '/authorities/banners/' + currentBanner?.BannerID"
                     *ngIf="!!playlist && currentBanner$ | async as currentBanner" style="width: 100%">
            </div>
        </div>
    </div>
</div>
