import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'MenuButton'
})
export class MenuButton {
    @Key linkId: number;
    linkTypeId: number;
    linkType: string;
    isActive: boolean;
    hasIcon: boolean;
    order: number;
    flags: number;
    text: string;
    url: string;
    backgroundColor: number;
    textColor: number;
    activatedOn: number;
    deactivatedOn: number;
    imageDataUrl?: string;
}
