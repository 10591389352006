import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthorityLocation } from '@models/AuthorityLocation';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { numberValidator } from 'app/util/form-validators';

const defaultVal = <T, U extends keyof T>(obj: T, key: U, value: T[U]) =>
    (obj ? obj[key] : value) || value;

@Component({
    selector: 'wic-wic-authority-location-edit',
    templateUrl: './wic-authority-location-edit.component.html',
    styleUrls: ['./wic-authority-location-edit.component.scss']
})
export class WicAuthorityLocationEditComponent implements OnInit {
    @Input() location: AuthorityLocation;
    form: FormGroup;

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            clinicGroup: [defaultVal(this.location, 'clinicGroup', '0')],
            status: [defaultVal(this.location, 'status', 0)],
            isActive: [defaultVal(this.location, 'isActive', true)],
            name: [defaultVal(this.location, 'name', ''), Validators.required],
            address1: [defaultVal(this.location, 'address1', ''), Validators.required],
            address2: [defaultVal(this.location, 'address2', '')],
            city: [defaultVal(this.location, 'city', ''), Validators.required],
            state: [defaultVal(this.location, 'state', ''), Validators.required],
            zip: [defaultVal(this.location, 'zip', ''), Validators.required],
            phoneNumber1: [defaultVal(this.location, 'phoneNumber1', '')],
            phoneNumber2: [defaultVal(this.location, 'phoneNumber2', '')],
            emailAddress: [defaultVal(this.location, 'emailAddress', '')],
            webAddress: [defaultVal(this.location, 'webAddress', '')],
            longitude: [defaultVal(this.location, 'longitude', 0), numberValidator()],
            latitude: [defaultVal(this.location, 'latitude', 0), numberValidator()],
            locationDetails: [defaultVal(this.location, 'locationDetails', '')],
        });
    }

    submit() {
        if (this.form.invalid) {
            return;
        }
        this.activeModal.close({...this.location, ...this.form.value});
    }
}
