import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LocalBenefitImportsFacade} from '@facades/local-benefit-imports.facade';
import {LocalBenefitImport} from '@models/LocalBenefitImport';
import {IImportParseError, IImportStep} from '@models/types';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

@Component({
    selector: 'wic-benefit-import-view',
    templateUrl: './wic-benefit-import-view.component.html',
    styleUrls: ['./wic-benefit-import-view.component.sass']
})
export class WicBenefitImportViewComponent implements OnInit {
    import$: Observable<LocalBenefitImport>;
    log$: Observable<IImportStep[]>;
    parseErrors$: Observable<IImportParseError[]>;

    constructor(private activatedRoute: ActivatedRoute, private localBenefitImportsFacade: LocalBenefitImportsFacade) {
    }

    ngOnInit() {
        this.import$ = this.localBenefitImportsFacade.selected;
        this.log$ = this.localBenefitImportsFacade.selectedLog;
        this.parseErrors$ = this.localBenefitImportsFacade.selectedParseErrors;
        this.activatedRoute.paramMap.pipe(
            first(params => params.has('id')),
            map(params => params.get('id'))
        ).subscribe(id =>
            this.localBenefitImportsFacade.selectByKey(id)
        );

        this.localBenefitImportsFacade.loadAll();
    }
}
