import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'Category'
})
export class Category {
    authorityId: number;
    @Key categoryId: number;
    description: string;
}

@Entity({
    modelName: 'SubCategory',
})
export class SubCategory {
    @Key categoryId: number;
    @Key subCategoryId: number;
    authorityId: number;
    description: string;
}

@Entity({
    modelName: 'CategoryLookup',
})
export class CategoryLookup {
    @Key categoryId: number;
    description: string;
}

@Entity({
    modelName: 'SubCategoryLookup',
})
export class SubCategoryLookup {
    @Key subCategoryId: number;
    @Key categoryId: number;
    description: string;
}

export class CategoryTree extends CategoryLookup {
    subCategories: SubCategoryLookup[];
}
