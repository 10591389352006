import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, OnInit, OnChanges, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal, NgbTabChangeEvent, NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { EnhancedIssueTranslation } from "app/enhanced-issues-translations/feature/enhanced-issue-translations.model";
import { EnhancedIssuesTranslationsFacade } from "app/enhanced-issues-translations/feature/enhanced-issues-translations.facade";
import { TranslationsFacade } from "app/translations/feature/translations/translations.facade";
import { WicConfirmModalComponent } from 'app/wic-confirm-modal/wic-confirm-modal.component';

@Component({
  selector: 'wic-enhanced-issue-translations',
  templateUrl: './wic-enhanced-issue-translations.component.html',
})
export class WicEnhancedIssueTranslationsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() enhancedIssueTranslations: EnhancedIssueTranslation[];
  @Output() save = new EventEmitter<EnhancedIssueTranslation[]>();
  @Output() added = new EventEmitter<EnhancedIssueTranslation[]>();
  @Output() delete = new EventEmitter<EnhancedIssueTranslation[]>();

  @ViewChild('langs', {static: true}) enahncedIssueTranslationsTabs: NgbTabset;

  form: FormGroup;
  
  get langArray(): FormArray {
    return this.form.get('langs') as FormArray;
  }

  constructor(
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private translations: TranslationsFacade,
    public enhancedIssueTranslationsFacade: EnhancedIssuesTranslationsFacade
  ) {}

  ngOnInit() {
    this.form = this.buildForm();
  }

  ngOnChanges() {
    if (this.enhancedIssueTranslations) {
      this.form = undefined;
      this.form = this.buildForm();
      this.populateForm(this.form);
    }
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  buildForm() {
    return this.formBuilder.group({
      langs: this.formBuilder.array([
        this.formBuilder.group({
            languageCode: 'en',
            enhancedIssueTranslations: this.formBuilder.array([
              this.formBuilder.group({
                translationId: '',
                translationKey: 'label',
                translationValue: ''
              }),
              this.formBuilder.group({
                translationId: '',
                translationKey: 'info',
                translationValue: ''
              }),
              this.formBuilder.group({
                translationId: '',
                translationKey: 'buttonText',
                translationValue: ''
              })
            ])
        }),
      ])
    });
  }

  populateForm(form: FormGroup) {
    if (!form) {
      return;
    }

    if (this.enhancedIssueTranslations && this.enhancedIssueTranslations.length) {
      const langs = this.form.get('langs') as FormArray;
      const missingLangs = this.enhancedIssueTranslations.filter(
          lang => !langs.controls.some(ctrl => ctrl.value.languageCode === lang.lang)
      );
      const uniqueLangs: string[] = Array.from(new Set<string>(missingLangs?.map((lang) => lang.lang)));

      for (let i = 0; i < uniqueLangs.length; i++) {   
        langs.push(this.formBuilder.group({
          languageCode: uniqueLangs[i],
          enhancedIssueTranslations: this.formBuilder.array([
            this.formBuilder.group({
              translationId: '',
              translationKey: 'label',
              translationValue: ''
            }),
            this.formBuilder.group({
              translationId: '',
              translationKey: 'info',
              translationValue: ''
            }),
            this.formBuilder.group({
              translationId: '',
              translationKey: 'buttonText',
              translationValue: ''
            })
          ])
        }));

        this.populateLangTranslations(uniqueLangs[i], i+1);
      }
      
      //tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < langs.controls.length; i++) {
        if (!this.enhancedIssueTranslations.some(lang => lang.lang === langs.controls[i].value.languageCode)) {
          langs.removeAt(i);
        }
      }

      const enText = this.enhancedIssueTranslations.filter(txt => txt.lang === 'en');
      this.populateLangTranslations(enText[0].lang, 0);
    }
  }

  populateLangTranslations(lang: string, index: number) {
    const langTranslations = this.enhancedIssueTranslations.filter((t) => t.lang === lang);
    const translations = this.langArray.at(index).get('enhancedIssueTranslations') as FormArray;
    
    translations.clear();
    
    langTranslations.forEach((t) => {
      translations.push(
        this.formBuilder.group({
          translationId: t.translationId,
          translationKey: t.translationKey,
          translationValue: t.translationValue
        })
      );
    });
  }

  saveTranslations(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    const langs = this.form.get('langs') as FormArray;
    let translations: EnhancedIssueTranslation[] = [];

    for (let i = 0; i < langs.controls.length; i++) {
      const langTranslations = langs.at(i).get('enhancedIssueTranslations') as FormArray;
      
      for (let j = 0; j < langTranslations.controls.length; j++) {
        const translation = {
          translationId: langTranslations.controls[j].value.translationId,          
          translationKey: langTranslations.controls[j].value.translationKey,
          translationValue: langTranslations.controls[j].value.translationValue,
          lang: langs.controls[i].value.languageCode
        } as EnhancedIssueTranslation
        translations.push(translation);
      }
    }
    
    if (translations && translations.length > 0) {
      this.save.emit(translations);
    }
    //this.cd.detectChanges();
  }

  removeLanguage(language: string, index: number) {
    const modal = this.modal.open(WicConfirmModalComponent);
    modal.componentInstance.modalTitle = 'Delete Translations for ' + language.toLocaleUpperCase();
    modal.componentInstance.message = 'Are you sure you wish to delete these translations?';
    modal.result.then(() => {
      if (language === 'en' && index === 0) {
          return;
      }

      const content = this.form.get('langs') as FormArray;
      content.removeAt(index);

      let translations: EnhancedIssueTranslation[];
      translations = this.enhancedIssueTranslations.filter(t => t.lang === language);
      if (!translations) {
        return;
      }

      if (translations[0].translationId) {
        this.delete.emit(translations);
      }

      this.cd.detectChanges();
    }, () => {
    });
  }

  tabBeforeChange($event: NgbTabChangeEvent) {
    if (!this.form) {
      return;
    }
    
    if ($event.nextId === 'addLang') {
      $event.preventDefault();
          
      const content = this.form.get('langs') as FormArray;
      const modal = this.translations.openSelectLangModal(content);
      modal.result.then(lang => {
        if (!lang) {
          return;
        }
            
        const newLang = {
          languageCode: lang.id,
          enhancedIssueTranslations: this.formBuilder.array([
            this.formBuilder.group({
              translationId: '',
              translationKey: 'label',
              translationValue: ''
            }),
            this.formBuilder.group({
              translationId: '',
              translationKey: 'info',
              translationValue: ''
            }),
            this.formBuilder.group({
              translationId: '',
              translationKey: 'buttonText',
              translationValue: ''
            })
          ])
        }

        const labelTranslation = {
          lang: lang.id,
          translationKey: 'label'
        } as EnhancedIssueTranslation

        const infoTranslation = {
          lang: lang.id,
          translationKey: 'info'
        } as EnhancedIssueTranslation

        const buttonTranslation = {
          lang: lang.id,
          translationKey: 'buttonText'
        } as EnhancedIssueTranslation

        const translationArray: EnhancedIssueTranslation[] = [labelTranslation, infoTranslation, buttonTranslation]
        this.added.emit(translationArray);
              
        content.push(this.formBuilder.group({
          ...newLang
        }));

        this.cd.detectChanges();
    
        setTimeout(() => {
          this.enahncedIssueTranslationsTabs.select((content.length - 1).toString());
        }, 100);
      }, () => {});
    }
  }
}