import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EnhancedIssuesFacadeBase, allEnhancedIssues } from './enhanced-issues.state';
import { addEditEnhancedIssue, confirmDeleteEnhancedIssue, loadAllByAuthority, moveOrderEnhancedIssue } from './enhanced-issues.actions';
import { EnhancedIssue } from './enhanced-issue.model';
import { IAppState } from '@state/app.state';

@Injectable({
  providedIn: 'root',
})
export class EnhancedIssuesFacade extends EnhancedIssuesFacadeBase {
  constructor(private store: Store<IAppState>) {
    super(EnhancedIssue, store);
  }

  get all(): Observable<EnhancedIssue[]> {
    return this.store.pipe(select(allEnhancedIssues));
  }

  loadAllByAuthority(authorityId: number) {
    this.store.dispatch(loadAllByAuthority({ authorityId }));
  }

  addEditEnhancedIssue(enhancedIssue?: EnhancedIssue) {
    this.store.dispatch(addEditEnhancedIssue({ enhancedIssue }))
  }

  deleteEnhancedIssue(enhancedIssue: EnhancedIssue) {
    this.store.dispatch(confirmDeleteEnhancedIssue({ enhancedIssue }));
  }

  moveEnhancedIssue(enhancedIssue: EnhancedIssue, newIndex: number) {
    this.store.dispatch(moveOrderEnhancedIssue({ enhancedIssue, newIndex }));
  }
}
