export class AuthorityMapping {
    // tslint:disable-next-line:variable-name
    _id?: string;
    name: string;
    username?: string;
    password?: string;
    binCode: string;
    endpoint: string;
    useProxy?: boolean;
    integration?: string;
    isEnabled: boolean;
}
