import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo, IEntityWithPageInfo, Page} from '@briebug/ngrx-auto-entity';
import * as moment from 'moment';

import {LocalBenefit} from '@models/LocalBenefit';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocalBenefitService implements IAutoEntityService<LocalBenefit> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: {authorityId: number}): Observable<LocalBenefit[]> {
        return this.http.get<LocalBenefit[]>(environment.apiRoot +
            `/admin/authorities/${criteria.authorityId}/benefits`
        );
    }

    loadPage(entityInfo: IEntityInfo, page: Page, criteria?: any): Observable<IEntityWithPageInfo<LocalBenefit>> {
        let fromObject: any = {
            page: (page.page || 1).toString(),
            size: (page.size || 25).toString(),
        };

        if (criteria.criteria) {
            fromObject = {
                ...fromObject,
                criteria: criteria.criteria,
            };
        }

        if (criteria.startDate) {
            fromObject = {
                ...fromObject,
                startDate: moment({
                    ...criteria.startDate,
                    month: criteria.startDate.month - 1,
                    hour: 0,
                    minute: 0,
                    second: 0,
                }).format(),
            };
        }

        if (criteria.endDate) {
            fromObject = {
                ...fromObject,
                endDate: moment({
                    ...criteria.endDate,
                    month: criteria.endDate.month - 1,
                    hour: 11,
                    minute: 59,
                    second: 59,
                }).format(),
            };
        }

        const params = new HttpParams({fromObject});

        return this.http
            .get<any>(environment.apiRoot + `/admin/authorities/${criteria.authorityId}/benefits`, {params})
            .pipe(
                map(benefitsMeta => {
                    return {
                        pageInfo: {
                            page: {
                                page: page.page || 1,
                                size: page.size || 25
                            },
                            totalCount: benefitsMeta.pageInfo && benefitsMeta.pageInfo.length ?
                                benefitsMeta.pageInfo[0].totalCount : 0,
                        },
                        entities: benefitsMeta.benefits || [],
                    };
                })
            );
    }
}
