import {Injectable} from '@angular/core';
import {Create, LoadAll, Update} from '@briebug/ngrx-auto-entity';
import {CardImage} from '@models/CardImage';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {allCardImages} from '@state/card-image.state';
import {Observable, of, Subject} from 'rxjs';
import {first, map, tap} from 'rxjs/operators';

import {Authority, AuthorityAccount, AuthorityFlag, AuthorityType, BannerPlaylist, MetaImage} from '@models';
import {IAppState} from '@state/app.state';
import {CreateAuthorityAccountAction, UpdateAuthorityAccountAction} from '@state/authorities/accounts.actions';
import {
  createAuthority,
  CreateAuthorityAction,
  FetchAuthoritiesAction,
  SelectAuthorityAction, SetAuthorityLogoAction,
  UpdateAuthorityAction,
} from '@state/authorities/authorities.actions';
import {
    allActiveAuthorities,
    allAuthorities, allAuthoritiesAsGroupedLookup,
    allAuthoritiesAsLookup,
    selectCurrentAuthority
} from '@state/authorities/authorities.selectors';
import {FetchAuthorityTypesAction} from '@state/authorities/types.actions';
import {selectAuthorityTypes} from '@state/authorities/types.selectors';
import {allAuthorityFlags} from '@state/authority-flags.state';
import {environment} from '../../environments/environment';
import {IAuthorityInfo} from '../wic-admin/wic-authorities/models';
import {
    WicAuthorityFlagEditComponent
} from '../wic-admin/wic-authorities/wic-authority-flags/wic-authority-flag-edit/wic-authority-flag-edit.component';
import {
    WicAuthorityBannerPlaylistEditComponent
} from '../wic-admin/wic-authorities/wic-authority-view/wic-authority-banner-playlist-edit/wic-authority-banner-playlist-edit.component';
import {
    WicAuthorityDetailEditComponent
} from '../wic-admin/wic-authorities/wic-authority-view/wic-authority-detail-edit/wic-authority-detail-edit.component';
import {WicImageUploaderModalComponent} from '../wic-image-uploader-modal/wic-image-uploader-modal.component';

@Injectable({
    providedIn: 'root'
})
export class AuthoritiesFacade {
    cardImageChanged$: Observable<Authority>;
    private cardImageChangedSubject: Subject<Authority>;

    all$ = this.store.select(allAuthorities);
    active$ = this.store.select(allActiveAuthorities);
    allAsLookup$ = this.store.select(allAuthoritiesAsLookup);
    allAsGroupedLookup$ = this.store.select(allAuthoritiesAsGroupedLookup);
    current$ = this.store.select(selectCurrentAuthority);
    flags$ = this.store.select(allAuthorityFlags);
    types$ = this.store.select(selectAuthorityTypes);

    cardImage$(authority: Authority): Observable<CardImage> {
        if (!authority) {
            return of(null);
        }

        return this.store.pipe(
            select(allCardImages),
            map(cardImages => cardImages.find(ci => +ci.authorityId === authority.id))
        );
    }

    constructor(private store: Store<IAppState>, private modal: NgbModal) {
        this.cardImageChangedSubject = new Subject<Authority>();
        this.cardImageChanged$ = this.cardImageChangedSubject.asObservable();
    }

    get infoReport(): Observable<IAuthorityInfo> {
        return this.all$.pipe(
            map(authorities => ({
                totalCount: authorities.length,
                activeCount: authorities.filter(authority => authority.isActive).length,
                providerCount: authorities.filter(authority => authority.type === 2).length,
                offlineCount: authorities.filter(authority => authority.type === 1).length,
                verifyCount: authorities.filter(authority => authority.type === 0).length,
                voucherCount: authorities.filter(authority => authority.type === 3).length,
                nonSubCount: authorities.filter(authority => authority.type === 4).length,
            })),
            map(info => ({
                ...info,
                inactiveCount: info.totalCount - info.activeCount,
            })),
            tap(info => console.log(info))
        );
    }


    // region Captures
    captureFlags(cb: (flags: AuthorityFlag[]) => void) {
        this.flags$.pipe(
            first(items => !!items && !!items.length)
        ).subscribe(cb);
    }

    captureTypes(cb: (types: AuthorityType[]) => void) {
        this.types$.pipe(
            first(items => !!items && !!items.length)
        ).subscribe(cb);
    }

    // endregion

    // region Dispatches
    setCurrent(authority: Authority) {
        // this.store.dispatch(new Select(Authority, authority));
        this.store.dispatch(new SelectAuthorityAction(authority));
    }

    loadAll() {
        // this.store.dispatch(new LoadAll(Authority));
        this.store.dispatch(new FetchAuthoritiesAction());
    }

    loadFlags() {
        this.store.dispatch(new LoadAll(AuthorityFlag));
    }

    loadTypes() {
        // this.store.dispatch(new LoadAll(AuthorityFlag));
        this.store.dispatch(new FetchAuthorityTypesAction());
    }

    loadCardImage(authority: Authority) {
        this.store.dispatch(new LoadAll(CardImage));
    }

    create(authority: Authority, account?: AuthorityAccount) {
      this.store.dispatch(createAuthority({ authority, account }));
        // this.store.dispatch(new CreateAuthorityAction(authority));
        // if (account) {
        //     this.store.dispatch(new CreateAuthorityAccountAction(account));
        // }
    }

    update(authority: Authority, account?: AuthorityAccount) {
        this.store.dispatch(new UpdateAuthorityAction(authority));
        if (account && account._id) {
            this.store.dispatch(new UpdateAuthorityAccountAction(account));
        } else if (account) {
            this.store.dispatch(new CreateAuthorityAccountAction(account));
        }
    }

    updateFlag(flag: AuthorityFlag) {
        this.store.dispatch(new Update(AuthorityFlag, flag));
    }

    setCardImage(cardImage: CardImage) {
        this.store.dispatch(cardImage._id ? new Update(CardImage, cardImage) : new Create(CardImage, cardImage));
    }

    setLogo(authority: Authority, logo: MetaImage) {
        this.store.dispatch(new SetAuthorityLogoAction(authority, logo));
        setTimeout(() => this.cardImageChangedSubject.next(authority), 500);
    }

    // endregion

    // region UI Helpers
    showPlaylistChooser(authority: Authority, playlists: BannerPlaylist[]) {
        const modalReference = this.modal.open(WicAuthorityBannerPlaylistEditComponent, {size: 'lg'});
        modalReference.componentInstance.authority = authority;
        modalReference.componentInstance.playlists = playlists;
        modalReference.result
            .then((edited: Authority) => {
               if (edited) {
                   this.update(edited);
               }
            }, () => {
            });
    }

    showFlagEdit(flag: AuthorityFlag) {
        if (!flag) { return; }
        const modalReference = this.modal.open(WicAuthorityFlagEditComponent, {size: 'lg'});
        modalReference.componentInstance.flag = flag;
        modalReference.result
            .then((edited: AuthorityFlag) => {
                if (edited) {
                    this.updateFlag(edited);
                }
            }, () => {
            });
    }

    showCardImageEdit(authority: Authority, cardImage: CardImage) {
        const modalReference = this.modal.open(WicImageUploaderModalComponent, {size: 'lg'});
        modalReference.componentInstance.modalTitle = 'Change Authority Card Image';
        modalReference.componentInstance.currentUri = cardImage ?
            `${environment.apiRoot}/authorities/${cardImage.authorityId}/card?cache=no` : null;
        modalReference.result
            .then((edited: { changed: boolean, url: string, mimeType: string, encoded: string }) => {
                if (edited.changed) {
                    const updatedCardImage: CardImage = {
                        _id: cardImage ? cardImage._id : undefined,
                        authorityId: authority.id,
                        image: edited.encoded,
                        mime: edited.mimeType
                    };
                    this.setCardImage(updatedCardImage);
                }
            }, () => {
            });
    }

    showLogoImageEdit(authority: Authority) {
        const modalReference = this.modal.open(WicImageUploaderModalComponent, {size: 'lg'});
        modalReference.componentInstance.modalTitle = 'Change Authority Logo';
        modalReference.componentInstance.currentUri = authority ?
            `${environment.apiRoot}/authorities/${authority.id}/logo?cache=no` : null;
        modalReference.result
            .then((edited: { changed: boolean, url: string, mimeType: string, encoded: string }) => {
                if (edited.changed) {
                    const logoImage: MetaImage = {
                        image: edited.encoded,
                        mime: edited.mimeType
                    };
                    this.setLogo(authority, logoImage);
                }
            }, () => {
            });
    }

    showEdit(authority: Authority, account: AuthorityAccount, flags: AuthorityFlag[], types: AuthorityType[]) {
        this.showEditModal(authority, account, flags, types)
            .then(([edited, editedAccount]) => {
                this.update(edited, editedAccount);
            }, () => {
            });
    }

    showCreate(flags: AuthorityFlag[], types: AuthorityType[]) {
        this.showEditModal({} as Authority, {} as AuthorityAccount, flags, types)
            .then(([created, createdAccount]) => {
                this.create(created, createdAccount);
            }, () => {
            });
    }

    // showDelete(config: APLImportConfig) {
    //     if (!config) {
    //         return;
    //     }
    //
    //     this.forDeletion.next(config);
    //
    //     const modalReference = this.modal.open(WicConfirmModalComponent);
    //     modalReference.componentInstance.modalTitle = 'Confirm APL Import Config Deletion';
    //     modalReference.componentInstance.message = 'Are you sure you want to delete this APL import config?';
    //     modalReference.result
    //         .then(() => {
    //             this.delete(config);
    //             this.forDeletion.next(null);
    //         }, () => {
    //             this.forDeletion.next(null);
    //         });
    // }
    // endregion

    // region Helpers
    private showEditModal(
        authority: Authority, account: AuthorityAccount, flags: AuthorityFlag[], types: AuthorityType[]
    ): Promise<[Authority, AuthorityAccount]> {
        const modalReference = this.modal.open(WicAuthorityDetailEditComponent, {size: 'lg'});
        modalReference.componentInstance.authority = authority || {};
        modalReference.componentInstance.account = account || [];
        modalReference.componentInstance.flags = flags || [];
        modalReference.componentInstance.types = types || [];
        return modalReference.result;
    }

    // endregion
}
