<div class="container">
    <router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>
</div>

<wic-demo-accounts-table
  *ngIf="!hideSelf" class="mt-5"
  [demoAccounts$]="demoAccounts.sorted$"
  [authorities]="authorities$ | async"
  [loading]="demoAccounts.isLoading$ | async"
  [saving]="demoAccounts.isSaving$ | async"
  (added)="add()"
  (deleted)="demoAccounts.showDelete($event)"
  (selected)="demoAccounts.select($event)"
  (replicate)="demoAccounts.showReplicateAgencyCard()"
  (addGroup)="addEditBenefitGroup($event)"
  (toggleActive)="toggleActive($event)"
  (editGroup)="addEditBenefitGroup($event.demoAccount, $event.benefitGroup)"
  (deleteGroup)="demoAccounts.showDeleteBenefitGroup($event.demoAccount, $event.benefitGroup)"
  (refreshed)="refresh()">
</wic-demo-accounts-table>
