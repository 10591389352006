import {Injectable} from '@angular/core';
import {LoadPage} from '@briebug/ngrx-auto-entity';

import {LocalBenefit} from '@models/LocalBenefit';
import {select, Store} from '@ngrx/store';
import {IAppState} from '@state/app.state';
import {allLocalBenefits, localBenefitPageableCount, localBenefitsPage} from '@state/local-benefit.state';
import {distinctUntilChanged} from 'rxjs/operators';

const PAGE_SIZE = 100;

@Injectable({
    providedIn: 'root'
})
export class LocalBenefitsFacade {
    all$ = this.store.select(allLocalBenefits);
    currentPage$ = this.store.pipe(
        select(localBenefitsPage),
        distinctUntilChanged()
    );

    totalPageableCount$ = this.store.pipe(
        select(localBenefitPageableCount),
        distinctUntilChanged()
    );

    constructor(private store: Store<IAppState>) {
    }

    loadPage(page: number, searchCriteria: any) {
        this.store.dispatch(new LoadPage(LocalBenefit, {
            page,
            size: PAGE_SIZE,
        }, {
            authorityId: searchCriteria.authority || 0,
            criteria: searchCriteria.criteria,
            startDate: searchCriteria.startDate,
            endDate: searchCriteria.endDate,
        }));
    }
}
