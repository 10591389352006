import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityIdentity, getKeyFromModel, IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { buildUrl, EntityCriteria } from './entity.service.utils';

@Injectable()
export class EntityService implements IAutoEntityService<any> {
  constructor(private http: HttpClient) {}

  load(entityInfo: IEntityInfo, key: any, criteria?: EntityCriteria): Observable<any> {
    const url = buildUrl(entityInfo, criteria, key);
    return this.http.get<any>(url);
  }

  loadMany(entityInfo: IEntityInfo, criteria?: EntityCriteria): Observable<any[]> {
    const url = buildUrl(entityInfo, criteria);
    return this.http.get<any[]>(url);
  }

  loadAll(entityInfo: IEntityInfo, criteria?: EntityCriteria): Observable<any[]> {
    const url = buildUrl(entityInfo, criteria);
    return this.http.get<any[]>(url);
  }

  create(entityInfo: IEntityInfo, entity: any, criteria?: EntityCriteria, originalEntity?: any): Observable<any> {
    const url = buildUrl(entityInfo, criteria);
    return this.http.post<any>(url, entity);
  }


  createMany<T>(entityInfo: IEntityInfo, entities: Array<T>, criteria?: any, originalEntities?: T[]): Observable<T[]> {
    const url = buildUrl(entityInfo, criteria);
    return this.http.post<any>(url, entities);
  }

  update(entityInfo: IEntityInfo, entity: any, criteria?: EntityCriteria, originalEntity?: any): Observable<any> {
    const url = buildUrl(entityInfo, criteria, getKeyFromModel(entityInfo.modelType, entity));
    return this.http.patch<any>(url, entity);
  }

  replace(entityInfo: IEntityInfo, entity: any, criteria?: EntityCriteria, originalEntity?: any): Observable<any> {
    const url = buildUrl(entityInfo, criteria, getKeyFromModel(entityInfo.modelType, entity));
    return this.http.put<any>(url, entity);
  }

  replaceMany(entityInfo: IEntityInfo, entities: any[], criteria?: any, originalEntities?: any[]): Observable<any[]> {
      const url = buildUrl(entityInfo, criteria, getKeyFromModel(entityInfo.modelType, entities));
      return this.http.put<any[]>(url, entities).pipe(map(() => entities));
  }

  delete(entityInfo: IEntityInfo, entity: any, criteria?: EntityCriteria, originalEntity?: any): Observable<any> {
    const url = buildUrl(entityInfo, criteria, getKeyFromModel(entityInfo.modelType, entity));
    return this.http.delete<any>(url, entity).pipe(map(() => entity));
  }

  deleteByKey(entityInfo: IEntityInfo, key: EntityIdentity, criteria?: EntityCriteria): Observable<EntityIdentity> {
    const url = buildUrl(entityInfo, criteria, key);
    return this.http.delete<any>(url).pipe(map(() => key));
  }

  deleteMany(entityInfo: IEntityInfo, entities: any[], criteria?: any, originalEntities?: any[]): Observable<any[]> {
      const url = buildUrl(entityInfo, criteria);

      return this.http.request<any>('DELETE', url, {
        body: entities
      }).pipe(map(() => entities));
  }
}
