<div class="card mt-5 mb-5">
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="articles?.length">
            <thead>
            <tr>
                <th class="text-left" (click)="wikiSorter.sortString('title')">
                    <i class="fa fa-fw fa-id-badge"></i> Title
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-alpha-asc': wikiSorter.direction === 1, 'fa-sort-alpha-desc': wikiSorter.direction === -1}"
                       *ngIf="wikiSorter.sortKey === 'title'"></i>
                </th>
                <th class="text-left" (click)="wikiSorter.sortString('slug')">
                    Slug
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-alpha-asc': wikiSorter.direction === 1, 'fa-sort-alpha-desc': wikiSorter.direction === -1}"
                       *ngIf="wikiSorter.sortKey === 'slug'"></i>
                </th>
                <th class="text-left" (click)="wikiSorter.sortString('author')">
                    Author
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-amount-asc': wikiSorter.direction === 1, 'fa-sort-amount-desc': wikiSorter.direction === -1}"
                       *ngIf="wikiSorter.sortKey === 'author'"></i>
                </th>
                <th class="text-left" (click)="wikiSorter.sortString('updatedAt')">
                    Updated At
                    <i class="fa fa-fw text-medium"
                       [ngClass]="{'fa-sort-alpha-asc': wikiSorter.direction === 1, 'fa-sort-alpha-desc': wikiSorter.direction === -1}"
                       *ngIf="wikiSorter.sortKey === 'updatedAt'"></i>
                </th>
                <th class="text-left" (click)="wikiSorter.sortString('createdAt')">
                    Created At
                    <i class="fa fa-fw text-medium fa-sort-numeric-asc"
                       [ngClass]="{'fa-sort-numeric-asc': wikiSorter.direction === 1, 'fa-sort-numeric-desc': wikiSorter.direction === -1}"
                       *ngIf="wikiSorter.sortKey === 'createdAt'"></i>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let article of articles">
                <td class="text-nowrap">
                    <a [routerLink]="['edit', article._id]">{{article.title}}</a>
                </td>
                <td class="text-monospace">{{article.slug}}</td>
                <td class="text-nowrap">{{article.author}}</td>
                <td class="text-left">
                    <span *ngIf="article.updatedAt">
                        <span class="badge badge-default badge-info text-white mr-1">
                            {{article.updatedAt?.split('T')[0]}}
                        </span>
                        <span class="badge badge-default badge-info text-white">
                            {{article.updatedAt?.split('T')[1]}}
                        </span>
                    </span>
                    <span class="badge badge-default badge-info text-white" *ngIf="!article.updatedAt">
                            Never
                    </span>
                </td>
                <td class="text-left">
                    <span class="badge badge-default badge-info text-white mr-1">
                        {{article.createdAt?.split('T')[0]}}
                    </span>
                    <span class="badge badge-default badge-info text-white">
                        {{article.createdAt?.split('T')[1]}}
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!articles?.length" class="card-body">
        <p><em>There are no wiki articles yet.</em></p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6">
                <!--<a class="btn btn-outline-secondary float-left" role="button"-->
                   <!--[download]="getFilename()" [href]="buildDownloadUri(users)">-->
                    <!--<i class="fa fa-download"></i> Download as CSV-->
                <!--</a>-->
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
                <button class="btn btn-outline-primary" (click)="added.emit()">
                    <i class="fa fa-user-plus"></i> Add Article
                </button>
            </div>
        </div>
    </div>
</div>
