<div class="row mt-3 mb-3">
    <div class="col-md-12">
        <h1 *ngIf="auth.user$ | async as user">
          <i class="fa fa-2x fa-user-circle mr-2"></i> <strong>{{user.name}}</strong>
        </h1>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li ngbNavItem="profile">
                <a ngbNavLink>Profile</a>
                <ng-template ngbNavContent>
                    User Profile
                </ng-template>
            </li>
            <li ngbNavItem="notification">
                <a ngbNavLink>Notifications</a>
                <ng-template ngbNavContent>
                    Notification Preferences
                </ng-template>
            </li>
        </ul>
    </div>
</div>
<div class="row mt-2">
    <div class="col-md-12" [ngbNavOutlet]="nav">
    </div>
</div>
