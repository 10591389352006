import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DeviceLogSession, EnhancedDevice} from '@models';

@Component({
    selector: 'wic-enhanced-devices-table',
    templateUrl: './enhanced-devices-table.component.html',
    styleUrls: ['./enhanced-devices-table.component.scss']
})
export class EnhancedDevicesTableComponent implements OnInit {
    @Input() devices: EnhancedDevice[];
    @Input() showFilter = false;
    @Input() showToolbar = true;
    @Input() lite = false;
    @Output() toggled = new EventEmitter<{device: EnhancedDevice, allowed: boolean}>();
    @Output() refreshed = new EventEmitter();
    @Output() deleted = new EventEmitter<EnhancedDevice>();

    form: FormGroup;

    constructor(private builder: FormBuilder) {
    }

    ngOnInit() {
        this.buildForm(this.builder);
    }

    buildForm(builder: FormBuilder) {
        return builder.group({
            criteria: ['']
        });
    }

}
