<div class="row mt-4">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">Import Log</h5>
            </div>
            <div class="">
                <table class="table table-sm table-striped table-hover fixed-header mb-0"
                       *ngIf="progressLog?.length">
                    <thead>
                    <tr>
                        <th class="">Step</th>
                        <th class="">Timestamp</th>
                        <th class="">Note</th>
                        <th class="">&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let entry of progressLog">
                        <td class="">
                            <span class="badge"
                                  [class.badge-info]="entry.step !== 'Error'"
                                  [class.text-white]="entry.step === 'Error'"
                                  [class.badge-danger]="entry.step === 'Error'">{{entry.step}}</span>
                        </td>
                        <td class="">{{entry.timestamp}}</td>
                        <td class="">{{entry.note || entry.message}}</td>
                        <td class="">
                            <span *ngIf="entry.rangeStart">{{entry.rangeStart}} - {{entry.rangeEnd}}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="!progressLog?.length" class="col-sm-12">
                <p><em>This import job has no log entries.</em></p>
            </div>
        </div>
    </div>
</div>
