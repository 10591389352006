import {Entity, Key} from '@briebug/ngrx-auto-entity';
import {IImportParseError, IImportStep} from '@models/types';

@Entity({
    modelName: 'AppointmentReminder'
})
export class AppointmentReminder {
    // tslint:disable-next-line:variable-name
    @Key _id?: string;
    authorityId: number;
    cardNumber?: string;
    householdId?: string;
    locationName: string;
    appointmentTime: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    email: string;
    apptRequirements: string;
}

@Entity({
    modelName: 'AppointmentImport'
})
export class AppointmentImport {
    // tslint:disable-next-line:variable-name
    @Key _id?: string;
    authorityId: number;
    filename: string;
    username: string;
    timestamp: string;
    oldCountRemoved: number;
    newCountAdded: number;
    newCountSkipped: number;
    progress: IImportStep[];
    parseError?: IImportParseError[];
    parseErrorCount?: number;
    succeeded?: boolean;
    incomplete?: boolean;
}
