import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'wic-nav-left',
    templateUrl: './nav-left.component.html',
    styleUrls: ['./nav-left.component.sass'],
})
export class NavLeftComponent {
    @Output() toggleSidebar: EventEmitter<any> = new EventEmitter();
}
