import { createSelector } from '@ngrx/store';
import { IAppState } from '../app.state';
import { ILoadingState as LoadingState } from './loading.reducers';

const loadingState = (state: IAppState) => state.loading;

const getLoading = (state: LoadingState) => state.loading;

export const loading = {
    loading: createSelector(loadingState, getLoading),
};
