import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wic-value-input-modal',
    templateUrl: './wic-value-input-modal.component.html',
    styleUrls: ['./wic-value-input-modal.component.sass']
})
export class WicValueInputModalComponent {
    @Input() modalTitle: string;
    @Input() label: string;

    value: string;

    constructor(public activeModal: NgbActiveModal) {
    }
}
