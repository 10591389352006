import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'User'
})
export class User {
    // tslint:disable-next-line:variable-name
    @Key _id?: string;
    authorityId?: number;
    username: string;
    password: string;
    newPassword?: string;
    newPasswordCheck?: string;
    email: string;
    first: string;
    last: string;
    lastLogin: string;
    isEnabled: boolean;
    hasAdminAccess: boolean;
    roles: string[];
    reset?: {
        timestamp: string;
        token: string
    };
}
