<div class="row mt-5" *ngIf="job && props">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    Error Detail
                </h5>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let prop of props">
                    <dl class="row mb-0">
                        <dt class="col-md-3">{{prop.name}}</dt>
                        <dd class="col-md-9">
                            <span *ngIf="!prop.isObject && prop.name !== 'code' && prop.name !== 'name'">{{prop.value}}</span>
                            <span class="badge badge-warning text-white" *ngIf="!prop.isObject && prop.name === 'code'">
                                {{prop.value}}
                            </span>
                            <span class="badge badge-pill badge-info text-white" *ngIf="!prop.isObject && prop.name === 'name'">
                                {{prop.value}}
                            </span>
                            <span *ngIf="prop.isObject">
                                {{prop.value | json}}
                            </span>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
