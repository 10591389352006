<div class="row mt-4" *ngIf="message">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">
                    Message Details
                </h5>
                <i class="fa fa-fw fa-pencil float-right m-1" (click)="edited.emit(message)"
                   ngbTooltip="Edit Details" placement="left"></i>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Id</dt>
                        <dd class="col-md-9">
                            <span class="badge badge-pill badge-info">{{message._id}}</span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Name</dt>
                        <dd class="col-md-9">{{message.name}}</dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Is Active</dt>
                        <dd class="col-md-9">
                            <span class="badge text-white"
                                  [class.badge-success]="message.isActive"
                                  [class.badge-danger]="!message.isActive">
                                {{message.isActive ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">On Benefits</dt>
                        <dd class="col-md-9">
                            <span class="badge text-white"
                                  [class.badge-success]="message.onBenefits"
                                  [class.badge-danger]="!message.onBenefits">
                                {{message.onBenefits ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">On Detail</dt>
                        <dd class="col-md-9">
                            <span class="badge text-white"
                                  [class.badge-success]="message.onDetail"
                                  [class.badge-danger]="!message.onDetail">
                                {{message.onDetail ? 'Yes' : 'No'}}
                            </span>
                        </dd>
                    </dl>
                </li>
                <li class="list-group-item">
                    <dl class="row mb-0">
                        <dt class="col-md-3">Icon</dt>
                        <dd class="col-md-9">
                            <span  class="text-info" *ngIf="!message.icon">No Icon Configured</span>
                            <img class="sub-cat-image" [src]="createImageUrl(message.icon)"  *ngIf="message.icon"/>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
