<aside class="sidebar affix-top" [class.sidebar-narrow]="showNarrow">
    <ul class="nav flex-column wic-main-menu">
        <li class="nav-item" *ngFor="let item of menuItems">
            <a class="nav-link text-nowrap" [routerLink]="[item.path]" [routerLinkActive]="'active'">
                <i class="fa fa-fw fa-lg" [ngClass]="item.icon"></i>
                <span class="wic-menu-text">{{item.title}}</span>
            </a>
            <ul class="nav flex-column wic-sub-menu" *ngIf="item.path === areaPath && item.subMenuItems?.length">
                <li class="nav-item" *ngFor="let subItem of item.subMenuItems">
                    <a class="nav-link text-nowrap" [routerLink]="[subItem.path]" [routerLinkActive]="'active'">
                        <span class="wic-submenu-chevron"><i class="fa fa-fw fa-ellipsis-v ml-2"></i></span>
                        <span class="wic-submenu-text">{{subItem.title}}</span>
                        <span class="wic-submenu-chevron float-right"><i class="fa fa-fw fa-angle-right"></i></span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
    <div class="affix-bottom sidebar-brand-box text-nowrap">
        <a href="http://www.jpma.com" target="_blank">
            <img class="jpma-logo" src="http://www.jpma.com/wp-content/uploads/2016/04/jpmalogo-white-SMALL.png" alt="JPMA, Inc.">
        </a>
    </div>
</aside>
