import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { addEditEnhancedIssue } from 'app/enhanced-issues/feature/enhanced-issues.actions';
import { map } from 'rxjs/operators';
import {
  addNewLanguage,
  deleteLanguage,
  loadAllByEnhancedIssue,
  updateEnhancedIssueTranslations,
} from './enhanced-issues-translations.actions';
import {
  createEnhancedIssueTranslations,
  deleteEnhancedIssueTranslations,
  loadAllEnhancedIssueTranslations,
  replaceEnhancedIssueTranslations,
} from './enhanced-issues-translations.state';

@Injectable()
export class EnhancedIssuesTranslationsEffects {
  constructor(private actions$: Actions) {}

  loadAllByEnhancedIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllByEnhancedIssue, addEditEnhancedIssue),
      map(({ enhancedIssue }) =>
        loadAllEnhancedIssueTranslations({
          criteria: {
            parents: {
              authorities: enhancedIssue.authorityId,
              'enhanced-issues': enhancedIssue.issueId,
            },
          },
        })
      )
    )
  );

  addNewLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNewLanguage),
      map(translations =>
        createEnhancedIssueTranslations({
          criteria: {
            parents: {
              authorities: translations.enhancedIssue.authorityId,
              'enhanced-issues': translations.enhancedIssue.issueId,
            },
          },
          entities: translations.enhancedIssueTranslations,
        })
      )
    )
  );

  deleteLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLanguage),
      map(translations =>
        deleteEnhancedIssueTranslations({
          criteria: {
            parents: {
              authorities: translations.enhancedIssue.authorityId,
              'enhanced-issues': translations.enhancedIssue.issueId,
            },
          },
          entities: translations.enhancedIssueTranslations,
        })
      )
    )
  );

  updateEnhancedIssueTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEnhancedIssueTranslations),
      map(translations =>
        replaceEnhancedIssueTranslations({
          criteria: {
            parents: {
              authorities: translations.enhancedIssue.authorityId,
              'enhanced-issues': translations.enhancedIssue.issueId,
            },
          },
          entities: translations.enhancedIssueTranslations,
        })
      )
    )
  );
}
