<table class="table table-sm table-striped fixed-header">
    <thead>
    <tr>
        <th>Bin Code</th>
        <th class="text-nowrap">Name</th>
        <th>Endpoint</th>
        <th class="text-center">Enabled</th>
        <th class="text-right"><i class="fa fa-wrench"></i></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let authorityMapping of authorityMappings" [ngClass]="{ 'table-active': authorityMapping === activeMapping }">
        <td><span class="badge badge-info">{{ authorityMapping.binCode }}</span></td>
        <td>{{ authorityMapping.name }}</td>
        <td><input class="invisible-input" [value]="authorityMapping.endpoint" readonly></td>
        <td class="text-center"><input type="checkbox" [checked]="authorityMapping.isEnabled" disabled readonly></td>
        <td class="text-right">
            <i class="fa fa-edit" (click)="this.selected.emit(authorityMapping); activeMapping = authorityMapping"></i>
            <i class="fa fa-trash" (click)="this.deleted.emit(authorityMapping); activeMapping = authorityMapping"></i>
        </td>
    </tr>
    </tbody>
</table>
