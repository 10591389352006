<div class="d-block d-xl-none mt-4">
    <ngb-tabset [destroyOnHide]="false">
        <ngb-tab title="Edit">
            <ng-template ngbTabContent>
                <wic-wiki-editor
                    [article]="article$ | async"
                    [user]="auth.user$ | async"
                    (changed)="workingArticle = $event"
                    (saved)="saveArticle($event)">
                </wic-wiki-editor>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="Preview">
            <ng-template ngbTabContent>
                <wic-wiki-preview [article]="workingArticle"></wic-wiki-preview>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>
<div class="d-none d-xl-block mt-4">
    <div class="row">
        <div class="col-md-6">
            <wic-wiki-editor
                [article]="article$ | async"
                [user]="auth.user$ | async"
                (changed)="workingArticle = $event"
                (saved)="saveArticle($event)">
            </wic-wiki-editor>
        </div>
        <div class="col-md-6">
            <wic-wiki-preview [article]="workingArticle"></wic-wiki-preview>
        </div>
    </div>
</div>
