import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal, NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';

import {MicroBlog, ServerSettings} from '@models';
import { TranslationsFacade } from 'app/translations/feature/translations/translations.facade';

@Component({
    selector: 'wic-micro-blog-post',
    templateUrl: './wic-micro-blog-post.component.html',
    styleUrls: ['./wic-micro-blog-post.component.sass']
})
export class WicMicroBlogPostComponent implements OnInit, OnChanges {
    @Input() microBlog: MicroBlog;
    @Input() rootSettings: ServerSettings;
    @Output() saved = new EventEmitter<MicroBlog>();
    @ViewChild('localizedContent', {static: true}) localizedContent: NgbTabset;

    form: FormGroup;

    get localizedContentArray(): FormArray {
        return this.form.get('localizedContent') as FormArray;
    }

    constructor(
        private sanitizer: DomSanitizer,
        private fb: FormBuilder,
        private translations: TranslationsFacade) {
    }

    ngOnInit() {
        this.form = this.buildForm();
    }

    ngOnChanges() {
        if (this.microBlog) {
            this.populateForm(this.form);
        }
    }

    buildForm() {
        return this.fb.group({
            localizedContent: this.fb.array([
                this.fb.group({
                    lang: 'English',
                    content: '',
                }),
            ]),
            image: [null]
        });
    }

    populateForm(form: FormGroup) {
        if (!form) {
            return;
        }

        if (this.microBlog.localizedContent) {
            const content = this.form.get('localizedContent') as FormArray;
            const missingLangs = this.microBlog.localizedContent.filter(
                lang => !content.controls.some(ctrl => ctrl.value.lang === lang.lang)
            );

            for (const missingLang of missingLangs) {
                content.push(this.fb.group({
                    lang: missingLang.lang,
                    content: ''
                }));
            }
            form.patchValue({
                localizedContent: this.microBlog.localizedContent
            });
        }

        if (this.microBlog.image) {
            form.patchValue({
                image: this.microBlog.image
            });
        }
    }

    tabBeforeChange($event: NgbTabChangeEvent) {
        if (!this.form) {
            return;
        }

        if ($event.nextId === 'addLang') {
            $event.preventDefault();

            const content = this.form.get('localizedContent') as FormArray;
            const modal = this.translations.openSelectLangModal(content);
            modal.result.then(langCode => {
                content.push(this.fb.group({
                    lang: langCode.name,
                    content: ''
                }));
                setTimeout(() => {
                    this.localizedContent.select((content.length - 1).toString());
                }, 100);
            }, () => {
            });
        }
    }

    addImage(files: File[]) {
        if (!files.length) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.form.patchValue({
                image: reader.result
            });
        };
    }

    createImageUrl(uri: string) {
        if (uri.startsWith('data:')) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(uri);
        } else {
            return this.rootSettings.microBlogImageUrl.replace(':filename', uri);
        }
    }

    contentChanged() {
    }

    save(microBlog: MicroBlog, form: FormGroup) {
        const value = form.value;
        this.saved.emit({
            ...this.microBlog,
            image: value.image,
            localizedContent: [...value.localizedContent]
        });
    }
}
