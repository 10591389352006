import {buildState} from '@briebug/ngrx-auto-entity';
import {APLTransform} from '@models/APLTransform';

const {initialState, selectors} = buildState(APLTransform);

export const {
    selectAll: allAPLTransforms,
    selectIsLoading: aplTransformsLoading,
    selectIsSaving: aplTransformSaving,
    selectIsDeleting: aplTransformDeleting,
    selectCurrentEntity: currentAPLTransform
} = selectors;

export function aplTransformReducer(state = initialState) {
    return state;
}
