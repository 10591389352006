<router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>

<div *ngIf="!hideSelf">
    <div *ngIf="authority$ | async as authority" class="row">
        <div class="col-md-8">
            <wic-authority-detail
                [authority]="authority"
                [account]="account$ | async"
                [flags]="flags$ | async"
                (logo)="editLogo($event)"
                (edited)="editDetails($event, account, flags, types)">
            </wic-authority-detail>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <wic-authority-cardimage #cardImage
                        [authority]="authority"
                        [cardImage]="cardImage$ | async"
                        (edited)="editCardImage($event.authority, $event.cardImage)">
                    </wic-authority-cardimage>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <wic-authority-banner-playlist
                        [authority]="authority"
                        [banners]="banners$ | async"
                        [playlist]="playlist$ | async"
                        (edited)="editPlaylist($event)">
                    </wic-authority-banner-playlist>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="float-left mb-0">Locations</h5>
                                    <i class="fa fa-fw fa-eye float-right m-1" role="button" routerLink="locations"></i>
                                </div>
                                <div class="card-body text-center">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <wic-authority-bincodes
                [binCodes]="binCodes$ | async"
                (refreshed)="refreshBinCodes()"
                (added)="addBinCode()"
                (edited)="editBinCode($event)"
                (deleted)="deleteBinCode($event)">
            </wic-authority-bincodes>
        </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <wic-enhanced-issues
          [enhancedIssues]="enhancedIssues$ | async"
          (addIssue)="addEnhancedIssue()"
          (editIssue)="editEnhancedIssue($event)"
          (deleteIssue)="deleteEnhancedIssue($event)"
          (movedUp)="moveUpEnhancedIssue($event)"
          (movedDown)="moveDownEnhancedIssue($event)">
        </wic-enhanced-issues>
      </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <wic-authority-menu
                [buttons]="menuButtons$ | async"
                (refreshed)="refreshMenuButtons()"
                (added)="addMenuButton()"
                (edited)="editMenuButton($event)"
                (deleted)="deleteMenuButton($event)"
                (movedUp)="moveUpMenuButton($event)"
                (movedDown)="moveDownMenuButton($event)">
            </wic-authority-menu>
        </div>
    </div>
    <div *ngIf="authority$ | async as authority" class="row mb-5">
        <div class="col-md-12">
            <wic-demo-accounts-table
                [demoAccounts$]="demoAccounts$"
                [loading]="demoAccountsFacade.isLoading$ | async"
                [showFilter]="false"
                [showToolbar]="false"
                (replicate)="demoAccountsFacade.showReplicateAgencyCard(authority?.id)"
                (added)="addDemoAccount()"
                (deleted)="deleteDemoAccount($event)"
                (refreshed)="refreshDemoAccounts()">
            </wic-demo-accounts-table>
        </div>
    </div>
</div>
