import {ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Category, CategoryLookup, SubCategory, SubCategoryConfig, SubCategoryLookup} from '@models';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'wic-sub-category-config-edit',
    templateUrl: './wic-sub-category-config-edit.component.html',
    styleUrls: ['./wic-sub-category-config-edit.component.sass'],
})
export class WicSubCategoryConfigEditComponent implements OnInit {
    @Input() subCatConfig: SubCategoryConfig;
    @Input() categories: CategoryLookup[];
    @Input() subCategories: SubCategoryLookup[];
    @Input() subCatConfigs: SubCategoryConfig[];

    filteredSubCategories: SubCategoryLookup[];
    submitted = false;

    form: FormGroup;

    constructor(private sanitizer: DomSanitizer,
                private fb: FormBuilder,
                public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.form = this.buildForm();
        if (this.subCatConfig) {
            this.populateForm(this.subCatConfig);
        }
    }

    buildForm() {
        const form = this.fb.group({
            categoryId: [null, Validators.required],
            subCategoryId: [null, Validators.required],
            image: [null]
        });

        form.get('categoryId').valueChanges.pipe(
            filter(() => !!this.subCategories)
        ).subscribe(categoryId => {
            this.filteredSubCategories = categoryId ? this.subCategories.filter(item => +item.categoryId === +categoryId) : [];
            const subCategory = this.subCatConfig ? this.filteredSubCategories.find(
                sc => +sc.categoryId === +this.subCatConfig.categoryId && +sc.subCategoryId === +this.subCatConfig.subCategoryId
            ) : null;
            form.patchValue({subCategoryId: subCategory ? subCategory.subCategoryId : ''});
        });

        return form;
    }

    populateForm(subCatConfig) {
        this.form.patchValue({
            ...subCatConfig,
            categoryId: +subCatConfig.categoryId,
            subCategoryId: +subCatConfig.subCategoryId
        });
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = this.form.value;

        if (!this.form.valid) {
            return;
        }

        if (this.subCatConfigs.filter(cfg => !this.subCatConfig || cfg._id !== this.subCatConfig._id).some(cfg =>
            +cfg.categoryId === +value.categoryId &&
            +cfg.subCategoryId === +value.subCategoryId)) {
            return;
        }

        const subCatConfig = {
            ...value,
            _id: (!this.subCatConfig || !this.subCatConfig._id) ? undefined : this.subCatConfig._id,
            linkDetails: this.subCatConfig ? this.subCatConfig.linkDetails : [],
            categoryDescription: (
                this.categories.find(c => +c.categoryId === +value.categoryId) || {} as Category
            ).description,
            subCategoryDescription: (
                this.subCategories.find(sc =>
                    +sc.categoryId === +value.categoryId &&  +sc.subCategoryId === +value.subCategoryId
                ) || {} as SubCategory
            ).description,
        };
        this.activeModal.close(subCatConfig);
    }

    imageChanged(event) {
        if (!event.target.files.length) {
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            this.form.patchValue({image: reader.result});
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    createImageUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
