/* tslint:disable */
import {Injectable} from '@angular/core';
import {Create, Delete, LoadAll, Select, Update} from '@briebug/ngrx-auto-entity';
import {APLMessage} from '@models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {allAPLMessages, APLMessageFacadeBase, aplMessagesLoading, currentAPLMessage} from '@state/apl-message.state';
import {IAppState} from '@state/app.state';
import {Observable, Subject} from 'rxjs';
import {WicAplMessageEditComponent} from '../wic-admin/wic-apl/wic-apl-messages/wic-apl-message-edit/wic-apl-message-edit.component';
import {WicConfirmModalComponent} from '../wic-confirm-modal/wic-confirm-modal.component';

/* tslint:enable */

@Injectable({
    providedIn: 'root'
})
export class APLMessagesFacade extends APLMessageFacadeBase {
    forDeletion$: Subject<APLMessage>;
    forEdit$: Subject<APLMessage>;

    constructor(private store: Store<IAppState>, private modal: NgbModal) {
        super(APLMessage, store);

        this.forDeletion$ = new Subject<APLMessage>();
        this.forEdit$ = new Subject<APLMessage>();
    }

    // region Selections
    selectAll(): Observable<APLMessage[]> {
        return this.store.pipe(select(allAPLMessages));
    }

    selectCurrent(): Observable<APLMessage> {
        return this.store.pipe(select(currentAPLMessage));
    }

    selectIsLoading(): Observable<boolean> {
        return this.store.pipe(select(aplMessagesLoading));
    }

    // endregion

    // region Dispatches
    setCurrent(message: APLMessage) {
        this.store.dispatch(new Select(APLMessage, message));
    }
    // endregion

    // region UI
    showEdit(message: APLMessage) {
        this.showEditModal(message)
            .then((edited: APLMessage) => {
                this.update(edited);
            }, () => {
            });
    }

    showCreate() {
        this.showEditModal({
            isActive: true,
            onBenefits: true,
            onDetail: true
        } as APLMessage)
            .then(created => {
                this.create({
                    ...created,
                });
            }, () => {
            });
    }

    showDelete(message: APLMessage) {
        if (!message) {
            return;
        }

        this.forDeletion$.next(message);

        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = 'Confirm APL Message Deletion';
        modalReference.componentInstance.message =
            'Are you sure you want to delete this APL message? This will delete all related text versions as well!';
        modalReference.result
            .then(() => {
                this.delete(message);
                this.forDeletion$.next(null);
            }, () => {
                this.forDeletion$.next(null);
            });
    }

    // endregion

    // region Helpers
    private showEditModal(message: APLMessage): Promise<APLMessage> {
        const modalReference = this.modal.open(WicAplMessageEditComponent, {size: 'lg'});
        modalReference.componentInstance.message = message || {};
        return modalReference.result;
    }

    // endregion
}
