import {Component, Input} from '@angular/core';
import {MenuItem} from '@models';

@Component({
    selector: 'wic-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent {
    @Input() showNarrow: boolean;
    @Input() menuItems: MenuItem[];
    @Input() areaPath: string;
}
