<div class="container">
    <router-outlet (activate)="hideSelf = true" (deactivate)="hideSelf = false"></router-outlet>
</div>

<div *ngIf="!hideSelf">
    <div class="row mt-4 mb-4">
        <div class="col-md-12">
            <wic-micro-blog-table
                #microBlogTable
                [isLoading]="isLoading$ | async"
                [showFilter]="false"
                [microBlogs]="microBlogs$ | async"
                (added)="add()"
                (deleted)="delete($event)"
                (refreshed)="refresh()">
            </wic-micro-blog-table>
        </div>
    </div>
</div>
