import {Injectable} from '@angular/core';
import {Create, Delete, LoadAll, Update} from '@briebug/ngrx-auto-entity';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';

import {Banner, BannerPlaylist, Partner} from '@models';
import {IAppState} from '@state/app.state';
import {allBanners, MoveBanner} from '@state/banner.state';
// tslint:disable-next-line:max-line-length
import {WicBannerPlaylistEntryEditComponent} from '../wic-admin/wic-authorities/wic-authority-banners/wic-banner-playlist-view/wic-banner-playlist-entry-edit/wic-banner-playlist-entry-edit.component';
import {WicConfirmModalComponent} from '../wic-confirm-modal/wic-confirm-modal.component';

@Injectable({
    providedIn: 'root'
})
export class BannersFacade {
    forDeletion$: Subject<Banner>;
    forEdit$: Subject<Banner>;

    constructor(private store: Store<IAppState>, private modal: NgbModal) {
        this.forDeletion$ = new Subject<Banner>();
        this.forEdit$ = new Subject<Banner>();
    }

    // region Selections
    get all(): Observable<Banner[]> {
        return this.store.pipe(select(allBanners));
    }
    // endregion

    // region Dispatches
    loadByPlaylist(playlist: BannerPlaylist) {
        this.store.dispatch(new LoadAll(Banner, {playlistId: playlist.PlaylistID}));
    }

    create(banner: Banner) {
        this.store.dispatch(new Create(Banner, banner));
    }

    update(banner: Banner) {
        this.store.dispatch(new Update(Banner, banner));
    }

    delete(banner: Banner) {
        this.store.dispatch(new Delete(Banner, banner));
    }

    move(banner: Banner, newIndex: number) {
        this.store.dispatch(new MoveBanner(banner, newIndex));
    }
    // endregion

    // region UI Helpers
    showEdit(playlist: BannerPlaylist, partners: Partner[], banner: Banner) {
        this.showEditModal(playlist, partners, banner)
            .then(edited => {
                this.update(edited);
            }, () => {
            });
    }

    showCreate(playlist: BannerPlaylist, partners: Partner[]) {
        if (!playlist) {
            return;
        }

        this.showEditModal(playlist, partners).then(created => {
            this.create(created);
        }, () => {
        });
    }

    showDelete(banner: Banner) {
        if (!banner) {
            return;
        }

        this.forDeletion$.next(banner);

        const modalReference = this.modal.open(WicConfirmModalComponent);
        modalReference.componentInstance.modalTitle = 'Confirm Banner Deletion';
        modalReference.componentInstance.message =
            'Are you sure you want to delete this banner?';
        modalReference.result
            .then(() => {
                this.delete(banner);
                this.forDeletion$.next(null);
            }, () => {
                this.forDeletion$.next(null);
            });
    }
    // endregion

    // region Helpers
    private showEditModal(playlist: BannerPlaylist, partners: Partner[], banner?: Banner): Promise<Banner> {
        const modalReference = this.modal.open(WicBannerPlaylistEntryEditComponent, {size: 'lg'});
        modalReference.componentInstance.playlist = playlist;
        modalReference.componentInstance.partners = partners;
        modalReference.componentInstance.banner = banner || {
            PlaylistID: playlist.PlaylistID
        };
        return modalReference.result;
    }

    // endregion
}
