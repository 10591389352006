import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@auth0/auth0-angular';
import { WikiArticle } from '@models';
import * as moment from 'moment';
import slugify from 'slugify';

@Component({
    selector: 'wic-wiki-editor',
    templateUrl: './wic-wiki-editor.component.html',
    styleUrls: ['./wic-wiki-editor.component.sass'],
})
export class WicWikiEditorComponent implements OnInit, OnChanges {
    @Input() article: WikiArticle;
    @Input() user: User;
    @Output() changed = new EventEmitter<WikiArticle>();
    @Output() saved = new EventEmitter<WikiArticle>();

    form: FormGroup;

    constructor(private formBuilder: FormBuilder) { }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);
        this.form.controls.title.valueChanges.subscribe(value => {
            const ctrl = this.form.controls.slug;
            if (!ctrl.value || (ctrl.pristine && ctrl.value !== 'home')) {
                const slug = slugify(value, { lower: true });
                ctrl.patchValue(slug);
                ctrl.markAsPristine();
            }
        });
        this.form.controls.slug.valueChanges.subscribe(value => {
            const ctrl = this.form.controls.slug;
            if (!value) {
                const slug = slugify(this.form.controls.title.value, { lower: true });
                ctrl.patchValue(slug);
                ctrl.markAsPristine();
            }
        });

        this.form.valueChanges.subscribe(value => {
            const copy = {
                ...this.article,
                ...value,
            };
            this.changed.emit(copy);
        });
    }

    ngOnChanges() {
        if (this.article && this.form) {
            this.form.patchValue(this.article);
        }
    }

    private buildForm(formBuilder) {
        return formBuilder.group({
            slug: [null, Validators.required],
            title: [null, Validators.required],
            markdown: [null, Validators.required],
        });
    }

    save(form) {
        if (!form.valid) {
            return;
        }

        const article = {
            ...this.article,
            ...form.value,
            author: this.user.name,
            createdAt: this.article.createdAt || moment().format(),
            updatedAt: this.article.createdAt ? moment().format() : undefined,
        };

        this.saved.emit(article);
    }
}
