import {Injectable} from '@angular/core';
import {LoadAll, Select} from '@briebug/ngrx-auto-entity';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {Category} from '@models';
import {IAppState} from '@state/app.state';
import { allCategories, allCategoriesAsGroupedLookup, CategoriesFacadeBase, currentCategory } from '@state/categories.state';

@Injectable({
    providedIn: 'root'
})
export class CategoriesFacade extends CategoriesFacadeBase {
    allAsGroupedLookup$ = this.store.select(allCategoriesAsGroupedLookup);

    constructor(private store: Store<IAppState>) {
      super(Category, store);
    }

    // region Selections
    selectAll(): Observable<Category[]> {
        return this.store.pipe(select(allCategories));
    }

    selectCurrent(): Observable<Category> {
        return this.store.pipe(select(currentCategory));
    }

    // endregion

    // region Dispatches
    setCurrent(category: Category) {
        this.store.dispatch(new Select(Category, category));
    }

    loadByAuthority(authorityId: number) {
        this.store.dispatch(new LoadAll(Category, {authorityId}));
    }

    // endregion
}
