<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <span class="badge badge-info" *ngIf="override?._id">
                {{override._id}}
            </span>
            {{override?._id ? 'Edit' : 'Create'}} APL Override
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label class="col-4 col-form-label">Authority</label>
            <div class="col-2" *ngIf="override.authorityId">
                <div class="form-check">
                    <label class="form-check-label">{{override.authorityId}}</label>
                </div>
            </div>
            <div class="col-2" *ngIf="!override.authorityId">
                <div class="form-check">
                    <label class="form-check-label">For All</label>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="categoryId" class="col-4 col-form-label" [class.text-danger]="submitted && f.categoryId.invalid">Category</label>
            <div class="col-8">
                <select class="form-control" [class.is-invalid]="submitted && !form.get('categoryId').valid"
                        formControlName="categoryId" name="categoryId" id="categoryId" required>
                    <option value="" disabled>Select Category</option>
                    <option *ngFor="let category of categories" [value]="category.categoryId">
                        {{category.description }} ({{category.categoryId}})
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="subCategoryId" class="col-4 col-form-label" [class.text-danger]="submitted && f.subCategoryId.invalid">Sub-Category</label>
            <div class="col-8">
                <span class="col-form-label text-secondary" *ngIf="!form.get('categoryId').value">Select a Category</span>
                <select class="form-control" *ngIf="form.get('categoryId').value"
                        [class.is-invalid]="submitted && !form.get('subCategoryId').valid"
                        formControlName="subCategoryId" name="subCategoryId" id="subCategoryId">
                    <option value="" disabled>Select Sub-Category</option>
                    <option *ngFor="let subCategory of filteredSubCategories" [value]="subCategory.subCategoryId">
                        {{subCategory.description }} ({{subCategory.subCategoryId}})
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label for="description" class="col-4 col-form-label">Description</label>
            <div class="col-8">
                <input class="form-control" formControlName="description" name="description" id="description">
            </div>
        </div>
        <div class="form-group row">
            <label for="uom" class="col-4 col-form-label">Unit of Measure</label>
            <div class="col-8">
                <input class="form-control" formControlName="uom" name="uom" id="uom">
            </div>
        </div>
        <div class="form-group row">
            <label for="packageSize" class="col-4 col-form-label">Package Size</label>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="packageSize" name="packageSize" id="packageSize">
            </div>
        </div>

        <div class="form-group row">
            <label for="isEnabled" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isEnabled" formControlName="isEnabled">
                    <label class="form-check-label" for="isEnabled">Is Enabled</label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
