import {Component, Input} from '@angular/core';
import {IImportStep} from '@models/types';

@Component({
  selector: 'wic-data-import-log',
  templateUrl: './wic-data-import-log.component.html',
  styleUrls: ['./wic-data-import-log.component.sass']
})
export class WicDataImportLogComponent {
    @Input() progressLog: IImportStep[];
}
