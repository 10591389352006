import { createAction, props } from '@ngrx/store';
import { StatisticsReportingJob } from '@state/reporting/statistics-reporting-job.entity';

export const createReportingJob = createAction(
  '[StatisticsReportingJob] Create Reporting Job',
  props<{ beginDate: string; endDate: string }>()
);

export const createReportingJobSuccess = createAction(
  '[StatisticsReportingJob] Create ReportingJob Success',
  props<{ reportingJob: StatisticsReportingJob, correlationId: string; }>()
);
export const createReportingJobFailure = createAction(
  '[StatisticsReportingJob] Create ReportingJob Failure',
  props<{ error: any }>()
);


export const pollReportingJob = createAction('[StatisticsReportingJob] Poll ReportingJob', props<{ correlationId: string }>());

export const pollReportingJobSuccess = createAction(
  '[StatisticsReportingJob] Poll ReportingJob Success',
  props<{ reportingJob: StatisticsReportingJob }>()
);

export const pollReportingJobFailure = createAction(
  '[StatisticsReportingJob] Poll ReportingJob Failure',
  props<{ error: any }>()
);
