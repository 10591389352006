import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Observable} from 'rxjs';

import {Category} from '@models';
import {environment} from '../../environments/environment';

@Injectable()
export class CategoryService implements IAutoEntityService<Category> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria: any): Observable<Category[]> {
        return this.http.get<Category[]>(
            environment.apiRoot + `/admin/authorities/${criteria.authorityId}/categories`
        );
    }
}
