import {buildState} from '@briebug/ngrx-auto-entity';
import {Category} from '@models';
import {createSelector} from '@ngrx/store';
import {IGroupedLookup} from '@models/types';

export const {
    initialState: initialCategoryState,
    selectors: {
        selectAll: allCategories,
        selectCurrentEntity: currentCategory
    },
  facade: CategoriesFacadeBase
} = buildState(Category);


export function categoryReducer(state = initialCategoryState) {
    return state;
}

export const allCategoriesAsGroupedLookup = createSelector(
    allCategories,
    (categories): IGroupedLookup[] => categories.map((cat) => ({
        value: cat.categoryId,
        group: `${cat.authorityId}`,
        text: `(${cat.categoryId}) ${cat.description}`,
    }))
);
