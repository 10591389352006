import {Component, Input} from '@angular/core';
import {ErrorReport} from '@models';

@Component({
    selector: 'wic-error-report-device-info',
    templateUrl: './error-report-device-info.component.html',
    styleUrls: ['./error-report-device-info.component.scss']
})
export class ErrorReportDeviceInfoComponent {
    @Input() report: ErrorReport;
}
