import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';

import {APLMessageMapping} from '@models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AplMessageMappingService implements IAutoEntityService<APLMessageMapping> {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<APLMessageMapping[]> {
        return this.http.get<APLMessageMapping[]>(
            environment.apiRoot + `/admin/apl/messages/${criteria && criteria.messageId ? `${criteria.messageId}/` : ''}mappings`
        );
    }

    upsertMany(entityInfo: IEntityInfo, entities: APLMessageMapping[]): Observable<APLMessageMapping[]> {
        return this.http.put<APLMessageMapping[]>(environment.apiRoot + `/admin/apl/messages/mappings`, entities);
    }

    deleteMany(entityInfo: IEntityInfo, entities: APLMessageMapping[]): Observable<APLMessageMapping[]> {
        return this.http.request<APLMessageMapping>('delete',
            environment.apiRoot + `/admin/apl/messages/mappings`, {body: entities}
        ).pipe(map(() => entities));
    }
}
