<div class="wic-app" [class.logo-bg]="hideLayout$ | async">
    <wic-nav [showNarrow]="showNarrow$ | async"
            [showLayout]="showLayout$ | async"
            [fullName]="fullName$ | async"
            [menuItems]="menuItems"
            [areaPath]="areaPath"
             [availableApps]="availableApps.all$ | async"
             [currentApp]="availableApps.current$ | async"
            (logout)="logout()"
            (toggleSidebar)="toggleSidebar()"
            (appSelected)="availableApps.select($event)">
    </wic-nav>
    <div class="wic-content"
         [hidden]="layout.navShown$ | async"
         [class.wic-content-full]="showNarrow$ | async"
         [class.wic-content-only]="hideLayout$ | async">
        <wic-sub-nav [crumbs]="crumbs" [area]="area" [areaPath]="areaPath" [showLayout]="showLayout$ | async"></wic-sub-nav>
        <div class="container" [class.wic-main-container]="hideLayout$ | async">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!--<fc-snackbar [snack]="snack$ | async" (dismissed)="dismissSnack()"></fc-snackbar>-->
</div>
