import {Component, OnInit} from '@angular/core';
import {APLImportConfigsFacade} from '@facades/apl-import-configs.facade';
import {APLReportingFacade, IAPLImportStatusCounts} from '@facades/apl-reporting.facade';
import {APLSummariesFacade} from '@facades/apl-summaries.facade';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {APLSummary} from '@models/APLSummary';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'wic-apl',
    templateUrl: './wic-apl.component.html',
    styleUrls: ['./wic-apl.component.sass']
})
export class WicAplComponent implements OnInit {
    summaries$: Observable<APLSummary[]>;
    importStatusCounts$: Observable<IAPLImportStatusCounts>;
    itemCounts$: Observable<number[]>;
    itemLabels$: Observable<string[]>;
    categoryCounts$: Observable<number[]>;
    categoryLabels$: Observable<string[]>;
    subCategoryCounts$: Observable<number[]>;
    subCategoryLabels$: Observable<string[]>;
    hideSelf = false;

    constructor(
        private authoritiesFacade: AuthoritiesFacade,
        private aplReportingFacade: APLReportingFacade,
        private aplSummariesFacade: APLSummariesFacade,
        private aplImportConfigsFacade: APLImportConfigsFacade) {
    }

    ngOnInit() {
        this.summaries$ = this.aplReportingFacade.detailedSummaries;
        this.importStatusCounts$ = this.aplReportingFacade.importStatusCounts;
        this.itemCounts$ = this.aplReportingFacade.itemCounts.pipe(map(series => series.map(dataPoint => dataPoint.count)));
        this.itemLabels$ = this.aplReportingFacade.itemCounts.pipe(map(series => series.map(dataPoint => dataPoint.name)));
        this.categoryCounts$ = this.aplReportingFacade.categoriesHistogram.pipe(
            map(histogram => histogram.map(counts => counts.count))
        );
        this.categoryLabels$ = this.aplReportingFacade.categoriesHistogram.pipe(
            map(histogram => histogram.map(counts => counts.value.toString()))
        );
        this.subCategoryCounts$ = this.aplReportingFacade.subCategoriesHistogram.pipe(
            map(histogram => histogram.map(counts => counts.count))
        );
        this.subCategoryLabels$ = this.aplReportingFacade.subCategoriesHistogram.pipe(
            map(histogram => histogram.map(counts => `${counts.value}-${counts.value + 9}`))
        );

        this.authoritiesFacade.loadAll();
        this.aplSummariesFacade.loadAll();
        this.aplImportConfigsFacade.loadAll();
    }
}
