// tslint:disable:max-line-length
import {Injectable} from '@angular/core';
import {Create, Delete, LoadAll, Update} from '@briebug/ngrx-auto-entity';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {Authority, BinCode} from '@models';
import {IAppState} from '@state/app.state';
import {allBinCodes} from '@state/bincode.state';
import {WicAuthorityBincodeEditComponent} from '../wic-admin/wic-authorities/wic-authority-view/wic-authority-bincode-edit/wic-authority-bincode-edit.component';
import {WicConfirmModalComponent} from '../wic-confirm-modal/wic-confirm-modal.component';

// tslint:enable:max-line-length

@Injectable({
    providedIn: 'root'
})
export class BinCodesFacade {
    constructor(private store: Store<IAppState>, private modal: NgbModal) {
    }

    // region Selections
    get all(): Observable<BinCode[]> {
        return this.store.pipe(select(allBinCodes));
    }

    // endregion

    // region Dispatches
    loadAllByAuthority(authorityId: number) {
        this.store.dispatch(new LoadAll(BinCode, {authorityId}));
    }

    create(binCode: BinCode) {
        this.store.dispatch(new Create(BinCode, binCode));
    }

    update(binCode: BinCode) {
        this.store.dispatch(new Update(BinCode, binCode));
    }

    delete(binCode: BinCode) {
        this.showConfirmModal(binCode).then(() => {
            this.store.dispatch(new Delete(BinCode, binCode));
        });
    }

    // endregion

    // region UI Helpers
    showEdit(binCode: BinCode) {
        this.showEditModal(binCode)
            .then(edited => {
                this.update(edited);
            }, () => {
            });
    }

    showCreate(authority: Authority) {
        if (!authority) {
            return;
        }

        this.showEditModal({
            AuthorityID: authority.id
        } as BinCode).then(created => {
            this.create(created);
        }, () => {
        });
    }

    // endregion

    // region Helpers
    private showConfirmModal(binCode: BinCode) {
        const modalReference = this.modal.open(WicConfirmModalComponent, {size: 'lg'});
        modalReference.componentInstance.modalTitle = `Delete BinCode?`;
        // tslint:disable-next-line:max-line-length
        modalReference.componentInstance.message = `Are you sure you wish to delete ${binCode.BINCode}? This process is irreversible and cannot be undone!`;
        return modalReference.result;
    }

    private showEditModal(binCode: BinCode): Promise<BinCode> {
        const modalReference = this.modal.open(WicAuthorityBincodeEditComponent, {size: 'lg'});
        modalReference.componentInstance.binCode = binCode || {};
        return modalReference.result;
    }

    // endregion
}
