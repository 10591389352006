<form>
    <div class="header">
        <h4>Upload CSV</h4>
    </div>
    <div class="form-group">
        <label class="custom-file form-control">
            <input #file class="custom-file-input" type="file" accept=".csv" ng2FileSelect [uploader]="uploader"/>
            <span class="custom-file-control" [class.selected]="file.value">{{ file.value.slice(12) }}</span>
        </label>
    </div>
    <div class="form-group">
        <button class="btn btn-primary" (click)="upload()">Upload</button>
    </div>
</form>
