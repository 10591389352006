<div class="card mt-5 mb-5">
    <!--<div class="card-header">-->
        <!--<form [formGroup]="form">-->
            <!--<div class="row">-->
                <!--<div class="col-6">-->
                    <!--<div class="form-group">-->
                        <!--<div class="input-group">-->
                            <!--<input class="form-control" placeholder="Search" formControlName="criteria">-->
                            <!--<div class="input-group-append">-->
                                <!--<button class="btn btn-outline-secondary" (click)="form.patchValue({criteria: null})" type="button">-->
                                    <!--<i class="fa fa-times"></i>-->
                                <!--</button>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="col-3">-->
                    <!--<div class="form-group">-->
                        <!--<div class="input-group">-->
                            <!--<input class="form-control" placeholder="Start (yyyy-mm-dd)"-->
                                   <!--name="startDate" formControlName="startDate"-->
                                   <!--ngbDatepicker #sd="ngbDatepicker">-->
                            <!--<div class="input-group-append">-->
                                <!--<button class="btn btn-outline-secondary" (click)="sd.toggle()" type="button">-->
                                    <!--<i class="fa fa-calendar"></i>-->
                                <!--</button>-->
                                <!--<button class="btn btn-outline-secondary" (click)="form.patchValue({startDate: null})" type="button">-->
                                    <!--<i class="fa fa-times"></i>-->
                                <!--</button>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="col-3">-->
                    <!--<div class="form-group">-->
                        <!--<div class="input-group">-->
                            <!--<input class="form-control" placeholder="End (yyyy-mm-dd)"-->
                                   <!--name="endDate" formControlName="endDate"-->
                                   <!--ngbDatepicker #ed="ngbDatepicker">-->
                            <!--<div class="input-group-append">-->
                                <!--<button class="btn btn-outline-secondary" (click)="ed.toggle()" type="button">-->
                                    <!--<i class="fa fa-calendar"></i>-->
                                <!--</button>-->
                                <!--<button class="btn btn-outline-secondary" (click)="form.patchValue({endDate: null})" type="button">-->
                                    <!--<i class="fa fa-times"></i>-->
                                <!--</button>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</form>-->
    <!--</div>-->
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="apptImports?.length">
            <thead>
            <tr>
                <th class="text-nowrap">Authority ID</th>
                <th class="text-nowrap">Username</th>
                <th class="text-nowrap text-center">Imported At</th>
                <th class="text-center">Status</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let impt of apptImports">
                <td class="">{{impt.authorityId}}</td>
                <td class=""><a [routerLink]="['view', impt._id]">{{impt.username}}</a></td>
                <td class="text-center">{{impt.timestamp}}</td>
                <td class="text-center">
                    <span class="badge badge-pill text-white"
                          [class.badge-info]="impt.succeeded !== true && impt.succeeded !== false && !impt.incomplete"
                          [class.badge-success]="impt.succeeded === true"
                          [class.badge-danger]="impt.succeeded === false && !impt.incomplete"
                          [class.badge-warning]="impt.incomplete">
                        <span *ngIf="impt.succeeded === true"><i class="fa fa-check"></i> Succeeded</span>
                        <span *ngIf="impt.succeeded === false"><i class="fa fa-times"></i> Failed</span>
                        <span *ngIf="impt.incomplete"><i class="fa fa-question-circle-o"></i> Incomplete</span>
                        <span *ngIf="impt.succeeded !== false && impt.succeeded !== true && !impt.incomplete"><i class="fa fa-question"></i> Indeterminate</span>
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!apptImports?.length" class="card-body">
        <p><em>There are no appointment import logs.</em></p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
            </div>
        </div>
    </div>
</div>
