import { Component, OnInit } from '@angular/core';
import { CategoriesFacade } from '@facades/categories.facade';
import { DemoAccountsFacade } from '@facades/demo-accounts.facade';
import { SubCategoriesFacade } from '@facades/subcategory.facade';
import { Authority, DemoAccount, DemoAccountBenefitGroup } from '@models';
import { ILookup } from '@models/types';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@state/app.state';
import { FetchAuthoritiesAction } from '@state/authorities/authorities.actions';
import { activeProviderAuthorities, activeProviderAuthoritiesAsLookup } from '@state/authorities/authorities.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'wic-demo-accounts',
  templateUrl: './wic-demo-accounts.component.html',
  styleUrls: ['./wic-demo-accounts.component.sass'],
})
export class WicDemoAccountsComponent implements OnInit {
  authorities$: Observable<ILookup[]>;
  hideSelf = false;

  private authorities: Authority[];

  constructor(
    private store: Store<IAppState>,
    public demoAccounts: DemoAccountsFacade,
    public categories: CategoriesFacade,
    public subCategories: SubCategoriesFacade
  ) {}

  ngOnInit() {
    this.authorities$ = this.store.select(activeProviderAuthoritiesAsLookup);
    this.store.pipe(select(activeProviderAuthorities)).subscribe(authorities => (this.authorities = authorities));

    this.refresh();
  }

  toggleActive(demoAccount: DemoAccount) {
    this.demoAccounts.update({ ...demoAccount, isCardActivated: !demoAccount.isCardActivated})
  }

  add() {
    this.demoAccounts.showCreate(this.authorities);
  }

  addEditBenefitGroup(demoAccount: DemoAccount, benefitGroup?: DemoAccountBenefitGroup) {
    this.categories.loadByAuthority(demoAccount.authorityId);
    this.subCategories.loadByAuthority(demoAccount.authorityId);
    this.demoAccounts.showAddEditBenefitGroup(demoAccount, benefitGroup);
  }

  refresh() {
    this.demoAccounts.loadAll();
    this.store.dispatch(new FetchAuthoritiesAction());
  }
}
