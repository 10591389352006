import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../environments/environment';

import {SubCategoryConfig} from '@models';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryConfigService implements IAutoEntityService<SubCategoryConfig>  {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<SubCategoryConfig[]> {
        return this.http.get<SubCategoryConfig[]>(`${environment.apiRoot}/admin/subcategoryconfigs`);
    }

    create(entityInfo: IEntityInfo, config: SubCategoryConfig, criteria?: any): Observable<SubCategoryConfig> {
        return this.http.post<SubCategoryConfig>(`${environment.apiRoot}/admin/subcategoryconfigs`, config);
    }

    update(entityInfo: IEntityInfo, config: SubCategoryConfig, criteria?: any): Observable<SubCategoryConfig> {
        return this.http.put<SubCategoryConfig>(`${environment.apiRoot}/admin/subcategoryconfigs/${config._id}`, config);
    }

    delete(entityInfo: IEntityInfo, config: SubCategoryConfig, criteria?: any): Observable<SubCategoryConfig> {
        return this.http.delete(`${environment.apiRoot}/admin/subcategoryconfigs/${config._id}`).pipe(
            map(() => config)
        );
    }
}
