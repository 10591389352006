import {Injectable} from '@angular/core';
import {buildState, Deselect, EntityActionTypes, IEntityState, LoadAllSuccess, ofEntityType, Select} from '@briebug/ngrx-auto-entity';
import {BenefitInfo} from '@models/BenefitInfo';
import {Actions, createEffect} from '@ngrx/effects';
import {createSelector} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';

export const {
    initialState: initialBenefitInfoState,
    facade: BenefitInfoFacadeBase,
    selectors: {
        selectCurrentEntity: currentBenefitInfo
    }
} = buildState(BenefitInfo);

export function benefitInfoReducer(state = initialBenefitInfoState): IEntityState<BenefitInfo> {
    return state;
}

@Injectable()
export class BenefitInfoEffects {
    constructor(private actions$: Actions) {
    }

    clearCurrent$ = createEffect(
        () => this.actions$.pipe(
            ofEntityType(BenefitInfo, EntityActionTypes.LoadAll),
            map(() => new Deselect(BenefitInfo))
        )
    );

    setCurrent$ = createEffect(
        () => this.actions$.pipe(
            ofEntityType<BenefitInfo, LoadAllSuccess<BenefitInfo>>(BenefitInfo, EntityActionTypes.LoadAllSuccess),
            map(({entities}) => entities && entities[0]),
            filter(entity => !!entity),
            map(entity => new Select(BenefitInfo, entity))
        )
    );
}

export const highlightJSON = (json: string) => Prism.highlight(json, Prism.languages.json, 'json');

export const mapToCurrentRawBenefits = (info: BenefitInfo): string =>
    info
        ? highlightJSON(JSON.stringify({...info, originalResponse: undefined}, null, 4))
        : null;


export const mapToOriginalRawBenefits = (info: BenefitInfo): string =>
    info && info.originalResponse
        ? highlightJSON(JSON.stringify(info.originalResponse, null, 4))
        : null;

export const currentRawBenefitInfo = createSelector(
    currentBenefitInfo,
    mapToCurrentRawBenefits
);

export const originalRawBenefitInfo = createSelector(
    currentBenefitInfo,
    mapToOriginalRawBenefits
);
