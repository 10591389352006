import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAutoEntityService, IEntityInfo} from '@briebug/ngrx-auto-entity';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../environments/environment';

import {ServerSettings} from '@models';

@Injectable({
    providedIn: 'root'
})
export class ServerSettingsService implements IAutoEntityService<ServerSettings>  {
    constructor(private http: HttpClient) {
    }

    loadAll(entityInfo: IEntityInfo, criteria?: any): Observable<ServerSettings[]> {
        return this.http.get<ServerSettings[]>(`${environment.apiRoot}/admin/settings`);
    }

    // create(entityInfo: IEntityInfo, config: ServerSettings, criteria?: any): Observable<ServerSettings> {
    //     return this.http.post<ServerSettings>(`${environment.apiRoot}/admin/subcategoryconfigs`, config);
    // }
    //
    // update(entityInfo: IEntityInfo, config: ServerSettings, criteria?: any): Observable<ServerSettings> {
    //     return this.http.put<ServerSettings>(`${environment.apiRoot}/admin/subcategoryconfigs/${config._id}`, config);
    // }
    //
    // delete(entityInfo: IEntityInfo, config: ServerSettings, criteria?: any): Observable<ServerSettings> {
    //     return this.http.delete(`${environment.apiRoot}/admin/subcategoryconfigs/${config._id}`).pipe(
    //         map(() => config)
    //     );
    // }
}
