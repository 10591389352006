<div class="row mt-4" *ngIf="subCategoryConfig">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5 class="float-left mb-0">Link Details</h5>
                <i class="fa fa-fw fa-plus float-right m-1" (click)="added.emit(subCategoryConfig)"></i>
            </div>
            <div class="">
                <table class="table table-sm table-striped table-hover fixed-header mb-0"
                       *ngIf="subCategoryConfig?.linkDetails?.length">
                    <thead>
                    <tr>
                        <th class="text-left">Label</th>
                        <th class="text-left">Url</th>
                        <th class="text-center">Icon</th>
                        <th class=" text-right"><i class="fa fa-wrench"></i></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let link of subCategoryConfig.linkDetails"
                        [class.bg-danger]="link?.label === linkForDeletion?.label"
                        [class.bg-success]="link?.label === linkForEdit?.label"
                        [class.text-white]="link?.label === linkForDeletion?.label || link?.label === linkForEdit?.label">
                        <td class="text-left">{{link?.label}}</td>
                        <td class="text-left">{{link?.url}}</td>
                        <td class="text-center">
                            <img style="width: auto; height: 24px; max-height: 24px" [src]="createImageUrl(link?.icon)" *ngIf="link?.icon"/>
                        </td>
                        <td class="text-right">
                            <i class="fa fa-edit"
                               (click)="edited.emit({subCategoryConfig: subCategoryConfig, linkDetail: link})"></i>&nbsp;
                            <i class="fa fa-trash"
                               (click)="deleted.emit({subCategoryConfig: subCategoryConfig, linkDetail: link})"></i>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="!subCategoryConfig?.linkDetails?.length" class="col-sm-12">
                <p><em>This sub category configuration currently has no link details.</em></p>
            </div>
        </div>
    </div>
</div>
