import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    private navShown$$ = new BehaviorSubject(false);

    get navShown$(): Observable<boolean> {
        return this.navShown$$.asObservable();
    }

    constructor() {
    }

    showNav() {
        this.navShown$$.next(true);
    }

    hideNav() {
        this.navShown$$.next(false);
    }
}
