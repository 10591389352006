import {Entity, Key} from '@briebug/ngrx-auto-entity';

@Entity({
    modelName: 'BenefitInfo',
    uriName: 'benefits',
})
export class BenefitInfo {
    @Key cardNumber: string;
    @Key householdId: string;
    authorityId: number;
    startDate: Date;
    endDate: Date;
    isCardActivated?: boolean;
    retrievedAt: string | number;
    retrievedAtFormatted: string;
    lastImportedDate?: Date;
    benefitsUpdatedAt?: Date;
    lastShoppedDate?: Date;
    lastVendorShopped?: string;
    entryGUID: string;
    benefits: Benefit[];
    originalResponse?: string;
}

export interface Benefit {
    categoryId: number;
    subCategoryId: number;
    beginDate: string;
    endDate: string;
    availableQuantity: number;
    quantity: number;
}

