import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DemoAccountsService } from '@services';
import { DemoAccountsUIService } from '@state/demo-accounts/demo-accounts-ui.service';
import {
  createDemoAccountSuccess,
  loadDemoAccount,
  replicateAgencyCard,
  selectDemoAccount,
  showReplicateAgencyCard,
} from '@state/demo-accounts/demo-accounts.state';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class DemoAccountEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly demoAccounts: DemoAccountsService,
    private readonly demoAccountsUI: DemoAccountsUIService
  ) {}

  loadDetailedDemoAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectDemoAccount),
      map(({ entity }) =>
        loadDemoAccount({
          keys: entity.cardNumber,
        })
      )
    )
  );

  showReplicateAgencyCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showReplicateAgencyCard),
      switchMap(({ authorityId }) =>
        this.demoAccountsUI.showReplicateAgencyCard().pipe(
          catchError(() => of(null)),
          filter(result => !!result)
        )
      ),
      map(replicateCard => replicateAgencyCard({ replicateCard }))
    )
  );

  replicateAgencyCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(replicateAgencyCard),
      switchMap(({ replicateCard }) => this.demoAccounts.replicateAgencyCard(replicateCard)),
      map(entity => createDemoAccountSuccess({ entity }))
    )
  );
}
