import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {APLMessage} from '@models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '@services';

@Component({
    selector: 'wic-apl-message-edit',
    templateUrl: './wic-apl-message-edit.component.html',
    styleUrls: ['./wic-apl-message-edit.component.sass']
})
export class WicAplMessageEditComponent implements OnInit {
    @Input() message: APLMessage;

    get f() {
        return this.form.controls;
    }

    form: FormGroup;
    submitted = false;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public util: UtilService,
        private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.form = this.buildForm(this.formBuilder);
        this.populateForm(this.message);
    }

    private buildForm(formBuilder) {
        const form = formBuilder.group({
            name: [null, Validators.required],
            isActive: [null, Validators.required],
            onBenefits: [null, Validators.required],
            onDetail: [null, Validators.required],
            icon: [null]
        });

        return form;
    }

    populateForm(message) {
        this.form.patchValue(message);
    }

    imageChanged(event) {
        if (!event.target.files.length) {
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            this.form.patchValue({icon: reader.result});
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    createImageUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    save(form: FormGroup) {
        this.submitted = true;
        const value = form.value;

        if (!form.valid) {
            return;
        }

        const message = {
            ...value,
            _id: !this.message._id ? undefined : this.message._id
        };
        this.activeModal.close(message);
    }
}
