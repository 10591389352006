import { Injectable } from "@angular/core";
import { StandardCategoriesFacadeBase } from "./standard-categories.state";
import { StandardCategory } from "./standard-category.model";
import { IAppState } from "@state/app.state";
import { Store } from "@ngrx/store";
import { standardCategoriesMinusExisting } from "./standard-categories.selectors";
import { loadAllForTranslations } from "./standard-categories.actions";

@Injectable({
  providedIn: 'root',
})
export class StandardCategoriesFacade extends StandardCategoriesFacadeBase {
  asLookup$ = this.store.select(standardCategoriesMinusExisting);
  constructor(protected store: Store<IAppState>) {
    super(StandardCategory, store);
  }

  loadAllForTranslations() {
    this.store.dispatch(loadAllForTranslations());
  }
}