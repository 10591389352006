import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';

import {type} from '@state/shared/utils';
import {APLImportService} from '../services/apl-import.service';

export const RUN_IMPORT_JOB = type('[APL Imports] Run import job');
export const CHECK_IMPORT_JOB = type('[APL Imports] Check import job');
export const UPDATE_CONFIG_MANUAL_RUN = type('[APL Imports] Update config manual run');

export class RunImportJob implements Action {
    readonly type = RUN_IMPORT_JOB;

    constructor(public authorityId: number) {
    }
}

export class CheckImportJob implements Action {
    readonly type = CHECK_IMPORT_JOB;

    constructor(public authorityId: number) {
    }
}

export class UpdateConfigManualRun implements Action {
    readonly type = UPDATE_CONFIG_MANUAL_RUN;

    constructor(public authorityId: number, public manualRun: any) {}
}

@Injectable()
export class APLImportEffects {
    @Effect()
    runImportJob$ = this.actions.pipe(
        ofType(RUN_IMPORT_JOB),
        exhaustMap((action: RunImportJob) => this.importService.run(action.authorityId).pipe(
            tap(result => console.log(result)),
            map(result => new UpdateConfigManualRun(action.authorityId, result)),
            catchError(err => of(new UpdateConfigManualRun(action.authorityId, err)))
        ))
    );

    @Effect()
    checkImportJob$ = this.actions.pipe(
        ofType(CHECK_IMPORT_JOB),
        exhaustMap((action: CheckImportJob) => this.importService.check(action.authorityId).pipe(
            tap(result => console.log(result)),
            map(result => new UpdateConfigManualRun(action.authorityId, result)),
            catchError(err => of(new UpdateConfigManualRun(action.authorityId, err)))
        ))
    );

    constructor(private actions: Actions, private importService: APLImportService) {
    }
}
