import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IEntityInfo} from '@briebug/ngrx-auto-entity';
import {BenefitInfo} from '@models/BenefitInfo';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

export interface IBenefitSearchCriteria {
    cardNumber: string;
    householdId?: string;
    authorityId: number;
    startDate?: string;
    endDate?: string;
}

@Injectable({providedIn: 'root'})
export class BenefitInfoService {
    constructor(private http: HttpClient) {
    }

    loadAll(info: IEntityInfo, criteria: IBenefitSearchCriteria): Observable<BenefitInfo[]> {
        let url = criteria.householdId
            ? `${environment.apiRoot3}/admin/cards/${criteria.cardNumber}/${criteria.householdId}/benefits`
            : `${environment.apiRoot3}/admin/cards/${criteria.cardNumber}/benefits`;
        url = `${url}?authorityId=${criteria.authorityId}`;
        url = `${url}&startDate=${criteria.startDate || moment().format('YYYY-MM-DD')}`;
        url = `${url}&endDate=${criteria.endDate || moment().format('YYYY-MM-DD')}`;
        url = `${url}&includeOriginal=true`;
        return this.http.get<BenefitInfo>(url).pipe(
            map(benefitInfo => [benefitInfo])
        );
    }
}
