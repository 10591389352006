import { Injectable } from "@angular/core";
import { EnhancedIssueTranslation } from "./enhanced-issue-translations.model";
import { EnhancedIssuesTranslationsFacadeBase, allEnhancedIssueTranslations } from "./enhanced-issues-translations.state";
import { IAppState } from "@state/app.state";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { addNewLanguage, deleteLanguage, loadAllByEnhancedIssue, updateEnhancedIssueTranslations } from "./enhanced-issues-translations.actions";
import { EnhancedIssue } from "app/enhanced-issues/feature/enhanced-issue.model";

@Injectable({
  providedIn: 'root',
})
export class EnhancedIssuesTranslationsFacade extends EnhancedIssuesTranslationsFacadeBase {
  constructor(private store: Store<IAppState>) {
    super(EnhancedIssueTranslation, store);
  }

  get all(): Observable<EnhancedIssueTranslation[]> {
    return this.store.pipe(select(allEnhancedIssueTranslations));
  }

  loadAllByEnhancedIssue(enhancedIssue: EnhancedIssue) {
    this.store.dispatch(loadAllByEnhancedIssue({ enhancedIssue }));
  }

  addNewLanguage(enhancedIssueTranslations: EnhancedIssueTranslation[], enhancedIssue: EnhancedIssue) {
    this.store.dispatch(addNewLanguage( { enhancedIssueTranslations, enhancedIssue }));
  }

  deleteLanguage(enhancedIssueTranslations: EnhancedIssueTranslation[], enhancedIssue: EnhancedIssue) {
    this.store.dispatch(deleteLanguage({ enhancedIssueTranslations, enhancedIssue }));
  }

  updateEnhancedIssueTranslations(enhancedIssueTranslations: EnhancedIssueTranslation[], enhancedIssue: EnhancedIssue) {
    this.store.dispatch(updateEnhancedIssueTranslations({ enhancedIssueTranslations, enhancedIssue }));
  }
}