import { moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkDragDrop } from '@angular/cdk/drag-drop/typings/drag-events';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Banner, BannerPlaylist, Partner} from '@models';

@Component({
    selector: 'wic-banner-playlist-entries-table',
    templateUrl: './wic-banner-playlist-entries-table.component.html',
    styleUrls: ['./wic-banner-playlist-entries-table.component.sass']
})
export class WicBannerPlaylistEntriesTableComponent {
    @Input() banners: Banner[];
    @Input() playlist: BannerPlaylist;
    @Input() partners: Partner[];
    @Output() refreshed = new EventEmitter<BannerPlaylist>();
    @Output() added = new EventEmitter<{playlist: BannerPlaylist, partners: Partner[] }>();
    @Output() edited = new EventEmitter<{banner: Banner, playlist: BannerPlaylist, partners: Partner[]}>();
    @Output() deleted = new EventEmitter<Banner>();
    @Output() moved = new EventEmitter<{ banner: Banner; to: number }>();

    constructor(private sanitizer: DomSanitizer) {
    }

    createImageUrl(type: string, data: string) {
        if (!type || !data) { return ''; }
        const uri = `data:${type};base64,${data}`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(uri);
    }

    drop(ev: CdkDragDrop<any>) {
      if (ev.previousIndex !== ev.currentIndex) {
        const banner = this.banners[ev.previousIndex];

        this.moved.emit({ banner, to: ev.currentIndex });
        moveItemInArray(this.banners, ev.previousIndex, ev.currentIndex);
      }
    }
}
