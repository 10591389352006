/* tslint:disable */
import {Injectable} from '@angular/core';
import {Create, Delete, DeleteMany, LoadAll, Select, UpdateMany} from '@briebug/ngrx-auto-entity';
import {APLMessageText} from '@models';
import {ObjectId} from '@models/types';
import {Store} from '@ngrx/store';
import {APLMessageTextFacadeBase} from '@state/apl-message-text.state';
import {IAppState} from '@state/app.state';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/* tslint:enable */

@Injectable({
    providedIn: 'root'
})
export class APLMessageTextsFacade extends APLMessageTextFacadeBase {
    constructor(private store: Store<IAppState>) {
        super(APLMessageText, store);
    }

    // region Selections
    selectByMessage(messageId: ObjectId): Observable<APLMessageText[]> {
        return this.all$.pipe(
            map(texts => texts.filter(text => text.messageId === messageId))
        );
    }

    // endregion

    // region Dispatches
    setCurrent(text: APLMessageText) {
        this.store.dispatch(new Select(APLMessageText, text));
    }

    loadByMessage(messageId: ObjectId) {
        this.store.dispatch(new LoadAll(APLMessageText, {messageId}));
    }

    create(text: APLMessageText): string {
        if (!text) {
            return;
        }
        this.store.dispatch(new Create(APLMessageText, text, {messageId: text.messageId}));
    }

    delete(text: APLMessageText): string {
        if (!text) {
            return;
        }

        this.store.dispatch(new Delete(APLMessageText, text, {messageId: text.messageId}));
    }

    updateAll(texts: APLMessageText[]) {
        if (!texts || !texts.length) {
            return;
        }

        this.store.dispatch(new UpdateMany(APLMessageText, texts, {messageId: texts[0].messageId}));
    }

    deleteAll(texts: APLMessageText[]) {
        if (!texts || !texts.length) {
            return;
        }

        this.store.dispatch(new DeleteMany(APLMessageText, texts, {messageId: texts[0].messageId}));
    }

    // endregion
}
