<div class="card mt-5" *ngIf="!!job">
    <div class="card-header">
        <div class="row">
            <div class="col-12">
                <h5 class="float-left mb-0">Import Job Log Transcript</h5>
            </div>
        </div>
    </div>
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0">
            <thead>
            <tr>
                <th class="text-nowrap">Type</th>
                <th class="text-nowrap">Time</th>
                <th class="text-nowrap">Message</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let log of job.log">
                <td class="text-center">
                    <span class="badge text-white"
                          [class.badge-info]="log.type === 'DEBUG'"
                          [class.badge-success]="log.type === 'INFO'"
                          [class.badge-warning]="log.type === 'WARN'"
                          [class.badge-danger]="log.type === 'ERROR'">
                        {{log.type}}
                    </span>
                </td>
                <td class="text-nowrap">
                    <span class="text-monospace text-nowrap">&nbsp;{{log.timestamp}}&nbsp;</span>
                </td>
                <td class="w-75">
                    <input class="invisible-input" [value]="log.message">
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
