import {Component, Input} from '@angular/core';
import {LocalBenefitImport} from '@models/LocalBenefitImport';

@Component({
    selector: 'wic-benefit-import-detail',
    templateUrl: './wic-benefit-import-detail.component.html',
    styleUrls: ['./wic-benefit-import-detail.component.sass']
})
export class WicBenefitImportDetailComponent {
    @Input() import: LocalBenefitImport;
}
