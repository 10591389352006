import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ModernStatisticsRecord} from '@models/ModernStatisticsRecord';
import {Observable} from 'rxjs';

import {UsageStatisticsRecord} from '@models';
import {environment} from '../../environments/environment';
import { AvailableApps } from '@state/available-apps/models/available-app.entity';

@Injectable()
export class ReportingService {
    constructor(private http: HttpClient) {
    }

    fetchUsageStatisticsReport(appId: AvailableApps, beginDate: string, endDate: string): Observable<UsageStatisticsRecord[]> {
        let url = `${environment.apiRoot}/admin/apps/${appId}/reports/usagestatistics?`;
        if (beginDate) {
            url = url + `&beginDate=${beginDate}`;
        }
        if (endDate) {
            url = url + `&endDate=${endDate}`;
        }
        return this.http.get<UsageStatisticsRecord[]>(url);
    }

    modernReport(appId: AvailableApps, startDate: string, endDate: string): Observable<ModernStatisticsRecord[]> {
        let url = `${environment.apiRoot}/admin/apps/${appId}/reports/modern/statistics?`;
        if (startDate) {
            url = url + `&startDate=${startDate}`;
        }
        if (endDate) {
            url = url + `&endDate=${endDate}`;
        }
        return this.http.get<ModernStatisticsRecord[]>(url);
    }
}
