import { Entity, Key } from "@briebug/ngrx-auto-entity";

@Entity({
  modelName: 'EnhancedIssueTranslation',
  uriName: 'enhanced-issues-translations'
})
export class EnhancedIssueTranslation {
  @Key translationId: number;
  issueId: number;
  translationKey: string;
  translationValue: string;
  lang: string;
}