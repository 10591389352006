import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';

import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {APLImportEffects} from '@state/apl-import.state';
import {APLMessageEffects} from '@state/apl-message.state';
import { AuthorityLocationEffects } from '@state/authorities/authority-locations/authority-locations.state';
import {BannerEffects} from '@state/banner.state';
import {BenefitInfoEffects} from '@state/benefit-info.state';
import {CardEffects} from '@state/card.state';
import { DemoAccountEffects } from '@state/demo-accounts/demo-account.effects';
import {MenuButtonEffects} from '@state/menu-button.state';
import {ModernstatisticsEffects} from '@state/reporting/modernstatistics.effects';
import {UserEffects} from '@state/users/user.state';
import {WikiEffects} from '@state/wiki/wiki.effects';

import {AuthorityMappingsService, AuthService, CardImagesService, DemoAccountsService, MenuButtonService} from '@services';
import {environment} from '../../environments/environment';
import {AppEffects} from './app.effects';
import {appMetaReducers, appState} from './app.state';

import { EntityEffects, NGRX_AUTO_ENTITY_APP_STORE, NgrxAutoEntityModule } from '@briebug/ngrx-auto-entity';
import {AuthorityAccountsEffects} from '@state/authorities/accounts.effects';
import {BINCodesEffects} from '@state/authorities/bincodes.effects';
import {AuthorityFlagsEffects} from '@state/authorities/flags.effects';
import {AuthorityTypesEffects} from '@state/authorities/types.effects';
// import {UsersEffects} from '@state/users/users.effects';
import {AuthEffects} from './auth/auth.effects';
import {AuthoritiesEffects} from './authorities/authorities.effects';
import {AuthorityMappingsEffects} from './authority-mappings/authority-mappings.effects';
import {CustomRouterStateSerializer} from './shared/utils';
import {APLItemEffects} from '@state/apl-item/apl-item.state';
import {BenefitImportJobEffects} from '@state/benefit-import-job/benefit-import-job.state';
import {CardLegacyStatusEffects} from '@state/card-status/card-legacy-status.effects';
import {CardStatusEffects} from '@state/card-status/card-status.effects';
import { AvailableApp } from '@state/available-apps/models/available-app.entity';
import { EntityService } from '../entity.service';
import { AvailableAppsEffects } from '@state/available-apps/available-apps.effects';
import { AvailableAppsFacade } from '@state/available-apps/available-apps.facade';
import { StatisticsReportingJobsEffects } from '@state/reporting/statistics-reporting-jobs.effects';
import { BannerPlaylistEffect } from './banner-playlist.state';
import { MicroBlogEffects } from './micro-blogs.state';
import { APLImportConfigEffects } from './apl-import-config.state';

@NgModule({
    imports: [
        StoreModule.forRoot(appState, {metaReducers: appMetaReducers}),
        EffectsModule.forRoot([
            EntityEffects,
            APLMessageEffects,
            APLImportConfigEffects,
            APLImportEffects,
            AppEffects,
            AuthEffects,
            AuthoritiesEffects,
            AuthorityAccountsEffects,
            AuthorityFlagsEffects,
            AuthorityLocationEffects,
            AuthorityTypesEffects,
            AuthorityMappingsEffects,
            AvailableAppsEffects,
            BannerEffects,
            BannerPlaylistEffect,
            BenefitInfoEffects,
            BINCodesEffects,
            CardEffects,
            DemoAccountEffects,
            MenuButtonEffects,
            StatisticsReportingJobsEffects,
            ModernstatisticsEffects,
            APLItemEffects,
            BenefitImportJobEffects,
            CardStatusEffects,
            CardLegacyStatusEffects,
            // UsersEffects,
            UserEffects,
            WikiEffects,
            MicroBlogEffects,
        ]),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 50,
            logOnly: environment.production
        }),
        NgrxAutoEntityModule.forRoot(),
    ],
    providers: [
        AuthorityMappingsService,
        CardImagesService,
        DemoAccountsService,
        AuthService,
        MenuButtonService,
        AvailableAppsFacade,

        { provide: AvailableApp, useClass: EntityService },
        {provide: NGRX_AUTO_ENTITY_APP_STORE, useExisting: Store },
    ],
})
export class StateModule {

    static forRoot(): ModuleWithProviders<StateModule> {
        return {
            ngModule: StateModule,
            providers: [
                /**
                 * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
                 * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
                 * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
                 */
                {provide: RouterStateSerializer, useClass: CustomRouterStateSerializer},
            ],
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: StateModule) {
        if (parentModule) {
            throw new Error(
                'State module is already loaded. Import it in the AppModule only');
        }
    }
}
