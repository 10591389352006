<div>
    <div class="row">
        <div class="col-md-12">
            <wic-banner-playlist-detail
                [playlist]="bannerPlaylist$ | async"
                (edited)="editPlaylist($event)">
            </wic-banner-playlist-detail>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <wic-banner-playlist-entries-table
                [banners]="banners$ | async"
                [playlist]="bannerPlaylist$ | async"
                [partners]="partners$ | async"
                (refreshed)="refreshBanners($event)"
                (added)="addBanner($event)"
                (edited)="editBanner($event)"
                (deleted)="deleteBanner($event)"
                (moved)="moveBanner($event)">
            </wic-banner-playlist-entries-table>
        </div>
    </div>
</div>
