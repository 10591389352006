import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
    formatError(errors) {
        const str = Object.keys(errors).map(key => {
            switch (key) {
                case 'required':
                    return 'Required';
                case 'pattern':
                    return 'Bad Format';
                case 'ngbDate':
                    return 'yyyy-mm-dd';
                default:
                    return errors[key];
            }
        }).reduce((msgs, msg) => msgs + ',' + msg, '');
        return str ? str.substr(1) : str;
    }
}
