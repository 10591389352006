import { Injectable } from '@angular/core';
import { AuthorityLocation } from '@models/AuthorityLocation';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { AuthorityLocationFacadeBase, saveLocation } from '@state/authorities/authority-locations/authority-locations.state';

@Injectable({providedIn: 'root'})
export class AuthorityLocationFacade extends AuthorityLocationFacadeBase {
    constructor(private store: Store<AppState>) {
        super(AuthorityLocation, store);
    }

    saveLocation(location: AuthorityLocation) {
        this.store.dispatch(saveLocation({location}));
    }
}
