import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from '@models';

@Component({
    selector: 'wic-user-detail',
    templateUrl: './wic-user-detail.component.html',
    styleUrls: ['./wic-user-detail.component.sass'],
})
export class WicUserDetailComponent {
    @Input() user: User;
    @Output() edited = new EventEmitter<User>();
    @Output() deleted = new EventEmitter<User>();
}
