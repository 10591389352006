import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable, of, merge, Subject} from 'rxjs';
import {filter, first, map, takeUntil, tap, withLatestFrom} from 'rxjs/operators';

import {MicroBlogsFacade} from '@facades/micro-blogs.facade';
import {ServerSettingsFacade} from '@facades/server-settings.facade';
import {MicroBlog, ServerSettings} from '@models';
import {ILookup} from '@models/types';
import {IAppState} from '@state/app.state';
import {allAuthoritiesAsLookup} from '@state/authorities/authorities.selectors';
import {switchCombine} from '../../../util';
import {WicMicroBlogContentComponent} from './wic-micro-blog-content/wic-micro-blog-content.component';
import {WicMicroBlogDetailComponent} from './wic-micro-blog-detail/wic-micro-blog-detail.component';

@Component({
    selector: 'wic-micro-blog-view',
    templateUrl: './wic-micro-blog-view.component.html',
    styleUrls: ['./wic-micro-blog-view.component.sass']
})
export class WicMicroBlogViewComponent implements OnInit, OnDestroy {
    @ViewChild('detail', {static: true}) detail: WicMicroBlogDetailComponent;
    @ViewChild('content', {static: true}) content: WicMicroBlogContentComponent;

    microBlog$: Observable<MicroBlog>;
    rootSettings$: Observable<ServerSettings>;
    authorities$: Observable<ILookup[]>;
    groups$: Observable<ILookup[]>;

    Ω = new Subject();

    constructor(
        private store: Store<IAppState>,
        private activatedRoute: ActivatedRoute,
        private serverSettingsFacade: ServerSettingsFacade,
        private microBlogsFacade: MicroBlogsFacade) {
    }

    ngOnInit() {
      this.microBlogsFacade.loadAll();
      this.serverSettingsFacade.loadAll();

        this.microBlog$ = this.microBlogsFacade.current;
        this.groups$ = this.microBlogsFacade.selectGroups();

        this.authorities$ = this.store.pipe(
            select(allAuthoritiesAsLookup),
            withLatestFrom(this.microBlogsFacade.current),
            map(([authorities, microBlog]) =>
                authorities.filter(authority =>
                    microBlog.authorities.some(id => id === authority.id)
                )
            )
        );
        this.rootSettings$ = this.serverSettingsFacade.selectRoot();

        this.activatedRoute.params.pipe(
            switchCombine(this.microBlogsFacade.all),
            first(([params, blogs]) => !!params && !!blogs && !!blogs.length),
            map(([params, blogs]) => blogs.find(blog => blog._id === params.id)),
            filter(blog => !!blog)
        ).subscribe(blog => {
            this.microBlogsFacade.setCurrent(blog);
            this.microBlogsFacade.load(blog._id);
        });


        this.detail.edited.pipe(
            withLatestFrom(
                this.store.pipe(select(allAuthoritiesAsLookup)),
                this.groups$
            ),
            takeUntil(this.Ω)
        ).subscribe(([microBlog, authorities, groups]) =>
            this.microBlogsFacade.showEdit(microBlog, authorities, groups)
        );

        this.content.saved.pipe(
            takeUntil(this.Ω)
        ).subscribe(microBlog =>
            this.microBlogsFacade.save(microBlog)
        );
    }

    ngOnDestroy(): void {
        this.Ω.next();
        this.Ω.complete();
    }

    // edit(microBlog: MicroBlog) {
    //     of(microBlog).pipe(
    //         withLatestFrom(this.authorities$)
    //     ).subscribe(([blog, authorities]) =>
    //         this.microBlogsFacade.showEdit(blog, authorities)
    //     );
    // }
    //
    // save(microBlog: MicroBlog) {
    //     this.microBlogsFacade.save(microBlog);
    // }
}
