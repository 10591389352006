import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MbscListviewOptions, MbscPopupOptions} from '@mobiscroll/angular';

@Component({
    selector: 'wic-filter-select',
    templateUrl: './filter-select.component.html',
    styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent implements OnChanges {
    // options$ = new BehaviorSubject<{ [p: string]: string }>({});
    // selected$ = new BehaviorSubject<string[]>([]);
    // @Input('options') set available(value: { [p: string]: string }) {
    //     this.options$.next(value);
    // }
    // @Input('selected') set selectedOptions(value: string[]) {
    //     this.selected$.next(value);
    // };
    @Input() selected: string[] = [];
    @Output() selectedChange = new EventEmitter<string[]>();
    @Input() options: { [p: string]: string } = {
        ERRORED: '#f04141',
        DELAYED: '#ffce00',
        IMPORTED: '#7cda87'
    };
    displayOptions = {};

    verticalSettings: MbscPopupOptions = {
        theme: 'ios',
        themeVariant: 'light',
        display: 'bubble',
        cssClass: 'mbsc-no-padding md-vertical-list',
        buttons: [],
    };
    lvSettings: MbscListviewOptions = {
        enhance: true,
        swipe: false,
    };

    ngOnChanges(changes: SimpleChanges) {
        this.displayOptions = Object.entries(this.options)
            .filter(([key, value]) => !this.selected.includes(key))
            .reduce((prev, [key, value]) => ({
                ...prev,
                [key]: value
            }), {});
    }

    emitChanges(value: string) {
        this.isSelected(value)
            ? this.selectedChange.emit(this.selected.filter((sel) => sel !== value))
            : this.selectedChange.emit([...this.selected, value]);
    }

    getColor(key: string) {
        return this.options[key] || '#888888';
    }

    isSelected(value: string) {
        return this.selected.includes(value);
    }
}
