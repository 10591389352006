<div class="card mt-5 mb-5">
    <div class="">
        <table class="table table-sm table-striped table-hover fixed-header mb-0" *ngIf="types?.length">
            <thead>
            <tr>
                <th class="text-nowrap"><i class="fa fa-key"></i></th>
                <th class="">Name</th>
                <th class="">Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let type of types">
                <td class="">{{type.AuthorityType}}</td>
                <td class="">{{type.Name}}</td>
                <td class="">{{type.Comment}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!types?.length" class="card-body">
        <p><em>There are no authority types.</em></p>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-outline-secondary mr-2" (click)="refreshed.emit()">
                    <i class="fa fa-refresh"></i> Refresh
                </button>
            </div>
        </div>
    </div>
</div>
