import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MbscSelectModule } from '@mobiscroll/angular';
import { SelectAuthorityComponent } from './select-authority/select-authority.component';

@NgModule({
    imports: [CommonModule, MbscSelectModule, ReactiveFormsModule],
    declarations: [SelectAuthorityComponent],
    exports: [SelectAuthorityComponent]
})
export class ComponentsModule {}
