import {buildState} from '@briebug/ngrx-auto-entity';
import {APLSummary} from '@models/APLSummary';

const {initialState, selectors} = buildState(APLSummary);

export const {
    selectAll: allAPLSummaries,
    selectIsLoading: aplSummariesLoading,
} = selectors;

export function aplSummaryReducer(state = initialState) {
    return state;
}
