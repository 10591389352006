import {buildState} from '@briebug/ngrx-auto-entity';
import {APLOverride} from '@models';

const {initialState, selectors} = buildState(APLOverride);

export const {
    selectAll: allAPLOverrides,
    selectIsLoading: aplOverridesLoading,
    selectIsSaving: aplOverrideSaving,
    selectIsDeleting: aplOverrideDeleting,
    selectCurrentEntity: currentAPLOverride
} = selectors;

export function aplOverrideReducer(state = initialState) {
    return state;
}
