import {Injectable} from '@angular/core';
import {buildState, Create, EntityActionTypes, ofEntityType} from '@briebug/ngrx-auto-entity';
import {APLMessage, APLMessageText} from '@models';
import {Actions, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';

export const {initialState, selectors, facade: APLMessageFacadeBase} = buildState(APLMessage);

export const {
    selectAll: allAPLMessages,
    selectIsLoading: aplMessagesLoading,
    selectIsSaving: aplMessageSaving,
    selectIsDeleting: aplMessageDeleting,
    selectCurrentEntity: currentAPLMessage
} = selectors;

export function aplMessageReducer(state = initialState) {
    return state;
}

@Injectable()
export class APLMessageEffects {
    constructor(private actions$: Actions) {}

    created$ = this.actions$.pipe(
        ofType(EntityActionTypes.CreateSuccess),
        tap(action => {
            console.log(action);
        }),
        ofEntityType(APLMessage, EntityActionTypes.CreateSuccess),
        map((action: Create<APLMessage>) => action.entity),
        map(message => new Create(APLMessageText, {messageId: message._id, languageCode: 'en'} as APLMessageText))
    );
}
