<form [formGroup]="form" (submit)="save(form)">
    <div class="modal-header">
        <h4 class="modal-title mb-0">
            <span class="badge badge-info" *ngIf="flag?.FlagID">
                {{flag.FlagID}}
            </span>
            {{flag?.FlagID ? 'Edit' : 'Create'}} Authority Flag
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label class="col-4 col-form-label">Bit</label>
            <div class="col-8">
                {{flag.Bit}}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-4 col-form-label">Mask</label>
            <div class="col-8">
                {{flag.Mask| hex}}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-4 col-form-label">Name</label>
            <div class="col-8">
                <input class="form-control" formControlName="Name" name="name" id="name">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-4 col-form-label">Property Name</label>
            <div class="col-8">
                <input class="form-control" formControlName="PropertyName" name="PropertyName" id="PropertyName">
            </div>
        </div>

        <div class="form-group row">
            <label for="IsEditable" class="col-4 col-form-label"></label>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="IsEditable" formControlName="IsEditable">
                    <label class="form-check-label" for="IsEditable">Is Editable</label>
                </div>
            </div>
        </div><div class="form-group row">
        <label for="IsEnabled" class="col-4 col-form-label"></label>
        <div class="col-8">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="IsEnabled" formControlName="IsEnabled">
                <label class="form-check-label" for="IsEnabled">Is Enabled</label>
            </div>
        </div>
    </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
    </div>
</form>
