import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BannerPlaylistsFacade} from '@facades/banner-playlists.facade';
import {BannersFacade} from '@facades/banners.facade';
import {CardImage} from '@models/CardImage';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {filter, map, tap, withLatestFrom} from 'rxjs/operators';

import {LoadAll} from '@briebug/ngrx-auto-entity';
import {AuthoritiesFacade} from '@facades/authorities.facade';
import {BinCodesFacade} from '@facades/bincodes.facade';
import {DemoAccountsFacade} from '@facades/demo-accounts.facade';
import {MenuButtonsFacade} from '@facades/menu-buttons.facade';
import { Authority, AuthorityAccount, AuthorityFlag, 
  AuthorityType, Banner, BannerPlaylist, BinCode, 
  DemoAccount, MenuButton } from '@models';
import {IAppState} from '@state/app.state';
import {FetchAuthorityAccountAction} from '@state/authorities/accounts.actions';
import {selectCurrentAuthorityAccount} from '@state/authorities/accounts.selectors';
import {SelectAuthorityByIdAction} from '@state/authorities/authorities.actions';
import {FetchAuthorityFlagsAction} from '@state/authorities/flags.actions';
import {FetchAuthorityTypesAction} from '@state/authorities/types.actions';
import {WicAuthorityCardimageComponent} from './wic-authority-cardimage/wic-authority-cardimage.component';
import { EnhancedIssuesFacade } from '../../../enhanced-issues/feature/enhanced-issues.facade';
import { EnhancedIssue } from '../../../enhanced-issues/feature/enhanced-issue.model';

@Component({
    selector: 'wic-authority-view',
    templateUrl: './wic-authority-view.component.html',
    styleUrls: ['./wic-authority-view.component.sass'],
})
export class WicAuthorityViewComponent implements OnInit, OnDestroy {
    authority$: Observable<Authority>;
    account$: Observable<AuthorityAccount>;
    demoAccounts$: Observable<DemoAccount[]>;
    cardImage$: Observable<CardImage>;
    playlist$: Observable<BannerPlaylist>;
    banners$: Observable<Banner[]>;
    binCodes$: Observable<BinCode[]>;
    flags$: Observable<AuthorityFlag[]>;
    menuButtons$: Observable<MenuButton[]>;
    enhancedIssues$: Observable<EnhancedIssue[]>;

    @ViewChild('cardImage', {static: true}) cardImage: WicAuthorityCardimageComponent;

    private authority: Authority;
    public flags: AuthorityFlag[];
    public types: AuthorityType[];
    public account: AuthorityAccount;
    private playlists: BannerPlaylist[];

    private isAlive = true;

    hideSelf = false;

    constructor(
        private modalService: NgbModal,
        private store: Store<IAppState>,
        private activatedRoute: ActivatedRoute,
        private authoritiesFacade: AuthoritiesFacade,
        private binCodesFacade: BinCodesFacade,
        public demoAccountsFacade: DemoAccountsFacade,
        private menuButtonsFacade: MenuButtonsFacade,
        private bannerPlaylistsFacade: BannerPlaylistsFacade,
        private bannersFacade: BannersFacade,
        private enhancedIssuesFacade: EnhancedIssuesFacade) {
    }

    ngOnInit() {
        this.authority$ = this.authoritiesFacade.current$.pipe(
            filter(authority => !!authority),
            tap(authority => {
                this.authority = authority;
                this.binCodesFacade.loadAllByAuthority(authority.id);
                this.authoritiesFacade.loadCardImage(authority);
                this.store.dispatch(new FetchAuthorityAccountAction(authority.id));
                this.menuButtonsFacade.loadByAuthority(authority.id);
                this.cardImage$ = this.authoritiesFacade.cardImage$(this.authority);
                this.authoritiesFacade.cardImageChanged$.subscribe(() => this.cardImage.sync());
                this.demoAccountsFacade.loadAll();
                this.playlist$ = this.bannerPlaylistsFacade.all.pipe(
                    tap(playlists => this.playlists = playlists),
                    map(playlists => playlists.find(playlist => playlist.PlaylistID === authority.bannerPlaylistId)),
                    filter(playlist => !!playlist),
                    tap(playlist => this.bannersFacade.loadByPlaylist(playlist))
                );
                this.enhancedIssuesFacade.loadAllByAuthority(authority.id);
            })
        );
        this.account$ = this.store.pipe(
            select(selectCurrentAuthorityAccount),
            tap(account => {
                this.account = account;
            })
        );
        this.banners$ = this.bannersFacade.all;
        this.binCodes$ = this.binCodesFacade.all;
        this.enhancedIssues$ = this.enhancedIssuesFacade.all;
        this.flags$ = this.authoritiesFacade.flags$.pipe(
            tap(flags => this.flags = flags)
        );
        this.authoritiesFacade.captureTypes(types => this.types = types);
        this.menuButtons$ = this.menuButtonsFacade.selectAll();

        this.demoAccounts$ = this.demoAccountsFacade.all$.pipe(
            withLatestFrom(this.authority$),
            map(([demoAccounts, authority]) => demoAccounts.filter(demoAccount =>
                demoAccount.authorityId === authority.id
            ))
        );

        this.bannerPlaylistsFacade.loadAll();

        this.activatedRoute.params.pipe(
            filter(params => !!params.id),
            map(params => +params.id)
        ).subscribe(id => {
            this.store.dispatch(new SelectAuthorityByIdAction(id));
        });

        this.store.dispatch(new FetchAuthorityFlagsAction());
        this.store.dispatch(new FetchAuthorityTypesAction());
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    refreshDemoAccounts() {
        this.store.dispatch(new LoadAll(DemoAccount));
    }

    editLogo(authority: Authority) {
        this.authoritiesFacade.showLogoImageEdit(authority);
    }

    editDetails(authority: Authority, account: AuthorityAccount, flags: AuthorityFlag[], types: AuthorityType[]) {
        this.authoritiesFacade.showEdit(authority, account, flags, types);
    }

    editCardImage(authority: Authority, cardImage: CardImage) {
        this.authoritiesFacade.showCardImageEdit(authority, cardImage);
    }

    editPlaylist(authority: Authority) {
        this.authoritiesFacade.showPlaylistChooser(authority, this.playlists);
    }

    refreshBinCodes() {
        if (this.authority) {
            this.binCodesFacade.loadAllByAuthority(this.authority.id);
        }
    }

    addBinCode() {
        this.binCodesFacade.showCreate(this.authority);
    }

    editBinCode(binCode: BinCode) {
        this.binCodesFacade.showEdit(binCode);
    }

    deleteBinCode(binCode: BinCode) {
        this.binCodesFacade.delete(binCode);
    }

    refreshMenuButtons() {
        if (this.authority) {
            this.menuButtonsFacade.loadByAuthority(this.authority.id);
        }
    }

    addMenuButton() {
        this.menuButtonsFacade.showCreate(this.authority);
    }

    editMenuButton(button: MenuButton) {
        this.menuButtonsFacade.showEdit(button);
    }

    deleteMenuButton(button: MenuButton) {
        this.menuButtonsFacade.showDelete(button);
    }

    moveUpMenuButton(button: MenuButton) {
        this.menuButtonsFacade.move(button, button.order - 1);
    }

    moveDownMenuButton(button: MenuButton) {
        this.menuButtonsFacade.move(button, button.order + 1);
    }

    addDemoAccount() {
        this.demoAccountsFacade.showCreate([this.authority]);
    }

    deleteDemoAccount(demoAccount: DemoAccount) {
        this.demoAccountsFacade.showDelete(demoAccount);
    }

    addEnhancedIssue() {
      this.enhancedIssuesFacade.addEditEnhancedIssue({
        authorityId: this.authority.id
      } as EnhancedIssue);
    }

    editEnhancedIssue(enhancedIssue: EnhancedIssue) {
      this.enhancedIssuesFacade.addEditEnhancedIssue(enhancedIssue);
    }

    deleteEnhancedIssue(enhancedIssue: EnhancedIssue) {
      this.enhancedIssuesFacade.deleteEnhancedIssue(enhancedIssue);
    }

    moveUpEnhancedIssue(enhancedIssue: EnhancedIssue) {
      this.enhancedIssuesFacade.moveEnhancedIssue(enhancedIssue, enhancedIssue.displayOrder - 1);
    }

    moveDownEnhancedIssue(enhancedIssue: EnhancedIssue) {
      this.enhancedIssuesFacade.moveEnhancedIssue(enhancedIssue, enhancedIssue.displayOrder + 1);
    }
}
