import { buildState } from "@briebug/ngrx-auto-entity";
import { EnhancedIssueTranslation } from "./enhanced-issue-translations.model";
import { Action, createReducer } from "@ngrx/store";

export const {
  initialState: initialEnhancedIssueTranslationsState,
  facade: EnhancedIssuesTranslationsFacadeBase,
  actions: {
    loadAll: loadAllEnhancedIssueTranslations,
    createMany: createEnhancedIssueTranslations,
    createManySuccess: createEnhancedIssueTranslationsSuccess,
    replaceMany: replaceEnhancedIssueTranslations,
    replaceManySuccess: replaceEnhancedIssueTranslationsSuccess,
    deleteMany: deleteEnhancedIssueTranslations,
    deleteManySuccess: deleteEnhancedIssueTranslationsSuccess,
  },
  selectors: {
    selectAll: allEnhancedIssueTranslations,
    selectCurrentEntity: currentEnhancedIssueTranslations,
  }
} = buildState(EnhancedIssueTranslation);

const reduce = createReducer(initialEnhancedIssueTranslationsState);

export function enhancedIssueTranslationsReducer(state = initialEnhancedIssueTranslationsState, action: Action) {
  return reduce(state, action);
}

export const ENHANCED_ISSUES_TRANSLATIONS_FEATURE_KEY = 'enhancedIssueTranslation';
